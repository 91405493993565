import moment from 'moment'
import Logger from 'common/Logger'
import config from './constants'

const logger = new Logger('Report utils')
const METERS_IN_KM = 1000
const MULTIPLICATOR = 10

const formatDistance = (distanceInMeters: string, precision: number = config.reports.distancePrecision, fallbackValue: number = 0): number => {

    if (distanceInMeters === null || distanceInMeters === undefined) {
    return fallbackValue
  } else {
    const km = parseFloat(distanceInMeters) / METERS_IN_KM
    const roundHelper = Math.pow(MULTIPLICATOR, precision)
    return Math.round(km * roundHelper) / roundHelper
  }
}

// distances are in meters
const parseFloatDistance = (value: string): number => {
  return (value === null || value === undefined) ? 0 : parseFloat(value) / 1000 // eslint-disable-line
}

// distances are in meters
const parseFloatNumber = (value: string): number => {
  return (value === null || value === undefined) ? 0 : parseFloat(value)
}

const parseReportDTO = (dto: any = {}, columns: any): any => {
  const res = {
    id: dto.id
  }

  columns.forEach((col: any) => {
    const fieldName = col.data && col.data[0] || col.name
    const rawValue = dto[fieldName]

    switch (col.type) {
    case 'boolean':
      res[fieldName] = rawValue
      break

    case 'cancellation':
      // when
      res[col.data[0]] = dto[col.data[0]] && moment(dto[col.data[0]]) || null
      // who
      res[col.data[1]] = dto[col.data[1]] || null
      // role
      res[col.data[2]] = dto[col.data[2]] || null
      break

    case 'date':
    case 'time':
    case 'timestamp':
    case 'datetime':
      res[fieldName] = rawValue && moment(rawValue) || null
      break

    case 'cost':
    case 'percent':
      res[fieldName] = parseFloatNumber(rawValue)
      break

    case 'distance':
      res[fieldName] = parseFloatDistance(rawValue)
      break

    case 'duration':
      res[fieldName] = rawValue && moment.duration(rawValue, 'ms') || null
      break

    case 'number':
      res[fieldName] = rawValue && Number(rawValue) || 0
      break

    case 'string':
    case 'paymentType':
      res[fieldName] = rawValue || ''
      break

    default:
      logger.warn('UNKNOWN TYPE', col.type, col, rawValue)
      res[fieldName] = rawValue
      break
    }
  })

  return res
}

export default {
  parseReportDTO,
  formatDistance
}
