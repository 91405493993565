class NumberInputControl {
  public form: ng.IFormController
  public name: string
  public model: number | string
  public localModel: string
  public isRequired: boolean
  public isDisabled: boolean
  public placeholder: string
  public zeroPlaceholder: string
  public decimalPointsCount: number
  public min: number
  public max: number
  public allowNegative: number
  public updateModelOnChange: boolean
  public disableOptionalPlaceholder: boolean
  public disabledPlaceholder: boolean
  public autoWidth: boolean
  public addOnText: string
  public hideZeroValue: boolean
  public onChange: (payload: {value: number}) => void

  constructor (
    public $scope: ng.IScope,
    public $filter: ng.IFilterService
  ) {
    $scope.$watchGroup([
      '$ctrl.model',
      '$ctrl.decimalPointsCount'
    ], () => {
      if (typeof this.model === 'number') {
        this.localModel = (!this.model && this.hideZeroValue) ? null : this.parseAndClamp(this.model).toFixed(this.decimalPointsCount)
        this.zeroPlaceholder = Number(0).toFixed(this.decimalPointsCount)
      }
    })
  }

  $onInit (): void {
    if (!this.disableOptionalPlaceholder) {
      const suffix = (!this.isRequired && this.placeholder) ?
        ' ' + this.$filter<ITranslateFilter>('translate')('COMMON.OPTIONAL_SUFFIX') :
        this.$filter<ITranslateFilter>('translate')('COMMON.OPTIONAL')
      this.placeholder += suffix
    }
  }

  handleOnBlur (): void {
    this.castLocalAndSyncModel()
  }

  handleOnChange (): void {
    if (this.updateModelOnChange) {
      this.castLocalAndSyncModel()
    }
  }

  castLocalAndSyncModel (): void {
    const castNumericModel = this.parseAndClamp(this.localModel)
    this.localModel = castNumericModel.toFixed(this.decimalPointsCount)
    this.model = castNumericModel

    this.onChange({value: castNumericModel})
  }

  parseAndClamp (numericString: number | string): number {
    let number = parseFloat(numericString as string)
    number = isNaN(number) ? 0 : number

    if (typeof this.min === 'number' && number < this.min) {
      number = this.min
    }

    if (typeof this.max === 'number' && number > this.max) {
      number = this.max
    }

    if (!this.allowNegative) {
      number = Math.abs(number)
    }

    return number
  }
}

export default {
  templateUrl: require('./number-input.pug'),
  controller: NumberInputControl,
  bindings: {
    form: '=',
    name: '@',
    model: '=',
    isRequired: '<',
    isDisabled: '<?',
    placeholder: '@',
    decimalPointsCount: '<',
    min: '<?',
    max: '<?',
    allowNegative: '<?',
    updateModelOnChange: '<?',
    disableOptionalPlaceholder: '<?',
    disabledPlaceholder: '<?',
    autoWidth: '<?',
    addOnText: '@?',
    onChange: '&',
    hideZeroValue: '<?'
  }
}
