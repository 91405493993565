import templateUrl from './areas-dashboard.pug'
import areasCollection from 'data/collections/areasCollection'
import BaseController from 'presentation/common/BaseController'

class AreasDashboard extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.sync()
  }

  onSync () {
    return areasCollection.getAll().then(areas => this.areas = areas)
  }

  deleteArea (params) {
    return areasCollection.delete(params.id)
      .catch(err => this.logger.error('Cannot delete area', err))
      .then(() => this.sync())
  }
}

export default {
  templateUrl,
  controller: AreasDashboard
}
