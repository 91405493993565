import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'
import credentialInput from './common/credential-input/credentialInput'

import claimAccountCheck from './claim-account-check/claimAccountCheck'
import claimAccountUnauthed from './claim-account-unauthed/claimAccountUnauthed'
import login from './login/login'
import registration from './registration/registration'
import signup from './signup/signup'
import signupComplete from './signup-complete/signupComplete'

import countriesSelect from './signup/countries-select/countriesSelect'

import requestPasswordReset from './request-password-reset/requestPasswordReset'
import resetPassword from './reset-password/resetPassword'

export default declareAppModule('auth', (module: ng.IModule) => module
  .component('credentialInput', credentialInput)
  .component('claimAccountCheck', claimAccountCheck)
  .component('claimAccountUnauthed', claimAccountUnauthed)
  .component('login', login)
  .component('countriesSelect', countriesSelect)
  .component('signup', signup)
  .component('signupComplete', signupComplete)
  .component('registration', registration)
  .component('requestPasswordReset', requestPasswordReset)
  .component('resetPassword', resetPassword)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'login',
      params: ['destination'],
      stateKey: 'LOGIN',
      componentName: 'login'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'registration',
      stateKey: 'REGISTRATION',
      componentName: 'registration'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'signup',
      stateKey: 'SIGNUP',
      componentName: 'signup'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'signup-complete',
      stateKey: 'SIGNUP_COMPLETE',
      componentName: 'signupComplete'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'request-password-reset',
      params: ['email', 'phoneNumber', 'countryDiallingCode'],
      stateKey: 'REQUEST_PASSWORD_RESET',
      componentName: 'requestPasswordReset'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'reset-password',
      params: ['code'],
      stateKey: 'RESET_PASSWORD',
      componentName: 'resetPassword'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'claim-check',
      stateKey: 'CLAIM_ACCOUNT_CHECK',
      componentName: 'claimAccountCheck'
    })

    declareState($stateProvider, {
      parent: 'auth',
      stateName: 'claim',
      stateKey: 'CLAIM_ACCOUNT',
      componentName: 'claimAccountUnauthed'
    })
  })
)
