import templateUrl from './kanban-popup.pug'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import BasePopupController from 'presentation/common/BasePopupController'
import envConfig from 'common/envConfig'
import DeliveryDataService from 'data/common/deliveryDataService'

class KanbanPopup extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DELIVERY_ACTIONS.VIEW, deliveryDialogIds.KANBAN_POPUP_ID)
    this.KANBAN_POPUP_ID = deliveryDialogIds.KANBAN_POPUP_ID
    this.deliveryId = null
    this.delivery = null
  }

  $onInit () {
    this.setupPopupOpenEventListener(delivery => {
      if (this.deliveryId !== delivery.id) {
        this.syncDelivery(delivery.id)
      }
    })

    this.deliverySyncListener = DeliveryDataService.subscribeOnDeliverySync(() => {
      if (this.deliveryId !== null) {
        this.syncDelivery(this.deliveryId)
      }
    })
  }

  $onDestroy () {
    this.clearRefreshTimeout()
    this.deliverySyncListener.remove()
    super.$onDestroy()
  }

  clearRefreshTimeout () {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  onClose () {
    this.delivery = null
    this.deliveryId = null
    this.clearRefreshTimeout()
  }

  syncDelivery (deliveryId) {
    this.clearRefreshTimeout()
    DeliveryDataService.getById(deliveryId, false)
      .then(delivery => {
        this.delivery = delivery
        this.deliveryId = delivery.id
        this.$scope.$digest()
      })
      .catch(error => {
        this.logger.error('Error loading delivery', error)
        this.delivery = null
        this.deliveryId = null
      })
      .then(() => {
        this.refreshTimeout = setTimeout(() => {
          if (this.deliveryId !== null) {
            this.syncDelivery(this.deliveryId)
          }
        }, envConfig.kanban.popupRefreshIntervalMs)
      })
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$rootScope', KanbanPopup]
}

