import cloneDeep from 'lodash-es/cloneDeep'
import templatesCollection from 'data/collections/templatesCollection'
import ViewEditFormController from 'presentation/common/ViewEditFormController'

export default class SettingsController extends ViewEditFormController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.updateSettingsFailed = false
    this.getSettingsFailed = false
    this.entityName = ''
    this.defaultSMSTemplates = {}
  }

  $onInit () {
    this.$scope.$watch('$ctrl.entityId', () => this.sync())

    this.getEntityName()
      .then(entityName => this.entityName = entityName)
      .catch(err => this.logger.error('Cannot get entity name', err))
  }

  getEntityName () {
    return Promise.resolve({})
  }

  getSettings () {
    return Promise.resolve({})
  }

  onSync () {
    if (!this.entityId) {
      return Promise.reject('No entityId specified')
    }

    return Promise.all([
      this.getSettings(),
      templatesCollection.getAllSMSTemplates()
    ])
      .then(([settings, defaultSMSTemplates]) => {
        this.handleGetSettingsSuccess(settings)
        this.handleDefaultSMSTemplates(defaultSMSTemplates)
      })
      .catch(err => this.handleGetSettingsFailed(err))
  }

  handleGetSettingsSuccess (settings) {
    this.model = settings
    this.backendModel = cloneDeep(this.model)
    this.getSettingsFailed = false
  }

  handleDefaultSMSTemplates (defaultSMSTemplatesList) {
    this.defaultSMSTemplates = defaultSMSTemplatesList
  }

  handleGetSettingsFailed (err) {
    this.logger.error('Couldn\'t get settings', err)
    this.getSettingsFailed = true
  }

  submit () {
    return super.submit()
  }

  beforeSubmit () {
    return Promise.resolve(this.model)
  }

  afterSubmitSuccess (response) {
    if (response) {
      this.handleGetSettingsSuccess(response)
    }
    this.isEditing = false
    this.updateSettingsFailed = false
    return super.afterSubmitSuccess()
  }

  afterSubmitFailure () {
    this.updateSettingsFailed = true
    return super.afterSubmitFailure()
  }
}
