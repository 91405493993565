import driverModel from 'data/models/driverModel'
import BaseFormController from 'presentation/common/BaseFormController'
import driverWizardRouteSteps from 'presentation/logistics-drivers/common/driverWizardRouteSteps'

export default class DriverBaseFormController extends BaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams

    this.wizardRouteSteps = driverWizardRouteSteps
    this.fullName = ''

    this.getDriver()
  }

  goToDrivers () {
    this.$state.go('main.logistics.drivers')
  }

  goToSetupAccount () {
    this.$state.go('main.logistics.drivers.setup-driver-account')
  }

  goToAddLicense () {
    this.$state.go('main.logistics.drivers.driver.add-license', {driverId: this.$stateParams.driverId})
  }

  goToAddVehicle () {
    this.$state.go('main.logistics.drivers.driver.add-vehicle', {
      id: this.$stateParams.id,
      fleetId: this.$stateParams.fleetId
    })
  }

  goToAssignToFleets () {
    this.$state.go('main.logistics.drivers.driver.assign-to-fleets', {
      driverId: this.$stateParams.driverId,
      fleetId: this.$stateParams.fleetId
    })
  }

  goToViewDriver (isNewDriver) {
    this.$state.go('main.logistics.drivers.driver.summary', {
      driverId: this.$stateParams.driverId,
      isNewDriver
    })
  }

  getDriver () {
    driverModel.get(this.$stateParams.driverId)
      .then(driver => this.handleDriverFound(driver))
      .catch(error => this.handleDriverNotFound(error))
      .then(() => this.$scope.$digest())
  }

  handleDriverFound (driver) {
    this.model = driver
    this.fullName = driver.fullName
  }

  handleDriverNotFound () {
    this.goToSetupAccount()
  }

  beforeSubmit () {
    return driverModel.update(this.model)
  }
}
