import templateUrl from './radio-button.pug'

class RadioButtonController {
  isValueDisabled (value) {
    return this.disabledValues && this.disabledValues.includes(value)
  }

  onLabelClicked (value) {
    if (!this.isDisabled && this.onChange) {
      this.onChange({value})
    }
  }
}

export default {
  controller: RadioButtonController,
  templateUrl,
  bindings: {
    name: '@',
    values: '=',
    disabledValues: '<',
    labels: '<',
    labelKeys: '=',
    labelKeysPrefix: '@',
    model: '=',
    form: '=',
    isDisabled: '=',
    isRequired: '=',
    hostClass: '@?',
    onChange: '&?'
  }
}
