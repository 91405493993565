import templateUrl from './user-details-panel.pug'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import ViewEditFormController from 'presentation/common/ViewEditFormController'
import CrudService from 'data/services/CrudService'
import userModel from 'data/models/userModel'
import endpoints from 'common/endpoints'

const userService = new CrudService(endpoints.USER)

class UserDetailsPanelController extends ViewEditFormController {
  constructor ($scope) {
    super($scope)
  }

  getDefaultModel () {
    return this.user
  }

  $onChanges (changeDescriptorsByKey) {
    this.user = getValueFromChangeDescriptors(changeDescriptorsByKey, 'user', this.user)
    this.setDefaultModel()
  }

  beforeSubmit () {
    return userService.update(this.model, [this.model.id])
  }

  afterSubmitSuccess (backendUser) {
    super.afterSubmitSuccess(backendUser)
    userModel.getUser() // this will force onUserChanged event
    this.onUserChanged({user: backendUser})
  }
}

export default {
  templateUrl,
  controller: UserDetailsPanelController,
  bindings: {
    user: '<',
    onUserChanged: '&'
  }
}
