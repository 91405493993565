import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import templateUrl from './location-icon.pug'

const STATIC_MAP_PATH_BASE = get(envConfig, 'thirdPartyApis.googleMaps.baseUrl', '')
const STATIC_MAP_PATH_TEMPLATE = get(envConfig, 'thirdPartyApis.googleMaps.paths.staticMap', '')
const STATIC_MAPS_API_KEY = get(envConfig, 'apiKeys.googleMapsStatic', '')

function LocationIconController () {
  const $ctrl = this
  const urlTemplate = STATIC_MAP_PATH_BASE + STATIC_MAP_PATH_TEMPLATE

  function init () {
    if ($ctrl.latitude && $ctrl.longitude && urlTemplate) {
      const centerParam = [$ctrl.latitude, $ctrl.longitude].join(',')
      $ctrl.imageUrl = urlTemplate.replace('{apiKey}', STATIC_MAPS_API_KEY).replace('{center}', centerParam)
    } else {
      $ctrl.imageUrl = ''
    }
  }

  $ctrl.$onInit = init
}

export default {
  templateUrl,
  controller: LocationIconController,
  bindings: {
    latitude: '=',
    longitude: '=',
    verified: '<',
    showStatusBar: '<'
  }
}
