import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Tariff from 'data/domain-objects/Tariff'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

const fleetTariffService = new CrudService(endpoints.FLEET_TARIFF)
const fleetTariffsService = new CrudService(endpoints.FLEET_TARIFFS)
const fleetTariffsOrderService = new CrudService(endpoints.FLEET_TARIFFS_ORDER)
const deliveryStateApplicabilityTypesService = new CrudService(endpoints.DELIVERY_STATE_APPLICABILITY_TYPES)
const deliveryScheduleApplicabilityTypesService = new CrudService(endpoints.DELIVERY_SCHEDULE_APPLICABILITY_TYPES)
const tariffScheduledRideDefaultsService = new CrudService(endpoints.TARIFF_SCHEDULED_RIDE_DEFAULTS)

export default {
  getAll: (fleetId: string): Promise<Tariff[]> => {
    return fleetTariffsService.get([fleetId])
      .then((fleetTariffs: any[]) => fleetTariffs.map(Tariff.build))
  },
  getFleetTariff: (fleetId: string, tariffId: string): Promise<Tariff> => {
    return fleetTariffService.get([fleetId, tariffId], {})
      .then(Tariff.build)
  },
  delete: (fleetId: string, tariffId: string): Promise<void> => {
    return fleetTariffService.delete([fleetId, tariffId])
  },
  createTariffForFleet: (tariff: Tariff, fleetId: string): Promise<Tariff> => {
    return fleetTariffService.create(tariff, [fleetId])
      .then(Tariff.build)
  },
  updateFleetTariff: (tariff: Tariff, tariffId: string, fleetId: string): Promise<Tariff> => {
    return fleetTariffService.update(tariff, [fleetId, tariffId])
      .then(Tariff.build)
  },
  saveFleetTariffsOrder: (fleetId: string, tariffsIds: string[]): Promise<void> => { // Tariffs order will be saved according to ids order in this array
    return fleetTariffsOrderService.update(tariffsIds, [fleetId])
  },
  getTariffDeliveryStateApplicabilityTypes: (): Promise<DBMappedNamedEntity[]> => {
    return deliveryStateApplicabilityTypesService.get([])
      .then((deliveryStateApplicabilityTypes: any[]) => deliveryStateApplicabilityTypes.map(DBMappedNamedEntity.build))
  },
  getDeliveryScheduleApplicabilityTypes: (): Promise<DBMappedNamedEntity[]> => {
    return deliveryScheduleApplicabilityTypesService.get()
  },
  getScheduledRideDefaults: (): any => {
    return tariffScheduledRideDefaultsService.get()
  }
}
