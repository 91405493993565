import BaseController from 'presentation/common/BaseController'
import LocationPoint from 'data/domain-objects/LocationPoint'
import { StateService } from '@uirouter/core'

class AddressLocationController extends BaseController {
  // inputs
  public latitude: string
  public longitude: string
  public isEditing: boolean
  public locationCanBeReverted: boolean

  // ouputs
  public onLocationEdited: (event: {newLocation: LocationPoint}) => {}
  public onRevertLocationClick: () => void
  public onLocationChangesCancel: () => void

  // properties
  public mapError: boolean = false

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  public handleRevertLocationClick (): void {
    return this.onRevertLocationClick()
  }

  public onMapError (): void {
    return this.handleMapError()
  }

  handleMapError (): void {
    this.mapError = true
  }

  handleLocationEdited (newLocation: LocationPoint): void {
    this.onLocationEdited({newLocation: LocationPoint.build(newLocation)})
  }
}

export default {
  templateUrl: require('./address-location.pug'),
  controller: AddressLocationController,
  bindings: {
    latitude: '@',
    longitude: '@',
    isEditing: '<',
    locationCanBeReverted: '<',
    onLocationEdited: '&',
    onRevertLocationClick: '&',
    onLocationChangesCancel: '&?'
  }
}
