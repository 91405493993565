import templateUrl from './countries-select.pug'
import countriesCollection from 'data/collections/countriesCollection'
import Logger from 'common/Logger'
const logger = new Logger('CountriesSelectController')

class CountriesSelectController {
  constructor ($scope) {
    this.$scope = $scope
    this.selectedCountry = null
    this.countries = []
  }

  $onInit () {
    countriesCollection.getCountriesCached()
      .then(countries => {
        this.countries = countries.map(country => {
          // default country
          if (this.selectedCountryCode && this.selectedCountryCode === country.code) {
            this.selectedCountry = country.name
          }
          return {
            id: country.id,
            description: country.name,
            code: country.code
          }
        })
      })
      .catch(err => logger.error('Cannot get countries list', err))
      .then(() => this.$scope.$digest())
  }
  onChange (suggestion) {
    this.onCountrySelected({country: suggestion})
  }
}

export default {
  templateUrl,
  controller: CountriesSelectController,
  bindings: {
    selectedCountryCode: '<?',
    onCountrySelected: '&',
    isRequired: '<?'
  }
}
