import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import TariffPeriod from 'data/domain-objects/TariffPeriod'

const tariffPeriodService = new CrudService(endpoints.TARIFF_PERIOD)
const tariffPeriodsService = new CrudService(endpoints.TARIFF_PERIODS)

export function tariffPeriodsCollectionFactory (tariffPeriodsService, tariffPeriodService) {
  function castTariffPeriod (tariffPeriodDTO) {
    return TariffPeriod.build(tariffPeriodDTO)
  }

  function getById (tariffPeriodId) {
    return tariffPeriodService.get([tariffPeriodId])
      .then(tariffPeriodDTO => TariffPeriod.build(tariffPeriodDTO))
  }

  function getAll (params = {}) {
    const mapResults = response => response.map(castTariffPeriod)
    return tariffPeriodsService.get([], params).then(mapResults)
  }

  function create (tariffPeriod) {
    return tariffPeriodService.create(tariffPeriod)
      .then(tariffPeriodDTO => TariffPeriod.build(tariffPeriodDTO))
  }

  function update (tariffPeriodId, tariffPeriod) {
    return tariffPeriodService.update(tariffPeriod, [tariffPeriodId])
  }

  function deleteFn (id) {
    return tariffPeriodService.delete([id])
  }

  return {
    getById,
    getAll,
    create,
    update,
    'delete': deleteFn
  }
}

export default tariffPeriodsCollectionFactory(tariffPeriodsService, tariffPeriodService)
