import Logger from 'common/Logger'
import Place from 'data/domain-objects/Place'
import PaginationParameters from 'data/domain-objects/PaginationParameters'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import {placesCollection, OrderDirections} from 'data/collections/placesCollection'
import {StateService} from '@uirouter/core'

const logger = new Logger('Places List Controller')
const TITLES_TO_ORDER_BY = {
  PLACE_NAME_EN: 'name.en',
  PLACE_NAME_AR: 'name.ar',
}

class PlacesListController {
  public places: Place[]
  public paginationParams: PaginationParameters = new PaginationParameters()
  public categories: DBMappedNamedEntity[] = []
  public selectedCategoryId: string
  public isLoading: boolean
  public titlesToOrderBy: object = TITLES_TO_ORDER_BY
  public selectedOrderByTitle: string
  public selectedOrderDirection: OrderDirections
  public orderDirections: any = OrderDirections
  private searchString: string

  constructor (
    private $scope: ng.IScope,
    private $state: StateService
  ) {}

  $onInit(): void {
    this.getPlaces()
    this.getCategories()
  }

  private getPlaces = (): void => {
    this.isLoading = true

    let searchString: string
    if (this.searchString && this.searchString.length) {
      searchString = this.searchString
    }

    placesCollection.getPlaces(
      this.paginationParams.skip,
      this.paginationParams.limit,
      searchString,
      this.selectedCategoryId,
      this.selectedOrderByTitle,
      this.selectedOrderDirection
    )
      .then((response: {places: Place[], paginationParams: PaginationParameters}) => {
        this.places = response.places
        this.paginationParams = response.paginationParams
      })
      .catch((error: Error) => {
        logger.error('Cannot load places:', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  private getCategories = (): void => {
    this.isLoading = true
    placesCollection.getPlacesCategories()
      .then((categories: DBMappedNamedEntity[]) => {
        this.categories = categories
      })
      .catch((error: Error) => {
        logger.error('Can\'t load places categories', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  public searchPlaces = (searchString: string): void => {
    this.searchString = searchString
    this.getPlaces()
  }

  public duplicatePlace = (placeId: string): void => {
    this.$state.go('main.tools.places.create', {
      place: this.places.find((place: Place) => place.id === placeId)
    })
  }

  public deletePlace = (placeId: string): Promise<void> => { // Delete Dialog Controller which we pass this function to awayting Promise
    return placesCollection.deletePlace(placeId)
      .then(() => {
        const placeIndex = this.places.findIndex((place: Place) => placeId === place.id)
        if (placeIndex > -1) {
          this.places.splice(placeIndex, 1)
        }
      })
      .catch((error: Error) => {
        logger.error('Can\'t delete place:', error)
      })
  }

  public onCategoryChanged = (category: ISuggestion | null): void => {
    if (category === null) {
      this.selectedCategoryId = undefined
    } else {
      this.selectedCategoryId = category.id
    }
    this.getPlaces()
  }

  public setOrderBy = (orderByTitle: string): void => {
    if (orderByTitle === this.selectedOrderByTitle) {
      this.changeOrderDirection()
    } else {
      this.selectedOrderByTitle = orderByTitle
      this.selectedOrderDirection = this.orderDirections.asc
    }
    this.getPlaces()
  }

  public onPaginationUpdated = (offset: number, limit: number): void => {
    this.paginationParams.skip = offset
    this.paginationParams.limit = limit
    this.getPlaces()
  }

  private changeOrderDirection = (): void => {
    if (this.selectedOrderDirection === this.orderDirections.asc) {
      this.selectedOrderDirection = this.orderDirections.desc
    } else {
      this.selectedOrderDirection = this.orderDirections.asc
    }
  }
}

export default {
  templateUrl: require('./places-list.pug'),
  controller: PlacesListController
}
