import PromoCode from 'data/domain-objects/PromoCode'
import CrudService from 'data/services/CrudService'
import endpoints from 'common/endpoints'
import Logger from 'common/Logger'
const logger = new Logger('Promocodes collection')

const promoCodesService = new CrudService(endpoints.PROMO_CODES)
const promoCodeService = new CrudService(endpoints.PROMO_CODE)

class PromoCodesCollection {

  public getPromoCodes(): Promise<PromoCode[]> {
    return promoCodesService.get()
    .then((promoCodes: any): PromoCode[] => promoCodes.map(PromoCode.build))
    .catch((err: Error) => {
      logger.error('Can\'t get promocodes', err)
      return Promise.resolve([])
    })
  }

  public getPromoCode(promoCodeId: string): Promise<PromoCode> {
    return promoCodeService.get([promoCodeId])
    .then(PromoCode.build)
  }

  public updatePromoCode(promoCode: PromoCode): Promise<PromoCode> {
    return promoCodeService.update(promoCode, [promoCode.id])
    .then(PromoCode.build)
  }

  public createPromoCode(promoCode: PromoCode): Promise<PromoCode> {
    return promoCodeService.create(promoCode)
    .then(PromoCode.build)
  }

  public deletePromoCode(promoCodeId: string): Promise<void> {
    return promoCodeService.delete([promoCodeId])
  }

}

export default new PromoCodesCollection()
