import moment from 'moment'
import envConfig from 'common/envConfig'
import BaseController from 'presentation/common/BaseController'

export default class BalanceHistoryBaseController extends BaseController {
  constructor ($scope, $state, $filter) {
    super($scope, $state)
    this.$filter = $filter

    this.currentPage = 1
    this.orderDirection = 'DESC'

    this.filters = {
      orderBy: 'createdAt',
      orderDirection: -1,
      limit: envConfig.pagination.defaultLimit,
      skip: 0
    }

    this.dateRange = {}
  }

  $onInit () {
    this.$scope.$watch('$ctrl.orderDirection', (value, oldValue) => this.onSortDirectionChange(value, oldValue))
    this.$scope.$watch('$ctrl.dateRange.endDate', value => this.onDateEndChange(value))
    this.$scope.$watch('$ctrl.filters', (newVal, oldVal) => this.onFiltersChange(newVal, oldVal), true)
  }

  changeDates (payload) {
    this.dateRange.startDate = payload.dateFrom
    this.dateRange.endDate = payload.dateTo
  }

  onSearchFieldChange (value) {
    this.filters.search = value
    this.filters.skip = 0
  }

  onOffsetChange (offset, limit) {
    this.filters.skip = offset
    this.filters.limit = limit
  }

  onSortDirectionChange (value, oldValue) {
    if (value && oldValue) {
      this.filters.orderDirection = value === 'DESC' ? -1 : 1
    }
  }

  onDateEndChange (value) {
    if (value) {
      this.filters.startDate = moment(this.dateRange.startDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}).valueOf()
      this.filters.finishDate = moment(this.dateRange.endDate).set({hour: 23, minute: 59, second: 59, millisecond: 0}).valueOf()
    } else {
      delete this.filters.startDate
      delete this.filters.finishDate
    }

    this.filters.skip = 0
    this.currentPage = 1
  }

  onFiltersChange (oldVal, newVal) {
    if (oldVal !== newVal) {
      // we need to reset to first page
      if (oldVal.senderId !== newVal.senderId || oldVal.status !== newVal.status) {
        this.filters.skip = 0
        this.currentPage = 1
      }
      this.sync()
    }
  }
}
