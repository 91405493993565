import userSettings from 'data/services/userSettingsService'

class TitledPanelController {
  /* Bindings START */
  public title: string
  public isCollapsible: boolean
  public spaceAbove: boolean
  public spaceBeneath: boolean
  public expandedStateUserSettingKey: string // we should not perform any direct interactions inside this component
  public defaultExpandedState: boolean
  public internalIsExpanded: boolean
  public isRequired: boolean
  /* Bindings END */

  public expanded: boolean

  constructor () {
    this.expanded = true
  }

  $onInit (): void {
    // This check (and line 17) needs to be a type check as undefined could incorrectly set the expanded state to false.
    if (typeof this.defaultExpandedState === 'boolean') {
      this.expanded = this.defaultExpandedState
    }

    if (this.expandedStateUserSettingKey) {
      const setting = userSettings.getValue(this.expandedStateUserSettingKey)
      if (typeof setting === 'boolean') {
        this.expanded = setting
      }
    }
  }

  $onChanges (simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.hasOwnProperty('internalIsExpanded')) {
      this.expanded = simpleChanges.internalIsExpanded.currentValue
    }
  }

  onHeaderClick (): void {
    if (this.isCollapsible) {
      this.expanded = !this.expanded
      // TODO: move this interaction outside the component, by for example firing event and reacting on it
      if (this.expandedStateUserSettingKey) {
        userSettings.setValue(this.expandedStateUserSettingKey, this.expanded)
      }
    }
  }
}

export default {
  templateUrl: require('./titled-panel.pug'),
  controller: TitledPanelController,
  transclude: true,
  bindings: {
    title: '@',
    isCollapsible: '<?',
    spaceAbove: '<?',
    spaceBeneath: '<?',
    expandedStateUserSettingKey: '<?', // we should not perform any direct interactions inside this component! this should be remved from here
    defaultExpandedState: '<?',
    internalIsExpanded: '<?',
    isRequired: '<?',
    hostClass: '@?'
  }
}
