import BasePopupController from 'presentation/common/BasePopupController'

export const ORDER_POPUP_ID = 'order-popup'
export const ORDER_POPUP_SHOW_ACTION = 'order-popup-show-action'

interface IOrderPopupPayload {
  businessId: string,
  orderId: string
}

class OrderPopupController extends BasePopupController {
  public businessId: string
  public orderId: string
  public ORDER_POPUP_ID: string = ORDER_POPUP_ID

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService
  ) {
    super($scope, $rootScope, ORDER_POPUP_SHOW_ACTION, ORDER_POPUP_ID)
  }

  $onInit (): void {
    this.setupPopupOpenEventListener((payload: IOrderPopupPayload): void => {
      this.businessId = payload.businessId
      this.orderId = payload.orderId
    })
  }

  onClose (): void {
    this.businessId = null
    this.orderId = null
  }
}

export default {
  templateUrl: require('./order-popup.pug'),
  controller: OrderPopupController
}
