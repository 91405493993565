import {StateService} from '@uirouter/core'
import envConfig from 'common/envConfig'
import Fleet from 'data/domain-objects/Fleet'
import Driver from 'data/domain-objects/Driver'
import Schedule from 'data/domain-objects/Schedule'
import comparator from 'data/common/comparator'
import BaseController from 'presentation/common/BaseController'
import driverCollection from 'data/collections/driverCollection'
import fleetsCollection from 'data/collections/fleetsCollection'
import {collectEntityFieldByIds} from 'data/common/collectByIds'
import scheduleCollection from 'data/collections/scheduleCollection'

class DriversDashboardController extends BaseController {

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }
  public allDrivers: Driver[] = []
  public filteredDrivers: Driver[] = []
  public lazyLoadedDrivers: Driver[] = []
  public filterByFleetsValue: string = ''
  public searchFieldValue: string = ''
  public fleetsMap: any = {}
  public schedulesMap: any = {}

  static sortDrivers (driverA: Driver, driverB: Driver): number {
    return comparator.string(driverA.fullName, driverB.fullName)
  }
  public onSearchFieldChange = (searchQuery: string): void => this.handleSearchFieldChange(searchQuery)
  public onFleetsFilterChange = (fleet: Fleet): void => this.handleFilterByFleetsChange(fleet)


  $onInit (): void {
    Promise.all([
      this.getSchedules(),
      this.getFleets()
    ]).then(() => this.sync())
  }

  getFleets (): Promise<void> {
    return fleetsCollection.getAllCached()
      .then((fleetsList: Fleet[]): void => this.fleetsMap = collectEntityFieldByIds(fleetsList))
      .catch((error: Error): void => this.logger.error('Cannot get fleets', error))
  }

  lazyLoadDrivers (): void {
    this.lazyLoadedDrivers = this.lazyLoadedDrivers.concat(this.filteredDrivers.splice(0, envConfig.driversDefaultPerPageLimit))
  }

  onSync (): Promise<void> {
    return driverCollection.getAll()
      .then((response: Driver[]): void => {
        this.allDrivers = response
        this.allDrivers.sort(DriversDashboardController.sortDrivers)
        this.handleFilterChange()
      })
      .catch((error: Error): void => this.logger.error('Cannot get drivers', error))
  }

  getSchedules (): Promise<void> {
    return scheduleCollection.getAll()
      .then((schedules: Schedule[]): void => this.schedulesMap = collectEntityFieldByIds(schedules))
      .catch((error: Error): void => this.logger.error('Cannot get schedules', error))
  }

  deleteDriver (params: {id: string}): Promise<void> {
    return driverCollection.delete(params.id)
      .catch((error: Error): void => this.logger.error('Cannot delete driver', error))
      .then(() => this.sync())
  }

  handleSearchFieldChange (searchQuery: string): void {
    this.searchFieldValue = searchQuery
    this.handleFilterChange()
  }

  handleFilterByFleetsChange (fleet: Fleet): void {
    this.filterByFleetsValue = fleet ? fleet.id : null
    this.handleFilterChange()

    if (!fleet) {
      this.$scope.$digest()
    }
  }

  handleFilterChange (): void {
    this.lazyLoadedDrivers = []
    this.filteredDrivers = this.allDrivers.filter((driver: Driver): boolean => this.isDriverMatchesSearchCriterias(driver))
    this.lazyLoadDrivers()
  }

  isDriverMatchesSearchCriterias (driver: Driver): boolean {
    let isDriverMatchesSearchQuery = true
    let isDriverMatchesFleet = true

    if (this.searchFieldValue) {
      const isDriverMatchesToSearchQueryByFullName = driver.fullName.toLocaleLowerCase().indexOf(this.searchFieldValue.toLocaleLowerCase()) >= 0
      const isDriverMatchesToSearchQueryByPhoneNumber = driver.phoneNumber.indexOf(this.searchFieldValue) >= 0
      isDriverMatchesSearchQuery = isDriverMatchesToSearchQueryByFullName || isDriverMatchesToSearchQueryByPhoneNumber
    }

    if (this.filterByFleetsValue) {
      isDriverMatchesFleet = driver.fleets.indexOf(this.filterByFleetsValue) >= 0
    }

    return isDriverMatchesSearchQuery && isDriverMatchesFleet
  }
}

export default {
  templateUrl: require('./drivers-dashboard.pug'),
  controller: DriversDashboardController
}
