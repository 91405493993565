import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

export default class Issue {
  constructor (
    public id: string = '',
    public type: string = '',
    public timestamp: string = '',
    public level: string = '',
    public delivery: any = {},
    public driver: any = {},
    public fleet: DBMappedNamedEntity = null,
    public resolver: any = null,
    public timestampResolved: string = ''
  ) {}

  static build (dto: any = {}): Issue {
    return new Issue(
      dto.id,
      dto.type,
      dto.timestamp,
      dto.level,
      dto.delivery,
      dto.driver,
      DBMappedNamedEntity.build(dto.fleet),
      dto.resolver,
      dto.timestampResolved
    )
  }
}
