import reduce from 'lodash-es/reduce'
import template from './days-picker.pug'

const ISO_DAY_VALUE_STRINGS = [
  '1', '2', '3', '4', '5', '6', '7'
]

class DaysPickerController {
  constructor () {
    this.dayIndices = ISO_DAY_VALUE_STRINGS
  }

  getDaysSelectedCount () {
    const daysSelectedByDayIndex = (this.model && typeof this.model === 'object' ? this.model : {})
    return reduce(daysSelectedByDayIndex, (accum, isDaySelected) => {
      return accum + (isDaySelected ? 1 : 0)
    }, 0)
  }
}

export default {
  templateUrl: template,
  replace: true,
  controller: DaysPickerController,
  bindings: {
    model: '=',
    form: '=',
    name: '@',
    backendValidationModel: '=',
    isEditing: '<',
    isRequired: '<',
    isDisabled: '<',
    title: '@',
    valueMessage: '@',
    placeholder: '@'
  }
}
