import localStorageService from 'data/services/localStorageService'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'

class PayoutsController {
  public isBankInfoFullyProvided: boolean = false
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)

  onBusinessSelected(business: any): void {
    if (business && business.businessId) {
      this.businessId = business.businessId
      localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)
    }
  }

  onBankInfoUpdated(bankId: string, accountType: string, accountNumber: string, registeredName: string): void {
    this.isBankInfoFullyProvided = !!(bankId && accountType && accountNumber && registeredName)
  }
}

export default {
  templateUrl: require('./payouts.pug'),
  controller: PayoutsController
}
