import { isSameDomain } from 'common/utils/urlUtils'
import envConfig from 'common/envConfig'

export default function setBasePageTitleAndFavicon (): void {
  const isTAZDomain = isSameDomain(envConfig.platformDomains.tazDeliveryDomain)

  if (isTAZDomain) {
    document.title = envConfig.platformTitles.tazDeliveryTitle
  } else {
    document.title = isSameDomain(envConfig.platformDomains.storefrontAdminDomain) ?
                     envConfig.platformTitles.baseAdminTitle :
                     envConfig.platformTitles.baseBusinessTitle
  }


  const head: HTMLHeadElement = document.querySelector('head')

  const url: string = isSameDomain(envConfig.platformDomains.storefrontAdminDomain) ?
                      envConfig.platformFavIcons.baseAdminFaviconUrl :
                      envConfig.platformFavIcons.baseBusinessFaviconUrl

  head.insertAdjacentHTML('beforeend', `<link rel="icon" type="image/x-icon" href="${url}">`)
}
