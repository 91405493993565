import templateUrl from './verify-address-settings.pug'
import get from 'lodash-es/get'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import addressVerificationService from 'presentation/address-verification/addressVerificationService'
import {SETTINGS_KEY_TEMPLATE_NAME, SETTINGS_KEY_CONFIGURATION} from 'presentation/address-verification/addressVerificationService'
import Logger from 'common/Logger'
import userModel from 'data/models/userModel'

const logger = new Logger('Verify Address Setting')

const SETTINGS_KEYS = [
  SETTINGS_KEY_TEMPLATE_NAME,
  SETTINGS_KEY_CONFIGURATION
]

class VerifyAddressSettings extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
    this.showSettings = false
    this.keyTmplName = SETTINGS_KEY_TEMPLATE_NAME
    this.keyConfiguration = SETTINGS_KEY_CONFIGURATION
    this.templatesList = addressVerificationService.getTemplatesList()
  }

  $onInit () {
    super.$onInit()

    userModel.getUser().then(user => {
      if (user.isSuperAdmin) {
        this.showSettings = true
        this.$scope.$digest()
      }
    })

    const value = get(this, `model[${SETTINGS_KEY_CONFIGURATION}].value`, null)

    if (value) {
      try {
        this.configuration = JSON.stringify(JSON.parse(value), null, '    ')
      } catch (e) {
        logger.error('Unable to JSON parse VAF configuration settings. Error: ', e, ' Original value', value)
      }
    }

    if (!this.configuration) {
      this.configuration = addressVerificationService.getDefaultConfiguration()
    }

    this.$scope.$watch('$ctrl.configuration', value => {
      if (value) {
        this.model[SETTINGS_KEY_CONFIGURATION] = {value, isOverridden: true}
        this.form.$setDirty()
      }
    })

    this.$scope.$watch('$ctrl.model.verifyAddressTemplate.value', value => {
      if (value && this.model[SETTINGS_KEY_TEMPLATE_NAME].value !== value) {
        this.model[SETTINGS_KEY_TEMPLATE_NAME].isOverridden = value !== addressVerificationService.getDefaultTemplate()
        this.form.$setDirty()
      }
    })

    if (!this.model[SETTINGS_KEY_TEMPLATE_NAME]) {
      this.model[SETTINGS_KEY_TEMPLATE_NAME] = {
        isOverridden: false,
        value: addressVerificationService.getDefaultTemplate()
      }
    }

    // hide this temporary
    this.hideCustomizationSettings = true
  }
}

export default {
  templateUrl,
  controller: VerifyAddressSettings,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
