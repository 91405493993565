import templateUrl from './driver-cash-limit.pug'
import Cost from 'data/domain-objects/Cost'

function driverCashLimitController () {
  const $ctrl = this

  function init () {
    $ctrl.currencyCode = 'KWD'
    $ctrl.getDecimalPointsCount = Cost.getDecimalPointsCountForCurrency
  }

  $ctrl.$onInit = init
}

export default {
  templateUrl,
  controller: driverCashLimitController,
  bindings: {
    model: '='
  }
}
