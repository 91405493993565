import envConfig from 'common/envConfig'
import Logger from 'common/Logger'

const logger = new Logger('Disabled Features Model')
const ENABLED_FEATURES_SESSION_KEY = 'apolloEnabledFeatures'

class FeaturesModel {
  private disabledFeatureNames: string[] = []
  private locallyEnabledFeatureNames: string[] = []

  constructor () {
    try {
      this.locallyEnabledFeatureNames = JSON.parse(window.sessionStorage.getItem(ENABLED_FEATURES_SESSION_KEY)) || []
    } catch (e) {
      this.locallyEnabledFeatureNames = []
    }

    this.disabledFeatureNames = envConfig.disabledFeatures
      .filter((featureName: string) => !this.locallyEnabledFeatureNames.includes(featureName))

    logger.info('Disabled Features are', this.disabledFeatureNames)
  }

  isFeatureEnabled (featureName: string): boolean {
    return !this.disabledFeatureNames.includes(featureName)
  }

  enableFeature(featureName: string): void {
    this.locallyEnabledFeatureNames.push(featureName)
    window.sessionStorage.setItem(ENABLED_FEATURES_SESSION_KEY, JSON.stringify(this.locallyEnabledFeatureNames))
    window.location.reload()
  }
}

const featuresModel = new FeaturesModel()
envConfig.features = featuresModel

export default featuresModel
