import permissionsModel from 'data/models/permissionsModel'
import templateUrl from './delete-dropdown.pug'
import {DIALOG_ID} from './deleteDialogController'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'

class deleteDropdown {
  constructor ($state) {
    this.$state = $state
  }

  $onInit () {
    this.deleteAllowed = permissionsModel.getHasPermission(this.permission)
  }

  view () {
    if (typeof this.onView === 'function') {
      this.onView(Object.assign({entityId: this.entityId}, this.payload))
    } else if (this.onViewState) {
      /**
       * onViewState is an array of the form
       * [ 'route-name', 'param1-key', 'param1-value' .. 'paramN-key', 'paramN-value']
       * so we parse that structure here and then use it for routing
       */
      const paramsArray = this.onViewState.slice()
      const route = paramsArray.shift()
      const params = {}

      for (let i = 0; i <= paramsArray.length; i += 2) {
        params[paramsArray[i]] = paramsArray[i + 1]
      }

      this.$state.go(route, params)
    }
  }

  delete () {
    modalDialogStateService.emitOpenModalDialog({
      dialogId: DIALOG_ID,
      entityId: this.entityId,
      payload: this.payload
    })
  }
}

export default {
  templateUrl,
  controller: deleteDropdown,
  transclude: true,
  bindings: {
    entityId: '@',
    permission: '@',
    onView: '&?', // you need to supply either onView callback
    onViewState: '<?', // OR a list of onViewState parameters
    payload: '=?'
  }
}
