import envConfig from 'common/envConfig'
import templateUrl from './delivery-charge-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import featuresModel from 'data/models/featuresModel'

const CONSUMER_OPTION = 'consumer'
const CONSUMER_OPTION_FEATURE_TOGGLE_KEY = 'CONSUMER_CHARGES'
const HIDE_VALUE = 'hide'

const SETTINGS_KEYS = [
  'showDeliveryCharge',
  'deliveryCharge'
]

class DeliveryChargeSettings extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)

    this.hideValue = HIDE_VALUE

    const deliveryChargeValues = envConfig.businessAndBranchSettings.deliveryChargeValues

    this.deliveryCharge = (!featuresModel.isFeatureEnabled(CONSUMER_OPTION_FEATURE_TOGGLE_KEY)) ?
      deliveryChargeValues.filter(value => value !== CONSUMER_OPTION) : deliveryChargeValues
  }
}

export default {
  templateUrl,
  controller: DeliveryChargeSettings,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
