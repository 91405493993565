import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Schedule from '../domain-objects/Schedule'

const scheduleService = new CrudService(endpoints.SCHEDULE)
const schedulesService = new CrudService(endpoints.SCHEDULES)

function scheduleCollectionFactory (scheduleService, schedulesService) {
  return {
    getAll: function () {
      return schedulesService.get()
        .then(schedules => schedules.map(schedule => Schedule.build(schedule)))
    },
    getById: function (scheduleId) {
      return scheduleService.get([scheduleId])
        .then(schedule => Schedule.build(schedule))
    },
    create: function (scheduleEntity) {
      return scheduleService.create(scheduleEntity)
        .then(schedule => Schedule.build(schedule))
    },
    update: function (scheduleId, scheduleEntity) {
      return scheduleService.update(scheduleEntity, [scheduleId])
        .then(schedule => Schedule.build(schedule))
    },
    'delete': function (scheduleId) {
      return scheduleService.delete([scheduleId])
    }
  }
}

export default scheduleCollectionFactory(scheduleService, schedulesService)
