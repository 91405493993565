import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import StorefrontPromoCode from 'data/domain-objects/storefront/StorefrontPromoCode'

const storefrontPromoCodeService = new CrudService(endpoints.STOREFRONT_PROMO_CODE)
const storefrontPromoCodesService = new CrudService(endpoints.STOREFRONT_PROMO_CODES)

export default {
  getAll: (businessId: string, q?: string): Promise<IRecordsAndMetadata<any>> =>
    storefrontPromoCodesService.get([businessId], q && q.length >= 2 ? {q} : {}),

  getById: (businessId: string, promoCodeId: string): Promise<StorefrontPromoCode> =>
    storefrontPromoCodeService.get([businessId, promoCodeId]).then(StorefrontPromoCode.build),

  create: (businessId: string, promoCode: StorefrontPromoCode): Promise<StorefrontPromoCode> =>
    storefrontPromoCodesService.create(promoCode, [businessId]).then(StorefrontPromoCode.build),

  update: (businessId: string, promoCodeId: string, promoCode: StorefrontPromoCode): Promise<StorefrontPromoCode> =>
    storefrontPromoCodeService.update(promoCode, [businessId, promoCodeId]).then(StorefrontPromoCode.build),

  delete: (businessId: string, promoCodeId: string): Promise<StorefrontPromoCode> =>
    storefrontPromoCodeService.delete([businessId, promoCodeId])
}
