import { StateService } from '@uirouter/angularjs'
import BaseController from 'presentation/common/BaseController'
import localStorageService from 'data/services/localStorageService'
import storefrontServedAreasCollection from 'data/collections/storefrontServedAreasCollection'
import BranchDeliveryTimeSettings from 'data/domain-objects/BranchDeliveryTimeSettings'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import AreaDeliveryTimeSettings from 'data/domain-objects/AreaDeliveryTimeSettings'
import cloneDeep from 'lodash-es/cloneDeep'
import uniqBy from 'lodash-es/uniqBy'
import BranchServedAreas from 'data/domain-objects/BranchServedAreas'
import BranchServedAreasSettings from 'data/domain-objects/BranchServedAreasSettings'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'

class DeliveryTimeController extends BaseController {
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public submitting: boolean = false
  public showError: boolean = false
  public showUpdateError: boolean = false
  public showConfirmation: boolean = false
  public areas: DBMappedNamedEntity[] = []
  public settings: BranchDeliveryTimeSettings[] = []
  public settingsMap: any = {}
  public defaultAreasSettings: BranchServedAreasSettings = {
    useDeliveryTimeMatrix: true
  } as BranchServedAreasSettings
  public areasSettings: BranchServedAreasSettings = {
    useDeliveryTimeMatrix: true
  } as BranchServedAreasSettings

  public deliveryTimeValidateRegexp: RegExp = /^\d{1,4}$/

  constructor($scope: ng.IScope, $state: StateService) {
    super($scope, $state)
  }

  $onInit(): void {
    if (this.businessId) {
      this.sync()
    }
  }

  onSync(): Promise<any> {
    this.showError = false

    return storefrontServedAreasCollection.getServedAreas(this.businessId)
      .then((response: BranchServedAreas): void => this.handleSettingsRecieved(response.servedAreas, response.settings))
      .catch((error: Error): void => {
        this.showError = true
        this.logger.error('Cannot get Storefront Delivery Time Settings', error)
      })
  }

  private handleSettingsRecieved(settings: BranchDeliveryTimeSettings[], areasSettings: BranchServedAreasSettings): void {
    const allAreas = []

    this.defaultAreasSettings = Object.assign({}, areasSettings)
    this.areasSettings = Object.assign({}, areasSettings)
    this.settings = settings

    this.settings.forEach((setting: BranchDeliveryTimeSettings): void => {
      this.settingsMap[setting.branch.id] = {}

      setting.values.forEach((areaSettings: AreaDeliveryTimeSettings): void => {
        allAreas.push(areaSettings.area)
        this.settingsMap[setting.branch.id][areaSettings.area.id] = {
          ...areaSettings.deliveryTime,
          isAuto: !areaSettings.deliveryTime.value || (areaSettings.deliveryTime.autoValue === areaSettings.deliveryTime.value)
        }
      })
    })

    this.areas = uniqBy(allAreas, (area: DBMappedNamedEntity): string => area.id)
  }

  private getSettingsModelToSubmit(): BranchDeliveryTimeSettings[] {
    const updatedSettings = cloneDeep(this.settings)

    updatedSettings.forEach((setting: BranchDeliveryTimeSettings): void => {
      setting.values.forEach((areaSettings: AreaDeliveryTimeSettings): void => {
        const newValue = parseInt(this.settingsMap[setting.branch.id][areaSettings.area.id].value)
        areaSettings.deliveryTime.value = isNaN(newValue) ? null : newValue
      })
    })

    return updatedSettings
  }

  public onBusinessSelected(business: any): void {
    if (business && business.businessId) {
      this.businessId = business.businessId
      localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)

      this.sync()
    }
  }

  public onAutoDeliveryTimeClicked(branchId: string, areaId: string, isAuto: boolean): void {
    if (isAuto) {
      this.settingsMap[branchId][areaId].value = null
    }
  }

  public resetForm(form: ng.IFormController): void {
    this.areas = []
    this.settingsMap = {}
    this.handleSettingsRecieved(this.settings, this.defaultAreasSettings)
    form.$setPristine()
  }

  public submit(form: ng.IFormController): void {
    this.submitting = true
    this.showUpdateError = false
    this.showConfirmation = false
    const settingsToSubmit = this.getSettingsModelToSubmit()

    storefrontServedAreasCollection.updateServedAreas(this.businessId, settingsToSubmit, this.areasSettings)
      .then((response: BranchServedAreas): void => {
        this.handleSettingsRecieved(response.servedAreas, response.settings)
        form.$setPristine()
        this.showConfirmation = true
      })
      .catch((error: Error): void => {
        this.showUpdateError = true
        this.logger.error('Cannot update Storefront Delivery Time Settings', error)
      })
      .then(() => {
        this.submitting = false
        this.$scope.$digest()
      })
  }

  public onAreasSettingsChange(settingName: string): void {
    this.areasSettings[settingName] = !this.areasSettings[settingName]
  }
}

export default {
  templateUrl: require('./delivery-time.pug'),
  controller: DeliveryTimeController
}
