import templateUrl from './delivery.pug'

class DeliveryController {
  constructor ($stateParams, $state) {
    this.$stateParams = $stateParams
    this.$state = $state
  }

  $onInit () {
    this.deliveryId = this.$stateParams.deliveryId || null
    this.isConsumer = this.$state.$current.name.indexOf('main.orders.order') > -1
  }
}

export default {
  templateUrl,
  controller: DeliveryController
}
