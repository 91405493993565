export default class ExportUtils {
  static exportCSV (fileName: string, data: any, columns: [{name: string}], columnTitles: string[]): void {
    // generating table with data
    const rows: string[] = []
    data.forEach((row: any) => {
      const cols: string[] = []

      if (Array.isArray(row)) {
        // accepted format: [[], [], []...]
        rows.push(row.join(','))
      } else {
        // accepted format: [{}, {}, {}...]
        columns.forEach((column: any) => {
          cols.push(row[column.name])
        })
        rows.push(cols.join(','))
      }
    })

    const dataURI: string = encodeURI('data:text/csv;charset=utf-8,' + columnTitles.join(',') + '\n' + rows.join('\n'))
    const link = document.createElement('a')
    link.setAttribute('href', dataURI)
    link.setAttribute('download', fileName)
    link.click()
  }
}
