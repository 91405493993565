import { StorefrontSubscriptionPlans } from 'common/constants/StorefrontSubscriptionPlans'

export default class StorefrontSubscription {
  constructor (
    public readonly activeUntilDate: Date = null,
    public readonly dateOfTermination: Date = null,
    public readonly nextPlanChargeDate: Date = null,
    public readonly trialEndDate: Date = null,
    public readonly plan: StorefrontSubscriptionPlans = null,
    private _isChargeFailed: boolean = false // BE sends `true` by default (sick!), so we have to wrap it with getter to determine if it actually failed
  ) { }

  get isChargeFailed(): boolean {
    return this._isChargeFailed && !!this.plan
  }

  get isCancelled(): boolean {
    return !!this.plan && !this.nextPlanChargeDate
  }

  public static build(data: any = {}): StorefrontSubscription {
    const activeUntilDate = !isNaN(Date.parse(data.activeUntilDate)) ? new Date(Date.parse(data.activeUntilDate)) : null
    const dateOfTermination = !isNaN(Date.parse(data.dateOfTermination)) ? new Date(Date.parse(data.dateOfTermination)) : null
    const nextPlanChargeDate = !isNaN(Date.parse(data.nextPlanChargeDate)) ? new Date(Date.parse(data.nextPlanChargeDate)) : null
    const trialEndDate = !isNaN(Date.parse(data.trialEndDate)) ? new Date(Date.parse(data.trialEndDate)) : null

    let plan = null
    if (Object.values(StorefrontSubscriptionPlans).includes(data.plan) || data.plan === null) {
      plan = data.plan
    }

    return new StorefrontSubscription(
      activeUntilDate,
      dateOfTermination,
      nextPlanChargeDate,
      trialEndDate,
      plan,
      !!data.isChargeFailed
    )
  }
}
