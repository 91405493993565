class FileInput {

  public file: File
  public fileChanged: (data: {file: File}) => void
  public showFileName: string
  public mimeTypes: string[] = []

  constructor(
    private $scope: ng.IScope,
    private $element: ng.IAugmentedJQuery
  ) {
    this.$scope.$watch('$ctrl.file', (newValue: any, oldValue: any) => {
      if (newValue !== oldValue) {
        this.fileChanged({file: this.file})
      }
    })
  }

  public onSelectFileButtonClicked(): void {
    this.$element.find('input')[0].click()
  }
}

export default {
  templateUrl: require('./file-input.pug'),
  controller: FileInput,
  bindings: {
    fileChanged: '&',
    showFileName: '<',
    mimeTypes: '<',
    buttonText: '@',
    isDisabled: '<'
  }
}
