import LocationPoint from './LocationPoint'

export default class LastKnownLocation {
  constructor(
    public location: LocationPoint = null,
    public atUtc: Date
  ) { }

  public static build(dto: any = {}): LastKnownLocation {
    return new LastKnownLocation(
      dto.location ? LocationPoint.build(dto.location) : null,
      new Date(dto.atUtc)
    )
  }
}

