export default class LogisticsSettings {
  constructor (
    public showWorkingDrivers: boolean = false,
    public showDriversAcceptedHail: boolean = false,
    public showAvailableDrivers: boolean = false,
    public showNotAvailableDrivers: boolean = false,
    public showDriversWithIssues: boolean = false
  ) {}

  static build (dto: any = {}): LogisticsSettings {
    return new LogisticsSettings(
      typeof dto.showWorkingDrivers !== 'undefined' ? dto.showWorkingDrivers : true,
      typeof dto.showDriversAcceptedHail !== 'undefined' ? dto.showDriversAcceptedHail : true,
      typeof dto.showAvailableDrivers !== 'undefined' ? dto.showAvailableDrivers : true,
      typeof dto.showNotAvailableDrivers !== 'undefined' ? dto.showNotAvailableDrivers : true,
      typeof dto.showDriversWithIssues !== 'undefined' ? dto.showDriversWithIssues : true
    )
  }
}
