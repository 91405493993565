
import envConfig from 'common/envConfig'

class EntityImageController {

  public defaultWidth: number = envConfig.defaultEntityImageWidth
  public defaultHeight: number = envConfig.defaultEntityImageHeight

  public imageId: string
  public imageUrl: string
  public iconClass: string
  public customClass: string

  $onChanges (changes: ng.IOnChangesObject): void {
    if (changes.imageUrl && this.imageUrl) {
      this.imageUrl = `${this.imageUrl}/${this.defaultWidth}x${this.defaultHeight}`
    }
  }
}

export default {
  templateUrl: require('./entity-image.pug'),
  controller: EntityImageController,
  bindings: {
    defaultWidth: '@?',
    defaultHeight: '@?',
    imageUrl: '<',
    iconClass: '@',
    customClass: '@'
  }
}
