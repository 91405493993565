import templateUrl from './warning-message.pug'

export default {
  templateUrl,
  bindings: {
    iconUrl: '@',
    title: '@',
    message: '@',
    noBottomSpacing: '<'
  }
}
