import templateUrl from './combined-text-input.pug'

const DEFAULT_MAX_LENGTH = 128
const ENTER_KEYCODE = 13

class CombinedTextInputController {
  constructor ($scope, $element, $timeout) {
    this.$scope = $scope
    this.$element = $element
    this.$timeout = $timeout
    this.onBlur = () => this.handleOnBlur()
    this.defaultMaxLength = DEFAULT_MAX_LENGTH
  }

  $onInit () {
    this.currentType = this.type === 'number' ? 'text' : this.type

    if (this.type === 'number') {
      this.$scope.$watch('$ctrl.decimalPointsCount', () => this.fitDecimalPointsFormat())
      this.fitDecimalPointsFormat()
    }

    // We need to wrap it in $timeout because of specific algorythm of how ngIf directive works
    // Otherwise since we have all inputs inside this component having ngIf we'll always get null trying to get their DOM nodes
    // You can find detailed explanation here: https://stackoverflow.com/a/34286383
    this.$timeout(() => {
      const DOMSelectElement = this.$element.find('input')[0]
      if (this.focusOnInit && DOMSelectElement && DOMSelectElement.focus) {
        DOMSelectElement.focus()
      }
    })
  }

  fitDecimalPointsFormat () {
    this.isNumber = true
    this.onBlur()
  }

  togglePasswordVisible () {
    this.currentType = (this.currentType === 'password' ? 'text' : 'password')
  }

  handleOnBlur () {
    if (this.isNumber) {
      this.conditionallyCastToDecimal()
    }

    if (this.onElementBlur) {
      this.onElementBlur()
    }
  }

  conditionallyCastToDecimal () {
    if (this.model !== null) {
      let number = parseFloat(this.model)
      number = isNaN(number) ? 0 : number
      if (number > this.maxNumber) {
        number = this.maxNumber
      }

      this.model = Math.abs(number).toFixed(this.decimalPointsCount)
    }
  }

  onKeyPress ($event) {
    if ($event && $event.keyCode === ENTER_KEYCODE) {
      if (this.preventEnterSubmit) {
        $event.preventDefault()
      }
      this.handleOnBlur()
    }
  }
}

export default {
  templateUrl,
  controller: CombinedTextInputController,
  transclude: true,
  bindings: {
    backendValidationModel: '=',
    form: '=',
    icon: '@?',
    isDisabled: '<?',
    isRequired: '<?',
    label: '@',
    minlength: '=?',
    maxlength: '=?',
    model: '=',
    name: '@',
    placeholder: '@?',
    prefix: '@?',
    suffix: '@?',
    type: '@',
    decimalPointsCount: '<?',
    regexValidatePattern: '<?',
    preventEnterSubmit: '<?',
    onElementBlur: '&?',
    onChange: '&?',
    focusOnInit: '<?',
    hostClass: '@?',
    maxNumber: '<',
    customValidationErrorMessage: '@?',
    noBottomMargin: '<',
    textDirection: '@?',
    hideOptional: '<?'
  }
}
