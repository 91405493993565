import BranchDeliveryTimeSettings from './BranchDeliveryTimeSettings'
import BranchServedAreasSettings from './BranchServedAreasSettings'

export default class BranchServedAreas {
  constructor(
    public settings: BranchServedAreasSettings,
    public servedAreas: BranchDeliveryTimeSettings[]
  ) { }

  static build(dto: any): BranchServedAreas {
    return new BranchServedAreas(
      dto.settings || {},
      (dto.servedAreas || []).map(BranchDeliveryTimeSettings.build)
    )
  }
}
