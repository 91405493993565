/* eslint-disable no-magic-numbers */
export class KanbanFiltersSettings {
  constructor (
    public deliveriesType: string = 'all',
    public businessId: string = '',
    public branchId: string = '',
    public fleetId: string = null,
    public pickupArea: string = null,
    public dropoffArea: string = null,
    public searchQuery: string = ''
  ) {}

  static build (dto: any = {}): KanbanFiltersSettings {
    return new KanbanFiltersSettings(
      dto.deliveriesType,
      dto.businessId,
      dto.branchId,
      dto.fleetId,
      dto.pickupArea,
      dto.dropoffArea,
      dto.searchQuery
    )
  }
}

class KanbanCardsSettings {
  constructor (
    public driver_name: boolean = true,
    public driver_phone: boolean = true,
    public branch: boolean = true,
    public area: boolean = true,
    public draewil_id: boolean = true,
    public pos: boolean = true,
    public estimations: boolean = true,
    public price: boolean = true,
    public recepient_name: boolean = true,
    public business: boolean = false
  ) {}

  static build (dto: any = {}): KanbanCardsSettings {
    return new KanbanCardsSettings(
      dto.driver_name,
      dto.driver_phone,
      dto.branch,
      dto.area,
      dto.draewil_id,
      dto.pos,
      dto.estimations,
      dto.price,
      dto.recepient_name,
      dto.business
    )
  }
}

class KanbanBoardSettings {
  constructor (
    public columnSize: number = 30,
    public period: number = 24,
    public cardSize: number = 3,
    public hiddenColumns: string[] = [],
  ) {}

  static build (dto: any = {}): KanbanBoardSettings {
    return new KanbanBoardSettings(
      dto.columnSize,
      dto.period,
      dto.cardSize,
      dto.hiddenColumns && dto.hiddenColumns.slice(0) || []
    )
  }
}

class KanbanMapSettings {
  constructor (public hiddenStatuses: string[] = []) {}

  static build (dto: any = {}): KanbanMapSettings {
    return new KanbanMapSettings(
      dto.hiddenStatuses && dto.hiddenStatuses.slice(0) || []
    )
  }
}

export default class KanbanSettings {
  constructor (
    public filters: KanbanFiltersSettings,
    public board: KanbanBoardSettings,
    public cards: KanbanCardsSettings,
    public map: KanbanMapSettings
  ) {}

  static build (dto: any = {}): KanbanSettings {
    const filtersDTO = dto.filters || {}
    const filters = new KanbanFiltersSettings(
      filtersDTO.deliveriesType,
      filtersDTO.businessId,
      filtersDTO.branchId,
      filtersDTO.fleetId,
      filtersDTO.pickupArea,
      filtersDTO.dropoffArea
    )

    const boardDTO = dto.board || {}
    const board = new KanbanBoardSettings(
      boardDTO.columnSize,
      boardDTO.period,
      boardDTO.cardSize,
      boardDTO.hiddenColumns
    )

    const cardsDTO = dto.cards || {}
    const cards = new KanbanCardsSettings(
      cardsDTO.driver_name,
      cardsDTO.driver_phone,
      cardsDTO.branch,
      cardsDTO.area,
      cardsDTO.draewil_id,
      cardsDTO.pos,
      cardsDTO.estimations,
      cardsDTO.price,
      cardsDTO.recepient_name,
      cardsDTO.business
    )

    const mapDTO = dto.map || {}
    const map = new KanbanMapSettings(
      mapDTO.hiddenStatuses
    )

    return new KanbanSettings(
      filters,
      board,
      cards,
      map
    )
  }
}
