import moment from 'moment'
import config from './constants'
import entitiesModel from 'data/models/entitiesModel'
import Branch from './Branch'
import Fleet from './Fleet'

interface IRole {
  roleId: string,
  scopes: any[]
}

export default class TeamMember {

  constructor (
    public countryDiallingCode: string = '',
    public email: string = '',
    public id: string = '',
    public phoneNumber: string = '',
    public user: any = null,
    public roleTypesById: any,
    public business: any[] = [],
    public branches: Branch[] = [],
    public fleets: Fleet[] = [],
    public roles: any[] = [],
    public createdBySummary: string = '',
    public createdOnFormatted: string = ''
  ) {}

  static build(teamMemberDTO: any = {}, roleTypesById: any = {}, business: any[] = [], branches: Branch[], fleets: Fleet[]): TeamMember {


    const getEntityNameByTypeWithId = (entityType: string, entityId: string): any => {
      if (entityType === entitiesModel.ENTITIES.BRANCH && branches.hasOwnProperty(entityId)) {
        return branches[entityId]
      }
      if (entityType === entitiesModel.ENTITIES.BUSINESS && business.hasOwnProperty(entityId)) {
        return business[entityId]
      }
      if (entityType === entitiesModel.ENTITIES.FLEET && fleets.hasOwnProperty(entityId)) {
        return fleets[entityId]
      }
      return ''
    }

    const roles = (teamMemberDTO.roles || []).map((role: any) => {
      role.name = roleTypesById[role.roleId]
      role.scopes.forEach((scope: any) => {
        let entityNames = []
        entityNames = scope.scopedEntityIds.map((id: string) => {
          const entityName = getEntityNameByTypeWithId(scope.entityName, id)
          return entityName
        })
        scope.entityNames = entityNames
      })
      return role
    })

    let createdBySummary = ''
    let createdOnFormatted = ''

    if (teamMemberDTO.createdBy) {
      createdBySummary = `${teamMemberDTO.createdBy.firstName} ${teamMemberDTO.createdBy.lastName}  (${teamMemberDTO.createdBy.email}) `
      createdOnFormatted = moment(teamMemberDTO.createdBy.createdAt).format(config.defaultDateFormat)
    }

    return new TeamMember(
      teamMemberDTO.countryDiallingCode || '',
      teamMemberDTO.email || '',
      teamMemberDTO.id || '',
      teamMemberDTO.phoneNumber || '',
      teamMemberDTO.user || null,
      roleTypesById,
      business,
      branches,
      fleets,
      roles,
      createdBySummary,
      createdOnFormatted
    )
  }

  addRole (roleId: string, scopes: any = []): void {
    const newRole = {
      roleId,
      scopes
    }
    const roleIndex = this.roles.findIndex((role: IRole) => role.roleId === roleId)
    if (roleIndex >= 0) {
      this.roles[roleIndex] = newRole
    } else {
      this.roles.push(newRole)
    }
  }

  removeRole (roleId: string): void {
    const roleIndex = this.roles.findIndex((role: IRole) => role.roleId === roleId)
    if (roleIndex >= 0) {
      this.roles.splice(roleIndex, 1)
    }
  }

  toPostJson(): any {
    return {
      ...this,
      roleTypesById: undefined,
      business: undefined,
      branches: undefined,
      fleets: undefined
    }
  }
}
