import templateUrl from './image-picker.pug'

export default {
  templateUrl,
  bindings: {
    imageUrl: '<',
    isEditing: '<',
    isRequired: '<', // optional
    buttonText: '@',
    defaultIcon: '@', // optional
    helpMessage: '@', // optional
    onButtonClick: '&' // optional
  }
}
