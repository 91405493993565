import moment from 'moment'
import localeHelper from 'common/localeHelper'

export default function timeToFilter () {
  function timeTo (time, preventArabicTranslation = false) {
    const currentLanguage = localeHelper.getLanguage()
    if (preventArabicTranslation) {
      moment.locale('en')
    }
    const result = moment().to(moment(time))
    if (preventArabicTranslation) {
      moment.locale(currentLanguage)
    }
    return result
  }

  return timeTo
}
