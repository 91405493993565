import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const driverCashBalanceService = new CrudService(endpoints.DRIVER_CASH_BALANCE)

export function driverCashBalanceCollectionFactory (driverCashBalanceService) {
  return {
    get: function (driverId, filters) {
      return driverCashBalanceService.get([driverId], filters)
    }
  }
}

export default driverCashBalanceCollectionFactory(driverCashBalanceService)
