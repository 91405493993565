import DateUtils from 'common/utils/dateUtils'

export default class StorefrontGoodsPreparationTime {

  constructor(
    public actualTime: Date,
    public estimatedTime: Date
  ) { }

  public static build(data: any = {}): StorefrontGoodsPreparationTime {
    return new StorefrontGoodsPreparationTime(
      data.actualTime ? DateUtils.makeSafeDateFromString(data.actualTime) : null,
      data.estimatedTime ? DateUtils.makeSafeDateFromString(data.estimatedTime) : null,
    )
  }
}
