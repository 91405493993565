import Tariff from 'data/domain-objects/Tariff'
import templateUrl from './fleet-tariffs-create.pug'
import fleetsCollection from 'data/collections/fleetsCollection'

class FleetTariffsCreate {
  constructor ($scope, $state, $stateParams) {
    this.$scope = $scope
    this.$state = $state
    this.targetState = $stateParams.targetState
    this.fleetId = $stateParams.fleetId
    this.fleet = null
    this.tariff = Tariff.build({})
  }

  $onInit () {
    fleetsCollection.get(this.fleetId)
      .then(fleet => {
        this.fleet = fleet
        this.$scope.$digest()
      })
  }

  onSubmitSuccess () {
    this.$state.go(this.$scope.targetState)
  }
}

export default {
  templateUrl,
  controller: FleetTariffsCreate
}
