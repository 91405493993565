import DeliveryDataService from 'data/common/deliveryDataService'
import BasePopupController from 'presentation/common/BasePopupController'

export default class ChangeDriverBaseController extends BasePopupController {
  constructor ($scope, $rootScope, actionEvent, popupId) {
    super($scope, $rootScope, actionEvent, popupId)

    this.drivers = []
    this.localDrivers = []
    this.selectedDriver = null
    this.driverAutocompleteModel = ''
    this.changeDriverFailed = false
    this.driversOnMap = []
    this.dialogId = popupId // could be re-defined

    this.setupPopupOpenEventListener(payload => this.onPopupOpen(payload))
  }

  onPopupOpen () {} // could be re-defined

  onClose () {
    this.localDrivers = []
    this.selectedDriver = null
    this.driverAutocompleteModel = ''
  }

  beforeLoadAvailableDrivers () { // could be re-defined
    return Promise.resolve([])
  }

  loadAvailableDrivers () {
    this.isLoading = true
    this.beforeLoadAvailableDrivers()
      .then(drivers => {
        this.availableDrivers = drivers || []
        this.localDrivers = drivers.map(driver => this.getDriverWithDescriptor(driver))
        if (this.localDrivers.length === 1) {
          this.onDriverPickedFromSuggester(this.localDrivers[0])
        }
      })
      .catch(err => this.logger.error('Cannot get available drivers to transfer', err))
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  getDriverWithDescriptor (driver) {
    let suggestionText = `${driver.fullName} (+${driver.countryDiallingCode} ${driver.phoneNumber})`

    if (driver.activeJob) {
      const jobDescription = this.getDriverJobDescriptor(driver.activeJob)

      if (jobDescription) {
        suggestionText = `${suggestionText} - ${jobDescription}`
      }
    }

    return Object.assign({}, driver, {description: suggestionText})
  }

  getDriverJobDescriptor (driverJob) {
    if (driverJob.deliveries) {
      return driverJob.deliveries
        .map(delivery => `${delivery.ref} - POS: ${delivery.posReference || '—'} (${delivery.dropoffAreaName || '—'})`)
        .join(', ')
    } else {
      return ''
    }
  }

  onDriverPickedFromSuggester (suggestedDriver) {
    this.selectedDriver = suggestedDriver
    this.driversOnMap = [this.selectedDriver]
    this.driverAutocompleteModel = suggestedDriver ? suggestedDriver.description : ''
  }

  onClearDriverSuggester () {
    this.onDriverPickedFromSuggester()
  }

  onCancelButtonClick () {
    this.close()
  }

  beforeChangeDriver () { // could be re-defined
    return Promise.resolve({})
  }

  changeDriver () {
    this.isSubmitting = true
    this.changeDriverFailed = false

    this.beforeChangeDriver()
      .then(() => this.close())
      .catch(error => {
        this.changeDriverFailed = true
        this.handleTransferDeliveryToDriverFailed(error)
        this.onConfirmFailed({error})
      })
      .then(() => {
        this.isSubmitting = false
        DeliveryDataService.publishDeliverySyncEvent()
        this.onConfirm()
        this.$scope.$digest()
      })
  }

  handleTransferDeliveryToDriverFailed () {} // could be re-defined
}
