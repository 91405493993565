import union from 'lodash-es/union'
import difference from 'lodash-es/difference'
import intersection from 'lodash-es/intersection'
import Address from 'data/domain-objects/Address'
import addressCollection from 'data/collections/addressCollection'
import Logger from 'common/Logger'
import AddressFormat from 'data/domain-objects/AddressFormat'
import AddressField from 'data/domain-objects/AddressField'
import AddressFieldDefinition from 'data/domain-objects/AddressFieldDefinition'
import toUpperSnakeCase from 'common/toUpperSnakeCase'
import { ADDRESS_EDITOR_OPEN_FIND_ADDRESS_EVENT } from 'common/constants/PopupEvents'
import locationsService from 'data/services/locationsService'
import debounce from 'lodash-es/debounce'
import LocationPoint from 'data/domain-objects/LocationPoint'
import envConfig from 'common/envConfig'

const logger = new Logger('Address Editor')

const PREDEFINED_SUGGESTION_CLASSNAME = 'c-autocomplete-input_suggestion--predefined'
const LAST_PREDEFINED_SUGGESTION_CLASSNAME = 'c-autocomplete-input_suggestion--predefined-last'
export const BASE_ADDRESS_FORM_NAME = 'addressForm'

const buildSuggestion = (id: string = '', name: string = '', className: string = ''): ISuggestion => {
  return {
    id,
    name,
    className
  }
}

class AddressEditorController {
  /* Bindings START */
  public address: Address
  public isEditing: boolean
  public addressFormatId: string
  public location: LocationPoint
  public localeCode: string
  public mapIsHidden: boolean
  public mapIsCollapsed: boolean
  public preDefinedValues: {[fieldName: string]: string[]} = {}
  public limitToPreDefinedValues: boolean = false
  public onAddressFormatIdChanged: (event: { addressFormatId: string }) => {}
  public onAddressLocationChanged: (event: { addressLocation: LocationPoint }) => {}
  /* Bindings END */

  public addressForm: ng.IFormController
  private addressFormats: AddressFormat[] = []
  public currentAddressFormat: AddressFormat // should not be initilized on this step because this is the semaphore for race condition
  private allDistinctAddressFields: AddressField[] = []
  private defaultAddressFormatId: string

  private mapOfAddressFieldsAvailableValues: {[fieldName: string]: string[] | ISuggestion[]} = {}

  public addressFormatIds: string[]
  public formatTypeKeys: string[]

  public paciCode: string = ''
  public locationHistory: LocationPoint[]
  private geolocationRequests: number[] = []
  public addressLocation: LocationPoint = new LocationPoint(
    envConfig.locations.defaultLocationLatitude,
    envConfig.locations.defaultLocationLongitude
  )

  // when you call this wrapper the function inside will be called after the delay time
  // but if you call this wrapper once again BEFORE the delay time pass,
  // the previouse function call will be cancelled and call delay interval will start over again
  private debouncedGetLocation: any = debounce(() => this.getAddressGeocodedLocation(), 200)

  private VALIDITY_LOADING_LOCATION: string = 'VALIDITY_LOADING_LOCATION'
  private VALIDITY_IS_VALID_LOCATION: string = 'VALIDITY_IS_VALID_LOCATION'
  public ADDRESS_FORM_NAME: string = ''

  constructor(private $scope: ng.IScope) {}

  get showMap(): boolean {
    return !this.mapIsHidden || (this.currentAddressFormat && this.currentAddressFormat.type.toUpperCase() === 'POINT ON MAP')
  }

  /* ------ LIFECYCLE HOOKS START --------- */
  $onInit(): void {
    this.ADDRESS_FORM_NAME = `addressForm${this.localeCode ? '_' + this.localeCode : ''}`
    logger.info('Start address editor initialization...')
  }

  $onChanges(simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.hasOwnProperty('address')) {
      if (this.currentAddressFormat) {
        this.initAddress()
      }
    }

    if (simpleChanges.hasOwnProperty('isEditing')) {
      if (this.address) {
        this.setLocationHistoryInitialState()
      }
    }

    if (simpleChanges.hasOwnProperty('addressFormatId') && !simpleChanges.addressFormatId.isFirstChange() && this.addressFormatId) {
      this.changeAddressFormatId(this.addressFormatId)
    }

    if (simpleChanges.hasOwnProperty('location') && !simpleChanges.location.isFirstChange() && this.location) {
      this.addressLocation = LocationPoint.build(this.location)
    }
  }

  $postLink(): void {
    // attach the form to controller
    this.addressForm = this.$scope[this.ADDRESS_FORM_NAME]
    addressCollection.getAddressFormats()
      .then((addressFormats: AddressFormat[]) => {
        if (!addressFormats.length) {
          throw new Error('Please define at least one address format')
        }
        this.addressFormats = addressFormats
        this.addressFormatIds = addressFormats.map((format: AddressFormat): string => format.id)
        this.defaultAddressFormatId = this.addressFormatIds[0]
        this.formatTypeKeys = addressFormats.map((format: AddressFormat): string => toUpperSnakeCase(format.type))

        this.initAddress()
      })
      .catch((error: any) => {
        logger.error('Cannot get address formats: ', error)
      })
      .then(() => {
        this.$scope.$digest()
      })
  }
  /* ------ LIFECYCLE HOOKS END --------- */

  /* ------ ADDRESS FIELDS MANIPULATION START --------- */
  private initAddress(): void {
    this.address.addressFormatId = this.address.addressFormatId || this.defaultAddressFormatId
    this.currentAddressFormat = this.addressFormats.find((addressFormat: AddressFormat) => addressFormat.id === this.address.addressFormatId)
    this.address.type = this.currentAddressFormat.type
    if (!isNaN (this.address.location.latitude) && !isNaN(this.address.location.longitude)) {
      this.addressLocation = LocationPoint.build(this.address.location)
      this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, true, null)
    } else {
      this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, false, null)
    }
    setTimeout(() => {
      this.$scope.$apply()
    }, 0)

    this.initAddressFields()
    this.updateListFieldsSuggestions()
    this.setLocationHistoryInitialState()
  }

  private initAddressFields(): void {
    // build this.allDistinctAddressFields
    this.allDistinctAddressFields = []
    for (const addressFormat of this.addressFormats) {
      for (const addressFieldDefinition of addressFormat.fields) {
        const matchField = this.allDistinctAddressFields.find((addressField: AddressField) => addressField.name === addressFieldDefinition.name)
        if (!matchField) {
          const addressField = new AddressField(addressFieldDefinition.id, addressFieldDefinition.name)
          addressField.fieldDefinition = AddressFieldDefinition.build(addressFieldDefinition)
          this.allDistinctAddressFields.push(addressField)
        }
      }
    }
    this.mapInputAddressFieldsValuesToAllDistinctAddressFields()
    this.switchAddressFields()
  }

  private mapInputAddressFieldsValuesToAllDistinctAddressFields(): void {
    for (const addressField of this.allDistinctAddressFields) {
      const fieldIndex = this.address.fields.findIndex((field: AddressField) => field.name === addressField.name)
      if (fieldIndex >= 0) {
        addressField.value = this.address.fields[fieldIndex].value
      }
    }
  }

  private switchAddressFields(): void {
    // On this step we push proper address fields from this.allDistinctAddressFields to this.address.fields
    // Also we set presentation variables here
    this.currentAddressFormat.presentation.mapOnTop = this.currentAddressFormat.type.toUpperCase() === 'POINT ON MAP'

    this.address.fields = []

    for (const addressFormatField of this.currentAddressFormat.fields) {
      const fieldIndex = this.allDistinctAddressFields.findIndex((field: AddressField) => field.name === addressFormatField.name)
      if (fieldIndex >= 0) {

        let displayInline = false

        if (this.currentAddressFormat.type.toUpperCase() === 'HOUSE') {
          displayInline = addressFormatField.name !== 'area' && addressFormatField.name !== 'additionalDirections'
        } else if (this.currentAddressFormat.type.toUpperCase() === 'APARTMENT') {
          displayInline = addressFormatField.name !== 'area'
        } else if (this.currentAddressFormat.type.toUpperCase() === 'OFFICE') {
          displayInline = addressFormatField.name !== 'area'
        } else if (this.currentAddressFormat.type.toUpperCase() === 'POINT ON MAP') {
          displayInline = addressFormatField.name !== 'area'
        }

        this.allDistinctAddressFields[fieldIndex].fieldDefinition.presentation.displayInline = displayInline
        this.allDistinctAddressFields[fieldIndex].fieldDefinition.required = addressFormatField.required

        // on the step when we have built this.allDistinctAddressFields we shuffled ids of fields, so we need to set proper ids as well
        // this is need for getting proper suggestions for address fields
        this.allDistinctAddressFields[fieldIndex].formatFieldId = addressFormatField.id
        this.address.fields.push(this.allDistinctAddressFields[fieldIndex])
      }
    }
  }
  /* ------ ADDRESS FIELDS MANIPULATION END --------- */

  /* ------- COMPONENT EVENTS HANDLERS START ----------- */
  public changeAddressFormatId(addressFormatId: string): void {
    this.currentAddressFormat = this.addressFormats.find((addressFormat: AddressFormat) => addressFormat.id === addressFormatId)
    this.address.type = this.currentAddressFormat.type
    if (this.currentAddressFormat.type.toUpperCase() === 'POINT ON MAP') {
      this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, true, null)
      this.addressForm.$setValidity(this.VALIDITY_LOADING_LOCATION, true, null)
      this.address.location = LocationPoint.build(this.addressLocation)
      this.geolocationRequests = []
    } else {
      if (!isNaN(this.address.location.latitude) && !isNaN(this.address.location.longitude)) {
        this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, true, null)
      } else {
        this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, false, null)
      }
    }
    this.switchAddressFields()

    this.onAddressFormatIdChanged({addressFormatId})
  }

  public onListValueChanged(field: AddressField, value: ISuggestion): void {
    field.value = value === null ? null : value.name

    this.updateListFieldsSuggestions()
    this.addressForm.$setValidity(this.VALIDITY_LOADING_LOCATION, false, null)
    setTimeout(() => {
      this.geocodeAddress()
    }, 0)
  }

  public onInputValueChanged(): void {
    this.addressForm.$setValidity(this.VALIDITY_LOADING_LOCATION, false, null)
    setTimeout(() => {
      this.geocodeAddress()
    }, 0)
  }

  private geocodeAddress(): void {
    if (
      this.addressForm.$valid
      || (!this.addressForm.$valid
          && (
              (
                Object.keys(this.addressForm.$error).length === 1
                && this.addressForm.$error.hasOwnProperty(this.VALIDITY_LOADING_LOCATION)
              )
              ||
              (
                Object.keys(this.addressForm.$error).length === 1
                && this.addressForm.$error.hasOwnProperty(this.VALIDITY_IS_VALID_LOCATION)
              )
              ||
              (
                Object.keys(this.addressForm.$error).length === 2
                && this.addressForm.$error.hasOwnProperty(this.VALIDITY_LOADING_LOCATION)
                && this.addressForm.$error.hasOwnProperty(this.VALIDITY_IS_VALID_LOCATION)
              )
            )
      )
    ) {
      this.addressForm.$setValidity(this.VALIDITY_LOADING_LOCATION, false, null)
      setTimeout(() => {
        this.$scope.$apply()
      })
      this.debouncedGetLocation()
    }
  }

  public onLocationEdited(newLocation: LocationPoint): void {
    this.locationHistory.push(LocationPoint.build(this.address.location))
    this.addressLocation = LocationPoint.build(newLocation)
    this.address.location = LocationPoint.build(newLocation)
    this.onAddressLocationChanged({addressLocation: this.addressLocation})
    this.$scope.$apply() // as far as we change input object which may be used somewhere else we need to trigger rootScope digest
  }

  public setLastLocation(): void {
    if (this.locationHistory.length > 1) {
      this.address.location = this.locationHistory.pop()
      this.onAddressLocationChanged({addressLocation: this.address.location})
    }
  }

  public resetLocation(): void {
    if (this.locationHistory.length > 1) {
      this.address.location = LocationPoint.build(this.locationHistory[0])
      this.setLocationHistoryInitialState()
      this.onAddressLocationChanged({addressLocation: this.address.location})
    }
  }

  public openFindAddressPopUp(): void {
    this.$scope.$emit(ADDRESS_EDITOR_OPEN_FIND_ADDRESS_EVENT)
  }

  public onAddressAccepted(acceptedAddress: Address): void {
    this.address.fields = acceptedAddress.fields
    this.address.addressFormatId = acceptedAddress.addressFormatId
    this.address.location = acceptedAddress.location
    this.initAddress()
    this.onAddressFormatIdChanged({addressFormatId: this.address.addressFormatId})
    this.onAddressLocationChanged({addressLocation: this.address.location})
  }
  /* ------- COMPONENT EVENTS HANDLERS END ----------- */

  public getListFieldSelectedId(field: AddressField): number {
    const availableValues = this.mapOfAddressFieldsAvailableValues[field.name] || []
    const selectedIndex = availableValues.findIndex((availableValue: string | ISuggestion) => {
      if (typeof availableValue === 'string') {
        return availableValue === field.value
      }
      return availableValue.name === field.value
    })
    return (typeof selectedIndex !== 'undefined') && selectedIndex > -1 ? selectedIndex : null
  }

  private setLocationHistoryInitialState(): void {
    this.locationHistory = [this.address.location]
  }

  /* -------- SERVER FETCH FUNCTIONS START ---------- */
  private updateListFieldsSuggestions(): void {
    for (const addressField of this.address.fields) {
      if (addressField.fieldDefinition.type === 'list') {
        const queryParams: {[fieldName: string]: string} = {}
        addressField.fieldDefinition.dependsOn.forEach((fieldName: string) => {

          const dependsOnField: AddressField = this.address.fields.find((field: AddressField) => field.name === fieldName)
          if (dependsOnField && dependsOnField.value) {
            queryParams[fieldName] = dependsOnField.value
          } else if (dependsOnField && !dependsOnField.value) {
            // Clear suggestions because we cannot choose anything in case if there is no value in dependsOn field
            this.mapOfAddressFieldsAvailableValues[addressField.name] = []
          }
        })

        if (
            (!addressField.fieldDefinition.dependsOn.length && !addressField.value)
            ||
            Object.keys(queryParams).length === addressField.fieldDefinition.dependsOn.length
        ) {
          this.fetchFieldValues(addressField, queryParams)
        }
      }
    }
  }

  private fetchFieldValues(field: AddressField, queryParams: {[fieldName: string]: string}): Promise <void> {
    return addressCollection.getAddressFields(this.currentAddressFormat.id, field.formatFieldId, queryParams, this.localeCode)
      .catch((error: Error) => {
        logger.error('Can\'t fetch addressField values', error)
      })
      .then((values: any[]) => {
        let processedValues: string[] | ISuggestion[] = []

        if (this.preDefinedValues && this.preDefinedValues[field.name]) {

          const fieldPredefinedValues = Object.assign([], this.preDefinedValues[field.name])

          if (this.limitToPreDefinedValues) {
            processedValues = intersection(fieldPredefinedValues, values) // strings
          } else {
            const highlightedValues = fieldPredefinedValues.map((value: any, idx: number, arr: any[]): any => {
              const className = `${PREDEFINED_SUGGESTION_CLASSNAME} ${idx === arr.length - 1 ? LAST_PREDEFINED_SUGGESTION_CLASSNAME : ''}`
              return buildSuggestion('', value, className)
            })
            const nonHighlightedValues = difference(values, fieldPredefinedValues)
              .map((valueName: string): ISuggestion => buildSuggestion('', valueName))

            processedValues = union<ISuggestion>(highlightedValues, nonHighlightedValues)
              .map((item: ISuggestion, idx: number): ISuggestion => {
                item.id = idx.toString()
                return item
              }) // ISuggestion[]
          }
        } else {
          processedValues = values // strings
        }

        this.mapOfAddressFieldsAvailableValues[field.name] = processedValues
        this.$scope.$digest()
      })
  }

  private getAddressGeocodedLocation(): void {
    this.geolocationRequests.push(0)
    locationsService.getGeocodedLocationForAddress(this.address)
    .then((location: LocationPoint) => {
      if (!isNaN(location.latitude) && !isNaN(location.longitude)) {
        this.addressLocation = LocationPoint.build(location)
        this.address.location = LocationPoint.build(location)
        this.geolocationRequests.pop()
        if (!this.geolocationRequests.length) {
          this.addressForm.$setValidity(this.VALIDITY_LOADING_LOCATION, true, null)
        }
        this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, true, null)

        this.onAddressLocationChanged({addressLocation: location})
      } else {
        this.addressForm.$setValidity(this.VALIDITY_IS_VALID_LOCATION, false, null)
      }
      this.$scope.$apply()
      this.setLocationHistoryInitialState()
    })
    .catch((error: Error) => {
      logger.error('Can\'t geocode address', error)
    })
  }
  /* -------- SERVER FETCH FUNCTIONS END ---------- */

}

export default {
  templateUrl: require('./address-editor.pug'),
  controller: AddressEditorController,
  bindings: {
    address: '<',
    isEditing: '<',

    // the following property should always be equals to address.addressFormatId.
    // I pass it here only for purpose of observing its changes in on onChanges hook
    addressFormatId: '<?',

    // the same for the following
    location: '<',

    localeCode: '@?',
    mapIsHidden: '<?',
    mapIsCollapsed: '<?',
    preDefinedValues: '<?',
    limitToPreDefinedValues: '<?',
    onAddressFormatIdChanged: '&',
    onAddressLocationChanged: '&'
  }
}
