import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import StorefrontCategory from 'data/domain-objects/storefront/StorefrontCategory'

const categoryService: CrudService = new CrudService(endpoints.STOREFRONT_CATEGORY)
const categoriesService: CrudService = new CrudService(endpoints.STOREFRONT_CATEGORIES)
const categoryAllLanguagesService: CrudService = new CrudService(endpoints.STOREFRONT_CATEGORY_ALL_LANGUAGES)
const categoriesOrderService: CrudService = new CrudService(endpoints.STOREFRONT_CATEGORIES_ORDER)

export default {
  getAll (businessId: string, q?: string): Promise<StorefrontCategory[]> {
    return categoriesService.get([businessId], q && q.length >= 2 ? {q} : {})
      .then((categories: StorefrontCategory[]): StorefrontCategory[] => categories.map(StorefrontCategory.build))
  },

  getById (businessId: string, categoryId: string): Promise<StorefrontCategory> {
    return categoryAllLanguagesService
      .get([businessId, categoryId])
      .then(StorefrontCategory.build)
  },

  create (businessId: string, category: StorefrontCategory): Promise<StorefrontCategory> {
    return categoryService
      .create(StorefrontCategory.sanitizeNewCategory(category), [businessId])
      .then(StorefrontCategory.build)
  },

  update (businessId: string, categoryId: string, category: StorefrontCategory): Promise<StorefrontCategory> {
    return categoryService
      .update(StorefrontCategory.sanitizeNewCategory(category), [businessId, categoryId])
      .then(StorefrontCategory.build)
  },

  deleteCategory (businessId: string, categoryId: string): Promise<void> {
    return categoryService
      .delete([businessId, categoryId])
  },

  updateCategoriesOrder (businessId: string, sortedCategoryIds: string[]): Promise<void> {
    return categoriesOrderService
      .update(sortedCategoryIds, [businessId])
  }
}
