import templateUrl from './transfer-deliveries-dialog.pug'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import ChangeDriverBaseController from 'presentation/common/ChangeDriverBaseController'

class TransferDeliveriesDialogController extends ChangeDriverBaseController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DELIVERY_ACTIONS.TRANSFER_DELIVERIES, deliveryDialogIds.TRANSFER_DELIVERIES_DIALOG_ID)
  }

  onPopupOpen (deliveryIds) {
    this.deliveryIds = deliveryIds
    this.loadAvailableDrivers()
  }

  beforeLoadAvailableDrivers () { // could be re-defined
    return deliveriesCollection.transferNextDayDeliveriesToDriver()
  }

  beforeChangeDriver () { // could be re-defined
    return deliveriesCollection.transferDeliveriesToDriver(this.deliveryIds, this.selectedDriver.id)
  }

  handleTransferDeliveryToDriverFailed (error) {
    this.logger.error(`Cannot transfer deliveries with IDs: ${this.deliveryIds.join(',')} to driver ${this.selectedDriver.fullName}`, error)
  }
}

export default {
  templateUrl,
  controller: TransferDeliveriesDialogController,
  bindings: {
    onConfirm: '&',
    onConfirmFailed: '&'
  }
}
