import Delivery from './Delivery'
import Cost from './Cost'

/**
 * Disclaimer: this file is one giant piece of shit
 * Due to UGLY BE data format, we have to perform weird things
 *
 */

export interface IQuoteBreakdown {
  basePrice: string,
  totalPrice: string,
  duration: string,
  waiting: string,
  distance: string,
  tariff: string
}

export class DeliveryQuote {

  constructor(
    private delivery: Delivery
  ) {}

  public getInitialBreakdown(): IQuoteBreakdown {
    return this.getInitialQuoteValue()
  }

  public getActualBreakdown(): IQuoteBreakdown {
    return this.getQuoteBreakdown(this.delivery.quote)
  }

  private getQuoteBreakdown(quote: any): IQuoteBreakdown {
    return {
      totalPrice: Cost.build(quote.totalPrice).getFormattedCost(),
      basePrice: Cost.build(quote.basePrice).getFormattedCost(),
      duration: this.buildChargeEntityString(quote, 'duration'),
      waiting: this.buildChargeEntityString(quote, 'waitingTime'),
      distance: this.buildChargeEntityString(quote, 'distance'),
      tariff: quote.tariff && quote.tariff.name || ''
    }
  }

    // This will be changed on BE
  private getInitialQuoteValue(): any {
    let quoteObj = this.delivery.events
      && this.delivery.events[0].payload
      && this.delivery.events[0].payload.quote
      && this.delivery.events[0].payload.quote
      || null

    if (quoteObj !== null) {
      quoteObj = {
        basePrice: quoteObj.basePrice,
        totalPrice: quoteObj.price,
        waitingTime: quoteObj.waitingTime,
        waitingTimeTotalPrice: quoteObj.waitingTimePrice,
        distance: quoteObj.distance,
        distanceTotalPrice: quoteObj.distancePrice,
        duration: quoteObj.duration,
        durationTotalPrice: quoteObj.durationPrice,
        tariff: quoteObj.tariff
      }
    } else {
      return {}
    }
    return this.getQuoteBreakdown(quoteObj)
  }

  private getFormattedDuration(seconds: number): string {
    const mins = Math.floor(seconds / 60)
    const sec = seconds - mins * 60
    return `${mins} min ${sec} sec`
  }

  private getFormattedDistance(meters: number): string {
    const km = Math.floor(meters / 1000)
    const m = Math.ceil(meters - km * 1000)
    return `${km} km ${m} m`
  }

  private buildChargeEntityString(quote: any, entityName: string): string {
    const entity = quote[entityName]
    const entityTotalPrice = quote[entityName + 'TotalPrice']
    const formattedTotalPrice = Cost.build(entityTotalPrice).getFormattedCost()
    let details

    switch (entityName) {
      case 'duration':
      case 'waitingTime':
          details = this.getFormattedDuration(entity.amount || entity.value || 0)
          break
      case 'distance':
          details = this.getFormattedDistance(entity.amount || entity.value || 0)
          break

      default:
        details = entity && entity.text || ''
    }
    return `${formattedTotalPrice} (${details})`
  }
}
