import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import DriversDashboard from './dashboard/DriversDashboard'
import driverCashLimit from './common/driver-cash-limit/driverCashLimit'
import driverInfo from './common/driver-info/driverInfo'
import driverSummary from './common/driver-summary/driverSummary'

import driverAddLicense from './driver-add-license/driverAddLicense'
import driverAddVehicle from './driver-add-vehicle/driverAddVehicle'
import driverAssignToFleets from './driver-assign-to-fleets/driverAssignToFleets'
import driverCashAccount from './driver-cash-account/driverCashAccount'
import driverCashAccountChange from './driver-cash-account/driver-cash-account-change/driverCashAccountChange'
import driverCashAccountHistory from './driver-cash-account-history/driverCashAccountHistory'
import driverDetails from './driver-details/driverDetails'
import driverForm from './driver-form/driverForm'
import driverOverview from './driver-details/driver-overview/driverOverview'
import driverOverviewPopup from './driver-overview-popup/driverOverviewPopup'

import driverSummaryTable from './driver-summary-page/driver-summary-table/driverSummaryTable'
import driverSummaryPage from './driver-summary-page/driverSummaryPage'
import driverJobTask from './driver-details/driver-job-task/driverJobTask'
import driverVerifyAccount from './driver-verify-account/driverVerifyAccount'

import existingAccounts from './existing-accounts/existingsAccounts'
import setupDriverAccount from './setup-driver-account/setupDriverAccount'

export default declareAppModule('logistics.drivers', (module: ng.IModule) => module
  .component('driverCashLimit', driverCashLimit)
  .component('driverInfo', driverInfo)
  .component('driverSummary', driverSummary)
  .component('driverAddLicense', driverAddLicense)
  .component('driverAddVehicle', driverAddVehicle)
  .component('driverAssignToFleets', driverAssignToFleets)
  .component('driverCashAccount', driverCashAccount)
  .component('driverCashAccountChange', driverCashAccountChange)
  .component('driverCashAccountHistory', driverCashAccountHistory)
  .component('driverDetails', driverDetails)
  .component('driverForm', driverForm)
  .component('driverOverview', driverOverview)
  .component('driverOverviewPopup', driverOverviewPopup)
  .component('driverSummaryPage', driverSummaryPage)
  .component('driverSummaryTable', driverSummaryTable)
  .component('driverJobTask', driverJobTask)
  .component('driversDashboard', DriversDashboard)
  .component('setupDriverAccount', setupDriverAccount)
  .component('driverVerifyAccount', driverVerifyAccount)
  .component('existingAccounts', existingAccounts)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'drivers',
      componentName: 'driversDashboard',
      stateKey: 'DRIVERS',
      requiresPermission: 'DRIVERS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers',
      stateName: 'existing-accounts',
      componentName: 'existingAccounts',
      stateKey: 'NEW_DRIVER_EXISTING_ACCOUNTS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers',
      stateName: 'setup-account',
      componentName: 'setupDriverAccount',
      stateKey: 'NEW_DRIVER_SETUP_ACCOUNT',
      params: ['fleetId'],
      requiresPermission: 'DRIVERS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers',
      stateName: 'driver',
      idParam: 'driverId',
      params: ['targetState', 'isEditing'],
      componentName: 'driverForm',
      stateKey: 'DRIVER'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers',
      stateName: 'driver-details',
      idParam: 'driverId',
      componentName: 'driverDetails',
      stateKey: 'DRIVER_DETAILS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'cash-account',
      componentName: 'driverCashAccount',
      stateKey: 'DRIVER_CASH_ACCOUNT'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'cash-account-history',
      componentName: 'driverCashAccountHistory',
      stateKey: 'DRIVER_CASH_ACCOUNT_HISTORY'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'add-license',
      params: ['fleetId'],
      componentName: 'driverAddLicense',
      stateKey: 'NEW_DRIVER_ADD_LICENSE',
      requiresPermission: 'DRIVERS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'add-vehicle',
      params: ['fleetId'],
      componentName: 'driverAddVehicle',
      stateKey: 'NEW_DRIVER_ADD_VEHICLE',
      requiresPermission: 'DRIVERS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'assign-to-fleets',
      params: ['fleetId'],
      componentName: 'driverAssignToFleets',
      stateKey: 'NEW_DRIVER_ASSIGN_TO_FLEETS',
      requiresPermission: 'DRIVERS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'verify-account',
      componentName: 'driverVerifyAccount',
      stateKey: 'NEW_DRIVER_VERIFY_ACCOUNT',
      requiresPermission: 'DRIVERS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.drivers.driver',
      stateName: 'summary',
      componentName: 'driverSummaryPage',
      stateKey: 'DRIVER_SUMMARY',
      requiresPermission: 'DRIVERS',
      params: ['isNewDriver']
    })
  })
)
