import template from './editable-list-select.pug'

class EditableListController {
  constructor ($scope, $filter) {
    this.$scope = $scope
    this.$filter = $filter
  }

  get label () {
    const fallbackLabel = '-'
    if (typeof this.model === 'object' && this.model !== null) {
      return this.model[this.valueLabelKey]
    } else if (this.valuesMap) {
      return this.valuesMap[this.model] ? this.valuesMap[this.model] : fallbackLabel
    } else if (this.valuePrefixTranslationKey) {
      return this.$filter('translate')(`${this.valuePrefixTranslationKey}.${this.model}`)
    } else {
      return this.model || fallbackLabel
    }
  }
}

export default {
  templateUrl: template,
  controller: EditableListController,
  bindings: {
    name: '@',
    values: '=',
    valuesMap: '=', // Optional
    valueLabelKey: '@?',
    model: '=',
    placeholder: '@',
    form: '=',
    isDisabled: '=',
    isRequired: '=',
    isEditing: '=',
    valueMessage: '@',
    title: '@',
    noDefaultValue: '=',
    disallowedValuesArray: '<',
    onChange: '&',
    valuePrefixTranslationKey: '@?',
    hostClass: '@?',
    hideOptionalSuffix: '<?',
    disabledMap: '<?'
  }
}
