export default class DeliveryTimeSetting {
  constructor (
    public autoValue: number,
    public value: number
  ) {}

  static build(dto: any): DeliveryTimeSetting {
    return new DeliveryTimeSetting(
      dto.autoValue || null,
      dto.value || null
    )
  }
}
