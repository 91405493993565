import * as KanbanEvents from 'presentation/kanban/kanbanEvents'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import Logger from 'common/Logger'
import DeliveryDataService from 'data/common/deliveryDataService'
import KanbanDataService from './kanbanData'
import Delivery from 'data/domain-objects/Delivery'
import GroupedDeliveries from 'data/domain-objects/GroupedDeliveries'
import { KanbanFiltersSettings } from 'data/domain-objects/KanbanSettings'
import userModel from 'data/models/userModel'

interface IDeliveriesData {
  counters: {},
  groupedByStatus: GroupedDeliveries
}

const logger = new Logger('Kanban')
const mapSettingsKey = get(envConfig, 'collapsiblePanelExpandedStateLocalStorageKeys', 'expandDeliveriesDashboardMap')

class Kanban {
  public EXPAND_DELIVERIES_DASHBOARD_MAP_USER_SETTING_KEY: string = mapSettingsKey
  public filters: KanbanFiltersSettings
  public settings: any
  public filtersChangedCounter: number
  public deliveriesCounter: number
  public selectedBranch: string

  private deliveriesSyncListener: IPubSubscription = null
  private hardRefreshInterval: any
  private updateTimer: any

  private syncInProgress: boolean
  public dataLoaded: boolean
  public deliveriesCounters: any
  private rawDelivires: IDeliveriesData
  public groupedDeliveries: any
  public mapDeliveries: any
  public showFleetsFilter: boolean = false

  constructor (
    private $scope: ng.IScope,
    private kanbanDataService: KanbanDataService
  ) {

    this.filters = this.kanbanDataService.filters
    this.settings = this.kanbanDataService.settings

    this.filtersChangedCounter = this.kanbanDataService.getChangedFiltersCount()
    this.deliveriesCounter = null

    this.selectedBranch = this.filters.branchId || this.filters.businessId || null
  }

  $onInit (): void {
    this.sync()

    this.deliveriesSyncListener = DeliveryDataService.subscribeOnDeliverySync(() => {
      this.sync()
    })

    this.hardRefreshInterval = setInterval(() => this.resetData(), envConfig.kanban.resetDeliveriesIntervalMs)

    if (userModel.getLocalUser().isSuperAdmin || userModel.getLocalUser().isFleetManager) {
      this.showFleetsFilter = true
    }
  }

  $onDestroy (): void {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
    }
    if (this.hardRefreshInterval) {
      clearInterval(this.hardRefreshInterval)
    }
    this.kanbanDataService.resetDeliveriesCache()
    this.deliveriesSyncListener.remove()
  }

  onDeliveryClick (delivery: Delivery): void {
    this.$scope.$emit(DELIVERY_ACTIONS.VIEW, delivery)
  }

  lazyLoadStatus (params: any): void {
    this.kanbanDataService.syncDeliveries(false, params.status, params.skip)
      .then((deliveriesData: any) => {
        this.processDeliveries(deliveriesData)
        this.$scope.$digest()
      })
      .catch((err: Error) => logger.error(err))
  }

  resetData (): void {
    this.dataLoaded = false
    this.kanbanDataService.resetDeliveriesCache()
    this.sync(true)
  }

  private sync (resetCache: boolean = false): void {
    if (this.syncInProgress) {
      this.scheduleSync(resetCache)
    } else {
      this.syncInProgress = true
      this.kanbanDataService.syncDeliveries(resetCache)
        .then((deliveriesData: any) => this.processDeliveries(deliveriesData))
        .then(() => {
          this.syncInProgress = false
          if (!this.dataLoaded) {
            this.dataLoaded = true
          }
          this.$scope.$digest()
        })
        .catch((err: Error) => logger.error(err))
        .then(() => {
          this.scheduleSync(resetCache)
        })
    }
  }

  processDeliveries (deliveriesData: IDeliveriesData): void {
    this.rawDelivires = deliveriesData
    this.deliveriesCounters = deliveriesData.counters
    this.filterDeliveries()
  }

  filterDeliveries (): void {
    this.groupedDeliveries = {}

    Object.keys(this.rawDelivires.groupedByStatus).forEach((status: string) => {
      if (this.settings.board.hiddenColumns.indexOf(status) === -1) {
        this.groupedDeliveries[status] = this.rawDelivires.groupedByStatus[status].slice(0)
      } else {
        this.groupedDeliveries[status] = []
      }
    })

    // deliveries for map
    if (this.settings.map.hiddenStatuses.length > 0) {
      this.mapDeliveries = {}
      Object.keys(this.groupedDeliveries).forEach((status: string) => {
        if (this.settings.map.hiddenStatuses.indexOf(status) > -1) {
          this.mapDeliveries[status] = []
        } else {
          this.mapDeliveries[status] = this.groupedDeliveries[status]
        }
      })
    } else {
      this.mapDeliveries = this.groupedDeliveries
    }
  }

  scheduleSync (resetCache: boolean = false): void {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
    }

    this.updateTimer = setTimeout(() => {
      this.sync(resetCache)
      this.scheduleSync(resetCache)
    }, envConfig.kanban.deliveriesRefreshIntervalMs)
  }

  resetFilters (): void {
    this.kanbanDataService.resetFilters()
    this.filters = this.kanbanDataService.filters
    this.selectedBranch = null
    this.filtersChangedCounter = this.kanbanDataService.getChangedFiltersCount()
    this.resetData()
  }

  openFilters (): void {
    this.$scope.$broadcast(KanbanEvents.EVENT_OPEN_FILTERS)
  }

  onFleetSelect (fleet: any): void {
    if (fleet.id !== this.filters.fleetId) {
      this.filters.fleetId = fleet.id || null
      this.kanbanDataService.filters = this.filters
      this.filtersChangedCounter = this.kanbanDataService.getChangedFiltersCount()
      this.resetData()
    }
  }

  onBusinessBranchSelect (businessOrBranch: any): void {
    if (businessOrBranch.businessId !== this.filters.businessId || businessOrBranch.branchId !== this.filters.branchId) {
      this.filters.businessId = businessOrBranch.businessId
      this.filters.branchId = businessOrBranch.branchId

      this.selectedBranch = this.filters.branchId || this.filters.businessId || null

      this.kanbanDataService.filters = this.filters
      this.filtersChangedCounter = this.kanbanDataService.getChangedFiltersCount()
      this.resetData()
    }
  }

  onFiltersChange (filters: any, settings: any): void {
    this.kanbanDataService.filters = filters

    const cardSizeChanged = settings.board.cardSize !== this.settings.board.cardSize
    const periodChanged = settings.board.period !== this.settings.board.period
    const columnSizeChanged = settings.board.columnSize !== this.settings.board.columnSize

    const pickupAreaChanged = filters.pickupArea !== this.filters.pickupArea
    const dropoffAreaChanged = filters.dropoffArea !== this.filters.dropoffArea
    const deliveriesTypeChanged = filters.deliveriesType !== this.filters.deliveriesType
    const searchQueryChanged = filters.searchQuery !== this.filters.searchQuery
    const businessIdChanged = filters.businessId !== this.filters.businessId
    const branchIdChanged = filters.branchId !== this.filters.branchId
    const fleetIdChanged = filters.fleetId !== this.filters.fleetId

    const needsDataReload = deliveriesTypeChanged || pickupAreaChanged || dropoffAreaChanged || fleetIdChanged
          || periodChanged || columnSizeChanged || searchQueryChanged || businessIdChanged || branchIdChanged

    if (cardSizeChanged || periodChanged || columnSizeChanged) {
      this.kanbanDataService.settings = settings
      this.settings = this.kanbanDataService.settings
    }

    // check if only UI filters have changed or if we need to request new data
    this.filters = this.kanbanDataService.filters
    this.filtersChangedCounter = this.kanbanDataService.getChangedFiltersCount()

    if (needsDataReload) {
      this.selectedBranch = this.filters.branchId || this.filters.businessId || null
      this.resetData()
    } else {
      this.filterDeliveries()
    }
  }
}

export default {
  templateUrl: require('./kanban.pug'),
  controller: ['$scope', 'kanbanDataService', Kanban]
}
