import templateUrl from './three-state-checkbox.pug'
import * as CHECK_STATE_BITS from 'common/constants/CheckStates'

class ThreeStateCheckboxController {
  constructor () {
    this.noneChecked = CHECK_STATE_BITS.NOTHING_CHECKED
    this.fullyChecked = CHECK_STATE_BITS.ALL_CHECKED
    this.partiallyChecked = CHECK_STATE_BITS.PARTIALLY_CHECKED
  }

  click ($event) {
    if (this.model === this.partiallyChecked) {
      this.model = this.preferChecked ? this.fullyChecked : this.noneChecked
    } else {
      this.model = this.model === this.fullyChecked ? this.noneChecked : this.fullyChecked
    }

    this.onChange({
      value: this.model
    })

    if (this.form && this.form.$setDirty) {
      this.form.$setDirty()
    }

    $event.stopPropagation()
  }
}

export default {
  templateUrl,
  controller: ThreeStateCheckboxController,
  transclude: true,
  bindings: {
    form: '=',
    name: '@',
    model: '<',
    isDisabled: '<',
    preferChecked: '<?',
    hostClass: '@?',
    onChange: '&'
  }
}
