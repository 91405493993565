import templateUrl from './request-confirm.html'
import BaseFormController from 'presentation/common/BaseFormController'
import requestsCollection from 'data/collections/requestsCollection'

class RequestConfirmController extends BaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.requestId = null
    this.request = null
    this.showRequestCreated = false
    this.showRequestCreateFailed = false
    this.requestNotFound = false
  }

  $onInit () {
    this.isLoading = true

    if (this.$stateParams.requestId) {
      this.requestId = this.$stateParams.requestId
      this.getRequest()
    }

    if (this.$stateParams.talabatData) {
      try {
        this.talabatData = JSON.parse(this.$stateParams.talabatData)
        this.talabatData.orderCost.amount = parseFloat(this.talabatData.orderCost.amount)
        this.talabatData.orderCost.currencyCode = this.talabatData.orderCost.currencyCode || this.talabatData.orderCost.currency
        this.talabatData.orderCost.currency = undefined
      } catch (e) {
        this.talabatData = {}
      }
      this.createRequestFromTalabatData()
    }
  }

  createRequestFromTalabatData () {
    return requestsCollection.requestCheck(this.talabatData.posReference)
      .then(checkResult => {
        this.requestId = checkResult.id
        return this.getRequest()
      })
      .catch(() => {
        return requestsCollection.createRequest(this.talabatData)
          .then(request => this.handleRequestCreated(request))
          .catch(error => this.handleRequestCreateFailed(error))
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  onConfirmSuccess () {
    this.request.confirmed = true
    this.handleRequestCreated(this.request)
  }

  handleRequestCreated (request) {
    if (!request.confirmed) {
      this.request = request
    } else {
      this.showRequestCreated = true
    }
    this.$scope.$digest()
  }

  handleRequestCreateFailed (error) {
    this.logger.error('Could not create request', error)
    this.showRequestCreateFailed = true
  }

  getRequest () {
    return requestsCollection.getRequestToConfirm(this.requestId)
      .then(request => this.request = request)
      .catch(error => {
        this.requestNotFound = true
        this.logger.error('Could not retrieve request', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  goToRequestsList () {
    this.$state.go('main.requests')
  }
}

export default {
  templateUrl,
  controller: RequestConfirmController
}
