import envConfig from 'common/envConfig'
import authService from 'data/services/authService'
import BaseFormController from 'presentation/common/BaseFormController'
import { StateService } from '@uirouter/core'

class ClaimAccountCheckController extends BaseFormController {

  public credential: any = {}
  public submitting: boolean = false
  public requestFailed: boolean = false
  public registerRoute: string = envConfig.registrationRoute

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  onCredentialChanged (credential: any): void {
    this.credential = credential
    this.requestFailed = false
  }

  beforeSubmit (): Promise<any> {
    // This has to use the 'reset password' API until the claim account API is provided.
    this.submitting = true
    return authService.requestPasswordReset(this.credential)
  }

  afterSubmitSuccess (): Promise<any> {
    this.submitting = false
    this.requestFailed = false
    this.$state.go('auth.claim')
    return Promise.resolve()
  }

  afterSubmitFailure (): Promise<any> {
    this.submitting = false
    this.requestFailed = true
    return Promise.resolve()
  }
}

export default {
  templateUrl: require('./claim-account-check.pug'),
  controller: ClaimAccountCheckController
}
