import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import batchingOptions from './batching-options/batchingOptions'

import consumerAddressPicker from './consumer-address-picker/consumerAddressPicker'
import delivery from './delivery/delivery'
import deliveryInfoPage from './delivery-info-page/deliveryInfoPage'

import deliveryAddressPicker from './delivery-address-picker/deliveryAddressPicker'
import deliveryChangeAddress from './change-address/deliveryChangeAddress'
import deliveryCostConfirmation from './delivery-cost-confirmation/deliveryCostConfirmation'
import deliveryConfirmation from './confirmation/deliveryConfirmation'
import deliverySummaryTable from './delivery-summary-table/deliverySummaryTable'
import printDeliveryReceipt from './print-delivery-receipt/printDeliveryReceipt'
import printDeliveryReceiptRow from './print-delivery-receipt-row/printDeliveryReceiptRow'
import confirmationRow from './confirmation/confirmation-row/confirmationRow'

export default declareAppModule('deliveries.delivery', (module: ng.IModule) => module
  .component('batchingOptions', batchingOptions)
  .component('consumerAddressPicker', consumerAddressPicker)
  .component('delivery', delivery)
  .component('deliveryInfoPage', deliveryInfoPage)
  .component('deliveryAddressPicker', deliveryAddressPicker)
  .component('deliveryChangeAddress', deliveryChangeAddress)
  .component('deliveryCostConfirmation', deliveryCostConfirmation)
  .component('deliveryConfirmation', deliveryConfirmation)
  .component('deliverySummaryTable', deliverySummaryTable)
  .component('printDeliveryReceipt', printDeliveryReceipt)
  .component('printDeliveryReceiptRow', printDeliveryReceiptRow)
  .component('confirmationRow', confirmationRow)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.deliveries',
      stateName: 'delivery',
      idParam: 'deliveryId',
      stateKey: 'DELIVERY',
      componentName: 'delivery',
      requiresPermission: 'DELIVERIES_VIEW'
    })

    // FAKE routes for consumers to view their Orders
    // first one is abstract and do nothing
    declareState($stateProvider, {
      parent: 'main',
      stateName: 'orders',
      stateKey: 'ORDERS',
      componentName: 'delivery',
      redirectTo: 'dashboard',
      abstract: true
    })

    // this one just redirects to delivery page
    declareState($stateProvider, {
      parent: 'main.orders',
      stateName: 'order',
      idParam: 'deliveryId',
      stateKey: 'DELIVERY',
      componentName: 'delivery'
    })

    declareState($stateProvider, {
      parent: 'main.deliveries',
      stateName: 'overview',
      idParam: 'deliveryId',
      stateKey: 'DELIVERYINFOPAGE',
      componentName: 'deliveryInfoPage',
      requiresPermission: 'DELIVERIES_VIEW'
    })

    declareState($stateProvider, {
      parent: 'main.deliveries.overview',
      stateName: 'confirmation',
      componentName: 'deliveryConfirmation',
      stateKey: 'DELIVERY_CONFIRMATION',
      requiresPermission: 'DELIVERIES_DELIVERY_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.deliveries.overview',
      stateName: 'change-address',
      componentName: 'deliveryChangeAddress',
      stateKey: 'DELIVERY_CHANGE_ADDRESS',
      requiresPermission: 'DELIVERIES_EDIT'
    })
  })
)
