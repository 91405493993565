import templateUrl from './text-message-settings.pug'

const MODES = {
  NO: 'NO_MESSAGE',
  DEFAULT: 'DEFAULT_MESSAGE',
  CUSTOM: 'CUSTOM_MESSAGE'
}

class TextMessageSettingsController {
  constructor () {
    this.messageModes = MODES
    this.messageMode = ''
  }

  get useDefault () {
    return !this.englishMessageSetting.isOverridden && !this.arabicMessageSetting.isOverridden && !this.smsEnabledSetting.isOverridden
  }
  set useDefault (value) {
    this.arabicMessageSetting.isOverridden = this.smsEnabledSetting.isOverridden = this.englishMessageSetting.isOverridden = !value
    this.process()
  }

  get englishMessage () {
    return this.useDefault ? this.englishMessageSetting.defaultValue : this.englishMessageSetting.value
  }
  set englishMessage (value) {
    if (this.useDefault) {
      // do nothing. We shouldn't assign any value to defaultValue
    } else {
      this.englishMessageSetting.value = value
    }
  }

  get arabicMessage () {
    return this.useDefault ? this.arabicMessageSetting.defaultValue : this.arabicMessageSetting.value
  }
  set arabicMessage (value) {
    if (this.useDefault) {
      // do nothing. We shouldn't assign any value to defaultValue
    } else {
      this.arabicMessageSetting.value = value
    }
  }

  get smsEnabled () {
    return this.useDefault ? this.smsEnabledSetting.defaultValue : this.smsEnabledSetting.value
  }
  set smsEnabled (value) {
    if (this.useDefault) {
      // do nothing. We shouldn't assign any value to defaultValue
    } else {
      this.smsEnabledSetting.value = value
    }
  }

  $onChanges () {
    this.process()
  }

  process () {
    if (!this.smsEnabled) {
      this.messageMode = MODES.NO
    } else {
      if (this.englishMessageSetting.isOverridden || this.arabicMessageSetting.isOverridden) {
        this.englishMessageSetting.isOverridden = true
        this.arabicMessageSetting.isOverridden = true
        this.messageMode = MODES.CUSTOM
      }
      if (this.defaultEnglishTemplate && this.englishMessage === this.defaultEnglishTemplate.body && this.defaultArabicTemplate && this.arabicMessage === this.defaultArabicTemplate.body) {
        this.messageMode = MODES.DEFAULT
      } else {
        this.messageMode = MODES.CUSTOM
      }
    }
  }

  onMessageChange () {
    this.messageMode = MODES.CUSTOM
  }

  onOverrideModeChange (useDefault) {
    this.useDefault = useDefault
    this.process()
  }

  onManualModeChange (value) {
    if (value === MODES.DEFAULT) {
      this.smsEnabled = true
      this.englishMessage = this.defaultEnglishTemplate.body
      this.arabicMessage = this.defaultArabicTemplate.body
    } else if (value === MODES.NO) {
      this.smsEnabled = false
    } else if (value === MODES.CUSTOM) {
      this.smsEnabled = true
    }
  }
}

export default {
  templateUrl,
  controller: TextMessageSettingsController,
  bindings: {
    title: '@',
    name: '@',
    form: '<',
    smsEnabledSetting: '<',
    englishMessageSetting: '<',
    arabicMessageSetting: '<',
    defaultEnglishTemplate: '<',
    defaultArabicTemplate: '<'
  }
}
