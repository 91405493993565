import endpoints from 'common/endpoints'
import agent from 'common/agent'
import Logger from 'common/Logger'
const logger = new Logger('Auth Service')

export default {
  login: function (credentials) {
    logger.info('Posting login for user via credentials with phone or email:', credentials.phone || credentials.email)
    return agent.post(endpoints.AUTH_BASIC.endpointPath, credentials)
      .then(response => {
        if (response && response.token) {
          agent.setAuthToken(response.token)
        }
      })
  },
  logout: function () {
    logger.info('Deleting auth for user.')
    // Note that a 401 may occur here if already logged out. Suppress the error because we're logged out as intended.
    return agent.delete(endpoints.AUTH.endpointPath)
      .then(() => agent.clearAuthToken())
      .catch(error => {
        logger.error('Error thrown during logout. Suppressing error.', error)
      })
  },
  loginViaToken: function (token) {
    const path = endpoints.AUTH_TOKEN.endpointPath.replace('{token}', token)
    return agent.get(path)
      .then(response => {
        agent.setAuthToken(token)
        return response
      })
  },
  claimUser: function (newPassword) {
    logger.info('Claiming user account given password.')
    return agent.post(endpoints.USER_CLAIM.endpointPath, {newPassword})
  },
  requestPasswordReset: function (credentials) {
    logger.info('Requesting password reset for user:', credentials)
    return agent.post(endpoints.USER_REQUEST_RESET_PASSWORD.endpointPath, credentials)
  },
  setNewPassword: function (rawCode, newPassword) {
    const code = rawCode + '' // API requires string
    logger.info('Setting new password for user with code:', code)
    return agent.put(endpoints.USER_RESET_PASSWORD.endpointPath, {code, newPassword})
  },
  changePassword: function (userId, currentPassword, newPassword) {
    logger.info('Changing password for user with id:', userId)
    const path = endpoints.USER_CHANGE_PASSWORD.endpointPath.replace('{userId}', userId)
    return agent.put(path, {currentPassword, newPassword})
  },
  resendPhoneVerificationCode: function () {
    logger.info('Requesting phone verification code for user')
    return agent.post(endpoints.USER_RESEND_CODE.endpointPath, {type: 'phone'})
  },
  resendEmailVerificationCode: function () {
    logger.info('Requesting email verification code for user')
    return agent.post(endpoints.USER_RESEND_CODE.endpointPath, {type: 'email'})
  },
  verifyPhone: function (phoneNumberDescriptor, code) {
    logger.info('Verifying phone number for number with code', phoneNumberDescriptor, code)
    return agent.post(endpoints.USER_CONFIRM_CREDENTIAL.endpointPath, {type: 'phone', value: phoneNumberDescriptor, code})
  },
  verifyEmail: function (emailDescriptor, code) {
    logger.info('Verifying email address for address with code', emailDescriptor, code)
    return agent.post(endpoints.USER_CONFIRM_CREDENTIAL.endpointPath, {type: 'email', value: emailDescriptor, code})
  }
}
