import templateUrl from './next-day-delivery-dashboard.pug'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import BaseController from 'presentation/common/BaseController'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'

const LIMIT = 10

class NextDayDeliveryDashboardController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.delivery = null
    this.allDeliveries = []
    this.totalRecordsCount = 0
    this.printingDeliveryReceiptTemplate = null
    this.showError = false
    this.limit = LIMIT
    this.skip = 0
    this.deliveryStatuses = DELIVERY_STATUSES
    this.selectedDeliveryStatus = ''
  }

  $onInit () {
    this.sync()
  }

  onDeliveryStatusFiltersChange (newStatus) {
    this.selectedDeliveryStatus = newStatus
    this.sync()
  }

  onSync () {
    this.showError = false
    return deliveriesCollection.getNextDayDeliveries(this.limit, this.skip, this.selectedDeliveryStatus)
      .then(response => {
        this.allDeliveries = response.records
        this.totalRecordsCount = response.metadata.count
      })
      .catch(error => {
        this.showError = true
        this.logger.error('Cannot get next day deliveries', error)
      })
  }

  changeOffset (offset, limit) {
    this.skip = offset
    this.limit = limit
    this.sync()
  }

  printDelivery (delivery, receiptTemplate) {
    this.delivery = delivery
    this.receiptTemplate = receiptTemplate
    this.$scope.$digest()
    setTimeout(() => window.print(), 200) // eslint-disable-line
  }
}

export default {
  templateUrl,
  controller: NextDayDeliveryDashboardController
}
