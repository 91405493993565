// TODO: kick this file out

const API_FIELDS_TO_FORM_FIELDS_MAP = {
  tariffPeriodId: 'selectedTariffPeriod',
  tierId: 'selectedTier'
}

function lookupFormFieldNameWithApiFieldName (fieldName) {
  const mappedFieldName = API_FIELDS_TO_FORM_FIELDS_MAP[fieldName]

  return mappedFieldName
}

export default {
  lookupFormFieldNameWithApiFieldName
}
