import templateUrl from './confirmation-row.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    titleKey: '@',
    value: '<',
    withBorder: '<',
    highlighted: '<',
    valueFromRight: '<',
    boldValue: '<'
  }
}
