import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import Product from 'data/domain-objects/Product'

const productService: CrudService = new CrudService(endpoints.STOREFRONT_PRODUCT)
const productsService: CrudService = new CrudService(endpoints.STOREFRONT_PRODUCTS)
const productAllLanguagesService: CrudService = new CrudService(endpoints.STOREFRONT_PRODUCT_ALL_LANGUAGES)
const productSKUsService: CrudService = new CrudService(endpoints.STOREFRONT_PRODUCT_SKUS)
const productTagsService: CrudService = new CrudService(endpoints.STOREFRONT_TAGS)
const productTagService: CrudService = new CrudService(endpoints.STOREFRONT_TAG)
const productAvailabilityService: CrudService = new CrudService(endpoints.STOREFRONT_PRODUCT_AVAILABILITY)
const productsOrderService: CrudService = new CrudService(endpoints.STOREFRONT_PRODUCTS_ORDER)

export default {
  getAll (businessId: string, branchId?: string, categoryId?: string, q?: string): Promise<Product[]> {
    const params: {
      branchId?: string,
      categoryId?: string
      q?: string
    } = {}

    if (branchId) {
      params.branchId = branchId
    }

    if (categoryId) {
      params.categoryId = categoryId
    }

    if (q) {
      params.q = q
    }

    return productsService.get([businessId], params, true)
      .then((products: Product[]): Product[] => products.map(Product.build))
  },

  getById (businessId: string, productId: string): Promise<Product> {
    return productAllLanguagesService.get([businessId, productId])
      .then((product: Product): Product => Product.build(product))
  },

  create (businessId: string, product: Product): Promise<Product> {
    return productService.create(Product.sanitize(product), [businessId])
      .then((product: Product): Product => Product.build(product))
  },

  update (businessId: string, productId: string, product: Product): Promise<Product> {
    return productService.update(Product.sanitize(product), [businessId, productId])
      .then((product: Product): Product => Product.build(product))
  },

  deleteProduct (businessId: string, productId: string): Promise<void> {
    return productService.delete([businessId, productId])
  },

  getAllSKUs (businessId: string, q?: string): Promise<DBMappedNamedEntity[]> {
    return productSKUsService.get([businessId], {q})
  },

  getAllTags (businessId: string, q?: string): Promise<DBMappedNamedEntity[]> {
    return productTagsService.get([businessId], {q})
  },

  getTag(businessId: string, tagId: string): Promise<DBMappedNamedEntity> {
    return productTagService.get([businessId, tagId])
  },

  createTag(businessId: string, name: string): Promise<DBMappedNamedEntity> {
    return productTagService.create({name}, [businessId])
  },

  deleteTag(businessId: string, tagId: string): Promise<void> {
    return productTagService.delete([businessId, tagId])
  },

  setProductAvailability (businessId: string, productId: string, isAvailable: boolean): Promise<void> {
    return productAvailabilityService.update({isAvailable}, [businessId, productId])
  },

  updateProductsOrder (businessId: string, categoryId: string, productIds: string[]): Promise<void> {
    return productsOrderService.update(productIds, [businessId, categoryId])
  }
}
