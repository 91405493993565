import templateUrl from './print-dialog-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SETTINGS_KEYS = [
  'showPrintDialog'
]

class PrintDialog extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
  }
}

export default {
  templateUrl,
  controller: PrintDialog,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
