import BaseController from 'presentation/common/BaseController'
import driverCollection from 'data/collections/driverCollection'
import driverWizardRouteSteps from 'presentation/logistics-drivers/common/driverWizardRouteSteps'
import Driver from 'data/domain-objects/Driver'
import {StateParams, StateService} from '@uirouter/core'

class DriverSummaryPageController extends BaseController {
  public driverId: string = ''
  public driver: Driver = null
  public targetState: string = ''
  public isNewDriver: boolean = false
  public wizardRouteSteps: any

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams
  ) {
    super($scope, $state)
  }

  $onInit(): void {
    this.targetState = this.$stateParams.targetState
    this.isNewDriver = this.$stateParams.isNewDriver
    this.driverId = this.$stateParams.driverId
    this.wizardRouteSteps = driverWizardRouteSteps
    this.sync()
  }

  onSync(): Promise<void> {
    return driverCollection.get(this.driverId)
      .then((driver: Driver) => this.driver = driver)
      .catch((err: Error) => this.logger.error('Cannot get driver with specified ID', err))
  }

  goToTargetState (): void {
    this.$state.go(this.targetState)
  }
}

export default {
  templateUrl: require('./driver-summary-page.pug'),
  controller: DriverSummaryPageController
}
