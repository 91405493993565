import templateUrl from './address-entry-type-picker.pug'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import featuresModel from 'data/models/featuresModel'
import BaseController from 'presentation/common/BaseController'

const ENTRY_TYPES = {
  DETAILS: 'DETAILS',
  PACI: 'PACI',
  POINT_OF_INTEREST: 'POINT_OF_INTEREST'
}

class AddressEntryPickerController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.localModel = null
    this.entryTypes = ENTRY_TYPES
    this.hasPaci = featuresModel.isFeatureEnabled('PACI_LOOKUPS')
    this.hasPOI = featuresModel.isFeatureEnabled('POI_LOOKUPS')
    this.orderedEntryTypes = AddressEntryPickerController.getEntryTypesGivenFeatures(this.hasPaci, this.hasPOI)
    this.$scope.$watch('$ctrl.localModel', (value, previous) => this.onLocalModelChanged(value, previous))
  }

  static getEntryTypesGivenFeatures (hasPaci, hasPOI) {
    const entryTypes = [ENTRY_TYPES.DETAILS]
    if (hasPaci) {
      entryTypes.push(ENTRY_TYPES.PACI)
    }
    if (hasPOI) {
      entryTypes.push(ENTRY_TYPES.POINT_OF_INTEREST)
    }
    return entryTypes
  }

  $onChanges (changeDescriptorsByKey) {
    const newModelValue = getValueFromChangeDescriptors(changeDescriptorsByKey, 'model', null)
    if (newModelValue && newModelValue !== this.localModel) {
      this.localModel = newModelValue
    }
  }

  onLocalModelChanged (newValue, previousValue) {
    if (newValue !== previousValue && newValue !== this.model) {
      this.onChange({value: newValue})
    }
  }

  handleAddress (address) {
    this.onAddressLookup({address})
  }
}

export {ENTRY_TYPES}

export default {
  templateUrl,
  controller: AddressEntryPickerController,
  bindings: {
    model: '<',
    onChange: '&',
    form: '<',
    isEditing: '<',
    isRequired: '<?',
    title: '@',
    onAddressLookup: '&'
  }
}
