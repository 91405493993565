import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'

import {StateProvider} from '@uirouter/angularjs'
import placesList from './places-list/placesList'
import placeCreate from './place-create/placeCreate'
import placeView from './place-view/placeView'
import placeEditor from './place-editor/placeEditor'
import exitPopup from './exits/exit-popup/exitPopup'
import exitEditor from './exits/exit-editor/exitEditor'
import exitMarkerInfoWindowComponent from './exits/exit-marker-info-window/exitMarkerInfoWindow.'
import placeBoundsEditorComponent from './place-bounds-editor/place-bounds-editor'

export default declareAppModule('places', (module: ng.IModule) => module
    .component('placesList', placesList)
    .component('placeCreate', placeCreate)
    .component('placeView', placeView)
    .component('placeEditor', placeEditor)
    .component('placeBoundsEditor', placeBoundsEditorComponent)
    .component('exitPopup', exitPopup)
    .component('exitEditor', exitEditor)
    .component('exitMarkerInfoWindow', exitMarkerInfoWindowComponent)
    .config(($stateProvider: StateProvider) => {
      declareState($stateProvider, {
        parent: 'main.tools',
        stateName: 'places',
        componentName: 'placesList',
        stateKey: 'PLACES',
        requiresPermission: 'PLACES_LIST'
      })

      declareState($stateProvider, {
        parent: 'main.tools.places',
        stateName: 'place',
        componentName: 'placeView',
        idParam: 'placeId',
        stateKey: 'PLACES',
        requiresPermission: 'PLACE_VIEW'
      })

      $stateProvider
        .state({
          name: 'main.tools.places.create',
          url: '/create',
          views: {
            ['content@main']: {
              template: '<place-create></place-create>'
            }
          },
          data: {
            requiresPermission: 'PLACE_CREATE'
          },
          params: {
            place: null // this is need for creating duplicates
          }
        })
    })
)
