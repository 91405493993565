import Logger from 'common/Logger'
import {StateService} from '@uirouter/core'
import PromoCode from 'data/domain-objects/PromoCode'
import promoCodesCollection from 'data/collections/promoCodesCollection'

const logger = new Logger('Promo Code Create Controller')

class PromoCodeCreateController {
  public promoCode: PromoCode
  public isSubmitting: boolean
  public promoCodeForm: ng.IFormController
  public formTitleKey: string
  public errors: {[errorCode: string]: boolean} = {}

  constructor (
    private $state: StateService,
    private $scope: ng.IScope,
  ) {}

  $onInit(): void {
    this.promoCode = PromoCode.build()
  }

  public createPromoCode(): void {
    if (this.promoCodeForm.$valid) {
      this.errors = {}
      this.isSubmitting = true

      promoCodesCollection.createPromoCode(this.promoCode)
        .then((promoCode: PromoCode): void => {
          this.$state.go('main.tools.promo-codes.promo-code', {
            promoCodeId: promoCode.id
          })
        })
        .catch((error: any): void => {
          logger.error('Error occurred while creating a promo code', error)
          if (error.code && error.code === 'FLEET_PROMOCODE_DUPLICATE') {
            this.errors['NAME_DUPLICATED'] = true
          } else if (error.status && error.status === 400) {
            this.errors['VALIDATION'] = true
          } else {
            this.errors['COMMON'] = true
          }
          this.isSubmitting = false
          this.$scope.$digest()
        })
    }
  }
}

export default {
  templateUrl: require('./promo-code-create.pug'),
  controller: PromoCodeCreateController
}
