import uiSettingsService from 'data/services/uiSettingsService'
import permissionsModel from 'data/models/permissionsModel'

class LayoutLoggedInController {

  public sidebarIsExpanded: boolean = uiSettingsService.isSidebarExpanded
  public showSidebar: boolean = permissionsModel.getHasPermission('USER_IS_CLAMED')

  constructor (public $scope: ng.IScope) {
    $scope.$watch(() => uiSettingsService.isSidebarExpanded, (isSidebarExpanded: boolean) => {
      this.sidebarIsExpanded = isSidebarExpanded
    })
  }
}

export default {
  templateUrl: require('./layout-logged-in.pug'),
  controller: LayoutLoggedInController
}
