import envConfig from 'common/envConfig'
import localeHelper from 'common/localeHelper'
import {generateLocaleData} from 'common/localeHelper'
import template from './locale-picker.pug'
import userModel from 'data/models/userModel'

class LocalePicker {
  constructor ($scope, $state) {
    this.$scope = $scope
    this.$state = $state
    this.currentLocale = localeHelper.getLocaleData()
    this.supportedLocales = envConfig.supportedLocales.map(generateLocaleData)

    this.$scope.$watch(() => localeHelper.getLocaleData(), currentLocale => this.currentLocale = currentLocale)
    this.reloadPageStates = envConfig.reloadPageOnLocaleChangeStatesList
  }

  checkIfPageNeedReloading () {
    if (this.reloadPageStates.indexOf(this.$state.$current.name) > -1) {
      window.location.reload()
    }
  }

  toggleLocalePickerState () {
    this.localeOptionsVisible = !this.localeOptionsVisible
  }

  updateSelectedLocale (locale) {
    userModel.setUserLocale(locale)
      .catch(() => {})
      .then(() => {
        this.checkIfPageNeedReloading()
        this.$scope.$apply()
      })
    this.toggleLocalePickerState()
  }
}

export default {
  templateUrl: template,
  controller: ['$scope', '$state', LocalePicker]
}
