import moment from 'moment'
import BaseController from 'presentation/common/BaseController'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import Delivery from 'data/domain-objects/Delivery'
import { StateService } from '@uirouter/core'
import deliveriesCollection from 'data/collections/deliveriesCollection'

const LIMIT = 10
const COMMON_DATE_FORMAT = 'DD.MM.YYYY'

class ScheduledDashboardController extends BaseController {
  public delivery: any = null
  public allDeliveries: any[] = []
  public groupedDeliveries: any = {}
  public totalRecordsCount: number = 0
  public showError: boolean = false
  public limit: number = LIMIT
  public skip: number = 0
  public deliveryStatuses: any = DELIVERY_STATUSES
  public selectedDeliveryStatus: string = ''

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
    ) {
    super($scope, $state)
    delete this.deliveryStatuses.IN_PROGRESS
  }

  $onInit (): void {
    this.sync()
  }

  onDeliveryStatusFiltersChange (newStatus: string): void {
    this.selectedDeliveryStatus = newStatus
    this.sync()
  }

  onSync (): Promise<void> {
    this.showError = false
    return deliveriesCollection.getScheduled(this.limit, this.skip, this.selectedDeliveryStatus)
      .then((response: any): void => {
        this.allDeliveries = response.records
        this.totalRecordsCount = response.metadata.count

        this.groupedDeliveries = this.allDeliveries.reduce((accum: any, delivery: Delivery): any => {
          const date = moment(delivery.pickupTime).format(COMMON_DATE_FORMAT)

          if (!accum.hasOwnProperty(date)) {
            accum[date] = []
          }

          accum[date].push(delivery)

          return accum
        }, {})
      })
      .catch((error: Error): void => {
        this.showError = true
        this.logger.error('Cannot get next day deliveries', error)
      })
  }

  changeOffset (offset: number = 0, limit: number = LIMIT): void {
    this.skip = offset
    this.limit = limit
    this.sync()
  }
}

export default {
  templateUrl: require('./scheduled-dashboard.pug'),
  controller: ScheduledDashboardController
}
