import envConfig from 'common/envConfig'
import moment from 'moment'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import {getApiPathForKey} from 'common/endpoints'
import LiveOrdersData from 'data/domain-objects/LiveOrdersData'
import DateUtils from 'common/utils/dateUtils'

const liveOrdersService = new CrudService(endpoints.REPORTS_LIVE_ORDERS)
const liveOrdersDailyService = new CrudService(endpoints.REPORTS_LIVE_ORDERS_DAILY)
const liveOrdersRawDataPath = getApiPathForKey('reportLiveOrdersRawCSV')

/**
 * Backend for report was designed to allow requesting only the changed data, so
 * this collection has cache that stores state and merges it with updated date
 * when needed
 */
export function reportLiveOrdersCollectionFactory (
  liveOrdersService,
  liveOrdersDailyService) {
  function getDaily (daysLimit) {
    const searchParams = {}
    // minus one is used to include current day
    searchParams.start = DateUtils.getFormattedDate(moment().subtract(daysLimit - 1, 'days'))
    return liveOrdersDailyService.get([], searchParams)
  }

  function resetTime (date) {
    date.set({second: 0, millisecond: 0})
    return date
  }

  function get (params = {}, columns) {
    params.start = resetTime(moment((params.start) ? params.start : Date.now())).valueOf()

    return liveOrdersService.get([], params)
      .then(dto => {
        return new LiveOrdersData(dto, columns)
      })
  }

  function getRawCSVUrl (start, finish) {
    return `${envConfig.api.prefix}${liveOrdersRawDataPath}?start=${start}&finish=${finish}`
  }

  return {
    get,
    getDaily,
    getRawCSVUrl
  }
}

export default reportLiveOrdersCollectionFactory(liveOrdersService, liveOrdersDailyService)
