import moment from 'moment'

export default function timeElapsedFilter () {
  function timeElapsed (time) {
    const timeMoment = moment(time || null) // Moment handles undefined by creating a moment for now, so prevent this
    if (timeMoment.isValid()) {
      return timeMoment.fromNow()
    } else {
      return '-'
    }
  }

  return timeElapsed
}
