export default function fileModel () {
  return {
    scope: {
      fileModel: '='
    },
    link: function (scope, element) {
      element.bind('change', function () {
        scope.fileModel = element[0].files[0]
        scope.$apply()
      })
    }
  }
}
