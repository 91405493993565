import templateUrl from './fleet-tariffs.pug'
import fleetTariffsCollection from 'data/collections/fleetTariffsCollection'
import BaseEntityListController from 'presentation/common/BaseEntityListController'
import {valueToAmount} from 'data/common/costFixer'

const REORDER_TARIFFS_TIMEOUT = 200

class FleetTariffsController extends BaseEntityListController {
  constructor ($scope, $state, $timeout) {
    super($scope, $state)
    this.$timeout = $timeout
    this.fleetId = this.$state.params.fleetId
  }

  beforeFetch () {
    return fleetTariffsCollection.getAll(this.$state.params.fleetId)
  }

  duplicateFleetTariff (tariff) {
    tariff.order = this.entities.length
    const tariffModel = valueToAmount(tariff, ['fleet.cost', 'basePrice', 'cancellationFee'])
    fleetTariffsCollection.createTariffForFleet(tariffModel, this.$state.params.fleetId)
      .then(createdTariff => {
        this.entities.push(createdTariff)
        this.$scope.$digest()
      })
  }

  deleteFleetTariff (tariffIdWrapperObject) {
    return fleetTariffsCollection.delete(this.$state.params.fleetId, tariffIdWrapperObject.id)
      .then(() => {
        const tariffIndex = this.entities.findIndex(entity => entity.id === tariffIdWrapperObject.id)
        if (tariffIndex > -1) {
          this.entities.splice(tariffIndex, 1)
          this.$scope.$digest()
        }
      })
      .catch(err => this.logger.error('Cannot delete fleet tariff', err))
  }

  updateTariffs () {
    fleetTariffsCollection.saveFleetTariffsOrder(this.$state.params.fleetId, this.entities.map(entity => entity.id))
  }

  reorderTariffs (oldIndex, newIndex) {
    const temp = this.entities.splice(oldIndex, 1)[0]
    this.$timeout(() => {
      this.entities.splice(newIndex, 0, temp)
      this.updateTariffs()
    }, REORDER_TARIFFS_TIMEOUT)
  }

  moveUp (index) {
    if (index <= 0 || index >= this.entities.length) {
      return
    }
    this.reorderTariffs(index, index - 1)
  }

  moveDown (index) {
    if (index >= this.entities.length - 1) {
      return
    }
    this.reorderTariffs(index, index + 1)
  }
}

export default {
  templateUrl,
  controller: FleetTariffsController
}
