import templateUrl from './tariff-summary.pug'
import fleetTariffsCollection from 'data/collections/fleetTariffsCollection'
import {valueToAmount} from 'data/common/costFixer'

class TariffSummaryController {
  constructor ($scope) {
    this.$scope = $scope
    this.isEditingTariffName = false
    this.intermediateTariffName = ''
  }

  get isTariffNameValid () {
    return this.intermediateTariffName.length
  }

  handleNameEditClick () {
    this.intermediateTariffName = this.tariff.name
    this.isEditingTariffName = true
  }

  handleSaveNameClick () {
    if (this.isTariffNameValid) {
      this.isEditingTariffName = false
      this.tariff.name = this.intermediateTariffName
      this.intermediateTariffName = ''

      const tariffModel = valueToAmount(this.tariff, ['fleet.cost', 'basePrice', 'cancellationFee'])

      fleetTariffsCollection.updateFleetTariff(tariffModel, this.tariff.id, this.fleetId)
      // TODO: add result handling. It's not critical here now but to be consistent it should be there.
    }
  }

  handleCancelNameClick () {
    this.isEditingTariffName = false
    this.intermediateTariffName = ''
  }

  handleTariffNameKeyDown (event) {
    const ESC_BUTTON_CODE = 27
    const ENTER_BUTTON_CODE = 13
    if (event.which && event.which === ESC_BUTTON_CODE) {
      this.handleCancelNameClick()
    } else if (event.which && event.which === ENTER_BUTTON_CODE) {
      if (this.isTariffNameValid) {
        this.handleSaveNameClick()
      }
    }
  }
}

export default {
  templateUrl,
  controller: TariffSummaryController,
  bindings: {
    fleetId: '<',
    tariff: '<',
    onDuplicateClick: '&',
    onMoveUp: '&',
    onMoveDown: '&'
  }
}
