import envConfig from 'common/envConfig'
import templateUrl from './delivery-status-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SETTINGS_KEYS = [
  'showDeliveryStatus',
  'newDeliveryStatus'
]

class DeliveryStatusSettings extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)

    this.deliveryStatus = envConfig.businessAndBranchSettings.deliveryStatusValues
  }
}

export default {
  templateUrl,
  controller: DeliveryStatusSettings,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
