import BaseController from 'presentation/common/BaseController'
import ValidationUtils from 'common/utils/validationUtils'
import {StateService} from '@uirouter/core'

export default class BaseFormController extends BaseController {

  public model: any = {}
  public backendErrorMessage: string = ''
  public backendValidationErrors: any = {}
  public submitFailedOther: boolean = false
  public submitFailedValidation: boolean = false
  public submitting: boolean = false

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)

    this.$scope.$watch('$ctrl.model', (updatedEntity: any, previousEntity: any) => {
      this.resetSubmitFailed() // Once the user edits the data, we haven't just failed a submit
      ValidationUtils.resetBackendValidationForChanged(this.backendValidationErrors, updatedEntity, previousEntity)
    }, true)
  }

  resetSubmitFailed (): void {
    this.submitFailedOther = false
    this.submitFailedValidation = false
    this.backendErrorMessage = ''
  }

  resetAllValidation (): void {
    this.backendValidationErrors = {}
    this.submitFailedOther = false
    this.submitFailedValidation = false
    this.backendErrorMessage = ''
  }

  notifySubmitSuccess (): void {
    this.submitFailedValidation = false
    this.submitFailedOther = false
    this.backendErrorMessage = ''
  }

  notifySubmitError (error: any): void {
    const errorDescriptorArraysByFieldName = error && error.validationErrors

    this.backendErrorMessage = error && error.message

    if (errorDescriptorArraysByFieldName && Object.keys(errorDescriptorArraysByFieldName).length > 0) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(errorDescriptorArraysByFieldName)
      this.submitFailedValidation = true
      this.submitFailedOther = false
    } else {
      this.backendValidationErrors = {}
      this.submitFailedValidation = false
      this.submitFailedOther = true
    }
  }

  submit (): Promise<any> {
    this.submitting = true
    const promisedSubmit = this.beforeSubmit()
    return promisedSubmit
      .then((response: any) => this.handleSubmitSuccess(response))
      .catch((error: Error) => this.handleSubmitFailure(error))
      .then(() => {
        this.submitting = false
        this.$scope.$digest()
      })
  }

  beforeSubmit (): Promise<any> {
    this.logger.warn('Invoked base beforeSubmit handler. This should be overridden by child class.')
    return Promise.resolve()
  }

  handleSubmitSuccess (response: any): Promise<any> {
    this.notifySubmitSuccess()
    return this.afterSubmitSuccess(response)
  }

  afterSubmitSuccess (response: any): Promise<any> {
    return Promise.resolve(response)
  }

  handleSubmitFailure (error: Error): Promise<any> {
    this.notifySubmitError(error)
    this.logger.error('Submit failed', error)
    return this.afterSubmitFailure(error)
  }

  afterSubmitFailure (error: Error): Promise<any> {
    this.isLoading = false
    this.submitting = false
    this.$scope.$digest()
    return Promise.reject(error)
  }
}
