import Cost from 'data/domain-objects/Cost'
import { Moment } from 'moment'
import BaseAddressTemplate from 'data/domain-objects/BaseAddressTemplate'
import AddressField from 'data/domain-objects/AddressField'

export const basicCompare = (a: number, b: number): number => {
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}

interface IEntityWithName {
  name: string
}

export const entityNameCompare = (a: IEntityWithName, b: IEntityWithName): number => {
  return a.name.localeCompare(b.name)
}

export const stringCompare = (a: string, b: string): number => {
  return b.localeCompare(a)
}

export const compareMomentDates = (a: Moment, b: Moment): number => {
  return basicCompare(a ? a.valueOf() : 0, b ? b.valueOf() : 0)
}

export const compareDates = (a: Date, b: Date): number => {
  return basicCompare(a ? a.getTime() : 0, b ? b.getTime() : 0)
}

export const compareCharges = (a: Cost, b: Cost): number => {
  return basicCompare(a ? a.amount : 0, b ? b.amount : 0)
}

export const compareAddresses = (a: BaseAddressTemplate, b: BaseAddressTemplate): number => {
  const countOfNonNullValuesForA = a.fields.filter((field: AddressField) => field.value).length
  const countOfNonNullValuesForB = b.fields.filter((field: AddressField) => field.value).length

  return countOfNonNullValuesForB - countOfNonNullValuesForA
}

export default {
  string: stringCompare,
  basic: basicCompare,
  dates: compareDates,
  charges: compareCharges,
  momentDates: compareMomentDates
}
