import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import { StateService, StateParams } from '@uirouter/core'
import permissionsModel from 'data/models/permissionsModel'
import storefrontPromoCodesCollection from 'data/collections/storefrontPromoCodesCollection'
import businessesCollection from 'data/collections/businessesCollection'
import DateUtils from 'common/utils/dateUtils'
import StorefrontPromoCode from 'data/domain-objects/storefront/StorefrontPromoCode'

const BUSINESS_ID_PARAM_NAME = 'businessId'
const PROMO_CODE_ID_PARAM_NAME = 'promoCodeId'

const PROMO_CODE_VIEW_STATE = 'main.storefront.promo-codes.promo-code'

const PROMOTION_ERROR = 'ERR_ORDER_PROMO_CODE_MISSING_HOMEPAGE_PROMOTION_FIELDS'
const DISCOUNT_ERROR = 'ERR_ORDER_PROMO_CODE_SHOULD_HAVE_EITHER_FIXED_OR_PERCENT_DISCOUNT'
const DUPLICATE_ERROR = 'ERR_ORDER_DUPLICATE_PROMO_CODE'

class StorefrontPromoCodeFormController extends CreateViewEditFormController {
  public businessId: string
  public showSaveButton: boolean = false
  public minDate: Date = DateUtils.getStartOfDay(new Date())

  public showPromotionSettingsError: boolean = false
  public showDiscountSettingsError: boolean = false
  public showDuplicateError: boolean = false

  constructor ($scope: ng.IScope, $state: StateService, $stateParams: StateParams) {
    super($scope, $state, $stateParams, PROMO_CODE_ID_PARAM_NAME)
  }

  $onInit (): void {
    this.businessId = this.$stateParams[BUSINESS_ID_PARAM_NAME]
    super.$onInit()

    if (this.isCreate) {
      this.getStorefrontSettings()
    }

    this.showSaveButton = this.isCreate ? permissionsModel.getHasPermission('STOREFRONT_CREATE') : permissionsModel.getHasPermission('STOREFRONT_UPDATE')

    this.$scope.$watch('$ctrl.model.percentDiscount', (percentValue: number) => {
      if (percentValue < 0) {
        this.model.percentDiscount = 0
      } else if (percentValue > 100) {
        this.model.percentDiscount = 100
      }
    })
  }

  getEntity (): Promise<StorefrontPromoCode> {
    return storefrontPromoCodesCollection.getById(
      this.businessId,
      this.$stateParams[PROMO_CODE_ID_PARAM_NAME]
    )
      .then((promoCode: StorefrontPromoCode): StorefrontPromoCode => this.backendModel = StorefrontPromoCode.build(promoCode))
      .then(() => this.getStorefrontSettings())
      .then(() => {
        this.setDefaultModel()
        return this.backendModel
      })
  }

  getStorefrontSettings (): Promise<any> {
    return this.businessId ? businessesCollection.getStorefrontSettings(this.businessId)
      .then((settings: any): void => {
        if (settings) {
          this.model.buttonColor = this.model.buttonColor ? this.model.buttonColor : settings.appearance__primaryAccentColor.value
          this.model.buttonTextColor = this.model.buttonTextColor ? this.model.buttonTextColor : settings.appearance__reverseFontColor.value
        }
      }) : Promise.resolve({})
  }

  getDefaultModel (): StorefrontPromoCode {
    return StorefrontPromoCode.build(this.backendModel || {})
  }

  onImageChanged (imageUrl: string): void {
    this.model.promotionImageUrl = imageUrl
    this.$scope.$digest()
  }

  beforeSubmit (): Promise<any> {
    const promoCodeModel = StorefrontPromoCode.build(this.model)

    this.showDuplicateError = false
    this.showDiscountSettingsError = false
    this.showPromotionSettingsError = false

    return this.isCreate ?
      storefrontPromoCodesCollection.create(this.businessId, promoCodeModel) :
      storefrontPromoCodesCollection.update(this.businessId, this.entityId, promoCodeModel)
  }

  afterSubmitSuccess (backendModel: StorefrontPromoCode): Promise<any> {
    const itWasSuccessfullCreate = this.isCreate
    return super.afterSubmitSuccess(backendModel)
      .then(() => {
        if (itWasSuccessfullCreate) {
          this.$state.go(PROMO_CODE_VIEW_STATE, {
            businessId: this.businessId,
            promoCodeId: backendModel.id
          })
        }
      })
  }

  afterSubmitFailure (error: any): Promise<any> {
    this.showDuplicateError = error.code === DUPLICATE_ERROR
    this.showDiscountSettingsError = error.code === DISCOUNT_ERROR
    this.showPromotionSettingsError = error.code === PROMOTION_ERROR
    return super.afterSubmitFailure(error)
  }
}

export default {
  templateUrl: require('./storefront-promo-code-form.pug'),
  controller: StorefrontPromoCodeFormController
}
