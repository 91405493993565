import templateUrl from './currency-and-cost-input.pug'
import Cost from 'data/domain-objects/Cost'

export const SET_COST_FOCUSED_EVENT = 'setCostFocused'

class CurrencyAndCostInputController {
  constructor ($scope, $element) {
    this.$element = $element
    this.currencyCodes = Cost.getAvailableCurrencyCodes()
    $scope.$watch('$ctrl.model.currencyCode', () => {
      if (this.model) {
        this.decimalPointsCount = Cost.getDecimalPointsCountForCurrency(this.model.currencyCode)
      }
    })

    $scope.$on(SET_COST_FOCUSED_EVENT, () => {
      this.$element.find('input')[0].focus()
    })
  }
}

export default {
  templateUrl,
  controller: CurrencyAndCostInputController,
  bindings: {
    form: '=',
    name: '@',
    model: '=',
    isRequired: '<',
    isDisabled: '<?',
    placeholder: '@',
    min: '<?',
    max: '<?',
    allowNegative: '<?',
    fixedCurrency: '<?',
    disableOptionalPlaceholder: '<?',
    disabledPlaceholder: '@?',
    onChange: '&?',
    hideZeroValue: '<?'
  }
}
