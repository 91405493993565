import {StateParams} from '@uirouter/core'

const BUSINESS_ID_PARAM_NAME = 'businessId'
const ORDER_ID_PARAM_NAME = 'orderId'

class OrderPageController {
  public businessId: string = null
  public orderId: string = null

  constructor (
    private $stateParams: StateParams
  ) {}

  $onInit (): void {
    this.businessId = this.$stateParams[BUSINESS_ID_PARAM_NAME]
    this.orderId = this.$stateParams[ORDER_ID_PARAM_NAME]
  }
}

export default {
  templateUrl: require('./order-page.pug'),
  controller: OrderPageController
}
