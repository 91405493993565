import BaseController from 'presentation/common/BaseController'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import packageTypesCollection from 'data/collections/packageTypesCollection'
import collectEntityFieldByIds from 'data/common/collectByIds'
import Delivery from 'data/domain-objects/Delivery'
import localeHelper from 'common/localeHelper'
import { StateService } from '@uirouter/core'

class DeliveryPrinter extends BaseController {
  public packageNamesById: object = null
  public delivery: Delivery
  public instantPrint: boolean
  public deliveryReceiptTemplate: string

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    private $timeout: ng.ITimeoutService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.getPackageTypes()
  }

  getPackageTypes(): Promise<void> {
    return packageTypesCollection.getAll()
      .then(collectEntityFieldByIds)
      .then((packageNamesById: any) => {
        this.packageNamesById = packageNamesById
        this.$scope.$digest()
      })
      .catch((err: Error) => this.logger.error('Cannot get package types', err))
  }

  getDeliveryReceiptTemplate(): void {
    this.delivery = Delivery.build(this.delivery)

    localeHelper.loadI18NResources('en')
      .then(() => {
        deliveriesCollection.getDeliveryReceiptTemplate(this.delivery.id)
        .then((receiptTemplate: any) => {
          if (receiptTemplate && receiptTemplate.body) {
            this.deliveryReceiptTemplate = receiptTemplate.body
            this.$scope.$digest()
            if (this.instantPrint) {
              this.$timeout(() => window.print())
            }
          }
        })
      })
      .catch((err: Error) => this.logger.error('Cannot get delivery receipt template for specified delivery', err))
  }

  $onChanges (): void {
    if (this.delivery && this.delivery.id) {
      this.getDeliveryReceiptTemplate()
    }
  }
}

export default {
  templateUrl: require('./delivery-printer.pug'),
  controller: DeliveryPrinter,
  bindings: {
    delivery: '<',
    instantPrint: '<'
  }
}
