import declareState from 'presentation/_utilities/declareState'
import declareAppModule from 'presentation/_utilities/declareAppModule'
import { StateProvider } from '@uirouter/angularjs'

import dashboard from './dashboard'
import noFleetsPanel from './common/no-fleets-panel/noFleetsPanel'
import branchOnboardingPanels from './common/branch-onboarding-panels/branchOnboardingPanels'
import businessOnboardingPanel from './common/business-onboarding-panel/businessOnboardingPanel'

export default declareAppModule('dashboard', (module: ng.IModule) => module
  .component('dashboard', dashboard)
  .component('noFleetsPanel', noFleetsPanel)
  .component('branchOnboardingPanels', branchOnboardingPanels)
  .component('businessOnboardingPanel', businessOnboardingPanel)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'dashboard',
      params: ['hasCompletedOnboarding', 'hasVerifiedAddress', 'hasClaimedAccount'],
      stateKey: 'DASHBOARD',
      componentName: 'dashboard'
    })
  })
)
