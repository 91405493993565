import DBMappedNamedEntity from './DBMappedNamedEntity'
import Fleet from './Fleet'
import Area from './Area'
import TariffPeriod from './TariffPeriod'

const MINIMAL_MULTIPLIER_VALUE = 1

const getEntityId = (entity: DBMappedNamedEntity): string => entity.id

export default class Surge {
  constructor(
    public id: string,
    public enabled: boolean,
    public comment: string,
    public multiplier: number,
    public fleetIds: string[],
    public pickupAreaIds: string[],
    public timePeriodIds: string[],
    public fleets: Fleet[],
    public pickupAreas: Area[],
    public timePeriods: TariffPeriod[]
  ) {}

  public static build(dto: any = {}): Surge {
    const multiplier = parseFloat(dto.multiplier)
    const fleetIds = (dto.fleets || []).map(getEntityId)
    const pickupAreas = (dto.pickupAreas || []).map(getEntityId)
    const timePeriods = (dto.timePeriods || []).map(getEntityId)

    return new Surge(
      dto.id || '',
      !!dto.enabled,
      dto.comment || '',
      isNaN(multiplier) ? MINIMAL_MULTIPLIER_VALUE : multiplier,
      dto.fleetIds || fleetIds,
      dto.pickupAreaIds || pickupAreas,
      dto.timePeriodIds || timePeriods,
      (dto.fleets || []).map(Fleet.build),
      (dto.pickupAreas || []).map(Area.build),
      (dto.timePeriods || []).map(TariffPeriod.build)
    )
  }

  public toPostJson(): any {
    return {
      ...this,
      fleets: undefined,
      pickupAreas: undefined,
      timePeriods: undefined
    }
  }
}
