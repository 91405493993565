import templateUrl from './delivery-receipt-settings.pug'
import templatesCollection from 'data/collections/templatesCollection'
import {collectEntityFieldByIds} from 'data/common/collectByIds'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SETTING_KEYS = [
  'receiptTemplate'
]

class DeliveryReceiptSettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTING_KEYS)

    DeliveryReceiptSettingsController.getAvailableReceiptTemplates()
      .then(availableReceipts => {
        this.availableTemplatesIds = availableReceipts.map(template => template.id)
        this.availableTemplateNamesById = collectEntityFieldByIds(availableReceipts)
        this.availableTemplatePreviewsById = collectEntityFieldByIds(availableReceipts, 'previewImageUrl')
        this.$scope.$digest()
      })
      .catch(err => this.logger.error('Cannot get available receipts templates', err))

    this.availableTemplatesIds = []
    this.availableTemplateNamesById = {}
    this.availableTemplatePreviewsById = {}
  }

  static getAvailableReceiptTemplates () {
    return templatesCollection.getAllReceiptTemplates()
  }
}

export default {
  templateUrl,
  controller: DeliveryReceiptSettingsController,
  bindings: {
    form: '=',
    name: '@',
    model: '=',
    isEditing: '<'
  }
}
