import get from 'lodash-es/get'
import orderBy from 'lodash-es/orderBy'
import templateUrl from './available-fleets.pug'
import BaseController from 'presentation/common/BaseController'
import fleetsCollection from 'data/collections/fleetsCollection'
import * as DeliveryTypes from 'common/constants/DeliveryTypes'

class AvailableFleetsController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.onSelectedFleetChanged = fleetId => this.handleSelectedFleetChanged(fleetId)

    this.resetSortingSettings()
    this.showNoFleetsWarning = false
    this.allAvailableFleets = []
    this.sortedFleets = []
  }

  resetSortingSettings () {
    this.sortByEtaAscending = false
    this.sortByEtdAscending = false
    this.sortByPriceAscending = false
    this.maxNumberOfFleetsRendered = null
  }

  $onChanges (changeDescriptorsByKey) {
    if (changeDescriptorsByKey.branchId ||
      changeDescriptorsByKey.branchAddressId ||
      changeDescriptorsByKey.consumerAddressId ||
      changeDescriptorsByKey.pickupTime ||
      changeDescriptorsByKey.packages) {
      this.getAvailableDeliveryFleets()
    }

    if (changeDescriptorsByKey.settings) {
      this.applyFleetRenderingSettings()
    }
  }

  getAvailableDeliveryFleets () {
    if (this.branchId && this.branchAddressId && this.consumerAddressId
      && this.pickupTime && this.deliveryType && this.packages && this.packages.length) {
      const pickupAddressId = (this.deliveryType === DeliveryTypes.PICK_UP_FROM_BRANCH)
        ? this.branchAddressId : this.consumerAddressId

      this.showNoFleetsWarning = false

      const dropoffAddressId = (this.deliveryType === DeliveryTypes.PICK_UP_FROM_BRANCH)
        ? this.consumerAddressId : this.branchAddressId

      const pickupBranchId = this.branchId
      const pickupTime = this.pickupTime.toISOStringGivenNow(new Date())

      const params = {
        pickupAddressId,
        dropoffAddressId,
        pickupTime,
        branches: [pickupBranchId],
        packages: this.packages
      }

      this.syncFleets(params)
    } else {
      this.resetFleets()
    }
  }

  syncFleets (params) {
    this.sortedFleets = []
    this.allAvailableFleets = []

    return fleetsCollection.getAvailableFleets(params, this.isNextDay)
      .then(response => this.handleAvailableFleets(response))
      .catch(error => this.handleAvailableFleetsError(params, error))
      .then(() => this.$scope.$digest())
  }

  handleAvailableFleets (availableFleets) {
    this.allAvailableFleets = availableFleets

    if (this.allAvailableFleets.length) {
      this.sortFleets()
    } else {
      this.showNoFleetsWarning = true
    }

    this.selectedFleetId = (this.sortedFleets.length) ? this.sortedFleets[0].id : null
    this.form.$setValidity('hasAvailableFleet', true)
  }

  applyFleetRenderingSettings () {
    this.resetSortingSettings()

    this.maxNumberOfFleetsRendered = get(this.settings, 'fleetsLimit.value', null)

    const SORT_BY_ETA = 'eta'
    const SORT_BY_ETD = 'etd'
    const SORT_BY_PRICE = 'price'
    const sortFleetsBySetting = get(this.settings, 'sortFleetsBy.value', null)

    if (sortFleetsBySetting) {
      if (sortFleetsBySetting === SORT_BY_ETA) {
        this.sortByEtaAscending = true
      } else if (sortFleetsBySetting === SORT_BY_ETD) {
        this.sortByEtdAscending = true
      } else if (sortFleetsBySetting === SORT_BY_PRICE) {
        this.sortByPriceAscending = true
      }
    }

    this.sortFleets()
  }

  sortFleets () {
    let sortedFleets = this.allAvailableFleets

    if (this.sortByEtaAscending) {
      sortedFleets = orderBy(sortedFleets, 'fleetETA', 'asc')
    }

    if (this.sortByEtdAscending) {
      sortedFleets = orderBy(sortedFleets, 'fleetETD', 'asc')
    }

    if (this.sortByPriceAscending) {
      sortedFleets = orderBy(sortedFleets, 'cost.amount', 'asc')
    }

    if (this.maxNumberOfFleetsRendered) {
      sortedFleets.splice(this.maxNumberOfFleetsRendered)
    }

    this.sortedFleets = sortedFleets
  }

  handleAvailableFleetsError (params, error) {
    this.logger.error('Could not retrieve available fleets given params.', params, error)
    this.showNoFleetsWarning = true
    this.form.$setValidity('hasAvailableFleet', false)
    this.resetFleets()
  }

  resetFleets () {
    this.allAvailableFleets = []
    this.sortedFleets = []
    this.selectedFleetId = null
  }

  handleSelectedFleetChanged (fleetId) {
    this.selectedFleetId = fleetId
  }
}

export default {
  templateUrl,
  controller: AvailableFleetsController,
  bindings: {
    isShowing: '<',
    form: '=',
    branchId: '<',
    branchAddressId: '<',
    consumerAddressId: '<',
    pickupTime: '<',
    deliveryType: '<',
    packages: '<',
    selectedFleetId: '=',
    settings: '<',
    isNextDay: '<?'
  }
}
