
const enum RideTypes {
  B2C = 'B2C',
  B2B = 'B2B',
  C2C = 'C2C',
  C2B = 'C2B'
}

export const C2B = RideTypes.C2B
export const C2C = RideTypes.C2C
export const B2C = RideTypes.B2C
export const B2B = RideTypes.B2B
