import branchesCollection from 'data/collections/branchesCollection'
import SettingsController from 'presentation/businesses/common/SettingsController'
import { StateParams, StateService } from '@uirouter/core'
import Setting from 'data/domain-objects/Setting'

class BranchSettingsController extends SettingsController {
  public entityId: string
  public businessId: string

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    private $stateParams: StateParams
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.entityId = this.$stateParams.branchId as string
    this.businessId = this.$stateParams.businessId as string
    super.$onInit()
  }

  /**
   * this.submit() has a very sophisticated promise chain and will always
   * catch all errors and return resolved promise
   *
   * So here we actually check if saving setting failed and return rejected
   * promise if needed
   */
  onSubmitHandler (): Promise<void> {
    return this.submit()
      .then(() => {
        return (this.updateSettingsFailed) ? Promise.reject() : Promise.resolve()
      })
  }

  getEntityName (): Promise<string> {
    return branchesCollection.getWithAllLanguages(this.entityId)
      .then((branch: any) => branch.name.en)
  }

  getSettings (): Promise<{[settingsKey: string]: Setting[]}> {
    return branchesCollection.getBranchSettings(this.businessId, this.entityId)
  }

  beforeSubmit (): Promise<void> {
    return branchesCollection.updateBranchSettings(this.businessId, this.entityId, this.model)
  }
}

export default {
  templateUrl: require('./branch-settings.pug'),
  controller: BranchSettingsController
}
