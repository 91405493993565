import {StateProvider} from '@uirouter/angularjs'

import declareState from 'presentation/_utilities/declareState'
import declareAppModule from 'presentation/_utilities/declareAppModule'

import rideCreate from 'presentation/ride/ride-create/rideCreate'
import newRideAddressPicker from 'presentation/ride/ride-create/new-ride-address-picker/newRideAddressPicker'
import balancesPicker from 'presentation/ride/ride-create/balances-picker/balancesPicker'

import rideConfirmation from 'presentation/ride/ride-confirmation/rideConfirmation'
import costBuilder from 'presentation/ride/cost-builder/costBuilder'

export default declareAppModule('ride', (module: ng.IModule) => module
  .component('newRideAddressPicker', newRideAddressPicker)
  .component('rideCreate', rideCreate)
  .component('rideConfirmation', rideConfirmation)
  .component('costBuilder', costBuilder)
  .component('balancesPicker', balancesPicker)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'ride-create',
      componentName: 'rideCreate',
      stateKey: 'RIDE_CREATE',
      requiresPermission: 'DELIVERIES_RIDE_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'ride-confirmation',
      params: ['rideId'],
      componentName: 'rideConfirmation',
      stateKey: 'RIDE_CONFIRMATION'
    })
  })
)
