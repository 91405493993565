import templateUrl from './account-balance.pug'
import envConfig from 'common/envConfig'

class AccountBalanceController {
  constructor ($scope) {
    this.$scope = $scope
    this.presetTopUpAmounts = envConfig.payments.consumer.presetTopUpAmounts
    this.paymentProcessedPath = envConfig.payments.consumer.paymentProcessedPath
    this.triggerReload = Date.now()
  }

  /**
   * Child components can set a watcher on triggerReload
   * and update data when triggerReload changes.
   *
   * So this function will just generate new timestamp everytime it's called
   */
  reload () {
    this.triggerReload = Date.now()
    this.$scope.$digest()
  }
}

export default {
  templateUrl,
  controller: AccountBalanceController
}
