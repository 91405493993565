import templateUrl from './branch-information.pug'
import BaseController from 'presentation/common/BaseController'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'

class BranchInformation extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.selectedBranchDescription = null
    this.localIsEditing = true
  }

  $onInit () {
    if (this.branches && this.selectedBranchIdOnLoad) {
      this.branches.forEach(branch => {
        if (branch.id === this.selectedBranchIdOnLoad) {
          this.selectedBranchDescription = branch.description
        }
      })
    }
  }

  onBranchSelected (branch) {
    const branchInfo = branch && branch.description ? branch : null
    this.selectedBranchDescription = branch && branch.description ? branch.description : null
    this.handleBranchSelected({branch: branchInfo})
  }

  $onChanges (changeDescriptorsByKey) {
    const suggestions = getValueFromChangeDescriptors(changeDescriptorsByKey, 'branchHasBeenAutoSet', null)
    if (suggestions !== null) {
      this.isRequired = !this.branchHasBeenAutoSet
    }
  }
}

export default {
  templateUrl,
  controller: BranchInformation,
  bindings: {
    branches: '<',
    form: '=',
    handleBranchSelected: '&',
    isEditing: '<',
    isDisabled: '<',
    selectedBranchIdOnLoad: '<?',
    branchHasBeenAutoSet: '<',
    hideTitle: '<?',
    hostClass: '@?'
  }
}
