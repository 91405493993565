import AddressField from './AddressField'

export class PointOfInterest {
  constructor(
    public id: string = '',
    public addressFormatId: string = '',
    public title: string = '',
    public addressString: string = '',
    public fields: AddressField[] = [],
    public location: {latitude: string, longitude: string} = {latitude: '', longitude: ''},
  ) {}

  public static build (poiDto: any): PointOfInterest {
    return new PointOfInterest(
      poiDto.id,
      poiDto.addressFormatId,
      poiDto.title,
      poiDto.addressString,
      Array.isArray(poiDto.fields) ? poiDto.fields.map(AddressField.build) : [],
      poiDto.location
    )
  }
}
