import cloneDeep from 'lodash-es/cloneDeep'
import {StateService} from '@uirouter/core'
import BaseFormController from 'presentation/common/BaseFormController'
import Cost from 'data/domain-objects/Cost'
import { ShippingProviders } from 'common/constants/ShippingProviders'

const DELIVERY_MINIMUM_CART_KEY_PREFIX = 'storefront__services__delivery__minimumCart'
const COLLECTION_MINIMUM_CART_KEY_PREFIX = 'storefront__services__collection__minimumCart'
const DRAEWIL_DELIVERY_KEY_PREFIX = 'storefront__services__draewil__deliveryCost'
const OTHER_DELIVERY_KEY_PREFIX = 'storefront__services__delivery__deliveryCost'
const BUSINESS_HOURS_SETTING_KEY = 'storefront__businessHours'
const TIMESLOT_DURATIONE_SETTING_KEY = 'storefront__timeSlot__duration'

const EMPTY_BUSINESS_HOURS_OBJECT = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: []
}

export default class StorefrontSettingsController extends BaseFormController {
  public settings: any
  public localSettings: any
  public deliveryMinimumCartCost: Cost
  public collectionMinimumCartCost: Cost
  public draewilDeliveryCost: Cost
  public otherDeliveryCost: Cost
  public isSubmitFailed: boolean = false
  public isSubmitSucceded: boolean = false
  public isAtLeastOneServiceAvailable: boolean = false
  public deliveryProviders: typeof ShippingProviders = ShippingProviders

  constructor(
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  handleSettingsReceived (settings: any): any {
    this.settings = settings

    this.localSettings = {}

    Object.keys(settings).forEach((settingName: string): void => {
      this.localSettings[settingName] = cloneDeep(settings[settingName].value !== null ? settings[settingName].value : settings[settingName].defaultValue)
    })

    this.convertSettingsToCostValues()
    this.onServicesAvailabilityChanged()
  }

  onSettingChanged (settingKey: string, newValue: any = null, form: ng.IFormController): void {
    this.localSettings[settingKey] = newValue

    if (form && form.$setDirty) {
      form.$setDirty()
    }
  }

  onImageChanged (settingKey: string, newValue: any = null, form: ng.IFormController): void {
    this.onSettingChanged(settingKey, newValue, form)
    this.$scope.$digest()
  }

  onServicesAvailabilityChanged (): void {
    setTimeout(() => {
      this.isAtLeastOneServiceAvailable =
        this.localSettings.storefront__services__delivery__isAvailable || this.localSettings.storefront__services__collection__isAvailable

      this.$scope.$digest()
    })
  }

  convertSettingsToCostValues (): void {
    this.deliveryMinimumCartCost = Cost.build({
      currencyCode: this.localSettings[`${DELIVERY_MINIMUM_CART_KEY_PREFIX}__currencyCode`],
      value: this.localSettings[`${DELIVERY_MINIMUM_CART_KEY_PREFIX}__value`]
    })

    this.collectionMinimumCartCost = Cost.build({
      currencyCode: this.localSettings[`${COLLECTION_MINIMUM_CART_KEY_PREFIX}__currencyCode`],
      value: this.localSettings[`${COLLECTION_MINIMUM_CART_KEY_PREFIX}__value`]
    })

    this.draewilDeliveryCost = Cost.build({
      currencyCode: this.localSettings[`${DRAEWIL_DELIVERY_KEY_PREFIX}__currencyCode`],
      value: this.localSettings[`${DRAEWIL_DELIVERY_KEY_PREFIX}__value`]
    })

    this.otherDeliveryCost = Cost.build({
      currencyCode: this.localSettings[`${OTHER_DELIVERY_KEY_PREFIX}__currencyCode`],
      value: this.localSettings[`${OTHER_DELIVERY_KEY_PREFIX}__value`]
    })
  }

  convertCostToSettingValues (settingKeyPrefix: string = '', costObject: Cost): void {
    this.localSettings[`${settingKeyPrefix}__currencyCode`] = costObject.currencyCode
    this.localSettings[`${settingKeyPrefix}__value`] = costObject.value
  }

  sanitizeBusinessHours (): void {
    if (this.localSettings[BUSINESS_HOURS_SETTING_KEY]) {
      Object.keys(this.localSettings[BUSINESS_HOURS_SETTING_KEY]).forEach((weekday: string) => {
        this.localSettings[BUSINESS_HOURS_SETTING_KEY][weekday] = this.localSettings[BUSINESS_HOURS_SETTING_KEY][weekday]
          .filter((timerange: any): boolean => !!timerange.from && !!timerange.to)
      })
    } else {
      this.localSettings[BUSINESS_HOURS_SETTING_KEY] = EMPTY_BUSINESS_HOURS_OBJECT
    }
  }

  getUpdatedSettings (): any {
    this.convertCostToSettingValues(DELIVERY_MINIMUM_CART_KEY_PREFIX, this.deliveryMinimumCartCost)
    this.convertCostToSettingValues(COLLECTION_MINIMUM_CART_KEY_PREFIX, this.collectionMinimumCartCost)
    this.convertCostToSettingValues(DRAEWIL_DELIVERY_KEY_PREFIX, this.draewilDeliveryCost)
    this.convertCostToSettingValues(OTHER_DELIVERY_KEY_PREFIX, this.otherDeliveryCost)
    this.sanitizeBusinessHours()

    this.localSettings[TIMESLOT_DURATIONE_SETTING_KEY] = parseInt(this.localSettings[TIMESLOT_DURATIONE_SETTING_KEY] || 0) ?
      this.localSettings[TIMESLOT_DURATIONE_SETTING_KEY] : this.settings[TIMESLOT_DURATIONE_SETTING_KEY].defaultValue

    Object.keys(this.settings).forEach((settingName: string): void => {
      this.settings[settingName].value = this.localSettings[settingName]
      this.settings[settingName].isOverridden = JSON.stringify(this.settings[settingName].value) !== JSON.stringify(this.settings[settingName].defaultValue)
    })

    return this.settings
  }

  beforeSubmit (): Promise<any> {
    this.isSubmitFailed = false
    this.isSubmitSucceded = false

    return Promise.resolve({})
  }

  afterSubmitSuccess (settings: any): Promise<any> {
    this.handleSettingsReceived(settings)
    this.isSubmitSucceded = true
    return Promise.resolve(settings)
  }

  afterSubmitFailure (error: Error): Promise<any> {
    this.isSubmitFailed = true
    return super.afterSubmitFailure(error)
  }
}
