import StorefrontCategory from 'data/domain-objects/storefront/StorefrontCategory'
import cloneDeep from 'lodash-es/cloneDeep'
import { StateParams, StateService } from '@uirouter/core'
import businessesCollection from 'data/collections/businessesCollection'
import BaseController from 'presentation/common/BaseController'
import Setting from 'data/domain-objects/Setting'

const SUB_CATEGORIES_SETTINGS_KEYS = [
  'menuLayoutMode',
  'appearance__categoriesLayoutMode',
  'storefront__expandCategory',
  'appearance__overlays__displayForCategoryPhotos',
  'appearance__displayCategoryTitleAndNumberOfItems',
  'appearance__categoryTitleColor',
  'appearance__numberOfItemsInCategoryColor',
  'appearance__numberOfItemsInCategoryBackgroundColor',
  'appearance__productsLayoutMode'
]

const COLORS_SETTINGS_KEYS = [
  'appearance__primaryAccentColor',
  'appearance__secondaryAccentColor',
  'appearance__mainFontColor',
  'appearance__headerFontColor',
  'appearance__reverseFontColor',
  'appearance__sidebarColor',
  'appearance__sidebarTextColor',
  'appearance__priceBackgroundColor',
  'appearance__priceTextColor',
  'appearance__coverTextAndIconsColor',
  'appearance__coverTitleColor',
  'appearance__coverSubtitleColor'
]

class CategoryInheritedSettingsController extends BaseController {
  public form: ng.IFormController
  public isCreate: boolean
  public category: StorefrontCategory
  public localSettings: ISettingsGroup = {}
  public blocksOverrideState: IBlockOverrideStatesGroup
  public showLoadDefaultsFailedError: boolean

  private settings: ISettingsGroup = {}

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams
  ) {
    super($scope, $state)

    this.blocksOverrideState = {
      subCategoriesSettings: {
        fieldKeys: SUB_CATEGORIES_SETTINGS_KEYS,
        useDefault: true
      },
      colors: {
        fieldKeys: COLORS_SETTINGS_KEYS,
        useDefault: true
      }
    }
  }

  $onInit(): void {
    if (this.isCreate) {
      this.sync()
    }
  }

  $onChanges(): void {
    this.$scope.$watch('$ctrl.category', () => {
      if (this.category && this.category.settings && Object.keys(this.category.settings).length) {
        this.handleSettingsGiven(this.category.settings)
      }
    }, true)
  }

  onSync(): Promise<void> {
    this.showLoadDefaultsFailedError = false

    return businessesCollection.getStorefrontSettings(this.$stateParams.businessId)
      .then((settings: ISettingsGroup): void => {
        this.category.settings = this.getCategorySettingsFromBusinessSettings(settings)
        this.handleSettingsGiven(this.category.settings)
      })
      .catch((error: Error): void => {
        this.showLoadDefaultsFailedError = true
        this.logger.error('Cannot get business storefront settings as category defaults', error)
      })
  }

  getCategorySettingsFromBusinessSettings(settings: ISettingsGroup): ISettingsGroup {
    const categorySettings = {}

    Object.keys(this.blocksOverrideState).forEach((blockName: string): void => {
      this.blocksOverrideState[blockName].fieldKeys.forEach((settingName: string): void => {
        if (settings[settingName]) {
          categorySettings[settingName] = Setting.build({
            value: cloneDeep(settings[settingName].value),
            defaultValue: cloneDeep(settings[settingName].value),
            isOverridden: false
          })
        }
      })
    })

    return categorySettings
  }

  handleSettingsGiven(settings: ISettingsGroup): void {
    this.settings = cloneDeep(settings)

    Object.keys(this.blocksOverrideState).forEach((blockName: string): void => {
      this.blocksOverrideState[blockName].useDefault = true

      this.blocksOverrideState[blockName].fieldKeys.forEach((settingName: string): void => {
        if (settings[settingName]) {
          this.localSettings[settingName] = cloneDeep(
            settings[settingName].value !== null ? settings[settingName].value : settings[settingName].defaultValue
          )

          this.blocksOverrideState[blockName].useDefault = this.blocksOverrideState[blockName].useDefault && !settings[settingName].isOverridden
        }
      })
    })
  }

  onOverrideModeChanged(blockOverrideState: IBlockOverrideState, useDefault: boolean): void {
    blockOverrideState.useDefault = useDefault

    if (blockOverrideState.useDefault) {
      this.restoreDefaults(blockOverrideState.fieldKeys)
    }

    if (this.form) {
      this.form.$setDirty()
    }
  }

  restoreDefaults(fieldKeys: string[]): void {
    fieldKeys.forEach((settingName: string): void => {
      if (this.category.settings[settingName]) {
        this.localSettings[settingName] = cloneDeep(this.settings[settingName].defaultValue)
      }
    })

    this.onSettingsChanged()
  }

  onSettingsChanged(): void {
    setTimeout(() => {
      Object.keys(this.localSettings).forEach((settingName: string): void => {
        const isOverridden = JSON.stringify(this.category.settings[settingName].defaultValue) !== JSON.stringify(this.localSettings[settingName])
        this.category.settings[settingName].value = cloneDeep(this.localSettings[settingName])
        this.category.settings[settingName].isOverridden = isOverridden
      })

      if (this.form) {
        this.form.$setDirty()
      }

      this.$scope.$digest()
    })
  }
}

export default {
  templateUrl: require('./category-inherited-settings.pug'),
  controller: CategoryInheritedSettingsController,
  bindings: {
    form: '=',
    category: '=',
    isEditing: '<',
    isCreate: '<'
  }
}
