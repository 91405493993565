enum PaymentProviders {
  'KNET' = 'knet',
  'TAP' = 'tap',
  'CYBERSOURCE' = 'cybersource-sa-wm',
  'STRIPE' = 'stripe'
}

export const KNET = PaymentProviders.KNET
export const TAP = PaymentProviders.TAP
export const CYBERSOURCE = PaymentProviders.CYBERSOURCE
export const STRIPE = PaymentProviders.STRIPE
