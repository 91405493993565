import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import servicesDashboard from './dashboard/servicesDashboard'
import serviceForm from './service-form/serviceForm'

export default declareAppModule('logistics.services', (module: ng.IModule) => module
  .component('servicesDashboard', servicesDashboard)
  .component('serviceForm', serviceForm)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'services',
      stateKey: 'SERVICES',
      componentName: 'servicesDashboard',
      requiresPermission: 'SERVICES'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.services',
      stateName: 'create',
      stateKey: 'CREATE_SERVICE',
      params: ['targetState'],
      componentName: 'serviceForm',
      requiresPermission: 'SERVICES_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.services',
      stateName: 'service',
      stateKey: 'SERVICE',
      idParam: 'serviceId',
      componentName: 'serviceForm'
    })
  })
)
