import get from 'lodash-es/get'
import templateUrl from './request-form.pug'
import RequestedTime from 'data/domain-objects/RequestedTime'
import BaseFormController from 'presentation/common/BaseFormController'
import branchesCollection from 'data/collections/branchesCollection'
import businessesCollection from 'data/collections/businessesCollection'
import Cost from 'data/domain-objects/Cost'
import Payment from 'data/domain-objects/Payment'
import * as PaymentMethods from 'common/constants/PaymentMethods'
import * as PaymentMethodsSettings from 'common/constants/PaymentMethodsSettings'
import requestsCollection from 'data/collections/requestsCollection'
import addressCollection from 'data/collections/addressCollection'
import debounce from 'lodash-es/debounce'
import DELIVERY_CREATION_ERRORS from 'common/constants/DeliveryCreationErrors'
import {convertVisibilitySettingValueToBoolean} from 'presentation/delivery-create/CreateDeliveryStateManager'
import {PICK_UP_FROM_BRANCH} from 'common/constants/DeliveryTypes'

const ADDRESS_SAVE_DEBOUNCE_DELAY = 1500
const ERROR_NOT_ENOUGHT_FUNDS = 'notEnoughFunds'
const ERROR_NO_FLEETS_AVAILABLE = 'noFleetsAvailable'
const ADDRESS_FORM_ACCESSOR = '$ctrl.addressForm'

class RequestFormController extends BaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.settings = null
    this.branches = []
    this.addressSubmitHandler = null
    this.cost = null
    this.requestAddressHints = {}
    this.errors = {}
    this.showPackageDetails = false
    this.showAvailableFleets = false
    this.showProofOfDelivery = false
    this.showAddressLocationMap = true
    this.deliveryType = PICK_UP_FROM_BRANCH
    this.availablePaymentMethods = []
    this.isBranchSettingsLoading = false

    this.attachAddressSubmitHandler = handler => this.handleAttachAddressSubmitHandler(handler)
  }

  $onInit () {
    this.getAllBranches()

    if (!this.request.id) {
      this.errors.loading = true
    }
    this.buildAddressHints()
    this.request.pickupTime = new RequestedTime()
    this.$scope.$watch(() => this.request.packages, () => this.onPackageSelected(), true)

    this.$scope.$watch(() => this.request.fleetId, () => {
      const fleetFound = !!this.request.fleetId
      this.requestForm.$setValidity(ERROR_NO_FLEETS_AVAILABLE, fleetFound)
    })

    this.onAddressChange = debounce((...args) => this.saveAddress(...args), ADDRESS_SAVE_DEBOUNCE_DELAY)
  }

  saveAddress (address) {
    if (this.requestForm && this.requestForm[ADDRESS_FORM_ACCESSOR] && this.requestForm[ADDRESS_FORM_ACCESSOR].$valid) {
      this.request.recipient.address = address
      return addressCollection
        .updateAddress(this.request.recipient.address.id, this.request.recipient.address)
        .catch(err => {
          this.logger.warn('Error saving address', err)
        })
    }
  }

  onPackageSelected () {
    if (this.request.packages && Object.keys(this.request.packages).length > 0) {
      this.showAvailableFleets = true
    }
  }

  buildAddressHints () {
    const addressFields = get(this, 'request.recipient.address.fields', [])

    addressFields.forEach(field => {
      this.requestAddressHints[field.name] = field.originalValue
    })
  }

  onBranchSelected (branch) {
    this.resetSettings()
    if (branch) {
      this.request.sender.id = branch.id
      this.request.sender.address = branch.addressId
      this.request.sender.businessId = branch.businessId
      this.request.sender.name = branch.name
      this.request.balanceAccountId = null
      this.getBranchSettings(this.request.sender.businessId, branch.id)
        .catch(error => this.afterSubmitFailure(error))
        .then(() => this.$scope.$digest())
    }
  }

  $onChanges (changeDescriptor) {
    if (changeDescriptor.request && this.request) {
      this.cost = Cost.build({amount: this.request.orderCost.amount, currencyCode: this.request.orderCost.currency})

      this.paymentType = (this.request.orderCost.paymentType || PaymentMethods.CASH).toLowerCase()
    }
  }

  handleAttachAddressSubmitHandler (handler) {
    this.addressSubmitHandler = handler
  }

  getAllBranches () {
    businessesCollection.getBusinessesWithTheirBranches()
      .then(businessesWithBranches => this.handleBusinessesWithBranchesReceived(businessesWithBranches))
      .then(() => {
        if (this.request.sender.businessId) {
          return this.getBranchSettings(this.request.sender.businessId, this.request.sender.id)
        } else {
          return Promise.resolve()
        }
      })
      .catch(error => this.afterSubmitFailure(error))
      .then(() => this.$scope.$digest())
  }

  handleBusinessesWithBranchesReceived (businessesWithBranches) {
    const allBranches = []
    businessesWithBranches
      .forEach(businessWithBranches => {
        get(businessWithBranches, 'branches', []).forEach(branch => {
          if (this.request.sender.id === branch.id) {
            this.request.sender.businessId = businessWithBranches.id
          }
          branch.businessId = businessWithBranches.id
          branch.businessName = businessWithBranches.name
          branch.description = `${branch.businessName} - ${branch.name}`// TODO: refactor to use Branch object
          allBranches.push(branch)
        })
      })
    this.branches = allBranches
    if (this.branches.length === 1) {
      this.onBranchSelected(this.branches[0])
    }
    this.branchesLoaded = true
  }

  resetSettings () {
    this.settings = null
    this.showPackageDetails = false
    this.showAddressLocationMap = false
    this.availablePaymentMethods = []
    this.showProofOfDelivery = false
    this.paymentType = (this.request.orderCost.paymentType || PaymentMethods.CASH).toLowerCase()
  }

  getBranchSettings (businessId, branchId) {
    this.isBranchSettingsLoading = true
    return branchesCollection.getBranchSettings(businessId, branchId, {merge: true})
      .then(settings => {
        this.isBranchSettingsLoading = false
        this.settings = settings
        this.showPackageDetails = convertVisibilitySettingValueToBoolean(settings, 'showPackageType')
        this.showAddressLocationMap = convertVisibilitySettingValueToBoolean(settings, 'showConsumerAddressLocationMap')

        if (settings[PaymentMethodsSettings.REQUEST_DEFAULT_PAID_OPTION_NAME].value) {
          this.availablePaymentMethods.push(settings[PaymentMethodsSettings.REQUEST_DEFAULT_PAID_OPTION_NAME].value)
        }
        if (settings[PaymentMethodsSettings.REQUEST_DEFAULT_CASH_OPTION_NAME].value) {
          this.availablePaymentMethods.push(settings[PaymentMethodsSettings.REQUEST_DEFAULT_CASH_OPTION_NAME].value)
        }

        if (this.paymentType === PaymentMethods.CARD) {
          this.paymentType = settings[PaymentMethodsSettings.REQUEST_DEFAULT_PAID_OPTION_NAME].value || settings[PaymentMethodsSettings.REQUEST_DEFAULT_CASH_OPTION_NAME].value
        } else {
          this.paymentType = settings[PaymentMethodsSettings.REQUEST_DEFAULT_CASH_OPTION_NAME].value || settings[PaymentMethodsSettings.REQUEST_DEFAULT_PAID_OPTION_NAME].value
        }

        const showProofOfDeliverySetting = convertVisibilitySettingValueToBoolean(settings, 'showProofOfDeliverySettings')
        const photoIdEnabled = get(settings, 'proofOfDeliveryPhotoIdentificationEnabled.value', false)
        const signatureEnabled = get(settings, 'proofOfDeliverySignatureEnabled.value', false)

        // this will prevent showing of batching/status in otherInformation component
        this.settings.showDeliveryStatus.value = 'hide'
        this.settings.showBatching.value = 'hide'

        this.showProofOfDelivery = (showProofOfDeliverySetting && (photoIdEnabled || signatureEnabled))
      })
  }

  beforeSubmit () {
    if (this.request.confirmed) {
      return Promise.resolve()
    }

    this.request.orderCost = new Payment(this.cost, this.paymentType)
    return requestsCollection.confirmRequest(this.request.id, this.request)
  }

  afterSubmitSuccess () {
    this.onConfirmSuccess()
    if (this.requestForm) {
      this.requestForm.$setValidity(ERROR_NOT_ENOUGHT_FUNDS, true)
    }
  }

  afterSubmitFailure (error) {
    const errMessage = error && error.message || ''

    switch (errMessage) {
    case DELIVERY_CREATION_ERRORS.INSUFFICIENT_FUNDS:
      this.errors.funds = true
      this.requestForm.$setValidity(ERROR_NOT_ENOUGHT_FUNDS, false)

      break
    default:
      this.errors.confirmation = true
    }

    this.logger.error('Failed to confirm request', this.request, ' Error message', error)
  }
}

export default {
  controller: RequestFormController,
  templateUrl,
  bindings: {
    onConfirmSuccess: '&',
    request: '<',
    isEditing: '<'
  }
}
