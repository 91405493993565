import CrudService from 'data/services/CrudService'
import endpoints from 'common/endpoints'
import DateUtils from 'common/utils/dateUtils'

const transactionsService = new CrudService(endpoints.STOREFRONT_TRANSACTIONS)

export default {
  getTransactions: (
    businessId: string,
    posReference?: string,
    startDate?: any,
    endDate?: any,
    limit: number = 1000,
    skip: number = 0): Promise<any> => {
    const params: any = {
      limit,
      skip,
    }

    if (posReference) {
      params.posReference = posReference
    }

    if (startDate) {
      params.createdAfter = DateUtils.getFormattedDate(startDate)
    }

    if (endDate) {
      params.createdBefore = DateUtils.getFormattedDate(endDate)
    }

    return transactionsService.get([businessId], params)
  }
}
