import {StateService} from '@uirouter/core'
import envConfig from 'common/envConfig'
import permissionsModel from 'data/models/permissionsModel'
import featuresModel from 'data/models/featuresModel'
import uiSettingsService from 'data/services/uiSettingsService'
import {isSameDomain} from 'common/utils/urlUtils'
import { DomainBasedRoles } from 'common/constants/DomainBasedRoles'

class SidebarController {
  public isStorefrontAdminDomain: boolean = isSameDomain(envConfig.platformDomains.storefrontAdminDomain)

  constructor ($scope: ng.IScope, $state: StateService) {
    this.sidebarIsExpanded = uiSettingsService.isSidebarExpanded
    this.state = $state
    this.$scope = $scope
    this.version = envConfig.version
    this.environment = envConfig.environment

    $scope.$watch(() => uiSettingsService.isSidebarExpanded, (isSidebarExpanded: boolean) => {
      this.sidebarIsExpanded = isSidebarExpanded
    })

    this.menuItems = {
      dashboard: true,
      addresses: permissionsModel.getHasPermission('ADDRESSES'),
      kanban: permissionsModel.getHasPermission('TRACKING') || permissionsModel.getHasPermission('DELIVERIES'),
      requests: permissionsModel.getHasPermission('REQUESTS'),
      confirmedRequests: permissionsModel.getHasPermission('REQUESTS'),
      scheduledDashboard: permissionsModel.getHasPermission('DELIVERIES'),
      nextDayDeliveries: permissionsModel.getHasPermission('DELIVERIES_NEXT_DAY'),
      nextDayLinkedDeliveries: permissionsModel.getHasPermission('DELIVERIES_LINKED_NEXT_DAY'),
      businesses: permissionsModel.getHasPermission('BUSINESSES'),
      businessBalance: permissionsModel.getHasPermission('BUSINESSES_CREATE'),
      logistics: permissionsModel.getHasPermission('LOGISTICS'),
      logisticsFleets: permissionsModel.getHasPermission('FLEETS'),
      logisticsDrivers: permissionsModel.getHasPermission('DRIVERS'),
      logisticsTariffPeriods: permissionsModel.getHasPermission('TARIFF_PERIODS'),
      logisticsAreas: permissionsModel.getHasPermission('AREAS'),
      logisticsSchedule: permissionsModel.getHasPermission('SCHEDULE'),
      logisticsServices: permissionsModel.getHasPermission('SERVICES'),
      logisticsTracking: permissionsModel.getHasPermission('TRACKING'),
      logisticsIssues: permissionsModel.getHasPermission('ISSUES'),
      storefront: permissionsModel.getHasPermission('STOREFRONT'),
      storefrontPayouts: permissionsModel.getHasPermission('STOREFRONT'),
      storefrontTransactions: permissionsModel.getHasPermission('STOREFRONT'),
      storefrontSalesReport: permissionsModel.getHasPermission('STOREFRONT'),
      storefrontDeliveryTime: permissionsModel.getHasPermission('STOREFRONT'),
      storefrontPromoCodes: permissionsModel.getHasPermission('STOREFRONT'),
      tools: permissionsModel.getHasPermission('TOOLS'),
      vouchers: permissionsModel.getHasPermission('VOUCHERS'),
      places: permissionsModel.getHasPermission('PLACES'),
      promoCodes: permissionsModel.getHasPermission('PROMO_CODES'),
      users: permissionsModel.getHasPermission('USER_MANAGEMENT'),
      reports: permissionsModel.getHasPermission('REPORTS'),
      account: permissionsModel.getHasPermission('ACCOUNT'),
      consumerBalance: true,
      paymentMethods: featuresModel.isFeatureEnabled('ADD_PAYMENT_METHODS'),
      liveOrders: permissionsModel.getHasPermission('REPORTS_LIVE_ORDERS'),
      driverAnalytics: permissionsModel.getHasPermission('REPORTS_DRIVER_ANALYTICS'),
      areaAnalytics: featuresModel.isFeatureEnabled('AREAS_REPORT') && permissionsModel.getHasPermission('REPORTS_AREA_ANALYTICS'),
      surgePricing: permissionsModel.getHasPermission('PRICE_SURGE')
    }
  }

  public sidebarIsExpanded: boolean
  public state: object
  public $scope: ng.IScope
  public version: string
  public environment: string
  public menuItems: object

  public domainBasedRoles: typeof DomainBasedRoles = DomainBasedRoles
  public domainBasedRole: DomainBasedRoles

  $onInit(): void {
    if (isSameDomain(envConfig.platformDomains.tazDeliveryDomain)) {
      this.domainBasedRole = DomainBasedRoles.TAZ
    } else if (isSameDomain(envConfig.platformDomains.sahalatDeliveryDomain)) {
      this.domainBasedRole = DomainBasedRoles.SAHALAT_EXPRESS
    } else if (isSameDomain(envConfig.platformDomains.storefrontAdminDomain)) {
      this.domainBasedRole = DomainBasedRoles.ORDER_DIRECT_ADMIN
    } else {
      this.domainBasedRole = DomainBasedRoles.DRAEWIL_DELIVERY_USER
    }
  }

  public static toggleSidebarState (): void {
    uiSettingsService.isSidebarExpanded = !uiSettingsService.isSidebarExpanded
  }

  static setSubmenuPosition (event: any): void {
    const subMenu = event.target.querySelector('.sidebar_sub-menu')
    if (!subMenu) {
      return
    }

    const menuElementParams = event.target.getBoundingClientRect()

    subMenu.style.top = (menuElementParams.top) + 'px'
    subMenu.style.bottom = 'unset'
    const subMenuParams = subMenu.getBoundingClientRect()

    if (subMenuParams.height && subMenuParams.top && Math.round(subMenuParams.top + subMenuParams.height) >= window.innerHeight) {
      subMenu.style.top = 'unset'
      subMenu.style.bottom = '0px'
    } else {
      subMenu.style.bottom = 'unset'
      subMenu.style.top = (menuElementParams.top) + 'px'
    }
  }

  $postLink (): void {
    const menuItems = document.querySelectorAll('.sidebar_menu-item')
    Array.prototype.slice.call(menuItems).forEach((menuItem: any) => {
      menuItem.addEventListener('mouseenter', SidebarController.setSubmenuPosition)
    })
  }

  $onDestroy (): void {
    const menuItems = document.querySelectorAll('.sidebar_menu-item')
    Array.prototype.slice.call(menuItems).forEach((menuItem: any) => {
      menuItem.removeEventListener('mouseenter', SidebarController.setSubmenuPosition)
    })
  }
}

export default {
  templateUrl: require('./sidebar.pug'),
  controller: SidebarController
}
