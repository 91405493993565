import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import fleetSelect from './fleet-select/fleetSelect'
import logisticsDashboard from './dashboard/logisticsDashboard'
import multipleFleetsPicker from './multiple-fleets-picker/multipleFleetsPicker'

import logisticsAreasModule from 'presentation/logistics-areas/logisticsAreasModule'
import logisticsDriversModule from 'presentation/logistics-drivers/logisticsDriversModule'
import logisticsFleetsModule from 'presentation/logistics-fleets/logisticsFleetsModule'
import logisticsServicesModule from 'presentation/logistics-services/logisticsServicesModule'
import logisticsTariffPeriodsModule from 'presentation/logistics-tariff-periods/logisticsTariffPeriodsModule'
import logisticsTrackingModule from 'presentation/logistics-tracking/logisticsTrackingModule'
import logisticsScheduleModule from 'presentation/logistics-schedule/logisticsScheduleModule'
import logisticsIssuesModule from 'presentation/logistics-issues/logisticsIssuesModule'
import surgePricingModule from 'presentation/logistics/surge-pricing/surgePricingModule'

export default declareAppModule('logistics', (module: ng.IModule) => module
  .component('multipleFleetsPicker', multipleFleetsPicker)
  .component('logisticsDashboard', logisticsDashboard)
  .component('fleetSelect', fleetSelect)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'logistics',
      componentName: 'logisticsDashboard',
      stateKey: 'LOGISTICS',
      requiresPermission: 'LOGISTICS'
    })
  }),
[
  logisticsAreasModule,
  logisticsDriversModule,
  logisticsFleetsModule,
  logisticsServicesModule,
  logisticsTariffPeriodsModule,
  logisticsTrackingModule,
  logisticsScheduleModule,
  logisticsIssuesModule,
  surgePricingModule
]
)
