import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import {AddressCollectionFactory} from 'data/collections/addressCollection'
import {RequestConfirmation} from 'data/domain-objects/RequestConfirmation'

const requestService = new CrudService(endpoints.REQUEST)
const requestsActionRequiredService = new CrudService(endpoints.REQUESTS_ACTION_REQUIRED)
const requestsConfirmed = new CrudService(endpoints.REQUESTS_CONFIRMED)
const requestCheck = new CrudService(endpoints.REQUEST_CHECK)
const addressService = new CrudService(endpoints.REQUEST_ADDRESS)
const addressFieldsService = new CrudService(endpoints.ADDRESS_FIELD_VALUES)
const addressFormatsService = new CrudService(endpoints.ADDRESS_FORMATS)
const addressFormatService = new CrudService(endpoints.ADDRESS_FORMAT)

const requestAddressCollection = new AddressCollectionFactory(addressService, addressFieldsService, addressFormatsService, addressFormatService)

const getAddress = request => {
  if (!request.recipient || !request.recipient.address) {
    return request
  }
  request.addressId = request.recipient && request.recipient.address
  return requestAddressCollection.get(request.addressId)
    .then(address => {
      request.recipient.address = address
      return request
    })
}

export default {
  createRequest: request => {
    return requestService.create(request).then(getAddress)
  },
  requestCheck: requestId => {
    return requestCheck.get([requestId])
  },
  confirmRequest: (requestId, request) => {
    const requestConfirmation = new RequestConfirmation(request)

    return requestService.update(requestConfirmation, [requestId])
  },
  getRequestToConfirm: requestId => {
    return requestService.get([requestId]).then(getAddress)
  },
  getAllRequestsThatRequireAction: (limit, skip) => {
    return requestsActionRequiredService.get([], {limit, skip})
  },
  getAllConfirmedRequests: (limit, skip) => {
    return requestsConfirmed.get([], {limit, skip})
  }
}
