import DriverBaseFormController from 'presentation/logistics-drivers/common/DriverBaseFormController'
import { StateService, StateParams } from '@uirouter/core'

class DriverAddVehicleController extends DriverBaseFormController {
  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams,
  ) {
    super($scope, $state, $stateParams)
  }

  afterSubmitSuccess (): Promise<void> {
    return Promise.resolve().then(() => this.goToAssignToFleets())
  }
}

export default {
  templateUrl: require('./driver-add-vehicle.pug'),
  controller: DriverAddVehicleController
}
