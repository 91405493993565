import envConfig from 'common/envConfig'

class BusinessBalanceAndSubscriptions {
  public presetTopUpAmounts: number[] = envConfig.payments.businessManager.presetTopUpAmounts
  public paymentProcessedPath: string = envConfig.payments.businessManager.paymentProcessedPath
  public triggerReload: number = Date.now()

  constructor (private $scope: ng.IScope) {}

  reload (): void {
    this.triggerReload = Date.now()
    this.$scope.$digest()
  }
}

export default {
  templateUrl: require('./businesses-balance-and-subscriptions.pug'),
  controller: BusinessBalanceAndSubscriptions
}
