import declareAppModule from 'presentation/_utilities/declareAppModule'

import autocompleteInput from './autocomplete-input/autocompleteInput'
import autosuggestInput from './autosuggest-input/autosuggestInput'
import businessBranchSelect from './business-branch-select/businessBranchSelect'
import fleetsPicker from './fleets-picker/fleetsPicker'

import cardPicker from './card-picker/cardPicker'
import cardPickerItem from './card-picker/card-picker-item/cardPickerItem'
import checkboxInput from './checkbox-input/checkboxInput'
import checkbox from './checkbox/checkbox'
import dropdown from './dropdown/dropdown'
import numberInput from './number-input/numberInput'
import currencyAndCostInput from './currency-and-cost-input/currencyAndCostInput'
import editableFormPanel from './editable-form-panel/editableFormPanel'
import editableListCurrencyAndCost from './editable-list-currency-and-cost/editableListCurrencyAndCost'
import editableListItem from './editable-list-item/editableListItem'
import editableListItemColorPicker from './editable-list-item-color-picker/editableListItemColorPicker'
import editableListTextarea from './editable-list-textarea/editableListTextarea'
import editableListItemRadio from './editable-list-item-radio/editableListItemRadio'
import editableListSelect from './editable-list-select/editableListSelect'
import editableListAreaPolygon from './editable-list-area-polygon/editableListAreaPolygon'
import editableListAreaEditor from './editable-list-area-editor/editableListAreaEditor'
import editableListImage from './editable-list-image/editableListImage'
import editableListToggle from './editable-list-toggle/editableListToggle'
import editableListDateRange from './editable-list-date-range/editableListDateRange'
import fileInput from './file-input/fileInput'
import formActions from './form-actions/formActions'
import formAction from './form-action/formAction'
import formMessages from './form-messages/formMessages'
import formItem from './form-item/formItem'
import formPanel from './form-panel/formPanel'
import formPanelItem from './form-panel-item/formPanelItem'
import matchIndicator from './match-indicator/matchIndicator'
import imagePicker from './image-picker/imagePicker'
import imageUploader from './image-uploader/imageUploader'
import inlineFieldGroup from './inline-field-group/inlineFieldGroup'
import listSelect from './list-select/listSelect'
import packagesBuilder from './packages-builder/packagesBuilder'
import radio from './radio/radio'
import radioButton from './radio-button/radioButton'
import radioGroup from './radio-group/radioGroup'
import searchField from './search-field/searchField'
import settingsBlock from './settings-block/settingsBlock'
import toggle from './toggle/toggle'
import combinedPhoneInput from './combined-phone-input/combinedPhoneInput'
import entityDeliverySettings from './entity-delivery-settings/entityDeliverySettings'
import localAutoCompleteInput from './local-auto-complete-input/localAutoCompleteInput'
import warningMessage from './warning-message/warningMessage'
import dateTimePicker from './date-time-picker/dateTimePicker'
import fancyTimePicker from './fancy-time-picker/fancyTimePicker'
import backendValidation from './backend-validation/backendValidation'
import combinedTextInput from './combined-text-input/combinedTextInput'
import disallowedValues from './disallowed-values/disallowedValues'
import editablePhoneInput from './editable-phone-input/editablePhoneInput'
import standardisedForm from './standardised-form/standardisedForm'
import timePicker from './time-picker/timePicker'
import multipleAreasPicker from './multiple-areas-picker/multipleAreasPicker'
import threeStateCheckbox from './three-state-checkbox/threeStateCheckbox'
import requestedTimeInput from './requested-time-input/requestedTimeInput'
import businessHours from './business-hours/businessHours'

// Address Editor
import addressEditor from './address-editor/addressEditor'
import findAddressDialog from './address-editor/find-address-dialog/findAddressDialog'
import multilangAddressEditor from './multilang-address-editor/multilangAddressEditor'

// EVIL and it's relatives
import dynamicAddress from './dynamic-address/dynamicAddress'
import addressAutoSuggestionList from './dynamic-address/address-auto-suggestion-list/addressAutoSuggestionList'
import addressEntryTypePicker from './dynamic-address/address-entry-type-picker/addressEntryTypePicker'
import addressLocation from './dynamic-address/address-location/addressLocation'
import addressLookupPaci from './dynamic-address/address-entry-type-picker/address-lookup-paci/addressLookupPaci'
import addressLookupPointOfInterest from './dynamic-address/address-entry-type-picker/address-lookup-point-of-interest/addressLookupPointOfInterest'
import editableListSelectWithImage from './editable-list-select-with-image/editableListSelectWithImage'


export default declareAppModule('core.forms', (module: ng.IModule) => module
  .component('addressEntryTypePicker', addressEntryTypePicker)
  .component('addressLookupPaci', addressLookupPaci)
  .component('addressLookupPointOfInterest', addressLookupPointOfInterest)
  .component('autocompleteInput', autocompleteInput)
  .component('autosuggestInput', autosuggestInput)
  .component('businessBranchSelect', businessBranchSelect)
  .component('fleetsPicker', fleetsPicker)
  .component('cardPicker', cardPicker)
  .component('cardPickerItem', cardPickerItem)
  .component('checkboxInput', checkboxInput)
  .component('checkbox', checkbox)
  .component('dropdown', dropdown)
  .component('numberInput', numberInput)
  .component('currencyAndCostInput', currencyAndCostInput)
  .component('editableFormPanel', editableFormPanel)
  .component('editableListCurrencyAndCost', editableListCurrencyAndCost)
  .component('editableListItem', editableListItem)
  .component('editableListItemColorPicker', editableListItemColorPicker)
  .component('editableListTextarea', editableListTextarea)
  .component('editableListItemRadio', editableListItemRadio)
  .component('editableListSelect', editableListSelect)
  .component('editableListAreaPolygon', editableListAreaPolygon)
  .component('editableListAreaEditor', editableListAreaEditor)
  .component('editableListImage', editableListImage)
  .component('editableListToggle', editableListToggle)
  .component('editableListDateRange', editableListDateRange)
  .component('editableListSelectWithImage', editableListSelectWithImage)
  .component('fileInput', fileInput)
  .component('formActions', formActions)
  .component('formAction', formAction)
  .component('formMessages', formMessages)
  .component('formItem', formItem)
  .component('formPanel', formPanel)
  .component('formPanelItem', formPanelItem)
  .component('matchIndicator', matchIndicator)
  .component('imagePicker', imagePicker)
  .component('imageUploader', imageUploader)
  .component('inlineFieldGroup', inlineFieldGroup)
  .component('listSelect', listSelect)
  .component('packagesBuilder', packagesBuilder)
  .component('radio', radio)
  .component('radioButton', radioButton)
  .component('radioGroup', radioGroup)
  .component('searchField', searchField)
  .component('settingsBlock', settingsBlock)
  .component('toggle', toggle)
  .component('combinedPhoneInput', combinedPhoneInput)
  .component('entityDeliverySettings', entityDeliverySettings)
  .component('addressLocation', addressLocation)
  .component('localAutoCompleteInput', localAutoCompleteInput)
  .component('addressAutoSuggestionList', addressAutoSuggestionList)
  .component('combinedTextInput', combinedTextInput)
  .component('dynamicAddress', dynamicAddress)
  .component('editablePhoneInput', editablePhoneInput)
  .component('timePicker', timePicker)
  .component('warningMessage', warningMessage)
  .component('dateTimePicker', dateTimePicker)
  .component('fancyTimePicker', fancyTimePicker)
  .component('multipleAreasPicker', multipleAreasPicker)
  .component('threeStateCheckbox', threeStateCheckbox)
  .component('addressEditor', addressEditor)
  .component('findAddressDialog', findAddressDialog)
  .component('multilangAddressEditor', multilangAddressEditor)
  .directive('backendValidation', backendValidation)
  .directive('disallowedValues', disallowedValues)
  .directive('standardisedForm', standardisedForm)
  .component('requestedTimeInput', requestedTimeInput)
  .component('businessHours', businessHours)
)
