import Cost from './Cost'
import * as PaymentMethods from 'common/constants/PaymentMethods'

interface IBrokenCostDTO {
  amount?: number,
  value?: number,
  currencyCode?: string
}

export default class Payment extends Cost {
  constructor (
    costDto: IBrokenCostDTO = {},
    public paymentType: PaymentMethods.PaymentMethod = PaymentMethods.CASH
  ) {
    super(costDto.amount || costDto.value, costDto.currencyCode)
  }

  getFormattedCost (noCurrencyCode: boolean = false): string {
    if (this.paymentType === PaymentMethods.CASH_OFF_SYSTEM || this.paymentType === PaymentMethods.PAID_OFF_SYSTEM) {
      return 'N/A'
    } else {
      return super.getFormattedCost(noCurrencyCode)
    }
  }
}
