import templateUrl from './business-onboarding-panel.pug'
import BaseController from 'presentation/common/BaseController'
import businessesCollection from 'data/collections/businessesCollection'

class BusinessOnboardingPanelController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.businesses = []
    this.hasCompletedOnboarding = false // Assume false until we have businesses back
    this.sync()
  }

  onSync () {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then(response => this.handleBusinessesSuccess(response))
      .catch(err => this.logger.error('Cannot get businesses with their branches', err))
  }

  handleBusinessesSuccess (businesses) {
    this.businesses = businesses
    this.hasCompletedOnboarding = this.getHasCompletedOnboarding()
  }

  getHasCompletedOnboarding () {
    const hasAtLeastOneBusinessWithBranch = this.businesses.reduce((accum, business) => {
      return accum || !!(business.branches && business.branches.length)
    }, false)
    return this.$state.params.hasCompletedOnboarding === 'true' && hasAtLeastOneBusinessWithBranch
  }
}

export default {
  templateUrl,
  controller: BusinessOnboardingPanelController
}
