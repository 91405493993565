export default {
  templateUrl: require('./editable-list-textarea.pug'),
  bindings: {
    form: '<',
    isEditing: '<',
    model: '=',
    name: '@',
    placeholder: '@',
    isRequired: '<',
    title: '@',
    valueMessage: '@',
    valueWarning: '@',
    hostClass: '@?'
  }
}
