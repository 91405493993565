import BaseController from 'presentation/common/BaseController'
import storefrontCategoriesCollection from 'data/collections/storefrontCategoriesCollection'
import localStorageService from 'data/services/localStorageService'
import {StateService} from '@uirouter/core'
import StorefrontCategory from 'data/domain-objects/storefront/StorefrontCategory'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'

class CategoriesController extends BaseController {
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public categories: StorefrontCategory[] = []
  public searchQuery: string = ''

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)

    if (this.businessId) {
      this.sync()
    }
  }

  onSync (): Promise<any> {
    return storefrontCategoriesCollection.getAll(this.businessId, this.searchQuery)
      .then((categories: StorefrontCategory[]): void => {
        this.categories = categories.map((category: StorefrontCategory): StorefrontCategory => StorefrontCategory.build(category))
      })
  }

  onBusinessSelected (businessOrBranch: any): void {
    this.businessId = businessOrBranch.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)

    if (this.businessId) {
      this.sync()
    } else {
      this.categories = []
    }
  }

  doSearch (searchQuery: string): void {
    this.searchQuery = searchQuery
    this.sync()
  }

  moveCategoryUp (categoryIndex: number): void {
    const newIndex = categoryIndex - 1

    if (newIndex >= 0) {
      this.moveCategory(categoryIndex, newIndex)
    }
  }

  moveCategoryDown (categoryIndex: number): void {
    const newIndex = categoryIndex + 1

    if (newIndex < this.categories.length) {
      this.moveCategory(categoryIndex, newIndex)
    }
  }

  moveCategory (fromIndex: number, toIndex: number): void {
    this.categories.splice(toIndex, 0, this.categories.splice(fromIndex, 1)[0])

    this.isLoading = true
    storefrontCategoriesCollection.updateCategoriesOrder(this.businessId, this.categories.map((category: StorefrontCategory): string => category.id))
      .then((): void => {
        this.isLoading = false
        this.$scope.$digest()
      })
      .catch((error: Error): void => {
        this.logger.error('Cannot update categories order', error)
      })
  }

  deleteEntity (params: {payload: {businessId: string, categoryId: string}}): void | Promise<any> {
    return storefrontCategoriesCollection.deleteCategory(params.payload.businessId, params.payload.categoryId)
      .catch((error: Error): void => this.logger.error('Cannot delete category', error))
      .then((): void => this.sync())
      .catch((error: Error): void => this.logger.error('Cannot get categories', error))
  }
}

export default {
  controller: CategoriesController,
  templateUrl: require('./categories.pug')
}
