import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import Cost from 'data/domain-objects/Cost'
import BalanceHistoryBaseController from 'presentation/common/BalanceHistoryBaseController'
import { StateService } from '@uirouter/core'
import storefrontTransactionsCollection from 'data/collections/storefrontTransactionsCollection'
import { StorefrontOrdersSorting } from 'data/collections/storefrontOrdersCollection'

const COLUMNS_CONFIGURATION = [
  { name: 'date', title: 'DATE', sortable: true, sortKey: 'createdAt', icon: 'time' },
  { name: 'businessBranchName', title: 'BRANCH_BUSINESS_NAME' },
  { name: 'pos', title: 'POS' },
  { name: 'net', title: 'NET' },
  { name: 'amount', title: 'AMOUNT' },
  { name: 'fee', title: 'FEE' },
  { name: 'type', title: 'TYPE' },
]

class StorefrontTransactionsReportPage extends BalanceHistoryBaseController {

  public columns: any = COLUMNS_CONFIGURATION
  public filtersInitialized: boolean = false
  public rowsTotalCount: number
  public rows: any = []
  public currentBusinessBalance: Cost = null

  constructor(
    $scope: ng.IScope,
    $state: StateService,
    $filter: ng.IFilterService
  ) {
    super($scope, $state, $filter)

    this.$scope.$watch('$ctrl.triggerDataReload', (newTimestamp: number, oldTimestamp: number) => this.conditionallyResync(newTimestamp, oldTimestamp))
  }

  conditionallyResync(newTimestamp: number, oldTimestamp: number): void { // Only re-sync on change
    if (oldTimestamp && newTimestamp !== oldTimestamp) {
      this.sync()
    }
  }

  onSync(): Promise<any> {
    const filters = Object.assign({}, this.filters)
    if (!this.filtersInitialized) {
      filters.includeFilters = true
      this.filtersInitialized = true
    }

    if (!this.filters.businessId) {
      const emptyResponse = {
        metadata: {
          skip: null,
          limit: null,
          count: 0
        },
        records: []
      }

      return Promise.resolve(emptyResponse)
        .then((response: any) => this.processData(response))
    }

    return storefrontTransactionsCollection.getTransactions(
      this.filters.businessId,
      this.filters.search,
      this.filters.startDate ? new Date(this.filters.startDate) : null,
      this.filters.finishDate ? new Date(this.filters.finishDate) : null,
      this.filters.limit,
      this.filters.skip
    ).then((response: any) => this.processData(response))
  }

  public onBusinessSelected(result: { businessId: string }): void {
    this.filters.businessId = result.businessId
  }

  processMetaData(metadata: any): void {
    this.rowsTotalCount = metadata.count
  }

  processData(response: any): any {
    this.processMetaData(response.metadata)
    this.currentBusinessBalance = Cost.build(response.currentBalance)

    this.rows = response.records.map((row: any) => {
      const date = (row.createdAt) ? this.$filter('date')(new Date(row.createdAt), envConfig.angularDefaultDateFormat) : ''
      const businessBranchName = get(row, 'branchName', '')

      const posLink = row.posReference && row.orderId
        ? `<a href="/#!/storefront/orders/order/${row.orderId}?businessId=${this.filters.businessId}">${row.posReference}</a>`
        : null
      let net = get(row, 'netPrice') || ''
      if (net) {
        net = Cost.build(net).getFormattedCost()
      }

      let amount = get(row, 'totalPrice') || ''
      if (amount) {
        amount = Cost.build(amount).getFormattedCost()
      }

      let fee = get(row, 'feePrice') || ''
      if (fee) {
        fee = Cost.build(fee).getFormattedCost()
      }

      return [
        date,
        businessBranchName,
        posLink,
        net,
        amount,
        fee,
        this.$filter('translate')(`COMPONENTS.STOREFRONT_TRANSACTIONS_REPORT_PAGE.TRANSACTION_TYPES.${get(row, 'type')}`)
      ]
    })
  }
}

export default {
  bindings: {
    triggerDataReload: '<?'
  },
  templateUrl: require('./storefront-transactions-report-page.pug'),
  controller: StorefrontTransactionsReportPage
}
