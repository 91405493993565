
import template from './tariff-periods-dashboard.pug'
import tariffPeriodsCollection from 'data/collections/tariffPeriodsCollection'
import BaseController from 'presentation/common/BaseController'

class TariffPeriodsDashboard extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.sync()
  }

  onSync () {
    return tariffPeriodsCollection.getAll()
      .then(tariffPeriods => {
        this.tariffPeriods = tariffPeriods
      })
      .catch(err => this.logger.error('Cannot get tariff periods', err))
  }

  deleteTariffPeriod (params) {
    return tariffPeriodsCollection.delete(params.id)
      .then(() => this.onSync())
      .catch(err => this.logger.error('Cannot delete tariff period', err))
  }
}

export default {
  templateUrl: template,
  controller: TariffPeriodsDashboard
}
