/* globals google:false*/
import templateUrl from './location-map.pug'
import Logger from 'common/Logger'
import ParseUtils from 'common/utils/parseUtils'

const logger = new Logger('Location Map Input')

const DEFAULT_ZOOM_LEVEL = 15

function locationMapController ($scope, $element) {
  function init () {
    $scope.map = null
    $scope.mapInitAttempted = false
    $scope.locationMarker = null
    $scope.windowResizedListener = null
    $scope.locationDragListener = null
    $scope.notifyMapError = $scope.onMapError || function () {}

    $scope.$watch(() => ($scope.latitude && $scope.longitude && !$scope.mapInitAttempted), conditionallyInitMap)
    $scope.$watch('latitude', conditionallySyncMapLocation)
    $scope.$watch('longitude', conditionallySyncMapLocation)
    $scope.$watch('isEditing', conditionallySyncEditability)
  }

  function conditionallyInitMap (hasRequiredDependencies) {
    if (hasRequiredDependencies) {
      logger.info('Map has required data for init, initing')
      $scope.mapInitAttempted = true
      try {
        initMap()
      } catch (error) {
        logger.error('Could not initialise map:', error)
        $scope.notifyMapError(error)
        removeEventListeners($scope)
      }
    }
  }

  function initMap () {
    const mapElement = $element[0].querySelector('.js_map_container')

    const locationLatLong = getGoogleLocationForLatLongStrings($scope.latitude, $scope.longitude)

    const mapOptions = getGoogleMapOptions(locationLatLong, $scope.isEditing)
    $scope.map = new google.maps.Map(mapElement, mapOptions)
    $scope.windowResizedListener = google.maps.event.addDomListener(window, 'resize', conditionallySyncMapLocation)

    const markerOptions = getMarkerOptions(locationLatLong, $scope.map, $scope.isEditing)
    $scope.locationMarker = new google.maps.Marker(markerOptions)
    $scope.locationDragListener = google.maps.event.addListener($scope.locationMarker, 'dragend', handleMarkerDragged)

    forceResizeOnLoaded()
  }

  function forceResizeOnLoaded () {
    // DP-478: Fix a bug in Google Maps v3. See https://stackoverflow.com/a/7262773.
    // When map first shows, it will often show as a blank grey half-loaded map until receiving a resize event.
    // To solve this, we hook into an event that occurs when the map has loaded, and trigger a resize then.
    // There is no 'map loaded' event, but the map should emit 'idle' after it's successfully loaded.
    google.maps.event.addListenerOnce($scope.map, 'idle', function () {
      google.maps.event.trigger($scope.map, 'resize')
      conditionallySyncMapLocation() // Needs to be re-synced to the centre
    })
  }

  function conditionallySyncMapLocation () {
    if ($scope.map && $scope.locationMarker) {
      const locationLatLong = getGoogleLocationForLatLongStrings($scope.latitude, $scope.longitude)
      $scope.map.setCenter(locationLatLong)
      $scope.locationMarker.setPosition(locationLatLong)
    }
  }

  function handleMarkerDragged (event) {
    const newLatitude = event.latLng.lat()
    const newLongitude = event.latLng.lng()
    $scope.onLocationEdited({newLocation: {latitude: newLatitude, longitude: newLongitude}})
  }

  function conditionallySyncEditability () {
    if ($scope.map && $scope.locationMarker) {
      const locationLatLong = getGoogleLocationForLatLongStrings($scope.latitude, $scope.longitude)
      const mapOptions = getGoogleMapOptions(locationLatLong, $scope.isEditing)
      $scope.map.setOptions(mapOptions)
      const markerOptions = getMarkerOptions(locationLatLong, $scope.map, $scope.isEditing)
      $scope.locationMarker.setOptions(markerOptions)
    }
  }

  function getGoogleMapOptions (centreLocation, isEditing) {
    return {
      center: centreLocation,
      zoom: DEFAULT_ZOOM_LEVEL,
      mapTypeId: google.maps.MapTypeId.ROADMAP, // Always reset back to road map when resetting the options
      gestureHandling: 'cooperative',
      draggable: isEditing,
      disableDefaultUI: !isEditing,
      disableDoubleClickZoom: !isEditing,
      scrollwheel: false,
      streetViewControl: false,
      clickableIcons: false,
      fullscreenControl: false
    }
  }

  function getMarkerOptions (centreLocation, map, isEditing) {
    return {
      position: centreLocation,
      map: map,
      draggable: isEditing,
      cursor: isEditing ? 'pointer' : 'default'
    }
  }

  function getGoogleLocationForLatLongStrings (latitude, longitude) {
    return {
      lat: ParseUtils.safeParseNumber(latitude, 0),
      lng: ParseUtils.safeParseNumber(longitude, 0)
    }
  }

  init()
}

function linkLocationMap ($scope, $element) {
  $element.on('$destroy', function () {
    logger.info('Destroying Map Directive')
    removeEventListeners($scope)
  })
}

function removeEventListeners ($scope) {
  logger.info('Removing location map event listeners')
  // RemoveListener handles being passed null listeners so no worries
  google.maps.event.removeListener($scope.locationDragListener)
  google.maps.event.removeListener($scope.windowResizedListener)
}

export default function locationMap () {
  return {
    restrict: 'E',
    templateUrl,
    controller: locationMapController,
    link: linkLocationMap,
    replace: true,
    scope: {
      latitude: '@',
      longitude: '@',
      onLocationEdited: '&',
      onMapError: '=',
      isEditing: '='
    }
  }
}
