import DBMappedNamedEntity from './DBMappedNamedEntity'
import AreaDeliveryTimeSettings from './AreaDeliveryTimeSettings'

export default class BranchDeliveryTimeSettings {
  constructor (
    public branch: DBMappedNamedEntity,
    public values: AreaDeliveryTimeSettings[]
  ) {}

  static build(dto: any): BranchDeliveryTimeSettings {
    return new BranchDeliveryTimeSettings(
      DBMappedNamedEntity.build(dto.branch || {}),
      (dto.values || []).map(AreaDeliveryTimeSettings.build)
    )
  }
}
