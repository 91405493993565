import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import scheduledDashboard from './dashboard/scheduledDashboard'
import scheduledDelivery from './scheduled-delivery/scheduledDelivery'

export default declareAppModule('deliveries.scheduled-dashboard', (module: ng.IModule) => module
  .component('scheduledDashboard', scheduledDashboard)
  .component('scheduledDelivery', scheduledDelivery)
  .config(($stateProvider: StateProvider) => {
    declareState($stateProvider, {
      parent: 'main',
      stateName: 'scheduled-dashboard',
      componentName: 'scheduledDashboard',
      stateKey: 'SCHEDULED_DASHBOARD'
    })
  })
)
