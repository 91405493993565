import imagesCollection from 'data/collections/imagesCollection'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'

const ZOOM_STEP = .1
const WIDTH_LIMIT = 560
const EXPORT_SIZE_MULTIPLIER = 4
const REQUEST_IS_TOO_LARGE_HTTP_STATUS_CODE = 413
const JPEG_MIME_TYPE = 'image/jpeg'

interface IImageUploadedResponse {
  id: string,
  url: string
}

class EditableListImageController {
  private cropper: Cropper = null
  private imageInput: ng.IAugmentedJQuery
  private image: ng.IAugmentedJQuery

  public imageUrl: string = null
  public aspectRatio: number
  public file: File = null
  public isEditing: boolean
  public isDragover: boolean = false
  public showResetButton: boolean = false
  public isImageEdited: boolean = false
  public isImageChanged: boolean = false
  public showUploadError: boolean = false
  public showImageIsTooLargeError: boolean = false
  public onChange: (data: {imageUrl: string}) => void

  constructor (
    private $element: ng.IAugmentedJQuery,
    private $scope: ng.IScope
  ) {}

  public $onChanges (changesDescriptor: any): void {
    const imageUrl = getValueFromChangeDescriptors(changesDescriptor, 'imageUrl', null)
    const isEditing = getValueFromChangeDescriptors(changesDescriptor, 'isEditing', false)

    if (isEditing && !this.cropper) {
      this.initEditor()
    }

    if (imageUrl && this.isEditing && this.cropper) {
      this.cropper.replace(this.imageUrl)
    }
  }

  public openFileUploadDialog(): void {
    if (this.imageInput && this.imageInput[0]) {
      this.imageInput[0].click()
    }
  }

  public clearImage(): void {
    this.setImage('')
  }

  private setDragEventsHandling(): void {
    // This hack with timeout is a simple debouncer since there's a lot
    // "dragleave" events firing by child elements while you still dragging file over component

    let timeoutId = null

    this.$element.on('dragover', (e: Event): void => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }

      if (!this.isDragover) {
        this.isDragover = true
        this.$scope.$digest()
      }
      e.stopPropagation()
    })

    this.$element.on('dragleave drop', (e: Event): void => {
      timeoutId = setTimeout(() => {
        if (this.isDragover) {
          this.isDragover = false
          this.$scope.$digest()
        }
      }, 50)
      e.stopPropagation()
    })
  }

  private handleFilePicked (): void {
    if (this.file && FileReader) {
      const fileReader = new FileReader()

      fileReader.onload = (): void => {
        this.imageUrl = fileReader.result as string
        this.cropper.replace(this.imageUrl)
        this.dropVisibilityState()
        this.isImageChanged = true
        this.$scope.$digest()
      }

      fileReader.readAsDataURL(this.file)
    }
  }

  public reset(): void {
    this.cropper.reset()
    this.showResetButton = false
    this.isImageEdited = false
  }

  public rotateLeft(): void {
    this.cropper.rotate(-90)
    this.markImageEdited()
  }

  public rotateRight(): void {
    this.cropper.rotate(90)
    this.markImageEdited()
  }

  public zoomIn(): void {
    this.cropper.zoom(ZOOM_STEP)
  }

  public zoomOut(): void {
    this.cropper.zoom(-ZOOM_STEP)
  }

  public uploadImage(): void {
    // Here's a tricky part to adopt canvas-generated image to look like picked from file-input
    // to make the existing API and imagesCollection work
    this.cropper.getCroppedCanvas({
      width: WIDTH_LIMIT * EXPORT_SIZE_MULTIPLIER,
      maxWidth: WIDTH_LIMIT * EXPORT_SIZE_MULTIPLIER,
      fillColor: '#000',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high' as any,
    }).toBlob((blob: Blob) => {
      const PNGFileName = (this.file && this.file.name) ?
        `${this.file.name.split('.').slice(0, -1).join('.')}.jpg` :
        'pic.jpg'

      const file = new File([blob], PNGFileName, {
        type: JPEG_MIME_TYPE
      })

      imagesCollection.upload(file)
        .then((response: IImageUploadedResponse): void => this.handleSuccessImageUpload(response))
        .catch((error: any): void => this.handleImageUploadError(error))
        .then((): void => this.$scope.$digest())
    }, JPEG_MIME_TYPE)
  }

  private initEditor (): void {
    setTimeout((): void => {
      this.initCropper()
      if (this.imageUrl && this.cropper) {
        this.cropper.replace(this.imageUrl)
      }
      this.imageInput = this.$element.find('input')
      this.setDragEventsHandling()
    }, 0)

    this.$scope.$watch('$ctrl.file', () => this.handleFilePicked())
  }

  private initCropper (): void {

    const CropperPromise = import(/* webpackChunkName: "cropperjs" */ 'cropperjs').then((CropperModule: any): void => CropperModule.default )
    CropperPromise.then((Cropper: any): void | PromiseLike<void> => {

      this.image = this.$element.find('img')
      this.cropper = new Cropper(this.image[0] as HTMLImageElement, {
        // config...
        viewMode: 2,
        dragMode: 'move' as any,
        autoCropArea: 1,
        aspectRatio: this.aspectRatio || 0,
        restore: false,
        modal: false,
        guides: true,
        highlight: true,
        responsive: true,
        cropBoxMovable: true,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        minCanvasWidth: WIDTH_LIMIT,
        minCropBoxWidth: WIDTH_LIMIT
      })

      this.image.on('cropmove zoom', (): void => {
        this.markImageEdited()

        // This one is to avoid  "$digest()/$apply is already running" error.
        // Probably bad solution =(
        setTimeout(() => this.$scope.$digest(), 0)
      })
    })
  }

  private markImageEdited(): void {
    this.isImageEdited = true
    this.showResetButton = true
  }

  private dropVisibilityState(): void {
    this.isImageEdited = false
    this.isImageChanged = false
    this.showUploadError = false
    this.showResetButton = false
    this.showImageIsTooLargeError = false
  }

  private handleSuccessImageUpload(response: IImageUploadedResponse): void {
    this.setImage(response.url)
  }

  private handleImageUploadError(error: any): void {
    this.dropVisibilityState()

    if (error.status === REQUEST_IS_TOO_LARGE_HTTP_STATUS_CODE) {
      this.showImageIsTooLargeError = true
    } else {
      this.showUploadError = true
    }
  }

  private setImage(url: string): void {
    this.imageUrl = url || ''
    this.cropper.replace(this.imageUrl)
    this.dropVisibilityState()
    this.onChange({
      imageUrl: this.imageUrl
    })
  }
}

export default {
  templateUrl: require('./editable-list-image.pug'),
  controller: EditableListImageController,
  bindings: {
    backendValidationModel: '=',
    form: '=',
    name: '@',
    imageUrl: '<',
    title: '@',
    onChange: '&',
    isEditing: '<',
    isRequired: '<',
    placeholder: '@',
    valueMessage: '@',
    aspectRatio: '<',
    hostClass: '@?'
  }
}
