import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
const useCacheParams = {useCache: true}
const currenciesService = new CrudService(endpoints.CURRENCIES)

export function currenciesCollectionFactory (currenciesService) {
  return {
    getCurrencyCodes: () => currenciesService.get([], useCacheParams)
      .then(currencies => currencies.map(currency => currency.code))
  }
}

export default currenciesCollectionFactory(currenciesService)
