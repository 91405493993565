import templateUrl from './qr-code.pug'
import QRCode from 'qrcode'
import BaseController from 'presentation/common/BaseController'

class QrCodeController extends BaseController {
  constructor () {
    super()
    this.src = ''
    this.defaultSize = 116
    this.defaultMargin = 4
  }

  $onChanges () {
    if (this.data) {
      this.getDataUrl()
    }
  }

  getDataUrl () {
    QRCode.toDataURL(
      this.data,
      {
        margin: typeof this.margin !== 'number' ? this.defaultMargin : this.margin,
        width: typeof this.size !== 'number' ? this.defaultSize : this.size
      },
      (error, url) => {
        if (error) {
          this.logger.error('Cannot generate a QR Code with data: ', this.data, error)
          this.src = ''
        } else {
          this.src = url
        }
      }
    )
  }
}

export default {
  templateUrl,
  controller: QrCodeController,
  bindings: {
    data: '<',
    hostClass: '@?',
    size: '<',
    margin: '<'
  }
}
