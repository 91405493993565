import cloneDeep from 'lodash-es/cloneDeep'
import BaseController from 'presentation/common/BaseController'
import Address from 'data/domain-objects/Address'
import addressCollection from 'data/collections/addressCollection'
import Consumer from 'data/domain-objects/Consumer'
import Setting from 'data/domain-objects/Setting'
import * as AreasSettingsConstants from 'common/constants/AreasSettings'
import { StateService } from '@uirouter/core'

class AddAddressToConsumerController extends BaseController {
  // bindings
  public consumer: Consumer
  public handleCancel: () => void
  public onNewAddressAdded: (consumer: Consumer) => void
  public showAddressLocationMap: boolean
  public expandAddressLocationMap: boolean
  public availableAreas: string[]
  public settings: Setting[]
  public consumerFirstName: string
  public consumerLastName: string

  // properties
  public firstName: string = ''
  public lastName: string = ''
  public isSubmitting: boolean = false
  public consumerAddress: Address =  Address.build({})
  public errors: {[errorType: string]: boolean} = {}
  public entity: Consumer

  // setting values
  public showAllAreas: string = AreasSettingsConstants.SHOW_AREA_SETTINGS_SHOW_ALL
  public showSelectedOnlyAreas: string = AreasSettingsConstants.SHOW_AREA_SETTINGS_SHOW_SELECTED

  constructor (
    $scope: ng.IScope,
    $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.entity = cloneDeep(this.consumer)
    this.firstName = this.consumerFirstName || this.consumer.firstName || null
    this.lastName = this.consumerLastName || this.consumer.lastName || null
    this.consumerAddress.consumerFirstName = this.firstName
    this.consumerAddress.consumerLastName = this.lastName
  }

  submit (): Promise<void> {
    this.errors = {}
    this.isSubmitting = true
    return this.submitAddress()
      .then((address: Address) => this.saveNewAddress(address))
      .then((consumerEntity: Consumer) => this.consumerAddressAdded(consumerEntity))
      .catch((err: Error) => {
        this.logger.error('Unable to create address:', err)
        this.errors['genericError'] = true
      })
      .then(() => {
        this.isSubmitting = false
        this.$scope.$digest()
      })
  }

  saveNewAddress (address: Address): Consumer {
    this.entity.addresses.push(Address.build(address))
    return this.entity
  }

  submitAddress (): Promise<Address> {
    this.consumerAddress.consumerFirstName = this.firstName || this.consumer.firstName
    this.consumerAddress.consumerLastName = this.lastName || this.consumer.lastName

    return addressCollection.createConsumerAddress(this.consumer.id, this.consumerAddress)
      .then((address: Address) => {
        const createdAddress = Address.build(address)
        createdAddress.type = this.consumerAddress.type
        createdAddress.consumerFirstName = createdAddress.consumerFirstName || this.consumer.firstName
        createdAddress.consumerLastName = createdAddress.consumerLastName || this.consumer.lastName
        return createdAddress
      })
  }

  consumerAddressAdded (consumer: Consumer): void {
    this.onNewAddressAdded(consumer)
  }

  handleCancelButtonClick (): void {
    this.handleCancel()
  }
}

export default {
  templateUrl: require('./add-address-to-consumer-form.pug'),
  controller: AddAddressToConsumerController,
  bindings: {
    consumer: '<',
    handleCancel: '<',
    onNewAddressAdded: '<',
    showAddressLocationMap: '<',
    expandAddressLocationMap: '<',
    availableAreas: '<',
    settings: '<?',
    consumerFirstName: '<?',
    consumerLastName: '<?'
  }
}
