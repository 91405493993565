import differenceWith from 'lodash-es/differenceWith'
import {ProductProperty, ProductPropertyValue} from 'data/domain-objects/Product'
import * as PRODUCT_EVENTS from 'common/constants/ProductEvents'
import BasePopupController from 'presentation/common/BasePopupController'

export const PRODUCT_PROPERTY_DIALOG_ID = 'product-property-dialog'

const MIN_AMOUNT_CONTROL_NAME = 'product-property-min-amount'
const MAX_AMOUNT_CONTROL_NAME = 'product-property-max-amount'

interface IRelation {
  id: string,
  name: string,
  propertyId: string,
  valueId: string
}

class ProductPropertyDialogController extends BasePopupController {
  public productProperty: ProductProperty
  public availableProperties: ProductProperty[] = []
  public allRelations: IRelation[] = []
  public selectedRelations: IRelation[] = []
  public availableRelations: IRelation[] = []
  public onSubmit: (payload: {property: ProductProperty}) => void

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IScope
  ) {
    super($scope, $rootScope, PRODUCT_EVENTS.ADD_PRODUCT_PROPERTY_EVENT, PRODUCT_PROPERTY_DIALOG_ID)

    this.setupPopupOpenEventListener((payload: any): void => {
      this.resetModel()

      if (payload) {
        if (payload.model) {
          this.productProperty = ProductProperty.build(payload.model)
        }

        if (payload.properties) {
          this.availableProperties = payload.properties.filter((property: ProductProperty): boolean => property.id !== this.productProperty.id)
          this.updateAllRelations()
        }
      }
    })
  }

  private updateAllRelations (): void {
    this.allRelations = this.availableProperties
      .reduce((accum: IRelation[], property: ProductProperty): IRelation[] => {
        const relationsFromCurrentProperty = property.values.map((value: ProductPropertyValue): IRelation => {
          return {
            id: `${property.id}:${value.id}`,
            name: `${property.name.getLocalized()} - ${value.name.getLocalized()}`,
            propertyId: property.id,
            valueId: value.id
          }
        })
        return accum.concat(relationsFromCurrentProperty)
      }, [])

    this.selectedRelations = this.allRelations.filter((relation: IRelation): boolean => {
      return !!this.productProperty.parentProperties.find((parentProperty: IParentProductProperty): boolean => {
        return relation.propertyId === parentProperty.propertyId && relation.valueId === parentProperty.valueId
      })
    })

    this.updateAvailableRelations()
  }

  private updateAvailableRelations(): void {
    this.availableRelations = differenceWith(this.allRelations, this.selectedRelations, (relation: IRelation, selectedRelation: IRelation) => {
      return relation.id === selectedRelation.id
    })
  }

  private updateParentProperties(): void {
    this.productProperty.parentProperties = this.selectedRelations.map((relation: IRelation): IParentProductProperty => {
      return {
        propertyId: relation.propertyId,
        valueId: relation.valueId
      }
    })
  }

  public onRelationPicked (relation: IRelation, form: ng.IFormController): void {
    this.selectedRelations.push(relation)
    this.updateAvailableRelations()
    this.updateParentProperties()
    form.$setDirty()
  }

  public removeRelation(relation: IRelation, form: ng.IFormController): void {
    this.selectedRelations = this.selectedRelations.filter((item: IRelation): boolean => item.id !== relation.id)
    this.updateAvailableRelations()
    this.updateParentProperties()
    form.$setDirty()
  }

  private resetModel (): void {
    this.productProperty = ProductProperty.build({})
  }

  public onAmountLimitsChanged (form: ng.IFormController): void {
    const isMinAmountValid = this.productProperty.minAmount >= 0
    const isMaxAmountValid = !this.productProperty.maxAmount ||
      (this.productProperty.maxAmount >= 0 && (!this.productProperty.minAmount || this.productProperty.maxAmount >= this.productProperty.minAmount))

    form.$setValidity('minAmount', isMinAmountValid, form[MIN_AMOUNT_CONTROL_NAME])
    form.$setValidity('maxAmount', isMaxAmountValid, form[MAX_AMOUNT_CONTROL_NAME])
  }

  public submit (): void {
    this.onSubmit({property: this.productProperty})
    this.close()
  }

  public cancel (): void {
    this.close()
  }
}

export default {
  templateUrl: require('./product-property-dialog.pug'),
  controller: ProductPropertyDialogController,
  bindings: {
    onClose: '&?',
    onSubmit: '&'
  }
}
