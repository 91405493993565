import templateUrl from './dropdown.pug'

class DropdownController {
  constructor ($scope, $document, $element) {
    this.$scope = $scope
    this.$document = $document
    this.$element = $element
    this.isOpened = false
    this.values = []
  }

  toggleDropdown () {
    this.isOpened = !this.isOpened

    if (this.isOpened) {
      this.$document.on('click', e => { // Close the dropdown on click outside of it
        this.isOpened = this.$element[0].contains(e.target) ? this.isOpened : false
        this.$scope.$apply()
      })
    } else {
      this.$document.off('click')
    }
  }

  selectItem (value) {
    this.model = value
    this.isOpened = !this.isOpened
    this.onChange({value})
    this.form.$setDirty()
  }
}

export default {
  templateUrl,
  controller: DropdownController,
  bindings: {
    form: '=',
    model: '=',
    values: '<',
    isDisabled: '<',
    onChange: '&?',
    baseTranslationKey: '@'
  }
}
