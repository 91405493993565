import { StateService } from '@uirouter/core'
import envConfig from 'common/envConfig'
import consumerCollection from 'data/collections/consumerCollection'
import Cost from 'data/domain-objects/Cost'
import Consumer from 'data/domain-objects/Consumer'
import Logger from 'common/Logger'

import {
  ConsumerHistoryBalance,
  ConsumerHistoryBalanceItem,
  ConsumerHistoryBalanceSubline,
  ConsumerHistoryBalanceSublineMethods
} from 'data/domain-objects/ConsumerBalanceHistory'

class ConsumerHistory {
  public columns: string[] = [ 'DATE', 'DRAEWIL_ID', 'OPERATION', 'METHOD', 'AMOUNT', 'BALANCE']
  public consumerId: string
  public consumerData: Consumer
  public historyData: ConsumerHistoryBalanceItem[]
  public isLoading: boolean = true
  public isHistoryLoading: boolean = true
  public currentBalance: Cost
  public angularDefaultDateFormat: string = envConfig.angularDefaultDateFormat
  private logger: any = new Logger(this.constructor.name)

  public pagination: {
    count: number,
    page: number,
    limit: number,
    skip: number
  } = {
    count: 0,
    page: 0,
    limit: envConfig.pagination.defaultLimit,
    skip: 0
  }

  constructor (
    public $state: StateService,
    public $scope: ng.IScope,
    public $filter: ng.IFilterService
  ) {}

  $onInit(): void {

    this.consumerId = this.$state.params.id

    consumerCollection.get(this.consumerId)
      .then((consumerData: Consumer) => {
        this.consumerData = consumerData
        this.isLoading = false
        this.$scope.$digest()
      })

    this.getBalanceHistory()
  }

  onOffsetChange(skip: number, limit: number): void {
    this.pagination.skip = skip
    this.pagination.limit = limit
    this.getBalanceHistory()
  }

  getMethodI18n(row: ConsumerHistoryBalanceItem, line: ConsumerHistoryBalanceSubline, $index: number): string {
    if ($index === 0) {
      let translated
      if (row.deliveryPaymentMethods) {
        translated = row.deliveryPaymentMethods.map((paymentMethod: string) => {
          return this.$filter<ITranslateFilter>('translate')(`PAGES.CUSTOMER_BALANCE_HISTORY.METHODS.${paymentMethod}`)
        }).join(', ')
      } else {
        translated = this.$filter<ITranslateFilter>('translate')(`PAGES.CUSTOMER_BALANCE_HISTORY.METHODS.${row.sublines[0].method}`)
      }
      return translated
    } else {
      let translated = this.$filter<ITranslateFilter>('translate')(`PAGES.CUSTOMER_BALANCE_HISTORY.METHODS.${line.method}`)
      if (line.method === ConsumerHistoryBalanceSublineMethods.card) {
        const cardInfo = line.card ? `${line.card.brand} ${line.card.last4}` : ''
        translated = `
          ${line.success ? '' : this.$filter<ITranslateFilter>('translate')('PAGES.CUSTOMER_BALANCE_HISTORY.FAILED')}
          ${translated}
          ${cardInfo}
        `
      }
      return translated
    }
  }

  getOperationI18n(operation: string): string {
    return operation && this.$filter<ITranslateFilter>('translate')(`PAGES.CUSTOMER_BALANCE_HISTORY.OPERATIONS.${operation}`) || ''
  }

  getBalanceHistory(): Promise<void> {
    this.isHistoryLoading = true
    return consumerCollection.getBalanceHistory(this.consumerId, this.pagination)
      .then((historyBalance: ConsumerHistoryBalance) => {
        this.pagination.count = historyBalance.metadata.count
        this.pagination.limit = historyBalance.metadata.limit
        this.pagination.skip = historyBalance.metadata.skip
        this.historyData = historyBalance.records
        this.currentBalance = historyBalance.currentBalance
      })
      .catch((err: Error) => {
        this.logger.error(err)
        this.historyData = []
        this.currentBalance = null
      })
      .then(() => {
        this.isHistoryLoading = false
        this.$scope.$digest()
      })
  }
}

export default {
  templateUrl: require('./consumer-history.pug'),
  controller: ConsumerHistory
}
