import BaseController from 'presentation/common/BaseController'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import packageTypesCollection from 'data/collections/packageTypesCollection'
import collectEntityFieldByIds from 'data/common/collectByIds'
import templateUrl from './print-delivery-receipt.pug'

class printDeliveryReceiptController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.packageNamesById = null
  }

  $onInit () {
    this.sync()
  }

  onSync () {
    return packageTypesCollection.getAll()
      .then(collectEntityFieldByIds)
      .then(packageNamesById => {
        this.packageNamesById = packageNamesById
      })
      .catch(err => this.logger.error('Cannot get package types', err))
  }

  getReceiptTemplate () {
    deliveriesCollection.getDeliveryReceiptTemplate(this.delivery.id)
      .then(receiptTemplate => {
        if (receiptTemplate && receiptTemplate.body) {
          this.deliveryReceiptTemplate = receiptTemplate.body
          this.$scope.$digest()
          if (this.instantPrint) {
            setTimeout(() => window.print())
          }
        }
      })
      .catch(err => this.logger.error('Cannot get delivery receipt template for specified delivery', err))
  }

  $onChanges () {
    if (this.delivery) {
      this.getReceiptTemplate()
    }
  }
}

export default {
  templateUrl: templateUrl,
  controller: printDeliveryReceiptController,
  bindings: {
    printText: '@',
    delivery: '<',
    instantPrint: '<',
    optionalButtonClass: '@'
  }
}
