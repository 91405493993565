import envConfig from 'common/envConfig'
import templateUrl from './delivery-batching-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SETTINGS_KEYS = [
  'showBatching',
  'batching'
]

class batchingSettings extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
    this.batchingValues = envConfig.businessAndBranchSettings.batchingTypes
  }
}

export default {
  templateUrl,
  controller: batchingSettings,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
