export default function backendValidation () {
  return {
    restrict: 'A',
    require: 'ngModel', // Requiring ngModel passes the ngModelController for this element to the link function
    scope: {
      backendValidation: '='
    },
    link: function ($scope, element, $attrs, ngModelController) {
      const VALIDATION_KEYS_BY_BACKEND_ERROR_CODE = {
        INVALID: 'backendInvalid', // Must use different validation keys to angular or we'll clash with it
        BAD_FORMAT: 'backendBadFormat',
        REQUIRED: 'backendRequired',
        MINLENGTH_EXPECTED: 'backendMinlength'
      }

      // Validate our field each time our backend validation is changed
      $scope.$watch('backendValidation', () => {
        ngModelController.$validate()
      })

      ngModelController.$validators.backendValidator = function () {
        let isBackendValid = true
        const backendErrorCodes = Object.keys(VALIDATION_KEYS_BY_BACKEND_ERROR_CODE)
        backendErrorCodes.forEach(backendErrorCode => {
          const validationKey = VALIDATION_KEYS_BY_BACKEND_ERROR_CODE[backendErrorCode]
          const isBackendValidForThisErrorType = backendErrorCode !== $scope.backendValidation
          ngModelController.$setValidity(validationKey, isBackendValidForThisErrorType)
          isBackendValid = isBackendValidForThisErrorType && isBackendValid // Keep false if invalid for any error type
        })
        return isBackendValid
      }
    }
  }
}
