import liveOrdersCollection from 'data/collections/reportLiveOrdersCollection'
import templateUrl from './live-orders-report.pug'
import moment from 'moment'
import envConfig from 'common/envConfig'
import BaseReport from 'presentation/reports/common/baseReport'
import {getItemFromLocalStorage} from 'common/utils/storageUtils'
import {AUTH_STORAGE_KEY, AUTH_HEADER} from 'common/agent'

const COLUMNS_CONFIG = [{
  title: 'date',
  name: 'timePlaced',
  type: 'datetime'
}, {
  title: 'order_number',
  name: 'orderNumber',
  type: 'string'
}, {
  title: 'pos',
  name: 'pos',
  type: 'string'
}, {
  title: 'payment_type',
  name: 'paymentType',
  type: 'paymentType'
}, {
  title: 'sender',
  name: 'sender',
  type: 'string'
}, {
  title: 'charged_from',
  name: 'chargedFrom',
  type: 'string'
}, {
  title: 'customer',
  name: 'customer',
  type: 'string'
}, {
  title: 'last_status',
  name: 'lastStatus',
  type: 'string'
}, {
  title: 'driver',
  name: 'driver',
  type: 'string'
}, {
  title: 'fleet_allocated',
  name: 'fleetAllocated',
  type: 'string'
}, {
  title: 'manually_batched',
  name: 'manuallyBatched',
  type: 'boolean'
}, {
  title: 'batch_size',
  name: 'batchSize',
  type: 'number'
}, {
  title: 'consumer',
  name: 'consumer',
  type: 'boolean'
}, {
  title: 'lead_time',
  name: 'leadTime',
  type: 'duration'
}, {
  title: 'time_allocated',
  name: 'timeAllocated',
  type: 'timestamp'
}, {
  title: 'time_pickup_geo',
  name: 'timeAtPickupGeofence',
  type: 'timestamp'
}, {
  title: 'time_pickup_arrival',
  name: 'arrivalAtPickup',
  type: 'timestamp'
}, {
  title: 'time_delivery_started',
  name: 'timeDeliveryStarted',
  type: 'timestamp'
}, {
  title: 'time_delivery_paid',
  name: 'timeDeliveryPaid',
  type: 'timestamp'
}, {
  title: 'time_dropoff_geo',
  name: 'timeAtDropOffGeofence',
  type: 'timestamp'
}, {
  title: 'time_dropoff',
  name: 'timeAtDropOff',
  type: 'timestamp'
}, {
  title: 'time_dropoff_complete',
  name: 'dropoffCompleted',
  type: 'timestamp'
}, {
  title: 'time_cash',
  name: 'timeCashCollected',
  type: 'timestamp'
}, {
  title: 'time_total',
  name: 'totalTime',
  type: 'duration'
}, {
  title: 'time_processing',
  name: 'processingComplete',
  type: 'timestamp'
}, {
  title: 'delivery_time',
  name: 'deliveryTime',
  type: 'duration'
}, {
  title: 'cancellation',
  name: 'cancellation',
  type: 'string'
}]

class LiveOrdersReport extends BaseReport {
  constructor ($scope, $interval, $filter, $document) {
    super($scope, $interval, $filter, $document)

    this.dateFormat = envConfig.defaultDateFormat

    this.updateIntervalConfig = envConfig.reports.liveOrders.updateIntervalInMs

    this.liveMode = true
    this.columnsConfig = COLUMNS_CONFIG
    this.isBackgroundUpdate = false

    // last N days
    this.defaultStartDate = moment(Date.now()).subtract(envConfig.reports.liveOrders.defaultNumberOfDaysAgo, 'days')

    this.orderBy = 'timePlaced'
    this.orderDirection = 'DESC'
    this.filters = {
      limit: 20,
      skip: 0,
      start: this.defaultStartDate,
      orderBy: this.orderBy,
      orderDirection: -1
    }

    this.dates = {
      startDate: null,
      endDate: null
    }

    this.rawData = []

    this.$scope.$watch('$ctrl.liveMode', () => {
      if (this.liveMode) {
        this.resetFilters()
        this.updateData()
      } else {
        this.cancelUpdate()
      }
    })

    this.updateDownloadLink()
  }

  linkReport (event) {
    event.preventDefault()
    const authToken = getItemFromLocalStorage(AUTH_STORAGE_KEY)
    document.cookie = `${AUTH_HEADER}=${authToken}`
    window.open(this.downloadRawCSVUrl, '_blank')
  }

  changeDates (payload) {
    this.dates.startDate = payload && payload.dateFrom ? payload.dateFrom : null
    this.dates.endDate = payload && payload.dateTo ? payload.dateTo : null

    // clear dates
    if (this.dates.startDate === null && this.dates.endDate === null) {
      this.resetFilters()
      this.updateData()
    }

    // new dates range
    if (this.dates.startDate && this.dates.endDate) {
      this.filters.start = moment(this.dates.startDate).set({second: 0, millisecond: 0}).valueOf()
      this.filters.finish = moment(this.dates.endDate).set({second: 59, millisecond: 0}).valueOf()
      this.updateData()
    }

    this.updateDownloadLink()
  }

  updateDownloadLink () {
    const startDate = moment(this.filters.start || new Date()).toISOString()
    const finishDate = moment(this.filters.finish || new Date()).toISOString()

    this.downloadRawCSVUrl = liveOrdersCollection.getRawCSVUrl(startDate, finishDate)
  }

  resetFilters () {
    this.filters.start = this.defaultStartDate
    delete this.filters.finish
  }

  onOffsetChange (offset, limit) {
    this.filters.skip = offset
    this.filters.limit = limit
    this.updateData()
  }

  scheduleUpdate () {
    this.$interval.cancel(this.updateInterval)
    this.updateInterval = this.$interval(() => {
      this.isBackgroundUpdate = true
      return this.updateData()
    }, envConfig.reports.liveOrders.updateIntervalInMs)
  }

  exportToCSV () {
    this.exportData('live-orders', this.rawData, this.columnsConfig, 'PAGES.REPORTS.LIVE_ORDERS.HEADERS.')
  }

  handleResponse (liveOrdersDTOResponse) {
    this.rowsTotalCount = liveOrdersDTOResponse.metadata.count
    this.rawData = Object.values(liveOrdersDTOResponse.records)
    this.formatData()

    // reschedule data update
    if (this.liveMode) {
      this.scheduleUpdate()
    }
    this.$scope.$digest()
  }

  onBeforeUpdateData () {
    if (!this.isBackgroundUpdate) {
      this.isLoading = true
    }
    return Promise.resolve()
  }

  onAfterUpdateData () {
    this.isLoading = false
    this.isBackgroundUpdate = false
    this.$scope.$apply()

    return Promise.resolve()
  }

  onUpdateData () {
    const filters = Object.assign({}, this.filters)

    return liveOrdersCollection.get(filters, this.columnsConfig)
      .then(liveOrdersDTOResponse => {
        this.handleResponse(liveOrdersDTOResponse)
      })
      .catch(err => {
        this.logger.error(err)
      })
  }

  formatData () {
    this.rawData.forEach(rowData => {
      this.columnsConfig.forEach(column => {
        rowData[column.name] = this.getFormattedValue({
          type: column.type,
          precision: column.precision || 0,
          values: [rowData[column.name]],
          name: column.name
        })
      })
    })
  }

  $onDestroy () {
    this.onDestroy(liveOrdersCollection)
  }
}

export default {
  templateUrl,
  controller: LiveOrdersReport
}
