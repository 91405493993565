import envConfig from 'common/envConfig'
import packageTypesCollection from 'data/collections/packageTypesCollection'
import get from 'lodash-es/get'

interface IPackageType {
  id: string
  name: string,
  description: string,
  stackableTo: number,
  length: number,
  type: string
  height: number,
  weight: number,
  width: number
}

const packageTypeLimit = envConfig.businessAndBranchSettings.packageTypeLimit
/**
 * This piece of code is result of poor BE developers, that are unable
 * to save/load piece of JSON data for settings.
 *
 * Hence we have to separate inner model from outside model and manually perform
 * JSON parse/stringify when model changes
 *
 */
class PackageSettings {

  public packagesLimit: number[] = Array.from(new Array(packageTypeLimit), ((value: number, index: number): number => index + 1))
  public value: any = null
  public showPackageTypeValue: string = null
  public model: any
  public useDefault: boolean
  public removeValueWatch: () => void
  public removePackageTypeValueWatch: () => void
  public packageTypes: IPackageType[] = []

  public form: ng.IFormController

  constructor (
    private $scope: ng.IScope
  ) {}

  $onInit (): void {
    packageTypesCollection.getAll().then((packageTypes: IPackageType[]) => this.handlePackageTypesReceived(packageTypes))
    const packageValue = get(this, 'model.package.value', null)
    if (packageValue) {
      try {
        this.value = JSON.parse(packageValue)
      } catch (e) {
        this.value = null
      }
    }

    const showPackageType = get(this, 'model.showPackageType.value', null)
    if (showPackageType && this.showPackageTypeValue === null) {
      this.showPackageTypeValue = showPackageType
    }

    if (!this.model.package) {
      this.model.package = {value: '', isOverridden: false}
      this.useDefault = true
    }

    this.onOverrideModeChange(this.useDefault)
  }

  onOverrideModeChange (useDefault: boolean): void {
    this.useDefault = useDefault
    if (useDefault) {
      this.turnOffModelWatcher()
      this.generateDefaultValues()
      this.notifyFormAboutModelChanges()
    } else {
      this.turnOnModelWatcher()
    }
  }

  turnOnModelWatcher (): void {
    this.removeValueWatch = this.$scope.$watch('$ctrl.value', () => {
      if (this.value) {
        this.checkDefaultDelivery()
        this.notifyFormAboutModelChanges()
      }
    }, true)

    this.removePackageTypeValueWatch = this.$scope.$watch('$ctrl.showPackageTypeValue', () => {
      if (this.showPackageTypeValue) {
        this.notifyFormAboutModelChanges()
      }
    })
  }

  turnOffModelWatcher (): void {
    if (typeof this.removeValueWatch === 'function') {
      this.removeValueWatch()
    }
    if (typeof this.removePackageTypeValueWatch === 'function') {
      this.removePackageTypeValueWatch()
    }
  }

  notifyFormAboutModelChanges (): void {
    const newValue = JSON.stringify(this.value)

    if (this.model.package.value !== newValue || this.model.package.isOverridden !== !this.useDefault) {
      this.model.package = {
        value: newValue,
        isOverridden: !this.useDefault
      }
      this.form.$setDirty()
    }

    if (this.model.showPackageType && (
      this.model.showPackageType.value !== this.showPackageTypeValue
        || this.model.showPackageType.isOverridden !== !this.useDefault)) {
      this.model.showPackageType = {
        value: this.showPackageTypeValue,
        isOverridden: !this.useDefault
      }
      this.form.$setDirty()
    }
  }

  generateDefaultValues (): void {
    if (this.packageTypes && this.packageTypes.length) {
      const res = {}
      this.packageTypes.forEach((packageType: IPackageType, index: number) => {
        if (!res.hasOwnProperty(packageType.id)) {
          res[packageType.id] = {
            enabled: (index === 0),
            limit: 1,
            isDefault: (index === 0)
          }
        }
      })

      this.value = res
      this.showPackageTypeValue = 'show'
    }
  }

  onDefaultChange (changes: any): void {
    const newDefaultItemId = changes.itemId

    this.packageTypes.forEach((packageType: IPackageType) => {
      this.value[packageType.id].isDefault = (packageType.id === newDefaultItemId)
    })

    this.checkDefaultDelivery()
  }

  checkDefaultDelivery (): void {
    const packageKeys = Object.keys(this.value)

    let foundValidDefaultDelivery = false
    packageKeys.forEach((packageId: string) => {
      const pkg = this.value[packageId]
      if (pkg.enabled) {
        if (pkg.isDefault) {
          foundValidDefaultDelivery = true
        }
      } else {
        pkg.isDefault = false
      }
    })

    // if no default option
    if (!foundValidDefaultDelivery) {
      packageKeys.forEach((packageId: string) => {
        if (!foundValidDefaultDelivery && this.value[packageId].enabled) {
          this.value[packageId].isDefault = true
          foundValidDefaultDelivery = true
        }
      })
    }
    // if still no default option
    // that means all package types have been disabled
    if (!foundValidDefaultDelivery) {
      this.value[packageKeys[0]].isDefault = true
      this.value[packageKeys[0]].enabled = true
    }
  }

  handlePackageTypesReceived (packageTypes: IPackageType[]): void {
    this.packageTypes = packageTypes
    /**
     * When settings block initialised, packageTypes are usually
     * not available, so we check for null value (no saved package settings)
     * and initialize if needed
     */
    if (this.value === null) {
      this.generateDefaultValues()
    }
    this.$scope.$digest()
  }
}

export default {
  templateUrl: require('./package-settings.pug'),
  controller: PackageSettings,
  bindings: {
    form: '<',
    model: '=',
    isEditing: '<'
  }
}
