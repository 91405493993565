import {ProductOption} from 'data/domain-objects/Product'
import * as PRODUCT_EVENTS from 'common/constants/ProductEvents'
import BasePopupController from 'presentation/common/BasePopupController'

export const PRODUCT_OPTION_DIALOG_ID = 'product-option-dialog'

interface ICallbackPayload {
  option: ProductOption
}

class ProductOptionDialogController extends BasePopupController {
  public productOption: ProductOption
  public onSubmit: (payload: ICallbackPayload) => void

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IScope
  ) {
    super($scope, $rootScope, PRODUCT_EVENTS.ADD_PRODUCT_OPTION_EVENT, PRODUCT_OPTION_DIALOG_ID)

    this.setupPopupOpenEventListener((payload: any): void => {
      this.resetModel()

      if (payload && payload.model) {
        this.productOption = ProductOption.build(payload.model)
      }
    })
  }

  resetModel (): void {
    this.productOption = ProductOption.build({})
  }

  submit (): void {
    this.onSubmit({option: this.productOption})
    this.close()
  }

  cancel (): void {
    this.close()
  }
}

export default {
  templateUrl: require('./product-option-dialog.pug'),
  controller: ProductOptionDialogController,
  bindings: {
    onClose: '&?',
    onSubmit: '&'
  }
}
