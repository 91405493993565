import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'

import {StateProvider} from '@uirouter/angularjs'
import promoCodesList from 'presentation/tools/promo-codes/promo-codes-list/promoCodesList'
import promoCodeCreate from './promo-code-create/promoCodeCreate'
import promoCodeView from './promo-code-view/promoCodeView'
import promoCodeEditor from './promo-code-editor/promoCodeEditor'

export default declareAppModule('promoCodes', (module: ng.IModule) => module
    .component('promoCodesList', promoCodesList)
    .component('promoCodeCreate', promoCodeCreate)
    .component('promoCodeView', promoCodeView)
    .component('promoCodeEditor', promoCodeEditor)
    .config(($stateProvider: StateProvider) => {
      declareState($stateProvider, {
        parent: 'main.tools',
        stateName: 'promo-codes',
        componentName: 'promoCodesList',
        stateKey: 'PROMO_CODES',
        requiresPermission: 'PROMO_CODES_LIST'
      })

      declareState($stateProvider, {
        parent: 'main.tools.promo-codes',
        stateName: 'promo-code',
        componentName: 'promoCodeView',
        idParam: 'promoCodeId',
        stateKey: 'PROMO_CODES',
        requiresPermission: 'PROMO_CODE_VIEW'
      })

      declareState($stateProvider, {
        parent: 'main.tools.promo-codes',
        stateName: 'create',
        componentName: 'promoCodeCreate',
        stateKey: 'PROMO_CODES',
        requiresPermission: 'PROMO_CODE_CREATE'
      })

    })
)
