import Logger from 'common/Logger'
import Place from 'data/domain-objects/Place'
import {placesCollection} from 'data/collections/placesCollection'
import {StateParams, StateService} from '@uirouter/core'

const logger = new Logger('Place Create Controller')

class PlaceCreateController {
  public place: Place
  public isSubmitting: boolean
  public placeForm: ng.IFormController
  public formTitleKey: string

  private globalOnBeforeUnloadHandler: any

  constructor (
    private $state: StateService,
    private $stateParams: StateParams,
    private $scope: ng.IScope,
    private $filter: ng.IFilterService
  ) {}

  $onInit(): void {
    if (this.$stateParams.place !== null) {
      this.place = this.$stateParams.place
      this.formTitleKey = 'PAGES.PLACE_CREATE.TITLE_DUPLICATE'
      this.globalOnBeforeUnloadHandler = window.addEventListener('beforeunload', this.onBeforeUnload)
    } else {
      this.place = new Place()
      this.formTitleKey = 'PAGES.PLACE_CREATE.TITLE_CREATE_NEW'
    }
  }

  $onDestroy(): void {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  }

  public createPlace(): void {
    if (this.placeForm.$valid) {
      this.isSubmitting = true
      placesCollection.createPlace(this.place)
        .then((place: Place) => {
          this.$state.go('main.tools.places.place', {
            placeId: place.id
          })
        })
        .catch((error: Error) => {
          logger.error('Error occurred while creating a place', error)
        })
        .then(() => {
          this.isSubmitting = false
          this.$scope.$digest()
        })
    }
  }

  private onBeforeUnload(): string {
    return this.$filter<ITranslateFilter>('translate')('PAGES.PLACE_CREATE.LEAVE_PAGE_CONFIRM') // https://stackoverflow.com/a/38880926
  }
}

export default {
  templateUrl: require('./place-create.pug'),
  controller: PlaceCreateController
}
