import get from 'lodash-es/get'
import set from 'lodash-es/set'
import cloneDeep from 'lodash-es/cloneDeep'

export const valueToAmount = (source: object, paths: string[] = []): any => {
  const sourceCopy = cloneDeep(source)
  paths.forEach((path: string) => {
    const replacement = get(source, path, null)
    if (replacement !== null) {
      set(sourceCopy, path, {
        amount: replacement.value,
        currencyCode: replacement.currencyCode
      })
    }
  })
  return sourceCopy
}
