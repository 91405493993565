import envConfig from 'common/envConfig'
import moment from 'moment'
import Logger from 'common/Logger'
import Cost from 'data/domain-objects/Cost'
import DateUtils from 'common/utils/dateUtils'
import storefrontOrdersCollection from 'data/collections/storefrontOrdersCollection'
import { StorefrontOrdersStatusesType, STOREFRONT_ORDER_STATUSES } from 'common/constants/StorefrontOrderStatuses'
import { STOREFRONT_DELIVERY_CREATION_STATUSES } from 'common/constants/StorefrontDeliveryCreationStatuses'
import { STOREFRONT_ORDER_STATUS_UPDATED } from 'common/constants/StorefrontOrderEvents'
import { compareDates } from 'data/common/comparator'
import { ShippingMethods } from 'common/constants/ShippingMethods'
import { ShippingProviders } from 'common/constants/ShippingProviders'
import businessesCollection from 'data/collections/businessesCollection'
import StorefrontBusinessDetails from 'data/domain-objects/storefront/StorefrontBusinessDetails'
import StorefrontOrder from 'data/domain-objects/storefront/StorefrontOrder'
import StorefrontOrderDeliveryInformation from 'data/domain-objects/storefront/StorefrontOrderDeliveryInformation'
import StorefrontOrderEvent, { StorefrontOrderEventTypes, StorefrontOrderPaymentEventTypes } from 'data/domain-objects/storefront/StorefrontOrderEvent'
import { StorefrontOrderReceiptTemplates } from 'common/constants/StorefrontOrderReceiptTemplates'
import { PaymentMethod, CARD, KNET } from 'common/constants/PaymentMethods'
import { copyToClipboard } from 'common/utils/clipboardUtils'

const logger = new Logger('Order Controller')
const POLLING_INTERVAL = 10000
const GOOGLE_LINK = 'https://www.google.com/maps/search/?api=1&query={{latitude}},{{longitude}}'

const MINUTES_SUFFIX_TRANSLATION_KEY = 'COMMON.DURATIONS.ABBREVIATION.MINU'
const RECEIPT_TEMPLATE_SETTING_KEY = 'receipts__template'
const RECEPT_SHOW_LOGO_SETTING_KEY = 'receipts__printRestaurantLogo'
const ORDER_PREPARATION_TIME_KEY = 'orderPreparationTime'
const AUTO_PRINT_RECEIPT_SETTING_KEY = 'receipts__autoPrint'
const POS_TRANSLATION_KEY = 'COMPONENTS.ORDER.LABELS.POS'

enum RefundErrorCodes {
  alreadyRequested = 'ERR_ORDER_REFUND_ALREADY_REQUESTED',
  failed = 'ERR_ORDER_REFUND_FAILED',
  notAvailable = 'ERR_ORDER_REFUND_NOT_AVAILABLE'
}

class OrderController {
  public isModal: boolean
  public isLoading: boolean = true
  public orderId: string = null
  public businessId: string = null
  public order: StorefrontOrder = null
  public businessDetails: StorefrontBusinessDetails = null
  public subtotal: Cost = Cost.build()
  public orderStatuses: any = STOREFRONT_ORDER_STATUSES
  public extraTimeOptions: number[] = [
    0,
    10,
    20,
    30,
    40,
    50,
    60
  ]
  public extraTimeOptionLabels: any = {}
  public extraTime: number
  public lastUpdate: string = ''
  public createdAt: string = ''
  public shippingMethods: typeof ShippingMethods = ShippingMethods
  public shippingProviders: typeof ShippingProviders = ShippingProviders

  public shouldCreateDelivery: boolean = false

  public buttonsVisibility: {
    accepted: boolean
    readyForCollection: boolean
    collected: boolean
    completed: boolean
    declined: boolean
  }

  public estimatedPickupTime: string = null

  public deliveryCreationStatuses: any = STOREFRONT_DELIVERY_CREATION_STATUSES
  public hasDeliveries: boolean = false
  public hasCreatedDeliveries: boolean = false
  public isSchedulingDelivery: boolean = false
  public schedulingHasBeenRequested: boolean = false
  public receiptTemplate: StorefrontOrderReceiptTemplates = StorefrontOrderReceiptTemplates.A4
  public orderPreparationTime: string
  public showLogo: boolean = false
  public doAutoPrint: boolean = false

  public orderEventTypes: typeof StorefrontOrderEventTypes = StorefrontOrderEventTypes
  public allOrderEventTypes: StorefrontOrderEventTypes[] = []

  public orderPaymentEventTypes: typeof StorefrontOrderPaymentEventTypes = StorefrontOrderPaymentEventTypes
  public allOrderPaymentEventTypes: StorefrontOrderPaymentEventTypes[] = []

  public isRequestingRefund: boolean = false
  public refundHasBeenRequested: boolean = false
  public refundError: string = ''
  public refundErrorCodes: typeof RefundErrorCodes = RefundErrorCodes

  public isCustomerInfoCopied: boolean = false

  private createDeliveryHasChanged: boolean = false
  private pollingTimeout: number

  constructor(
    private $scope: ng.IScope,
    private $filter: ng.IFilterService
  ) {
    this.allOrderEventTypes = Object.values(StorefrontOrderEventTypes)
    this.allOrderPaymentEventTypes = Object.values(StorefrontOrderPaymentEventTypes)
  }

  $onInit(): void {
    this.extraTimeOptions.forEach((option: number): void => {
      this.extraTimeOptionLabels[option] = `${option} ${this.$filter<ITranslateFilter>('translate')(MINUTES_SUFFIX_TRANSLATION_KEY)}`
    })

    this.extraTime = this.extraTimeOptions[0]

    if (this.businessId) {
      this.getBusinessDetails(this.businessId)
    }
  }

  $onChanges(): void {
    if (this.businessId && this.orderId) {
      if (this.pollingTimeout) {
        this.stopPolling()
      }

      this.startPollingOrder()
    }
  }

  $onDestroy(): void {
    this.order = null
    this.stopPolling()
  }

  public get isPaymentMethodCard(): boolean {
    return this.order && this.order.payment && this.order.payment.method === CARD
  }

  public get isPaymentMethodKnet(): boolean {
    return this.order && this.order.payment && this.order.payment.method === KNET
  }

  public get hasSKUsInOrderItems(): boolean {
    return this.order && this.order.items && this.order.items.some((item: any) => !!item.sku)
  }

  public get showActionsBlock(): boolean {
    const condition: boolean = [
      StorefrontOrdersStatusesType.cancelled,
      StorefrontOrdersStatusesType.declined,
      StorefrontOrdersStatusesType.completed,
      StorefrontOrdersStatusesType.collected
    ].includes(this.order && this.order.status)

    const isDelivery: boolean = this.order &&
      this.order.shipping.method &&
      this.order.shipping.method === this.shippingMethods.delivery

    const hasDeliveryRef: boolean = this.order &&
      this.order.shipping &&
      this.order.shipping.deliveries &&
      this.order.shipping.deliveries.some((delivery: StorefrontOrderDeliveryInformation) => !!delivery.ref)

    if (!condition) {
      return isDelivery
    }

    return isDelivery && hasDeliveryRef
  }

  public get showRefundButton(): boolean {
    return this.order &&
      this.order.status === this.orderStatuses.CANCELLED &&
      !this.order.payment.refundRequested &&
      !this.order.payment.refundProcessed &&
      (this.order.payment.method === PaymentMethod.card || this.order.payment.method === PaymentMethod.knet) &&
      !this.refundHasBeenRequested
  }

  public copyCustomerInfo(): void {
    const posTitle = this.$filter<ITranslateFilter>('translate')(POS_TRANSLATION_KEY)
    const pos = this.order.posReference
    const name = this.order.contactDetails.getFullName()
    const phone = this.order.contactDetails.getFormattedPhone().replace(' ', '')
    const address = this.order.shipping.address.getFormattedAddress()
    const additionalDirections = this.order.shipping.address.getAdditionalDirections()
    const latitude = this.order.shipping.address.location.latitude.toString()
    const longitude = this.order.shipping.address.location.longitude.toString()
    const link = GOOGLE_LINK
      .replace('{{latitude}}', latitude)
      .replace('{{longitude}}', longitude)

    const str = `${posTitle} ${pos} ${name} ${phone}\r\n${address} ${additionalDirections}\r\n${link}`

    copyToClipboard(str)
    this.isCustomerInfoCopied = true
  }

  public retryDeliveryCreation(): void {
    storefrontOrdersCollection.retryDeliveryCreation(this.businessId, this.orderId)
      .then(() => {
        this.schedulingHasBeenRequested = true
        this.$scope.$digest()
      })
  }

  public recalculateEstimatedPickupTime(minutes: number): void {
    this.extraTime = minutes
    this.estimatedPickupTime = this.getEstimatedPickupTime(this.order)
  }

  public print(): void {
    window.print()
  }

  public acceptOrder(): void {
    this.updateStatus(
      this.orderStatuses.ACCEPTED,
      this.extraTime,
      this.shouldCreateDelivery
    ).then(() => {
      if (this.doAutoPrint) {
        this.print()
      }
    })
  }

  public cancelOrder(): void {
    let statusToSet: StorefrontOrdersStatusesType = StorefrontOrdersStatusesType.cancelled
    if (this.order.status === StorefrontOrdersStatusesType.submitted) {
      statusToSet = StorefrontOrdersStatusesType.declined
    }
    this.updateStatus(statusToSet)
  }

  public updateStatus(status: StorefrontOrdersStatusesType, extraTime?: number, createDelivery?: boolean): Promise<void> {
    this.stopPolling()

    this.isLoading = true
    return storefrontOrdersCollection.updateOrderStatus(
      this.businessId,
      this.orderId,
      status,
      extraTime,
      createDelivery
    ).then(() => {
      this.startPollingOrder()
      this.$scope.$emit(STOREFRONT_ORDER_STATUS_UPDATED)
    })
  }

  public getIsToday(timestamp: number): boolean {
    return DateUtils.getIsToday(timestamp)
  }

  public formatEventIntoClass(event: StorefrontOrderEvent): string {
    const specificStatuses = [
      STOREFRONT_ORDER_STATUSES.SUBMITTED,
      STOREFRONT_ORDER_STATUSES.COLLECTED,
      STOREFRONT_ORDER_STATUSES.READY_FOR_COLLECTION,
      STOREFRONT_ORDER_STATUSES.COMPLETED
    ]

    if (event.type === StorefrontOrderEventTypes.statusChange && specificStatuses.indexOf(event.value as StorefrontOrdersStatusesType) >= 0) {
      return `${event.value}`
    }

    if (!this.allOrderEventTypes.includes(event.type)) {
      return StorefrontOrderEventTypes.unknown
    }

    return `${event.type}`
  }

  public onCreateDeliveryChanged(): void {
    this.createDeliveryHasChanged = true
  }

  public requestRefund(): void {
    this.refundError = ''
    this.refundHasBeenRequested = false
    this.isRequestingRefund = true
    storefrontOrdersCollection.requestRefund(this.businessId, this.orderId)
      .then(() => {
        this.refundHasBeenRequested = true
      })
      .catch((error: any) => {
        this.refundError = error.code
        if (!Object.values(RefundErrorCodes).includes(error.code)) {
          this.refundError = RefundErrorCodes.failed
        }
      })
      .then(() => {
        this.isRequestingRefund = false
        this.$scope.$digest()
      })
  }

  private recalculateDeliveriesState(): void {
    if (this.order && this.order.shipping && this.order.shipping.deliveries) {
      this.schedulingHasBeenRequested = false
      this.hasDeliveries = !!this.order.shipping.deliveries
        .filter((delivery: StorefrontOrderDeliveryInformation): boolean => {
          return delivery.creation !== this.deliveryCreationStatuses.NOT_REQUIRED
        }).length

      if (this.hasDeliveries) {
        this.hasCreatedDeliveries = !!this.order.shipping.deliveries
          .filter((delivery: StorefrontOrderDeliveryInformation): boolean => {
            return delivery.creation === this.deliveryCreationStatuses.COMPLETED
          }).length
      }
    } else {
      this.hasDeliveries = false
      this.hasCreatedDeliveries = false
    }
  }

  private recalculateButtonsVisibility(): void {
    this.buttonsVisibility = {
      accepted: this.order.status === STOREFRONT_ORDER_STATUSES.SUBMITTED,
      readyForCollection: this.order.status === STOREFRONT_ORDER_STATUSES.ACCEPTED,
      collected: this.order.status === STOREFRONT_ORDER_STATUSES.READY_FOR_COLLECTION && !this.hasCreatedDeliveries,
      completed: this.order.status === STOREFRONT_ORDER_STATUSES.COLLECTED && !this.hasCreatedDeliveries,
      declined: [
        STOREFRONT_ORDER_STATUSES.SUBMITTED,
        STOREFRONT_ORDER_STATUSES.ACCEPTED,
        STOREFRONT_ORDER_STATUSES.READY_FOR_COLLECTION
      ].indexOf(this.order.status) >= 0
    }
  }

  private stopPolling(): void {
    clearTimeout(this.pollingTimeout)
  }

  private startPollingOrder(): Promise<void> {
    this.isLoading = true
    return Promise.all([
      businessesCollection.getStorefrontSettings(this.businessId),
      storefrontOrdersCollection.getById(this.businessId, this.orderId)
    ]).then(([settings, order]: [any, StorefrontOrder]) => {
      this.isLoading = false
      this.order = order
      this.order.events.reverse()
      this.receiptTemplate = settings[RECEIPT_TEMPLATE_SETTING_KEY].value
      this.orderPreparationTime = settings[ORDER_PREPARATION_TIME_KEY].value
      this.showLogo = settings[RECEPT_SHOW_LOGO_SETTING_KEY].value
      this.doAutoPrint = settings[AUTO_PRINT_RECEIPT_SETTING_KEY].value

      if (!this.createDeliveryHasChanged && this.order.shouldCreateDeliveryWhileAccept) {
        this.shouldCreateDelivery = true
      }

      this.hasDeliveries =
        order &&
        order.shipping &&
        !!(order.shipping.deliveries || [])
          .filter((delivery: StorefrontOrderDeliveryInformation): boolean => delivery.creation !== this.deliveryCreationStatuses.NOT_REQUIRED)
          .length
      this.subtotal = this.getSubtotal(order)
      this.recalculateDeliveriesState()
      this.recalculateButtonsVisibility()
      this.pollingTimeout = window.setTimeout(() => this.startPollingOrder(), POLLING_INTERVAL)
      this.estimatedPickupTime = this.getEstimatedPickupTime(order)
      this.lastUpdate = this.$filter<ISpecificTimeFilter>('specificTimeTo')(this.order.updatedAt.toISOString(), true, true)
      this.createdAt = this.$filter<ISpecificTimeFilter>('specificTimeTo')(this.order.createdAt.toISOString(), true, true)
      this.$scope.$digest()
    })
      .catch((error: Error) => {
        logger.error('Cannot get order by ID', error)
        this.stopPolling()
      })
  }

  private getBusinessDetails(businessId: string): void {
    businessesCollection.getStorefrontDetails(businessId)
      .then((businessDetails: StorefrontBusinessDetails) => {
        this.businessDetails = businessDetails
        this.$scope.$digest()
      })
  }

  private getEstimatedPickupTime(order: StorefrontOrder): string {
    let estimatedPickupTime: Date = order.branches
      .map((branch: any): Date => branch.deliveryEstimatedPickupTime)
      .sort((dateA: Date, dateB: Date): number => compareDates(dateB, dateA)) // pay attention! DESC sorting
      .shift()

    if (!estimatedPickupTime) {
      return '-'
    }

    estimatedPickupTime = moment(estimatedPickupTime).add(this.extraTime, 'minutes').toDate()

    let date = ''

    if (DateUtils.getIsToday(estimatedPickupTime.getTime())) {
      date = `${this.$filter<ITranslateFilter>('translate')('COMMON.DAYS.TODAY')},`
    } else if (DateUtils.getIsTomorrow(estimatedPickupTime.getTime())) {
      date = `${this.$filter<ITranslateFilter>('translate')('COMMON.DAYS.TOMORROW')},`
    } else {
      date = moment(estimatedPickupTime).format(envConfig.dateOnlyDateFormat)
    }

    return `${date} ${moment(estimatedPickupTime).format(envConfig.timeOnlyDateFormat)}`
  }

  private getSubtotal(order: StorefrontOrder): Cost {
    return Cost.build({
      amount: order.items.reduce((acc: number, item: any): number => acc + item.totalPrice.value, 0)
    })
  }
}

export default {
  templateUrl: require('./order.pug'),
  controller: OrderController,
  bindings: {
    businessId: '<',
    orderId: '<',
    isModal: '<?'
  }
}
