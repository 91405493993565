import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import businessesCollection from 'data/collections/businessesCollection'
import fleetsCollection from 'data/collections/fleetsCollection'

const CACHE_PARAMS = {useCache: true}

const rolesService = new CrudService(endpoints.ROLES)
const rolesAssignableService = new CrudService(endpoints.ROLES_ASSIGNABLE)

function getRoleIdsArray (roles) {
  return roles.map(role => {
    return role.id
  })
}

function getRoleNamesMap (roles) {
  const rolesMap = {}

  roles.forEach(role => {
    rolesMap[role.id] = role.name || ''
  })

  return rolesMap
}

function getRoleEntitiesMap (roles) {
  const rolesMap = {}

  roles.forEach(role => {
    rolesMap[role.id] = role.entities || []
  })

  return rolesMap
}

function getRoleRemovalFlagMap (roles) {
  const rolesMap = {}

  roles.forEach(role => {
    rolesMap[role.id] = role.canRemoveIfAssigned || false
  })

  return rolesMap
}

function getRolesByIds (rolesIds = []) {
  return rolesService.get([], CACHE_PARAMS).then(roles => {
    return roles.filter(role => rolesIds.indexOf(role.id) > -1)
  })
}

function processGetAllDataReceived (entityCollections) {
  const [businessesAndBranches, fleetsData, roles] = entityCollections

  const branchesMap = {}
  const businessesMap = {}
  const branches = []
  const businesses = []
  businessesAndBranches.forEach(business => {
    businesses.push({
      id: business.id,
      name: business.name
    })
    businessesMap[business.id] = business.name
    business.branches.forEach(branch => {
      branchesMap[branch.id] = `${business.name} - ${branch.name}`
      branches.push({
        id: branch.id,
        name: `${business.name} - ${branch.name}`
      })
    })
  })

  const fleetsMap = {}
  fleetsData.forEach(fleet => {
    fleetsMap[fleet.id] = fleet.name
  })

  return {
    branches,
    branchesMap,
    businesses,
    businessesMap,
    fleets: fleetsData,
    fleetsMap,
    roles,
    roleIds: getRoleIdsArray(roles),
    rolesById: getRoleNamesMap(roles),
    rolesByIdMappedToEntities: getRoleEntitiesMap(roles),
    rolesByIdMappedToRemoveFlag: getRoleRemovalFlagMap(roles)
  }
}

export default {
  getRolesByIds,
  getAllWithSupportingData: () => {
    return Promise.all([
      businessesCollection.getBusinessesWithTheirBranches(),
      fleetsCollection.getAllCached(),
      rolesService.get([], CACHE_PARAMS)
    ])
      .then(entityCollections => processGetAllDataReceived(entityCollections))
  },
  getAllAssignableWithSupportingData: () => {
    return Promise.all([
      businessesCollection.getBusinessesWithTheirBranches(),
      fleetsCollection.getAllCached(),
      rolesAssignableService.get([], CACHE_PARAMS)
    ])
      .then(entityCollections => processGetAllDataReceived(entityCollections))
  }
}
