import templateUrl from './driver-logo.pug'

export default {
  templateUrl,
  bindings: {
    logoUrl: '=',
    onboarded: '=',
    hideOnBoardedState: '<'
  }
}
