import envConfig from 'common/envConfig'
import moment from 'moment'
import Cost from 'data/domain-objects/Cost'
import { ShippingMethods } from 'common/constants/ShippingMethods'
import StorefrontBusinessDetails from 'data/domain-objects/storefront/StorefrontBusinessDetails'
import StorefrontOrder from 'data/domain-objects/storefront/StorefrontOrder'
import { StorefrontOrdersStatusesType } from 'common/constants/StorefrontOrderStatuses'
import { StorefrontOrderReceiptTemplates } from 'common/constants/StorefrontOrderReceiptTemplates'

enum DateFormats {
  TIME = 'HH:mm',
  DATE_TIME = 'HH:mm dd/MM/yyyy'
}

class PrintableOrderController {
  // Inputs
  public order: StorefrontOrder = null
  public businessDetails: StorefrontBusinessDetails = null
  public orderPreparationTime: number
  public template: StorefrontOrderReceiptTemplates

  // Props
  public shippingMethods: typeof ShippingMethods = ShippingMethods
  public estimatedTime: string

  constructor(
    private $filter: ng.IFilterService
  ) { }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.order) {
      this.recalculateEstimatedTime()
    }
  }

  recalculateEstimatedTime(): void {
    let date: Date

    if (this.order.shipping.method === ShippingMethods.collection && this.order.shipping.desiredTime) {
      date = this.order.shipping.desiredTime
    } else {
      if (this.order.status !== StorefrontOrdersStatusesType.submitted) {
        date = this.order.goodsPreparationTime.estimatedTime
      } else {
        if (this.order.shipping.method === ShippingMethods.delivery) {
          date = this.order.branch.deliveryEstimatedPickupTime
        } else if (this.order.shipping.method === ShippingMethods.collection) {
          date = new Date(new Date().getTime() + this.orderPreparationTime * 60 * 1000)
        }
      }
    }

    const format: DateFormats = this.order.isToday(date.toISOString()) ? DateFormats.TIME : DateFormats.DATE_TIME
    this.estimatedTime = this.$filter<ng.IFilterDate>('date')(date, format)
  }

  public get createdAt(): string {
    return moment(this.order.createdAt.toISOString()).format(envConfig.defaultDateFormat)
  }

  public get desiredTime(): string {
    return moment(this.order.shipping.desiredTime.toISOString()).format(envConfig.defaultDateFormat)
  }

  public get subtotal(): Cost {
    return Cost.build({
      amount: this.order.items.reduce((acc: number, item: any): number => acc + item.totalPrice.value, 0)
    })
  }
}

export default {
  templateUrl: require('./printable-order.pug'),
  controller: PrintableOrderController,
  bindings: {
    order: '<',
    orderPreparationTime: '<',
    showLogo: '<',
    businessDetails: '<',
    template: '<'
  }
}
