import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DriverAnalyticsData from 'data/domain-objects/DriverAnalytics'

import moment from 'moment'

const driverAnalyticsService = new CrudService(endpoints.REPORTS_DRIVER_ANALYTICS)

/**
 * Backend for report was designed to allow requesting only the changed data, so
 * this collection has cache that stores state and merges it with updated date
 * when needed
 */
export function driverAnalyticsCollectionFactory (driverAnalyticsService) {
  let columns = null

  const purgeCache = () => {
    columns = null
  }

  const get = (params = {}) => {
    const searchParams = {}

    searchParams.start = moment((params.start) ? params.start : params.initialDate).valueOf()
    if (params.finish) {
      searchParams.finish = moment(params.finish).valueOf()
    }

    return driverAnalyticsService.get([], searchParams).then(dto => {
      const data = new DriverAnalyticsData(dto, columns)
      if (columns === null) {
        columns = {
          drivers: data.drivers.metadata.columns,
          fleets: data.fleets.metadata.columns
        }
      }

      return data
    })
  }

  return {
    get,
    purgeCache
  }
}

export default driverAnalyticsCollectionFactory(driverAnalyticsService)
