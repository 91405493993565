import Cost from '../Cost'

export default class StorefrontAppliedPromoCode {
  constructor(
    public id: string,
    public name: string,
    public goods: Cost,
    public delivery: Cost,
  ) {}

  static build(dto: any): StorefrontAppliedPromoCode {
    return new StorefrontAppliedPromoCode(
      dto.id,
      dto.name,
      Cost.build(dto.goods),
      Cost.build(dto.delivery)
    )
  }
}
