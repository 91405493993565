import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

export default class AddressAreaSurcharge {
  constructor (
    public multiplier: number = 1,
    public areas: DBMappedNamedEntity[] = []
  ) {}

  static build (dto: any = {}): AddressAreaSurcharge {
    return new AddressAreaSurcharge(
      parseFloat(dto.multiplier),
      dto.areas ? dto.areas.map(DBMappedNamedEntity.build) : []
    )
  }
}
