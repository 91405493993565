import templateUrl from './delivery-cancel-dialog.pug'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import {DELIVERY_ACTIONS, DELIVERY_TYPES} from 'data/collections/deliveriesCollection'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import BasePopupController from 'presentation/common/BasePopupController'
import DeliveryDataService from 'data/common/deliveryDataService'

class DeliveryCancelDialog extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DELIVERY_ACTIONS.CANCEL, deliveryDialogIds.CANCEL_CONFIRMATION_DIALOG_ID)
    this.CANCEL_CONFIRMATION_DIALOG_ID = deliveryDialogIds.CANCEL_CONFIRMATION_DIALOG_ID

    this.setupPopupOpenEventListener(delivery => {
      this.isNextDay = delivery.type === DELIVERY_TYPES.NEXT_DAY
      this.deliveryId = delivery.id
    })
  }

  confirm () {
    if (this.deliveryId) {
      DeliveryDataService.publishDeliveryLoadingEvent()
      const promisedResult = this.isNextDay ?
        deliveriesCollection.deleteNextDayDelivery(this.deliveryId) :
        deliveriesCollection.delete(this.deliveryId)

      promisedResult
        .then(() => {
          this.onConfirm()
          DeliveryDataService.publishDeliverySyncEvent()
        })
        .catch(error => {
          this.logger.error('Cannot delete delivery', error)
          this.onConfirmFailed({error})
        })
    }
  }
}

export default {
  templateUrl,
  controller: DeliveryCancelDialog,
  bindings: {
    onConfirm: '&',
    onConfirmFailed: '&'
  }
}
