import ReportUtils from 'data/domain-objects/ReportUtils'

export class LiveOrdersParams {
  constructor (
    public limit: number = 0,
    public orderDirection: number = -1,
    public orderBy: string = '',
    public skip: number = 0,
    public columns: any[] = [] ,
    public count: number = 0
  ) {}

  static build(dto: any = {}): LiveOrdersParams {
    return new LiveOrdersParams(
      dto.limit || 0,
      dto.orderDirection || -1,
      dto.orderBy || '',
      dto.skip || 0,
      dto.columns || [],
      dto.count || 0
    )
  }
}

export default class LiveOrdersData {

  public records: any = {}
  public metadata: LiveOrdersParams = null

  constructor (
    dto: any = {},
    columns: any = []
  ) {
    this.metadata = LiveOrdersParams.build(dto.metadata)

    for (let i = 0, len = dto.records.length; i < len; i++) {
      const record = ReportUtils.parseReportDTO(dto.records[i], columns)
      this.records[record.id] = record
    }
  }
}
