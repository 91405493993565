import get from 'lodash-es/get'
import templateUrl from './branches-branch-cash-balance.pug'
import Cost from 'data/domain-objects/Cost'
import BaseController from 'presentation/common/BaseController'
import branchesCollection from 'data/collections/branchesCollection'
import businessesCollection from 'data/collections/businessesCollection'

class BranchesBranchCashBalanceController extends BaseController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams

    this.branch = null
    this.business = null
    this.branchBalance = null

    this.sync()
  }

  onSync () {
    const businessId = this.$stateParams.businessId
    const branchId = this.$stateParams.branchId

    return Promise.all([
      businessesCollection.get(businessId),
      branchesCollection.getBranchWithBalance(branchId)
    ]).then(([business, branch]) => this.handleBusinessAndBranch(business, branch))
      .catch(error => {
        this.logger.error('Could not get business and branch given ids', businessId, branchId, error)
      })
  }

  handleBusinessAndBranch (business, branch) {
    this.business = business
    this.branch = branch

    const lastBalance = get(branch, 'cashBalanceAccount.lastBalance')
    if (lastBalance) {
      this.branchBalance = Cost.build(lastBalance)
    } else {
      throw new Error('Branch has no cash balance account')
    }
  }
}

export default {
  templateUrl,
  controller: BranchesBranchCashBalanceController
}
