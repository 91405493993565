import rideReportCollection from 'data/collections/rideReportCollection'
import { getItemFromLocalStorage } from 'common/utils/storageUtils'
import { AUTH_STORAGE_KEY, AUTH_HEADER } from 'common/agent'

class ReportsDashboard {
  public linkReport(event: MouseEvent): void {
    event.preventDefault()
    const authToken = getItemFromLocalStorage(AUTH_STORAGE_KEY)
    document.cookie = `${AUTH_HEADER}=${authToken}`
    window.open(rideReportCollection.getRawCSVUrl(), '_blank')
  }
}

export default {
  templateUrl: require('./reports-dashboard.pug'),
  controller: ReportsDashboard
}
