import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import nextDayDeliveryDashboard from './dashboard/nextDayDeliveryDashboard'
import nextDayLinkedDashboard from './next-day-linked-dashboard/nextDayLinkedDashboard'
import nextDayDelivery from './next-day-delivery/nextDayDelivery'
import inboundOutboundToggle from './inbound-outbound-toggle/inboundOutboundToggle'
import linkedDeliveriesTimeSlot from './linked-deliveries-time-slot/linkedDeliveriesTimeSlot'
import linkedDeliveriesArea from './linked-deliveries-area/linkedDeliveriesArea'
import linkedDelivery from './linked-delivery/linkedDelivery'
import transferDeliveriesDialog from './transfer-deliveries-dialog/transferDeliveriesDialog'

export default declareAppModule('deliveries.nextDay', (module: ng.IModule) => module
  .component('nextDayDeliveryDashboard', nextDayDeliveryDashboard)
  .component('linkedDeliveriesTimeSlot', linkedDeliveriesTimeSlot)
  .component('nextDayLinkedDashboard', nextDayLinkedDashboard)
  .component('inboundOutboundToggle', inboundOutboundToggle)
  .component('linkedDeliveriesArea', linkedDeliveriesArea)
  .component('nextDayDelivery', nextDayDelivery)
  .component('linkedDelivery', linkedDelivery)
  .component('transferDeliveriesDialog', transferDeliveriesDialog)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'next-day-deliveries',
      componentName: 'nextDayDeliveryDashboard',
      stateKey: 'NEXT_DAY_DELIVERIES'
    })

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'next-day-linked-deliveries',
      componentName: 'nextDayLinkedDashboard',
      stateKey: 'NEXT_DAY_LINKED_DELIVERIES'
    })
  })
)
