import envConfig from 'common/envConfig'
import localeHelper, { generateLocaleData } from 'common/localeHelper'
import { BASE_ADDRESS_FORM_NAME } from '../address-editor/addressEditor'
import LocationPoint from 'data/domain-objects/LocationPoint'
import BaseAddressTemplate from 'data/domain-objects/BaseAddressTemplate'
import MultilangAddress from 'data/domain-objects/MultilangAddress'

class MultilangAddressEditorController {
  // inputs
  public multilangAddress: MultilangAddress
  public isEditing: boolean

  // outputs

  // properties
  public locales: ILocaleData[] = []
  public currentAddressLocale: ILocaleData
  public multilangForm: ng.IFormController

  $onInit(): void {
    this.locales = envConfig.supportedLocales.map(generateLocaleData)
    this.currentAddressLocale = this.locales.find((localeData: ILocaleData) => {
      const currentLocaleCode: string = localeHelper.getLocaleData().code
      return localeData.code === currentLocaleCode
    })
  }

  public getAddressFormObject(localeCode: string): ng.IFormController {
    return this.multilangForm[`${BASE_ADDRESS_FORM_NAME}_${localeCode}`]
  }

  public setAllAddressesAddressFormat(addressFormatId: string): void {
    this.locales.forEach((localeData: ILocaleData) => {
      (this.multilangAddress[localeData.language] as BaseAddressTemplate).addressFormatId = addressFormatId
    })
  }

  public setAllAddressesLocation(addressLocation:  LocationPoint): void {
    this.locales.forEach((localeData: ILocaleData) => {
      (this.multilangAddress[localeData.language] as BaseAddressTemplate).location = addressLocation
    })
  }
}

export default {
  templateUrl: require('./multilang-address-editor.pug'),
  controller: MultilangAddressEditorController,
  bindings: {
    multilangAddress: '<',
    isEditing: '<'
  }
}
