import AddressField from './AddressField'
import LocationPoint from './LocationPoint'
import MultiLangString from './MultiLangString'
import BaseAddressTemplate from './BaseAddressTemplate'
import MultilangAddress from './MultilangAddress'

export default class PlaceExit {
  private _id: string = `pe_${(+new Date().getTime() + Math.floor(Math.random() * 10)).toString(16)}` // time-based random id

  constructor(
    public name: MultiLangString,
    public pickupInstructions: MultiLangString,
    public address: MultilangAddress
  ) { }

  get id(): string {
    return this._id
  }

  toJSON(): any {
    const mergedAddress: BaseAddressTemplate = MultilangAddress.getMergedAddress(this.address)

    return {
      ...this,

      location: mergedAddress.location,
      fields: mergedAddress.fields,
      addressFormatId: mergedAddress.addressFormatId,

      address: undefined,
      _id: undefined
    }
  }

  public static build (dto: any = {}): PlaceExit {
    const address = MultilangAddress.buildFromMultilangFields(dto)
    return new PlaceExit(
      MultiLangString.build(dto.name),
      MultiLangString.build(dto.pickupInstructions || {}),
      address
    )
  }
}
