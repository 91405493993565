import declareState from 'presentation/_utilities/declareState'
import declareAppModule from 'presentation/_utilities/declareAppModule'
import { StateProvider } from '@uirouter/angularjs'

import deliveryBatchDialog from './popups/delivery-batch-dialog/deliveryBatchDialog'
import deliveryReadyDialog from './popups/delivery-ready-dialog/deliveryReadyDialog'
import deliveryCancelDialog from './popups/delivery-cancel-dialog/deliveryCancelDialog'
import deliveryRehailDialog from './popups/delivery-rehail-dialog/deliveryRehailDialog'
import deliveryPendingDialog from './popups/delivery-pending-dialog/deliveryPendingDialog'
import deliveryDeliveredDialog from './popups/delivery-delivered-dialog/deliveryDeliveredDialog'
import deliveryCollectedDialog from './popups/delivery-collected-dialog/deliveryCollectedDialog'
import deliveryChangeDriverDialog from './popups/delivery-change-driver-dialog/deliveryChangeDriverDialog'

export default declareAppModule('deliveries', (module: ng.IModule) => module
  .component('deliveryBatchDialog', deliveryBatchDialog)
  .component('deliveryReadyDialog', deliveryReadyDialog)
  .component('deliveryCancelDialog', deliveryCancelDialog)
  .component('deliveryRehailDialog', deliveryRehailDialog)
  .component('deliveryPendingDialog', deliveryPendingDialog)
  .component('deliveryDeliveredDialog', deliveryDeliveredDialog)
  .component('deliveryCollectedDialog', deliveryCollectedDialog)
  .component('deliveryChangeDriverDialog', deliveryChangeDriverDialog)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'deliveries',
      stateKey: 'DELIVERIES',
      redirectTo: 'main.kanban',
      data: {
        titleKey: 'PAGES.DELIVERIES.TITLE'
      }
    })
  })
)
