import templateUrl from './driver-summary.pug'

class DriverSummaryController {
  constructor ($state) {
    this.$state = $state
  }

  viewDriver () {
    this.$state.go('main.logistics.drivers.driver', {driverId: this.driver.id, targetState: 'main.logistics.drivers'})
  }

  viewFleet (fleetId) {
    this.$state.go('main.logistics.fleets.fleet', {fleetId})
  }

  viewSchedule (scheduleId) {
    this.$state.go('main.logistics.schedule.details', {scheduleId})
  }
}

export default {
  templateUrl,
  controller: DriverSummaryController,
  bindings: {
    driver: '=',
    fleetsMap: '=',
    schedulesMap: '=',
    goToAddLicense: '=',
    handleDriverSelected: '='
  }
}
