import templateUrl from './standardised-form.pug'

export default function standardisedForm () {
  return {
    restrict: 'E',
    templateUrl,
    replace: true,
    transclude: true
  }
}
