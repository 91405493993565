import '../styles/app.scss'

import angular from 'angular'

import 'common/sentry'
import '@uirouter/angularjs'
import 'angular-animate'
import 'angular-messages'

import attachHasLoadedClass from './presentation/_utilities/attachHasLoadedClass'
import StateTransitionManager from './presentation/_utilities/stateTransitionManager'
import setBasePageTitleAndFavicon from './presentation/_utilities/setBasePageTitleAndFavicon'

import coreModule from './presentation/_core/coreModule'
import coreElementsModule from './presentation/_core-elements/coreElementsModule'
import coreFormsModule from './presentation/_core-forms/coreFormsModule'
import accountModule from './presentation/account/accountModule'
import accountConsumerBalanceModule from './presentation/account-consumer-balance/accountConsumerBalanceModule'
import authModule from './presentation/auth/authModule'
import businessesModule from './presentation/businesses/businessesModule'
import businessesBalanceModule from './presentation/businesses-balance/businessesBalanceModule'
import dashboardModule from './presentation/dashboard/dashboardModule'
import deliveriesModule from './presentation/deliveries/deliveriesModule'
import deliveryModule from './presentation/delivery/deliveryModule'
import deliveryCreateModule from './presentation/delivery-create/deliveryCreateModule'
import logisticsModule from './presentation/logistics/logisticsModule'
import reportsModule from 'presentation/reports/reportsModule'
import usersModule from 'presentation/users/usersModule'
import requestsModule from 'presentation/requests/requestsModule'
import kanban from 'presentation/kanban/kanbanModule'
import nextDayDelivery from 'presentation/next-day-delivery/nextDayDeliveryModule'
import toolsModule from 'presentation/tools/toolsModule'
import rideModule from './presentation/ride/rideModule'
import storefrontModule from 'presentation/storefront/storefrontModule'
import scheduledDashboardModule from 'presentation/scheduled-dashboard/scheduledDashboardModule'
import localeHelper from 'common/localeHelper'

angular.module('draewil',
  [
    'sentry',
    'ui.router',
    'ngAnimate',
    'ngMessages',
    coreModule,
    coreElementsModule,
    coreFormsModule,
    accountModule,
    accountConsumerBalanceModule,
    // accountPaymentMethodsModule,
    authModule,
    businessesModule,
    businessesBalanceModule,
    dashboardModule,
    deliveriesModule,
    deliveryModule,
    deliveryCreateModule,
    rideModule,
    logisticsModule,
    reportsModule,
    usersModule,
    requestsModule,
    kanban,
    nextDayDelivery,
    toolsModule,
    storefrontModule,
    scheduledDashboardModule
  ])
  .run(setBasePageTitleAndFavicon)
  .run(StateTransitionManager)
  .run(attachHasLoadedClass)

localeHelper.init().then(() => angular.bootstrap(document.querySelector('.draewil-app'), ['draewil']))
