import templateUrl from './next-day-delivery.pug'
import BaseController from 'presentation/common/BaseController'
import branchesCollection from 'data/collections/branchesCollection'
import templatesCollection from 'data/collections/templatesCollection'

class NextDayDeliveryController extends BaseController {
  constructor ($scope) {
    super($scope)
  }

  getPrintData (businessId, branchId) {
    return this.receiptTemplate ? Promise.resolve() : branchesCollection.getBranchSettings(businessId, branchId)
      .then(settings => {
        const receiptTemplateId = settings && settings.receiptTemplate && settings.receiptTemplate.value
        return receiptTemplateId ?
          templatesCollection.getReceiptTemplateById(receiptTemplateId) :
          Promise.reject('Empty receipt template ID given from branch settings!')
      })
      .then(receiptTemplateData => {
        this.receiptTemplate = receiptTemplateData && receiptTemplateData.body
        this.$scope.$digest()
      })
      .catch(error => this.logger.error(`Cannot get branch settings with businessId: ${businessId} and branchId: ${branchId}`, error))
  }

  toggleExpand () {
    this.isExpanded = !this.isExpanded
  }

  print () {
    this.getPrintData(this.delivery.sender.businessId, this.delivery.sender.branchId)
      .then(() => {
        this.onPrint({
          delivery: this.delivery,
          template: this.receiptTemplate
        })
      })
  }
}

export default {
  templateUrl,
  controller: NextDayDeliveryController,
  bindings: {
    delivery: '<',
    isExpanded: '<?',
    onPrint: '&',
    hostClass: '@?'
  }
}
