import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import SmsTemplate from 'data/domain-objects/SmsTemplate'

const smsTemplatesService = new CrudService(endpoints.SMS_TEMPLATES)
const receiptTemplatesService = new CrudService(endpoints.RECEIPT_TEMPLATES)
const receiptTemplateService = new CrudService(endpoints.RECEIPT_TEMPLATE)

export function templatesCollectionFactory (smsTemplatesService, receiptTemplatesService, receiptTemplateService) {
  return {
    getAllSMSTemplates () {
      return smsTemplatesService.get()
        .then(smsTemplates => smsTemplates.map(SmsTemplate.build))
    },
    getAllReceiptTemplates () {
      return receiptTemplatesService.get()
    },
    getReceiptTemplateById (templateId) {
      return receiptTemplateService.get([templateId])
    }
  }
}

export default templatesCollectionFactory(smsTemplatesService, receiptTemplatesService, receiptTemplateService)
