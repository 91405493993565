import templateUrl from './verify-account-credential.pug'
import BaseFormController from 'presentation/common/BaseFormController'
import authService from 'data/services/authService'
import userModel from 'data/models/userModel'

const NUMERIC_STRING_REGEX = /^\d+$/

class VerifyAccountCredentialController extends BaseFormController {
  constructor ($scope, $state) {
    super($scope, $state)
    const code = getValidCode($state.params.code) || ''

    this.baseTranslationKey = $state.current.data.baseTranslationKey
    this.verifyingPhone = $state.current.data.verifyingPhone // If not phone, we assume email
    this.user = {}
    this.model = {code}
    this.credentialVerified = false
    this.codeResent = false

    // If credential is already verified, show them this instead of redirecting
    userModel.getUser()
      .then(user => {
        this.user = user
        this.credentialVerified = VerifyAccountCredentialController.getIsCredentialAlreadyVerified(user, this.verifyingPhone)
        $scope.$digest()
      })
      .catch(err => this.logger.error('Cannot get current user', err))
  }

  static getIsCredentialAlreadyVerified (user, isVerifyingPhone) {
    return (isVerifyingPhone && user.phoneConfirmed) || (!isVerifyingPhone && user.emailConfirmed)
  }

  beforeSubmit () {
    return this.verifyingPhone ? this.getVerifyPhone() : this.getVerifyEmail()
  }

  getVerifyPhone () {
    const phoneNumberDescriptor = {
      phoneNumber: this.user.phoneNumber,
      countryDiallingCode: this.user.countryDiallingCode
    }
    return authService.verifyPhone(phoneNumberDescriptor, this.model.code)
  }

  getVerifyEmail () {
    const emailDescriptor = {
      email: this.user.email
    }
    return authService.verifyEmail(emailDescriptor, this.model.code)
  }

  afterSubmitSuccess () {
    this.credentialVerified = true
    this.codeResent = false
  }

  resendCode () {
    const promisedResend = this.verifyingPhone ? this.resendPhoneCode() : this.resendEmailCode()
    promisedResend()
      .then(() => { // If resend fails, stay where we are
        this.codeResent = true
        this.$scope.$digest()
      })
  }

  resendPhoneCode () {
    return authService.resendPhoneVerificationCode()
  }

  resendEmailCode () {
    return authService.resendEmailVerificationCode()
  }

  resetForm () {
    this.resetAllValidation()
    this.model = {code: ''}
    this.credentialVerified = false
    this.codeResent = false
  }
}

function getValidCode (codeString) {
  const isNumericString = NUMERIC_STRING_REGEX.test(codeString)
  return isNumericString ? codeString : null
}

export default {
  templateUrl,
  controller: VerifyAccountCredentialController
}
