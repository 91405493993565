import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import addAddressToConsumerForm from './add-address-to-consumer-form/addAddressToConsumerForm'
import addressRadioSelect from './address-radio-select/addressRadioSelect'
import availableFleets from './available-fleets/availableFleets'
import branchInformation from './branch-information/branchInformation'
import consumerForm from './consumer-form/consumerForm'
import consumerSearchForm from './consumer-search-form/consumerSearchForm'
import deliveryCreate from './create/deliveryCreate'
import deliveryCreateNextDay from './create-next-day/deliveryCreateNextDay'
import deliveryType from './delivery-type/deliveryType'
import fleetPicker from './fleet-picker/fleetPicker'
import packageDetails from './package-details/packageDetails'
import pickupTime from './pickup-time/pickupTime'
import pickupSlot from './pickup-slot/pickupSlot'
import proofOfDelivery from './proof-of-delivery/proofOfDelivery'
import orderDetails from './order-details/orderDetails'
import otherInformation from './other-information/otherInformation'
import selectConsumerAddress from './select-consumer-address/selectConsumerAddress'

export default declareAppModule('delivery-create', (module: ng.IModule) => module
  .component('addAddressToConsumerForm', addAddressToConsumerForm)
  .component('addressRadioSelect', addressRadioSelect)
  .component('availableFleets', availableFleets)
  .component('branchInformation', branchInformation)
  .component('consumerForm', consumerForm)
  .component('consumerSearchForm', consumerSearchForm)
  .component('deliveryCreate', deliveryCreate)
  .component('deliveryCreateNextDay', deliveryCreateNextDay)
  .component('deliveryType', deliveryType)
  .component('fleetPicker', fleetPicker)
  .component('packageDetails', packageDetails)
  .component('pickupTime', pickupTime)
  .component('pickupSlot', pickupSlot)
  .component('proofOfDelivery', proofOfDelivery)
  .component('orderDetails', orderDetails)
  .component('otherInformation', otherInformation)
  .component('selectConsumerAddress', selectConsumerAddress)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.deliveries',
      stateName: 'create',
      componentName: 'deliveryCreate',
      stateKey: 'DELIVERY_CREATE',
      requiresPermission: 'DELIVERIES_DELIVERY_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.deliveries',
      stateName: 'create-next-day',
      componentName: 'deliveryCreateNextDay',
      stateKey: 'DELIVERY_CREATE_NEXT_DAY',
      requiresPermission: 'DELIVERIES_DELIVERY_CREATE_NEXT_DAY'
    })
  })
)
