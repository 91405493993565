import templateUrl from './verify-credentials-panel.pug'
import BaseController from 'presentation/common/BaseController'
import authService from 'data/services/authService'

class VerifyCredentialsPanelController extends BaseController {
  constructor ($scope) {
    super($scope)
  }

  $onInit () {
    this.phoneCodeResent = false
    this.emailCodeResent = false
  }

  $onChanges () {
    this.showPanel = this.user && (!this.user.phoneConfirmed || (this.user.email && !this.user.emailConfirmed))
  }

  resendPhoneCode () {
    return authService.resendPhoneVerificationCode()
      .then(() => this.handleResendPhoneSuccess())
      .catch(err => this.handleResendError(err))
      .then(() => this.$scope.$digest())
  }

  resendEmailCode () {
    return authService.resendEmailVerificationCode()
      .then(() => this.handleResendEmailSuccess())
      .catch(err => this.handleResendError(err))
      .then(() => this.$scope.$digest())
  }

  handleResendPhoneSuccess () {
    this.logger.info('Phone verification code resent')
    this.phoneCodeResent = true
  }

  handleResendEmailSuccess () {
    this.logger.info('Email verification code resent')
    this.emailCodeResent = true
  }

  handleResendError (error) {
    this.logger.error('Error resending verification code', error)
  }
}

export default {
  templateUrl,
  controller: VerifyCredentialsPanelController,
  bindings: {
    user: '<'
  }
}
