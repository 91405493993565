import deliveriesCollection from 'data/collections/deliveriesCollection'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import DeliveryDataService from 'data/common/deliveryDataService'
import Logger from 'common/Logger'

const logger = new Logger('DeliveryReadyDialog')

/**
 * This is not a dialog (yet). But every other delivery action triggers dialogs,
 * so I made this dialog stub just to make things consistent.
 */
class DeliveryReadyDialog {
  constructor ($rootScope) {
    this.$rootScope = $rootScope
  }

  $onInit () {
    DeliveryDataService.publishDeliveryLoadingEvent()
    this.markAsReadyUnregisterListener = this.$rootScope.$on(DELIVERY_ACTIONS.MARK_READY, (evt, delivery) => {
      if (delivery.id) {
        deliveriesCollection.markAsReady(delivery.id).then(delivery => {
          DeliveryDataService.publishDeliveryStatusChangeEvent(delivery.status)
          DeliveryDataService.publishDeliverySyncEvent()
        })
          .catch(error => {
            logger.error('Cannot transition delivery to status \'pending\': ', error)
          })
      }
    })
  }

  $onDestroy () {
    this.markAsReadyUnregisterListener()
  }
}

export default {
  template: '<div></div>',
  controller: DeliveryReadyDialog
}
