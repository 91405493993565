import templateUrl from 'presentation/delivery-create/create/delivery-create.pug'
import {DeliveryCreateController} from 'presentation/delivery-create/create/deliveryCreate'
import CreateDeliveryStateManager from 'presentation/delivery-create/CreateDeliveryStateManager'
import NewDeliveryModel from 'data/domain-objects/NewDeliveryModel'
import deliveriesCollection from 'data/collections/deliveriesCollection'

class DeliveryCreateNextDayController extends DeliveryCreateController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.isNextDay = true
  }

  createDeliveryStateManager () {
    this.createDeliveryStateManager = new CreateDeliveryStateManager(this.isNextDay)
  }

  createDeliveryModel () {
    this.model = new NewDeliveryModel(this.isNextDay)
  }

  beforeSubmit () {
    this.deliverySubmissionInProgress = true
    const deliveryDescriptor = this.model.getDeliveryDescriptorForApi()
    return deliveriesCollection.createNextDay(deliveryDescriptor)
  }

  applyDeliveryTypeSettingToModel () { // set it to b2c delivery directly ignoring all related settings
    this.model.setToPickUpFromBranch()
  }
}

export default {
  templateUrl,
  controller: DeliveryCreateNextDayController
}
