import FormModel from 'data/models/FormModel'
import templateUrl from './reset-password.pug'
import authService from 'data/services/authService'
import ValidationUtils from 'common/utils/validationUtils'
import BaseController from 'presentation/common/BaseController'

const NUMERIC_STRING_REGEX = /^\d+$/
const CODE_INVALID = 'ERR_USER_PASSWORD_RESET_CODE_INVALID'

class ResetPasswordController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)

    this.model = {
      code: ResetPasswordController.getValidCode(this.$state.params.code) || '',
      newPassword: ''
    }

    this.formModel = new FormModel()
    this.requestSuccess = false
    this.requestFailedCode = false
    this.requestFailedPassword = false
    this.requestFailedOther = false
    this.requestFailedCodeInvalid = false
  }

  $onInit () {
    this.$scope.$watch('$ctrl.model', (newValue, oldValue) => this.resetBackendValidationForChanged(newValue, oldValue), true)
  }

  resetBackendValidationForChanged (newValue, oldValue) {
    ValidationUtils.resetBackendValidationForChanged(this.formModel.backendValidationErrors, newValue, oldValue)
  }

  static getValidCode (codeString) {
    const isNumericString = NUMERIC_STRING_REGEX.test(codeString)
    return isNumericString ? codeString : null
  }

  submit () {
    authService.setNewPassword(this.model.code, this.model.newPassword)
      .then(() => this.handleResetSuccess())
      .catch(err => this.handleResetFailure(err))
      .then(() => this.$scope.$digest())
  }

  handleResetSuccess () {
    this.logger.info('Reset Password Success')
    this.requestSuccess = true

    this.requestFailedCode = false
    this.requestFailedPassword = false
    this.requestFailedOther = false

    this.requestFailedCodeInvalid = false
  }

  handleResetFailure (error) {
    this.logger.info('Reset Password Failed')
    this.requestSuccess = false

    if (this.error && this.error.validationErrors) {
      this.formModel.notifySubmitError(error)

      this.requestFailedCode = !!this.formModel.backendValidationErrors.code
      this.requestFailedPassword = !!this.formModel.backendValidationErrors.newPassword
      this.requestFailedOther = !(this.requestFailedCode || this.requestFailedPassword)
    }

    if (error && error.code) {
      this.requestFailedCodeInvalid = error.code === CODE_INVALID
    }
  }
}

export default {
  templateUrl,
  controller: ResetPasswordController
}
