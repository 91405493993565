import templateUrl from './form-panel.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    title: '@',
    noBackground: '<?',
    hideTitle: '<?'
  }
}
