import templateUrl from './checkbox.pug'

class CheckboxController {
  onCheckboxChanged () {
    this.onChange({value: this.model})
  }
}

export default {
  templateUrl,
  controller: CheckboxController,
  transclude: true,
  bindings: {
    form: '=',
    name: '@',
    model: '=',
    value: '@',
    isDisabled: '<',
    isRequired: '<',
    hostClass: '@?',
    onChange: '&'
  }
}
