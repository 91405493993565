import templateUrl from './confirmed-requests.pug'
import requestsCollection from 'data/collections/requestsCollection'
import PaginationParameters from 'data/domain-objects/PaginationParameters'
import BaseController from 'presentation/common/BaseController'

class ConfirmedRequestsController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.paginationParams = new PaginationParameters()
    this.confirmedRequests = []
    this.getConfirmedRequests()
  }

  getConfirmedRequests () {
    this.confirmedRequestsLoading = true
    return requestsCollection.getAllConfirmedRequests(this.paginationParams.limit, this.paginationParams.skip)
      .then(response => {
        this.paginationParams = PaginationParameters.build(response.metadata)
        this.confirmedRequests = response.records // TODO: Refactor to use some cool DO
      })
      .catch(error => this.logger.error('Error getting confirmed requests.', error))
      .then(() => {
        this.confirmedRequestsLoading = false
        this.$scope.$digest()
      })
  }

  onOffsetChange (offset, limit) {
    this.paginationParams.limit = limit
    this.paginationParams.skip = offset
    this.getConfirmedRequests()
  }
}

export default {
  controller: ConfirmedRequestsController,
  templateUrl
}
