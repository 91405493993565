import templateUrl from './proof-of-delivery-settings.pug'
import envConfig from 'common/envConfig'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SETTINGS_KEYS = [
  'showProofOfDeliverySettings',
  'proofOfDeliverySignatureEnabled',
  'proofOfDeliverySignatureSetting',
  'proofOfDeliveryPhotoIdentificationEnabled',
  'proofOfDeliveryPhotoIdentificationSetting'
]

class ProofOfDeliverySettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
    this.proofOfDeliverySettingOptions = envConfig.businessAndBranchSettings.proofOfDeliverySettings.availableOptions
  }
}

export default {
  controller: ProofOfDeliverySettingsController,
  templateUrl,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<',
    enableGlobalOption: '<?'
  }
}
