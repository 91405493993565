const STATUS = {
  TO_DO: 'TO_DO',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE'
}

export default class Task {
  constructor (
    public id: string = '',
    public action: string = '',
    public status: string = '',
    public address: any = null,
    public eta: any = null,
    public partyName: string = null,
    public deliveryRefs: string[] = [],
    public costs: any = {},
    public metadata: any = {},
    public cashOffSystem: boolean = false
  ) {}

  static build (
    dto: any, status: string, address: any, eta: any, partyName: string,
    deliveryDescriptors: any[], costs: any, cashOffSystem: boolean): Task {
    return new Task(
      dto._id,
      dto.action,
      status,
      address || null,
      (eta && eta.eta) ? eta.eta : null,
      partyName || null,
      deliveryDescriptors ? deliveryDescriptors.map((d: any) => d.ref) : [],
      costs || null,
      dto.metadata,
      cashOffSystem
    )
  }

  static get STATUS (): any {
    return STATUS
  }
}
