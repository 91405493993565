import cloneDeep from 'lodash-es/cloneDeep'
import get from 'lodash-es/get'
import BaseController from 'presentation/common/BaseController'

const DEFAULT_VALUE_FIELD_NAME = 'defaultValue'

export default class SettingsGroupController extends BaseController {
  constructor ($scope, $state, settingsKeys) {
    super($scope, $state)
    this.useDefault = true
    this.settingKeys = settingsKeys
  }

  $onInit () { // Adding watcher to get the moment when settings model loads to validate it, add default values and get summary value for useDefault toggle
    this.$scope.$watch('$ctrl.model', () => this.model && Object.keys(this.model).length && this.handleModelUpdate(), true)
  }

  getDefaultValueForSetting (settingKey) { // Receives default value from default value field of certain setting
    return cloneDeep(get(this.model, `${settingKey}.${DEFAULT_VALUE_FIELD_NAME}`, null))
  }

  handleModelUpdate () {
    this.applyDefaultValuesForFields()
    this.getOverrideValueFromMultipleFields()
  }

  getOverrideValueFromMultipleFields () { // If at least one of the settings fields has isOverridden === true, then we set useDefault to false
    this.useDefault = !this.settingKeys.find(settingsKey => this.model[settingsKey].isOverridden === true)
    this.applyOverrideModeToSettingsFields(!this.useDefault)
  }

  applyDefaultValuesForFields (forceToDefault = false) { // This method applies valid structure to missing settings and default values if setting value is empty or if force flag given
    this.settingKeys.forEach(settingKey => {
      if (!this.model[settingKey]) {
        this.model[settingKey] = {}
      }

      if (!this.model[settingKey].hasOwnProperty('value') || forceToDefault) {
        this.model[settingKey].value = this.getDefaultValueForSetting(settingKey)
      }

      if (!this.model[settingKey].hasOwnProperty('isOverridden')) {
        this.model[settingKey].isOverridden = forceToDefault ? false : !this.useDefault
      }
    })
  }

  onOverrideModeChange (useDefault) { // Fallback all fields to their default values and set isOverriden flag for each one
    this.applyDefaultValuesForFields(true)
    this.applyOverrideModeToSettingsFields(!useDefault)
  }

  applyOverrideModeToSettingsFields (isOverridden) {
    this.settingKeys.forEach(settingKey => this.model[settingKey].isOverridden = isOverridden)
  }

  onDisplayModeChange () {} // Must be redefined in your class if you want some custom behaviour from display mode dropdown
}
