import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import toUpperSnakeCase from 'common/toUpperSnakeCase'

function createEntityConstants () {
  const ENTITIES = {}
  const entitiesInConfig = get(envConfig, 'roleEntities', [])

  if (entitiesInConfig.length) {
    entitiesInConfig.forEach(entity => {
      ENTITIES[toUpperSnakeCase(entity)] = entity
    })
  }

  return ENTITIES
}

export default {
  ENTITIES: createEntityConstants()
}
