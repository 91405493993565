import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

const deliverySlaTypesService = new CrudService(endpoints.DELIVERY_SLA_TYPES)

export function slaCollectionFactory (
  deliverySlaTypesService
) {
  return {
    getAvailableDeliverySLATypes: function () {
      return deliverySlaTypesService.get([], {useCache: true})
        .then(slaTypes => slaTypes.map(DBMappedNamedEntity.build))
    }
  }
}

export default slaCollectionFactory(
  deliverySlaTypesService
)
