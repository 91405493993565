import DurationSurcharge from './DurationSurcharge'
import Duration from './Duration'
import Cost from './Cost'
import * as MeasureUnits from 'common/constants/MeasureUnits'

export default class WaitingTimeSurcharge extends DurationSurcharge {

  static build (dto: any = {}): WaitingTimeSurcharge {
    const step = dto.step || {unitCode: MeasureUnits.MINU}
    const offset = dto.offset || {unitCode: MeasureUnits.MINU}
    return new WaitingTimeSurcharge(
      Cost.build(dto.price),
      Duration.build(step),
      Duration.build(offset)
    )
  }

}
