import featuresModel from 'data/models/featuresModel'
import Logger from 'common/Logger'

const MATCH_ANGULAR_EXPRESSION_REGEX = /^{{(.*)}}$/
const logger = new Logger('If Feature Enabled Directive')

/* if-feature-enabled Directive
 *
 * This directive's approach and code is taken directly from the if-has-permission directive.
 */
export default function ifFeatureEnabled (ngIfDirective) {
  const ngIf = ngIfDirective[0] // Angular passes it wrapped in an array
  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority, // This means we will be linked before any attribute value has been evaluated. See below.
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    link: function ($scope, $element, $attrs) {
      const featureName = getValidFeatureName($scope, $attrs.ifFeatureEnabled)
      $attrs.ngIf = () => getVisibilityGivenFeatureName(featureName)
      ngIf.link.apply(ngIf, arguments)
    }
  }
}

function getValidFeatureName ($scope, featureNameString) {
  const angularExpressionMatch = MATCH_ANGULAR_EXPRESSION_REGEX.exec(featureNameString)
  if (!featureNameString) {
    logger.warn('Empty feature name passed to directive')
    return ''
  } else if (angularExpressionMatch) { // If we were passed an angular expression, evaluate its value
    return $scope.$eval(angularExpressionMatch[1]) // First matched group is at index 1.
  } else {
    return featureNameString
  }
}

function getVisibilityGivenFeatureName (featureName) {
  return featuresModel.isFeatureEnabled(featureName)
}
