import templateUrl from './information-list-item.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    key: '@',
    highlighted: '@',
    highlightedKey: '@',
    highlightedValue: '@'
  }
}
