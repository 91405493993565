import debounce from 'lodash-es/debounce'
import cloneDeep from 'lodash-es/cloneDeep'
import toUpperSnakeCase from 'common/toUpperSnakeCase'

import Logger from 'common/Logger'
import locationsService from 'data/services/locationsService'
import ValidationUtils from 'common/utils/validationUtils'
import addressFormatPresentationService from 'data/services/addressFormatPresentationService'
import {ENTRY_TYPES} from './address-entry-type-picker/addressEntryTypePicker'
import addressCollection from 'data/collections/addressCollection'

import templateUrl from './dynamic-address.html'

const GEOLOCATION_DEBOUNCE_PERIOD_MS = 500
const logger = new Logger('Dynamic Address Directive')

/*
 * DYNAMIC ADDRESS
 *
 * The requirements for this feature are documented fully on the "Dynamic Address" page on Confluence.
 *
 * This component has very complex requirements. However, the implementation was done very quickly,
 * and so the code has many points for improvement. All requirements are met, but the implementation could
 * be separated out into cleaner concerns. The implementation is not covered by tests, and would benefit from this.
 *
 * This code can be separated out to have separate pieces. These could include:
 * - The data model actions should be separated from the controller as much as possible.
 *   The need for geocoding, or to sync the address with a lookup, or to change format, should all be done
 *   in a data model (or several), and the controller just told when the data has changed. This model should
 *   handle async data or events gracefully.
 * - The form validity should be only a controller concern, and should be calculated from the data models.
 * - The mapping of address and address format to the data the UI can be separate from the controller.
 *   This can include the field grouping mapping, the address to 'addressFields' mapping etc.
 * - Internal components that depend on the API to be valid (e.g. dependent fields, map) and their state could
 *   be part of another separate data model. If the child components are not valid, this can be notified to the controller.
 */
class DynamicAddressController {
  constructor ($scope, $element) {
    this.$scope = $scope
    this.$element = $element

    this.debouncedSyncLatestGeocodedLocation = debounce(() => {
      this.syncLatestGeocodedLocation()
    }, GEOLOCATION_DEBOUNCE_PERIOD_MS)

    // The local address itself
    this.address = null
    // And the backend address object, if there is one
    this.backendAddress = null
    this.backendAddressDescriptor = null
    // Address formats and data for model
    this.entryType = ENTRY_TYPES.DETAILS
    this.addressFormats = null
    this.addressFormatPresentationMap = null
    this.formatIds = []
    this.formatTypes = []
    this.formatTypeKeys = []
    this.defaultAddressFormatId = null
    this.formatIsMapDriven = false
    this.mapLocationCanBeReverted = false
    this.mapLocationBeforeEditing = null
    // The address fields we'll render
    this.addressFields = []
    // Error handling
    this.addressError = false
    this.backendValidationErrors = {}
  }

  $onInit () {
    // Event callbacks
    this.onParentSubmit(function () {})

    this.localShowAddressLocationMap = (typeof this.showAddressLocationMap === 'boolean') ? this.showAddressLocationMap : true
    this.localExpandAddressLocationMap = (typeof this.expandAddressLocationMap === 'boolean') ? this.expandAddressLocationMap : true

    // Set parent form invalid until we've fully succeeded
    this.form.$setValidity('requiredAddressData', false)
    this.onAddressLookup = address => this.handleAddressLookup(address)
    this.changeEntryType = value => this.handleChangeEntryType(value)
    this.onLocationEdited = editedLocation => this.handleLocationEdited(editedLocation)
  }

  $onChanges (simpleChanges) {
    if (simpleChanges.inputAddress && !simpleChanges.inputAddress.isFirstChange()) {
      this.resetAddressStateGivenDescriptor(this.inputAddress)
    }
  }

  $postLink () {
    // Fetch address formats and any required address before we enable the remaining functionality
    addressCollection.getAddressFormats()
      .then(addressFormats => this.handleAddressFormats(addressFormats))
      .then(() => this.resetWidgetStateGivenAddressId())
      .then(() => this.attachSubmitHandler())
      .then(() => this.initWatchers())
      .catch(error => this.handleAddressDataError(error))
      .then(() => this.$scope.$digest())
  }

  handleAddressFormats (addressFormats) {
    if (Array.isArray(addressFormats) && addressFormats.length > 0) {
      this.addressFormatPresentationMap = addressFormatPresentationService.getAddressFormatPresentationMap(addressFormats)
      this.addressFormats = addressFormats
      this.formatIds = addressFormats.map(format => format.id)
      this.formatTypes = addressFormats.map(format => format.type)
      this.formatTypeKeys = addressFormats.map(format => toUpperSnakeCase(format.type))
      this.defaultAddressFormatId = addressFormats[0].id

      const firstInputDOMElement = this.$element.find('input')[0]

      if (this.shouldBeFocusedOnInit && firstInputDOMElement && firstInputDOMElement.focus) {
        firstInputDOMElement.focus()
      }
    } else {
      throw new Error('Could not retrieve any address formats from response')
    }
  }

  resetWidgetStateGivenAddressId () {
    // Reset the parent form validity first until we've succeeded
    this.form.$setValidity('requiredAddressData', false)
    return this.getAddressDescriptor()
      .then(backendAddressDescriptor => this.resetAddressStateGivenDescriptor(backendAddressDescriptor))
      .then(() => this.form.$setValidity('requiredAddressData', true))
  }

  getAddressDescriptor () {
    if (this.addressId) { // Address ID may be set after we do this fetch, so there's a race to handle watch out for here
      logger.info('Address ID provided, retrieving address from backend with id', this.addressId)
      return (this.consumerId) ? addressCollection.getConsumerAddress(this.consumerId, this.addressId) : addressCollection.getAddressDescriptor(this.addressId)
    } else if (this.inputAddress) {
      return Promise.resolve(this.inputAddress)
    } else {
      return Promise.resolve(this.getDefaultAddressDescriptor())
    }
  }

  resetAddressStateGivenDescriptor (backendAddressDescriptor) {
    logger.info('Resetting address state given backend address descriptor', backendAddressDescriptor)
    // Save our backend descriptor
    this.backendAddressDescriptor = backendAddressDescriptor
    // Set up the address fields to match the provided format
    const backendAddressFormat = this.getAddressFormatWithId(backendAddressDescriptor.addressFormatId)
    this.addressFields = this.getAddressFieldsGivenFormat(backendAddressFormat)
    // Then cast the descriptor to our local 'address' object and save an unedited backup
    const address = this.getAddressFromAddressDescriptorAndFormat(backendAddressDescriptor, backendAddressFormat)
    this.address = cloneDeep(address)
    this.backendAddress = cloneDeep(address)

    if (this.consumerId) {
      this.consumerFirstName = backendAddressDescriptor.consumerFirstName || this.consumerFirstName
      this.consumerLastName = backendAddressDescriptor.consumerLastName || this.consumerLastName
    }

    // This hack is required because model isn't fully applied yet when the BaseFormController call $digest() after successful form submission
    setTimeout(() => this.$scope.$digest())
  }

  attachSubmitHandler () {
    this.onParentSubmit(() => this.doSubmit())
  }

  initWatchers () {
    // If we've just been given a new, different address id (or one we never had before) then reset the form given this
    this.$scope.$watch('$ctrl.addressId', addressId => {
      if (this.backendAddressDescriptor.id !== addressId) {
        this.resetWidgetStateGivenAddressId()
          .catch(() => this.handleAddressDataError())
          .then(() => this.$scope.$digest())
      }
    })

    // Watch Address Fields and Location for whether it's changed and notify parent and sync backend validation appropriately
    this.$scope.$watch('$ctrl.address.fields', (updatedFields, previousFields) => this.handleAddressFieldsChange(updatedFields, previousFields), true)
    this.$scope.$watch('$ctrl.address.location', () => this.handleLocationChange(), true)

    // Switch address fields when user changes address format ID
    this.$scope.$watch('$ctrl.address.addressFormatId', addressFormatId => {
      if (addressFormatId && this.addressFormats) {
        const currentAddressFormat = this.getAddressFormatWithId(addressFormatId)
        this.addressFields = this.getAddressFieldsGivenFormat(currentAddressFormat)
        this.formatIsMapDriven = currentAddressFormat.presentation.mapOnTop

        this.persistAddressValuesAcrossAddressFormats()
      }
    })

    // Watch isEditing and reset data if the edit is abandoned by user
    this.$scope.$watch('$ctrl.isEditing', (isEditing, wasEditing) => {
      if (wasEditing && !isEditing) {
        logger.info('isEditing reset to false, setting address to match backend address data')
        this.resetAddressStateGivenDescriptor(this.backendAddressDescriptor)
      }
    })

    this.$scope.$watchGroup(['consumerFirstName', 'consumerLastName'], () => {
      if (this.consumerId) {
        this.notifyConsumersOfAddressChange()
      }
    })
  }

  // This function required to merge the values from "house","building" and etc. fields across different address formats
  persistAddressValuesAcrossAddressFormats () {
    if (this.address.fields.hasOwnProperty('house')) {
      this.address.fields.building = this.address.fields.house
    } else if (this.address.fields.hasOwnProperty('building')) {
      this.address.fields.house = this.address.fields.building
    }
  }

  handleAddressFieldsChange (updatedFields, previousFields) {
    const isAddressFieldsChangedFromPrevious = ValidationUtils.getIsEntityChanged(updatedFields, previousFields)
    const areAddressFieldsDiffFromBackend = ValidationUtils.getIsEntityChanged(this.address.fields, this.backendAddress.fields)

    this.notifyConsumersOfAddressChange()
    ValidationUtils.resetBackendValidationForChanged(this.backendValidationErrors, updatedFields, previousFields)
    this.syncAddressLocationGivenState(isAddressFieldsChangedFromPrevious, areAddressFieldsDiffFromBackend)
  }

  syncAddressLocationGivenState (isAddressFieldsChangedFromPrevious, areAddressFieldsDiffFromBackend) {
    // We don't touch the location if the format is map driven. The user alone edits the location.
    // We then need to re-geocode the location on each field change, even if it's changing *back* to the backend values
    // NB. This currently resets the address location even if non-geocoded fields were changed. This is a minor bug.
    if (!this.formatIsMapDriven && this.addressForm && this.addressForm.$valid && isAddressFieldsChangedFromPrevious && areAddressFieldsDiffFromBackend) {
      this.attachLatestGeocodedLocation()
    } else if (!this.formatIsMapDriven && this.addressForm && this.addressForm.$valid && isAddressFieldsChangedFromPrevious && !areAddressFieldsDiffFromBackend) {
      this.resetAddressLocation() // Reset to backend value when fields go back to backend value
    }
  }

  handleLocationChange () {
    this.notifyConsumersOfAddressChange()
  }

  notifyConsumersOfAddressChange () {
    const latestAddressClone = cloneDeep(this.getCurrentAddressDescriptor())
    this.onAddressChanged({address: latestAddressClone})
    this.syncParentIsAddressChanged()
  }

  syncParentIsAddressChanged () {
    const areAddressFieldsDiffFromBackend = ValidationUtils.getIsEntityChanged(this.address.fields, this.backendAddress.fields)
    const isAddressLocationDiffFromBackend = ValidationUtils.getIsEntityChanged(this.address.location, this.backendAddress.location)
    logger.info('Syncing parent isAddressChanged. isAddressFieldsChanged and isAddressLocationChanged are:',
      areAddressFieldsDiffFromBackend, isAddressLocationDiffFromBackend)
    if (this.onIsAddressChanged) {
      this.onIsAddressChanged(areAddressFieldsDiffFromBackend || isAddressLocationDiffFromBackend)
    }
  }

  handleAddressDataError (error) {
    logger.error('Could not retrieve all address data', error)
    this.addressError = true
  }

  /*
   * GEOCODED LOCATION HANDLING
   */
  attachLatestGeocodedLocation () {
    // We set the form invalid until we've geocoded the address. But we only geocode once the changes have stopped.
    this.form.$setValidity('attachedGeocodedLocation', false)
    this.debouncedSyncLatestGeocodedLocation()
  }

  syncLatestGeocodedLocation () {
    logger.info('Geocoding address location')
    const addressDescriptor = this.getCurrentAddressDescriptor()
    locationsService.getGeocodedLocationForAddress(addressDescriptor)
      .catch(error => {
        logger.error('Could not get geocoded location for address. Using default location.', error)
        return locationsService.getDefaultLocation()
      })
      .then(location => {
        const addressWithLocation = Object.assign(cloneDeep(addressDescriptor), {location})
        const addressFormat = this.getAddressFormatWithId(addressDescriptor.addressFormatId)
        const address = this.getAddressFromAddressDescriptorAndFormat(addressWithLocation, addressFormat)
        this.address = cloneDeep(address) // Note we only update the address, not backend address
      })
      .then(() => {
        this.form.$setValidity('attachedGeocodedLocation', true)
        this.$scope.$apply()
      })
  }

  resetAddressLocation () {
    logger.info('Resetting address location to value from backend')
    this.address.location = cloneDeep(this.backendAddressDescriptor.location)
  }

  handleLocationEdited (editedLocation) {
    logger.info('Address location edited, latest location is', editedLocation)
    if (!this.mapLocationCanBeReverted && !this.mapLocationBeforeEditing) {
      this.mapLocationCanBeReverted = true
      this.mapLocationBeforeEditing = this.address.location
    }
    this.address.location = editedLocation
    this.$scope.$digest() // May be kicked off from an external scope, so must apply
  }

  handleRevertEditedLocation () {
    this.mapLocationCanBeReverted = false
    this.address.location = this.mapLocationBeforeEditing
    this.mapLocationBeforeEditing = null
  }

  /*
   * FORM SUBMISSION
   */
  doSubmit () {
    const submitHandler = this.backendAddressDescriptor.id ? this.doUpdate : this.doCreate
    return submitHandler.bind(this)()
  }

  doCreate () {
    const addressDescriptor = this.getCurrentAddressDescriptor()

    const promisedResult = this.consumerId ?
      addressCollection.createConsumerAddress(this.consumerId, addressDescriptor) :
      addressCollection.createAddress(addressDescriptor)

    return promisedResult
      .then(backendAddressDescriptor => this.handleSubmitSucceeded(backendAddressDescriptor))
      .catch(err => this.handleSubmitFailed(err))
  }

  doUpdate () {
    const addressDescriptor = this.getCurrentAddressDescriptor()

    const promisedResult = this.consumerId ?
      addressCollection.updateConsumerAddress(this.consumerId, addressDescriptor.id, addressDescriptor) :
      addressCollection.updateAddress(addressDescriptor.id, addressDescriptor)

    return promisedResult
      .then(backendAddressDescriptor => this.handleSubmitSucceeded(backendAddressDescriptor))
      .catch(err => this.handleSubmitFailed(err))
  }

  handleSubmitSucceeded (backendAddressDescriptor) {
    logger.info('Successfully submitted address', backendAddressDescriptor)
    this.resetAddressStateGivenDescriptor(backendAddressDescriptor)

    return !backendAddressDescriptor.addressFormatId ? backendAddressDescriptor :
      addressCollection.getAddressFormat(backendAddressDescriptor.addressFormatId)
        .then(addressFormat => this.handleGetAddressFormatSucceeded(addressFormat, backendAddressDescriptor))
        .catch(() => this.handeGetAddressFormatFailed())
  }

  handleGetAddressFormatSucceeded (addressFormat, addressDescriptor) {
    addressDescriptor.type = addressFormat.type
    return addressDescriptor
  }

  handeGetAddressFormatFailed (error) {
    logger.error('Cannot get address format for given backend address descriptor', error)
  }

  handleSubmitFailed (error) {
    logger.error('Submit failed', error)
    const errorDescriptorArraysByFieldName = error && error.validationErrors
    if (errorDescriptorArraysByFieldName) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(errorDescriptorArraysByFieldName)
    } else {
      this.backendValidationErrors = {}
    }
    throw error // Note this rethrows to ensure consumer gets it too
  }

  /*
   * ADDRESS ENTRY AND LOOKUP HANDLING
   */
  handleChangeEntryType (entryType) {
    // The form should only be valid if the details can be seen and confirmed by the user
    this.entryType = entryType
    this.form.$setValidity('entryTypeDetails', entryType === ENTRY_TYPES.DETAILS)
  }

  handleAddressLookup (address) {
    // Set entry type picker to details and use new address
    this.handleChangeEntryType(ENTRY_TYPES.DETAILS)
    const lookupAddress = cloneDeep(address)
    lookupAddress.id = this.backendAddressDescriptor ? this.backendAddressDescriptor.id : null
    this.resetAddressStateGivenDescriptor(lookupAddress)
  }

  onFieldChanged (fieldName, value) {
    if (fieldName) {
      this.address.fields[fieldName] = value
    }
  }

  /*
   * DATA MANIPULATION AND CASTING
   */
  getDefaultAddressDescriptor () {
    const defaultAddressFormatId = this.addressFormats[0].id
    return {
      id: null,
      addressFormatId: defaultAddressFormatId,
      fields: [],
      location: locationsService.getDefaultLocation()
    }
  }

  getCurrentAddressDescriptor () {
    return {
      id: (this.backendAddressDescriptor ? this.backendAddressDescriptor.id : null),
      addressFormatId: this.address.addressFormatId,
      fields: this.getFieldDescriptorsForAddress(this.getAddressFormatWithId(this.address.addressFormatId).fields, this.address),
      location: this.address.location,
      name: this.addressName || null,
      consumerFirstName: this.consumerFirstName || null,
      consumerLastName: this.consumerLastName || null
    }
  }

  getAddressFormatWithId (id) {
    return this.addressFormats.find(addressFormat => addressFormat.id === id) || null
  }

  getAddressFieldsGivenFormat (addressFormat) {
    return addressFormat ? this.addressFormatPresentationMap[addressFormat.id] : []
  }

  getDependentValues (dependsOn) {
    const values = {}
    let fields = dependsOn
    if (typeof dependsOn === 'string') {
      fields = dependsOn.split(',')
    }

    fields.forEach(field => {
      values[field] = this.address.fields[field]
    })

    return JSON.stringify(values)
  }

  getFieldDescriptorsForAddress (addressFieldDescriptors, address) {
    return addressFieldDescriptors.map(field => {
      const fieldName = field.name

      return {
        formatFieldId: field.id,
        value: address.fields[fieldName] || null,
        hint: this.requestAddressHints && this.requestAddressHints[fieldName] || null
      }
    })
  }

  getAddressFromAddressDescriptorAndFormat (addressDescriptor, addressFormat) {
    const address = {
      addressFormatId: addressDescriptor.addressFormatId,
      location: cloneDeep(addressDescriptor.location),
      fields: {}
    }
    addressDescriptor.fields.forEach(addressField => {
      // Name isn't provided on the address descriptor, so have to take from addressFormat
      const addressFieldId = addressField.formatFieldId
      const field = this.getFieldWithIdFromFormat(addressFieldId, addressFormat)
      if (field) {
        address.fields[field.name] = addressField.value
      }
    })
    return address
  }

  getFieldWithIdFromFormat (fieldId, addressFormat) {
    const fields = addressFormat ? addressFormat.fields : []
    const fieldsWithId = fields.filter(field => field.id === fieldId)
    return fieldsWithId.length ? fieldsWithId[0] : null
  }
}

export default {
  templateUrl,
  controller: DynamicAddressController,
  bindings: {
    addressId: '@',
    isEditing: '<',
    shouldBeFocusedOnInit: '<',
    form: '=',
    inputAddress: '<',
    availableAreas: '<',
    onParentSubmit: '=',
    onIsAddressChanged: '=',
    onAddressChanged: '&',
    showAddressLocationMap: '<?',
    expandAddressLocationMap: '<?',
    showAddressFieldHints: '<?',
    requestAddressHints: '<?',
    settings: '<?',
    consumerId: '<',
    consumerFirstName: '=',
    consumerLastName: '=',
    addressName: '<'
  }
}
