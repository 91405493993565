import templateUrl from './form-panel-item.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    heading: '@?',
    isItemRequired: '<?',
    explanation: '@?',
    inlineContent: '<?',
    borderContent: '<?',
    noBottomSpace: '<?',
    extraContentPadding: '<?'
  }
}
