import Cost from './Cost'

interface ISubscriptionPlanDuration {
  amount: number,
  unitCode: string
}

export default class SubscriptionPlan {
  constructor (
    public id: string = null,
    public name: string = null,
    public subtitle: string = null,
    public price: Cost = null,
    public duration: ISubscriptionPlanDuration = null
  ) {}

  static build(dto: any = {}): SubscriptionPlan {
    const duration: ISubscriptionPlanDuration = {
      amount: dto.duration && dto.duration.amount ? dto.duration.amount : 0,
      unitCode: dto.duration && dto.duration.unitCode ? dto.duration.unitCode : ''
    }

    return new SubscriptionPlan(
      dto.id,
      dto.name,
      dto.subtitle,
      Cost.build(dto.price),
      duration
    )
  }
}
