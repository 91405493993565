import templateUrl from './dropdown-navigation.pug'

class DropdownMenuController {
  $onInit () {
    this.optionsVisible = false
  }

  toggleOptionsVisibility () {
    this.optionsVisible = !this.optionsVisible
  }
}

export default {
  controller: DropdownMenuController,
  templateUrl,
  transclude: true,
  bindings: {
    primaryNavigationRoute: '@',
    primaryNavigationLabel: '@',
    primaryNavigationAction: '&'
  }
}
