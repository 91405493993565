import templateUrl from './inline-field-group.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    label: '@',
    valueMessage: '@',
    narrow: '<'
  }
}
