import LatLongPoint from './LatLongPoint'
import GeoJSONPolygon from './GeoJSONPolygon'

export default class Area implements IPostPutJsonAdapter {
  constructor (
    public id: string = '',
    public name: string = '',
    public description: string = '',
    public polygon: LatLongPoint[] = [], // will be deprecated soon
    public bounds: GeoJSONPolygon = new GeoJSONPolygon()
  ) {}

  static build (dto: any = {}): Area {

    return new Area(
      dto.id || '',
      dto.name || '',
      dto.description || '',
      Array.isArray(dto.polygon) ? dto.polygon.map(LatLongPoint.build) : [],
      GeoJSONPolygon.build(dto.bounds)
    )
  }

  toPostJSON(): any {
    return {
      ...this,

      // strip
      id: undefined,
      polygon: undefined
    }
  }

  toPutJSON(): any {
    return {
      ...this,

      // strip
      id: undefined,
      polygon: undefined
    }
  }

}
