import CrudService from 'data/services/CrudService'
import endpoints from 'common/endpoints'

const fileService = new CrudService(endpoints.FILE)

export default {
  postFile: (file: File): Promise<{id: string, url: string}> => {
    const formData: FormData = new FormData()
    formData.append('file', file, file.name)
    return fileService.create(formData)
  }
}
