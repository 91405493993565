import DateUtils from 'common/utils/dateUtils'

export default class DeliveriesHistorySettings {
  constructor (
    public pickupId: string = null,
    public search: string = null,
    public status: string = null,
    public limit: string = '20',
    public orderBy: string = 'date',
    public orderDirection: number = -1,
    public skip: number = 0,
    public startDate: Date = null,
    public finishDate: Date = null
  ) {
    this.startDate = startDate === null ? null : new Date(startDate)
    this.finishDate = finishDate === null ? null : new Date(finishDate)
    if (startDate !== null && !DateUtils.isValidDateObject(this.startDate)) {
      this.startDate = null
    }
    if (finishDate !== null && !DateUtils.isValidDateObject(this.finishDate)) {
      this.finishDate = null
    }
  }

  static build (dto: any = {}): DeliveriesHistorySettings {
    return new DeliveriesHistorySettings(
      dto.pickupId,
      dto.search,
      dto.status,
      dto.limit,
      dto.orderBy,
      dto.orderDirection,
      dto.skip,
      dto.startDate,
      dto.finishDate
    )
  }
}
