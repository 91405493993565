import fleetsCollection from 'data/collections/fleetsCollection'
import envConfig from 'common/envConfig'
import userModel from 'data/models/userModel'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import permissionsModel from 'data/models/permissionsModel'
import businessesCollection from 'data/collections/businessesCollection'
import {StateParams, StateService} from '@uirouter/core'
import Setting from 'data/domain-objects/Setting'
import Fleet from 'data/domain-objects/Fleet'
import Branch from 'data/domain-objects/Branch'
import MultiLangString from 'data/domain-objects/MultiLangString'
import MultilangContact from 'data/domain-objects/MultilangContact'
import User from 'data/domain-objects/User'

class FleetEntity {
  constructor(
    public name: MultiLangString = new MultiLangString(),
    public arabicName: string = '',
    public description: MultiLangString = new MultiLangString(),
    public services: string[] = [],
    public contacts: MultilangContact,
    public logoUrl: string = '',
    public areas: any[] = [],
    public global: boolean = false,
    public restrictedToBranches: string[] = [],
    public availableBranches: any[] = []
  ) {}

  set defaultCountryCode(code: string) {
    this.contacts.en.countryDiallingCode = code
    this.contacts.ar.countryDiallingCode = code
  }

  static build(fleetDTO: any): FleetEntity {
    return new FleetEntity(
      MultiLangString.build(fleetDTO.name),
      fleetDTO.arabicName,
      MultiLangString.build(fleetDTO.description),
      fleetDTO.services,
      MultilangContact.build(fleetDTO.contacts),
      fleetDTO.logoUrl,
      fleetDTO.areas,
      fleetDTO.global,
      fleetDTO.restrictedToBranches,
      fleetDTO.availableBranches
    )
  }
}

const CONTACT_DETAILS_FOR_RIDE_OPTIONS = [
  'driver',
  'fleet'
]

interface IFleetSettings {
  fleetSafeDistance: Setting,
  contactDetailsForRide: Setting
  performNextDayDelivery: Setting,
  driverAllocationStrategy: Setting
}

class FleetFormController extends CreateViewEditFormController {

  constructor (
    $scope: ng.IScope,
    $state: StateService,
    $stateParams: StateParams,
    $filter: ng.IFilterService) {
    super($scope, $state, $stateParams, 'fleetId')

    this.contactDetailsForRideOptions = CONTACT_DETAILS_FOR_RIDE_OPTIONS

    this.fleetSettingKeys = [
      'driverAllocationStrategy',
      'fleetSafeDistance',
      'performNextDayDelivery',
      'contactDetailsForRide'
    ]

    this.allocationStrategiesMap = {}
    /* This list is hardcoded on BE in Fleets configuration */
    this.allocationStrategies = ['preferClosest', 'preferFree', 'preferBusy']

    this.allocationStrategies.forEach((allocationStrategy: string) => {
      this.allocationStrategiesMap[allocationStrategy] = $filter<ITranslateFilter>('translate')(`PAGES.FLEET.ALLOCATION_STRATEGIES.${allocationStrategy}`)
    })

    this.backendModel = null
  }
  public availableBranches: IAbstractEntity[]
  public contactDetailsForRideOptions: string[]
  public fleetSettings: IFleetSettings
  public fleetSettingKeys: string[]
  public allocationStrategiesMap: object
  public allocationStrategies: string[]
  public fleetId: string
  public targetState: string

  static updateIsGlobal (fleet: Fleet, global: boolean): Promise<void> {
    return fleetsCollection.updateGlobal(fleet, global)
  }

  $onInit (): void {
    const namedEntityId = this.$stateParams[this.entityIdParamName]
    this.fleetId = (namedEntityId || this.$stateParams.id)

    // super should happen after above fleetId init
    super.$onInit()

    if (this.isCreate) {
      this.targetState = 'main.businesses'
      this.getFleetSettings()
    }

    this.loadDependencies()
  }

  loadDependencies (): void {
    const userPromise = this.isCreate ? this.getUser() : Promise.resolve()
    Promise.all([
      this.getAvailableBranches(),
      userPromise
    ]).then(() => this.$scope.$digest())
  }

  onSync (): Promise<[void, void]> {
    return Promise.all([this.getFleetSettings(), this.getEntity()])
  }

  getFleetSettings (): Promise<void> {
    return fleetsCollection.getSettings(this.fleetId)
      .then((fleetSettings: IFleetSettings) => {
        this.fleetSettings = fleetSettings
        this.fleetSettings.fleetSafeDistance.value = String(this.fleetSettings.fleetSafeDistance.value)
        this.fleetSettings.fleetSafeDistance.defaultValue = String(this.fleetSettings.fleetSafeDistance.defaultValue)
      })
  }

  saveFleetSettings (): Promise<void> {
    this.fleetSettingKeys.forEach((key: string) => {
      if (this.fleetSettings.hasOwnProperty(key) && this.fleetSettings[key].value !== this.fleetSettings[key].defaultValue) {
        this.fleetSettings[key].isOverridden = true
      }
    })
    return fleetsCollection.setSettings(this.fleetSettings, this.fleetId)
  }

  getAvailableBranches (): Promise<void> {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then((businessesWithBranches: object[]) => {
        this.availableBranches = []
        businessesWithBranches.forEach((businessWithBranches: any) => {
          const branches = businessWithBranches.branches || []
          branches.forEach((branch: Branch) => {
            branch.name = `${businessWithBranches.name} - ${branch.name}`
            this.availableBranches.push(branch)
          })
        })
      })
      .catch((error: Error) => this.logger.error('Could not get available branches that the fleet can service', error))
  }

  getEntity (): Promise<void> {
    return fleetsCollection.getWithAllLanguages(this.fleetId)
      .then((entity: Fleet) => {
        this.backendModel = entity
        this.setDefaultModel()
      })
      .catch((error: Error) => this.logger.error('Could not get fleet:', error))
  }

  getUser (): Promise<void> {
    return userModel.getUser()
      .then((user: User) => this.handleGetUserSuccess(user))
      .catch((error: Error) => this.handleGetUserFailed(error))
  }

  getDefaultModel (): FleetEntity {
    if (this.backendModel) {
      return this.backendModel
    }
    const defaultEntityModel = FleetEntity.build({})
    defaultEntityModel.defaultCountryCode = envConfig.defaultCountryCode.toString()
    return defaultEntityModel
  }

  handleGetUserSuccess (user: User): void {
    this.model.contactName = `${user.firstName} ${user.lastName}`
    this.model.contactEmail = user.email
    this.model.contactCountryDiallingCode = user.countryDiallingCode
    this.model.contactPhoneNumber = user.phoneNumber
  }

  handleGetUserFailed (error: Error): void {
    this.logger.error('Could not prepopulate fleet contact details from user:', error)
  }

  updateLogoUrl (logoUrl: string): void {
    this.model.logoUrl = logoUrl
  }

  beforeSubmit (): Promise<void> {
    // Reset restricted branches if the user has selected to not restrict or the restricted branches aren't an array
    if (this.model.global || !Array.isArray(this.model.restrictedToBranches)) {
      this.model.restrictedToBranches = []
    }

    this.model.contacts['ar'] = this.model.contacts['en']

    let promisedFleet
    if (this.isCreate) {
      promisedFleet = fleetsCollection.create(this.model)
        .then((fleet: Fleet) => {
          this.fleetId = fleet.id
          return fleet
        })
    } else {
      promisedFleet = fleetsCollection.update(this.model, this.model.id)
    }

    // If we have set fleet global permissions, we must sync fleet global status but must do it after syncing fleet
    return promisedFleet
      .then((fleet: Fleet) => {
        this.saveFleetSettings()
        const hasFleetGlobalPermission = permissionsModel.getHasPermission('FLEETS_EDIT_GLOBAL')
        if (hasFleetGlobalPermission) {
          return FleetFormController.updateIsGlobal(fleet, this.model.global)
        } else {
          return fleet
        }
      })
  }
}

export default {
  templateUrl: require('./fleet-form.pug'),
  controller: FleetFormController
}
