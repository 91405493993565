import Consumer from 'data/domain-objects/Consumer'
import BaseController from 'presentation/common/BaseController'
import consumerCollection from 'data/collections/consumerCollection'
import Address from 'data/domain-objects/Address'
import Setting from 'data/domain-objects/Setting'
import PhoneNumber from 'data/domain-objects/PhoneNumber'
import addressCollection from 'data/collections/addressCollection'
import * as AreasSettingsConstants from 'common/constants/AreasSettings'
import { StateService } from '@uirouter/core'

class ConsumerFormController extends BaseController {

  // bindings
  public settings: Setting[]
  public availableAreas: string[]
  public phoneNumber: PhoneNumber
  public onConsumerCreated: (payload: {consumer: Consumer}) => void
  public onAddressAdded: () => void
  public showAddressLocationMap: boolean
  public expandAddressLocationMap: boolean

  // properties
  public consumerId: string = null
  public consumer: Consumer = Consumer.build()
  public consumerAddress: Address = Address.build()
  public addressName: string = ''
  public errors: {[errorType: string]: boolean} = {}

  // setting values
  public showAllAreas: string = AreasSettingsConstants.SHOW_AREA_SETTINGS_SHOW_ALL
  public showSelectedOnlyAreas: string = AreasSettingsConstants.SHOW_AREA_SETTINGS_SHOW_SELECTED

  constructor (
    $scope: ng.IScope,
    $state: StateService,
  ) {
    super($scope, $state)
  }

  syncPhoneNumber (): void {
    if (this.phoneNumber) {
      this.consumer.countryDiallingCode = this.phoneNumber.countryDiallingCode || ''
      this.consumer.phoneNumber = this.phoneNumber.phoneNumber || ''
    }
  }

  submit (): void {
    this.syncPhoneNumber()
    this.errors = {}
    this.isLoading = true
    this.submitUser()
      .then(() => this.submitAddress())
      .catch((err: Error) => {
        this.logger.error('Cannot submit address and consumer', err)
        this.errors.genericError = true
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  submitUser (): Promise<void> {
    return consumerCollection.create(Consumer.build(this.consumer).toJSON())
      .then((consumer: any) => {
        const createdConsumer = Consumer.build(consumer)

        // Our BE doesn't return firstName and lastName...
        createdConsumer.firstName = this.consumer.firstName
        createdConsumer.lastName = this.consumer.lastName

        this.consumerAddress.consumerFirstName = this.consumer.firstName
        this.consumerAddress.consumerLastName = this.consumer.lastName

        this.consumerId = consumer.id
        this.consumer = createdConsumer
      })
  }

  submitAddress (): Promise<void> {
    return addressCollection.createConsumerAddress(this.consumerId, this.consumerAddress)
      .then((address: any) => {
        const createdAddress = Address.build(address)

        createdAddress.type = this.consumerAddress.type
        createdAddress.consumerFirstName = this.consumer.firstName
        createdAddress.consumerLastName = this.consumer.lastName

        this.consumer.addresses.push(createdAddress)
        this.onConsumerCreated({consumer: this.consumer})
        this.onAddressAdded()
      })
  }
}

export default {
  templateUrl: require('./consumer-form.pug'),
  controller: ConsumerFormController,
  bindings: {
    settings: '<?',
    availableAreas: '<',
    phoneNumber: '<',
    onConsumerCreated: '&',
    onAddressAdded: '&',
    showAddressLocationMap: '<',
    expandAddressLocationMap: '<'
  }
}
