import templateUrl from './change-password-panel.pug'

import ViewEditFormController from 'presentation/common/ViewEditFormController'
import authService from 'data/services/authService'

class ChangePasswordPanelController extends ViewEditFormController {
  constructor ($scope) {
    super($scope)
    this.passwordChanged = false
  }

  $onInit () {
    super.$onInit()

    this.$scope.$watchGroup(['$ctrl.model.newPassword', '$ctrl.model.confirmPassword'], () => {
      if (this.isEditing && this.passwordForm.confirmPassword) {
        const passwordsDontMatch = (this.model.newPassword !== this.model.confirmPassword)
        this.passwordForm.confirmPassword.$setValidity('passwordsMatch', !passwordsDontMatch)
      }
    })
  }

  getDefaultModel () {
    return {currentPassword: '', newPassword: '', confirmPassword: ''}
  }

  beforeSubmit () {
    return authService.changePassword(
      this.user.id, this.model.currentPassword, this.model.newPassword
    )
  }

  afterSubmitFailure (error) {
    this.logger.error(error)
    this.passwordChanged = false
  }

  afterSubmitSuccess () {
    super.afterSubmitSuccess(this.getDefaultModel())
    this.passwordChanged = true
  }

  resetPanel () {
    super.cancelEdit()
    this.passwordChanged = false
  }
}

export default {
  templateUrl,
  controller: ChangePasswordPanelController,
  bindings: {
    user: '<'
  }
}
