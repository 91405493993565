import Cost from '../Cost'

export default class StorefrontOrderCost {
  constructor (
    public totalPrice: Cost,
    public subtotalPrice: Cost,
    public deliveryPrice: Cost,
    public subtotalAfterDiscountPrice: Cost,
    public promoCodeDiscount: Cost
  ) {}

  static build(dto: any): StorefrontOrderCost {
    return new StorefrontOrderCost(
      Cost.build(dto.totalPrice || {}),
      Cost.build(dto.subtotalPrice || {}),
      Cost.build(dto.deliveryPrice || {}),
      Cost.build(dto.subtotalAfterDiscountPrice || {}),
      Cost.build(dto.promoCodeDiscount || {}, true)
    )
  }
}
