import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'
import { payoutsCollection } from 'data/collections/payoutsCollection'
import businessesCollection from 'data/collections/businessesCollection'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import EarningSummary from 'data/domain-objects/EarningSummary'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import BalanceAccount from 'data/domain-objects/BalanceAccount'

class PayoutsManagement extends BaseController {
  public businessId: string = null
  public allowManualPayout: boolean = false

  public dialogId: string = 'request-manual-payout-dialog'
  public businessName: string = null
  public earningSummary: EarningSummary = null
  public showLoadingError: boolean = false
  public showRequestManualPayoutError: boolean = false
  public showRequestManualPayoutConfirmation: boolean = false
  public balancesSelectionStateById: any = {}

  private balancesSelectedForManualPayout: BalanceAccount[] = []

  constructor ($scope: ng.IScope, $state: StateService) {
    super($scope, $state)
  }

  $onChanges(): void {
    if (this.businessId) {
      this.sync()
    }
  }

  onSync(): Promise<any> {
    this.showLoadingError = false

    return Promise.all([
      businessesCollection.get(this.businessId),
      payoutsCollection.getEarningSummary(this.businessId)
    ])
      .then(([business, earningSummary]: [DBMappedNamedEntity, EarningSummary]): void => {
        if (business) {
          this.businessName = business.name
        }

        if (earningSummary) {
          this.earningSummary = earningSummary
        }
      })
      .catch((error: Error): void => {
        this.logger.error('Cannot load business or earnings summary', error)
        this.showLoadingError = true
      })
  }

  openRequestManualPayoutDialog(): void {
    modalDialogStateService.emitOpenModalDialog({ dialogId: this.dialogId })
  }

  closeRequestPayoutDialog(): void {
    modalDialogStateService.emitCloseModalDialog({ dialogId: this.dialogId })
    this.balancesSelectedForManualPayout = []
    this.balancesSelectionStateById = {}
  }

  onBalanceCheckedToPayout(balanceAccount: BalanceAccount, isSelected: boolean): void {
    if (isSelected) {
      this.balancesSelectedForManualPayout.push(balanceAccount)
    } else {
      this.balancesSelectedForManualPayout = this.balancesSelectedForManualPayout
        .filter((selectedBalance: BalanceAccount): boolean => selectedBalance.id !== balanceAccount.id)
    }
  }

  requestManualPayout(): void {
    this.showRequestManualPayoutError = false
    this.showRequestManualPayoutConfirmation = false

    const branchIds = this.balancesSelectedForManualPayout.map((balanceAccount: BalanceAccount): string => balanceAccount.branch.id)

    payoutsCollection.requestManualPayout(this.businessId, branchIds)
      .then(() => { this.showRequestManualPayoutConfirmation = true })
      .catch((error: Error): void => {
        this.logger.error('Cannot request manual payout', error)
        this.showRequestManualPayoutError = true
      })
      .then(() => this.sync())

    this.closeRequestPayoutDialog()
  }
}

export default {
  templateUrl: require('./payouts-management.pug'),
  controller: PayoutsManagement,
  bindings: {
    businessId: '<',
    allowManualPayout: '<'
  }
}
