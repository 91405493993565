import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const imageService = new CrudService(endpoints.IMAGE)

export default {
  upload: (file: any): Promise<any> => {
    return imageService.create({}, [], {}, {file})
  }
}
