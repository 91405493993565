import templateUrl from './issue-driver-details.pug'

export default {
  templateUrl,
  bindings: {
    initialDriver: '=',
    currentDriver: '=',
    onDriverChangesClick: '&',
    onDriverClick: '&',
    showDriverChangesIcon: '<'
  }
}
