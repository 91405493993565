import declareAppModule from 'presentation/_utilities/declareAppModule'

import addButton from './add-button/addButton'
import filtersButton from './filters-button/filtersButton'

import basicTable from './basic-table/basicTable'
import collapsibleTitledPanel from './collapsible-titled-panel/collapsibleTitledPanel'
import confirmDialog from './confirm-dialog/confirmDialog'

import deleteDialog from './delete-dialog/deleteDialogController'
import deleteDropdown from './delete-dialog/deleteDropdown'

import deliveryLocationsMap from './delivery-locations-map/deliveryLocationsMap'

import deliveryInfo from './delivery-info/deliveryInfo'
import deliveryPrinter from './delivery-info/delivery-printer/deliveryPrinter'

import driverLocationsMap from './driver-locations-map/driverLocationsMap'
import deliveryOverviewMap from './delivery-overview-map/deliveryOverviewMap'
import driverLogo from './driver-logo/driverLogo'
import dropdownNavigation from './dropdown-navigation/dropdownNavigation'
import dynamicTable from './dynamic-table/dynamicTable'
import entityImage from './entity-image/entityImage'
import entityPicker from './entity-picker/entityPicker'
import entityListPicker from './entity-list-picker/entityListPicker'
import headerLoggedIn from './header-logged-in/headerLoggedIn'

import informationList from './information-list/informationList'
import informationListItem from './information-list/information-list-item/informationListItem'

import loadingDirective from './loading-directive/loadingDirective'
import localePicker from './locale-picker/localePicker'
import locationIcon from './location-icon/locationIcon'
import locationMap from './location-map/locationMap'
import masthead from './masthead/masthead'
import messagePanel from './message-panel/messagePanel'
import modalDialog from './modal-dialog/modalDialog'
import pagination from './pagination/pagination'
import progressWizard from './progress-wizard/progressWizard'
import reportTable from './report-table/reportTable'

import sidebar from './sidebar/sidebar'
import tableCell from './table-cell/tableCell'
import titledPage from './titled-page/titledPage'
import titledPanel from './titled-panel/titledPanel'

import userBalance from './user-balance-top-up/user-balance/userBalance'
import applyVoucher from './user-balance-top-up/apply-voucher/applyVoucher'
import userBalanceTopUp from './user-balance-top-up/userBalanceTopUp'
import userTopUp from './user-balance-top-up/user-top-up/userTopUp'
import userTransactionsHistory from './user-transactions-history/userTransactionsHistory'
import UserMenu from './user-menu/userMenu'
import paymentMethodsPicker from './user-balance-top-up/payment-methods-picker/paymentMethodsPicker'
import tag from './tag/tag'
import lazyLoader from './lazy-loader/lazyLoader'
import calendar from './calendar/calendar'
import calendarRangePicker from './calendar-range-picker/calendarRangePicker'
import fancyDashboardButton from './fancy-dashboard-button/fancyDashboardButton'
import inheritedTitledPanel from './inherited-titled-panel/inheritedTitledPanel'

export default declareAppModule('core.elements', (module: ng.IModule) => module
  .component('addButton', addButton)
  .component('filtersButton', filtersButton)
  .component('basicTable', basicTable)
  .component('collapsibleTitledPanel', collapsibleTitledPanel)
  .component('confirmDialog', confirmDialog)
  .component('deleteDialog', deleteDialog)
  .component('deleteDropdown', deleteDropdown)
  .component('driverLocationsMap', driverLocationsMap)
  .component('deliveryInfo', deliveryInfo)
  .component('deliveryPrinter', deliveryPrinter)
  .component('deliveryLocationsMap', deliveryLocationsMap)
  .component('deliveryOverviewMap', deliveryOverviewMap)
  .component('driverLogo', driverLogo)
  .component('dropdownNavigation', dropdownNavigation)
  .component('dynamicTable', dynamicTable)
  .component('entityImage', entityImage)
  .component('entityPicker', entityPicker)
  .component('entityListPicker', entityListPicker)
  .component('headerLoggedIn', headerLoggedIn)
  .component('informationList', informationList)
  .component('informationListItem', informationListItem)
  .component('lazyLoader', lazyLoader)
  .directive('loader', loadingDirective)
  .component('localePicker', localePicker)
  .component('locationIcon', locationIcon)
  .directive('locationMap', locationMap)
  .component('masthead', masthead)
  .component('messagePanel', messagePanel)
  .component('modalDialog', modalDialog)
  .component('pagination', pagination)
  .component('progressWizard', progressWizard)
  .component('reportTable', reportTable)
  .component('sidebar', sidebar)
  .component('tableCell', tableCell)
  .component('titledPage', titledPage)
  .component('titledPanel', titledPanel)
  .component('userBalanceTopUp', userBalanceTopUp)
  .component('applyVoucher', applyVoucher)
  .component('userBalance', userBalance)
  .component('userTopUp', userTopUp)
  .component('userTransactionsHistory', userTransactionsHistory)
  .component('userMenu', UserMenu)
  .component('paymentMethodsPicker', paymentMethodsPicker)
  .component('tag', tag)
  .component('calendar', calendar)
  .component('calendarRangePicker', calendarRangePicker)
  .component('fancyDashboardButton', fancyDashboardButton)
  .component('inheritedTitledPanel', inheritedTitledPanel)
)

