import moment from 'moment'
import envConfig from 'common/envConfig'
import FormModel from 'data/models/FormModel'
import templateUrl from './subscription-settings.pug'
import SubscriptionPlan from 'data/domain-objects/SubscriptionPlan'
import branchesCollection from 'data/collections/branchesCollection'
import userSubscriptionCollection from 'data/collections/userSubscriptionCollection'
import userModel from 'data/models/userModel'
import userCollection from 'data/collections/userCollection'
import subscriptionPlansCollection from 'data/collections/subscriptionPlansCollection'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import BaseController from 'presentation/common/BaseController'

const YEARLY_PLAN_CHANGE_PERIOD_DAYS = 30
const YEARLY_PLAN_CHANGE_PERIOD_MS = moment.duration(YEARLY_PLAN_CHANGE_PERIOD_DAYS, 'days').asMilliseconds()

const ERROR_RETRIES_COUNT = 1

class SubscriptionSettingsController extends BaseController {
  constructor ($scope) {
    super($scope)
  }

  $onInit () {
    this.formModel = new FormModel()
    this.balanceName = ''
    this.availablePlans = []
    this.selectedBranches = []
    this.availableBranches = []
    this.branchesNamesById = {}
    this.currentSubscriptionPlan = {}
    this.currentSubscriptionPlanId = ''
    this.newSubscriptionPlanId = ''
    this.subscriptionStartDate = null
    this.subscriptionEndDate = null
    this.canChangeSubscriptionPlan = true
    this.backendValidationErrors = {}
    this.dateFormat = envConfig.angularOnlyDateFormat
    this.errorRetriesCount = ERROR_RETRIES_COUNT

    userModel.getUser()
      .catch(err => this.logger.error('Cannot get current user', err))
      .then(user => this.syncData(user.id))

    this.openModalDialogSubscription = modalDialogStateService.onOpenModalDialog(() => this.onOpenModalDialog())
    this.closeModalDialogSubscription = modalDialogStateService.onCloseModalDialog(payload => this.onCloseModalDialog(payload))
  }

  $onDestroy () {
    this.openModalDialogSubscription.remove()
    this.closeModalDialogSubscription.remove()
  }

  syncData (userId) {
    this.isLoading = true
    Promise.all([
      userCollection.getBalance(userId),
      subscriptionPlansCollection.getAll(),
      userSubscriptionCollection.get(),
      branchesCollection.getAllWithoutAddresses()
    ])
      .then(response => this.handleData(response))
      .catch(err => this.logger.error('Cannot get subscription and branches data', err))
  }

  handleData ([balance, availableSubscriptionPlans, userSubscription, branchesData]) {
    this.handleAvailableSubscriptionPlans(availableSubscriptionPlans)
    this.handleUserSubscriptionSuccess(userSubscription)
    this.handleAvailableBranches(branchesData)
    this.balanceName = balance.name
    this.isLoading = false
    this.errorRetriesCount = ERROR_RETRIES_COUNT
    this.$scope.$apply()
  }

  handleAvailableBranches (availableBranches) {
    this.availableBranches = availableBranches
    this.branchesNamesById = availableBranches.reduce((accum, type) => (accum[type.id] = type.name) && accum, {})
  }

  handleAvailableSubscriptionPlans (availableSubscriptionPlans) {
    this.availablePlans = availableSubscriptionPlans.map(plan => SubscriptionPlan.build(plan))
  }

  handleUserSubscriptionSuccess (userSubscription) {
    if (userSubscription) { // We may have successfully gotten their subscription, and they don't have one
      this.selectedBranches = userSubscription.branches
      this.currentSubscriptionPlanId = userSubscription.subscriptionPlanId
      this.subscriptionStartDate = new Date(userSubscription.startDate)
      this.subscriptionEndDate = new Date(userSubscription.endDate)
      this.newSubscriptionPlanId = this.currentSubscriptionPlanId ? this.currentSubscriptionPlanId : ''
      this.currentSubscriptionPlan = this.availablePlans.find(plan => plan.id === userSubscription.subscriptionPlanId) || SubscriptionPlan.build({})
      // You can change a monthly plan at any time, but a yearly plan only within its plan end period.
      const earliestChangeYearlyPlanTimestamp = (this.subscriptionEndDate.getTime() - YEARLY_PLAN_CHANGE_PERIOD_MS)
      this.canChangeSubscriptionPlan = this.currentSubscriptionPlan.duration.unitCode.toUpperCase() !== 'YEAR' || Date.now() >= earliestChangeYearlyPlanTimestamp
    }
  }

  static openBranchesSelectModal () {
    modalDialogStateService.emitOpenModalDialog({dialogId: 'subscription-settings-branches-select'})
  }

  static closeBranchesSelectModal () {
    modalDialogStateService.emitCloseModalDialog({preSave: true, dialogId: 'subscription-settings-branches-select'})
  }

  onOpenModalDialog () {
    // Cloning previously selected branches to restore them if user simply close popup after made some changes
    this.previouslySelectedBranches = this.selectedBranches.slice()
  }

  onCloseModalDialog (payload) {
    if (payload && payload.preSave) {
      this.submit()
    } else {
      this.selectedBranches = this.previouslySelectedBranches
    }
  }

  submit () {
    let submitPromise = null

    const subscriptionSettings = {
      subscriptionPlanId: this.newSubscriptionPlanId,
      branches: this.selectedBranches
    }

    if (this.currentSubscriptionPlanId) {
      submitPromise = userSubscriptionCollection.update(subscriptionSettings)
    } else {
      submitPromise = userSubscriptionCollection.create(subscriptionSettings)
    }

    submitPromise
      .then(response => this.handleSubmitSuccess(response))
      .catch(err => this.handleSubmitFailed(err))
      .then(() => this.$scope.$apply())
  }

  handleSubmitSuccess (userSubscription) {
    this.isError = false
    this.logger.info('User Subscription submitted', userSubscription)
    this.formModel.notifySubmitSuccess()

    this.handleUserSubscriptionSuccess(userSubscription)
  }

  handleSubmitFailed (error) {
    this.isError = true
    if (this.errorRetriesCount > 0) {
      this.errorRetriesCount -= 1
      this.syncData()
    }

    this.logger.error('Submit failed', error)
    this.formModel.notifySubmitError(error)
    this.$scope.$digest()
  }
}

export default {
  templateUrl,
  controller: ['$scope', SubscriptionSettingsController]
}
