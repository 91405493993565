import templateUrl from './access-control-builder.pug'
import BaseController from 'presentation/common/BaseController'
import entitiesModel from 'data/models/entitiesModel'

class AccessControlBuilderController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.resetAccessControlBuilder()
    this.resetVisibleEntityPickers()
    this.setupWatchers()
  }

  setupWatchers () {
    this.$scope.$watch('$ctrl.buildingAccessForRoleId', () => this.setVisibleEntityPickers())
  }

  resetAccessControlBuilder () {
    this.buildingAccessForRoleId = ''
    this.showAddRoleButton = false
  }

  resetVisibleEntityPickers () {
    this.showBusinessPicker = false
    this.selectedBusinesses = []
    this.showBranchPicker = false
    this.selectedBranches = []
    this.showFleetPicker = false
    this.selectedFleets = []
  }

  setVisibleEntityPickers () {
    this.resetVisibleEntityPickers()

    let entities = []

    if (this.buildingAccessForRoleId.length) {
      this.showAddRoleButton = true
    }

    if (this.rolesByIdMappedToEntities && this.buildingAccessForRoleId.length) {
      entities = this.rolesByIdMappedToEntities[this.buildingAccessForRoleId]
    }

    if (entities.length) {
      entities.forEach(entity => {
        if (entity === entitiesModel.ENTITIES.BUSINESS) {
          this.showBusinessPicker = true
        }

        if (entity === entitiesModel.ENTITIES.BRANCH) {
          this.showBranchPicker = true
        }

        if (entity === entitiesModel.ENTITIES.FLEET) {
          this.showFleetPicker = true
        }
      })
    }
  }

  onAddRoleClick () {
    const entities = this.rolesByIdMappedToEntities[this.buildingAccessForRoleId]
    const selectedEntitiesForRole = []

    entities.forEach(entity => {
      let scopedEntityIds = []

      if (entity === entitiesModel.ENTITIES.BUSINESS) {
        scopedEntityIds = this.selectedBusinesses
      } else if (entity === entitiesModel.ENTITIES.BRANCH) {
        scopedEntityIds = this.selectedBranches
      } else if (entity === entitiesModel.ENTITIES.FLEET) {
        scopedEntityIds = this.selectedFleets
      }

      selectedEntitiesForRole.push({
        entityName: entity,
        scopedEntityIds
      })
    })

    this.handleAddRoleClick(this.buildingAccessForRoleId, selectedEntitiesForRole)
    this.resetAccessControlBuilder()
  }
}

export default {
  controller: AccessControlBuilderController,
  templateUrl,
  bindings: {
    businesses: '<',
    branches: '<',
    fleets: '<',
    roleIds: '<',
    rolesById: '<',
    rolesByIdMappedToEntities: '<',
    handleAddRoleClick: '<'
  }
}
