import Logger from 'common/Logger'
import promoCodesCollection from 'data/collections/promoCodesCollection'
import {StateParams} from '@uirouter/core/'
import PromoCode from 'data/domain-objects/PromoCode'

const logger = new Logger('PromoCode View Controller')

class PromoCodeViewController {

  private promoCodeId: string
  public promoCode: PromoCode
  public promoCodeForm: ng.IFormController
  public isEditing: boolean = false
  private isLoading: boolean = false
  public isSubmitting: boolean = false
  public errors: {[errorCode: number]: boolean} = {}

  constructor (
    private $stateParams: StateParams,
    private $scope: ng.IScope
  ) {}

  $onInit(): void {
    this.promoCodeId = this.$stateParams.promoCodeId
    this.getPromoCode()
  }

  get isPromoCodeFetchFailed(): boolean {
    return !this.isLoading && !this.promoCode
  }

  public startEditing(): void {
    this.isEditing = true
  }

  private getPromoCode(): void {
    this.isLoading = true
    promoCodesCollection.getPromoCode(this.promoCodeId)
      .then((promoCode: PromoCode): void => {
        this.promoCode = promoCode
      })
      .catch((error: Error): void => {
        logger.error('Cannot retrieve PromoCode', error)
      })
      .then((): void => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  public updatePromoCode(): void {
    this.errors = {}
    this.isSubmitting = true
    if (this.promoCodeForm.$valid) {
      promoCodesCollection.updatePromoCode(this.promoCode)
        .then((): void => {
          this.isEditing = false
        })
        .catch((error: any): void => {
          logger.error('There were some problems while updating PromoCode. PromoCode Id: ', this.promoCodeId, error)
          if (error.code && error.code === 'FLEET_PROMOCODE_DUPLICATE') {
            this.errors['NAME_DUPLICATED'] = true
          } else if (error.status && error.status === 400) {
            this.errors['VALIDATION'] = true
          } else {
            this.errors['COMMON'] = true
          }
        })
        .then((): void => {
          this.isSubmitting = false
          this.$scope.$digest()
        })
    }
  }
}

export default {
  templateUrl: require('./promo-code-view.pug'),
  controller: PromoCodeViewController
}
