import envConfig from 'common/envConfig'
import templateUrl from './fleet-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import Cost from 'data/domain-objects/Cost'

const SETTINGS_KEYS = [
  'showFleetSettings',
  'sortFleetsBy',
  'fleetsLimit',
  'hideGlobalFleets',
  'deliverySLA',
  'serviceFee'
]

class FleetSettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
    this.fleetLimitsMin = envConfig.businessAndBranchSettings.fleetsLimitRange.min
    this.fleetLimitsMax = envConfig.businessAndBranchSettings.fleetsLimitRange.max
    this.fleetSortingOrders = envConfig.fleetSortingOrders
    this.serviceFee = Cost.build()
  }

  $onInit () {
    super.$onInit()

    if (this.entityType === 'branch' && this.model.deliverySLA) {
      this.showDeliverySLA = true
    }

    this.$scope.$watch('$ctrl.serviceFee.amount', () => {
      this.model.serviceFee.value = this.serviceFee.amount
    })
  }

  $onChanges () {
    if (this.model && this.model.serviceFee) {
      this.serviceFee = Cost.build({amount: this.model.serviceFee.value})
    }
  }
}

export default {
  templateUrl,
  controller: FleetSettingsController,
  bindings: {
    entityType: '<',
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
