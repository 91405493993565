import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import issuesDashboard from './issues-dashboard/issuesDashboard'
import issueDriverDetails from './issue-driver-details/issueDriverDetails'
import resolveIssueDialog from './resolve-issue-dialog/resolveIssueDialog'
import driversHistoryPopup from './drivers-history-popup/driversHistoryPopup'
import issueTrackerFilters from './issue-tracker-filters/issueTrackerFilters'
import issuesTable from 'presentation/logistics-issues/issues-table/issuesTable'

export default declareAppModule('logistics.issues', (module: ng.IModule) => module
  .component('issuesDashboard', issuesDashboard)
  .component('issuesTable', issuesTable)
  .component('issueDriverDetails', issueDriverDetails)
  .component('resolveIssueDialog', resolveIssueDialog)
  .component('driversHistoryPopup', driversHistoryPopup)
  .component('issueTrackerFilters', issueTrackerFilters)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'issues',
      stateKey: 'ISSUES',
      componentName: 'issuesDashboard',
      requiresPermission: 'ISSUES'
    })
  })
)
