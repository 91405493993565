import PubSub from 'common/PubSub'

const pubSub = new PubSub()
const EVENT_OPEN_MODAL_DELIVERY = 'EVENT_OPEN_MODAL_DELIVERY'
const EVENT_CLOSE_MODAL_DELIVERY = 'EVENT_CLOSE_MODAL_DELIVERY'

function emitOpenModalDialog (payload = {}) {
  pubSub.publish(EVENT_OPEN_MODAL_DELIVERY, payload)
}
function onOpenModalDialog (onPublish) {
  return pubSub.subscribe(EVENT_OPEN_MODAL_DELIVERY, onPublish)
}

function emitCloseModalDialog (payload = {}) {
  pubSub.publish(EVENT_CLOSE_MODAL_DELIVERY, payload)
}
function onCloseModalDialog (onPublish) {
  return pubSub.subscribe(EVENT_CLOSE_MODAL_DELIVERY, onPublish)
}

export default {
  emitOpenModalDialog,
  onOpenModalDialog,
  emitCloseModalDialog,
  onCloseModalDialog
}
