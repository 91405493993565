import templateUrl from './order-details-settings.pug'
import envConfig from 'common/envConfig'
import currenciesCollection from 'data/collections/currenciesCollection'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import Setting from 'data/domain-objects/Setting'
import * as PaymentMethodsOptions from 'common/constants/PaymentMethodsSettings'

const SETTINGS_KEYS = [
  PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_CASH,
  PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_CARD,
  PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_CASH_ON_DELIVERY,
  PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_CASH_OFF_SYSTEM,
  PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_PAID_OFF_SYSTEM,
  PaymentMethodsOptions.DEFAULT_CURRENCY_OPTION_NAME,
  PaymentMethodsOptions.PAYMENT_OPTION_NAME
]

class OrderDetailsSettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
    this.currencyCodes = []
    this.sync()
    this.settingsDefinitions = []
    this.isSettingSelectionDisabled = false
  }

  $onChanges (simpleChanges) {
    if (simpleChanges.hasOwnProperty('model')) {
      this.settingsDefinitions = []
      for (const paymentOption of PaymentMethodsOptions.ALL_PAYMENTS_OPTIONS) {
        const settingName = PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_MAPPING[paymentOption]
        const defaultSettingParams = PaymentMethodsOptions.PAYMENT_METHOD_DEFAULT_SETTINGS_VALUES_MAPPING[paymentOption]

        this.model[settingName] = this.model[settingName] || new Setting(defaultSettingParams)
        this.settingsDefinitions.push({setting: this.model[settingName], key: paymentOption})
      }
    }
  }

  onSync () {
    return currenciesCollection.getCurrencyCodes()
      .then(currencyCodes => this.currencyCodes = currencyCodes)
  }

  applyDefaultValuesForFields (forceToDefault) {
    super.applyDefaultValuesForFields(forceToDefault)
    this.model.defaultCurrency.value = this.model.defaultCurrency.value || envConfig.defaultCurrencyCode
  }

  onOptionsChange (changedPaymentMethod, optionState) { // argument is one of the constants from PaymentMethods
    const availablePaymentMethods = []

    for (const paymentOption of PaymentMethodsOptions.ALL_PAYMENTS_OPTIONS) {
      const setting = this.model[PaymentMethodsOptions.PAYMENT_METHOD_SHOW_SETTING_MAPPING[paymentOption]]
      if (setting && setting.value) {
        availablePaymentMethods.push(paymentOption)
      }
    }

    this.isSettingSelectionDisabled = availablePaymentMethods.length === 1

    if (this.model.payment && this.model.payment.value === changedPaymentMethod && !optionState) { // !optionState means that option was just disabled
      this.model.payment.value = availablePaymentMethods ? availablePaymentMethods[0] : ''
    }
  }
}

export default {
  templateUrl,
  controller: OrderDetailsSettingsController,
  bindings: {
    form: '=',
    model: '<',
    isEditing: '<'
  }
}
