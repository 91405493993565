export default {
  templateUrl: require('./editable-list-item-color-picker.pug'),
  bindings: {
    backendValidationModel: '=',
    decimalPointsCount: '=',
    form: '=',
    isEditing: '<',
    isDisabled: '<',
    model: '=',
    name: '@',
    placeholder: '@',
    prefix: '@',
    isRequired: '<',
    suffix: '@',
    title: '@',
    type: '@',
    valueMessage: '@',
    valueWarning: '@',
    onChange: '&',
    regexValidatePattern: '<?',
    hostClass: '@?'
  }
}
