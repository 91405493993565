
export default class TariffPeriod {
  constructor (
    public days: any = {},
    public from: string = null,
    public to: string = null,
    public name: string = null,
    public id: string = null
  ) {}

  static build(dto: any = {}): TariffPeriod {
    return new TariffPeriod(
      Object.assign({}, dto.days),
      dto.from || '00:00',
      dto.to || '24:00',
      dto.name || '',
      dto.id || null
    )
  }
}
