import cloneDeep from 'lodash-es/cloneDeep'
import Setting from 'data/domain-objects/Setting'

const MIN_SCHEDULED_RIDE_START_MINUTES = 15
const MAX_SCHEDULED_RIDE_END_MINUTES = 4320
const SHOW_CANCELLATION_POLICY_SETTING_KEY = 'showCancellationPolicy'
const SHOW_CANCELLATION_POLICY_DEFAULT_VALUE = false

class AreaApplicabilityFormController {
  public form: ng.IFormController
  public settings: any = {}
  public localSettings: any = {}
  public defaultSettings: any = {}
  public minScheduledRideStartMinutes: number = MIN_SCHEDULED_RIDE_START_MINUTES
  public maxScheduledRideEndMinutes: number = MAX_SCHEDULED_RIDE_END_MINUTES
  public onChange: (payload: {value: any}) => void

  constructor (
    public $scope: ng.IScope
  ) {}

  $onChanges (): void {
    if (this.defaultSettings && this.settings && this.settings.scheduledRide) {
      this.defaultSettings[SHOW_CANCELLATION_POLICY_SETTING_KEY] = SHOW_CANCELLATION_POLICY_DEFAULT_VALUE

      Object.keys(this.defaultSettings).forEach((settingKey: string): void => {
        const value = cloneDeep(this.settings.scheduledRide.hasOwnProperty(settingKey) ?
          this.settings.scheduledRide[settingKey] : this.defaultSettings[settingKey])

        if (!this.localSettings[settingKey]) {
          this.localSettings[settingKey] = Setting.build({
            value,
            defaultValue: this.defaultSettings.hasOwnProperty(settingKey) ? cloneDeep(this.defaultSettings[settingKey]) : null,
            isOverridden: this.getOverrideValueBySettingKey(settingKey)
          })
        }
      })
    }
  }

  getOverrideValueBySettingKey (settingKey: string): boolean {
    if (this.localSettings[settingKey] && this.localSettings[settingKey].hasOwnProperty('isOverridden')) {
      return this.localSettings[settingKey].isOverridden
    }

    if (this.settings.scheduledRide[settingKey]) {
      return this.settings.scheduledRide[settingKey] !== this.defaultSettings[settingKey]
    }

    return false
  }

  onOverrideModeChanged (settingKey: string, isOverridden: boolean): void {
    this.localSettings[settingKey].isOverridden = isOverridden

    if (!isOverridden) {
      this.localSettings[settingKey].value = cloneDeep(this.localSettings[settingKey].defaultValue)
    }

    this.handleSettingsChanged()
  }

  handleAreaSelected (suggestion: ISuggestion): void {
    if (suggestion) {
      this.settings.pickupArea = suggestion
    }

    this.handleSettingsChanged()
  }

  handleDropoffAreaSelected (suggestion: ISuggestion): void {
    this.settings.dropoffArea = suggestion
    this.handleSettingsChanged()
  }

  handleDropoffAreaSearchTextChanged (searchText: string): void {
    if (!searchText) {
      this.settings.dropoffArea = null
      this.handleSettingsChanged()
    }
  }

  handleSingleSettingChanged (key: string, value: any): void {
    this.localSettings[key].value = value
    this.handleSettingsChanged()
  }

  handleShowCancellationPolicyChanged (value: boolean): void {
    const isOverridden = this.localSettings[SHOW_CANCELLATION_POLICY_SETTING_KEY].defaultValue !== value
    this.onOverrideModeChanged(SHOW_CANCELLATION_POLICY_SETTING_KEY, isOverridden)
    this.handleSingleSettingChanged(SHOW_CANCELLATION_POLICY_SETTING_KEY, value)
  }

  handleSettingsChanged (): void {
    const outputSettings = {
      pickupArea: this.settings.pickupArea,
      dropoffArea: this.settings.dropoffArea,
      scheduledRide: {}
    }

    Object.keys(this.defaultSettings).forEach((settingKey: string): void => {
      if (this.localSettings[settingKey].isOverridden) {
        outputSettings.scheduledRide[settingKey] = this.localSettings[settingKey].value
      }
    })

    this.onChange({value: outputSettings})

    if (this.form && this.form.$setDirty) {
      this.form.$setDirty()
    }
  }
}

export default {
  controller: AreaApplicabilityFormController,
  templateUrl: require('./area-applicability-form.pug'),
  bindings: {
    form: '=',
    name: '@',
    areas: '<',
    settings: '<',
    defaultSettings: '<',
    onChange: '&',
    isEditing: '<'
  }
}
