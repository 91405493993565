import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

class EntityListPicker {

  // bindings
  public items: DBMappedNamedEntity[] = []
  public isEditing: boolean
  public hostClass: string
  public onChange: (data: {values: string[]}) => void

  // internal
  public availableEntitiesList: IEntityListItem[] = null
  public filteredEntitiesList: IEntityListItem[] = []
  private availableListDomElement: any = null
  private selectedListDomElement: any = null
  public selectedItemsCount: number = 0
  private delayedInitialisation: boolean = false

  constructor (
    private $scope: ng.IScope,
    private $element: ng.IAugmentedJQuery
  ) {}

  static removeEventListenersFromDomNode(domNode: any): void {
    const clone = domNode.cloneNode(true)
    domNode.parentNode.replaceChild(clone, domNode)
  }

  prepareDate(): void {
    this.availableEntitiesList = this.items.map((item: IEntityListItem): IEntityListItem => {
      return {...item,
        selected: !!item.selected,
        highlighted: false,
        lowercasedName: item.name.toLocaleLowerCase()
      }
    })
    this.filteredEntitiesList = this.availableEntitiesList
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.items && changes.items.currentValue) {
      this.prepareDate()
      this.countSelectedItems()
    }

    if (changes.isEditing && changes.isEditing.currentValue) {
      this.toggleMouseHandlers()
      this.countSelectedItems()
    }
  }

  countSelectedItems(): void {
    if (this.availableEntitiesList !== null) {
      this.selectedItemsCount = this.availableEntitiesList.filter((item: IEntityListItem) => item.selected).length
    }
  }

  toggleMouseHandlers(): void {
    if (!this.availableListDomElement || !this.selectedListDomElement) {
      this.delayedInitialisation = true
      return
    }

    if (this.isEditing) {
      this.setupFancyMouseSelection(this.availableListDomElement)
      this.setupFancyMouseSelection(this.selectedListDomElement)
    } else {
      EntityListPicker.removeEventListenersFromDomNode(this.availableListDomElement)
      EntityListPicker.removeEventListenersFromDomNode(this.selectedListDomElement)
      this.saveListsDomNodes()
    }
  }

  toggleHighlighted(item: IEntityListItem): void {
    item.highlighted = !item.highlighted
  }

  toggleAllEntities(toSelectedList: boolean): void {
    this.availableEntitiesList.forEach((item: IEntityListItem) => {
      item.highlighted = false
      item.selected = toSelectedList
    })
    this.returnSelectedItems()
  }

  quickFilter(searchTerm: string): void {
    const searchText = searchTerm.trim().toLowerCase()
    this.filteredEntitiesList = this.availableEntitiesList.filter((item: IEntityListItem) => item.lowercasedName.includes(searchText))
  }

  toggleHighlightedEntities(toSelectedList: boolean): void {
    this.availableEntitiesList.forEach((item: IEntityListItem) => {
      if (item.highlighted) {
        item.highlighted = false
        item.selected = toSelectedList
      }
    })
    this.returnSelectedItems()
  }

  setupFancyMouseSelection(tag: any): void {
    tag.__isMouseDown = false

    const onMouseDownListener = (): boolean => {
      tag.__isMouseDown = true
      document.addEventListener('mouseup', () => {
        tag.__isMouseDown = false
      }, {once: true})
      return false
    }

    const onMouseMoveListener = ($event: any): boolean => {
      const isSelected = !($event.altKey || $event.metaKey || $event.shiftKey || $event.ctrlKey)
      if (tag.__isMouseDown && $event.target.classList.contains('entity-list-picker_entity')) {
        const itemIndex = $event.target.getAttribute('index')
        if (itemIndex > -1 && itemIndex < this.filteredEntitiesList.length) {
          this.filteredEntitiesList[itemIndex].highlighted = isSelected
          setTimeout(() => this.$scope.$digest())
        }
      }
      return false
    }

    tag.addEventListener('mousemove', onMouseMoveListener)
    tag.addEventListener('mousedown', onMouseDownListener)
  }

  $onInit (): void {
    this.saveListsDomNodes()
    if (this.delayedInitialisation) {
      this.toggleMouseHandlers()
    }
  }

  saveListsDomNodes(): void {
    this.availableListDomElement = this.$element[0].querySelector('.js-available-list')
    this.selectedListDomElement = this.$element[0].querySelector('.js-selected-list')
  }

  returnSelectedItems(): void {
    this.countSelectedItems()
    const listOfSelectedItemsIds = this.availableEntitiesList
      .filter((item: IEntityListItem) => item.selected)
      .map((item: IEntityListItem) => item.id)

    this.onChange({values: listOfSelectedItemsIds})
  }
}

export default {
  templateUrl: require('./entity-list-picker.pug'),
  controller: EntityListPicker,
  bindings: {
    isEditing: '<',
    onChange: '&',
    items: '<',
    hostClass: '@?',
  }
}
