import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const teamMemberService = new CrudService(endpoints.USER_MANAGEMENT_TEAM_MEMBER)
const teamMembersService = new CrudService(endpoints.USER_MANAGEMENT_TEAM_MEMBERS)

export default {
  create: teamMember => teamMemberService.create(teamMember),
  update: (teamMember, teamMemberId) => teamMemberService.update(teamMember, [teamMemberId]),
  get: teamMemberId => teamMemberService.get([teamMemberId], {populate: 'user,createdBy'}),
  getAll: () => teamMembersService.get()
}
