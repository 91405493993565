import StorefrontAppliedPromoCode from './StorefrontAppliedPromoCode'

export default class StorefrontDiscount {
  constructor(
    public promoCode: StorefrontAppliedPromoCode
  ) {}

  static build(dto: any): StorefrontDiscount {
    return new StorefrontDiscount(
      StorefrontAppliedPromoCode.build(dto.promoCode || {})
    )
  }
}
