import { StateService } from '@uirouter/core'
import StorefrontCategory from 'data/domain-objects/storefront/StorefrontCategory'
import BaseController from 'presentation/common/BaseController'
import storefrontCategoriesCollection from 'data/collections/storefrontCategoriesCollection'

const CATEGORY_STATE = 'main.storefront.categories.category'

class CategorySummaryController extends BaseController {
  public category: StorefrontCategory
  public businessId: string

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  viewCategory (categoryId: string): void {
    this.$state.go(CATEGORY_STATE, {
      categoryId,
      businessId: this.businessId
    })
  }

  moveChildCategoryUp(categoryIndex: number): void {
    const newIndex = categoryIndex - 1

    if (newIndex >= 0) {
      this.moveCategory(categoryIndex, newIndex)
    }
  }

  moveChildCategoryDown(categoryIndex: number): void {
    const newIndex = categoryIndex + 1

    if (newIndex < this.category.categories.length) {
      this.moveCategory(categoryIndex, newIndex)
    }
  }

  moveCategory (fromIndex: number, toIndex: number): void {
    this.category.categories.splice(toIndex, 0, this.category.categories.splice(fromIndex, 1)[0])

    this.isLoading = true
    storefrontCategoriesCollection.updateCategoriesOrder(this.businessId, this.category.categories.map((category: StorefrontCategory): string => category.id))
      .then((): void => {
        this.isLoading = false
        this.$scope.$digest()
      })
      .catch((error: Error): void => {
        this.logger.error('Cannot update categories order', error)
      })
  }
}

export default {
  templateUrl: require('./category-summary.pug'),
  controller: CategorySummaryController,
  bindings: {
    businessId: '<',
    category: '<',
    showMoveUp: '<',
    showMoveDown: '<',
    onMoveCategoryUp: '&',
    onMoveCategoryDown: '&',
  }
}
