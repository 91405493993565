import Place from 'data/domain-objects/Place'
import GeoJSONPolygon from 'data/domain-objects/GeoJSONPolygon'
import PlaceExit from 'data/domain-objects/PlaceExit'
import locationsService from 'data/services/locationsService'
import addressCollectionFactory from 'data/collections/addressCollection'
import BaseAddressTemplate from 'data/domain-objects/BaseAddressTemplate'
import AddressFormat from 'data/domain-objects/AddressFormat'
import AddressField from 'data/domain-objects/AddressField'
import LocationPoint from 'data/domain-objects/LocationPoint'
import { IMarkerConfig } from 'presentation/_core-forms/editable-list-area-polygon/editableListAreaPolygon'
import localeHelper from 'common/localeHelper'

const EXIT_MARKER_ICON = '/assets/images/pin-in.svg'

interface IMarkerScope extends ng.IScope {
  exit?: PlaceExit,
  isEditing?: boolean
}

class PlaceBoundsEditorController {
  // inputs
  public place: Place
  public placeExits: PlaceExit[]
  public isEditing: boolean
  public form: ng.IFormController

  // properties
  public originalBounds: GeoJSONPolygon
  public exitMarkers: IMarkerConfig[] = []
  public currentLanguage: string = localeHelper.getLanguage()

  constructor(
    private $scope: ng.IScope,
    private $compile: ng.ICompileService
  ) {
    this.$scope.$watch(() => localeHelper.getLocaleData(), (currentLocale: ILocaleData) => {
      this.currentLanguage = currentLocale.language
      this.generateArrayOfMarkers()
    })
  }

  $onChanges(simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.place) {
      this.originalBounds = GeoJSONPolygon.build(this.place.bounds)
    }

    if (simpleChanges.placeExits && this.placeExits) {
      this.generateArrayOfMarkers()
    }

    if (simpleChanges.hasOwnProperty('isEditing')) {
      this.exitMarkers.forEach((exitMarker: IMarkerConfig) => {
        if (exitMarker.additionalData) {
          const markerScope = exitMarker.additionalData.markerScope
          markerScope.isEditing = this.isEditing
          setTimeout(() => markerScope.$digest())
        }
      })
    }
  }

  public generateArrayOfMarkers(): void {
    this.exitMarkers = []
    this.placeExits.forEach((exit: PlaceExit) => {
      const exitMarker: IMarkerConfig = this.getExitMarkerConfig(exit)
      this.exitMarkers.push(exitMarker)
    })
  }

  private getExitMarkerConfig(exit: PlaceExit, openInfoWindow: boolean = false): IMarkerConfig {
    const markerScope: IMarkerScope = this.$scope.$new(true)
    markerScope.exit = exit
    markerScope.isEditing = this.isEditing
    const exitMarker: IMarkerConfig = {
      markerId: exit.id,
      latitude: exit.address[this.currentLanguage].location.latitude,
      longitude: exit.address[this.currentLanguage].location.longitude,
      icon: EXIT_MARKER_ICON,
      infoWindowContent: this.$compile('<exit-marker-info-window exit="exit" is-editing="isEditing"></exit-marker-info-window>')(markerScope)[0],
      infoWindowOpened: openInfoWindow,
      onLocationChanged: (longitude: number, latitude: number): void => {
        return this.onExitMarkerLocationChanged(longitude, latitude, markerScope)
      },
      additionalData: {
        markerScope // I put marker scope here such way because scope doesn't make sense to IMarkerConfig itself
      }
    }

    return exitMarker
  }

  public onExitMarkerLocationChanged(longitude: number, latitude: number, markerScope: IMarkerScope): void {
    const exit: PlaceExit = markerScope.exit
    exit.address[this.currentLanguage].location = new LocationPoint(latitude, longitude)
    markerScope.$digest()
    this.getAddress(exit.address[this.currentLanguage].location, markerScope)
  }

  public addNewExit(location: LocationPoint): void {
    const exit: PlaceExit = PlaceExit.build()
    exit.address[this.currentLanguage].location = LocationPoint.build(location)
    this.place.exits.push(exit)
    const openInfoWindow = true
    const exitMarker: IMarkerConfig = this.getExitMarkerConfig(exit, openInfoWindow)
    this.exitMarkers.push(exitMarker)
    this.$scope.$digest()

    this.getAddress(location, exitMarker.additionalData.markerScope)
  }

  private getAddress(location: LocationPoint, markerScope: IMarkerScope): void {
    Promise.all([
      locationsService.getAddressByLocation(location.longitude, location.latitude),
      addressCollectionFactory.getAddressFormats()
    ]).then((result: [BaseAddressTemplate, AddressFormat[]]) => {
      this.onAddressAndFormatsLoaded(result[0], result[1], markerScope)
    })
    .finally(() => {
      this.$scope.$digest()
    })
  }

  private onAddressAndFormatsLoaded(
    addressTemplate: BaseAddressTemplate,
    addressFormats: AddressFormat[],
    markerScope: IMarkerScope
  ): void {
    if (!addressTemplate.fields.find((field: AddressField) => field.name === 'exit')) {
      const currentAddressFormat = addressFormats.find((format: AddressFormat) => format.id === addressTemplate.addressFormatId)
      const exitFieldDefinition = currentAddressFormat.getFieldDefinitionReferrence('exit')

      if (exitFieldDefinition) {
        const exitField = new AddressField(exitFieldDefinition.id, 'exit', markerScope.exit.name[localeHelper.getLanguage()])
        addressTemplate.fields.push(exitField)
      }
    }

    markerScope.exit.address[this.currentLanguage].addressFormatId = addressTemplate.addressFormatId
    markerScope.exit.address[this.currentLanguage].fields = addressTemplate.fields
  }

  removeExit(exitId: string): void {
    const markerIndex = this.exitMarkers.findIndex((markerConfig: IMarkerConfig) => markerConfig.markerId === exitId)
    this.exitMarkers.splice(markerIndex, 1)

    const placeIndex = this.placeExits.findIndex((exit: PlaceExit) => exit.id === exitId)
    this.placeExits.splice(placeIndex, 1)
  }

  public setBounds(polygon: number[][][]): void {
    this.place.bounds = GeoJSONPolygon.build({coordinates: polygon})
  }
}

export default {
  templateUrl: require('./place-bounds-editor.pug'),
  controller: PlaceBoundsEditorController,
  bindings: {
    place: '<',
    placeExits: '<',
    isEditing: '<',
    form: '<'
  }
}
