import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Voucher from 'data/domain-objects/Voucher'
import VoucherValidation from 'data/domain-objects/VoucherValidation'

const voucherService = new CrudService(endpoints.VOUCHER)
const voucherValidateService = new CrudService(endpoints.VOUCHER_VALIDATE)
const voucherApplyService = new CrudService(endpoints.VOUCHER_APPLY)

export function vouchersCollectionFactory (voucherService, voucherValidateService, voucherApplyService) {
  const castVoucherValidation = voucherValidationDTO => VoucherValidation.build(voucherValidationDTO)

  const get = id => voucherService.get([id]).then(voucherDTO => Voucher.build(voucherDTO))

  const getAll = (params = {}) => {
    const searchParams = {}
    if (params.limit) {
      searchParams.limit = params.limit
    }
    if (params.skip) {
      searchParams.skip = params.skip
    }
    if (params.text) {
      searchParams.q = params.text
    }
    if (params.startDate) {
      searchParams.after = (new Date(params.startDate)).getTime()
    }
    if (params.endDate) {
      searchParams.before = (new Date(params.endDate)).getTime()
    }
    return voucherService.get([], searchParams)
  }

  const create = voucher => voucherService.create(voucher)

  const update = voucher => voucherService.update(voucher, [voucher.id])

  const deleteFn = id => voucherService.delete([id])

  const validate = (voucherCode, balanceAccountId = null) => {
    const params = {voucherCode}
    if (balanceAccountId) {
      params.balanceAccountId = balanceAccountId
    }
    return voucherValidateService.create(params).then(castVoucherValidation)
  }

  const applyFn = (voucherId, balanceAccountId) => {
    return voucherApplyService.update({
      balanceAccountId
    }, [voucherId])
  }

  return {
    get,
    getAll,
    create,
    update,
    'delete': deleteFn,
    validate,
    apply: applyFn
  }
}

export default vouchersCollectionFactory(voucherService, voucherValidateService, voucherApplyService)
