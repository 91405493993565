export default class Ride {
  constructor (
    public customerId: string,
    public pickupAddressId: string,
    public dropOffAddressId: string,
    public fleetId: string,
    public paymentMethod: string,
    public numberOfPassengers: number,
    public pickupTime: string,
    public balanceAccount: string,
    public id: string,
    public consumerFirstName: string,
    public consumerLastName: string,
    public originatingBranchId: string,
    public promoCode: string
  ) {}

  set consumerId(value: string) {
    this.customerId = value
  }

  static build(RideDTO: any): Ride {
    return new Ride(
      RideDTO.customerId || RideDTO.id || null,
      RideDTO.pickupAddressId || null,
      RideDTO.dropOffAddressId || null,
      RideDTO.fleetId || null,
      RideDTO.paymentMethod || '',
      RideDTO.numberOfPassengers || 1,
      RideDTO.pickupTime || '',
      RideDTO.balanceAccountId || '',
      RideDTO.id || '',
      RideDTO.consumerFirstName || '',
      RideDTO.consumerLastName || '',
      RideDTO.originatingBranchId || null,
      RideDTO.promoCode || ''
    )
  }

  toRideDTO(): any {
    const rideDTO: any = {
      customerId: this.customerId,
      pickupAddressId: this.pickupAddressId,
      dropOffAddressId: this.dropOffAddressId,
      fleetId: this.fleetId,
      originatingBranchId: this.originatingBranchId,
      paymentMethod: this.paymentMethod,
      consumerFirstName: this.consumerFirstName,
      consumerLastName: this.consumerLastName,
      promoCodeName: this.promoCode
    }
    if (this.pickupTime !== null) {
      rideDTO.pickupTime = this.pickupTime
    }
    if (this.balanceAccount) {
      rideDTO.balanceAccountId = this.balanceAccount
    }
    return rideDTO
  }
}
