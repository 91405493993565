import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

export default declareAppModule('logistics.tracking', (module: ng.IModule) => module
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'tracking',
      componentName: 'kanban',
      stateKey: 'TRACKING',
      requiresPermission: 'TRACKING',
      data: {
        titleKey: 'PAGES.TRACKING.TITLE'
      }
    })
  })
)
