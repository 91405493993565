/* globals google:false */
import isEqual from 'lodash-es/isEqual'
import difference from 'lodash-es/difference'

import googleMapsService, {GoogleMapsPoint, GoogleMapsOptions, GoogleMapsDrawingOptions} from 'data/services/googleMapsService'
import locationsService from 'data/services/locationsService'
import {Point, MathUtils} from 'common/utils/mathUtils'
import GeoJSONPolygon from 'data/domain-objects/GeoJSONPolygon'
import LocationPoint from 'data/domain-objects/LocationPoint'
import envConfig from 'common/envConfig'

declare const google

const VALIDITY_POLYGON_SELF_INTERSECTING = 'polygon is intersecting'
const VALIDITY_EMPTY_POLYGON = 'polygon is empty'
const MINIMUM_NUMBER_OF_VERTICES_IN_A_POLYGON = 3
const ADD_HOLE_MODE_CLASS = 'put-hole'
const REMOVE_HOLE_MODE_CLASS = 'remove-hole'
const ADD_MARKER_CLASS = 'add-marker'

export interface IMarkerConfig { // in case of using this interface somewhere else move it to declarations.d.ts
  readonly markerId: string
  readonly latitude: number,
  readonly longitude: number,
  readonly icon: string,
  readonly infoWindowContent: HTMLElement,
  readonly infoWindowOpened: boolean,
  readonly onLocationChanged: (longitude: number, latitude: number) => void,
  readonly additionalData?: {[key: string]: any}
}

enum EditingModes {
  addHole = 'ADD_HOLE',
  removeHole = 'REMOVE_HOLE',
  edgesTranform = 'EDGES_TRANSFORM',
  addMarker = 'ADD_MARKER'
}

interface IMarkerSpec {
  markerId: string,
  marker: any,
  infoWindow: any
}

class AreaMapController {
  constructor (
    private $scope: ng.IScope,
    private $element: ng.IAugmentedJQuery,
  ) {}

  // inputs
  public geoJsonPolygon: GeoJSONPolygon
  public originalPolygon: GeoJSONPolygon
  public markers: IMarkerConfig[]
  public addMarkerButtonTitle: string
  public isEditing: boolean
  public isRequired: boolean
  public labelKey: string
  public onPolygonUpdated: ({}: {polygon: number[][][]}) => {}
  public onAddMarkerRequested: (event: {location: LocationPoint}) => {}
  public onRemoveMarkerRequested: (event: {markerId: string}) => {}
  public form: ng.IFormController
  public centerMapCoordinates: LocationPoint
  public isFullWidth: boolean

  // properties
  private map: any = null
  private mapElement: HTMLElement
  private mapDrawingManager: any = null
  private polygonMapShape: any

  private mapMarkers: IMarkerSpec[] = []

  private tempInnerPolygonsWithRings: Array<{ring: any, polygon: any}> = []

  private polygonCompleteEventListener: () => {} = null
  private polygonMapShapeSetAtEventListener: () => {} = null
  private polygonMapShapeRemoveAtEventListener: () => {} = null
  private polygonMapShapeInsertAtEventListener: () => {} = null
  private polygonMapShapeDragStartEventListener: () => {} = null
  private polygonMapShapeDragEndEventListener: () => {} = null
  private polygonMapShapeRightClickEventListener: () => {} = null
  private polygonMapShapeMouseoverEventListener: () => {} = null
  private polygonMapShapeMouseoutEventListener: () => {} = null
  private polygonMapShapeClickEventListener: () => {} = null
  private windowResizedListener: () => {} = null

  private centerMarker: any = null

  private zoomChanged: boolean = false
  private errorEmpty: boolean = true
  private errorIntersection: boolean = false

  public polygonStates: GeoJSONPolygon[] = []
  public initialZoomLevel: number

  public editingMode: EditingModes = EditingModes.edgesTranform
  public editingModes: typeof EditingModes = EditingModes

  // -----------Life-Cycle hooks START-------------
  $onInit (): void {
    if (!this.mapElement) {
      this.init()
    }
  }

  $onChanges (simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.hasOwnProperty('geoJsonPolygon')) {
      if (!this.mapElement) {
        this.init()
      }

      if (!this.polygonMapShape && !!this.geoJsonPolygon) {
        this.initializePolygon()
      }
    }

    if (simpleChanges.hasOwnProperty('isEditing')) {
      if (!simpleChanges.isEditing.isFirstChange()) {
        this.resetSavedPolygonState()
        googleMapsService.setMapDrawable(this.map, this.mapDrawingManager, !!this.isEditing && !this.geoJsonPolygon.coordinates.length)
        if (this.polygonMapShape) {
          this.polygonMapShape.setEditable(!!this.isEditing)
        }
        if (!this.isEditing) {
          this.setEdgesTransformMode()
        }
        this.mapMarkers.forEach((markerSpec: IMarkerSpec) => {
          markerSpec.marker.setDraggable(this.isEditing)
        })
      }
    }

    if (simpleChanges.hasOwnProperty('centerMapCoordinates')) {
      if (this.map && this.centerMapCoordinates) {
        this.initializeCenterMarker()
      }
    }
  }

  $onDestroy (): void {
    this.removePolygonMapShapeEventListeners()
    google.maps.event.clearInstanceListeners(this.map)
    google.maps.event.removeListener(this.windowResizedListener)
  }
  // -------------Life-Cycle hooks END-------------

  init(): void {
    this.mapElement = this.$element[0].querySelector('.js_map_container')
    let centreGoogleMapsPoint
    if (this.centerMapCoordinates) {
      centreGoogleMapsPoint = new GoogleMapsPoint(this.centerMapCoordinates.latitude, this.centerMapCoordinates.longitude)
    } else {
      const defaultLocation = locationsService.getDefaultLocation()
      centreGoogleMapsPoint = new GoogleMapsPoint(defaultLocation.latitude, defaultLocation.longitude)
    }
    const mapOptions = new GoogleMapsOptions(this.isEditing, centreGoogleMapsPoint, true, true)
    this.map = new google.maps.Map(this.mapElement, mapOptions)
    this.initialZoomLevel = this.map.getZoom()
    this.addListeners()
    this.validatePolygon([])
    if (this.centerMapCoordinates) {
      this.initializeCenterMarker()
    }

    this.$scope.$watchCollection(() => this.markers, (newValue: IMarkerConfig[], oldValue: IMarkerConfig[]) => {
      if (Array.isArray(newValue) && Array.isArray(oldValue)) {
        const newMarkers: IMarkerConfig[] = difference(newValue, oldValue)
        const removedMarkers: IMarkerConfig[] = difference(oldValue, newValue)
        if (!newMarkers.length && !removedMarkers.length) {
          this.initMarkers()
        } else {
          newMarkers.forEach((markerConfig: IMarkerConfig, index: number) => this.initMarker(markerConfig, index))

          removedMarkers.forEach((markersConfig: IMarkerConfig) => {
            const mapMarkerIndex: number = this.mapMarkers.findIndex((markerSpec: IMarkerSpec) => markerSpec.markerId === markersConfig.markerId)
            if (mapMarkerIndex > -1) {
              this.mapMarkers[mapMarkerIndex].marker.setMap(null)
              this.mapMarkers.splice(mapMarkerIndex, 1)
            }
          })
        }
      } else {
        this.initMarkers()
      }
    })
  }

  initializeCenterMarker (): void {
    if (this.centerMarker) {
      this.centerMarker.setMap(null)
    }
    if (isNaN(this.centerMapCoordinates.latitude) && isNaN(this.centerMapCoordinates.longitude)) {
      this.centerMapCoordinates.latitude = envConfig.locations.defaultLocationLatitude,
      this.centerMapCoordinates.longitude = envConfig.locations.defaultLocationLongitude
    }
    this.map.setCenter(new GoogleMapsPoint(this.centerMapCoordinates.latitude, this.centerMapCoordinates.longitude))
    this.centerMarker = googleMapsService.addMarker(this.map, this.centerMapCoordinates.latitude, this.centerMapCoordinates.longitude)
  }

  addListeners (): void {
    this.windowResizedListener = google.maps.event.addDomListener(window, 'resize', () => {
      // resize event should ALWAYS fitMap even if user have changed zoom level
      this.zoomChanged = false
      this.fitMapToBounds()
    })
    this.mapDrawingManager = new google.maps.drawing.DrawingManager(new GoogleMapsDrawingOptions(this.map, this.isEditing))
    this.polygonCompleteEventListener = google.maps.event.addListener(
      this.mapDrawingManager,
      'polygoncomplete',
      (polygonShape: any) => this.onPolygonDrawn(polygonShape)
    )
    this.map.addListener('zoom_changed', () => this.onZoomChangedEvent())
    this.map.addListener('click', (event: any) => this.onMapClick(event))
    this.map.addListener('mousemove', (event: any) => this.onMapMouseMove())
  }

  initMarkers(): void {
    if (this.map) {
      this.mapMarkers.forEach((markerSpec: IMarkerSpec) => {
        markerSpec.marker.setMap(null)
      })
      this.mapMarkers = []
      this.markers.forEach((markerConfig: IMarkerConfig) => {
        this.initMarker(markerConfig)
      })
    }
  }

  initMarker(markerConfig: IMarkerConfig, markerIndex?: number): void {
    const marker = googleMapsService.addMarker(
      this.map,
      markerConfig.latitude,
      markerConfig.longitude,
      '', // title
      markerConfig.icon
    )

    marker.setDraggable(this.isEditing)

    const markerSpec: IMarkerSpec = {
      markerId: markerConfig.markerId,
      marker,
      infoWindow: new google.maps.InfoWindow({
        content: markerConfig.infoWindowContent
      })
    }
    const start = markerIndex || this.mapMarkers.length
    this.mapMarkers.splice(start, 0, markerSpec)
    if (markerConfig.infoWindowContent && markerConfig.infoWindowOpened) {
      this.openMarkerInfoWindow(marker)
    }

    this.attachMarkerEventListeners(marker, markerConfig)
  }

  // -------------Map Events callbacks START-----------------//
  onMapClick(event: any): void {
    this.onPolygonMapShapeClick(event)
  }

  onMapMouseMove(): void {
    if (this.editingMode === EditingModes.addMarker) {
      this.mapElement.classList.add(ADD_MARKER_CLASS)
    } else {
      this.mapElement.classList.remove(ADD_MARKER_CLASS)
    }
  }
  // -------------Map Events callbacks END-----------------//

  // -------------Polygon Events callbacks START-------------//
  onPolygonDrawn (polygonShape: any): void {
    const polygonCoordinates = polygonShape.getPath().getArray()
    if (MathUtils.isPolygonClockwise(polygonCoordinates.map((coordinate: any): Point => new Point(coordinate.lng(), coordinate.lat())))) {
      const newCoordinates = polygonCoordinates.reverse()
      polygonShape.setPath(newCoordinates)
    }
    this.polygonMapShape = polygonShape
    googleMapsService.setMapDrawable(this.map, this.mapDrawingManager, false)
    this.generateAreaPolygonFromPolygonMapShape()
  }

  onPolygonMapShapeDragStart (): void {
    // Dragend event generates set_at and insert_at events for each path,
    // so we remove them on dragstart and attach back on dragend to decrease the number of generated events
    google.maps.event.removeListener(this.polygonMapShapeSetAtEventListener)
    google.maps.event.remopolygonPathsveListener(this.polygonMapShapeInsertAtEventListener)
    google.maps.event.removeListener(this.polygonMapShapeRemoveAtEventListener)
  }

  onPolygonMapShapeDragEnd (): void {
    const polygonPaths = this.polygonMapShape.getPaths().getArray()
    polygonPaths.forEach((path: any, index: number) => {
      this.polygonMapShapeSetAtEventListener = google.maps.event.addListener(path, 'set_at', () => this.generateAreaPolygonFromPolygonMapShape())
      this.polygonMapShapeInsertAtEventListener = google.maps.event.addListener(path, 'insert_at', () => this.generateAreaPolygonFromPolygonMapShape())
      this.polygonMapShapeRemoveAtEventListener = google.maps.event.addListener(path, 'remove_at', () => this.generateAreaPolygonFromPolygonMapShape())
    })

    this.generateAreaPolygonFromPolygonMapShape()
  }

  onZoomChangedEvent (): void {
    this.zoomChanged = !!(this.initialZoomLevel !== this.map.getZoom())
  }

  // delete vertex from polygon
  onPolygonMapShapeRightClick (event: any): void {
    if (event.vertex === undefined) {
      return
    }
    const polygonMapShapePaths = this.polygonMapShape.getPaths().getArray()
    if (polygonMapShapePaths[event.path].length > MINIMUM_NUMBER_OF_VERTICES_IN_A_POLYGON) {
      polygonMapShapePaths[event.path].removeAt(event.vertex)
    }
    this.generateAreaPolygonFromPolygonMapShape()
  }

  onPolygonMapShapeClick(event: any): void {
    this.closeAllMarkersInfoWindows()
    if (this.editingMode === EditingModes.addMarker) {
      this.notifyParentToAddMarker(event.latLng.lat(), event.latLng.lng())
      this.toggleAddMarkerMode()
    }
  }
  // -------------Polygon Events callbacks END-------------

  // -------------polygonMapShape processing methods START-------------
  initializePolygon (): void {
    this.initPolygonMapShapeFromGeoJSON(this.geoJsonPolygon)

    this.fitMapToBounds()
    googleMapsService.setMapDrawable(this.map, this.mapDrawingManager, !!this.isEditing && !this.geoJsonPolygon.coordinates.length)
    this.validatePolygon(this.geoJsonPolygon.coordinates)
  }

  initPolygonMapShapeFromGeoJSON (polygon: GeoJSONPolygon): void {
    this.clearPolygonMapShape()
    this.polygonMapShape = googleMapsService.getGoogleMapsPolygonForGeoJSONPolygon(polygon)
    this.polygonMapShape.setMap(this.map)
    this.polygonMapShape.setEditable(!!this.isEditing && (this.editingMode === EditingModes.edgesTranform || this.editingMode === EditingModes.addHole))
    this.polygonMapShape.setDraggable(false)
    this.initInnerPolygons()
    if (!this.tempInnerPolygonsWithRings.length) {
      this.setEdgesTransformMode()
    }
    this.initPolygonMapShapeEventListeners()
  }

  initInnerPolygons(): void {
    this.tempInnerPolygonsWithRings.forEach((figure: {ring: any, polygon: any}) => {
      figure.polygon.setMap(null)
    })
    this.tempInnerPolygonsWithRings = []
    this.polygonMapShape.getPaths().forEach((ring: any, index: number) => {
      if (index === 0) {
        return
      }

      const innerPolygon = new google.maps.Polygon({
        paths: ring,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      })

      this.tempInnerPolygonsWithRings.push({ring, polygon: innerPolygon})

      google.maps.event.addListener(innerPolygon, 'click', (event: any) => {
        innerPolygon.setMap(null)

        const indexToRemove = this.polygonMapShape.getPaths().getArray().findIndex((el: any) => el === ring)
        if (indexToRemove > -1) {
          this.polygonMapShape.getPaths().removeAt(indexToRemove)
        }

        this.generateAreaPolygonFromPolygonMapShape()

      })

      google.maps.event.addListener(innerPolygon, 'mousemove', (event: any) => {
        if (this.isEditing && this.editingMode === EditingModes.removeHole) {
          this.mapElement.classList.add(REMOVE_HOLE_MODE_CLASS)
        }
      })

      google.maps.event.addListener(innerPolygon, 'mouseout', (event: any) => {
        this.mapElement.classList.remove(REMOVE_HOLE_MODE_CLASS)
      })

      innerPolygon.setMap(this.isEditing && this.editingMode === EditingModes.removeHole ? this.map : null)
    })
  }

  initPolygonMapShapeEventListeners (): void {
    if (this.polygonMapShape && this.polygonMapShape.getPath()) {
      const polygonPaths = this.polygonMapShape.getPaths().getArray()

      polygonPaths.forEach((path: any, index: number) => {

        this.polygonMapShapeSetAtEventListener = google.maps.event.addListener(path, 'set_at', () => this.generateAreaPolygonFromPolygonMapShape())
        this.polygonMapShapeInsertAtEventListener = google.maps.event.addListener(path, 'insert_at', () => this.generateAreaPolygonFromPolygonMapShape())
        this.polygonMapShapeRemoveAtEventListener = google.maps.event.addListener(path, 'remove_at', () => this.generateAreaPolygonFromPolygonMapShape())
      })
      this.polygonMapShapeMouseoverEventListener = google.maps.event.addListener(this.polygonMapShape, 'mousemove', (event: any) => {
        if (this.isEditing && this.editingMode === EditingModes.addHole && !event.path && !event.vertex && !event.edge) {
          this.mapElement.classList.add(ADD_HOLE_MODE_CLASS)
        }
      })
      this.polygonMapShapeMouseoutEventListener = google.maps.event.addListener(this.polygonMapShape, 'mouseout', (event: any) => {
        this.mapElement.classList.remove(ADD_HOLE_MODE_CLASS)
      })

      this.polygonMapShapeDragStartEventListener = google.maps.event.addListener(
        this.polygonMapShape,
        'dragstart',
        () => this.onPolygonMapShapeDragStart()
      )

      this.polygonMapShapeDragEndEventListener = google.maps.event.addListener(
        this.polygonMapShape,
        'dragend',
        () => this.onPolygonMapShapeDragEnd()
      )

      this.polygonMapShapeRightClickEventListener = google.maps.event.addListener(
        this.polygonMapShape,
        'rightclick',
        (event: any) => this.onPolygonMapShapeRightClick(event)
      )

      this.polygonMapShapeClickEventListener = google.maps.event.addListener(
        this.polygonMapShape,
        'click',
        (event: any) => {
          if (!this.isEditing) {
            return
          }

          if (this.editingMode === EditingModes.addHole) {
            if (event.vertex || event.path) {
              return
            }
            const triangle = googleMapsService.getPolygonTriangle(
              this.polygonMapShape,
              event.latLng,
              0.8
            )

            polygonPaths.push(new google.maps.MVCArray(triangle))
            this.generateAreaPolygonFromPolygonMapShape()
          } else if (this.editingMode === EditingModes.addMarker) {
            this.onPolygonMapShapeClick(event)
          }

        }
      )
    }
  }

  removePolygonMapShapeEventListeners (): void {
    google.maps.event.removeListener(this.polygonMapShapeSetAtEventListener)
    google.maps.event.removeListener(this.polygonMapShapeInsertAtEventListener)
    google.maps.event.removeListener(this.polygonMapShapeRemoveAtEventListener)
    google.maps.event.removeListener(this.polygonMapShapeDragStartEventListener)
    google.maps.event.removeListener(this.polygonMapShapeDragEndEventListener)
    google.maps.event.removeListener(this.polygonMapShapeRightClickEventListener)
    google.maps.event.removeListener(this.polygonCompleteEventListener)
    google.maps.event.removeListener(this.polygonMapShapeMouseoverEventListener)
    google.maps.event.removeListener(this.polygonMapShapeMouseoutEventListener)
    google.maps.event.removeListener(this.polygonMapShapeClickEventListener)
  }

  clear (): void {
    this.errorEmpty = true
    this.errorIntersection = false
    this.setFormValidity()
    this.zoomChanged = false
    this.geoJsonPolygon = new GeoJSONPolygon()
    this.initializePolygon()
  }

  reset (): void {
    this.errorEmpty = false
    this.errorIntersection = false
    this.setFormValidity()
    this.zoomChanged = false
    this.geoJsonPolygon = new GeoJSONPolygon()
    this.geoJsonPolygon.coordinates = this.originalPolygon.coordinates.map((ring: number[][]) => ring.map((coordinate: number[]) => coordinate.slice()))
    this.resetSavedPolygonState()
    this.initializePolygon()
  }

  clearPolygonMapShape (): void {
    if (this.polygonMapShape) {
      this.removePolygonMapShapeEventListeners()
      this.polygonMapShape.setMap(null)
      this.polygonMapShape = null
    }
  }

  resetSavedPolygonState (): void {
    this.polygonStates = []
    this.savePolygonState(this.geoJsonPolygon)
  }

  restorePreviousState (): void {
    this.polygonStates.pop()
    const stateToBeRestored = this.polygonStates[this.polygonStates.length - 1]

    this.initPolygonMapShapeFromGeoJSON(stateToBeRestored)
    setTimeout(() => {
      this.updatePolygon(stateToBeRestored.coordinates)
    })
  }

  savePolygonState (polygon: GeoJSONPolygon): void {
    const lastSavedPolygon = this.polygonStates.length > 0 ? this.polygonStates[this.polygonStates.length - 1] : new GeoJSONPolygon()
    if (!isEqual(polygon, lastSavedPolygon)) {
      this.polygonStates.push(GeoJSONPolygon.build(polygon))
    }
  }

  generateAreaPolygonFromPolygonMapShape (): void {
    const polygon: number[][][] = []
    if (this.polygonMapShape) {
      for (const ring of this.polygonMapShape.getPaths().getArray()) {
        const path = []
        for (const point of ring.getArray()) {
          path.push([point.lng(), point.lat()])
        }
        polygon.push(path)
      }
    }
    this.savePolygonState(GeoJSONPolygon.build({coordinates: polygon}))
    this.updatePolygon(polygon)
  }

  updatePolygon (polygon: number[][][]): void {
    this.validatePolygon(polygon)
    if (!this.errorEmpty && !this.errorIntersection) {
      this.clearPolygonMapShape()
      this.onPolygonUpdated({polygon})
    } else if (this.errorIntersection) {
      this.polygonMapShape.setOptions({strokeColor: 'red', fillColor: 'red'})
    }
    if (this.errorEmpty || this.errorIntersection) {
      this.$scope.$digest()
    }
  }

  validatePolygon (polygon: number[][][]): void {
    this.errorEmpty = !polygon.length
    this.errorIntersection = false

    for (const ring of polygon) {
      if (MathUtils.isPolygonSelfIntersecting(ring.map((coordinate: number[]): Point => new Point(coordinate[1], coordinate[0])))) {
        this.errorIntersection = true
      }
    }
    this.setFormValidity()
  }

  // -------------polygonMapShape processing methods END-------------

  fitMapToBounds (): void {
    if (this.map && this.polygonMapShape && this.polygonMapShape.getPath() && !this.zoomChanged) {
      const latLngLiteralArray: Array<{ lat: number, long: number}> = this.polygonMapShape.getPath().getArray().map(
        (latLng: any) => {
          return {lat: latLng.lat(), long: latLng.lng()}
        }
      )
      googleMapsService.fitMapToBounds(this.map, latLngLiteralArray)
    }
  }

  setFormValidity (): void {
    this.form.$setValidity(VALIDITY_POLYGON_SELF_INTERSECTING, !this.errorIntersection, null)
    this.form.$setValidity(VALIDITY_EMPTY_POLYGON, !this.errorEmpty, null)
    setTimeout(() => this.$scope.$apply()) // we DO need apply here to propagate changes to parent components
  }

  toggleAddHoleMode(): void {
    if (this.editingMode === EditingModes.addHole) {
      this.setEdgesTransformMode()
    } else {
      this.editingMode = EditingModes.addHole
      this.polygonMapShape.setEditable(true)
      // remove from map
      this.hideInnerPolygons()
    }
  }

  toggleRemoveHoleMode(): void {
    if (this.editingMode === EditingModes.removeHole) {
      this.setEdgesTransformMode()
    } else {
      this.editingMode = EditingModes.removeHole
      this.polygonMapShape.setEditable(false)
      this.showInnerPolygons()
    }
  }

  setEdgesTransformMode(): void {
    this.editingMode = EditingModes.edgesTranform
    this.polygonMapShape.setEditable(this.isEditing)
    this.hideInnerPolygons()
  }

  toggleAddMarkerMode(): void {
    if (this.editingMode === EditingModes.addMarker) {
      this.setEdgesTransformMode()
    } else {
      this.setEdgesTransformMode()

      this.polygonMapShape.setEditable(false)
      this.editingMode = EditingModes.addMarker
    }
  }

  showInnerPolygons(): void {
    this.tempInnerPolygonsWithRings.forEach((figure: {ring: any, polygon: any}) => {
      figure.polygon.setMap(this.map)
    })
  }

  hideInnerPolygons(): void {
    this.tempInnerPolygonsWithRings.forEach((figure: {ring: any, polygon: any}) => {
      figure.polygon.setMap(null)
    })
  }

  // this function actually doesn't add marker
  // it only notifies parent which then should push marker to `markers` array
  // after that watcher catch this change and process markers
  notifyParentToAddMarker(latitude: number, longitude: number): void {
    this.closeAllMarkersInfoWindows()
    this.onAddMarkerRequested({location: LocationPoint.build({latitude, longitude})})
    this.$scope.$apply()
  }

  attachMarkerEventListeners(marker: any, markerConfig: IMarkerConfig): void {
    marker.addListener('rightclick', (event: any) => {
      this.closeAllMarkersInfoWindows()
      if (this.isEditing) {
        this.onRemoveMarkerRequested({markerId: markerConfig.markerId})
        this.$scope.$apply()
      }
    })

    marker.addListener('dragend', (event: any) => {
      markerConfig.onLocationChanged(event.latLng.lng(), event.latLng.lat())
      this.openMarkerInfoWindow(marker)
    })

    marker.addListener('dragstart', (event: any) => {
      this.closeAllMarkersInfoWindows()
    })

    marker.addListener('click', (event: any) => {
      this.closeAllMarkersInfoWindows()
      this.openMarkerInfoWindow(marker)
    })
  }

  openMarkerInfoWindow(marker: any): void {
    const currentMarkerSpec = this.mapMarkers.find((markerSpec: IMarkerSpec) => markerSpec.marker === marker)
    if (currentMarkerSpec) {
      currentMarkerSpec.infoWindow.open(this.map, marker)
    }
  }

  closeMarkerInfoWindow(marker: any): void {
    const currentMarkerSpec = this.mapMarkers.find((markerSpec: IMarkerSpec) => markerSpec.marker === marker)
    if (currentMarkerSpec) {
      currentMarkerSpec.infoWindow.close()
    }
  }

  closeAllMarkersInfoWindows(): void {
    this.mapMarkers.forEach((markerSpec: IMarkerSpec) => {
      markerSpec.infoWindow.close()
    })
  }

}

export default {
  templateUrl: require('./editable-list-area-polygon.pug'),
  controller: AreaMapController,
  bindings: {
    geoJsonPolygon: '<',
    isEditing: '<',
    isRequired: '<',
    markers: '<',
    addMarkerButtonTitle: '@',
    labelKey: '@',
    onPolygonUpdated: '&',
    onAddMarkerRequested: '&',
    onRemoveMarkerRequested: '&',
    originalPolygon: '<?',
    form: '<',
    centerMapCoordinates: '<?',
    isFullWidth: '<?'
  }
}
