import templateUrl from './driver-details.pug'
import DriverOverviewDataService from 'presentation/logistics-drivers/driver-details/driver-overview/driverOverviewData'
import Logger from 'common/Logger'
const logger = new Logger('DriverPopup')
import envConfig from 'common/envConfig'

class DriverDetails {
  constructor ($scope, $stateParams) {
    this.$scope = $scope
    this.$stateParams = $stateParams
    this.driver = null
  }

  $onInit () {
    this.driverId = this.$stateParams.driverId || null
    this.loadDriverData()
  }

  $onDestroy () {
    this.driver = null
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  scheduleUpdate () {
    this.refreshTimeout = setTimeout(() => {
      if (this.driverId !== null) {
        this.loadDriverData(this.driverId)
      }
    }, envConfig.driversRefreshInterval)
  }

  loadDriverData () {
    DriverOverviewDataService.getDriverInfo(this.driverId)
      .then(data => this.driver = data)
      .catch(err => logger.error('Coulnd\'t load driver info for driver id', this.driverId, err))
      .then(() => {
        this.$scope.$digest()
        this.scheduleUpdate()
      })
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$stateParams', DriverDetails]
}

