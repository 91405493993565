import PhoneNumber from 'data/domain-objects/PhoneNumber'
import Consumer from 'data/domain-objects/Consumer'
import Address from 'data/domain-objects/Address'
import BaseController from 'presentation/common/BaseController'
import consumerCollection from 'data/collections/consumerCollection'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'
import addressCollection from 'data/collections/addressCollection'
import { StateService } from '@uirouter/core'

class ConsumerSearchFormController extends BaseController {

  public searchModel: PhoneNumber = PhoneNumber.build({})

  // bindings
  public autoCreateConsumer: boolean
  public countryDiallingCode: string
  public phoneNumber: string
  public isAddingNewAddress: boolean
  public onConsumerFound: (payload: {consumer: Consumer}) => void
  public onConsumerNotFound: (payload: {phoneNumber: PhoneNumber}) => void

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $element: ng.IAugmentedJQuery = null
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    if (this.countryDiallingCode && this.phoneNumber) {
      this.searchModel.phoneNumber = this.phoneNumber
      this.searchModel.countryDiallingCode = this.countryDiallingCode
      this.submit()
    } else {
      this.getDialingCodes()
    }
  }

  getDialingCodes (): void {
    this.isLoading = true
    diallingCodesCollection.getDefault()
      .then((codes: string) => {
        this.searchModel.countryDiallingCode = codes
      })
      .catch((err: Error) => this.logger.error('Cannot get default country dialling code', err))
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  submit (): void {
    if (this.searchModel.phoneNumber && this.searchModel.phoneNumber.trim().length > 0) {
      if (typeof this.isAddingNewAddress !== 'undefined') {
        this.isAddingNewAddress = false
      }
      this.sync()
    }
  }

  onSync (): Promise<void> {
    const getConsumerPromise = this.autoCreateConsumer
      ? consumerCollection.searchOrCreateByPhoneNumberDO(this.searchModel)
      : consumerCollection.getConsumerByPhoneNumberDO(this.searchModel)

    return getConsumerPromise
      .then((response: Consumer[]) => {
        this.handleSearchResults(response)
      })
      .catch((err: Error) => this.logger.error('Cannot perform a consumer search', err))
  }

  handleSearchResults (consumers: Consumer[]): void {
    if (consumers.length > 0) {
      const consumer = consumers[0]
      this.getConsumerWithAppliedAddressTypes(consumer)
        .then((consumer: Consumer) => this.onConsumerFound({consumer}))
    } else {
      this.onConsumerNotFound({phoneNumber: this.searchModel})
    }
  }

  getConsumerWithAppliedAddressTypes (consumer: Consumer): Promise<any> {
    return addressCollection.getGivenAddressesWithAddressFormats(consumer.addresses)
      .then((addressesWithAppliedTypes: Address[]) => Object.assign(consumer, {addresses: addressesWithAppliedTypes}))
      .catch((err: Error) => this.logger.error('Cannot apply address formats to consumer addresses', err))
  }
}

export default {
  templateUrl: require('./consumer-search-form.pug'),
  controller: ConsumerSearchFormController,
  bindings: {
    onConsumerFound: '&',
    onConsumerNotFound: '&',
    isAddingNewAddress: '=?',
    phoneNumber: '<?',
    countryDiallingCode: '<?',
    autoCreateConsumer: '<?'
  }
}
