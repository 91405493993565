import set from 'lodash/set'
import {StateService, StateParams} from '@uirouter/core'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import storefrontCategoriesCollection from 'data/collections/storefrontCategoriesCollection'
import permissionsModel from 'data/models/permissionsModel'
import StorefrontCategory, { StorefrontDisplayLogoOptions } from 'data/domain-objects/storefront/StorefrontCategory'

const CATEGORY_ID_PARAM_NAME = 'categoryId'
const BUSINESS_ID_PARAM_NAME = 'businessId'

const CATEGORY_VIEW_STATE = 'main.storefront.categories.category'

class CategoryFormController extends CreateViewEditFormController {
  public businessId: string = ''
  public showSaveButton: boolean = false
  public availableParentIds: string[] = []
  public availableParentsNamesById: any = {}
  public disallowedParentIdsMap: any = {}
  public displayLogoValues: StorefrontDisplayLogoOptions[] = [
    StorefrontDisplayLogoOptions.NOT_DISPLAYING,
    StorefrontDisplayLogoOptions.ROUND,
    StorefrontDisplayLogoOptions.SQUARE
  ]

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams,
  ) {
    super($scope, $state, $stateParams, CATEGORY_ID_PARAM_NAME)
  }

  $onInit (): void {
    this.businessId = this.$stateParams[BUSINESS_ID_PARAM_NAME]
    super.$onInit()
    this.showSaveButton = this.isCreate ? permissionsModel.getHasPermission('STOREFRONT_CREATE') : permissionsModel.getHasPermission('STOREFRONT_UPDATE')

    if (this.isCreate) {
      this.getAvailableParents()
    }
  }

  getEntity (): Promise<StorefrontCategory> {
    return storefrontCategoriesCollection.getById(
      this.businessId,
      this.$stateParams[CATEGORY_ID_PARAM_NAME]
    ).then((category: StorefrontCategory): StorefrontCategory => {
      this.backendModel = StorefrontCategory.build(category)
      this.setDefaultModel()
      this.getAvailableParents()
      return this.backendModel
    })
  }

  getAvailableParents(): Promise<StorefrontCategory[] | void> {
    return storefrontCategoriesCollection.getAll(this.businessId, null)
      .then((response: StorefrontCategory[]): void => {
        response.forEach((category: StorefrontCategory): void => { this.addCategoryToParentsOptions(category) })
        this.$scope.$digest()
      })
  }

  addCategoryToParentsOptions(category: StorefrontCategory, prefix: string = ''): void {
    this.availableParentIds.push(category.id)
    this.availableParentsNamesById[category.id] = `${prefix} ${category.name.getLocalized()}`
    category.categories.forEach((category: StorefrontCategory): void => { this.addCategoryToParentsOptions(category, `${prefix}—`) })

    if (this.model.hasChildCategoryById(category.id) || this.model.id === category.id) {
      this.disallowedParentIdsMap[category.id] = true
    }
  }

  getDefaultModel (): StorefrontCategory {
    return StorefrontCategory.build(this.backendModel || {})
  }

  beforeSubmit (): Promise<any> {
    const categoryModel = StorefrontCategory.build(this.model)

    return this.isCreate ?
      storefrontCategoriesCollection.create(this.businessId, categoryModel) :
      storefrontCategoriesCollection.update(this.businessId, this.entityId, categoryModel)
  }

  afterSubmitSuccess (category: StorefrontCategory): Promise<any> {
    const itWasSuccessfullCreate = this.isCreate
    return super.afterSubmitSuccess(category)
      .then(() => {
        if (itWasSuccessfullCreate) {
          this.$state.go(CATEGORY_VIEW_STATE, {
            businessId: this.businessId,
            categoryId: category.id
          })
        }
      })
  }

  onBusinessHoursChanged(value: any, form: ng.IFormController): void {
    this.model.businessHours = value

    if (form) {
      form.$setDirty()
    }
  }

  onImageUploaded(fieldPath: string, imageUrl: string): void {
    set(this.model.appearance, fieldPath, imageUrl)
    this.$scope.$digest()
  }
}

export default {
  controller: CategoryFormController,
  templateUrl: require('./category-form.pug')
}
