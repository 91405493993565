import get from 'lodash-es/get'
import templateUrl from './pickup-time.pug'
import BaseController from 'presentation/common/BaseController'

class PickupTimeController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.showAsap = true
    this.showInMinutes = true
    this.showDateTime = true
  }

  $onChanges (changeDescriptors) {
    if (changeDescriptors.settings) {
      this.showAsap = get(this.settings, 'showPickupTimeASAP.value', true)
      this.showInMinutes = get(this.settings, 'showPickupTimeInMinutes.value', true)
      this.showDateTime = get(this.settings, 'showPickupDateTime.value', true)
    }
  }
}

export default {
  templateUrl,
  controller: PickupTimeController,
  bindings: {
    form: '=',
    requestedPickupTime: '<',
    onRequestedPickupTimeChanged: '&',
    settings: '<',
    isDatePickerOpen: '=',
    defaultPickupTimeInMinutes: '<'
  }
}
