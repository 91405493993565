import templateUrl from './card-picker-item.pug'

export default {
  templateUrl,
  transclude: true,
  require: {
    picker: '^cardPicker'
  },
  bindings: {
    value: '<'
  }
}
