import templateUrl from './business-settings.pug'
import businessesCollection from 'data/collections/businessesCollection'
import SettingsController from 'presentation/businesses/common/SettingsController'

class BusinessSettingsController extends SettingsController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
  }

  $onInit () {
    this.entityId = this.$stateParams.businessId
    super.$onInit()
  }

  /**
   * this.submit() has a very sophisticated promise chain and will always
   * catch all errors and return resolved promise
   *
   * So here we actually check if saving setting failed and return rejected
   * promise if needed
   */
  onSubmitHandler () {
    return this.submit()
      .then(() => {
        return (this.updateSettingsFailed) ? Promise.reject() : Promise.resolve()
      })
  }

  getEntityName () {
    return businessesCollection.get(this.entityId)
      .then(business => business.name)
  }

  getSettings () {
    return businessesCollection.getBusinessSettings(this.entityId)
  }

  beforeSubmit () {
    return businessesCollection.updateBusinessSettings(this.entityId, this.model)
  }
}

export default {
  templateUrl,
  controller: BusinessSettingsController
}
