import templateUrl from './schedule-form.pug'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import areasCollection from 'data/collections/areasCollection'
import collectEntityFieldByIds from 'data/common/collectByIds'
import tariffPeriodsCollection from 'data/collections/tariffPeriodsCollection'
import scheduleCollection from 'data/collections/scheduleCollection'

class ScheduleFormController extends CreateViewEditFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state, $stateParams, 'scheduleId')
    this.getDependencies()
  }

  getDependencies () {
    Promise.all([
      this.getAreas(),
      this.getTimePeriods()
    ]).then(() => this.$scope.$digest())
  }

  getAreas () {
    return areasCollection.getAllCached()
      .then(areas => this.handleGetAreasSuccess(areas))
      .catch(err => this.logger.error('Cannot get areas', err))
  }

  handleGetAreasSuccess (areas) {
    this.areasIds = areas.map(area => area.id)
    this.areasIdsByName = collectEntityFieldByIds(areas)
  }

  getTimePeriods () {
    return tariffPeriodsCollection.getAll()
      .then(timePeriods => this.handleGetTimePeriodsSuccess(timePeriods))
      .catch(err => this.logger.error('Cannot get time periods', err))
  }

  handleGetTimePeriodsSuccess (timePeriods) {
    this.timePeriodsIds = timePeriods.map(timePeriod => timePeriod.id)
    this.timePeriodsIdsByName = collectEntityFieldByIds(timePeriods)
  }

  getEntity () {
    return scheduleCollection.getById(this.entityId)
      .then(entity => {
        this.backendModel = entity
        this.setDefaultModel()
      })
  }

  beforeSubmit () {
    return (this.isCreate) ? scheduleCollection.create(this.model) : scheduleCollection.update(this.model.id, this.model)
  }
}

export default {
  templateUrl,
  controller: ScheduleFormController
}
