import templateUrl from './request.pug'
import BaseController from 'presentation/common/BaseController'
import requestsCollection from 'data/collections/requestsCollection'

class RequestController extends BaseController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.request = null
  }

  $onInit () {
    if (this.$stateParams.requestId) {
      this.requestId = this.$stateParams.requestId
      this.getRequest()
    }
  }

  getRequest () {
    return requestsCollection.getRequestToConfirm(this.requestId)
      .then(request => this.handleRequestReceived(request))
      .catch(error => this.logger.error('Could not retrieve request', error))
  }

  handleRequestReceived (request) {
    this.request = request
    this.$scope.$digest()
  }
}

export default {
  templateUrl,
  controller: RequestController
}
