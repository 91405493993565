import templateUrl from './issues-table.pug'
import * as issueTypes from 'common/constants/IssueTypes'
import * as issueLevels from 'common/constants/IssueLevels'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import {RESOLVE_ISSUE_DIALOG_EVENT, SHOW_DRIVER_CHANGES_HISTORY_EVENT} from 'common/constants/IssueEvents'
import {DRIVER_OVERVIEW_OPEN_POPUP_EVENT} from 'common/constants/PopupEvents'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'

class IssuesTableController {
  constructor ($scope, $filter) {
    this.$scope = $scope
    this.$filter = $filter
    this.issueTypes = issueTypes
    this.issueLevels = issueLevels
    this.issuesTableRows = []
  }

  $onInit () {
    this.formatters = this.getFormatters()
  }

  $onChanges (simpleChanges) {
    if (simpleChanges.hasOwnProperty('issues')) {
      this.buildIssuesTableRows()
    }
  }

  /* -----EVENT HANDLERS START----- */
  handleDialogSuccessfulIssueAction () {
    this.onIssueActionConfirmSucceeded()
  }

  handleDialogFailedIssueAction (error) {
    this.onIssueActionConfirmFailed({error})
  }

  handleDialogIssueResolved () {
    this.onIssueResolved()
  }
  /* -----EVENT HANDLERS END----- */

  /* -----FORMATTING LOGIC START----- */
  buildIssuesTableRows () {
    this.issuesTableRows = this.issues.map(issue => {
      if (this.areResolved) {
        return this.getResolvedRow(issue)
      } else {
        return this.getUnresolvedRow(issue)
      }
    })
  }

  getUnresolvedRow (issue) {
    return {
      id: issue.id,
      dateTime: {
        level: issue.level,
        dateTime: issue.timestamp
      },
      issue: issue.type,
      draewilId: Object.assign({}, issue.delivery, {
        showDeliveryPopup: deliveryId => this.showDeliveryPopup(deliveryId)
      }),
      driver: {
        initialDriver: issue.driver,
        currentDriver: issue.delivery.driver || issue.driver,
        hasDriverChanged: IssuesTableController.checkIfDriverHasBeenChanged(issue),
        showDriversHistoryPopup: () => this.onShowDriversHistoryPopup(issue.driver, issue.delivery.driver),
        openDriverPopup: driver => this.openDriverPopup(driver)
      },
      fleet: issue.fleet.name,
      lastKnownLocationUpdateTime: issue.driver.lastSeenAt,
      // lastKnownChargePercentage: issue.driver.lastKnownChargePercentage, // NB. will be turned back when BE will provide real data for this column
      actions: {
        resolve: () => this.resolve(issue.id),
        moveToNotReady: () => this.moveToNotReady(issue.delivery.id),
        cancelDelivery: () => this.cancelDelivery(issue.delivery.id),
        transferDelivery: () => this.transferDelivery(issue.delivery.id),
        markAsPickedUp: () => this.markAsPickedUp(issue.delivery.id),
        markAsDelivered: () => this.markAsDelivered(issue.delivery.id),
        rehail: () => this.rehail(issue.delivery.id),
        deliveryActionsVisibility: IssuesTableController.getDeliveryActionsVisibility(issue.delivery)
      }
    }
  }

  getResolvedRow (issue) {
    return {
      id: issue.id,
      dateTime: {
        level: issue.level,
        dateTime: issue.timestamp
      },
      issue: issue.type,
      draewilId: Object.assign({}, issue.delivery, {
        showDeliveryPopup: deliveryId => this.showDeliveryPopup(deliveryId)
      }),
      driver: {
        initialDriver: issue.driver,
        currentDriver: issue.delivery.driver || issue.driver,
        hasDriverChanged: IssuesTableController.checkIfDriverHasBeenChanged(issue),
        showDriversHistoryPopup: () => this.onShowDriversHistoryPopup(issue.driver, issue.delivery.driver),
        openDriverPopup: driver => this.openDriverPopup(driver)
      },
      fleet: issue.fleet.name,
      resolved: {
        resolver: issue.resolver,
        resolvedAt: issue.timestampResolved
      }
    }
  }

  getFormatters () {
    if (this.areResolved) {
      return {
        dateTime: () => '',
        issue: issueType => this.$filter('translate')(`COMMON.ISSUE_TYPES.${issueType}`),
        draewilId: () => '',
        driver: () => '',
        fleet: fleetName => fleetName ? fleetName : '-',
        resolved: () => ''
      }
    } else {
      return {
        dateTime: () => '',
        draewilId: () => '',
        driver: () => '',
        fleet: fleetName => fleetName ? fleetName : '-',
        issue: issueType => this.$filter('translate')(`COMMON.ISSUE_TYPES.${issueType}`),
        lastKnownLocationUpdateTime: value => this.$filter('specificTimeTo')(value, true, true),
        // lastKnownChargePercentage: value => value ? `${value}%` : '-', // NB. see the comment above
        actions: () => ''
      }
    }
  }

  resolve (issueId) {
    this.$scope.$emit(RESOLVE_ISSUE_DIALOG_EVENT, issueId)
  }

  moveToNotReady (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.MARK_PENDING, {id: deliveryId})
  }

  cancelDelivery (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.CANCEL, {id: deliveryId})
  }

  transferDelivery (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.CHANGE_DRIVER, {id: deliveryId})
  }

  markAsPickedUp (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.MARK_COLLECTED, {id: deliveryId})
  }

  markAsDelivered (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.MARK_DELIVERED, {id: deliveryId})
  }

  rehail (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.REHAIL, {id: deliveryId})
  }

  onShowDriversHistoryPopup (initialDriver, currentDriver) {
    this.$scope.$emit(SHOW_DRIVER_CHANGES_HISTORY_EVENT, {initialDriver, currentDriver})
  }

  showDeliveryPopup (deliveryId) {
    this.$scope.$emit(DELIVERY_ACTIONS.VIEW, {id: deliveryId})
  }

  openDriverPopup (driver) {
    this.$scope.$emit(DRIVER_OVERVIEW_OPEN_POPUP_EVENT, driver.id)
  }
  /* -----FORMATTING LOGIC END----- */

  static getDeliveryActionsVisibility (delivery) {
    const checkStatusMatch = (...args) => args.indexOf(delivery.status) >= 0

    const displayConditions = {
      moveToNotReady: checkStatusMatch(DELIVERY_STATUSES.PENDING, DELIVERY_STATUSES.ALLOCATING, DELIVERY_STATUSES.ALLOCATED, DELIVERY_STATUSES.AT_PICKUP),
      cancelDelivery: !!delivery.id && delivery.status !== DELIVERY_STATUSES.DELIVERED && delivery.status !== DELIVERY_STATUSES.CANCELLED,
      transferDelivery: checkStatusMatch(DELIVERY_STATUSES.PENDING, DELIVERY_STATUSES.ALLOCATING, DELIVERY_STATUSES.ALLOCATED, DELIVERY_STATUSES.AT_PICKUP),
      markAsPickedUp: checkStatusMatch(DELIVERY_STATUSES.ALLOCATED, DELIVERY_STATUSES.AT_PICKUP),
      markAsDelivered: !!delivery.id && delivery.status !== DELIVERY_STATUSES.DELIVERED,
      rehail: checkStatusMatch(DELIVERY_STATUSES.CANCELLED, DELIVERY_STATUSES.ISSUE)
    }

    displayConditions.shouldDropdownWillBeShown = Object.keys(displayConditions).reduce((accum, key) => accum || displayConditions[key], false)

    return displayConditions
  }

  static checkIfDriverHasBeenChanged (issue) {
    if (issue.driver && issue.delivery && issue.delivery.driver) {
      return issue.driver.id !== issue.delivery.driver.id
    }

    return false
  }
}

export default {
  templateUrl,
  controller: IssuesTableController,
  bindings: {
    issues: '<',
    areResolved: '<',
    onIssueResolved: '&',
    onIssueActionConfirmSucceeded: '&',
    onIssueActionConfirmFailed: '&'
  }
}
