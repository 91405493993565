import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'

import surgesCollection from 'data/collections/surgesCollection'
import Surge from 'data/domain-objects/Surge'
import Area from 'data/domain-objects/Area'
import Fleet from 'data/domain-objects/Fleet'

interface ISurgeRow {
  order: any,
  pickUp: any,
  fleets: any,
  multiplier: any,
  status: any,
  actions: any
}

class SurgePricingDashboardController extends BaseController {
  public surges: Surge[] = []

  public rows: ISurgeRow[] = []
  public formatters: ISurgeRow = null

  public isEnabled: boolean = false
  public showLoadingFailedError: boolean = false
  public showSettingsUpdateError: boolean = false
  public showSurgeUpdateError: boolean = false

  constructor(
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.sync()
    this.formatters = this.getFormatters()
  }

  onSync (): Promise<any> {
    return Promise.all([
      this.getSurges(),
      this.getSurgePricingSettings()
    ]).catch((error: Error): void => {
      this.logger.error('Cannot get surges', error)
      this.showLoadingFailedError = true
    })
  }

  private getSurgePricingSettings (): Promise<void> {
    return surgesCollection.getSettings()
      .then((settings: ISurgePricingSettings): void => { this.isEnabled = settings.enabled })
  }

  private getSurges (): Promise<void> {
    return surgesCollection.getSurges()
      .then((surges: Surge[]): void => { this.handleSurgesLoaded(surges) })
  }

  private handleSurgesLoaded(surges: Surge[]): void {
    this.surges = surges
    this.rows = surges.map((surge: Surge, index: number): ISurgeRow => {
      return {
        order: {
          index,
          isFirst: index === 0,
          isLast: index === surges.length - 1,
          moveUp: (): void => this.movePriceSurgeUp(index),
          moveDown: (): void => this.movePriceSurgeDown(index)
        },
        pickUp: surge.pickupAreas,
        fleets: surge.fleets,
        multiplier: surge.multiplier,
        status: {
          isEnabled: surge.enabled,
          onStatusChanged: (isEnabled: boolean): void => {
            this.showSurgeUpdateError = false
            surgesCollection.setSurgeStatus(surge.id, isEnabled)
              .catch((error: Error) => {
                this.logger.error('Cannot update price surge status', error)
                this.showSurgeUpdateError = true
                this.$scope.$digest()
              })
          }
        },
        actions: {
          surgeId: surge.id,
          viewPriceSurge: (): void => { this.$state.go('main.logistics.surge-pricing.price-surge', { surgeId: surge.id }) }
        }
      }
    })
  }

  movePriceSurgeUp (index: number): void {
    const newIndex = index - 1

    if (newIndex >= 0) {
      this.movePriceSurge(index, newIndex)
    }
  }

  movePriceSurgeDown (categoryIndex: number): void {
    const newIndex = categoryIndex + 1

    if (newIndex < this.surges.length) {
      this.movePriceSurge(categoryIndex, newIndex)
    }
  }

  movePriceSurge (fromIndex: number, toIndex: number): void {
    this.surges.splice(toIndex, 0, this.surges.splice(fromIndex, 1)[0])
    this.handleSurgesLoaded(this.surges)
    this.updateSurgePricingSettings()
  }

  private getFormatters(): ISurgeRow {
    return {
      order: (): null => null,
      pickUp: (): null => null,
      fleets: (): null => null,
      multiplier: (multiplier: number): string => `${multiplier.toFixed(2)}x`,
      status: (): null => null,
      actions: (): null => null
    }
  }

  public onSurgePricingSettingsChange(isEnabled: boolean): void {
    this.isLoading = true
    this.isEnabled = isEnabled
    this.updateSurgePricingSettings()
  }

  private updateSurgePricingSettings(): void {
    const settings = {
      enabled: this.isEnabled,
      order: this.surges.map((surge: Surge): string => surge.id)
    }

    this.showSettingsUpdateError = false

    surgesCollection.updateSettings(settings)
      .then((settings: ISurgePricingSettings): void => {
        this.isEnabled = settings.enabled
      })
      .catch((error: Error): void => {
        this.logger.error('Cannot update surge pricing settings', error)
        this.showSettingsUpdateError = true
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  public deletePriceSurge(surgeId: string): Promise<void> {
    return surgesCollection.deleteSurge(surgeId)
      .catch((error: Error) => { this.logger.error('Cannot delete price surge', error) })
      .then(() => this.sync())
  }
}

export default {
  templateUrl: require('./surge-pricing-dashboard.pug'),
  controller: SurgePricingDashboardController
}
