export default {
  templateUrl: require('./form-action.pug'),
  transclude: true,
  bindings: {
    main: '<?',
    disabled: '<?',
    danger: '<?',
    create: '<?',
    secondary: '<?'
  }
}
