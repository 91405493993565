import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'
import businessesBalanceAndSubscriptions from './businesses-balance-and-subscription/businessesBalanceAndSubscriptions'
import subscriptionPlanCard from './subscription-plan-card/subscriptionPlanCard'
import subscriptionSettings from './subscription-settings/subscriptionSettings'

export default declareAppModule('businesses.balance', (module: ng.IModule) => module
  .component('businessesBalanceAndSubscriptions', businessesBalanceAndSubscriptions)
  .component('subscriptionPlanCard', subscriptionPlanCard)
  .component('subscriptionSettings', subscriptionSettings)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.businesses',
      stateName: 'balance',
      componentName: 'businessesBalanceAndSubscriptions',
      stateKey: 'BUSINESSES_BALANCE',
      requiresPermission: 'BUSINESSES_CREATE',
      params: [
        'paymentSuccess',
        'paymentFailed',
        'paymentType',
        'topUpAmount',
        'paymentid',
        'result',
        'auth',
        'ref',
        'tranid',
        'postdate',
        'trackid',
        'operdate'
      ]
    })
  })
)
