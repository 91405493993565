import sortBy from 'lodash-es/sortBy'
import fleetsCollection from 'data/collections/fleetsCollection'
import Logger from 'common/Logger'
import Fleet from 'data/domain-objects/Fleet'
const logger = new Logger('Business and Branch select')

interface IFleet {
  id: string,
  name: string
}

class FleetsSelectController {
  public fleets: IFleet[] = []
  public selectedId: string = null
  public placeholder: string
  public hostClass: string
  public selectSingle: boolean
  public onSelect: (data: {value: any}) => void

  constructor (
    public $scope: ng.IScope
  ) {}

  $onInit (): void {
    if (!this.hostClass) {
      this.hostClass = 'fleets-picker'
    }

    this.getFleetsData().then(() => this.$scope.$digest())
  }

  getFleetsData(): Promise<void> {
    return fleetsCollection.getAllCached()
      .then((fleets: Fleet[]) => {
        this.fleets = fleets.map((fleet: Fleet) => {
          return {
            id: fleet.id,
            name: fleet.name
          }
        })

        this.fleets = sortBy(this.fleets, ['name'])

        if (this.selectSingle && this.fleets.length === 1) {
          this.onChange(this.fleets[0])
        }
      })
      .catch((err: Error) => logger.error('Cannot get all businesses with branches', err))
  }

  onChange (suggestion: IFleet): void {
    if (!suggestion) {
      this.onSelect({value: {}})
    } else {
      this.onSelect({value: {
        id: suggestion && suggestion.id,
        name: suggestion.name
      }})
    }
  }
}

export default {
  templateUrl: require('./fleets-picker.pug'),
  controller: FleetsSelectController,
  bindings: {
    selectedId: '<?',
    hostClass: '@?',
    selectSingle: '<?',
    onSelect: '&',
    placeholder: '@',
    isDisabled: '<?'
  }
}
