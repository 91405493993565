import templateUrl from './existing-accounts.pug'
import driverAccountModel from 'data/models/driverAccountModel'
import Logger from 'common/Logger'

const logger = new Logger('Driver Existing Account Controller')

class ExistingAccountsController {
  constructor ($state) {
    this.$state = $state
    this.wizardRouteSteps = [
      {
        iconClass: 'key',
        current: true,
        label: 'COMMON.DRIVER.ACCOUNT'
      },
      {
        iconClass: 'id-card',
        label: 'COMMON.DRIVER.LICENSE'
      },
      {
        iconClass: 'car',
        label: 'COMMON.DRIVER.VEHICLE'
      },
      {
        iconClass: 'cogs',
        label: 'COMMON.DRIVER.FLEETS'
      },
      {
        iconClass: 'check',
        label: 'COMMON.DRIVER.ACTIVE'
      }
    ]
    this.accounts = driverAccountModel.getLastSearchResults()
  }

  createDriverFromExistingUser (user) {
    logger.info('Requesting create driver with existing user account selected:', user)

    if (user.recordType === 'driver') {
      this.goToViewDriver(user)
    } else {
      driverAccountModel.createDriverFromUser(user)
        .then(driver => this.goToViewDriver(driver))
        .catch(error => this.handleCreateDriverFailed(error))
    }
  }

  goToViewDriver (driver) {
    const params = {
      driverId: driver.id
    }

    this.$state.go('main.logistics.drivers.driver', params)
  }

  handleCreateDriverFailed (error) {
    logger.error('Failed to create driver with selected user account.', error)
  }
}

export default {
  templateUrl,
  controller: ExistingAccountsController
}
