import envConfig from 'common/envConfig'
import userModel from 'data/models/userModel'
import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'

const I_COULDNT_FIX_IT_WITHOUT_TIMEOUT = 100

class LoginController extends BaseController {

  public destinationPath: string = null
  public wasRedirected: boolean = false
  public isPendingLogin: boolean = false
  public loginFailed: boolean = false
  public credential: any = {}
  public password: string = ''
  public registerRoute: string = envConfig.registrationRoute

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.destinationPath = this.$state.params.destination
    this.wasRedirected = !!this.destinationPath

    this.$scope.$watch('$ctrl.password', () => {
      this.loginFailed = false
    })
  }

  onCredentialChanged (credential: any): void {
    this.credential = credential
    this.loginFailed = false
  }

  submit (): void {
    this.isPendingLogin = true
    const credentials = this.getCredentials()
    userModel.logIn(credentials)
      .then(() => {
        this.handleLoggedIn()
        this.redirectToNextState()
      })
      .catch(() => this.handleLoginFailed())
      .then(() => this.$scope.$digest())
  }

  getCredentials (): void {
    return Object.assign({password: this.password}, this.credential)
  }

  handleLoggedIn (): void {
    this.logger.info('User logged in')
    this.isPendingLogin = false
    this.loginFailed = false
  }

  handleLoginFailed (): void {
    this.isPendingLogin = false
    this.loginFailed = true
  }

  redirectToNextState (): void {
    if (this.destinationPath) { // If we have a destination to redirect to after login, go there
      const decodedDestinationPath = decodeURIComponent(this.destinationPath)
      const url = window.location.origin + '/#!' + decodedDestinationPath
      setTimeout(() => {
        window.location.replace(url)
      }, I_COULDNT_FIX_IT_WITHOUT_TIMEOUT)
    } else {
      this.$state.go('main.dashboard')
    }
  }

  redirectToPasswordReset (): void {
    // Send credential through to reset form
    this.$state.go('auth.request-password-reset', this.credential)
  }
}

export default {
  templateUrl: require('./login.pug'),
  controller: LoginController
}
