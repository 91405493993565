import templateUrl from './pagination.pug'
import range from 'lodash-es/range'
import envConfig from 'common/envConfig'

const MINIMAL_PAGES_COUNT_TO_SHOW_THE_DOTS = 7

class PaginationController {
  constructor ($scope) {
    this.pagesLimit = envConfig.pagination.maximumNumberOfPages
    this.offsetOptions = []

    $scope.$watch('$ctrl.count', () => {
      // Those vars placed here cuz they're maybe result of some async magic from the outside
      this.count = parseInt(this.count)
      this.recalculatePages()
    })
  }

  get currentPage () {
    const currentPageNumber = Math.round(this.offset / this.limit) + 1
    return !isNaN(currentPageNumber) ? currentPageNumber : 1
  }

  recalculatePages () {
    this.pages = this.count ? range(Math.ceil(this.count / this.limit)) : []
  }

  $onInit () {
    // Make sure that limit and offset are numbers to avoid ugly NaNs
    this.offset = isNaN(parseInt(this.offset)) ? 0 : this.offset
    this.limit = isNaN(parseInt(this.limit)) ? envConfig.pagination.defaultLimit : this.limit
    this.offsetOptions = envConfig.pagination.limitOptions
  }

  goToPage (pageNumber) {
    if (pageNumber <= this.pages.length) {
      this.changeOffset((pageNumber - 1) * this.limit)
    }
  }

  changeLimit (limit) {
    this.limit = limit
    this.offset = 0
    this.recalculatePages()
    this.onOffsetChange({offset: this.offset, limit: this.limit}) // <--- poke a callback when offset changed
  }

  changeOffset (offset) {
    this.offset = offset
    this.onOffsetChange({offset: this.offset, limit: this.limit}) // <--- poke a callback when offset changed
  }

  isLeftDotsShouldBeShown () { // Beginner level. Show dots on the left since we reached the right side of central page buttons
    return this.pagesLimit >= MINIMAL_PAGES_COUNT_TO_SHOW_THE_DOTS && this.currentPage >= this.pagesLimit - 2
  }

  isRightDotsShouldBeShown () { // Expert level. Show dots on the right since we reached the left side of central page buttons
    return this.pagesLimit >= MINIMAL_PAGES_COUNT_TO_SHOW_THE_DOTS && this.pages.length && this.currentPage < this.pages.length - Math.floor(this.pagesLimit / 2)
  }

  getCenterPages () { // YodaMan level!
    let centralPagesOffset = 2

    if (this.isLeftDotsShouldBeShown()) { // Add some space for dots from the left
      centralPagesOffset++
    }

    if (this.isRightDotsShouldBeShown()) { // ...the same from the right
      centralPagesOffset++
    }

    // trying to find the center of the universe
    const centralPagesCenterOffset = Math.floor((this.pagesLimit - centralPagesOffset) / 2)

    // then calculate the proper range for central pages
    let startPageNumber = 2
    if (this.isLeftDotsShouldBeShown()) {
      if (this.isRightDotsShouldBeShown()) {
        startPageNumber = this.currentPage - centralPagesCenterOffset
      } else {
        const maximalStartPageNumber = this.pages.length - (this.pagesLimit - centralPagesOffset)
        const currentPageOffset = this.currentPage - centralPagesCenterOffset + 1
        startPageNumber = currentPageOffset < maximalStartPageNumber ? currentPageOffset : maximalStartPageNumber
      }
    }

    return range(startPageNumber, startPageNumber + this.pagesLimit - centralPagesOffset)
  }
}

export default {
  templateUrl,
  controller: PaginationController,
  bindings: {
    count: '<',
    limit: '<',
    offset: '<',
    isDisabled: '<',
    onOffsetChange: '&',
    hideLimitSelector: '<?'
  }
}
