import get from 'lodash-es/get'
import forIn from 'lodash-es/forIn'
import scrollTo from 'common/scroll'
import templateUrl from './delivery-create.pug'
import BaseFormController from 'presentation/common/BaseFormController'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import NewDeliveryModel from 'data/domain-objects/NewDeliveryModel'
import CreateDeliveryStateManager, {scrollToSectionIds} from 'presentation/delivery-create/CreateDeliveryStateManager'
import branchesCollection from 'data/collections/branchesCollection'
import businessesCollection from 'data/collections/businessesCollection'
import {SET_COST_FOCUSED_EVENT} from 'presentation/_core-forms/currency-and-cost-input/currencyAndCostInput'
import Consumer from 'data/domain-objects/Consumer'
import DELIVERY_CREATION_ERRORS from 'common/constants/DeliveryCreationErrors'
import areasCollection from 'data/collections/areasCollection'

const OPTION_SHOW = 'show'
const POS_UPDATED_FLAG_SET_DELAY = 300

export class DeliveryCreateController extends BaseFormController {
  constructor ($scope, $state) {
    super($scope, $state)

    this.branchHasBeenAutoSet = false

    this.newConsumerPhoneNumber = null
    this.deliverySubmissionInProgress = false
    this.selectedBranch = {}
    this.branches = []
    this.availablePaymentMethods = []
    this.allAreas = []

    this.settings = null
    this.consumer = null
    this.branch = null
    this.isEditing = true
    this.showWarningNoCredit = false
    this.showUnhandledError = false
    this.currentSectionID = null
    this.scrolledToElementIds = []
    this.isAddingNewAddress = false
    this.scrollToSectionIds = scrollToSectionIds

    this.handleConsumerFound = consumer => this.saveConsumer(consumer)
    this.handleConsumerNotFound = phoneNumber => this.onConsumerNotFound(phoneNumber)
    this.handleSelectedBranchChanged = branch => this.onSelectedBranchChanged(branch)
    this.handleOnDeliveryTypeChangeClick = () => this.onDeliveryTypeChangeClick()

    this.getAllAreas()
    this.createDeliveryModel()
  }

  createDeliveryModel () {
    this.model = new NewDeliveryModel()
  }

  $onInit () {
    this.createDeliveryStateManager()
    this.getAllBranches()
    this.setupWatchers()
    setTimeout(() => {
      this.setupScrollHelper()
    })
  }

  // This method is overriden in Next Day Delivery
  createDeliveryStateManager () {
    this.createDeliveryStateManager = new CreateDeliveryStateManager()
  }

  selectNewlyCreatedAddress () {
    const newlyCreatedAddress = this.consumer.addresses[this.consumer.addresses.length - 1]
    this.model.consumer.addressId = newlyCreatedAddress.id
  }

  onAddressCreated (consumer) {
    if (consumer) {
      this.saveConsumer(consumer)
    }
    this.selectNewlyCreatedAddress()
  }

  handlePosBlur () {
    if (this.model.posReference && this.model.posReference.length > 0) {
      setTimeout(() => {
        this.model.posHasBeenEntered = true
        this.$scope.$digest()
      }, POS_UPDATED_FLAG_SET_DELAY)
    }
  }

  setupScrollHelper () {
    const helper = document.querySelector('.scroll-helper')
    helper.style.height = document.documentElement.clientHeight + 'px'
  }

  animateScrollToElementId (elementId) {
    if (!this.hasElementBeenScrolledTo(elementId) && !this.animationInProgress) {
      this.animationInProgress = true
      const elementToBringInView = document.getElementById(elementId)
      if (elementToBringInView && elementToBringInView.offsetHeight > 0) {
        const elementYPosition = elementToBringInView.getBoundingClientRect().top + window.scrollY - 100 // eslint-disable-line
        this.scrolledToElementIds.push(elementId)
        this.scrolledElement = elementId
        scrollTo(elementYPosition, () => {
          this.animationInProgress = false
        })
      }
    }
  }

  hasElementBeenScrolledTo (elementId) {
    return this.scrolledToElementIds.indexOf(elementId) > -1
  }

  beforeSubmit () {
    this.deliverySubmissionInProgress = true

    const deliveryDescriptor = this.model.getDeliveryDescriptorForApi()
    return deliveriesCollection.create(deliveryDescriptor)
  }

  afterSubmitSuccess (delivery) {
    this.$state.go('main.deliveries.overview.confirmation', {
      deliveryId: delivery.id
    })
  }

  afterSubmitFailure (error) {
    this.deliverySubmissionInProgress = false
    if (error.message === DELIVERY_CREATION_ERRORS.INSUFFICIENT_FUNDS) {
      this.showWarningNoCredit = true
    } else {
      this.showUnhandledError = true
    }
  }

  setupWatchers () {
    this.$scope.$watch('$ctrl.model', () => this.updateState(), true)
    this.$scope.$watch('$ctrl.settings', () => this.updateState(), true)
    this.$scope.$watch('$ctrl.newConsumerPhoneNumber', () => this.updateState())
    this.$scope.$watch('$ctrl.consumer.addressId', () => this.updateState())
  }

  updateState () {
    const selectedConsumerAddress = this.consumer && this.consumer.addresses.find(address => address.id === this.model.consumer.addressId)
    this.createDeliveryStateManager.update(this.branches, this.model, this.settings, this.newConsumerPhoneNumber, selectedConsumerAddress, this.allAreas) // it is starting to smell

    const elementIdToScrollTo = get(this.createDeliveryStateManager, 'elementIdToScrollTo', null)
    if (elementIdToScrollTo && this.scrolledElement !== elementIdToScrollTo && !this.createDeliveryStateManager.isDatePickerOpen) {
      setTimeout(() => this.animateScrollToElementId(elementIdToScrollTo))
    }
  }

  getAllBranches () {
    businessesCollection.getBusinessesWithTheirBranches()
      .then(businessesWithBranches => this.getAllBranchesFromAllBusinesses(businessesWithBranches))
      .then(branches => this.handleBranchesReceived(branches))
      .catch(err => this.logger.error(err))
  }

  getAllBranchesFromAllBusinesses (businessesWithBranches) {
    const allBranches = []
    businessesWithBranches
      .forEach(businessWithBranches => {
        get(businessWithBranches, 'branches', []).forEach(branch => {
          branch.businessId = businessWithBranches.id
          branch.businessName = businessWithBranches.name
          allBranches.push(branch)
        })
      })

    return allBranches
  }

  getAllAreas () {
    areasCollection.getRealGeographicalAreasCached()
      .then(areas => {
        this.allAreas = areas
      })
  }

  handleBranchesReceived (branches) {
    const uniqueBusinesses = {}
    this.branches = branches.map(branch => {
      branch.description = branch.businessName + ' - ' + branch.name // TODO: refactor to use Branch object
      uniqueBusinesses[branch.businessId] = true
      return branch
    })

    if (this.branches.length === 1) {
      this.onSelectedBranchChanged(this.branches[0])
      this.branchHasBeenAutoSet = true
    } else if (this.branches.length) {
      const businessesCount = Object.keys(uniqueBusinesses).length
      this.getBusinessSettings(this.branches[0].businessId, businessesCount)
    }
    this.$scope.$digest()
  }

  onSelectedBranchChanged (branch) {
    this.model.cost.amount = get(this.model, 'cost.amount', null)
    if (branch) {
      this.branch = branch
      this.model.setBranch(branch)
      this.getBranchSettings(branch.businessId, branch.id)
    } else {
      this.model.resetBranch()
      this.branch = null
      this.settings = null
    }
  }

  setDefaultSettings (setting) {
    return setting.value = setting.defaultValue
  }

  getBusinessSettings (businessId, businessCount) {
    businessesCollection.getBusinessSettings(businessId)
      .then(settings => {
        // if there are more than 1 business,
        // we gonna use default settings for areas and map
        if (businessCount > 1) {
          this.setDefaultSettings(settings.showAreas)
          this.setDefaultSettings(settings.selectedAreas)
          this.setDefaultSettings(settings.showConsumerAddressLocationMap)
          this.setDefaultSettings(settings.consumerAddressLocationMapState)
        }
        this.handleSettingsReceived(settings)
      })
      .catch(error => this.handleBusinessSettingsError(error))
  }

  getBranchSettings (businessId, branchId) {
    branchesCollection.getBranchSettings(businessId, branchId, {merge: true})
      .then(response => this.handleSettingsReceived(response))
      .then(() => this.conditionallySetFocusToCostInput())
      .catch(error => this.handleBranchSettingsError(error))
  }

  handleSettingsReceived (settings) {
    this.settings = settings
    this.applyDeliveryTypeSettingToModel()
    this.applyPickupTimeSettingToModel()
    this.applyOrderDetailsSettingToModel()
    this.applyChargeableAccountSettingToModel()
    this.applyPackageSettingToModel()
    this.applyProofOfDeliverySettingToModel()
    this.applyBatchingSettingToModel()
    this.applyStatusSettingToModel()
    this.$scope.$digest()
  }

  handleBusinessSettingsError (error) {
    this.logger.error('Cannot get business settings', error)
  }

  handleBranchSettingsError (error) {
    this.logger.error('Cannot get branch settings', error)
  }

  // Conditionally broadcast event to put focus on cost input if it is empty
  conditionallySetFocusToCostInput () {
    if (!this.model.cost || !this.model.cost.amount) {
      this.$scope.$broadcast(SET_COST_FOCUSED_EVENT)
    }
  }

  saveConsumer (consumer) {
    this.model.resetConsumer()
    this.consumer = Consumer.build(consumer)
    this.newConsumerPhoneNumber = null
    this.applyChargeableAccountSettingToModel()
    this.model.setConsumer(consumer)
    this.$scope.$digest()
  }

  onConsumerNotFound (phoneNumber) {
    this.model.resetConsumer()
    this.newConsumerPhoneNumber = phoneNumber
    this.consumer = null
    this.$scope.$digest()
  }

  onDeliveryTypeChangeClick () {
    this.model.toggleDeliveryType()
  }

  onPosReferenceChanged (posReference) {
    this.model.posReference = posReference
  }

  handlePackagesUpdated (packages) {
    this.model.packages = packages
  }

  applyDeliveryTypeSettingToModel () {
    const CONSUMER_OPTION = 'consumer'
    const BRANCH_OPTION = 'branch'
    const deliveryTypeSetting = get(this.settings, 'deliveryType.value', null)

    if (deliveryTypeSetting) {
      if (deliveryTypeSetting === CONSUMER_OPTION) {
        this.model.setToPickUpFromConsumer()
      } else if (deliveryTypeSetting === BRANCH_OPTION) {
        this.model.setToPickUpFromBranch()
      }
    }
  }

  applyPickupTimeSettingToModel () {
    const OPTION_ASAP = 'asap'
    const OPTION_IN_MINUTES = 'in-minutes'
    const OPTION_DATE_TIME = 'date-time'
    const NOW = new Date()

    const defaultPickupTimeMode = get(this.settings, 'defaultPickupTimeMode.value', OPTION_ASAP)
    this.defaultPickupTimeInMinutes = get(this.settings, 'defaultPickupTimeInMinutes.value', null)

    if (defaultPickupTimeMode) {
      if (defaultPickupTimeMode === OPTION_ASAP) {
        this.model.requestedPickupTime.setAsap(true)
      } else if (defaultPickupTimeMode === OPTION_DATE_TIME) {
        this.model.requestedPickupTime.setAtDateTime(NOW)
      } else if (this.defaultPickupTimeInMinutes && defaultPickupTimeMode === OPTION_IN_MINUTES) {
        this.model.requestedPickupTime.setAfterDelayMinutes(this.defaultPickupTimeInMinutes)
      }
    }
  }

  applyStatusSettingToModel () {
    const statusSetting = get(this.settings, 'newDeliveryStatus.value', null)
    if (statusSetting !== null) {
      this.model.setStatus(statusSetting)
    }
  }

  applyOrderDetailsSettingToModel () {
    this.availablePaymentMethods = branchesCollection.getPaymentMethods(this.settings)
    const orderCurrencyCodeSetting = get(this.settings, 'defaultCurrency.value', null)
    const orderPaymentMethodSetting = get(this.settings, 'payment.value', null)

    if (orderCurrencyCodeSetting) {
      this.model.setCurrencyCode(orderCurrencyCodeSetting)
    }

    if (orderPaymentMethodSetting) {
      this.model.paymentMethod = orderPaymentMethodSetting
    }
  }

  applyChargeableAccountSettingToModel () {
    const OPTION_CONSUMER = 'consumer'
    const OPTION_BRANCH = 'branch'

    const chargeDeliveryToSetting = get(this.settings, 'deliveryCharge.value', null)

    if (chargeDeliveryToSetting) {
      if (chargeDeliveryToSetting === OPTION_CONSUMER && this.consumer) {
        this.model.setBalanceAccountId(this.consumer.balanceAccountId)
      } else if (chargeDeliveryToSetting === OPTION_BRANCH && this.branch) {
        this.model.setBalanceAccountId(this.branch.balanceAccountId)
        this.getBalanceAccountIdForBranch(this.branch)
      }
    }
  }

  getBalanceAccountIdForBranch (branch) {
    branchesCollection.getBalances(branch.businessId, branch.id)
      .then(balances => {
        const balancesWithEnoughFunds = balances.filter(balance => balance.isEnoughFundsToCreateDelivery)

        const accountId = (balancesWithEnoughFunds.length) ? balancesWithEnoughFunds[0].balanceAccountId : null
        this.model.setBalanceAccountId(accountId)
        this.$scope.$digest()
      })
      .catch(error => this.logger.error('Cannot get balance account ID for selected branch', error))
  }

  applyBatchingSettingToModel () {
    const allowBatching = get(this.settings, 'batching.value', null)
    this.model.allowBatching = allowBatching === 'auto'
    const showBatchingOptions = get(this.settings, 'showBatching.value', null)
    if (showBatchingOptions !== null) {
      this.model.showBatchingOptions = (showBatchingOptions === OPTION_SHOW)
    }
  }

  applyPackageSettingToModel () {
    let packageTypesSettings = get(this.settings, 'package.value', null)
    try {
      packageTypesSettings = JSON.parse(packageTypesSettings)
    } catch (e) {
      packageTypesSettings = {}
    }
    forIn(packageTypesSettings, (packageType, packageTypeId) => {
      if (packageType.enabled && packageType.isDefault) {
        this.model.packages = [{amount: packageType.limit, packageTypeId}]
      }
    })
  }

  applyProofOfDeliverySettingToModel () {
    const OPTION_REQUIRED = 'required'

    const showPhotoIdentification = get(this.settings, 'proofOfDeliveryPhotoIdentificationEnabled.value', false)
    const photoIdentificationRequiredSetting =
      get(this.settings, 'proofOfDeliveryPhotoIdentificationSetting.value', null)

    const showSignature = get(this.settings, 'proofOfDeliverySignatureEnabled.value', false)
    const signatureRequiredSetting = get(this.settings, 'proofOfDeliverySignatureSetting.value', null)

    if (showPhotoIdentification && photoIdentificationRequiredSetting &&
      photoIdentificationRequiredSetting === OPTION_REQUIRED) {
      this.model.requirePhotoIdentification = true
    }

    if (showSignature && signatureRequiredSetting && signatureRequiredSetting === OPTION_REQUIRED) {
      this.model.requireSignature = true
    }
  }

  onRequestedPickupTimeChanged (requestedTime) {
    this.model.requestedPickupTime = requestedTime
  }

  onChangeTimeSlot (timestamp) {
    this.model.requestedPickupTime.setTimeSlot(timestamp)
    this.createDeliveryStateManager.validateRequestPickupTime()
  }
}

export default {
  templateUrl,
  controller: DeliveryCreateController
}
