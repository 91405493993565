export default function disallowedValues () {
  return {
    restrict: 'A',
    require: 'ngModel', // Requiring ngModel passes the ngModelController for this element to the link function
    scope: {
      disallowedValues: '<'
    },
    link: function ($scope, element, $attrs, ngModelController) {
      // Validate our field each time the value is changed
      $scope.$watch(() => $scope.disallowedValues, () => {
        ngModelController.$validate()
      })

      ngModelController.$validators.disallowedValues = function (modelValue) {
        // If there's no disallowed values or model value, then we're valid.
        let disallowedValues = $scope.disallowedValues
        if (!disallowedValues || !modelValue) {
          return true
        }
        if (!Array.isArray(disallowedValues)) {
          disallowedValues = [disallowedValues]
        }
        for (const disallowedValue of disallowedValues) {
          if (disallowedValue === modelValue) {
            return false
          }
        }
        return true
      }
    }
  }
}
