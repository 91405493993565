import moment from 'moment'
import User from 'data/domain-objects/User'
import userModel from 'data/models/userModel'
import rolesService from 'data/services/rolesService'
import userCollection from 'data/collections/userCollection'
import BaseFormController from 'presentation/common/BaseFormController'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'
import envConfig from 'common/envConfig'
import { StateService } from '@uirouter/core'
import { isSameDomain } from 'common/utils/urlUtils'

class RegistrationController extends BaseFormController {

  public hostname: string = window.location.hostname
  public host: string = window.location.host
  public hasAgreedTerms: boolean = true
  public shouldInviteCodeFieldBeShown: boolean = true
  public inviteCodePattern: string = moment().format('YYYYMMDD')
  public isStorefrontAdminDomain: boolean = isSameDomain(envConfig.platformDomains.storefrontAdminDomain)

  constructor(
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit(): void {
    this.model = new User()

    diallingCodesCollection.getDefault()
      .then((countryDiallingCode: string) => {
        this.model.countryDiallingCode = countryDiallingCode
        this.$scope.$digest()
      })
      .catch((err: Error) => this.logger.error('Cannot get default country dialling code', err))
  }

  beforeSubmit(): Promise<void> {
    return rolesService.getRoleIdForHostname(this.hostname)
      .then((roleId: string) => {
        const userWithRole = Object.assign({}, this.model.toJSON(), { role: roleId })
        return userCollection.create(userWithRole)
      })
  }

  afterSubmitSuccess(): Promise<void> {
    this.logger.info('Performing login with registration credentials')
    const credentials = {
      email: this.model.email,
      password: this.model.password
    }
    return userModel.logIn(credentials)
      .then(() => {
        this.logger.info('Logged in with registration credentials, redirecting to create first business')
        this.$state.go('main.dashboard') // Note syntactic 'this'
      })
      .catch(() => {
        this.logger.info('Failed to log in with registration credentials, redirecting to login')
        this.$state.go('auth.login')
      })
  }
}

export default {
  templateUrl: require('./registration.pug'),
  controller: RegistrationController
}
