import {StateService, StateParams} from '@uirouter/core'
import Product from 'data/domain-objects/Product'
import BaseController from 'presentation/common/BaseController'
import localStorageService from 'data/services/localStorageService'
import productsCollection from 'data/collections/productsCollection'
import storefrontCategoriesCollection from 'data/collections/storefrontCategoriesCollection'
import {collectMappedEntitiesByIds} from 'data/common/collectByIds'
import MultiLangString from 'data/domain-objects/MultiLangString'
import StorefrontCategory from 'data/domain-objects/storefront/StorefrontCategory'
import { flattenTree } from 'common/utils/treeUtils'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'
const PRODUCT_STATE = 'main.storefront.products.product'

class ProductsController extends BaseController {
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public categoryId: string = ''
  public branchId: string = ''
  public categoryNamesByIds: object = {}
  public products: Product[] = []
  public searchQuery: string = ''
  public categorySuggestions: object[] = []
  public showCloneError: boolean = false

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.categoryId = this.$stateParams.categoryId || null

    if (this.businessId) {
      this.sync()
    }
  }

  onSync (): Promise<any> {
    return Promise.all([
      productsCollection.getAll(this.businessId, this.branchId, this.categoryId, this.searchQuery),
      this.categorySuggestions.length ? Promise.resolve([]) : storefrontCategoriesCollection.getAll(this.businessId)
    ])
      .then(([products, categories]: any[]) => {
        this.handleGetProductsSuccess(products)
        this.handleGetCategoriesSuccess(categories)
      })
  }

  handleGetProductsSuccess (products: Product[]): void {
    this.products = products
  }

  handleGetCategoriesSuccess (categories: StorefrontCategory[]): void {
    if (categories.length) {
      const flattenedCategoriesList = flattenTree(categories, 'categories')
      this.categoryNamesByIds = collectMappedEntitiesByIds(
        flattenedCategoriesList,
        (category: StorefrontCategory): MultiLangString => MultiLangString.build(category.name)
      )
      this.categorySuggestions = flattenedCategoriesList.map((category: StorefrontCategory): ISuggestion => {
        return {
          id: category.id,
          name: category.name.getLocalized(),
        }
      })
    }
  }

  onBusinessSelected (business: any): void {
    this.businessId = business.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)
    this.branchId = null
    if (this.businessId) {
      this.sync()
    } else {
      this.products = []
    }
  }

  onBranchSelected (branch: any): void {
    this.branchId = branch.branchId

    if (this.businessId) {
      this.sync()
    } else {
      this.products = []
    }
  }

  onCategorySelected (suggestion: ISuggestion): void {
    this.categoryId = suggestion !== null ? suggestion.id : null
    this.$stateParams.categoryId = this.categoryId
    this.sync()
  }

  doSearch (searchQuery: string): void {
    this.searchQuery = searchQuery
    this.sync()
  }

  setAvailability (productId: string, availability: boolean): void {
    productsCollection.setProductAvailability(this.businessId, productId, availability)
      .then(() => {
        this.products.forEach((product: Product, productIndex: number) => {
          if (product.id === productId) {
            this.products[productIndex].isAvailable = availability
          }
        })
        this.$scope.$digest()
      })
      .catch((error: Error) => this.logger.error('Cannot update product availability', error))
  }

  viewProduct (productId: string): void {
    this.$state.go(PRODUCT_STATE, {
      productId,
      businessId: this.businessId
    })
  }

  moveProductUp (productIndex: number): void {
    const newIndex = productIndex - 1

    if (newIndex >= 0) {
      this.moveProduct(productIndex, newIndex)
    }
  }

  moveProductDown (productIndex: number): void {
    const newIndex = productIndex + 1

    if (newIndex < this.products.length) {
      this.moveProduct(productIndex, newIndex)
    }
  }

  moveProduct (fromIndex: number, toIndex: number): void {
    this.products.splice(toIndex, 0, this.products.splice(fromIndex, 1)[0])

    this.isLoading = true
    productsCollection.updateProductsOrder(this.businessId, this.categoryId, this.products.map((product: Product): string => product.id))
      .then((): void => {
        this.isLoading = false
        this.$scope.$digest()
      })
      .catch((error: Error): void => {
        this.logger.error('Cannot update products order for certain category', error)
      })
  }

  deleteEntity (params: {payload: {businessId: string, productId: string}}): void | Promise<any> {
    return productsCollection.deleteProduct(params.payload.businessId, params.payload.productId)
      .catch((error: Error): void => this.logger.error('Cannot delete product', error))
      .then((): void => this.sync())
      .catch((error: Error): void => this.logger.error('Cannot get products', error))
  }

  cloneProduct (product: Product): void {
    this.showCloneError = false
    productsCollection.create(this.businessId, Product.clone(product))
      .then(() => {
        this.sync()
      })
      .catch((error: Error) => {
        this.showCloneError = true
        this.logger.error('Cannot clone product', error)
        this.$scope.$digest()
      })
  }
}

export default {
  controller: ProductsController,
  templateUrl: require('./products.pug')
}
