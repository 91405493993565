import moment from 'moment'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import userModel from 'data/models/userModel'
import envConfig from 'common/envConfig'
import Consumer from 'data/domain-objects/Consumer'
import Address from 'data/domain-objects/Address'

class ConsumerAddressPicker {

  public newConsumerAddressId: string
  public disabledValuesMap: object = {}
  public consumerAddressIds: string[]
  public consumerAddressNamesById: any

  // bindings
  public name: string
  public form: ng.IFormController
  public placeholder: string
  public consumer: Consumer
  public consumerAddressId: string
  public onConsumerAddressSelected: (payload: {addressId: string}) => void
  public isRequired: boolean
  public isDisabled: boolean
  public disallowedAddressId: string
  public autoSelectSingleAddress: boolean

  constructor (
    public $scope: ng.IScope,
  ) {}

  $onInit (): void {
    this.$scope.$watch('$ctrl.newConsumerAddressId', () => {
      if (this.newConsumerAddressId !== this.consumerAddressId) {
        this.onConsumerAddressSelected({addressId: this.newConsumerAddressId})
      }
    })

    userModel.onUserChanged(() => this.syncAddressNames()) // Until application settings model gives locale change events, use user change
  }

  $onChanges (changeDescriptorsByKey: ng.IOnChangesObject ): void {
    if (changeDescriptorsByKey.consumerAddressId) {
      this.newConsumerAddressId = changeDescriptorsByKey.consumerAddressId.currentValue
    }

    const changedConsumer = getValueFromChangeDescriptors(changeDescriptorsByKey, 'consumer')
    if (changedConsumer && Array.isArray(changedConsumer.addresses)) {
      this.syncConsumerAddressData(changedConsumer.addresses)
    }

    if (changeDescriptorsByKey.disallowedAddressId) {
      this.disabledValuesMap = {}
      this.disabledValuesMap[this.disallowedAddressId] = true
    }
  }

  syncConsumerAddressData (consumerAddresses: Address[]): void {
    const actualConsumerAddresses = consumerAddresses.filter((address: Address) => !address.detached)
    this.consumerAddressIds = actualConsumerAddresses.map((address: Address) => address.id)
    this.consumerAddressNamesById = {}
    actualConsumerAddresses.forEach((consumerAddress: Address) => {
      this.consumerAddressNamesById[consumerAddress.id] = this.getConsumerAddressDescription(consumerAddress)
    })
    // autoSelectSingleAddress if it's not disabled
    if (this.autoSelectSingleAddress && this.consumerAddressIds.length === 1) {
      if (this.disabledValuesMap.hasOwnProperty(this.consumerAddressIds[0])) {
        this.newConsumerAddressId = this.consumerAddressIds[0]
      }
    }
  }

  getConsumerAddressDescription (consumerAddress: Address): string {
    let consumerInfo = ''

    if (consumerAddress.consumerFirstName || consumerAddress.consumerLastName) {
      consumerInfo = `${consumerAddress.consumerFirstName || ''} ${consumerAddress.consumerLastName || ''} / `
    }

    if (consumerAddress.name) {
      return `${consumerAddress.getFormattedAddress()} (${consumerInfo}${consumerAddress.name})`
    } else {
      const addressDate = moment(consumerAddress.createdAt).format(envConfig.dateOnlyDateFormat)
      return `${consumerAddress.getFormattedAddress()} (${consumerInfo}${addressDate})`
    }
  }

  syncAddressNames (): void {
    if (this.consumer && this.consumer.addresses) {
      this.syncConsumerAddressData(this.consumer.addresses)
      this.$scope.$digest()
    }
  }
}

export default {
  templateUrl: require('./consumer-address-picker.pug'),
  controller: ConsumerAddressPicker,
  bindings: {
    name: '@',
    form: '<',
    placeholder: '@',
    consumer: '<',
    consumerAddressId: '<',
    onConsumerAddressSelected: '&',
    isRequired: '<',
    isDisabled: '<',
    disallowedAddressId: '<?',
    autoSelectSingleAddress: '<?'
  }
}
