import { StateParams, StateService } from '@uirouter/core'
import StorefrontSettingsController from 'presentation/businesses/common/StorefrontSettingsController'
import storefrontDomainsCollection from 'data/collections/storefrontDomainsCollection'
import businessesCollection from 'data/collections/businessesCollection'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import Setting from 'data/domain-objects/Setting'
import PhoneNumber from 'data/domain-objects/PhoneNumber'
import envConfig from 'common/envConfig'
import storefrontSubscriptionsCollection from 'data/collections/storefrontSubscriptionsCollection'
import { STOREFRONT_OPEN_PURCHASE_STOREFRONT_POPUP_EVENT } from 'common/constants/PopupEvents'
import { StorefrontOrderReceiptTemplates } from 'common/constants/StorefrontOrderReceiptTemplates'

const DOMAIN_SETTING_KEY = 'domainName'
const DRAEWIL_TECH_DOMAIN = 'draewil.tech'

enum PosGenerationModeOptionType {
  LINEAR = 'linear',
  COMPLEX = 'complex'
}

enum PosIncrementModeOptionType {
  FIXED = 'fixed',
  RANDOM = 'random'
}

enum FrontPageOptions {
  MENU = 'menu',
  AREAS = 'areas'
}

enum ProtocolTypes {
  HTTP = 'http://',
  HTTPS = 'https://'
}

enum CheckoutModeOptions {
  STANDARD = 'standard',
  GUEST_ONLY = 'guestOnly'
}

const HOMEPAGE_PHONE_DISPLAY_OPTIONS_COLUMN = 'column'

const GENERIC_ERROR_CODE = 'OTHER'

const POS_GENERATION_MODE_OPTIONS: PosGenerationModeOptionType[] = [
  PosGenerationModeOptionType.LINEAR,
  PosGenerationModeOptionType.COMPLEX
]

const POS_INCREMENT_MODE_OPTIONS: PosIncrementModeOptionType[] = [
  PosIncrementModeOptionType.FIXED,
  PosIncrementModeOptionType.RANDOM
]

const businessOnlySettings: Partial<IBusinessStorefrontSettings> = {
  appearance__primaryAccentColor: Setting.build({ value: '#e74c3c' }),
  appearance__secondaryAccentColor: Setting.build({ value: '#8e44ad' }),
  appearance__mainFontColor: Setting.build({ value: '#1abc9c' }),
  appearance__headerFontColor: Setting.build({ value: '#3498db' }),
  appearance__reverseFontColor: Setting.build({ value: '#ffffff' }),
  appearance__sidebarColor: Setting.build({ value: '#0A213A' }),
  appearance__sidebarTextColor: Setting.build({ value: '#ffffff' }),
  appearance__priceBackgroundColor: Setting.build({ value: '#677A8A' }),
  appearance__priceTextColor: Setting.build({ value: '#ffffff' }),
  appearance__coverTextAndIconsColor: Setting.build({ value: '#ffffff' }),
  appearance__overlays__displayForMainPhoto: Setting.build({ value: true }),
  appearance__overlays__displayForProductPhotos: Setting.build({ value: true }),
  appearance__displayBusinessTitleAndSubtitle: Setting.build({ value: true }),
  appearance__phoneNumbers__displayHomepageBranchPhone: Setting.build({ value: HOMEPAGE_PHONE_DISPLAY_OPTIONS_COLUMN }),
  appearance__phoneNumbers__displayMenuContactPhone: Setting.build({ value: true }),
  appearance__phoneNumbers__displayTrackingPagePhone: Setting.build({ value: true }),
  appearance__categoriesLayoutMode: Setting.build({ value: 'text' }),
  appearance__displayCategoryTitleAndNumberOfItems: Setting.build({ value: true }),
  appearance__categoryTitleColor: Setting.build({ value: '#ffffff' }),
  appearance__overlays__displayForCategoryPhotos: Setting.build({ value: true }),
  socialNetworks__whatsApp: Setting.build({}),
  socialNetworks__instagram: Setting.build({}),
  socialNetworks__snapChat: Setting.build({}),
  socialNetworks__facebook: Setting.build({}),
  socialNetworks__pinterest: Setting.build({}),
  socialNetworks__tripadvisor: Setting.build({}),
  socialNetworks__twitter: Setting.build({}),
  socialNetworks__website: Setting.build({}),
  socialNetworks__youtube: Setting.build({}),
  displayCutlery: Setting.build({}),
  analytics__googleAnalyticsId: Setting.build({}),
  analytics__facebookPixelId: Setting.build({}),
  frontPage: Setting.build({ value: FrontPageOptions.AREAS }),
  scheduledOrdersEnabled: Setting.build({ value: true }),
  receipts__autoPrint: Setting.build({ value: false }),
  receipts__displayAcceptAndPrint: Setting.build({ value: false }),
  receipts__template: Setting.build({}),
  receipts__printRestaurantLogo: Setting.build({ value: false }),
  asapOrdersEnabled: Setting.build({ value: true }),
  orderProcessing__limits__scheduled__enabled: Setting.build({ value: false }),
  orderProcessing__limits__scheduled__options__cutOffTime: Setting.build({ value: '00:00' }),
  orderProcessing__limits__scheduled__options__minDays: Setting.build({ value: 0 }),
  orderProcessing__limits__scheduled__options__maxDays: Setting.build({ value: 7 }),
  guestCheckoutOnly: Setting.build({ value: false }),
  appearance__fontName__en: Setting.build({}),
  appearance__fontName__ar: Setting.build({})
}

const WHATSAPP_PHONE_NUMBER_SETTING_KEY = 'socialNetworks__whatsApp'
const SCHEDULED_MIN_DAYS_LIMIT_SETTING_KEY = 'orderProcessing__limits__scheduled__options__minDays'
const SCHEDULED_MAX_DAYS_LIMIT_SETTING_KEY = 'orderProcessing__limits__scheduled__options__maxDays'
const WEBSITE_ADDRESS_SETTING_KEY = 'socialNetworks__website'
const CUTOFF_TIME_SETTING_KEY = 'orderProcessing__limits__scheduled__options__cutOffTime'

const MIDNIGHT = '24:00'

interface IBusinessStorefrontSettings {
  socialNetworks__whatsApp: Setting,
  socialNetworks__instagram: Setting,
  socialNetworks__snapChat: Setting,
  socialNetworks__facebook: Setting,
  socialNetworks__pinterest: Setting,
  socialNetworks__tripadvisor: Setting,
  socialNetworks__twitter: Setting,
  socialNetworks__website: Setting,
  socialNetworks__youtube: Setting,
  appearance__headerFontColor: Setting,
  appearance__mainFontColor: Setting,
  appearance__primaryAccentColor: Setting,
  appearance__reverseFontColor: Setting,
  appearance__secondaryAccentColor: Setting,
  appearance__sidebarColor: Setting,
  appearance__sidebarTextColor: Setting,
  appearance__priceBackgroundColor: Setting,
  appearance__priceTextColor: Setting,
  appearance__coverTextAndIconsColor: Setting,
  appearance__overlays__displayForMainPhoto: Setting,
  appearance__overlays__displayForProductPhotos: Setting,
  appearance__displayBusinessTitleAndSubtitle: Setting,
  appearance__phoneNumbers__displayHomepageBranchPhone: Setting,
  appearance__phoneNumbers__displayMenuContactPhone: Setting,
  appearance__phoneNumbers__displayTrackingPagePhone: Setting,
  appearance__categoriesLayoutMode: Setting,
  appearance__displayCategoryTitleAndNumberOfItems: Setting,
  appearance__categoryTitleColor: Setting,
  appearance__overlays__displayForCategoryPhotos: Setting,
  domainName: Setting,
  layoutMode: Setting,
  introText__en: Setting,
  introText__ar: Setting,
  photoUrl__en: Setting,
  photoUrl__ar: Setting,
  selectedAreas: Setting,
  showAreas: Setting,
  displayCutlery: Setting,
  appearance__addresses__displayBranchAddress: Setting,
  storefront__businessHours: Setting,
  storefront__isAvailable: Setting,
  storefront__timezoneId: Setting,
  storefront__acceptingOrders: Setting,
  storefront__expandCategory: Setting,
  storefront__services__delivery__payment__cash__enabled: Setting,
  storefront__services__collection__payment__cash__enabled: Setting,
  orderPreparationTime: Setting,
  logoLayoutMode: Setting,
  analytics__googleAnalyticsId: Setting,
  analytics__facebookPixelId: Setting,
  frontPage: Setting,
  scheduledOrdersEnabled: Setting,
  receipts__autoPrint: Setting,
  receipts__displayAcceptAndPrint: Setting,
  receipts__template: Setting,
  receipts__printRestaurantLogo: Setting,
  asapOrdersEnabled: Setting,
  orderProcessing__limits__scheduled__enabled: Setting,
  orderProcessing__limits__scheduled__options__cutOffTime: Setting,
  orderProcessing__limits__scheduled__options__minDays: Setting,
  orderProcessing__limits__scheduled__options__maxDays: Setting,
  guestCheckoutOnly: Setting,
  storefront__timeSlot__duration: Setting,
  appearance__fontName__en: Setting,
  appearance__fontName__ar: Setting
}

class BusinessStorefrontSettingsController extends StorefrontSettingsController {
  public business: DBMappedNamedEntity
  public availableDomains: string[]
  public selectedDomain: string
  public subdomain: string
  public errorCode: string = null
  public draewilTechDomain: string = DRAEWIL_TECH_DOMAIN
  public frontPageOptions: typeof FrontPageOptions = FrontPageOptions

  public simpleSubdomainValidationPattern: RegExp = /^[\w\d\-]*$/
  public complexSubdomainValidationPattern: RegExp = /^([\w\d\-]*\.)?[\w\d\-]*$/
  public googleAnaluticsValidationPattern: RegExp = /^ua-\d{4,9}-\d{1,4}$/i
  public facebookPixelValidationPattern: RegExp = /^\d{0,20}$/
  public scheduledDaysLimitValidationPattern: RegExp = /^([0-9]|[1-2][0-9]|30)$/
  public timeValidationPattern: RegExp = /^(([0-1][0-9]|2[0-3]):[0-5][0-9]|24:00)$/
  public fontNameValidationPattern: RegExp = /^[\w\s]*$/

  public checkoutModeOptions: CheckoutModeOptions[] = []
  public selectedCheckoutModeOption: CheckoutModeOptions = CheckoutModeOptions.STANDARD

  public defaultLocaleOptions: string[] = envConfig.supportedLocales
  public posGenerationModeOptions: PosGenerationModeOptionType[] = POS_GENERATION_MODE_OPTIONS
  public posIncrementModeOptions: PosIncrementModeOptionType[] = POS_INCREMENT_MODE_OPTIONS

  public isUserHaveActiveSubscriptions: boolean = false

  public whatsAppPhoneNumber: PhoneNumber = PhoneNumber.build({
    countryDiallingCode: envConfig.defaultCountryCode.toString()
  })

  public DOMAIN_SETTING_KEY: string = DOMAIN_SETTING_KEY
  public isSuperCustomDomain: boolean

  public areScheduledLimitsValid: boolean = true

  public defaulScheduledMinDays: number = envConfig.storefrontOrdersSchedulingLimitsDefaults.minDays
  public defaulScheduledMaxDays: number = envConfig.storefrontOrdersSchedulingLimitsDefaults.maxDays

  public orderReceiptTemplateOptions: StorefrontOrderReceiptTemplates[] = [
    StorefrontOrderReceiptTemplates.A4,
    StorefrontOrderReceiptTemplates.THERMO
  ]

  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams,
    public $filter: ng.IFilterService
  ) {
    super($scope, $state)

    this.checkoutModeOptions = [CheckoutModeOptions.STANDARD, CheckoutModeOptions.GUEST_ONLY]
  }

  $onInit(): void {
    if (this.$stateParams.businessId) {
      this.sync()
    }
    this.$scope.$watch('$ctrl.subdomain', (): void => this.localSettings && this.onDomainChanged(this.selectedDomain))

    this.$scope.$watchGroup([
      '$ctrl.localSettings.orderProcessing__limits__scheduled__enabled',
      '$ctrl.localSettings.orderProcessing__limits__scheduled__options__minDays',
      '$ctrl.localSettings.orderProcessing__limits__scheduled__options__maxDays'
    ], (changesDescriptor: string[]) => {
      if (this.localSettings) {
        const areLimitsEnabled = this.localSettings.orderProcessing__limits__scheduled__enabled
        const minDays = parseInt(this.localSettings.orderProcessing__limits__scheduled__options__minDays || this.defaulScheduledMinDays)
        const maxDays = parseInt(this.localSettings.orderProcessing__limits__scheduled__options__maxDays || this.defaulScheduledMaxDays)

        this.areScheduledLimitsValid = !areLimitsEnabled || (minDays <= maxDays)
      }
    })

    this.$scope.$watch('$ctrl.selectedCheckoutModeOption', () => {
      const valueMapper = {
        [CheckoutModeOptions.STANDARD]: false,
        [CheckoutModeOptions.GUEST_ONLY]: true
      }

      if (this.localSettings) {
        this.localSettings.guestCheckoutOnly = !!valueMapper[this.selectedCheckoutModeOption]
      }

    })
  }

  public get posGenerationModeIsLinear(): boolean {
    return this.localSettings && this.localSettings.posReference__generationMode === PosGenerationModeOptionType.LINEAR
  }

  public get posIncrementModeIsFixed(): boolean {
    return this.localSettings && this.localSettings.posReference__linear__incrementMode === PosIncrementModeOptionType.FIXED
  }

  public get displayPosMaximumLimitError(): boolean {
    return this.localSettings && this.localSettings.posReference__linear__minimum >= this.localSettings.posReference__linear__maximum
  }

  onSync(): Promise<void> {
    return Promise.all([
      businessesCollection.get(this.$stateParams.businessId),
      businessesCollection.getStorefrontSettings(this.$stateParams.businessId),
      storefrontSubscriptionsCollection.getIsStorefrontBusinessSubscriptionPurchased(this.$stateParams.businessId),
      storefrontDomainsCollection.getAllDomains()
    ])
      .then(([business, settings, haveActiveSubscriptions, domains]: any[]): void => {
        this.business = business
        this.handleSettingsReceived(Object.assign(businessOnlySettings, settings))
        this.handleDomainsReceived(domains)

        this.isUserHaveActiveSubscriptions = haveActiveSubscriptions
      })
      .catch((error: Error): void => this.logger.error('Cannot get business or business storefront settings', error))
  }

  handleSettingsReceived(settings: IBusinessStorefrontSettings): void {
    super.handleSettingsReceived(Object.assign(businessOnlySettings, settings))

    if (this.localSettings[WHATSAPP_PHONE_NUMBER_SETTING_KEY]) {
      this.whatsAppPhoneNumber = PhoneNumber.build(JSON.parse(this.localSettings[WHATSAPP_PHONE_NUMBER_SETTING_KEY]))
    }

    this.selectedCheckoutModeOption = !!this.localSettings.guestCheckoutOnly ? CheckoutModeOptions.GUEST_ONLY : CheckoutModeOptions.STANDARD
  }

  handleDomainsReceived(domains: DBMappedNamedEntity[]): void {
    this.availableDomains = domains.map((domain: DBMappedNamedEntity): string => domain.name)
    this.subdomain = ''
    this.selectedDomain = this.localSettings[DOMAIN_SETTING_KEY] ?
      this.localSettings[DOMAIN_SETTING_KEY].match(/[^\.]*\.[^\.]*$/)[0] :
      this.availableDomains[0]

    if (this.localSettings[DOMAIN_SETTING_KEY]) {
      this.subdomain = this.localSettings[DOMAIN_SETTING_KEY].replace(/\.[^\.]*\.[^\.]*$/, '')
      this.selectedDomain = this.localSettings[DOMAIN_SETTING_KEY].match(/[^\.]*\.[^\.]*$/)[0]
      this.isSuperCustomDomain = !this.availableDomains.filter((domain: string) => {
        return this.localSettings[DOMAIN_SETTING_KEY].indexOf(domain) > -1
      }).length
    }
  }

  onDomainChanged(selectedDomain: string): void {
    if (!this.isSuperCustomDomain) {
      this.localSettings[DOMAIN_SETTING_KEY] = this.subdomain && selectedDomain ? `${this.subdomain}.${selectedDomain}` : ''
    }
  }

  onToggleClick(): void {
    if (!this.isUserHaveActiveSubscriptions) {
      this.$scope.$emit(STOREFRONT_OPEN_PURCHASE_STOREFRONT_POPUP_EVENT)
    }
  }

  beforeSubmit(): Promise<IBusinessStorefrontSettings> {
    this.localSettings[WHATSAPP_PHONE_NUMBER_SETTING_KEY] = this.whatsAppPhoneNumber.phoneNumber ? JSON.stringify(this.whatsAppPhoneNumber) : null
    this.localSettings[SCHEDULED_MIN_DAYS_LIMIT_SETTING_KEY] = parseInt(this.localSettings[SCHEDULED_MIN_DAYS_LIMIT_SETTING_KEY], 10)
    this.localSettings[SCHEDULED_MAX_DAYS_LIMIT_SETTING_KEY] = parseInt(this.localSettings[SCHEDULED_MAX_DAYS_LIMIT_SETTING_KEY], 10)
    this.localSettings[WEBSITE_ADDRESS_SETTING_KEY] = this.upadateWebsiteAddress(this.localSettings[WEBSITE_ADDRESS_SETTING_KEY])
    this.localSettings[CUTOFF_TIME_SETTING_KEY] = this.localSettings[CUTOFF_TIME_SETTING_KEY] ? this.localSettings[CUTOFF_TIME_SETTING_KEY] : MIDNIGHT

    super.beforeSubmit()

    return businessesCollection.updateStorefrontSettings(
      this.getUpdatedSettings(),
      this.business.id
    )
  }

  afterSubmitFailure(error: any): Promise<any> {
    this.errorCode = (error && error.code && error.code.replace('ERR_', '')) || GENERIC_ERROR_CODE
    return super.afterSubmitFailure(error)
  }

  upadateWebsiteAddress(websiteAddress: string): string {
    if (websiteAddress == null || websiteAddress === '') {
      return ''
    }

    const lowerCaseWebsiteAddress = websiteAddress.toLowerCase()

    if (lowerCaseWebsiteAddress.includes(ProtocolTypes.HTTP) ||
      lowerCaseWebsiteAddress.includes(ProtocolTypes.HTTPS)) {
      return websiteAddress
    }

    return ProtocolTypes.HTTP + websiteAddress
  }

  setCheckoutMode(value: CheckoutModeOptions): void {
    const valueMapper = {
      [CheckoutModeOptions.STANDARD]: false,
      [CheckoutModeOptions.GUEST_ONLY]: true
    }

    this.localSettings.guestCheckoutOnly = valueMapper[value]
  }

  sanitizeSocialNickname(settingKey: string): void {
    const lastURLSegmentRegexp = /([^\/]+)\/?$/
    const queryParamsRegexp = /\?[^\/\?]*$/

    if (this.localSettings[settingKey]) {
      this.localSettings[settingKey] = this.localSettings[settingKey].replace(queryParamsRegexp, '')

      const matches = this.localSettings[settingKey].match(lastURLSegmentRegexp)

      if (matches && matches.length) {
        this.localSettings[settingKey] = matches[0].replace('/', '')
      }
    }
  }
}

export default {
  controller: BusinessStorefrontSettingsController,
  templateUrl: require('./business-storefront-settings.pug')
}
