import { StateService } from '@uirouter/core'

enum LogoDisplayOptionType {
  hide = 'hide',
  round = 'round',
  square = 'square',
  freeForm = 'freeForm'
}

const LOGO_DISPLAY_OPTIONS: LogoDisplayOptionType[] = [
  LogoDisplayOptionType.hide,
  LogoDisplayOptionType.round,
  LogoDisplayOptionType.square,
  LogoDisplayOptionType.freeForm
]

enum LogoInSidebarDisplayOptionType {
  dontDisplay = 'dontDisplay',
  round = 'round',
  square = 'square',
  freeForm = 'freeForm'
}

const LOGO_IN_SIDEBAR_DISPLAY_OPTIONS: LogoInSidebarDisplayOptionType[] = [
  LogoInSidebarDisplayOptionType.dontDisplay,
  LogoInSidebarDisplayOptionType.round,
  LogoInSidebarDisplayOptionType.square,
  LogoInSidebarDisplayOptionType.freeForm
]

enum LogoInNavbarDisplayOptionType {
  dontDisplay = 'dontDisplay',
  round = 'round',
  square = 'square',
  freeForm = 'freeForm',
  text = 'text'
}

const LOGO_IN_NAVBAR_DISPLAY_OPTIONS: LogoInNavbarDisplayOptionType[] = [
  LogoInNavbarDisplayOptionType.dontDisplay,
  LogoInNavbarDisplayOptionType.round,
  LogoInNavbarDisplayOptionType.square,
  LogoInNavbarDisplayOptionType.freeForm,
  LogoInNavbarDisplayOptionType.text
]

enum DeliveryStatusToShowMapOptionType {
  toDropoff = 'toDropoff',
  toPickup = 'toPickup'
}

const DELIVERY_STATUS_TO_SHOW_MAP_OPTIONS: DeliveryStatusToShowMapOptionType[] = [
  DeliveryStatusToShowMapOptionType.toDropoff,
  DeliveryStatusToShowMapOptionType.toPickup
]

const HOMEPAGE_PHONE_DISPLAY_OPTIONS = [
  null,
  'aligned',
  'column'
]

class BusinessStorefrontAppearanceSettingsController {
  // inputs
  public allSettings: any
  public form: ng.IFormController

  // props
  public logoDisplayOptions: LogoDisplayOptionType[] = LOGO_DISPLAY_OPTIONS
  public logoInSidebarDisplayOptions: LogoInSidebarDisplayOptionType[] = LOGO_IN_SIDEBAR_DISPLAY_OPTIONS
  public logoInNavbarDisplayOptions: LogoInNavbarDisplayOptionType[] = LOGO_IN_NAVBAR_DISPLAY_OPTIONS
  public deliveryStatusToShowMapOptions: DeliveryStatusToShowMapOptionType[] = DELIVERY_STATUS_TO_SHOW_MAP_OPTIONS
  public homepagePhoneDisplayOptions: string[] = HOMEPAGE_PHONE_DISPLAY_OPTIONS

  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
  ) { }

}

export default {
  controller: BusinessStorefrontAppearanceSettingsController,
  templateUrl: require('./business-storefront-appearance-settings.pug'),
  bindings: {
    allSettings: '<',
    form: '<'
  }
}
