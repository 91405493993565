import Place from 'data/domain-objects/Place'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import CrudService from 'data/services/CrudService'
import endpoints from 'common/endpoints'
import PaginationParameters from 'data/domain-objects/PaginationParameters'
import envConfig from 'common/envConfig'

export enum OrderDirections {
  asc = 1,
  desc = -1
}

class PlacesCollection {
  public placesService: CrudService = new CrudService(endpoints.PLACES)
  public placeService: CrudService = new CrudService(endpoints.PLACE)
  public placeAllLanguagesService: CrudService = new CrudService(endpoints.PLACE_ALL_LANGUAGES)
  public placeCategoriesService: CrudService = new CrudService(endpoints.PLACE_CATEGORIES)

  public getPlaces = (
    skip: number = 0,
    limit: number = envConfig.pagination.defaultLimit,
    search?: string,
    categoryId?: string,
    orderBy?: string,
    orderDirection?: OrderDirections
  ): Promise<{ places: Place[], paginationParams: PaginationParameters }> => {
    return this.placesService.get([], {
      skip,
      limit,
      search,
      categoryId,
      orderBy,
      orderDirection
    }, true)
      .then((response: any) => {
        return {
          places: response.places.map(Place.build),
          paginationParams: PaginationParameters.build(response.metadata)
        }
      })
  }

  public getPlace = (placeId: string): Promise<Place> => {
    return this.placeAllLanguagesService.get([placeId])
      .then((place: any) => Place.build(place))
  }

  public getPlacesCategories = (): Promise<DBMappedNamedEntity[]> => {
    return this.placeCategoriesService.get()
      .then((categories: any) => categories.map(DBMappedNamedEntity.build))
  }

  public createPlace = (place: Place): Promise<Place> => {
    return this.placeService.create(place.toPostJSON())
      .then((createdPlace: any) => Place.build(createdPlace))
  }

  public updatePlace = (place: Place): Promise<void> => {
    const putPlace = place.toPutJSON()
    return this.placeService.update(putPlace, [place.id])
  }

  public deletePlace = (placeId: string): Promise<void> => {
    return this.placeService.delete([placeId])
  }
}

export const placesCollection = new PlacesCollection()
