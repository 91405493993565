import templateUrl from './calendar-range-picker.pug'
import {DATE_TIME_PICKER_CHANGE_DATE_EVENT, DATE_TIME_PICKER_OPEN_EVENT} from 'presentation/_core-forms/date-time-picker/dateTimePicker'
import envConfig from 'common/envConfig'
import moment from 'moment'
import DateUtils from 'common/utils/dateUtils'

class CalendarRangePickerController {
  constructor ($scope, $filter) {
    this.$scope = $scope
    this.$filter = $filter
    this.isCloseButtonVisible = false
    this.dateTimePickerConfig = {
      isRange: true
    }
  }

  $onChanges () {
    this.dateTimePickerConfig.minDate = this.minDate
    this.dateTimePickerConfig.maxDate = this.maxDate
    this.dateTimePickerConfig.showTime = !!this.showTime

    if (!this.placeholder) {
      this.placeholder = this.$filter('translate')('COMPONENTS.CALENDAR_RANGE_PICKER.PLACEHOLDER')
    }
    if (!this.format) {
      this.format = this.showTime ? envConfig.defaultDateFormat : envConfig.dateOnlyDateFormat
    }

    if (this.maxDate) {
      this.maxDate = new Date(this.maxDate)
    }

    if (this.startDate && this.finishDate) {
      this.startDate = new Date(this.startDate)
      this.finishDate = new Date(this.finishDate)
      this.isCloseButtonVisible = true
      this.formatDate()
    }
  }

  formatDate () {
    this.value = `${moment(this.startDate).format(this.format)} — ${moment(this.finishDate).format(this.format)}`
  }

  openCalendar () {
    if (this.isDisabled) {
      return
    }
    if (this.startDate && this.finishDate) {
      this.dateTimePickerConfig.valueFrom = this.startDate
      this.dateTimePickerConfig.valueTo = this.finishDate
    }

    this.$scope.$emit(DATE_TIME_PICKER_OPEN_EVENT, this.dateTimePickerConfig)

    this.DateTimePickerSubscriptionDestructor = this.$scope.$on(DATE_TIME_PICKER_CHANGE_DATE_EVENT, (event, payload) => {
      this.startDate = payload.dateFrom
      this.finishDate = payload.dateTo
      this.isCloseButtonVisible = true
      this.formatDate()

      this.onChangeDate({payload: {
        dateFrom: this.showTime ? payload.dateFrom : DateUtils.getStartOfDay(payload.dateFrom),
        dateTo: this.showTime ? payload.dateTo : DateUtils.getEndOfDay(payload.dateTo)
      }})

      this.DateTimePickerSubscriptionDestructor()
    })
  }

  resetDates ($event) {
    $event.preventDefault()
    $event.stopPropagation()
    this.startDate = null
    this.finishDate = null
    this.dateTimePickerConfig.valueFrom = null
    this.dateTimePickerConfig.valueTo = null
    this.isCloseButtonVisible = false
    this.value = ''

    this.onChangeDate({payload: {
      dateFrom: null,
      dateTo: null
    }})
  }
}

export default {
  templateUrl,
  controller: CalendarRangePickerController,
  bindings: {
    minDate: '<?',
    maxDate: '<?',
    showTime: '<?',
    onChangeDate: '&',
    startDate: '<?',
    finishDate: '<?',
    placeholder: '@?',
    format: '@?',
    hostClass: '@?',
    isDisabled: '<'
  }
}
