import Logger from 'common/Logger'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
const ESC_KEYCODE = 27

export default class BasePopupController {
  public removeOpenPopupEventListener: () => void
  public escListener: (event: KeyboardEvent) => void
  public isVisible: boolean = false
  public logger: Logger

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IScope,
    public actionEvent: string,
    public popupId: string
  ) {
    this.logger = new Logger(this.constructor.name)
  }

  $onDestroy (): void {
    if (typeof this.removeOpenPopupEventListener === 'function') {
      this.removeOpenPopupEventListener()
    }
    this.removeESCListener()
  }

  addEscListener (): void {
    this.escListener = document.onkeyup
    document.onkeyup = (event: KeyboardEvent): void => this.onKeyPress(event)
  }

  removeESCListener (): void {
    if (this.escListener) {
      document.onkeyup = this.escListener
      this.escListener = null
    }
  }

  onKeyPress (event: KeyboardEvent): void {
    if (event && event.keyCode === ESC_KEYCODE && this.isVisible) {
      event.preventDefault()
      event.stopPropagation()
      this.close()
    }
  }

  close (): void {
    this.isVisible = false
    this.removeESCListener()
    this.onClose()
    modalDialogStateService.emitCloseModalDialog({dialogId: this.popupId})
    setTimeout(() => this.$scope.$digest())
  }

  onClose (): void {}

  setupPopupOpenEventListener (callback: (payload: any) => void): void {
    this.removeOpenPopupEventListener = this.$rootScope.$on(this.actionEvent, (evt: ng.IAngularEvent, payload: any) => {
      if (typeof callback === 'function') {
        callback(payload)
      }
      this.addEscListener()
      modalDialogStateService.emitOpenModalDialog({dialogId: this.popupId})
      this.isVisible = true
    })
  }
}
