import fleetsCollection from 'data/collections/fleetsCollection'
import Fleet from 'data/domain-objects/Fleet'
import Logger from 'common/Logger'

const logger = new Logger('Fleet Select Controller')

class FleetSelectController {
  public selectedFleetId: string = null
  public fleets: Fleet[] = []
  public onFleetSelected: (data: {fleet: Fleet}) => void

  constructor (
    public $scope: ng.IScope
  ) {}

  $onInit (): void {
    fleetsCollection.getAllCached()
      .then((allFleets: Fleet[]): void => {
        this.fleets = allFleets
      })
      .catch((error: Error): void => logger.error('Cannot get fleets', error))
      .then(() => this.$scope.$digest())
  }

  onChange (suggestion: any): void {
    this.onFleetSelected({fleet: suggestion})
    this.selectedFleetId = suggestion ? suggestion.id : null
  }
}

export default {
  templateUrl: require('./fleet-select.pug'),
  controller: FleetSelectController,
  bindings: {
    onFleetSelected: '&'
  }
}
