import endpoints from 'common/endpoints'
import Logger from 'common/Logger'
import CrudService from 'data/services/CrudService'

const logger = new Logger('Driver Model')
const driverService = new CrudService(endpoints.DRIVER)

let driver = {}

export default {
  clear,
  get,
  update,
  updateDriverAsOnBoard
}

function clear () {
  driver = {}
}

function get (id) {
  return new Promise((resolve, reject) => {
    if (Object.keys(driver).length > 0 && (driver.id === id)) {
      logger.info('Driver already held locally.')
      resolve(driver)
    } else {
      logger.info('Requesting driver from server')
      driverService.get([id])
        .then(handleGetDriverSuccess.bind(null, resolve))
        .catch(handleGetDriverFailed.bind(null, reject))
    }
  })
}

function handleGetDriverSuccess (resolve, response) {
  logger.info('Received driver from server:', response)
  driver = response
  resolve(response)
}

function handleGetDriverFailed (reject, error) {
  logger.error('Failed to get driver from server:', error)
  reject()
}

function updateDriverAsOnBoard () {
  logger.info('Sending driver update to server.')
  driver.onboarded = true

  return new Promise((resolve, reject) => {
    logger.info('Sending driver onboarded to server.')
    driverService.update(driver, [driver.id])
      .then(handleDriverUpdated.bind(null, resolve))
      .catch(handleDriverNotUpdated.bind(null, reject))
  })
}

function update (formModel) {
  if (formModel.make) {
    driver.vehicle = formModel
  } else {
    driver = Object.assign(driver, formModel)
  }

  return new Promise((resolve, reject) => {
    logger.info('Sending driver update to server.')
    driverService.update(driver, [driver.id])
      .then(handleDriverUpdated.bind(null, resolve))
      .catch(handleDriverNotUpdated.bind(null, reject))
  })
}

function handleDriverUpdated (resolve, response) {
  logger.info('Driver updated:', response)
  driver = response
  resolve(driver)
}

function handleDriverNotUpdated (reject, error) {
  logger.info('Driver updated faile:', error)
  reject(error) // Pass error back so that form can consume validation errors.
}
