import Cost from './Cost'
import { uuid } from 'uuidv4'
import MultiLangString from './MultiLangString'
import ProductPreparationData from './ProductPreparationData'
import { BasePriceDisplayMode } from 'common/constants/BasePriceDisplayMode'
import { BusinessHours } from './BusinessHours'

const PRODUCT_CLONE_NAME_PREFIX = 'Copy of'

class EntityWithSanitizibleIdAndCost {
  constructor (
    public id: string = ''
  ) {}

  toJSON (): any {
    const sanitizedObject = Object.assign({}, this)

    if (!sanitizedObject.id) {
      delete sanitizedObject.id
    }

    Object.keys(sanitizedObject).forEach((fieldKey: string): void => {
      if (sanitizedObject[fieldKey] instanceof Cost) {
        sanitizedObject[fieldKey] = sanitizedObject[fieldKey].toJSON()
      }
    })

    return sanitizedObject
  }
}

export class ProductAppearance {
  constructor (
    public basePriceDisplayMode: BasePriceDisplayMode
  ) { }

  static build (dto: any = {}): ProductAppearance {
    return new ProductAppearance(
      dto.basePriceDisplayMode || BasePriceDisplayMode.SHOW
    )
  }
}

export class ProductExtra extends EntityWithSanitizibleIdAndCost {
  constructor (
    public id: string = '',
    public name: MultiLangString,
    public maximumAmount: number,
    public pricePerExtra: Cost
  ) {
    super()
  }

  static build (dto: any = {}): ProductExtra {
    const maximumAmount = parseInt(dto.maximumAmount)
    return new ProductExtra(
      dto.id || '',
      MultiLangString.build(dto.name),
      (isNaN(maximumAmount) || maximumAmount === 0) ? null : maximumAmount,
      Cost.build(dto.pricePerExtra)
    )
  }
}

export class ProductOption extends EntityWithSanitizibleIdAndCost {
  constructor (
    public id: string = '',
    public name: MultiLangString,
    public optionPrice: Cost
  ) {
    super()
  }

  static build (dto: any = {}): ProductOption {
    return new ProductOption(
      dto.id || '',
      MultiLangString.build(dto.name),
      Cost.build(dto.optionPrice)
    )
  }
}

export class ProductPropertyValue extends EntityWithSanitizibleIdAndCost {
  constructor (
    public id: string = uuid(),
    public name: MultiLangString,
    public priceAdjustment: Cost
  ) {
    super()
  }

  static build (dto: any = {}): ProductPropertyValue {
    return new ProductPropertyValue(
      dto.id,
      MultiLangString.build(dto.name),
      Cost.build(dto.priceAdjustment)
    )
  }
}

export class ProductProperty extends EntityWithSanitizibleIdAndCost {
  constructor (
    public id: string = uuid(),
    public name: MultiLangString,
    public minAmount: number,
    public maxAmount: number,
    public values: ProductPropertyValue[],
    public parentProperties: IParentProductProperty[]
  ) {
    super()
  }

  static build (dto: any = {}): ProductProperty {
    return new ProductProperty(
      dto.id,
      MultiLangString.build(dto.name),
      dto.minAmount,
      dto.maxAmount,
      (dto.values || []).map((value: ProductPropertyValue): ProductPropertyValue => ProductPropertyValue.build(value)),
      dto.parentProperties || []
    )
  }

  get isValid (): boolean {
    const isMinPassed = !this.minAmount || this.values.length >= this.minAmount
    return this.values.length && isMinPassed
  }

  toJSON (): any {
    const sanitizedObject = super.toJSON()
    sanitizedObject.values = this.values.map((value: ProductPropertyValue): any => value.toJSON())
    sanitizedObject.minAmount = (sanitizedObject.minAmount === '' || sanitizedObject.minAmount === null) ? undefined : sanitizedObject.minAmount
    sanitizedObject.maxAmount = (sanitizedObject.maxAmount === '' || sanitizedObject.maxAmount === null) ? undefined : sanitizedObject.maxAmount
    return sanitizedObject
  }
}

export default class Product {
  constructor (
    public id: string,
    public name: MultiLangString,
    public extrasTitle: MultiLangString,
    public description: MultiLangString,
    public photoUrl: string,
    public sku: string,
    public basePrice: Cost,
    public appearance: ProductAppearance,
    public branches: string[],
    public categories: string[],
    public extras: ProductExtra[],
    public options: ProductOption[],
    public properties: ProductProperty[],
    public tags: string[],
    public isAvailable: boolean,
    public extrasMinAmount: number,
    public extrasMaxAmount: number,
    public preparation: ProductPreparationData,
    public businessHours: BusinessHours
  ) {}

  get isValid (): boolean {
    const invalidProperties = this.properties.filter((property: ProductProperty): boolean => !property.isValid)
    return this.categories.length && !invalidProperties.length
  }

  static build (dto: any = {}): Product {
    return new Product(
      dto.id || '',
      MultiLangString.build(dto.name),
      MultiLangString.build(dto.extrasTitle),
      MultiLangString.build(dto.description),
      dto.photoUrl || '',
      dto.sku || '',
      Cost.build(dto.basePrice),
      ProductAppearance.build(dto.appearance || {}),
      dto.branches || [],
      dto.categories || [],
      dto.extras ? dto.extras.map((extra: ProductExtra): ProductExtra => ProductExtra.build(extra)) : [],
      dto.options ? dto.options.map((option: ProductOption): ProductOption => ProductOption.build(option)) : [],
      dto.properties ? dto.properties.map((property: ProductProperty): ProductProperty => ProductProperty.build(property)) : [],
      dto.tags || [],
      !!dto.isAvailable,
      dto.extrasMinAmount || 0,
      dto.extrasMaxAmount,
      ProductPreparationData.build(dto.preparation || {}),
      BusinessHours.build(dto.businessHours || {})
    )
  }

  static sanitize (product: Product): any {
    const sanitizedProduct = Object.assign({}, product)
    delete sanitizedProduct.id
    delete sanitizedProduct.isAvailable

    sanitizedProduct.basePrice = product.basePrice.toJSON() as any
    sanitizedProduct.extras = sanitizedProduct.extras.map((extra: ProductExtra): any => extra.toJSON())
    sanitizedProduct.options = sanitizedProduct.options.map((option: ProductOption): any => option.toJSON())
    sanitizedProduct.properties = sanitizedProduct.properties.map((property: ProductProperty): any => property.toJSON())

    sanitizedProduct.extrasMaxAmount = sanitizedProduct.extrasMaxAmount ? sanitizedProduct.extrasMaxAmount : undefined

    sanitizedProduct.preparation.expectedTime = sanitizedProduct.preparation.expectedTime ? sanitizedProduct.preparation.expectedTime : null

    return sanitizedProduct
  }

  static clone (product: Product): Product {
    const productClone = Product.build(product)
    const idReplacementMap = {}

    productClone.id = undefined
    productClone.name.en = productClone.name.en ? `${PRODUCT_CLONE_NAME_PREFIX} ${productClone.name.en}` : ''
    productClone.name.ar = productClone.name.ar ? `${PRODUCT_CLONE_NAME_PREFIX} ${productClone.name.ar}` : ''

    productClone.properties.forEach((property: ProductProperty): void => {
      const oldPropertyId = property.id
      property.id = uuid()

      idReplacementMap[oldPropertyId] = property.id

      property.values.forEach((value: ProductPropertyValue): void => {
        const oldValueId = value.id
        value.id = uuid()
        idReplacementMap[oldValueId] = value.id
      })
    })

    productClone.extras.forEach((extra: ProductExtra): void => {
      extra.id = undefined
    })

    productClone.options.forEach((option: ProductOption): void => {
      option.id = undefined
    })

    const stringifiedProductClone = Object.keys(idReplacementMap)
      .reduce((accum: string, idToReplace: string): string => {
        const idReplacementRegExp = new RegExp(`${idToReplace}`, 'gi')
        return accum.replace(idReplacementRegExp, idReplacementMap[idToReplace])
      }, JSON.stringify(productClone))

    return Product.build(JSON.parse(stringifiedProductClone))
  }
}
