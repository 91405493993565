import Cost from 'data/domain-objects/Cost'
import Duration from 'data/domain-objects/Duration'
import BaseSurcharge from 'data/domain-objects/BaseSurcharge'
import * as MeasureUnits from 'common/constants/MeasureUnits'
import {valueToAmount} from 'data/common/costFixer'

export default class StatusSurcharge extends BaseSurcharge {
  constructor (
    public price: Cost,
    public step: Duration,
    public offset: Duration,
    public status: string
  ) {
    super(price, step)
  }

  static build (dto: any = {}): StatusSurcharge {
    const step = dto.step || {unitCode: MeasureUnits.MINU}
    const offset = dto.offset || {unitCode: MeasureUnits.MINU}
    return new StatusSurcharge(
      Cost.build(dto.price),
      Duration.build(step),
      Duration.build(offset),
      dto.status || ''
    )
  }

  toJSON(): any {
    return valueToAmount(this, ['price'])
  }
}
