import cloneDeep from 'lodash-es/cloneDeep'
import toUpperSnakeCase from 'common/toUpperSnakeCase'

const MAPPING_TYPES = {
  ADDRESS: 'ADDRESS',
  DETAILS: 'DETAILS'
}
const PRESENTATION_MAPPINGS_BY_TYPE = {
  HOUSE: {
    block: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    street: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'large'
    },
    avenue: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    house: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    additionalDirections: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'full-width'
    }
  },
  APARTMENT: {
    block: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    street: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'large'
    },
    avenue: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    building: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    floor: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'large'
    },
    apartmentNumber: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'large'
    },
    additionalDirections: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'full-width'
    }
  },
  OFFICE: {
    block: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    street: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'large'
    },
    avenue: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    building: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    floor: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'large'
    },
    office: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'large'
    },
    additionalDirections: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'full-width'
    }
  },
  POINT_ON_MAP: {
    block: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'large'
    },
    street: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'large'
    },
    avenue: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    building: {
      grouping: MAPPING_TYPES.ADDRESS,
      size: 'small'
    },
    floor: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'small'
    },
    apartmentNumber: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'small'
    },
    office: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'small'
    },
    additionalDirections: {
      grouping: MAPPING_TYPES.DETAILS,
      size: 'full-width'
    }
  }
}

function processAddressFormat (addressFormat) {
  const processedAddressFormat = cloneDeep(addressFormat)
  const processedFields = []
  const addressFields = {
    type: 'inline',
    name: 'address',
    innerFields: []
  }
  const detailsFields = {
    type: 'inline',
    name: 'details',
    innerFields: []
  }
  const presentationMappings = PRESENTATION_MAPPINGS_BY_TYPE[toUpperSnakeCase(processedAddressFormat.type)] || null

  processedAddressFormat.fields.forEach(field => {
    if (presentationMappings && presentationMappings.hasOwnProperty(field.name)) {
      const presentation = presentationMappings[field.name]

      field.presentation.size = presentation.size

      if (presentation.grouping === MAPPING_TYPES.ADDRESS) {
        addressFields.innerFields.push(field)
      } else if (presentation.grouping === MAPPING_TYPES.DETAILS) {
        detailsFields.innerFields.push(field)
      }
    } else {
      processedFields.push(field)
    }
  })

  if (addressFields.innerFields.length) { // Only push if we have inner fields to push
    processedFields.push(addressFields)
  }
  if (detailsFields.innerFields.length) {
    processedFields.push(detailsFields)
  }

  return processedFields
}

function getAddressFormatPresentationMap (addressFormats) {
  const map = {}

  addressFormats.forEach(addressFormat => {
    map[addressFormat.id] = processAddressFormat(addressFormat)
  })

  return map
}

export default {
  getAddressFormatPresentationMap
}
