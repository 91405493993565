import moment from 'moment'
import envConfig from 'common/envConfig'
import Cost from 'data/domain-objects/Cost'
import Voucher from 'data/domain-objects/Voucher'

import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import vouchersCollection from 'data/collections/vouchersCollection'

import template from 'presentation/tools/vouchers/voucher-form/voucher-form.pug'

/**
 * Controller for creating/editing of vouchers
 */
class VoucherFormController extends CreateViewEditFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state, $stateParams, 'voucherId')

    if (this.isCreate) {
      this.isEditing = true
    }

    // setting errMessage to any true value
    this.isError = false

    // don't allow to set voucher dates in the past
    this.minCalendarDate = moment(Date.now())
  }

  onChangeDate (payload) {
    this.model.dateRange = {
      startDate: payload.dateFrom,
      endDate: payload.dateTo
    }
  }

  getEntity () {
    return vouchersCollection.get(this.$stateParams.voucherId)
      .then(voucher => {
        this.setModel(voucher)
      })
      .catch(() => {
        this.isError = true
        this.setModel(this.getDefaultModel())
      })
      .then(() => {
        this.$scope.$apply()
      })
  }

  deleteVoucher () {
    this.isDeleting = true
    this.submit()
  }

  beforeSubmit () {
    if (this.isDeleting) {
      return vouchersCollection.delete(this.model.id)
    }

    if (this.isCreate) {
      return vouchersCollection.create(this.model.getProperModel())
    }

    return vouchersCollection.update(this.model.getProperModel())
  }

  afterSubmitSuccess () {
    this.$state.go('main.tools.vouchers')
  }

  afterSubmitFailure () {
    this.isError = true
    this.isEditing = true
  }

  /**
   * defaults to create new voucher
   */
  getDefaultModel () {
    return Voucher.build({
      limit: '',
      amount: Cost.build({amount: envConfig.vouchers.defaults.amount})
    })
  }

  cancel () {
    if (!this.isCreate && this.isEditing) {
      this.cancelEdit()
    } else {
      this.$state.go('main.tools.vouchers')
    }
  }
}

export default {
  templateUrl: template,
  controller: VoucherFormController
}
