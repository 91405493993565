import templateUrl from './signup.pug'
import BaseFormController from 'presentation/common/BaseFormController'
import envConfig from 'common/envConfig'
import SignupService from 'data/services/signupService.js'
import {isSameDomain} from 'common/utils/urlUtils'

class Signup extends BaseFormController {
  constructor ($scope, $state) {
    super($scope, $state)

    this.locationsNumberValues = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+']
    this.defaultCountryCode = envConfig.defaultCountry

    this.isStorefrontAdminDomain = isSameDomain(envConfig.platformDomains.storefrontAdminDomain)

    this.model = {
      email: null,
      phoneNumber: null,
      industry: null,
      locationsNumber: null,
      companyName: '',
      firstName: '',
      countryDiallingCode: null,
      countryCode: this.defaultCountryCode
    }
  }

  $onInit () {
    SignupService.getDefaultDiallingCode().then(defaultDiallingCode => this.model.countryDiallingCode = defaultDiallingCode)
    SignupService.getIndustriesList().then(industriesList => this.industryValues = industriesList)
  }

  onCountrySelected (country) {
    this.model.countryCode = country.code
  }

  beforeSubmit () {
    return SignupService.createBusiness(this.model)
  }

  afterSubmitSuccess () {
    this.$state.go('auth.signup-complete')
  }
}

export default {
  templateUrl,
  controller: Signup
}
