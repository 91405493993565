import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'
import industriesCollection from 'data/collections/industriesCollection'
const signupService = new CrudService(endpoints.SIGNUP)

import Logger from 'common/Logger'
const logger = new Logger('SignupService')

class SignupService {
  createBusiness (businessModel) {
    return signupService.create(businessModel)
  }

  getDefaultDiallingCode () {
    return diallingCodesCollection.getDefault()
      .catch(err => logger.error('Cannot get default country dialling code', err))
  }

  getIndustriesList () {
    return industriesCollection.getIndustries()
      .catch(err => logger.error('Cannot get industries list', err))
  }
}

export default new SignupService()
