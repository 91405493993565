
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

const deliveryBatchingTypesService = new CrudService(endpoints.DELIVERY_BATCHING_TYPES)

export function batchingCollectionFactory (
  deliveryBatchingTypesService
) {
  return {
    getAvailableDeliveryBatchingTypes: function () {
      return deliveryBatchingTypesService.get([], {useCache: true})
        .then(batchingTypes => batchingTypes.map(DBMappedNamedEntity.build))
    }
  }
}

export default batchingCollectionFactory(
  deliveryBatchingTypesService
)
