class EditableListSelectWithImageController {
  // inputs
  public form: ng.IFormController
  public isEditing: boolean
  public isDisabled: boolean
  public model: string
  public options: ISelectWithImageOption[]
  public onOptionSelected: (option: {value: string}) => {}
  public name: string
  public isRequred: boolean
  public title: string
  public hoistClass: string
  public valuePrefixTranslationKey: string

  constructor(
    private $scope: ng.IScope
  ) { }

  public selectOption(optionValue: string): void {
    if (this.isEditing && !this.isDisabled) {
      if (this.form) {
        this.form.$setDirty()
      }
      this.onOptionSelected({value: optionValue})
    }
  }
}

export default {
  controller: EditableListSelectWithImageController,
  templateUrl: require('./editable-list-select-with-image.pug'),
  bindings: {
    form: '<',
    isEditing: '<',
    isDisabled: '<',
    model: '<',
    options: '<',
    onOptionSelected: '&',
    name: '@',
    isRequired: '<',
    title: '@',
    hostClass: '@?',
    valuePrefixTranslationKey: '@?',
    valueMessage: '@',
    valueWarning: '@'
  }
}
