import templateUrl from './address-lookup-point-of-interest.pug'
import addressLookupService from 'data/services/addressLookupService'
import BaseController from 'presentation/common/BaseController'

class AddressLookupPointOfInterestController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.selectedSuggestion = null
    this.suggestions = []
  }

  onChange (suggestion) {
    this.selectedSuggestion = suggestion
  }

  onSuggestionsRequired (searchText) {
    addressLookupService.getAddressDescriptorsForSearchText(searchText)
      .then(addressMatchDescriptors => {
        this.suggestions = addressMatchDescriptors.map(AddressLookupPointOfInterestController.getSuggestionForAddressDescriptor)
      })
      .catch(error => {
        this.logger.error('Could not get address matches for search text:', searchText, error)
      })
      .then(() => this.$scope.$digest())
  }

  static getSuggestionForAddressDescriptor (addressDescriptor) {
    return {
      description: `${addressDescriptor.title} (${addressDescriptor.addressString})`,
      value: AddressLookupPointOfInterestController.getAddressForAddressDescriptor(addressDescriptor) // Ensure values are true address objects
    }
  }

  static getAddressForAddressDescriptor (addressDescriptor) {
    // Descriptor contains various fields that are only for backend, including an ID that is not a GET /address ID
    return {
      addressFormatId: addressDescriptor.addressFormatId,
      fields: addressDescriptor.fields,
      location: addressDescriptor.location
    }
  }

  onSuggestionsClearRequired () {
    this.suggestions = []
  }

  submit () {
    if (this.selectedSuggestion && this.selectedSuggestion.value) {
      this.onAddressLookup({address: this.selectedSuggestion.value})
    }
  }
}

export default {
  templateUrl,
  controller: AddressLookupPointOfInterestController,
  bindings: {
    onAddressLookup: '&'
  }
}
