import Task from './Task'
import Delivery from './Delivery'

export default class Job {
  constructor (
    public id: string = '',
    public tasks: Task[] = [],
    public deliveries: Delivery[] = [],
    public isRide: boolean = false
  ) {}

  static build(jobDTO: any = {}, tasks: Task[], deliveries: Delivery[]): Job {
    const isRide = !tasks.find((task: Task) => task.action === 'collect')
    return new Job(
      jobDTO.id,
      tasks,
      deliveries,
      isRide
    )
  }
}
