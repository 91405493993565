export function collectEntityFieldByIds (entities = [], fieldName = 'name') {
  return entities.reduce((accum, entity) => (accum[entity.id] = entity[fieldName]) && accum, {})
}

export function collectEntitiesByIds (entities) {
  return entities.reduce((accum, entity) => (accum[entity.id] = entity) && accum, {})
}

export function collectEntitiesByField (entities, fieldToMap) {
  return entities.reduce((accum, entity) => (accum[entity[fieldToMap]] = entity) && accum, {})
}

export function collectMappedEntitiesByIds (entities, mapFunction) {
  return entities.reduce((accum, entity) => (accum[entity.id] = mapFunction(entity)) && accum, {})
}

export default collectEntityFieldByIds
