import endpoints from 'common/endpoints'
import driverCollection from 'data/collections/driverCollection'
import driverModel from 'data/models/driverModel'
import templateUrl from './driver-verify-account.pug'
import BaseFormController from 'presentation/common/BaseFormController'

class DriverVerifyAccountController extends BaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.driver = {}
    this.model = ''
    this.phoneCodeResent = false
    this.showErrorUnknown = false
    this.wizardRouteSteps = [
      {
        iconClass: 'key',
        current: true,
        label: 'COMMON.DRIVER.ACCOUNT'
      },
      {
        iconClass: 'id-card',
        label: 'COMMON.DRIVER.LICENSE'
      },
      {
        iconClass: 'car',
        label: 'COMMON.DRIVER.VEHICLE'
      },
      {
        iconClass: 'cogs',
        label: 'COMMON.DRIVER.FLEETS'
      },
      {
        iconClass: 'check',
        label: 'COMMON.DRIVER.ACTIVE'
      }
    ]

    this.$scope.endPointDriverVerifcationCode = endpoints.DRIVER_VERIFICATION_CODE // @todo move to driverCollection
    this.$scope.serviceEndpoint = endpoints.DRIVER_VERIFICATION_CODE // @todo move to driverCollection
  }

  $onInit () {
    this.targetState = this.$state.get(this.$stateParams.targetState) ? this.$stateParams.targetState : ''
    this.getDriver()
  }

  redirectToDriverState () {
    driverModel.clear()
    this.$state.go('main.logistics.drivers.driver', this.$stateParams)
  }

  getDriver () {
    driverModel.get(this.$stateParams.driverId)
      .then(driver => this.driver = driver)
      .catch(err => this.handleGetDriverNotFound(err))
      .then(() => this.$scope.$digest())
  }

  resendPhoneCode () {
    this.phoneCodeResent = true
    driverCollection.sendVerification(this.$stateParams.driverId)
  }

  handleGetDriverNotFound () {}

  getDefaultModel () {
    return ''
  }

  beforeSubmit () {
    this.showErrorUnknown = false
    return driverCollection.verifyAccount(this.$stateParams.driverId, this.model)
  }

  afterSubmitSuccess () {
    this.redirectToDriverState()
  }

  afterSubmitFailure () {
    this.showErrorUnknown = true
  }
}

export default {
  templateUrl,
  controller: DriverVerifyAccountController
}
