import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'

import templateUrl from './team-member-edit.pug'
import BaseFormController from 'presentation/common/BaseFormController'
import rolesCollection from 'data/collections/rolesCollection'
import teamMembersCollection from 'data/collections/teamMembersCollection'
import TeamMember from 'data/domain-objects/TeamMember'

class TeamMemberController extends BaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)

    this.$stateParams = $stateParams
    this.teamMember = null
    this.originalTeamMember = {}
    this.isEditing = false
    this.rolesChanged = false
    this.rolesWithSupportingData = {}
    this.rolesAssignableWithSupportingData = {}

    this.addRoleToTeamMember = (roleId, entities) => this.addRole(roleId, entities)
    this.handleRemoveRole = roleId => this.removeRole(roleId)
  }

  $onInit () {
    this.getAllData()
  }

  addRole (roleId, entities) {
    this.teamMember.addRole(roleId, entities)
    this.setRolesHaveChanged()
  }

  removeRole (roleId) {
    this.teamMember.removeRole(roleId)
    this.setRolesHaveChanged()
  }

  setRolesHaveChanged () {
    this.rolesChanged = !isEqual(this.originalTeamMember.roles, this.teamMember.roles)
  }

  startEdit () {
    this.isEditing = true
  }

  cancelEdit () {
    this.isEditing = false
    this.teamMember = cloneDeep(this.originalTeamMember)
  }

  getAllData () {
    Promise.all([
      teamMembersCollection.get(this.$stateParams.teamMemberId),
      rolesCollection.getAllWithSupportingData(),
      rolesCollection.getAllAssignableWithSupportingData()
    ])
      .then(entityCollections => this.handleAllDataReceived(entityCollections))
      .then(() => this.$scope.$digest())
  }

  handleAllDataReceived (entityCollections) {
    const [teamMember, dataBundle, rolesAssignableWithSupportingData] = entityCollections
    this.originalTeamMember = TeamMember.build(
      teamMember,
      dataBundle.rolesById,
      dataBundle.businessesMap,
      dataBundle.branchesMap,
      dataBundle.fleetsMap
    )
    this.teamMember = cloneDeep(this.originalTeamMember)
    this.rolesWithSupportingData = dataBundle
    this.rolesAssignableWithSupportingData = rolesAssignableWithSupportingData
  }

  beforeSubmit () {
    return teamMembersCollection.update(this.teamMember, this.teamMember.id)
  }

  afterSubmitSuccess () {
    this.$state.go('main.users.user-management')
  }

  afterSubmitFailure (error) {
    this.notifySubmitError(error)
  }
}

export default {
  controller: TeamMemberController,
  templateUrl
}
