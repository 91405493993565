import Cost from 'data/domain-objects/Cost'
import Duration from 'data/domain-objects/Duration'
import BaseSurcharge from 'data/domain-objects/BaseSurcharge'
import * as MeasureUnits from 'common/constants/MeasureUnits'
import {valueToAmount} from 'data/common/costFixer'

export default class DurationSurcharge extends BaseSurcharge {
  constructor (
    public price: Cost,
    public step: Duration,
    public offset: Duration
  ) {
    super(price, step)
  }

  static build (dto: any = {}): DurationSurcharge {
    const step = dto.step || {unitCode: MeasureUnits.MINU}
    const offset = dto.offset || {unitCode: MeasureUnits.MINU}
    return new DurationSurcharge(
      Cost.build(dto.price),
      Duration.build(step),
      Duration.build(offset)
    )
  }

  toJSON(): any {
    return valueToAmount(this, ['price'])
  }
}
