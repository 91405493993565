import get from 'lodash-es/get'
import templateUrl from './package-details.pug'
import BaseController from 'presentation/common/BaseController'
import packageTypesCollection from 'data/collections/packageTypesCollection'

class PackageDetailsController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.allPackageTypes = []
    this.availablePackageTypes = []
    this.defaultPackage = null
    this.sync()
    this.onPackagesUpdated = packages => this.handlePackagesUpdated(packages)
  }

  $onChanges (changeDescriptors) {
    if (changeDescriptors.settings) {
      this.processAvailablePackageTypes()
    }
  }

  onSync () {
    return packageTypesCollection.getAll()
      .then(packageTypes => this.handlePackageTypesReceived(packageTypes))
  }

  handlePackageTypesReceived (packageTypes) {
    this.allPackageTypes = packageTypes
    this.processAvailablePackageTypes()
  }

  processAvailablePackageTypes () {
    this.availablePackageTypes = []
    let packageTypesSettings = null
    let showPackageTypeSettings = null

    if (this.settings) {
      packageTypesSettings = get(this.settings, 'package.value', null)
      showPackageTypeSettings = get(this.settings, 'showPackageType.value', 'show')

      try {
        packageTypesSettings = JSON.parse(packageTypesSettings)
      } catch (e) {
        packageTypesSettings = null
      }
    }

    if (packageTypesSettings) {
      this.allPackageTypes.forEach(packageType => this.addPackageTypeToAvailable(packageType, packageTypesSettings))
    } else {
      this.availablePackageTypes = this.allPackageTypes.slice(0)
      packageTypesSettings = {}
    }

    if (showPackageTypeSettings === 'hide' || (!this.selectedPackages || this.selectedPackages.length === 0)) {
      Object.keys(packageTypesSettings).forEach(packageId => {
        const packageTypeSettings = packageTypesSettings[packageId]
        if (packageTypeSettings.enabled && packageTypeSettings.isDefault) {
          this.handlePackagesUpdated([{amount: packageTypeSettings.limit, packageTypeId: packageId}])
        }
      })
    }

    // if no settings or something went really wrong — enable all types
    if (this.availablePackageTypes.length === 0) {
      this.availablePackageTypes = this.allPackageTypes
    }
  }

  addPackageTypeToAvailable (packageType, packageTypesSettings) {
    const packageTypeSettings = packageTypesSettings[packageType.id]

    if (packageTypeSettings && packageTypeSettings.enabled) {
      this.availablePackageTypes.push(packageType)
    }
  }

  handlePackagesUpdated (packages) {
    this.selectedPackages = packages
  }
}

export default {
  templateUrl,
  controller: PackageDetailsController,
  bindings: {
    form: '=',
    selectedPackages: '=',
    settings: '<'
  }
}
