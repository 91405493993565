import tariffViewModel from 'data/view-models/tariffViewModel'
import ValidationUtils from 'common/utils/validationUtils'
import fleetTariffsCollection from 'data/collections/fleetTariffsCollection'
import BaseController from 'presentation/common/BaseController'
import TariffPeriod from 'data/domain-objects/TariffPeriod'
import DistanceSurcharge from 'data/domain-objects/DistanceSurcharge'
import DurationSurcharge from 'data/domain-objects/DurationSurcharge'
import StatusSurcharge from 'data/domain-objects/StatusSurcharge'
import SpeedSurcharge from 'data/domain-objects/SpeedSurcharge'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import currenciesCollection from 'data/collections/currenciesCollection'
import measureUnitsCollection from 'data/collections/measureUnitsCollection'
import tiersCollection from 'data/collections/tiersCollection'
import tariffPeriodsCollection from 'data/collections/tariffPeriodsCollection'
import deliverySlaCollection from 'data/collections/deliverySlaCollection'
import deliveryBatchingCollection from 'data/collections/deliveryBatchingCollection'
import areasCollection from 'data/collections/areasCollection'
import businessesCollection from 'data/collections/businessesCollection'
import AddressAreaSurcharge from 'data/domain-objects/AddressAreaSurcharge'
import Tariff from 'data/domain-objects/Tariff'
import Cost from 'data/domain-objects/Cost'
import * as PRICE_MODELS from 'common/constants/PriceModels'
import differenceBy from 'lodash-es/differenceBy'
import localeHelper from 'common/localeHelper'
import WaitingTimeSurcharge from 'data/domain-objects/WaitingTimeSurcharge'
import { valueToAmount } from 'data/common/costFixer'
import promoCodesCollection from 'data/collections/promoCodesCollection'
import PromoCode from 'data/domain-objects/PromoCode'
import { StateService } from '@uirouter/core'
import { entityNameCompare } from 'data/common/comparator'
import cloneDeep from 'lodash-es/cloneDeep'
import { AllocationTypes } from 'common/constants/AllocationTypes'

interface IScheduledRideDefaults {
  cancellationFee: Cost,
  startMinutes: number,
  endMinutes: number,
  cancellationThreshold: number
}

const CONSUMERS_TIER_NAME = 'consumers'
const BUSINESS_TIER_NAME = 'business customers'

class FleetTariffController extends BaseController {

  // bindings
  public tariff: Tariff = null
  private uneditedTariff: Tariff = null
  public isEditing: boolean
  public fleetId: string
  public fleetName: string
  public onSubmitSuccess: () => void
  public isConsumerTariff: boolean = false
  public isBusinessTariff: boolean = true

  // properties
  public currencyCodes: string[] = []
  public distanceUnitCodes: string[] = []
  public availableTiers: DBMappedNamedEntity[] = []
  public availableSLATypes: DBMappedNamedEntity[] = []
  public availableBatchingTypes: DBMappedNamedEntity[] = []
  public availableDeliveryStatesApplicabilityTypes: DBMappedNamedEntity[] = []
  public availableTariffPeriods: TariffPeriod[] = []
  public availableRealGeographicalAreas: DBMappedNamedEntity[] = []
  public branchesList: IEntityListItem[] = null
  public allRealGeographicalAreas: DBMappedNamedEntity[] = []
  public genericAreas: DBMappedNamedEntity[] = []
  public applicabilityTypes: DBMappedNamedEntity[]
  public applicabilityTypesAppliedInFleet: any
  public scheduledRideDefaults: IScheduledRideDefaults
  public isFormChanged: boolean = false
  public showErrorValidation: boolean = false
  public showErrorUnknown: boolean = false
  public backendValidationErrors: any = {}
  public validationErrors: any = {}
  public priceModels: string[] = Object.keys(PRICE_MODELS)
  public stateSurchargeStatuses: string[] = [DELIVERY_STATUSES.AT_PICKUP]
  public speedSurchargeStatuses: string[] = [DELIVERY_STATUSES.COLLECTED]
  public promocodesList: IEntityListItem[] = null
  public allocationTypes: string[] = Object.keys(AllocationTypes)

  constructor (
    $scope: ng.IScope,
    $state: StateService,
    private $filter: ng.IFilterService
  ) {
    super($scope, $state)
  }

  get formTitle(): string {
    if (!this.tariff) {
      return ''
    }
    let formTitle = this.fleetName
    if (this.tariff.id && this.tariff.name) {
        formTitle += `, ${this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.SUB_HEADINGS.TARIFF')}: ${this.tariff.name}`
    } else if (this.tariff.id && !this.tariff.name) {
      formTitle += `, ${this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.TARIFF_EDITING')}`
    }

    return formTitle
  }

  $onInit (): void {
    this.$scope.$watch(() => this.tariff, (updatedTariff: Tariff, previousTariff: Tariff) => {
      if (this.isEditing && updatedTariff !== null && previousTariff !== null) {
        this.isFormChanged = ValidationUtils.getIsEntityChanged(updatedTariff, previousTariff)
        ValidationUtils.resetBackendValidationForChanged(this.backendValidationErrors, updatedTariff, previousTariff)
      }
    }, true)

    this.$scope.$watch(() => localeHelper.getLocale(), () => {
      // for each of the following arrays we are adding default element at the beginning, which should be localized. So here is the localization
      [this.availableSLATypes, this.availableBatchingTypes].forEach((item: DBMappedNamedEntity[]) => {
        if (item.length) {
          item[0].name = this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.DEFAULTS.ALL')
        }
      })
      if (this.availableDeliveryStatesApplicabilityTypes.length) {
        this.availableDeliveryStatesApplicabilityTypes[0].name = this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.DEFAULTS.ANY')
      }
    })

    this.$scope.$watch('$ctrl.tariff.tier.name', (): void => {
      if (this.tariff && this.tariff.tier) {
        this.isConsumerTariff = this.tariff.tier.name.toLowerCase() === CONSUMERS_TIER_NAME
        this.isBusinessTariff = this.tariff.tier.name.toLowerCase() === BUSINESS_TIER_NAME

        if (this.isConsumerTariff && !this.tariff.applicableAreas.length) {
          this.addApplicableArea()
        }

        if (this.isBusinessTariff) {
          this.tariff.applicableAreas = []
        }
      }
    })
  }

  $onChanges (simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.hasOwnProperty('tariff') && simpleChanges.tariff.currentValue !== null) {
      this.uneditedTariff = cloneDeep(this.tariff)
      this.getDependencies()
    }
  }

  getDependencies (): void {
    Promise.all([
      currenciesCollection.getCurrencyCodes()
        .then((currencyCodes: string[]): void => this.handleReceivedCurrencies(currencyCodes))
        .catch((err: Error): void => {
          this.logger.error('Failed to load currency codes:', err)
        }),
      measureUnitsCollection.getAvailableDistanceUnitCodes()
        .then((distanceUnits: string[]): void => this.handleReceivedDistanceUnits(distanceUnits))
        .catch((err: Error): void => {
          this.logger.error('Failed to load distance unit codes:', err)
        }),
      tiersCollection.getAvailableTiers()
        .then((tiers: DBMappedNamedEntity[]): void => this.handleReceivedTiers(tiers))
        .catch((err: Error): void => {
          this.logger.error('Failed to load tiers:', err)
        }),
      tariffPeriodsCollection.getAll()
        .then((tariffPeriods: TariffPeriod[]): void => this.handleReceivedTariffPeriods(tariffPeriods))
        .catch((err: Error): void => {
          this.logger.error('Failed to load tariff periods:', err)
        }),
      deliverySlaCollection.getAvailableDeliverySLATypes()
        .then((slaTypes: DBMappedNamedEntity[]): void => this.handleReceivedDeliverySLATypes(slaTypes))
        .catch((err: Error): void => {
          this.logger.error('Failed to load sla types:', err)
        }),
      deliveryBatchingCollection.getAvailableDeliveryBatchingTypes()
        .then((batchingTypes: DBMappedNamedEntity[]): void => this.handleReceivedDeliveryBatchingTypes(batchingTypes))
        .catch((err: Error): void => {
          this.logger.error('Failed to load batching types:', err)
        }),
      areasCollection.getRealGeographicalAreasCached()
        .then((areas: DBMappedNamedEntity[]): void => this.handleReceivedRealGeographicalAreas(areas))
        .catch((err: Error): void => {
          this.logger.error('Failed to load areas:', err)
        }),
      businessesCollection.getBusinessesWithTheirBranches()
        .then((businesses: any): void => this.handleReceivedBusinesses(businesses))
        .catch((err: Error): void => {
          this.logger.error('Failed to load branches:', err)
        }),
      fleetTariffsCollection.getTariffDeliveryStateApplicabilityTypes()
        .then((deliveryStateApplicabilityTypes: DBMappedNamedEntity[]): void => {
          this.handleReceivedDeliveryStatesApplicabilityTypes(deliveryStateApplicabilityTypes)
        })
        .catch((err: Error): void => {
          this.logger.error('Failed to load delivery state applicability types:', err)
        }),
      promoCodesCollection.getPromoCodes()
        .then((promoCodes: PromoCode[]): void => {
          this.handleReceivedPromoCodes(promoCodes)
        })
        .catch((err: Error): void => {
          this.logger.error('Failed to load delivery promo codes:', err)
        }),
      areasCollection.getAllCached()
        .then((areas: DBMappedNamedEntity[]): void => this.handleGenericAreasReceived(areas))
        .catch((error: Error): void => this.logger.error('Failed to load generic areas:', error)),
      fleetTariffsCollection.getDeliveryScheduleApplicabilityTypes()
        .then((applicabilityTypes: DBMappedNamedEntity[]): void => this.handleDeliveryScheduleApplicabilityTypesReceived(applicabilityTypes))
        .catch((error: Error): void => this.logger.error('Failed to load Delivery Schedule Applicability Types:', error)),
      fleetTariffsCollection.getScheduledRideDefaults()
        .then((response: any): void => this.handleScheduledRideDefaults(response))
        .catch((error: Error): void => this.logger.error('Failed to load scheduled ride defaults:', error))
    ])
      .then((): void => {
        this.isLoaded = true
        this.$scope.$digest()
      })
  }

  private handleGenericAreasReceived (areas: DBMappedNamedEntity[]): void {
    this.genericAreas = areas
  }

  private handleDeliveryScheduleApplicabilityTypesReceived (applicabilityTypes: DBMappedNamedEntity[]): void {
    this.applicabilityTypes = applicabilityTypes
    this.applicabilityTypesAppliedInFleet = {}
    this.applicabilityTypes.forEach((type: any) => {
      this.applicabilityTypesAppliedInFleet[type.id] = this.tariff.deliveryScheduleApplicabilityTypes.indexOf(type.id) >= 0
    })
  }

  private handleScheduledRideDefaults (response: any): void {
    this.scheduledRideDefaults = {
      cancellationFee: Cost.build(response.cancellationFee),
      startMinutes: response.startMinutes,
      endMinutes: response.endMinutes,
      cancellationThreshold: response.cancellationThreshold
    }
    if (!this.tariff.isCancellationFeeEnabled) {
      this.tariff.cancellationFee = this.scheduledRideDefaults.cancellationFee
    }
    if (!this.tariff.isCancellationThresholdEnabled) {
      this.tariff.cancellationThresholdInSeconds = this.scheduledRideDefaults.cancellationThreshold
    }
  }

  private handleReceivedCurrencies (currencyCodes: string[]): void {
    this.currencyCodes = currencyCodes
  }

  private handleReceivedDistanceUnits (distanceUnitCodes: string[]): void {
    this.distanceUnitCodes = distanceUnitCodes
  }

  private handleReceivedTiers (tiers: DBMappedNamedEntity[]): void {
    this.availableTiers = tiers
    if (this.tariff.id) {
      for (const availableTier of this.availableTiers) {
        if (this.tariff.tier.id === availableTier.id) {
          this.tariff.tier = availableTier
          return
        }
      }
    }
  }

  private handleReceivedTariffPeriods (tariffPeriods: TariffPeriod[]): void {
    this.availableTariffPeriods = tariffPeriods
    if (this.tariff.id) {
      for (const availableTariffPeriod of this.availableTariffPeriods) {
        if (this.tariff.tariffPeriod.id === availableTariffPeriod.id) {
          this.tariff.tariffPeriod = availableTariffPeriod
          return
        }
      }
    }
  }

  private handleReceivedDeliverySLATypes (slaTypes: DBMappedNamedEntity[]): void {
    this.availableSLATypes = slaTypes

    const defaultSlaType = new DBMappedNamedEntity('', this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.DEFAULTS.ALL'))
    this.availableSLATypes.unshift(defaultSlaType)

    for (const availableSlaType of this.availableSLATypes) {
      if (this.tariff.slaApplicabilityType.id === availableSlaType.id) {
        this.tariff.slaApplicabilityType = availableSlaType
        return
      }
    }
  }

  private handleReceivedDeliveryBatchingTypes (batchingTypes: DBMappedNamedEntity[]): void {
    this.availableBatchingTypes = batchingTypes
    const defaultBatchingType = new DBMappedNamedEntity('', this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.DEFAULTS.ALL'))
    this.availableBatchingTypes.unshift(defaultBatchingType)

    for (const availableBatchingType of this.availableBatchingTypes) {
      if (this.tariff.batchingApplicabilityType.id === availableBatchingType.id) {
        this.tariff.batchingApplicabilityType = availableBatchingType
        return
      }
    }
  }

  private handleReceivedRealGeographicalAreas (areas: DBMappedNamedEntity[]): void {
    this.allRealGeographicalAreas = areas
    this.filterRealGeographicalAreas()
  }

  private handleReceivedBusinesses (businesses: any): void {
    this.branchesList = []
    businesses.forEach((business: any): void => {
      business.branches.forEach((branchDTO: any): void => {
        this.branchesList.push({
          id: branchDTO.id,
          name: `${business.name} - ${branchDTO.name}`,
          selected: this.tariff.restrictedToBranches.includes(branchDTO.id)
        })
      })
    })

    this.branchesList.sort(entityNameCompare)
  }

  private handleReceivedDeliveryStatesApplicabilityTypes (deliveryStatesApplicabilityTypes: DBMappedNamedEntity[]): void {

    const defaultDeliveryStatesApplicabilityType = new DBMappedNamedEntity(
      '',
      this.$filter<ITranslateFilter>('translate')('COMPONENTS.FLEET_TARIFF.DEFAULTS.ANY')
    )

    this.availableDeliveryStatesApplicabilityTypes = [defaultDeliveryStatesApplicabilityType].concat(deliveryStatesApplicabilityTypes)

    for (const availableApplicabilityType of this.availableDeliveryStatesApplicabilityTypes) {
      if (this.tariff.deliveryStateApplicabilityType.id === availableApplicabilityType.id) {
        this.tariff.deliveryStateApplicabilityType = availableApplicabilityType
        return
      }
    }
  }

  private handleReceivedPromoCodes(promoCodes: PromoCode[]): void {
    this.promocodesList = promoCodes.map((promoCode: PromoCode) => {
      return {
        id: promoCode.id,
        name: promoCode.name,
        selected: this.tariff.applicablePromoCodeIds.includes(promoCode.id)
      }
    }).sort(entityNameCompare)
  }

  private getEnabledApplicabilityTypesIds (): string[] {
    return Object.keys(this.applicabilityTypesAppliedInFleet)
      .filter((applicabilityTypeId: string): string => this.applicabilityTypesAppliedInFleet[applicabilityTypeId])
  }

  public onSubmitClick (): void {
    const tariffModel = valueToAmount(this.tariff, ['fleet.cost', 'basePrice', 'cancellationFee'])
    tariffModel.deliveryScheduleApplicabilityTypes = this.getEnabledApplicabilityTypesIds()
    this.isLoading = true
    if (this.tariff.id.length) {
      fleetTariffsCollection.updateFleetTariff(tariffModel, this.tariff.id, this.fleetId)
        .then((): void => this.handleSubmitSuccess())
        .catch((error: Error): void => this.handleSubmitFailed(error))
        .then((): void => this.$scope.$digest())
    } else {
      fleetTariffsCollection.createTariffForFleet(tariffModel, this.fleetId)
        .then((): void => this.handleSubmitSuccess())
        .catch((error: Error): void => this.handleSubmitFailed(error))
        .then((): void => this.$scope.$digest())
    }
  }

  public handleSubmitSuccess (): void {
    this.isLoading = false
    this.resetErrorState()
    this.isEditing = false
    this.uneditedTariff = cloneDeep(this.tariff)
    this.onSubmitSuccess()
  }

  handleSubmitFailed (error: any): void {
    this.logger.error('Submit failed:', error)
    const errorDescriptorArraysByFieldName = error && error.validationErrors
    if (errorDescriptorArraysByFieldName) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(
        errorDescriptorArraysByFieldName,
        tariffViewModel.lookupFormFieldNameWithApiFieldName)
      this.showErrorValidation = true
      this.showErrorUnknown = false
      this.isFormChanged = false
    } else {
      this.backendValidationErrors = {}
      this.showErrorValidation = false
      this.showErrorUnknown = true
    }
  }

  resetErrorState (): void {
    this.showErrorValidation = false
    this.showErrorUnknown = false
  }

  // @TODO: think how to simplify the list of these functions
  onAddDistanceSurchargeClick (): void {
    this.tariff.distanceSurcharges.push(DistanceSurcharge.build())
  }

  onRemoveDistanceSurchargeClick (index: number): void {
    this.tariff.distanceSurcharges.splice(index, 1)
  }

  onAddDurationSurchargeClick (): void {
    this.tariff.durationSurcharges.push(DurationSurcharge.build())
  }

  onRemoveDurationSurchargeClick (index: number): void {
    this.tariff.durationSurcharges.splice(index, 1)
  }

  onAddStatusSurchargeClick (): void {
    this.tariff.statusSurcharges.push(StatusSurcharge.build())
  }

  onRemoveStatusSurchargeClick (index: number): void {
    this.tariff.statusSurcharges.splice(index, 1)
  }

  onAddWaitingTimeSurchargeClick (index: number): void {
    this.tariff.waitingTimeSurcharges.push(WaitingTimeSurcharge.build())
  }

  onRemoveWaitingTimeSurchargeClick (index: number): void {
    this.tariff.waitingTimeSurcharges.splice(index, 1)
  }

  onAddSpeedSurchargeClick (): void {
    this.tariff.speedSurcharges.push(SpeedSurcharge.build())
  }

  onRemoveSpeedSurchargeClick (index: number): void {
    this.tariff.speedSurcharges.splice(index, 1)
  }

  onAddAddressAreaSurchargeClick (): void {
    this.tariff.addressAreaSurcharges.push(AddressAreaSurcharge.build())
  }

  onRemoveAddressAreaSurchargeClick (index: number): void {
    this.tariff.addressAreaSurcharges.splice(index, 1)
  }

  filterRealGeographicalAreas (): void {
    let selectedAreas = []
    for (const addressAreaSurcharge of this.tariff.addressAreaSurcharges) {
      selectedAreas = selectedAreas.concat(addressAreaSurcharge.areas)
    }
    this.availableRealGeographicalAreas = differenceBy(this.allRealGeographicalAreas, selectedAreas, 'id')
  }

  onAddressAreasChanged (): void {
    this.filterRealGeographicalAreas()
  }

  startEdit (): void {
    this.isEditing = true
  }

  cancelEdit (): void {
    this.isEditing = false
    this.tariff = cloneDeep(this.uneditedTariff)
    this.getDependencies()
  }

  addApplicableArea (): void {
    this.tariff.applicableAreas.push({
      pickupArea: {},
      dropoffArea: {},
      scheduledRide: {}
    })
  }

  setScheduledDeliveryApplicabilityTypeToTariff (isChecked: boolean, typeId: string, form: ng.IFormController): void {
    const indexOfTypeInTariff = this.tariff.deliveryScheduleApplicabilityTypes.indexOf(typeId)

    if (indexOfTypeInTariff < 0 && isChecked) {
      this.tariff.deliveryScheduleApplicabilityTypes.push(typeId)
    }

    if (indexOfTypeInTariff >= 0 && !isChecked) {
      this.tariff.deliveryScheduleApplicabilityTypes.splice(indexOfTypeInTariff, 1)
    }

    this.setFormDirty(form)
  }

  removeAreaSettingsByIndex (areaSettingsIndex: number, form: ng.IFormController): void {
    this.tariff.applicableAreas.splice(areaSettingsIndex, 1)

    this.setFormDirty(form)
  }

  onAreaSettingsChanged (areaSettingsIndex: number, updatedSettings: any): void {
    this.tariff.applicableAreas[areaSettingsIndex] = updatedSettings
  }

  setFormDirty (form: ng.IFormController): void {
    if (form && form.$setDirty) {
      form.$setDirty()
    }
  }

  onRestrictedToBranchesChanged(restrictedBranchesIds: string[]): void {
    this.tariff.restrictedToBranches = restrictedBranchesIds
  }

  onPromocodesChanged(selectedPromocodeIds: string[]): void {
    this.tariff.applicablePromoCodeIds = selectedPromocodeIds
  }
}

export default {
  templateUrl: require('./fleet-tariff-form.pug'),
  controller: FleetTariffController,
  replace: true,
  bindings: {
    tariff: '<',
    fleetId: '<',
    fleetName: '<',
    isEditing: '<',
    targetState: '@',
    onSubmitSuccess: '&'
  }
}
