import templateUrl from './request-payment-options-settings.pug'
import envConfig from 'common/envConfig'
import currenciesCollection from 'data/collections/currenciesCollection'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import Setting from 'data/domain-objects/Setting'
import * as PaymentMethodsOptions from 'common/constants/PaymentMethodsSettings'
import * as PaymentMethods from 'common/constants/PaymentMethods'

class RequestPaymentOptionsSettings extends SettingsGroupController {
  constructor ($scope, $state, $filter) {
    super($scope, $state, [
      PaymentMethodsOptions.REQUEST_DEFAULT_CASH_OPTION_NAME,
      PaymentMethodsOptions.REQUEST_DEFAULT_PAID_OPTION_NAME,
      PaymentMethodsOptions.REQUEST_DEFAULT_CURRENCY_OPTION_NAME
    ])
    this.$filter = $filter
    this.currencyCodes = []
    this.sync()
    this.settingsDefinitions = []
    this.defaultCurrencyOptionName = PaymentMethodsOptions.REQUEST_DEFAULT_CURRENCY_OPTION_NAME
    this.defaultPaidOptionName = PaymentMethodsOptions.REQUEST_DEFAULT_PAID_OPTION_NAME
    this.defaultCashOptionName = PaymentMethodsOptions.REQUEST_DEFAULT_CASH_OPTION_NAME

    this.cashOptions = [
      PaymentMethods.CASH,
      PaymentMethods.CASH_ON_DELIVERY,
      PaymentMethods.CASH_OFF_SYSTEM
    ]
    this.paidOptions = [
      PaymentMethods.CARD,
      PaymentMethods.PAID_OFF_SYSTEM
    ]

    this.$scope.$watchGroup([
      () => this.model[PaymentMethodsOptions.REQUEST_DEFAULT_PAID_OPTION_NAME].value,
      () => this.model[PaymentMethodsOptions.REQUEST_DEFAULT_CASH_OPTION_NAME].value
    ], () => {
      this.checkDefaultCashAndPaidValidity()
    })
  }

  $onInit () {
    super.$onInit()

    if (typeof this.model[PaymentMethodsOptions.REQUEST_DEFAULT_PAID_OPTION_NAME] === 'undefined') {
      this.model[PaymentMethodsOptions.REQUEST_DEFAULT_PAID_OPTION_NAME] = new Setting(PaymentMethods.CARD, false, PaymentMethods.CARD)
    }
    if (typeof this.model[PaymentMethodsOptions.REQUEST_DEFAULT_CASH_OPTION_NAME] === 'undefined') {
      this.model[PaymentMethodsOptions.REQUEST_DEFAULT_CASH_OPTION_NAME] = new Setting(PaymentMethods.CASH, false, PaymentMethods.CASH)
    }
  }

  checkDefaultCashAndPaidValidity () {
    const isValid = !!this.model[PaymentMethodsOptions.REQUEST_DEFAULT_PAID_OPTION_NAME].value || !!this.model[PaymentMethodsOptions.REQUEST_DEFAULT_CASH_OPTION_NAME].value
    this.form.$setValidity('defaultCashOrPaidRequired', isValid)
  }

  onSync () {
    return currenciesCollection.getCurrencyCodes()
      .then(currencyCodes => this.currencyCodes = currencyCodes)
  }

  applyDefaultValuesForFields (forceToDefault) {
    super.applyDefaultValuesForFields(forceToDefault)
    this.model[this.defaultCurrencyOptionName].value = this.model[this.defaultCurrencyOptionName].value || envConfig.defaultCurrencyCode
  }
}

export default {
  templateUrl,
  controller: RequestPaymentOptionsSettings,
  bindings: {
    form: '=',
    model: '<',
    isEditing: '<'
  }
}
