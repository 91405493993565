import get from 'lodash-es/get'
import { DELIVERY_STATUSES, DeliveryStatusesType } from 'common/constants/DeliveryStatuses'
import { EVENT_TYPES, DELIVERY_TYPES } from 'data/collections/deliveriesCollection'
import { DELIVERY_ACTIONS } from 'data/collections/deliveriesCollection'
import { IFilterDate } from 'angular'
import permissionsModel from 'data/models/permissionsModel'
import * as DriverAppConnectionStatuses from 'common/constants/DriverAppConnectionStatuses'
import * as AuthenticationStatuses from 'common/constants/AuthenticationStatuses'
import * as PaymentMethods from 'common/constants/PaymentMethods'
import Payment from 'data/domain-objects/Payment'
import userModel from 'data/models/userModel'
import NextDayDeliveryTimeSlot from 'data/domain-objects/NextDayDeliveryTimeSlot'
import DateUtils from 'common/utils/dateUtils'
import DeliveryDataService from 'data/common/deliveryDataService'
import Cost from 'data/domain-objects/Cost'
import { DeliveryQuote } from 'data/domain-objects/DeliveryQuote'

class DeliveryInfo {

  public DATE_FORMAT: string = 'dd/MM/yy HH:mm'
  public deliveryEvents: any = EVENT_TYPES.DELIVERY
  public rideEvents: any = EVENT_TYPES.RIDE
  public ACTIONS: any = DELIVERY_ACTIONS
  public showActualChargeBreakdown: boolean = false
  public showInitialChargeBreakdown: boolean = false
  public deliveryIsLoading: boolean = false
  private statusChangeSubscription: IPubSubscription = null
  private deliveryLoadingSubscription: IPubSubscription = null
  public delivery: any = null
  public isNextDayDelivery: boolean = false
  public isRide: boolean = false
  public buttonsVisibility: any = {}
  public signatureImageUrl: string = null
  public driverStatuses: any[] = []
  public amountText: string = null
  public etaTime: string = null
  public consumerView: boolean = false
  public isModal: boolean = false
  public recipientPhone: string = null
  public recipientAddressVerification: boolean = false
  public senderAddressVerification: boolean = false
  public cancellationReason: string = null

  constructor (
    private $scope: ng.IScope,
    private $filter: ng.IFilterService
  ) {}

  $onInit (): void {
    this.statusChangeSubscription = DeliveryDataService.subscribeOnDeliveryStatusChange((newStatus: DeliveryStatusesType) => {
      if (this.delivery !== null) {
        this.delivery.status = newStatus
        this.$scope.$digest()
      }
    })

    this.deliveryLoadingSubscription = DeliveryDataService.subscribeOnDeliveryLoading(() => {
      this.deliveryIsLoading = true
    })
  }

  markAsReady (): void {
    this.deliveryIsLoading = true
    this.emitDeliveryAction(this.ACTIONS.MARK_READY)
  }

  $onDestroy (): void {
    this.statusChangeSubscription.remove()
    this.deliveryLoadingSubscription.remove()
  }

  getAtAddressEventTitle (addressId: string): string {
    let result = ''
    if (this.delivery.recipient && this.delivery.recipient.addressId && addressId === this.delivery.recipient.addressId) {
      result = this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_EVENT_LOG.EVENT_TYPES.DELIVERY.AT_DROPOFF_ADDRESS')
    } else if (this.delivery.sender && this.delivery.sender.addressId && addressId === this.delivery.sender.addressId) {
      result = this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_EVENT_LOG.EVENT_TYPES.DELIVERY.AT_PICKUP_ADDRESS')
    } else {
      result = this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_EVENT_LOG.EVENT_TYPES.DELIVERY.AT_ADDRESS')
    }
    return result
  }

  get deliveryScheduledTime (): string {
    if (this.delivery && this.delivery.pickupTime) {
      if (this.isNextDayDelivery) {
        return NextDayDeliveryTimeSlot.buildFromDate(this.delivery.pickupTime).getFormattedTimeSlot()
      } else {
        return this.$filter<IFilterDate>('date')(this.delivery.pickupTime, this.DATE_FORMAT)
      }
    } else {
      return this.$filter<ITranslateFilter>('translate')('COMMON.ASAP')
    }
  }

  get deliveryCollectedAt (): string {
    return this.delivery && this.delivery.collectedAt ? this.$filter<IFilterDate>('date')(this.delivery.collectedAt, this.DATE_FORMAT) : '-'
  }

  getIsToday (timestamp: number): boolean {
    return DateUtils.getIsToday(timestamp)
  }

  $onChanges (changes: ng.IOnChangesObject): void {
    if (changes.hasOwnProperty('delivery') && changes.delivery.currentValue) {
      this.processData()
    }

    if (changes.hasOwnProperty('deliveryStatus') && changes.deliveryStatus.currentValue) {
      this.processVisibilityButtons()
    }

    const signatureImageId = get(this, 'delivery.proofOfDelivery.signature.imageUrl', '')

    if (signatureImageId) {
      this.signatureImageUrl = signatureImageId
    }
  }

  processData (): void {
    this.isNextDayDelivery = this.delivery.type === DELIVERY_TYPES.NEXT_DAY
    this.isRide = this.delivery.type === DELIVERY_TYPES.RIDE

    this.driverStatuses = []
    if (this.delivery.driver) {
      this.driverStatuses = []
      this.driverStatuses.push([
        this.delivery.driver.status,
        this.$filter<ITranslateFilter>('translate')(`COMMON.DRIVER_STATUSES.${this.delivery.driver.status}`)
      ])
      if (this.delivery.driver.connectionStatus === DriverAppConnectionStatuses.CONNECTED) {
        this.driverStatuses.push(['connected', this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_INFO.DRIVER_STATUSES.CONNECTED')])
      }
      if (this.delivery.driver.connectionStatus === DriverAppConnectionStatuses.DISCONNECTED) {
        this.driverStatuses.push(['disconnected', this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_INFO.DRIVER_STATUSES.DISCONNECTED')])
      }
      if (this.delivery.driver.authStatus === AuthenticationStatuses.AUTHENTICATED) {
        this.driverStatuses.push(['authenticated', this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_INFO.DRIVER_STATUSES.AUTHENTICATED')])
      }
      if (this.delivery.driver.authStatus === AuthenticationStatuses.LOGGED_OUT) {
        this.driverStatuses.push([
          'notAuthenticated',
          this.$filter<ITranslateFilter>('translate')('COMPONENTS.DELIVERY_INFO.DRIVER_STATUSES.NOT_AUTHENTICATED')
        ])
      }
    }

    if (this.delivery.feedback && this.delivery.feedback.tip) {
      this.delivery.feedbackCost = Cost.build(this.delivery.feedback.tip).getFormattedCost()
    }

    if (this.delivery.payment.method === PaymentMethods.CASH_OFF_SYSTEM) {
      this.amountText = this.$filter<ITranslateFilter>('translate')(`COMMON.PAYMENT_METHODS.${PaymentMethods.CASH_OFF_SYSTEM}`)
    } else if (this.delivery.payment.method === PaymentMethods.PAID_OFF_SYSTEM) {
      this.amountText = this.$filter<ITranslateFilter>('translate')(`COMMON.PAYMENT_METHODS.${PaymentMethods.PAID_OFF_SYSTEM}`)
    } else {
      this.amountText = this.$filter<ITranslateFilter>('translate')(`COMMON.PAYMENT_METHODS.${this.delivery.payment.method}`)
      + ': '
      + new Payment(this.delivery.payment.cost, this.delivery.payment.method).getFormattedCost()
    }

    this.etaTime = ''
    if (this.delivery.pickedUpAt) {
      this.etaTime = `(${this.$filter<IFilterDate>('date')(this.delivery.pickedUpAt, 'dd/MM/yy HH:mm')})`
    }

    if (this.delivery.etaPickup
        && this.delivery.etaPickup.time
        && !this.delivery.pickedUpAt
        && [DELIVERY_STATUSES.ISSUE, DELIVERY_STATUSES.CANCELLED].indexOf(this.delivery.status) === -1) {
      this.etaTime = `(${this.$filter<IFilterDate>('date')(this.delivery.etaPickup.time, 'dd/MM/yy HH:mm')}
        - ${this.$filter<ISpecificTimeFilter>('specificTimeTo')(this.delivery.etaPickup.time, false, true)})`
    }

    if (get(this, 'delivery.recipient.contactPhoneNumber', false)) {
      this.recipientPhone = `+ ${this.delivery.recipient.contactCountryDiallingCode} ${this.delivery.recipient.contactPhoneNumber}`
    }

    this.recipientAddressVerification = false
    this.senderAddressVerification = false

    const addressVerificationHelper = (who: string): void => {
      if (this.delivery[who].type === 'consumer') {
        this[`${who}AddressVerification`] = true
        this[`${who}AddressVerificationStatus`] = this.delivery[who].address && this.delivery[who].address.verified ? 'verified' : 'not-verified'
        this[`${who}AddressVerificationTitle`] = this.$filter<ITranslateFilter>('translate')(
          this.delivery[who].address && this.delivery[who].address.verified ? 'COMMON.VERIFIED_ADDRESS' : 'COMMON.NON_VERIFIED_ADDRESS'
        )
      } else {
        this[`${who}AddressVerification`] = false
      }
    }

    addressVerificationHelper('recipient')
    addressVerificationHelper('sender')

    const addresTitleHelper = (who: string): void => {
      this.delivery[`${who}Address`] = ''
      if (this.delivery[who].address.type) {
        this.delivery[`${who}Address`] = this.$filter<ITranslateFilter>('translate')(`COMMON.ADDRESS_TYPES.${this.delivery[who].address.type}`)
          + `, ${this.delivery[who].address.getFormattedAddress()}`
      } else {
        this.delivery[`${who}Address`] = this.$filter<ITranslateFilter>('translate')('COMPONENTS.RIDE.RIDE_SUMMARY.CONSUMER_CONFIRM_DROPOFF')
      }
    }

    addresTitleHelper('recipient')
    addresTitleHelper('sender')

    if (this.delivery.status === DELIVERY_STATUSES.CANCELLED &&  this.delivery.cancellation && this.delivery.cancellation.reason) {
      this.cancellationReason = this.delivery.cancellation.reason.reason // not a typo
    }

    this.delivery.dropoffTime = get(this, 'delivery.etaDropoff.time', null) ? `(${this.$filter('date')(this.delivery.etaDropoff.time, this.DATE_FORMAT)})` : ''

    this.showInitialChargeBreakdown =  this.delivery.status === DELIVERY_STATUSES.DELIVERED && this.isRide
    this.showActualChargeBreakdown = this.isRide || ((
      this.delivery.status === DELIVERY_STATUSES.AT_DROPOFF
      || this.delivery.status === DELIVERY_STATUSES.DELIVERED) && this.delivery.quote.totalPrice)

    const deliveryQuote = new DeliveryQuote(this.delivery)

    this.delivery.actualChargeQuote = deliveryQuote.getActualBreakdown()
    this.delivery.initialChargeQuote = deliveryQuote.getInitialBreakdown()

    this.delivery.events.reverse()
    this.deliveryIsLoading = false
  }

  processVisibilityButtons (): void {
    this.buttonsVisibility = {
      ready: false,
      pending: false,
      rehail: false,
      delivered: false,
      collected: false,
      changeDriver: false,
      view: false,
      changeAddress: false,
      cancel: false,
      batching: false
    }

    if (this.consumerView) {
      return
    }

    const hasEditDeliveryPermission = permissionsModel.getHasPermission('DELIVERIES_EDIT')
    const hasEditFleetsPermission = permissionsModel.getHasPermission('FLEETS_EDIT')
    const allowBatching = this.delivery.permissions && this.delivery.permissions.indexOf('allowBatching') > -1

    if (this.isModal) {
      this.buttonsVisibility.view = true
    }

    if (allowBatching && !this.isNextDayDelivery) {
      this.buttonsVisibility.batching = true
    }

    if (this.delivery.status === DELIVERY_STATUSES.PENDING
        || this.delivery.status === DELIVERY_STATUSES.ALLOCATING
        || this.delivery.status === DELIVERY_STATUSES.ALLOCATED
        || this.delivery.status === DELIVERY_STATUSES.AT_PICKUP) {
      this.buttonsVisibility.pending = true
    }

    if (this.delivery.status === DELIVERY_STATUSES.NOT_READY) {
      this.buttonsVisibility.ready = true
    }

    if ((userModel.getLocalUser().isSuperAdmin
      || userModel.getLocalUser().isFleetManager)
      && (this.delivery.status === DELIVERY_STATUSES.ISSUE || this.delivery.status === DELIVERY_STATUSES.CANCELLED)) {
      this.buttonsVisibility.rehail = true
    }

    if (!this.isNextDayDelivery && hasEditDeliveryPermission
        && this.delivery.status !== DELIVERY_STATUSES.DELIVERED
        && this.delivery.recipient.type === 'consumer') {
      this.buttonsVisibility.changeAddress = true
    }

    if (this.delivery.status !== DELIVERY_STATUSES.DELIVERED && this.delivery.status !== DELIVERY_STATUSES.CANCELLED) {
      this.buttonsVisibility.cancel = true
    }

    if (hasEditFleetsPermission) {
      if (this.delivery.status !== DELIVERY_STATUSES.DELIVERED) {
        this.buttonsVisibility.delivered = true
      }

      if (this.delivery.status === DELIVERY_STATUSES.ALLOCATED || this.delivery.status === DELIVERY_STATUSES.AT_PICKUP) {
        this.buttonsVisibility.collected = true
      }
    }

    if (this.delivery.permissions && this.delivery.permissions.indexOf('allowManagerTransferDelivery') > -1
      && (this.delivery.status === DELIVERY_STATUSES.PENDING
        || this.delivery.status === DELIVERY_STATUSES.ALLOCATING
        || this.delivery.status === DELIVERY_STATUSES.ALLOCATED
        || this.delivery.status === DELIVERY_STATUSES.AT_PICKUP)
    ) {
      this.buttonsVisibility.changeDriver = true
    }
  }

  print (): void {
    window.print()
  }

  emitDeliveryAction (action: string): void {
    this.$scope.$emit(action, this.delivery)
  }

  formatEventTypeIntoClass (eventType: string): string {
    return eventType.replace(/delivery\.|ride\./, '').replace(/([A-Z])+/, '-$1').replace(/(\.)+/, '-$1').replace('.', '').toLowerCase()
  }
}

export default {
  templateUrl: require('./delivery-info.pug'),
  controller: ['$scope', '$filter', DeliveryInfo],
  bindings: {
    delivery: '<',
    deliveryStatus: '<',
    isModal: '<',
    consumerView: '<'
  }
}
