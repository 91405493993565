import templateUrl from './apply-voucher.pug'
import userModel from 'data/models/userModel'
import BaseController from 'presentation/common/BaseController'
import vouchersCollection from 'data/collections/vouchersCollection'

class ApplyVoucherController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.balanceAccountId = null
    this.getBalanceAccountId()
    this.isSubmitting = false

    $scope.$watch('$ctrl.voucherCode', () => {
      this.resetValues()
    })
  }

  resetValues (resetCode = false) {
    if (resetCode) {
      this.voucherCode = null
    }
    this.errorCode = {}
    this.voucherAmount = ''
    this.voucherValidated = false
  }

  getBalanceAccountId () {
    userModel.getUser()
      .then(user => {
        this.balanceAccountId = user.balanceAccount
      })
      .catch(err => {
        this.handleErrorCode(err)
        this.logger.error('Cannot get current user', err)
      })
  }

  handleErrorCode (params = {}) {
    this.isSubmitting = false
    this.errorCode = {}

    if (params.code) {
      this.errorCode[params.code] = true
      return
    }

    if (params.message === 'Not found') {
      this.errorCode.codeNotFound = true
      return
    }

    this.errorCode.genericError = true
  }

  apply () {
    this.isSubmitting = true
    vouchersCollection.apply(this.voucherId, this.balanceAccountId)
      .then(response => {
        if (response.reApplyAttempt) {
          this.handleErrorCode({code: 'alreadyApplied'})
          return
        }

        // triggers updating of userBalance in the parent component
        if (typeof this.onApply === 'function') {
          this.onApply()
        }
        this.resetValues(true)
        this.isSubmitting = false
      })
      .catch(response => this.handleErrorCode(response))
      .then(() => {
        this.$scope.$apply()
      })
  }

  validate () {
    this.isSubmitting = true
    vouchersCollection.validate(this.voucherCode)
      .then(validation => {
        this.isSubmitting = false

        if (validation.voucherExpired) {
          this.handleErrorCode({code: 'voucherExpired'})
          return
        }

        if (validation.balanceAccountPermitted === 'false') {
          this.handleErrorCode({code: 'accountProblem'})
          return
        }

        if (!validation.balanceAccountValid) {
          this.handleErrorCode({code: 'accountProblem'})
          return
        }

        if (validation.usageLimit === validation.limitUsed) {
          this.handleErrorCode({code: 'limitReached'})
          return
        }

        this.voucherValidated = true
        this.voucherAmount = validation.amount.getFormattedCost()
        this.voucherId = validation.id
      })
      .catch(response => this.handleErrorCode(response))
      .then(() => {
        this.$scope.$digest()
      })
  }
}

export default {
  templateUrl,
  bindings: {
    onApply: '&?'
  },
  controller: ApplyVoucherController
}
