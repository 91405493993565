import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import keys from 'lodash-es/keys'
import templateUrl from './vouchers-dashboard.pug'
import envConfig from 'common/envConfig'
import Cost from 'data/domain-objects/Cost'
import comparator from 'data/common/comparator'
import vouchersCollection from 'data/collections/vouchersCollection'
import TableWithFiltersController from 'presentation/common/TableWithFiltersController'
import PaginationParameters from 'data/domain-objects/PaginationParameters'
import Voucher from 'data/domain-objects/Voucher'

class VouchersDashboardController extends TableWithFiltersController {
  constructor ($scope, $state, $stateParams, $filter) {
    super($scope, $state, $stateParams, $filter, true)
    this.defaultSortingKey = 'createdAt'
    this.defaultSortingDirection = 'DESC'
    this.paginationParams = new PaginationParameters()

    this.appliedFilters = {}
    this.areFiltersActive = false

    this.viewVoucherButton = voucher => {
      this.$state.go('main.tools.vouchers.voucher', {voucherId: voucher.action})
    }

    $scope.$watch('$ctrl.datesFilter.endDate', () => {
      this.applyFilters()
    })

    // restoring state from URL
    this.searchFilter = this.$state.params.text || ''
    this.datesFilter = {
      startDate: this.$state.params.startDate ? new Date(this.$state.params.startDate) : null,
      endDate: this.$state.params.endDate ? new Date(this.$state.params.endDate) : null
    }
    this.applyFilters(true)
  }

  onChangeDate (payload) {
    this.datesFilter.startDate = payload.dateFrom
    this.datesFilter.endDate = payload.dateTo
    this.applyFilters()
  }

  onPaginationUpdate (offset, limit) {
    this.paginationParams.limit = limit
    this.paginationParams.skip = offset

    this.sync()
  }

  onSync () {
    return vouchersCollection.getAll({
      ...this.appliedFilters,
      limit: this.paginationParams.limit,
      skip: this.paginationParams.skip
    })
      .then(response => {
        this.paginationParams = PaginationParameters.build(response.page)
        return response.results.map(voucherDTO => Voucher.build(voucherDTO))
      })
      .then(vouchers => this.processVouchers(vouchers))
      .catch(err => this.logger.error('Cannot get vouchers', err))
      .then(() => this.$scope.$digest())
  }

  processVouchers (vouchers) {
    const dynamicTableData = vouchers.map(voucher => {
      return {
        createdAt: voucher.createdAt,
        voucherCode: voucher.voucherCode,
        use: voucher.usageLimit,
        amount: Cost.build(voucher.amount),
        status: voucher.limitUsed,
        action: voucher.id
      }
    })

    this.handleRows(dynamicTableData)
    this.isLoading = false
  }

  newVoucherBtnClick () {
    this.$state.go('main.tools.vouchers.create')
  }

  getFormatters () {
    // 'use' and 'status' HAS to be empty strings, because they are handled in the template
    return {
      createdAt: value => this.$filter('date')(value, envConfig.angularDefaultDateFormat),
      voucherCode: value => value,
      amount: value => value.getFormattedCost(),
      use: () => '',
      status: () => '',
      action: () => ''
    }
  }

  changeSearchFilter (params) {
    this.searchFilter = params && params.value || null
    this.applyFilters()
  }

  /**
   * This function will check if filters are changed and load new date from server if needed.
   *
   * On page init we have to force data loading even though filters weren't changed. Hence
   * the flag forceApply. If set to true — request for data will be sent.
   *
   * @param {Boolean} forceApply
   */
  applyFilters (forceApply = false) {
    const searchParams = {}

    if (this.searchFilter) {
      searchParams.text = this.searchFilter
    }

    if (this.datesFilter && this.datesFilter.endDate) {
      searchParams.startDate = this.datesFilter.startDate.toISOString()
      searchParams.endDate = this.datesFilter.endDate.toISOString()
    }

    if (forceApply || !isEqual(this.appliedFilters, searchParams)) {
      this.appliedFilters = cloneDeep(searchParams)
      this.areFiltersActive = keys(this.appliedFilters).length > 0

      // loads new date from server
      this.sync()
    }
  }

  getSorting () {
    return {
      createdAt: (a, b) => comparator.dates(new Date(a), new Date(b)),
      amount: (a, b) => comparator.basic(a.amount, b.amount),
      voucherCode: true,
      use: true,
      status: true
    }
  }
}

export default {
  templateUrl,
  controller: VouchersDashboardController
}
