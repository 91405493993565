import templateUrl from './address-lookup-paci.pug'
import addressLookupService from 'data/services/addressLookupService'
import BaseFormController from 'presentation/common/BaseFormController'

const PACI_CODE_CHAR_COUNT = 8

class AddressLookupPaciController extends BaseFormController {
  constructor ($scope) {
    super($scope)
    this.model.paciCode = ''
    this.requiredPaciLength = PACI_CODE_CHAR_COUNT
  }

  beforeSubmit () {
    this.resetAllValidation()
    const paciCode = this.model.paciCode
    return addressLookupService.getAddressDescriptorsForPaciCode(paciCode)
      .then(addressDescriptors => {
        if (addressDescriptors.length) {
          return AddressLookupPaciController.getAddressForAddressDescriptor(addressDescriptors[0])
        } else {
          throw new Error(`No address found for PACI code: ${paciCode}`)
        }
      })
  }

  static getAddressForAddressDescriptor (addressDescriptor) {
    // Descriptor contains various fields that are only for backend, including an ID that is not a GET /address ID
    return {
      addressFormatId: addressDescriptor.addressFormatId,
      fields: addressDescriptor.fields,
      location: addressDescriptor.location
    }
  }

  afterSubmitSuccess (address) {
    this.onAddressLookup({address})
  }
}

export default {
  templateUrl,
  controller: AddressLookupPaciController,
  bindings: {
    onAddressLookup: '&'
  }
}
