import pull from 'lodash-es/pull'
import { StateService, StateParams, StateObject } from '@uirouter/core'
import * as PRODUCT_EVENTS from 'common/constants/ProductEvents'
import collectEntityFieldByIds from 'data/common/collectByIds.js'
import productsCollection from 'data/collections/productsCollection'
import branchesCollection from 'data/collections/branchesCollection'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import storefrontCategoriesCollection from 'data/collections/storefrontCategoriesCollection'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import Product, { ProductExtra, ProductOption, ProductProperty, ProductPropertyValue } from 'data/domain-objects/Product'
import permissionsModel from 'data/models/permissionsModel'
import { BasePriceDisplayMode } from 'common/constants/BasePriceDisplayMode'
import StorefrontCategory from 'data/domain-objects/storefront/StorefrontCategory'
import { flattenTree } from 'common/utils/treeUtils'

const PRODUCT_ID_PARAM_NAME = 'productId'
const BUSINESS_ID_PARAM_NAME = 'businessId'
const DELETE_PROPERTY_DIALOG = 'delete-product-property-dialog'

const BASE_PRICE_DISPLAY_MODE_OPTIONS: BasePriceDisplayMode[] = [
  BasePriceDisplayMode.SHOW,
  BasePriceDisplayMode.HIDE,
  BasePriceDisplayMode.PRICE_ON_SELECTION,
  BasePriceDisplayMode.FROM_AMOUNT
]

class ProductFormController extends CreateViewEditFormController {
  public showSaveButton: boolean = false
  public businessId: string = ''
  public availableBranches: object[] = []
  public availableCategories: StorefrontCategory[] = []
  public availableCategoriesSuggestions: ISuggestion[] = []
  public categoryNamesByIds: object = {}
  public categoriesSuggesterModel: string = ''
  public availableskus: object[] = []
  public availableTags: object[] = []
  public newTag: string = ''
  public isPropertyValuesAffected: boolean = false
  public arePropertiesAffected: boolean = false
  public areOptionsAffected: boolean = false
  public areExtrasAffected: boolean = false
  public areExtrasLimitsValid: boolean = false
  public showExtrasTotalMinLimitError: boolean = false
  public showExtrasTotalMaxLimitError: boolean = false
  public deletingPropertyIndex: number
  public currentlyEditingPropertyValueIndex: number
  public currentlyEditingPropertyIndex: number
  public currentlyEditingOptionIndex: number
  public currentlyEditingExtraIndex: number
  public DELETE_PROPERTY_DIALOG: string = DELETE_PROPERTY_DIALOG
  public skuSuggestionModel: ISuggestion = {
    id: '',
    name: ''
  }
  public basePriceDisplayMode: BasePriceDisplayMode[] = BASE_PRICE_DISPLAY_MODE_OPTIONS
  public parentPropertiesNamesByIds: any = {}
  public preparationTimeRegexp: RegExp = /^\d{0,5}$/

  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams,
    private $rootScope: ng.IScope
  ) {
    super($scope, $state, $stateParams, PRODUCT_ID_PARAM_NAME)
  }

  static getLocalizedSuggestionFromCategory(category: StorefrontCategory): ISuggestion {
    return {
      id: category.id,
      name: category.name.getLocalized()
    }
  }

  $onInit(): void {
    this.businessId = this.$stateParams[BUSINESS_ID_PARAM_NAME]
    super.$onInit()

    if (this.isCreate) {
      this.sync()
    }

    this.showSaveButton = this.isCreate ? permissionsModel.getHasPermission('STOREFRONT_CREATE') : permissionsModel.getHasPermission('STOREFRONT_UPDATE')

    this.$scope.$watchGroup([
      '$ctrl.model.extrasMinAmount',
      '$ctrl.model.extrasMaxAmount'
    ], ([min, max]: number[] | string[]): void => this.validateExtras(min, max))
  }

  onSync(): Promise<any> {
    return this.isCreate ? this.getDependencies() :
      this.getDependencies()
        .then(() => this.getEntity())
  }

  getDependencies(): Promise<any> {
    return Promise.all([
      this.getBranches(),
      this.getCategories(),
      this.getskus(),
      this.getTags()
    ])
  }

  getEntity(): Promise<any> {
    return productsCollection.getById(
      this.businessId,
      this.$stateParams[PRODUCT_ID_PARAM_NAME]
    ).then((product: Product): Product => {
      this.backendModel = Product.build(product)
      this.setDefaultModel()
      this.updateParentPropertiesNames()
      this.updateAvailableCategories()

      const fakeSKUsuggestion = {
        id: '_',
        name: this.model.sku
      }

      this.availableskus.push(fakeSKUsuggestion)
      this.skuSuggestionModel = fakeSKUsuggestion
      return this.backendModel
    })
      .catch((error: Error): void => this.logger.error('Cannot get product', error))
  }

  getBranches(): Promise<any[] | void> {
    return branchesCollection.getAllWithoutAddresses()
      .then((branches: any[]): any[] => this.availableBranches = branches)
      .catch((error: Error): void => this.logger.error('Cannot get branches', error))
  }

  getCategories(): Promise<void | StorefrontCategory[]> {
    return storefrontCategoriesCollection.getAll(this.businessId)
      .then((categories: StorefrontCategory[]): void => {
        this.availableCategories = flattenTree(categories, 'categories')
        this.categoryNamesByIds = collectEntityFieldByIds(this.availableCategories)
        this.updateAvailableCategories()
      })
      .catch((error: Error) => this.logger.error('Cannot get product categories', error))
  }

  extractChildCategories(allCategories: StorefrontCategory[], category: Partial<StorefrontCategory>): StorefrontCategory[] {
    const childCategories = category.categories.reduce((acc: StorefrontCategory[], category: StorefrontCategory): StorefrontCategory[] => {
      acc.push(category)
      return this.extractChildCategories(acc, category)
    }, [])
    return allCategories.concat(childCategories)
  }

  updateParentPropertiesNames(): void {
    this.parentPropertiesNamesByIds = this.model.properties
      .reduce((accum: any, property: ProductProperty): any => {
        property.values.forEach((value: ProductPropertyValue): any => {
          accum[`${property.id}:${value.id}`] = `${property.name.getLocalized()} - ${value.name.getLocalized()}`
        })
        return accum
      }, {})
  }

  updateAvailableCategories(): void {
    this.availableCategoriesSuggestions = this.availableCategories
      .map(ProductFormController.getLocalizedSuggestionFromCategory)
      .filter((category: ISuggestion): boolean => this.model.categories.indexOf(category.id) < 0)
  }

  attachProductToCategory(suggestion: ISuggestion): void {
    if (suggestion) {
      this.model.categories.push(suggestion.id)
      this.updateAvailableCategories()
    }
  }

  removeCategory(categoryId: string): void {
    this.model.categories = pull(this.model.categories, categoryId)
    this.updateAvailableCategories()
  }

  getskus(): Promise<void | DBMappedNamedEntity[]> {
    return productsCollection.getAllSKUs(this.businessId)
      .then((skus: DBMappedNamedEntity[]): DBMappedNamedEntity[] => this.availableskus = skus)
      .catch((error: Error): void => this.logger.error('Cannot get product skus', error))
  }

  getTags(): Promise<void | DBMappedNamedEntity[]> {
    return productsCollection.getAllTags(this.businessId)
      .then((tags: DBMappedNamedEntity[]): DBMappedNamedEntity[] => this.availableTags = tags)
      .catch((error: Error): void => this.logger.error('Cannot get tags', error))
  }

  getDefaultModel(): Product {
    return Product.build(this.backendModel || {})
  }

  beforeSubmit(): Promise<any> {
    const productModel = Product.build(this.model)

    const promisedProduct = this.isCreate ?
      productsCollection.create(this.businessId, productModel) :
      productsCollection.update(this.businessId, this.entityId, productModel)

    let updatedProduct

    return promisedProduct
      .then((product: Product): Promise<void> => {
        updatedProduct = Product.build(Object.assign(product, { isAvailable: this.model.isAvailable }))
        return productsCollection.setProductAvailability(this.businessId, product.id, this.model.isAvailable)
      })
      .then(() => updatedProduct)
  }

  afterSubmitSuccess(backendModel: Product): Promise<StateObject> {
    return super.afterSubmitSuccess(backendModel)
      .then(() => this.$state.go('main.storefront.products.product', {
        businessId: this.businessId,
        productId: this.model.id
      }))
  }

  saveTag(): void {
    productsCollection.createTag(this.businessId, this.newTag.trim().replace(/\#/g, ''))
      .then((tag: DBMappedNamedEntity): void => {
        this.availableTags.push(tag)
        this.newTag = ''
        this.$scope.$digest()
      })
      .catch((error: Error): void => this.logger.error('Cannot add new tag', error))
  }

  onskuSelected(suggestion: ISuggestion): void {
    if (suggestion) {
      this.skuSuggestionModel = suggestion
      this.model.sku = suggestion.name
    }
  }

  onSKUChange(sku: string): void {
    this.model.sku = sku
    this.skuSuggestionModel = {
      id: '',
      name: sku
    }
  }

  updateLogoUrl(imageUrl: string): void {
    this.model.photoUrl = imageUrl
  }

  submit(): Promise<any> {
    this.areOptionsAffected = false
    return super.submit()
  }

  cancel(): void {
    super.cancel()
    this.updateAvailableCategories()
    this.areOptionsAffected = false
  }

  // Properties

  openPropertyDialog(property?: ProductProperty): void {
    this.$rootScope.$broadcast(PRODUCT_EVENTS.ADD_PRODUCT_PROPERTY_EVENT, {
      model: property,
      properties: this.model.properties
    })
  }

  editPropertyByIndex(propertyIndex: number): void {
    this.currentlyEditingPropertyIndex = propertyIndex
    this.openPropertyDialog(this.model.properties[propertyIndex])
  }

  cancelPropertyEditing(): void {
    this.currentlyEditingPropertyIndex = null
  }

  handlePropertySubmitted(property: ProductProperty): void {
    if (typeof this.currentlyEditingPropertyIndex === 'number') {
      this.updateProperty(property, this.currentlyEditingPropertyIndex)
    } else {
      this.addProperty(property)
    }
  }

  addProperty(property: ProductProperty): void {
    this.model.properties.push(ProductProperty.build(property))
    this.updateParentPropertiesNames()
    this.markPropertiesAffected()
  }

  updateProperty(property: ProductProperty, propertyIndex: number): void {
    if (this.model.properties[propertyIndex]) {
      this.model.properties[propertyIndex] = property
      this.currentlyEditingPropertyIndex = null
      this.updateParentPropertiesNames()
      this.markPropertiesAffected()
    }
  }

  isMentionedAsParentProperty(propertyId: string): boolean {
    return !!this.getChildProperties(propertyId).length
  }

  getChildProperties(propertyId: string): ProductProperty[] {
    return this.model.properties.filter((property: ProductProperty): boolean => {
      return !!property.parentProperties.find((parentProperty: IParentProductProperty): boolean => parentProperty.propertyId === propertyId)
    })
  }

  removePropertyFromChildProperties(propertyId: string): void {
    this.model.properties.forEach((property: ProductProperty): void => {
      property.parentProperties = property.parentProperties.filter((parentProperty: IParentProductProperty): boolean => {
        return parentProperty.propertyId !== propertyId
      })
    })
  }

  deletePropertyByIndex(propertyIndex: number): void {
    const propertyId = this.model.properties[propertyIndex] && this.model.properties[propertyIndex].id
    const isParentProperty = this.isMentionedAsParentProperty(propertyId)

    if (this.model.properties[propertyIndex].values.length || isParentProperty) {
      this.deletingPropertyIndex = propertyIndex
      modalDialogStateService.emitOpenModalDialog({ dialogId: this.DELETE_PROPERTY_DIALOG })
    } else {
      this.onConfirmDeletePropertyByIndex(propertyIndex)
    }
  }

  onConfirmDeletePropertyByIndex(propertyIndex: number): void {
    if (typeof propertyIndex === 'number') {
      const propertyId = this.model.properties[propertyIndex] && this.model.properties[propertyIndex].id
      this.removePropertyFromChildProperties(propertyId)
      this.model.properties.splice(propertyIndex, 1)
      this.markPropertiesAffected()
      this.deletingPropertyIndex = null
    }
  }

  markPropertiesAffected(): void {
    this.arePropertiesAffected = true
  }

  moveProperty (fromIndex: number, toIndex: number): void {
    this.model.properties.splice(toIndex, 0, this.model.properties.splice(fromIndex, 1)[0])
    this.markPropertiesAffected()
  }

  movePropertyUp (propertyIndex: number): void {
    const newIndex = propertyIndex - 1

    if (newIndex >= 0) {
      this.moveProperty(propertyIndex, newIndex)
    }
  }

  movePropertyDown (propertyIndex: number): void {
    const newIndex = propertyIndex + 1

    if (newIndex < this.model.properties.length) {
      this.moveProperty(propertyIndex, newIndex)
    }
  }

  cloneProperty(property: ProductProperty, index: number): void {
    const name = Object.keys(property.name).reduce((acc: any, locale: string): any => {
      acc[locale] = property.name[locale] ? `Copy of ${property.name[locale]}` : property.name[locale]
      return acc
    }, {})

    const values = property.values.map((propertyValue: ProductPropertyValue): ProductPropertyValue => {
      return ProductPropertyValue.build({
        ...propertyValue,
        id: undefined
      })
    })

    const sanitizedProperty = {
      ...property,
      name,
      values,
      id: undefined,
      parentProperties: []
    }

    this.model.properties.splice(index, 0, ProductProperty.build(sanitizedProperty))
    this.markPropertiesAffected()
  }

  // Property values

  openPropertyValueDialog(propertyIndex: number, propertyValue?: ProductPropertyValue): void {
    this.currentlyEditingPropertyIndex = propertyIndex
    this.$rootScope.$broadcast(PRODUCT_EVENTS.ADD_PRODUCT_PROPERTY_VALUE_EVENT, { model: propertyValue })
  }

  editPropertyValueByIndex(propertyIndex: number, valueIndex: number): void {
    this.currentlyEditingPropertyIndex = propertyIndex
    this.currentlyEditingPropertyValueIndex = valueIndex
    this.openPropertyValueDialog(propertyIndex, this.model.properties[propertyIndex].values[valueIndex])
  }

  cancelPropertyValueEditing(): void {
    this.currentlyEditingPropertyIndex = null
    this.currentlyEditingPropertyValueIndex = null
  }

  handlePropertyValueSubmitted(propertyValue: ProductPropertyValue): void {
    if (
      typeof this.currentlyEditingPropertyIndex === 'number' &&
      typeof this.currentlyEditingPropertyValueIndex === 'number'
    ) {
      this.updatePropertyValue(propertyValue, this.currentlyEditingPropertyIndex, this.currentlyEditingPropertyValueIndex)
    } else {
      this.addPropertyValue(propertyValue, this.currentlyEditingPropertyIndex)
    }
  }

  addPropertyValue(propertyValue: ProductPropertyValue, propertyIndex: number): void {
    this.model.properties[propertyIndex].values.push(ProductPropertyValue.build(propertyValue))
    this.currentlyEditingPropertyIndex = null
    this.markPropertiesAffected()
  }

  updatePropertyValue(propertyValue: ProductPropertyValue, propertyIndex: number, valueIndex: number): void {
    if (this.model.properties[propertyIndex] && this.model.properties[propertyIndex].values[valueIndex]) {
      this.model.properties[propertyIndex].values[valueIndex] = propertyValue
      this.currentlyEditingPropertyIndex = null
      this.currentlyEditingPropertyValueIndex = null
      this.markPropertiesAffected()
    }
  }

  removePropertyValueFromChildProperties(propertyId: string, valueId: string): void {
    this.model.properties.forEach((property: ProductProperty): void => {
      property.parentProperties = property.parentProperties.filter((parentProperty: IParentProductProperty): boolean => {
        return !(parentProperty.propertyId === propertyId && parentProperty.valueId === valueId)
      })
    })
  }

  deletePropertyValueByPropertyAndValueIndex(property: ProductProperty, valueIndex: number): void {
    if (typeof valueIndex === 'number') {
      const valueId = property.values[valueIndex] && property.values[valueIndex].id
      this.removePropertyValueFromChildProperties(property.id, valueId)
      property.values.splice(valueIndex, 1)
      this.markPropertiesAffected()
    }
  }

  movePropertyValue (property: ProductProperty, fromIndex: number, toIndex: number): void {
    property.values.splice(toIndex, 0, property.values.splice(fromIndex, 1)[0])
    this.markPropertiesAffected()
  }

  movePropertyValueUp (property: ProductProperty, propertyValueIndex: number): void {
    const newIndex = propertyValueIndex - 1

    if (newIndex >= 0) {
      this.movePropertyValue(property, propertyValueIndex, newIndex)
    }
  }

  movePropertyValueDown (property: ProductProperty, propertyValueIndex: number): void {
    const newIndex = propertyValueIndex + 1

    if (newIndex < property.values.length) {
      this.movePropertyValue(property, propertyValueIndex, newIndex)
    }
  }

  // Extras

  openExtraDialog(extra?: ProductExtra): void {
    this.$rootScope.$broadcast(PRODUCT_EVENTS.ADD_PRODUCT_EXTRA_EVENT, { model: extra })
  }

  editExtraByIndex(extraIndex: number): void {
    this.currentlyEditingExtraIndex = extraIndex
    this.openExtraDialog(this.model.extras[extraIndex])
  }

  cancelExtraEditing(): void {
    this.currentlyEditingExtraIndex = null
  }

  markExtrasAffected(): void {
    this.areExtrasAffected = true
  }

  validateExtras(extrasTotalMin: number | string, extrasTotalMax: number | string): void {
    const sanitizedMinValue = extrasTotalMin ? parseInt(extrasTotalMin as string) : 0
    const sanitizedMaxValue = extrasTotalMax ? parseInt(extrasTotalMax as string) : null
    const availableMaximumOfExtras = this.model.extras
      .map((extra: ProductExtra): number => extra.maximumAmount || 0)
      .reduce((sum: number, extraMaximumAmount: number): number => sum += extraMaximumAmount, 0)

    const amountOfUnlimitedExtras = this.model.extras
      .filter((extra: ProductExtra): boolean => !extra.maximumAmount)
      .length

    this.showExtrasTotalMaxLimitError = sanitizedMaxValue && (sanitizedMaxValue < sanitizedMinValue)
    this.showExtrasTotalMinLimitError = !amountOfUnlimitedExtras && (availableMaximumOfExtras < sanitizedMinValue)
    this.areExtrasLimitsValid = !this.showExtrasTotalMinLimitError && !this.showExtrasTotalMaxLimitError
  }

  handleExtraSubmitted(extra: ProductExtra): void {
    if (typeof this.currentlyEditingExtraIndex === 'number') {
      this.updateExtra(extra, this.currentlyEditingExtraIndex)
    } else {
      this.addExtra(extra)
    }

    this.validateExtras(this.model.extrasMinAmount, this.model.extrasMaxAmount)
  }

  addExtra(extra: ProductExtra): void {
    this.model.extras.push(ProductExtra.build(extra))
    this.markExtrasAffected()
  }

  updateExtra(extra: ProductExtra, extraIndex: number): void {
    if (this.model.extras[extraIndex]) {
      this.model.extras[extraIndex] = ProductExtra.build(extra)
      this.currentlyEditingExtraIndex = null
      this.markExtrasAffected()
    }
  }

  deleteExtraByIndex(extraIndex: number): void {
    if (typeof extraIndex === 'number') {
      this.model.extras.splice(extraIndex, 1)
      this.markExtrasAffected()
    }
  }

  moveExtra (fromIndex: number, toIndex: number): void {
    this.model.extras.splice(toIndex, 0, this.model.extras.splice(fromIndex, 1)[0])
    this.markExtrasAffected()
  }

  moveExtraUp (extraIndex: number): void {
    const newIndex = extraIndex - 1

    if (newIndex >= 0) {
      this.moveExtra(extraIndex, newIndex)
    }
  }

  moveExtraDown (extraIndex: number): void {
    const newIndex = extraIndex + 1

    if (newIndex < this.model.extras.length) {
      this.moveExtra(extraIndex, newIndex)
    }
  }

  // Options

  openOptionDialog(option?: ProductOption): void {
    this.$rootScope.$broadcast(PRODUCT_EVENTS.ADD_PRODUCT_OPTION_EVENT, { model: option })
  }

  editOptionByIndex(optionIndex: number): void {
    this.currentlyEditingOptionIndex = optionIndex
    this.openOptionDialog(this.model.options[optionIndex])
  }

  cancelOptionEditing(): void {
    this.currentlyEditingOptionIndex = null
  }

  markOptionsAffected(): void {
    this.areOptionsAffected = true
  }

  handleOptionSubmitted(option: ProductOption): void {
    if (typeof this.currentlyEditingOptionIndex === 'number') {
      this.updateOption(option, this.currentlyEditingOptionIndex)
    } else {
      this.addOption(option)
    }
  }

  addOption(option: ProductOption): void {
    this.model.options.push(ProductOption.build(option))
    this.markOptionsAffected()
  }

  updateOption(option: ProductOption, optionIndex: number): void {
    if (this.model.options[optionIndex]) {
      this.model.options[optionIndex] = option
      this.currentlyEditingOptionIndex = null
      this.markOptionsAffected()
    }
  }

  deleteOptionByIndex(optionIndex: number): void {
    if (typeof optionIndex === 'number') {
      this.model.options.splice(optionIndex, 1)
      this.markOptionsAffected()
    }
  }

  onProductPhotoUploaded(imageUrl: string): void {
    this.model.photoUrl = imageUrl
    this.$scope.$digest()
  }

  onExtraTimeRequiredChange(extraTimeRequired: boolean): void {
    this.model.preparation.extraTimeRequired = extraTimeRequired
  }

  onBusinessHoursChanged(value: any, form: ng.IFormController): void {
    this.model.businessHours = value

    if (form) {
      form.$setDirty()
    }
  }
}

export default {
  controller: ProductFormController,
  templateUrl: require('./product-form.pug')
}
