import uniqBy from 'lodash-es/uniqBy'
import differenceWith from 'lodash-es/differenceWith'
import templateUrl from './multiple-fleets-picker.pug'

class MultipleFleetsPickerController {
  constructor () {
    this.allFleets = []
    this.visibleFleets = []
  }

  $onChanges (simpleChanges) {
    if (simpleChanges.hasOwnProperty('fleets')) {
      this.allFleets = uniqBy(this.fleets, fleet => fleet.name)
    }
    if (simpleChanges.hasOwnProperty('selectedFleets')) {
      this.updateVisibleFleets()
    }
  }

  removeFleet (fleet) {
    if (!this.isDisabled) {
      this.selectedFleets.splice(this.selectedFleets.findIndex(selectedFleet => selectedFleet === fleet), 1)
      this.updateVisibleFleets()
      this.handleFleets()
    }
  }

  updateVisibleFleets () {
    this.visibleFleets = differenceWith(this.allFleets, this.selectedFleets, (allFleetsVal, selectedFleetsVal) => allFleetsVal.name === selectedFleetsVal.name)
  }

  handleFleets () {
    if (this.form) {
      this.form.$setDirty()
    }

    if (this.selectedFleets) {
      this.onSelectedFleetsChange({values: this.selectedFleets})
    }
  }

  onChange (suggestion) {
    if (suggestion) {
      const suggestedFleet = this.fleets.find(fleet => fleet.name === suggestion.name)
      if (suggestedFleet) {
        this.selectedFleets.push(suggestedFleet)
      }
      this.updateVisibleFleets()
      this.handleFleets()
    }
  }
}

export default {
  templateUrl,
  controller: MultipleFleetsPickerController,
  bindings: {
    form: '=',
    isDisabled: '<',
    fleets: '<',
    selectedFleets: '<',
    onSelectedFleetsChange: '&'
  }
}
