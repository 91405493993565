import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import fleetsDashboard from './dashboard/fleetsDashboard'

import fleetForm from './fleet-form/fleetForm'
import fleetBalance from './fleet-balance/fleetBalance'
import fleetTariffs from './fleet-tariffs/fleetTariffs'
import tariffSurcharge from './common/tariff-surcharge/tariffSurcharge'
import tariffSummary from './fleet-tariffs/tariff-summary/tariffSummary'
import fleetTariffForm from './common/fleet-tariff-form/fleetTariffForm'
import fleetTariffsCreate from './fleet-tariffs-create/fleetTariffsCreate'
import fleetTariffsTariff from './fleet-tariffs-tariff/fleetTariffsTariff'
import areaApplicabilityForm from './common/area-applicability-form/areaApplicabilityForm'

import fleetJobHistory from './fleet-balance/fleet-job-history/fleetJobHistory'
import fleetBalanceWithdrawal from './fleet-balance/fleet-balance-withdrawal/fleetBalanceWithdrawal'

export default declareAppModule('logistics.fleets', (module: ng.IModule) => module
  .component('fleetForm', fleetForm)
  .component('fleetBalance', fleetBalance)
  .component('fleetTariffs', fleetTariffs)
  .component('tariffSummary', tariffSummary)
  .component('fleetsDashboard', fleetsDashboard)
  .component('fleetJobHistory', fleetJobHistory)
  .component('fleetTariffForm', fleetTariffForm)
  .component('tariffSurcharge', tariffSurcharge)
  .component('fleetTariffsCreate', fleetTariffsCreate)
  .component('fleetTariffsTariff', fleetTariffsTariff)
  .component('fleetBalanceWithdrawal', fleetBalanceWithdrawal)
  .component('areaApplicabilityForm', areaApplicabilityForm)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'fleets',
      stateKey: 'FLEETS',
      componentName: 'fleetsDashboard',
      requiresPermission: 'FLEETS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.fleets',
      stateName: 'create',
      params: ['targetState'],
      stateKey: 'CREATE_FLEET',
      requiresPermission: 'FLEETS_CREATE',
      componentName: 'fleetForm'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.fleets',
      stateName: 'fleet',
      params: ['targetState'],
      idParam: 'fleetId',
      stateKey: 'FLEET',
      componentName: 'fleetForm'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.fleets.fleet',
      stateName: 'balance',
      stateKey: 'FLEET_BALANCE',
      componentName: 'fleetBalance'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.fleets.fleet',
      stateName: 'tariffs',
      componentName: 'fleetTariffs',
      stateKey: 'FLEET_TARIFFS',
      requiresPermission: 'TARIFFS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.fleets.fleet.tariffs',
      stateName: 'create',
      componentName: 'fleetTariffsCreate',
      stateKey: 'FLEET_TARIFF_CREATE',
      requiresPermission: 'TARIFFS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.fleets.fleet.tariffs',
      stateName: 'tariff',
      idParam: 'tariffId',
      componentName: 'fleetTariffsTariff',
      stateKey: 'FLEET_TARIFF'
    })
  })
)
