import templateUrl from './inbound-outbound-toggle.pug'

class InboundOutboundToggleController {
  constructor () {
    this.values = ['in', 'out']
  }

  onLocalChange (value) {
    this.model = value
    this.onChange({value})
  }
}

export default {
  templateUrl,
  controller: InboundOutboundToggleController,
  bindings: {
    model: '<',
    onChange: '&',
    isDisabled: '<',
    hostClass: '@?'
  }
}
