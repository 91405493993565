import isEqual from 'lodash-es/isEqual'
import userModel from './userModel'
import PubSub from 'common/PubSub'
import Logger from 'common/Logger'

const IS_AUTH_CHANGED_EVENT = 'is-auth-changed'
const logger = new Logger('Auth Model')

/*
 * AUTH MODEL
 * Knowing if we're authed is problematic. We can request the user every time, but this causes tons of API requests.
 * If we're authed, we can cache our isAuthed status. When we get an unauthed request, the user model will tell us
 * we have no user and so we know we're no longer authed. However, the opposite does not happen. If we're not authed,
 * we can cache that, but we won't be told when we become authed unless a login happens or the page requests the user.
 * This causes problems when there are multiple tabs and the user is in different auth states in each.
 *
 * So here we have a halfway house which solves these problems. We cache isAuthed if we're authed, and wait for the
 * userModel to tell us when we're not authed. But if we're not authed, we re-fetch the user every time.
 */
export function authModelFactory (userModel) {
  const authModelPubSub = new PubSub()
  let isAuthed

  function init () {
    userModel.onUserChanged(syncIsAuthedGivenUser)
  }

  function getIsAuthed () {
    if (!isAuthed) {
      // If we don't know if we're authed, or we're not authed, re-sync
      return userModel.getUser()
        .catch(error => {
          logger.error('Could not retrieve user from user model', error)
          return null
        })
        .then(user => {
          syncIsAuthedGivenUser(user)
          return isAuthed
        })
    } else {
      // Otherwise we resolve the most recent value, knowing user model will notify us when changed
      return Promise.resolve(isAuthed)
    }
  }

  function syncIsAuthedGivenUser (user) {
    setIsAuthed(!!user) // We're authed if we have a user
  }

  function setIsAuthed (newIsAuthed) {
    if (!isEqual(isAuthed, newIsAuthed)) {
      isAuthed = newIsAuthed
      authModelPubSub.publish(IS_AUTH_CHANGED_EVENT, isAuthed)
    }
  }

  function onIsAuthedChanged (callback) {
    authModelPubSub.subscribe(IS_AUTH_CHANGED_EVENT, callback)
  }

  init()

  return {
    getIsAuthed,
    onIsAuthedChanged
  }
}

export default authModelFactory(userModel)
