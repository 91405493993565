import { DeliveryStatusesType } from 'common/constants/DeliveryStatuses'
import { StorefrontDeliveryCreationStatusesType } from 'common/constants/StorefrontDeliveryCreationStatuses'
import { StorefrontDeliveryErrorCodesType } from 'common/constants/StorefrontDeliveryErrorCodes'

export default class StorefrontOrderDeliveryInformation {
  constructor(
    public id: string,
    public ref: string,
    public status: DeliveryStatusesType,
    public creation: StorefrontDeliveryCreationStatusesType,
    public errorCode: StorefrontDeliveryErrorCodesType
  ) { }

  static build(dto: any): StorefrontOrderDeliveryInformation {
    return new StorefrontOrderDeliveryInformation(
      dto.id,
      dto.ref,
      dto.status,
      dto.creation,
      dto.errorCode
    )
  }
}
