import get from 'lodash-es/get'
import template from './driver-locations-map.pug'
import BaseMapController from 'presentation/common/BaseMapController'
import envConfig from 'common/envConfig'

class DriverLocationMapController extends BaseMapController {
  constructor ($scope, $element) {
    const mapElement = $element[0].querySelector('.js_map_container')
    const markerType = envConfig.googleMapsSettings.markerTypes.dot.name
    super($scope, mapElement, markerType)

    this.drivers = []
    this.$scope = $scope
    this.driversProcessedFirstTime = false
  }

  $onInit () {
    this.showReadyDrivers = typeof this.showReadyDrivers === 'undefined' ? true : this.showReadyDrivers
    this.showWorkingDrivers = typeof this.showWorkingDrivers === 'undefined' ? true : this.showWorkingDrivers
    this.showOnlineDrivers = typeof this.showOnlineDrivers === 'undefined' ? true : this.showOnlineDrivers
    this.showOfflineDrivers = typeof this.showOfflineDrivers === 'undefined' ? true : this.showOfflineDrivers
    this.showDriversWithIssues = typeof this.showDriversWithIssues === 'undefined' ? true : this.showDriversWithIssues
    this.initMap()

    this.$scope.$watchGroup([
      () => this.showReadyDrivers,
      () => this.showWorkingDrivers,
      () => this.showOnlineDrivers,
      () => this.showOfflineDrivers,
      () => this.showDriversWithIssues
    ], () => {
      this.syncDriverLocationsFromDrivers()
      this.viewAllMarkers()
    })
    this.$scope.$watch(() => this.drivers, () => {
      this.syncDriverLocationsFromDrivers()
    })
  }

  $onDestroy () {
    this.clearMapListeners()
  }

  handleDriverClick (driver) {
    if (this.onDriverClick) {
      this.onDriverClick(driver)
      this.$scope.$apply() // Manually trigger digest cycle as click events originating in Google Maps occur outside of Angular.
    }
  }

  syncDriverLocationsFromDrivers () {
    const markers = this.drivers
      .filter(driver => !!driver)
      .map(driver => this.getMarkerData(driver))
      .filter(marker => !!marker)
    this.checkAndRedrawMarkers(markers)
    this.setZoomState()
  }

  getMarkerData (driver) {
    let marker = null
    const showMarker = (driver.isReady && this.showReadyDrivers)
          || (driver.isWorking && this.showWorkingDrivers)
          || (driver.isOnline && this.showOnlineDrivers)
          || (driver.isOffline && this.showOfflineDrivers)
    if (get(driver, 'lastKnownLocation.latitude', false) && get(driver, 'lastKnownLocation.longitude', false) && showMarker) {
      let status = ''
      if (driver.isOnline) {
        status = 'online'
      } else if (driver.isReady) {
        status = 'ready'
      } else if (driver.isOffline) {
        status = 'offline'
      } else {
        status = driver.status
      }
      marker = {
        id: driver.id,
        location: {
          latitude: driver.lastKnownLocation.latitude,
          longitude: driver.lastKnownLocation.longitude
        },
        status: status,
        onClick: () => this.handleDriverClick(driver)
      }
    }

    return marker
  }

  setZoomState () {
    if (!this.driversProcessedFirstTime && this.drivers.length) {
      this.driversProcessedFirstTime = true
      this.viewAllMarkers()
    }

    if (this.autoSetMapZoom) {
      this.viewAllMarkers()
    }
  }
}

export default {
  templateUrl: template,
  controller: DriverLocationMapController,
  bindings: {
    drivers: '<',
    resetButtonTitle: '@?',
    onDriverClick: '<?',
    fullHeightMode: '<?',
    autoSetMapZoom: '<?',
    showReadyDrivers: '<?',
    showWorkingDrivers: '<?',
    showOnlineDrivers: '<?',
    showOfflineDrivers: '<?',
    showDriversWithIssues: '<?'
  }
}
