import { StateService } from '@uirouter/core'
import get from 'lodash-es/get'
import includes from 'lodash-es/includes'
import filter from 'lodash-es/filter'
import BaseController from 'presentation/common/BaseController'
import driverCollection from 'data/collections/driverCollection'
import envConfig from 'common/envConfig'
import * as DriverStatuses from 'common/constants/DriverStatuses'
import uiSettingsService from 'data/services/uiSettingsService'
import LogisticsSettings from 'data/domain-objects/LogisticsSettings'
import {DRIVER_OVERVIEW_OPEN_POPUP_EVENT} from 'common/constants/PopupEvents'
import Driver from 'data/domain-objects/Driver'

const DEFAULT_REFRESH_DRIVERS_INTERVAL_MS = 5000
const REFRESH_DRIVERS_INTERVAL_MS = get(envConfig, 'driversRefreshInterval', DEFAULT_REFRESH_DRIVERS_INTERVAL_MS)

const EXPAND_LOGISTICS_DASHBOARD_MAP_USER_SETTING_KEY = get(envConfig, 'collapsiblePanelExpandedStateLocalStorageKeys', 'expandLogisticsDashboardMap')

class LogisticsDashboardController extends BaseController {

  public EXPAND_LOGISTICS_DASHBOARD_MAP_USER_SETTING_KEY: string = EXPAND_LOGISTICS_DASHBOARD_MAP_USER_SETTING_KEY
  public allDrivers: Driver[] = []
  public viewingDrivers: Driver[] = []
  public previousfilterFleetsByFleetId: string = null
  public filterFleetsByFleetId: string = null
  public lastSearchQuery: string = ''
  public driverFilterHasChanged: boolean = false

  public driverStatuses: any = DriverStatuses

  public driversOnline: Driver[] = []
  public driversOffline: Driver[] = []
  public driversReady: Driver[] = []
  public driversWorking: Driver[] = []
  public driversBlocked: Driver[] = []

  public showDriversWithIssues: boolean = false
  public showReadyDrivers: boolean = false
  public showWorkingDrivers: boolean = false
  public showOnlineDrivers: boolean = false
  public showOfflineDrivers: boolean = false

  private stopUpdates: boolean = false
  private updateTimeout: number

  static matches (value: string, query: string): boolean {
    const preparedValue = LogisticsDashboardController.prepareStringForSearch(value)

    return includes(preparedValue, query)
  }

  static prepareStringForSearch (value: string): string {
    return value.trim().toLocaleLowerCase()
  }

  public onDriverPinClick: (driver: Driver) => void = driver => this.handleDriverClick(driver.id)
  public onDriverClick: (driverId: string) => void = driverId => this.handleDriverClick(driverId)

  constructor (
    $scope: ng.IScope,
    $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    const logisticsSettings = uiSettingsService.logisticsSettings
    this.showDriversWithIssues = logisticsSettings.showDriversWithIssues
    this.showReadyDrivers = logisticsSettings.showDriversAcceptedHail
    this.showWorkingDrivers = logisticsSettings.showWorkingDrivers
    this.showOnlineDrivers = logisticsSettings.showAvailableDrivers
    this.showOfflineDrivers = logisticsSettings.showNotAvailableDrivers

    this.$scope.$watch('$ctrl.allDrivers', () => this.setSearchQuery())
    this.sync()
  }

  scheduleUpdate (): void {
    if (!this.stopUpdates) {
      this.updateTimeout = window.setTimeout(() => this.sync(), REFRESH_DRIVERS_INTERVAL_MS)
    }
  }

  $onDestroy (): void {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout)
      this.updateTimeout = null
    }
    this.stopUpdates = true
  }

  changeVisibilityFor (driverStatus: string): void {
    if (driverStatus === DriverStatuses.READY) {
      this.showReadyDrivers = !this.showReadyDrivers
    } else if (driverStatus === DriverStatuses.WORKING) {
      this.showWorkingDrivers = !this.showWorkingDrivers
    } else if (driverStatus === DriverStatuses.ONLINE) {
      this.showOnlineDrivers = !this.showOnlineDrivers
    } else if (driverStatus === DriverStatuses.OFFLINE) {
      this.showOfflineDrivers = !this.showOfflineDrivers
    } else if (driverStatus === DriverStatuses.BLOCKED) {
      this.showDriversWithIssues = !this.showDriversWithIssues
    }

    const logisticsSettings = new LogisticsSettings(
      this.showWorkingDrivers,
      this.showReadyDrivers,
      this.showOnlineDrivers,
      this.showOfflineDrivers,
      this.showDriversWithIssues
    )

    uiSettingsService.logisticsSettings = logisticsSettings
  }

  onSync (): Promise<void> {
    return driverCollection.getAll(this.filterFleetsByFleetId)
      .then((drivers: Driver[]) => this.allDrivers = drivers)
      .catch((err: any) => this.logger.error('Cannot get drivers', err))
      .then(() => this.scheduleUpdate())
  }

  onFleetChanged (fleet: ISuggestion): void {
    this.filterFleetsByFleetId = fleet ? fleet.id : null
    this.sync()
  }

  setSearchQuery (query?: string): void {
    const activeSearchQuery = (typeof query === 'string') ? LogisticsDashboardController.prepareStringForSearch(query) : this.lastSearchQuery
    this.setDriverFilterHasChangedState(activeSearchQuery)

    if (activeSearchQuery.length) {
      this.searchDrivers(activeSearchQuery)
    } else {
      this.viewingDrivers = this.allDrivers
    }
    this.syncDriverStats()
  }

  setDriverFilterHasChangedState (activeSearchQuery: string): void {
    if (this.lastSearchQuery !== activeSearchQuery || this.previousfilterFleetsByFleetId !== this.filterFleetsByFleetId) {
      this.driverFilterHasChanged = true
    } else {
      this.driverFilterHasChanged = false
    }

    this.lastSearchQuery = activeSearchQuery // Save query in case API call to get drivers is refreshed.
    this.previousfilterFleetsByFleetId = this.filterFleetsByFleetId
  }

  syncDriverStats (): void {
    this.driversOnline = filter(this.viewingDrivers, (driver: Driver) => driver.status === DriverStatuses.ONLINE)
    this.driversOffline = filter(this.viewingDrivers, (driver: Driver) => driver.isOffline)
    this.driversReady = filter(this.viewingDrivers, (driver: Driver) => driver.isReady)
    this.driversWorking = filter(this.viewingDrivers, (driver: Driver) => driver.isWorking)
    this.driversBlocked = filter(this.viewingDrivers, (driver: Driver) => driver.status === DriverStatuses.BLOCKED)
  }

  searchDrivers (query: string): void {
    this.viewingDrivers = this.allDrivers.filter((driver: Driver) => {
      return LogisticsDashboardController.matches(driver.fullName, query) ||
        LogisticsDashboardController.matches(driver.phoneNumber, query) ||
        LogisticsDashboardController.matches(driver.vehicle.make, query) ||
        LogisticsDashboardController.matches(driver.vehicle.model, query) ||
        LogisticsDashboardController.matches(`${driver.vehicle.make} ${driver.vehicle.model}`, query) ||
        LogisticsDashboardController.matches(driver.vehicle.registrationPlate, query)
    })
  }

  handleDriverClick (driverId: string): void {
    this.$scope.$emit(DRIVER_OVERVIEW_OPEN_POPUP_EVENT, driverId)
  }
}

export default {
  templateUrl: require('./logistics-dashboard.pug'),
  controller: LogisticsDashboardController
}
