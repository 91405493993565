import envConfig from 'common/envConfig'
import {isSameDomain} from 'common/utils/urlUtils'
import { DomainBasedRoles } from 'common/constants/DomainBasedRoles'

class MastheadController {
  // inputs
  public small: boolean

  // props
  public domainBasedRoles: typeof DomainBasedRoles = DomainBasedRoles
  public domainBasedRole: DomainBasedRoles

  $onInit(): void {
    if (isSameDomain(envConfig.platformDomains.tazDeliveryDomain)) {
      this.domainBasedRole = DomainBasedRoles.TAZ
    } else if (isSameDomain(envConfig.platformDomains.sahalatDeliveryDomain)) {
      this.domainBasedRole = DomainBasedRoles.SAHALAT_EXPRESS
    } else if (isSameDomain(envConfig.platformDomains.storefrontAdminDomain)) {
      this.domainBasedRole = DomainBasedRoles.ORDER_DIRECT_ADMIN
    } else {
      this.domainBasedRole = DomainBasedRoles.DRAEWIL_DELIVERY_USER
    }
  }
}

export default {
  templateUrl: require('./masthead.pug'),
  controller: MastheadController,
  bindings: {
    small: '<'
  }
}
