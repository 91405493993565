import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

const storefrontDomainsService = new CrudService(endpoints.STOREFRONT_DOMAINS)

export default {
  getAllDomains (): Promise<DBMappedNamedEntity[]> {
    return storefrontDomainsService.get()
  }
}
