import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'
import storefrontSubscriptionsCollection from 'data/collections/storefrontSubscriptionsCollection'
import StorefrontBusinessWithSubscription from 'data/domain-objects/storefront/StorefrontBusinessWithSubscription'

class SubscriptionsController extends BaseController {

  public isSubscriptionsFetchFailed: boolean = false
  public businessesWithSubscriptions: StorefrontBusinessWithSubscription[] = []

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.sync()
  }

  onSync (): Promise<any> {
    return storefrontSubscriptionsCollection.getSubscriptions()
    .then((result: StorefrontBusinessWithSubscription[]) => {
      this.businessesWithSubscriptions = result
    })
    .catch((error: any) => {
      this.logger.error('Cannot load subscriptions', error)
      this.isSubscriptionsFetchFailed = true
    })
  }

  public updateChangedSubscription(businessWithSubscription: StorefrontBusinessWithSubscription): void {
    const elementIndex: number = this.businessesWithSubscriptions.findIndex((business: StorefrontBusinessWithSubscription) => {
      return business.businessId === businessWithSubscription.businessId
    })

    this.businessesWithSubscriptions[elementIndex] = businessWithSubscription
    this.$scope.$digest()
  }
}

export default {
  controller: SubscriptionsController,
  templateUrl: require('./subscriptions.pug')
}
