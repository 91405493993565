import permissionsModel from 'data/models/permissionsModel'

class ToolsDashboardController {

  public showVouchers: boolean
  public showPlaces: boolean
  public showPromoCodes: boolean

  constructor () {
    this.showVouchers = permissionsModel.getHasPermission('VOUCHERS')
    this.showPlaces = permissionsModel.getHasPermission('PLACES')
    this.showPromoCodes = permissionsModel.getHasPermission('PROMO_CODES')
  }

}

export default {
  templateUrl: require('./tools-dashboard.pug'),
  controller: ToolsDashboardController
}
