import Logger from 'common/Logger'
import Place from 'data/domain-objects/Place'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import {placesCollection} from 'data/collections/placesCollection'
import MultiLangString from 'data/domain-objects/MultiLangString'
import PlaceExit from 'data/domain-objects/PlaceExit'
import { EXIT_POPUP_SHOW_ACTION } from '../exits/exit-popup/exitPopup'
import localeHelper from 'common/localeHelper'

const logger = new Logger('Place Editor Controller')

class PlaceEditorController {
  /* Bindings START */
  public place: Place
  public isEditing: boolean
  public form: ng.IFormController
  /* Bindings END */

  public categories: DBMappedNamedEntity[] = []
  public selectedCategoryId: string = ''
  public websiteValidatePattern: string = '^(https?:\\/\\/)?(www\\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\\.)+[\\w]{2,}(\\/\\S*)?$'
  public currentLanguage: string = localeHelper.getLanguage()

  public placeholderForDirections: MultiLangString = new MultiLangString(
    'e.g. Next to big white building',
    'مثلا بجانب مبنى كبير أبيض',
  )
  public placeholderForComments: MultiLangString = new MultiLangString(
    'e.g. This is the country\'s main international airport',
    'على سبيل المثال ، هذا هو المطار الدولي الرئيسي للبلاد',
  )
  public placeholderForExits: MultiLangString = new MultiLangString(
    'English',
    'عربى'
  )

  constructor(
    private $scope: ng.IScope,
  ) {
    this.$scope.$watch(() => localeHelper.getLocaleData(), (currentLocale: ILocaleData) => {
      this.currentLanguage = currentLocale.language
    })
  }

  $onInit(): void {
    this.getCategories()
  }

  $onChanges(simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.place) {
      this.matchPlaceCategory()
    }
  }

  private getCategories(): void {
    placesCollection.getPlacesCategories()
      .then((categories: DBMappedNamedEntity[]) => {
        this.categories = categories
        this.matchPlaceCategory()
        this.$scope.$digest()
      })
      .catch((error: Error) => {
        logger.error('Can\'t load places categories', error)
      })
  }

  private matchPlaceCategory(): void {
    if (this.place) {
      const selectedCategory: DBMappedNamedEntity = this.categories.find((category: DBMappedNamedEntity) => {
        return category.id === this.place.category.id
      })

      if (selectedCategory) {
        this.selectedCategoryId = selectedCategory.id
      }
    }
  }

  public onCategoryChanged(category: DBMappedNamedEntity | null): void {
    if (category === null) {
      this.place.category = undefined
      this.selectedCategoryId = undefined
      return
    }
    this.place.category = category
    this.selectedCategoryId = category.id
  }

  public addNewExit(): void {
    this.place.exits.unshift(PlaceExit.build())
  }

  public removeExit(exitIndex: number): void {
    this.place.exits.splice(exitIndex, 1)
  }

  public openExitEditPopup (exit: PlaceExit): void {
    this.$scope.$emit(EXIT_POPUP_SHOW_ACTION, { exit })
  }
}

export default {
  templateUrl: require('./place-editor.pug'),
  controller: PlaceEditorController,
  bindings: {
    place: '<',
    isEditing: '<',
    form: '<'
  }
}
