import BaseController from './BaseController'

export default class BaseEntityListController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.entities = []
    this.fetch()
  }

  fetch () {
    this.isLoading = true
    this.beforeFetch()
      .then(entities => this.handleFetchSuccess(entities))
      .catch(error => this.handleFetchFailure(error))
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  beforeFetch () {
    this.logger.warn('Invoked base beforeFetch handler. This should be overridden by child class.')
    return Promise.resolve()
  }

  handleFetchSuccess (entities) {
    this.logger.info('Fetched entities:', entities)
    this.entities = entities
  }

  handleFetchFailure (error) {
    this.logger.error('Could not fetch entities:', error)
  }
}
