export default class ETATime {
  constructor(
    public time: Date = null
  ) { }

  public static build(dto: any = {}): ETATime {
    return new ETATime(
      dto.time ? new Date(dto.time) : null
    )
  }
}
