import templateUrl from './driver-overview.pug'

import * as DriverStatuses from 'common/constants/DriverStatuses'
import * as AuthenticationStatuses from 'common/constants/AuthenticationStatuses'
import * as DriverAppConnectionStatuses from 'common/constants/DriverAppConnectionStatuses'
import envConfig from 'common/envConfig'
import get from 'lodash-es/get'

class DriverOverviewController {
  constructor (kanbanDataService) {
    this.kanbanSettings = kanbanDataService.settings

    this.DRIVER_OVERVIEW_EXPAND_FLEETS_USER_SETTING_KEY = get(envConfig, 'collapsiblePanelExpandedStateLocalStorageKeys', 'expandDriverOverviewFleets')
    this.DriverStatuses = DriverStatuses
    this.AuthenticationStatuses = AuthenticationStatuses
    this.DriverAppConnectionStatuses = DriverAppConnectionStatuses
  }
}

export default {
  templateUrl,
  controller: ['kanbanDataService', DriverOverviewController],
  bindings: {
    driver: '<',
    showDetailsView: '<',
    hideDeliveryPopupDialog: '<?'
  }
}
