import { StateProvider } from '@uirouter/angularjs'
import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import accountBalance from './account-balance/accountBalance'

export default declareAppModule('account.consumer-balance', (module: ng.IModule) => module
  .component('accountBalance', accountBalance)
  .config(($stateProvider: StateProvider) => {
    declareState($stateProvider, {
      parent: 'main.account',
      stateName: 'consumer-balance',
      stateKey: 'ACCOUNT_CONSUMER_BALANCE',
      componentName: 'accountBalance',
      params: [
        'paymentSuccess',
        'paymentFailed',
        'paymentType',
        'topUpAmount',
        'paymentid',
        'result',
        'auth',
        'ref',
        'tranid',
        'postdate',
        'trackid',
        'operdate'
      ]
    })
  })
)
