import moment from 'moment'
import envConfig from 'common/envConfig'
import Logger from 'common/Logger'
import comparator from 'data/common/comparator'
import findIndex from 'lodash-es/findIndex'
import ExportUtils from 'common/utils/exportUtils'

export default class BaseReport {
  constructor ($scope, $interval, $filter, $document) {
    this.$scope = $scope
    this.$interval = $interval
    this.$filter = $filter
    this.$document = $document
    this.isLoading = false
    this.logger = new Logger(this.constructor.name)
  }

  getFormattedValue (params) {
    const value = params.values[0]
    let res

    switch (params.type) {
    case 'datetime':
      return (moment(value).isValid()) ? moment(value).format(envConfig.defaultDateFormat) : envConfig.reports.invalidData

    case 'date':
      return (moment(value).isValid()) ? moment(value).format(envConfig.dateOnlyDateFormat) : envConfig.reports.invalidData

    case 'timestamp':
    case 'time':
      return (moment(value).isValid()) ? moment(value).format(envConfig.timeOnlyDateFormat) : envConfig.reports.invalidData

    case 'boolean':
      return value ? '✔' : envConfig.reports.invalidData

    case 'percent':
      if (value === null) {
        return envConfig.reports.invalidData
      }
      return `${(params.precision !== undefined) ? value.toFixed(params.precision) : value}%`

    case 'number':
    case 'distance':
      if (value === null) {
        return envConfig.reports.invalidData
      }
      return (params.precision !== undefined) ? value.toFixed(params.precision) : value

    case 'cost':
      return value.toFixed(params.precision || 2) + ' ' + (params.currency || 'KWD')

    case 'string':
      return value

    case 'duration':
      if (!value) {
        return envConfig.reports.invalidData
      }

      if (params.precision === 'minutes') {
        return Math.round(value.asMinutes())
      }
      if (params.precision === 'hours') {
        return Math.round(value.asHours())
      }

      return value.humanize()

    case 'paymentType':
      return value ? this.$filter('translate')(`COMMON.PAYMENT_METHODS.${value}`) : value

    case 'cancellation':
      res = ''
      // when
      res += (moment(value).isValid()) ? moment(value).format(envConfig.defaultDateFormat) : ''
      // who
      res += (params.values[1] !== null) ? ` ${params.values[1]} ` : ''
      // role
      res += (params.values[2] !== null) ? `(${params.values[2]})` : ''

      return (res === '') ? envConfig.reports.invalidData : res

    default:
      this.logger.warn('UNKNOWN FORMAT', params.type, params.values) // eslint-disable-line
      return value
    }
  }

  onBeforeUpdateData () {
    this.isLoading = true
    return Promise.resolve()
  }

  onAfterUpdateData () {
    this.isLoading = false
    this.$scope.$apply()

    return Promise.resolve()
  }

  /**
   * This method supposed to be overriden
   */
  onUpdateData () {
    return Promise.resolve()
  }

  updateData () {
    if (this.isLoading) {
      return
    }

    return this.onBeforeUpdateData()
      .then(() => this.onUpdateData())
      .then(() => this.onAfterUpdateData())
  }

  // TODO add more comparators for other data types
  sortData (rawData, columnsConfig, orderBy, orderDirection) {
    // handle empty data sets or data sets with empty columns config
    if (!rawData || !rawData.length || !columnsConfig || !columnsConfig.length) {
      return []
    }

    let orderByName = orderBy
    // if no default sorting, we gonna use first column
    if (orderByName === null) {
      orderByName = columnsConfig[0].name
    }

    const resultData = rawData.slice(0)
    const activeColumnIndex = findIndex(columnsConfig, col => {
      return col.name === orderByName
    })
    const sortType = columnsConfig[activeColumnIndex].type
    const dataRef = columnsConfig[activeColumnIndex].data[0]

    let compareFn = null

    switch (sortType) {
    case 'string':
      compareFn = comparator.string
      break

    case 'date':
    case 'time':
      compareFn = comparator.momentDates
      break

    default:
      compareFn = comparator.basic
    }

    resultData.sort((prevRow, nextRow) => {
      const sortingResult = compareFn(prevRow[dataRef], nextRow[dataRef])
      return orderDirection === 'ASC' ? sortingResult : 0 - sortingResult
    })

    return resultData
  }

  formatData (rawData, columnsConfig) {
    const records = rawData.slice(0)

    records.forEach(rowData => {
      columnsConfig.forEach(column => {
        const valuesList = column.data.map(dataKey => rowData[dataKey])

        rowData[column.name] = this.getFormattedValue({
          type: column.type,
          precision: column.precision || 0,
          values: valuesList,
          name: column.name
        })
      })
    })

    return records
  }

  exportData (reportName, data, columns, titlePrefix) {
    // getting column titles with translations
    const columnTitles = columns.map(column => {
      return this.$filter('translate')(titlePrefix + column.title)
    })
    const fileName = reportName + '_' + moment(Date.now()).format(envConfig.defaultDateFormat) + '.csv'
    ExportUtils.exportCSV(fileName, data, columns, columnTitles)
  }

  handleColumns (columns, host = this) {
    // upon first request for data we receive
    // reports columns configuration
    if (Array.isArray(columns)) {
      host.columnsConfig = columns.map((column, idx) => {
        return {
          name: `${column.data[0]}_${idx}`,
          title: column.title.toUpperCase(),
          data: column.data,
          type: column.type,
          precision: column.precision
        }
      })

      // default filtering using first column
      if (host.filters.orderBy === null) {
        host.filters.orderBy = host.columnsConfig[0].name
      }
    }
  }

  onDestroy (collection) {
    if (collection && typeof collection.purgeCache === 'function') {
      collection.purgeCache()
    }
    this.cancelUpdate()
  }

  cancelUpdate () {
    this.$interval.cancel(this.updateInterval)
  }

  scheduleUpdate () {
    this.cancelUpdate()
    if (this.updateIntervalConfig) {
      this.updateInterval = this.$interval(() => this.updateData(), this.updateIntervalConfig)
    }
  }
}

