import CrudService from 'data/services/CrudService'
import endpoints from 'common/endpoints'
import BranchServedAreas from 'data/domain-objects/BranchServedAreas'
import BranchServedAreasSettings from 'data/domain-objects/BranchServedAreasSettings'

const storefrontServedAreasSettings = new CrudService(endpoints.STOREFRONT_SERVED_AREAS)

export default {
  getServedAreas: (businessId: string): Promise<BranchServedAreas> => {
    return storefrontServedAreasSettings.get([businessId])
      .then((response: any): BranchServedAreas => BranchServedAreas.build(response))
  },
  updateServedAreas: (businessId: string, servedAreas: any, settings: BranchServedAreasSettings): Promise<BranchServedAreas> => {
    return storefrontServedAreasSettings.update({ servedAreas, settings }, [businessId])
      .then((response: any): BranchServedAreas => BranchServedAreas.build(response))
  }
}
