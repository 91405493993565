import templateUrl from './checkbox-input.pug'

export default {
  templateUrl,
  bindings: {
    form: '=',
    name: '@',
    model: '=',
    value: '@',
    label: '@',
    isDisabled: '=',
    isRequired: '='
  }
}
