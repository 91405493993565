import envConfig from 'common/envConfig'
import templateUrl from './delivery-type-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import featuresModel from 'data/models/featuresModel'

const CONSUMER_TO_BRANCH_FEATURE_KEY = 'FROM_CONSUMER_DELIVERY'
const CONSUMER_TO_BRANCH_DELIVERY_TYPE_KEY = 'consumer'

const SETTINGS_KEYS = [
  'showDeliveryType',
  'deliveryType'
]

class DeliveryTypeSettings extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)

    const deliveryTypes = envConfig.businessAndBranchSettings.availableDeliveryTypes

    this.deliveryTypes = (!featuresModel.isFeatureEnabled(CONSUMER_TO_BRANCH_FEATURE_KEY)) ?
      deliveryTypes.filter(deliveryType => deliveryType !== CONSUMER_TO_BRANCH_DELIVERY_TYPE_KEY) : deliveryTypes
  }
}

export default {
  templateUrl,
  controller: DeliveryTypeSettings,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
