import moment from 'moment'
import envConfig from 'common/envConfig'
import templateUrl from './requested-time-input.pug'
import RequestedTime from 'data/domain-objects/RequestedTime'
import {DATE_TIME_PICKER_CHANGE_DATE_EVENT, DATE_TIME_PICKER_OPEN_EVENT} from 'presentation/_core-forms/date-time-picker/dateTimePicker'

class RequestedTimeInputController {
  constructor ($scope) {
    this.$scope = $scope

    this.time = null
    this.minDate = moment().subtract(1, 'day').toDate()
    this.requestedTimeTypes = RequestedTime.TYPES
    this.timeDelaysMinutes = envConfig.requestedTime.inMinutes
    this.timeDelayMinutes = null
    this.timeDelayDropdownFocused = false
    this.format = envConfig.defaultDateFormat

    this.dateTimePickerConfig = {
      selectedDate: new Date(),
      showDate: true,
      showTime: true,
      minDate: this.minDate
    }
  }

  $onInit () {
    this.requestedTimeType = this.requestedTime ? this.requestedTime.getType() : {}

    if (this.requestedTime.afterDelayMinutes) {
      this.timeDelayMinutes = this.requestedTime.afterDelayMinutes.toString()
    } else if (this.defaultPickupTimeInMinutes) {
      this.timeDelayMinutes = this.defaultPickupTimeInMinutes.toString()
    } else {
      this.timeDelayMinutes = this.timeDelaysMinutes[0].toString()
    }
  }

  changeRequestedTimeType (requestedTimeType) {
    this.requestedTimeType = requestedTimeType
    const requestedTime = this.getRequestedTimeGivenType(this.requestedTimeType)
    this.onRequestedTimeChanged({requestedTime})
  }

  getRequestedTimeGivenType (requestedTimeType) {
    const requestedTime = new RequestedTime()
    if (requestedTimeType === RequestedTime.TYPES.ASAP) {
      requestedTime.setAsap(true)
    } else if (requestedTimeType === RequestedTime.TYPES.AFTER_DELAY_MINUTES) {
      requestedTime.setAfterDelayMinutes(this.timeDelayMinutes)
    } else if (requestedTimeType === RequestedTime.TYPES.AT_DATE_TIME) {
      const date = new Date((this.time === null) ? this.time : moment(this.time, this.format).valueOf())
      requestedTime.setAtDateTime(date)
    }
    return requestedTime
  }

  /* NESTED INNER DROPDOWN HANDLERS
   * We need the outer radio button around the dropdown to appear focused when the inner
   * dropdown is focused, so we use a flag for this. We also need the time type to be set
   * to 'AFTER_DELAY_MINUTES' when the dropdown is clicked or changed. */
  handleTimeDelayDropdownClicked () {
    this.timeDelayDropdownFocused = true
    this.changeRequestedTimeType(RequestedTime.TYPES.AFTER_DELAY_MINUTES)
  }

  handleTimeDelayDropdownChanged () {
    this.changeRequestedTimeType(RequestedTime.TYPES.AFTER_DELAY_MINUTES)
  }

  handleTimeDelayDropdownBlurred () {
    this.timeDelayDropdownFocused = false
  }

  showDateTimePicker () {
    this.$scope.$emit(DATE_TIME_PICKER_OPEN_EVENT, this.dateTimePickerConfig)
    this.cancelDateTimePickerSubscription = this.$scope.$on(DATE_TIME_PICKER_CHANGE_DATE_EVENT, (...args) => this.handleTimePickerClose(...args))
  }

  handleTimePickerClose (event, payload) {
    this.time = moment(payload.date).format(this.format)
    this.dateTimePickerConfig.selectedDate = payload.date
    this.changeRequestedTimeType(this.requestedTimeType)
    this.cancelDateTimePickerSubscription()
  }
}

export default {
  templateUrl,
  controller: RequestedTimeInputController,
  bindings: {
    form: '<',
    name: '@',
    requestedTime: '<?',
    onRequestedTimeChanged: '&',
    isDisabled: '<',
    isRequired: '<',
    showAsap: '<',
    showInMinutes: '<',
    showDateTime: '<',
    isDatePickerOpen: '=',
    defaultPickupTimeInMinutes: '<'
  }
}
