import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const packageTypesService = new CrudService(endpoints.PACKAGE_TYPES)

export function packageTypesCollectionFactory (packageTypesService) {
  return {
    getAll: function () {
      return packageTypesService.get([], {useCache: true})
    }
  }
}

export default packageTypesCollectionFactory(packageTypesService)
