import { StateParams, StateService } from '@uirouter/core'
import pull from 'lodash-es/pull'
import cloneDeep from 'lodash-es/cloneDeep'
import differenceWith from 'lodash-es/differenceWith'
import uniq from 'lodash-es/uniq'
import StorefrontSettingsController from 'presentation/businesses/common/StorefrontSettingsController'
import areasCollection from 'data/collections/areasCollection'
import branchesCollection from 'data/collections/branchesCollection'
import businessesCollection from 'data/collections/businessesCollection'
import MultiLangString from 'data/domain-objects/MultiLangString'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import { collectEntityFieldByIds } from 'data/common/collectByIds'
import StorefrontBranchAreasConfiguration from 'data/domain-objects/storefront/StorefrontBranchAreasConfiguration'

enum BranchDisplayModes {
  SHOW = 'show',
  HIDE = 'hide',
  OMIT_ADDRESS_AND_PIN = 'omitAddressAndPin'
}

const BRANCH_DISPLAY_MODE_OPTIONS: BranchDisplayModes[] = [
  BranchDisplayModes.SHOW,
  BranchDisplayModes.HIDE,
  BranchDisplayModes.OMIT_ADDRESS_AND_PIN
]

const BRANCH_OPERATION_BLOCK_FIELD_KEYS = [
  'storefront__acceptingOrders'
]

const AVAILABLE_SERVICES_BLOCK_FIELD_KEYS = [
  'storefront__services__delivery__isAvailable',
  'storefront__services__delivery__minimumCart__value',
  'storefront__services__delivery__minimumCart__currencyCode',
  'storefront__services__delivery__payment__cash__enabled',
  'storefront__services__collection__isAvailable',
  'storefront__services__collection__minimumCart__value',
  'storefront__services__collection__minimumCart__currencyCode',
  'storefront__services__collection__payment__cash__enabled',
]

const OPENING_HOURS_BLOCK_FIELD_KEYS = [
  'storefront__businessHours'
]

const ORDER_PREPARATION_TIME = [
  'orderPreparationTime'
]

const DELIVERY_TIME = [
  'storefront__deliveryTime',
  'storefront__timeSlot__duration'
]

const DELIVERY_PROVIDER_BLOCK_FIELD_KEYS = [
  'storefront__deliveryProvider',
  'storefront__services__delivery__deliveryCost__currencyCode',
  'storefront__services__delivery__deliveryCost__value',
  'storefront__services__draewil__deliveryCost__currencyCode',
  'storefront__services__draewil__deliveryCost__value'
]

class BranchStorefrontSettingsController extends StorefrontSettingsController {
  public businessName: MultiLangString
  public branchName: MultiLangString
  public settings: any
  public blocksOverrideState: IBlockOverrideStatesGroup
  public allAreas: any[]
  public availableCustomizableAreas: any[]
  public areaNamesById: any
  public availableAreas: any[]
  public branchDisplayModeOptions: BranchDisplayModes[] = BRANCH_DISPLAY_MODE_OPTIONS

  public areasSettings: StorefrontBranchAreasConfiguration[] = []

  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
    private $stateParams: StateParams
  ) {
    super($scope, $state)

    this.blocksOverrideState = {
      branchOperation: {
        fieldKeys: BRANCH_OPERATION_BLOCK_FIELD_KEYS,
        useDefault: false
      },
      availableServices: {
        fieldKeys: AVAILABLE_SERVICES_BLOCK_FIELD_KEYS,
        useDefault: false
      },
      openingHours: {
        fieldKeys: OPENING_HOURS_BLOCK_FIELD_KEYS,
        useDefault: false
      },
      orderPreparationTime: {
        fieldKeys: ORDER_PREPARATION_TIME,
        useDefault: false
      },
      deliveryTime: {
        fieldKeys: DELIVERY_TIME,
        useDefault: false
      },
      deliveryProvider: {
        fieldKeys: DELIVERY_PROVIDER_BLOCK_FIELD_KEYS,
        useDefault: false
      }
    }
  }

  $onInit(): void {
    if (this.$stateParams.businessId && this.$stateParams.branchId) {
      this.sync()
    }
  }


  onSync(): Promise<any> {
    return Promise.all([
      this.getBusiness(this.$stateParams.businessId),
      this.getBranch(this.$stateParams.branchId),
      this.getSettings().then(() => this.getAreas()),
      this.getAreasSettings(this.$stateParams.businessId, this.$stateParams.branchId)
    ])
  }

  getBusiness(businessId: string): Promise<void | MultiLangString> {
    return businessesCollection.getWithAllLanguages(this.$stateParams.businessId)
      .then((business: any): MultiLangString => this.businessName = MultiLangString.build(business.name))
      .catch((error: Error): void => this.logger.error('Cannot get business with given businessId', error))
  }

  getBranch(branchId: string): Promise<void | MultiLangString> {
    return branchesCollection.getWithAllLanguages(this.$stateParams.branchId)
      .then((branch: any): MultiLangString => this.branchName = MultiLangString.build(branch.name))
      .catch((error: Error): void => this.logger.error('Cannot get branch with given branchId', error))
  }

  getSettings(): Promise<void | any> {
    return branchesCollection.getStorefrontSettings(this.$stateParams.businessId, this.$stateParams.branchId)
      .then((settings: any) => {
        this.handleSettingsReceived(settings)
        this.updateBlocksOverrideState()
      })
      .catch((error: Error): void => this.logger.error('Cannot get branch storefront settings', error))
  }

  getAreas(): Promise<void | any> {
    return areasCollection.getRealGeographicalAreasForStorefrontCached()
      .then((areas: DBMappedNamedEntity[]) => {
        this.allAreas = areas
        this.areaNamesById = collectEntityFieldByIds(areas)
        this.handleAreas()
      })
      .catch((error: Error): void => this.logger.error('Cannot get areas', error))
  }

  getAreasSettings(businessid: string, branchId: string): Promise<void> {
    return branchesCollection.getBranchAreasSettings(businessid, branchId)
      .then((areasSettings: StorefrontBranchAreasConfiguration[]) => { this.areasSettings = areasSettings })
      .catch((error: Error): void => this.logger.error('Cannot get branch areas settings', error))
  }

  get hasSelectedAreas(): boolean {
    return this.localSettings && this.localSettings.selectedAreas.length === 0
  }

  handleAreas(): void {
    this.availableAreas = differenceWith(this.allAreas, this.localSettings.selectedAreas, (areaObj: DBMappedNamedEntity, id: string): boolean => {
      return areaObj.id === id
    })

    this.handleServedAreasChange()
  }

  handleServedAreasChange(): void {
    const selectedCustomizableAreas = uniq(this.areasSettings
      .map((settings: StorefrontBranchAreasConfiguration): string[] => settings.areas)
      .reduce((acc: string[], areaIds: string[]): string[] => acc.concat(areaIds), [])
    )
    this.availableCustomizableAreas = differenceWith(this.localSettings.selectedAreas, selectedCustomizableAreas)
      .map((areaId: string): DBMappedNamedEntity => {
        return {
          id: areaId,
          name: this.areaNamesById[areaId]
        }
      })
  }

  onAreaPicked(suggestion: DBMappedNamedEntity | null, form: ng.IFormController): void {
    if (suggestion) {
      this.localSettings.selectedAreas.push(suggestion.id)
      this.handleAreas()
      this.setFormDirty(form)
    }
  }

  removeArea(area: string, form: ng.IFormController): void {
    this.localSettings.selectedAreas = pull(this.localSettings.selectedAreas, area)
    this.areasSettings.forEach((settings: StorefrontBranchAreasConfiguration): void => this.removeCustomizableArea(settings, area, form))
    this.handleAreas()
    this.setFormDirty(form)
  }

  updateBlocksOverrideState(): void {
    Object.keys(this.blocksOverrideState).forEach((blockKey: string) => {
      this.blocksOverrideState[blockKey].useDefault = !this.blocksOverrideState[blockKey].fieldKeys
        .map((fieldKey: string): boolean => this.settings[fieldKey].isOverridden)
        .filter((isFieldOverriden: boolean): boolean => isFieldOverriden)
        .length
    })
  }

  onOverrideModeChange(blockState: IBlockOverrideState, useDefault: boolean = false, form: ng.IFormController): void {
    const fieldKeys = blockState.fieldKeys

    blockState.useDefault = useDefault

    fieldKeys.forEach((fieldKey: string): void => {
      this.settings[fieldKey].isOverridden = !useDefault

      if (useDefault) {
        this.localSettings[fieldKey] = cloneDeep(this.settings[fieldKey].defaultValue)
      }
    })

    if ([AVAILABLE_SERVICES_BLOCK_FIELD_KEYS, DELIVERY_PROVIDER_BLOCK_FIELD_KEYS].includes(blockState.fieldKeys)) {
      this.convertSettingsToCostValues()
    }

    this.setFormDirty(form)
  }

  setFormDirty(form: ng.IFormController): void {
    if (form && form.$setDirty) {
      form.$setDirty()
    }
  }

  addAreasNewConfiguration(): void {
    this.areasSettings.push(StorefrontBranchAreasConfiguration.build({}))
  }

  onCustomizableAreaPicked(settings: StorefrontBranchAreasConfiguration, area: DBMappedNamedEntity, form: ng.IFormController): void {
    if (area && area.id) {
      settings.areas.push(area.id)
      this.handleServedAreasChange()
      form.$setDirty()
    } else {
      this.logger.error('Attempting to add empty area to current customizable areas configuration')
    }
  }

  removeCustomizableArea(settings: StorefrontBranchAreasConfiguration, areaId: string, form: ng.IFormController): void {
    const areaIdIndex = settings.areas.indexOf(areaId)

    if (areaId && areaIdIndex >= 0) {
      settings.areas.splice(areaIdIndex, 1)
      this.handleServedAreasChange()
      form.$setDirty()
    }
  }

  removeCustomizableAreasConfiguration(index: number, form: ng.IFormController): void {
    if (this.areasSettings[index]) {
      this.areasSettings.splice(index, 1)
      this.handleServedAreasChange()
      form.$setDirty()
    } else {
      this.logger.error(`Cannot remove customizable areas configuration with index (${index}). Given index does not exist.`)
    }
  }

  beforeSubmit(): Promise<any> {
    super.beforeSubmit()

    return branchesCollection.updateStorefrontSettings(
      this.getUpdatedSettings(),
      this.$stateParams.businessId,
      this.$stateParams.branchId
    ).then((storefrontSettings: any): Promise<any> => {
      return branchesCollection.updateBranchAreasSettings(
        this.areasSettings,
        this.$stateParams.businessId,
        this.$stateParams.branchId
      ).then((areasSettings: StorefrontBranchAreasConfiguration[]): any => {
        this.areasSettings = areasSettings
        return storefrontSettings
      })
    })
  }
}

export default {
  controller: BranchStorefrontSettingsController,
  templateUrl: require('./branch-storefront-settings.pug')
}
