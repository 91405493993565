import deliveriesCollection from 'data/collections/deliveriesCollection'
import userCollection from 'data/collections/userCollection'
import fleetsCollection from 'data/collections/fleetsCollection'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import {EVENT_TYPES} from 'data/collections/deliveriesCollection'
import PubSub from 'common/PubSub'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import Logger from 'common/Logger'
import driverCollection from 'data/collections/driverCollection'
import Driver from 'data/domain-objects/Driver'
import Delivery from 'data/domain-objects/Delivery'
import DeliveryEvent from 'data/domain-objects/DeliveryEvent'

const logger = new Logger('DeliveryPopup')

class DeliveryDataService {
  private pubSub: PubSub = new PubSub()
  constructor () {}

  publishDeliveryStatusChangeEvent (status: string): void {
    this.pubSub.publish(DELIVERY_ACTIONS.STATUS_UPDATE_FINISHED, status)
  }

  publishDeliveryLoadingEvent (): void {
    this.pubSub.publish(DELIVERY_ACTIONS.LOADING)
  }

  publishDeliverySyncEvent (): void {
    this.pubSub.publish(DELIVERY_ACTIONS.SYNC)
  }

  subscribeOnDeliveryLoading (callback: () => void): IPubSubscription {
    return this.pubSub.subscribe(DELIVERY_ACTIONS.LOADING, callback)
  }

  subscribeOnDeliveryStatusChange (callback: (status?: string) => void): IPubSubscription {
    return this.pubSub.subscribe(DELIVERY_ACTIONS.STATUS_UPDATE_FINISHED, callback)
  }

  subscribeOnDeliverySync (callback: () => void ): IPubSubscription {
    return this.pubSub.subscribe(DELIVERY_ACTIONS.SYNC, callback)
  }

  conditionallyExtendEvent (event: DeliveryEvent): Promise<any> {
    let promiseChain: Promise<any> = Promise.resolve()

    if (event && event.payload.fromUser) {
      promiseChain = promiseChain.then(() => userCollection.getCached(event.payload.fromUser))
        .then((user: any) => event.initiatedByUserName = `${user.fullName} (+${user.countryDiallingCode}${user.phoneNumber})`)
        .catch((err: Error) => logger.error('Cannot get user for delivery events log', err))
    }

    if (event && event.type === EVENT_TYPES.DELIVERY.ASSIGN && event.payload.driverId) {
      promiseChain = promiseChain.then(() => driverCollection.getCached(event.payload.driverId))
        .then((driver: Driver) => {
          event.driverData = `${driver.firstName} ${driver.lastName} (+${driver.countryDiallingCode}${driver.phoneNumber})`
        })
    }

    return promiseChain
  }

  processPermissionsDeliveryViaFleetSettings (delivery: any): any {
    if ([DELIVERY_STATUSES.PENDING, DELIVERY_STATUSES.ALLOCATING, DELIVERY_STATUSES.ALLOCATED, DELIVERY_STATUSES.AT_PICKUP].indexOf(delivery.status) >= 0) {
      return fleetsCollection.getSettingsCached(delivery.fleetId)
        .then((settings: any) => {
          const isManagerAllowedToTransferDelivery = (settings) ? !!settings.allowManagerTransferDelivery : false
          if (isManagerAllowedToTransferDelivery) {
            delivery.permissions = delivery.permissions || []
            delivery.permissions.push('allowManagerTransferDelivery')
          }
          return delivery
        })
        .catch((err: Error) => logger.error('Cannot get fleet settings', err))
    } else {
      return delivery
    }
  }

  getById (deliveryId: string, doProcessPermissionsViaFleetSettings: boolean = true): Promise<Delivery> {
    return deliveriesCollection.getById(deliveryId)
      .then((delivery: Delivery) => doProcessPermissionsViaFleetSettings ? this.processPermissionsDeliveryViaFleetSettings(delivery) : delivery)
      .then((delivery: Delivery) => {
        const eventsPromises = []
        if (delivery.hasOwnProperty('events')) {
          delivery.events.forEach((event: DeliveryEvent) => eventsPromises.push(this.conditionallyExtendEvent(event)))
          return Promise.all(eventsPromises).then(() => delivery)
        } else {
          delivery.events = []
          return delivery
        }
      })
  }
}

export default new DeliveryDataService()
