import uniq from 'lodash-es/uniq'
import find from 'lodash-es/find'
import envConfig from 'common/envConfig'
import countriesCollection from './countriesCollection'

export function diallingCodesCollectionFactory (envConfig) {
  let dialingCodesCache = null
  let defaultDiallingCode = null

  function getDiallingCodes () {
    if (dialingCodesCache) {
      return Promise.resolve(dialingCodesCache)
    }
    return countriesCollection.getCountriesCached().then(countries => {
      const unsortedDiallingCodes = countries.map(country => country.diallingCode)
      const sortedDiallingCodes = unsortedDiallingCodes.sort()
      dialingCodesCache = uniq(sortedDiallingCodes)
      return dialingCodesCache
    })
  }

  function getDefaultDiallingCode () {
    if (defaultDiallingCode) {
      return Promise.resolve(defaultDiallingCode)
    }

    return countriesCollection.getCountriesCached().then(countries => {
      const defaultCountry = find(countries, {code: envConfig.defaultCountry}) || {}
      defaultDiallingCode = defaultCountry.diallingCode || ''
      return defaultDiallingCode
    })
  }

  return {
    getAll: getDiallingCodes,
    getDefault: getDefaultDiallingCode
  }
}

export default diallingCodesCollectionFactory(envConfig)
