function tableCellController ($scope, $element, $transclude) {
  const $ctrl = this

  function init () {
    $transclude($scope.$parent, function (transEl) {
      $element.append(transEl)
    })
  }

  $ctrl.$onInit = init
}

export default {
  controller: tableCellController,
  require: {
    table: '^dynamicTable'
  },
  scope: {
    columnKey: '=',
    value: '='
  }
}
