import envConfig from 'common/envConfig'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import { StorefrontOrdersStatusesType, STOREFRONT_ORDER_STATUSES } from 'common/constants/StorefrontOrderStatuses'
import StorefrontOrder from 'data/domain-objects/storefront/StorefrontOrder'
import {getApiPathForKey} from 'common/endpoints'
import DateUtils from 'common/utils/dateUtils'

const storefrontOrdersService = new CrudService(endpoints.STOREFRONT_ORDERS)
const storefrontOrderService = new CrudService(endpoints.STOREFRONT_ORDER)
const storefrontOrderStatusService = new CrudService(endpoints.STOREFRONT_UPDATE_ORDER_STATUS)
const storefrontAcceptOrderService = new CrudService(endpoints.STOREFRONT_ACCEPT_ORDER)
const storefrontOrderDeliveryService = new CrudService(endpoints.STOREFRONT_ORDER_DELIVERY)
const storefrontOrderRefundService = new CrudService(endpoints.STOREFRONT_ORDER_REFUND)

const salesReportRawDataPath = getApiPathForKey('storefrontOrderHistory')

export enum StorefrontOrdersSorting {
  created = 'createdAt',
  updated = 'updatedAt',
  createdReversed = '-createdAt',
  updatedReversed = '-updatedAt',
  desiredTime = 'shipping.desiredTime'
}

export default {
  getOrders(
    businessId: string,
    branchId?: string,
    searchQuery?: string,
    status?: StorefrontOrdersStatusesType | StorefrontOrdersStatusesType[],
    limit: number = 1000,
    skip: number = 0,
    sort?: StorefrontOrdersSorting | StorefrontOrdersSorting[],
    startDate?: Date,
    endDate?: Date
  ): Promise<StorefrontOrder[]> {
    const params: any = {
      limit,
      skip,
    }

    if (branchId) {
      params.branchId = branchId
    }

    if (searchQuery) {
      params.q = searchQuery
    }

    if (status) {
      params.status = status
    }

    if (sort) {
      params.sort = sort
    }

    if (startDate) {
      params.createdAfter = DateUtils.getFormattedDate(startDate)
    }

    if (endDate) {
      params.createdBefore = DateUtils.getFormattedDate(endDate)
    }

    return storefrontOrdersService.get([businessId], params, true)
      .then((response: any): any => {
        response.records = response.records.map(StorefrontOrder.build)
        return response
      })
  },
  getById(businessId: string, orderId: string): Promise<StorefrontOrder> {
    return storefrontOrderService.get([businessId, orderId], {}, true)
      .then(StorefrontOrder.build)
  },
  getSalesReportCSVLink(businessId: string, start: string, finish: string = '', branchId: string = ''): string {
    const apiUrl = `${envConfig.api.prefix}${salesReportRawDataPath}`.replace('{businessId}', businessId)

    const params: any = [`start=${start}`]

    if (branchId) {
      params.push(`branchId=${branchId}`)
    }

    if (finish) {
      params.push(`finish=${finish}`)
    }

    return `${apiUrl}?${params.join('&')}`
  },
  updateOrderStatus(
    businessId: string,
    orderId: string,
    status: StorefrontOrdersStatusesType,
    extraTime?: number,
    createDelivery?: boolean
  ): Promise<void> {
    const isTransitionToAccepted = status === STOREFRONT_ORDER_STATUSES.ACCEPTED

    const params: any = {
      status
    }

    if (isTransitionToAccepted) {
      params.extraTime = extraTime
      params.createDelivery = !!createDelivery
    }

    return storefrontOrderStatusService.update(params, [businessId, orderId])
  },
  acceptOrder(businessId: string, orderId: string): Promise<void> {
    return storefrontAcceptOrderService.create(null, [businessId, orderId])
  },
  retryDeliveryCreation(businessId: string, orderId: string): Promise<void> {
    return storefrontOrderDeliveryService.create(null, [businessId, orderId])
  },
  requestRefund(businessId: string, orderId: string): Promise<void> {
    return storefrontOrderRefundService.create(null, [businessId, orderId])
  }
}
