import sortBy from 'lodash-es/sortBy'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import {getItemFromLocalStorage} from 'common/utils/storageUtils'
import localeHelper from 'common/localeHelper'
const countriesService = new CrudService(endpoints.COUNTRIES)

let countriesGetAttempts = 0
const COUNTRIES_RETRIES_LIMIT = 3
const COUNTRIES_STORAGE_KEY = `draewilCountriesList_${localeHelper.getLocale()}`

export function CountriesCollectionFactory () {
  function sortByName (countries) {
    return sortBy(countries, ['name'])
  }

  function getCountriesCached () {
    const savedCountries = getItemFromLocalStorage(COUNTRIES_STORAGE_KEY, null)
    if (savedCountries !== null) {
      return Promise.resolve(savedCountries)
    } else {
      return countriesService.get([], {useCache: true})
        .catch(() => {
          // Temprorary hack for DP-3159
          countriesGetAttempts += 1
          if (countriesGetAttempts < COUNTRIES_RETRIES_LIMIT) {
            return countriesService.get([], {useCache: true})
          }
        })
        .then(rawCountries => {
          const countries = sortByName(rawCountries)
          localStorage.setItem(COUNTRIES_STORAGE_KEY, JSON.stringify(countries))
          return countries
        })
    }
  }

  function getCountries () {
    return countriesService.get([]).then(rawCountries => sortByName(rawCountries))
  }

  return {
    getCountries,
    getCountriesCached
  }
}

export default CountriesCollectionFactory()
