import Consumer from './Consumer'
import Driver from './Driver'
import Fleet from './Fleet'
import LastKnownLocation from './LastKnownLocation'
import PaymentData from './PaymentData'
import PartyDescriptor from './PartyDescriptor'
import { DeliveryStatusesType } from 'common/constants/DeliveryStatuses'
import AllocationBooking from './AllocationBooking'
import ETATime from './ETATime'
import { DELIVERY_TYPES } from 'data/collections/deliveriesCollection'

export default class KanbanDeliveryResponse {

  public isInbound: boolean
  public isOutbound: boolean

  constructor(
    public allocation: {booking: AllocationBooking},
    public consumer: Consumer = null,
    public driver: Driver = null,
    public etaDropoff: ETATime = null,
    public etaPickup: ETATime = null,
    public fleet: Fleet = null,
    public id: string = null,
    public lastKnownLocation: LastKnownLocation = null,
    public localPickupTime: Date = null,
    public payment: PaymentData = null,
    public pickupTime: Date = null,
    public recipient: PartyDescriptor = null,
    public ref: string = '',
    public sender: PartyDescriptor = null,
    public status: DeliveryStatusesType = null,
    public statusUpdatedAt: Date = null,
    public type: string
  ) {
    this.isInbound = !!ref.match(/^.*-IN$/)
    this.isOutbound = !!ref.match(/^.*-OUT$/)
   }

  get isNextDay (): boolean {
    return this.type === DELIVERY_TYPES.NEXT_DAY
  }

  get isRelatedToNextDay (): boolean {
    return this.isNextDay || this.isInbound || this.isOutbound
  }

  get pos (): string {
    return this.payment.posReference
  }

  set pos (posValue: string) {
    this.payment.posReference = posValue
  }

  public static build(dto: any = {}): KanbanDeliveryResponse {
    return new KanbanDeliveryResponse(
      dto.allocation ? { booking: AllocationBooking.build(dto.allocation.booking) } : null,
      dto.consumer ? Consumer.build(dto.consumer) : null,
      dto.driver ? Driver.build(dto.driver) : null,
      dto.etaDropoff ? ETATime.build(dto.etaDropoff) : null,
      dto.etaPickup ? ETATime.build(dto.etaPickup) : null,
      dto.fleet ? Fleet.build(dto.fleet) : null,
      dto.id || '',
      dto.lastKnownLocation ? LastKnownLocation.build(dto.lastKnownLocation) : null,
      dto.localPickupTime ? new Date(dto.localPickupTime) : null,
      dto.payment ? PaymentData.build(dto.payment) : null,
      dto.pickupTime ? new Date(dto.pickupTime) : null,
      dto.recipient ? PartyDescriptor.build(dto.recipient) : null,
      dto.ref || '',
      dto.sender ? PartyDescriptor.build(dto.sender) : null,
      dto.status || null,
      dto.statusUpdatedAt ? new Date(dto.statusUpdatedAt) : null,
      dto.type || ''
    )
  }
}
