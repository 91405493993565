import ViewEditFormController from './ViewEditFormController'
import {PERMISSIONS} from 'data/models/permissionsModel'
import {StateParams, StateService} from '@uirouter/core'

export default class CreateViewEditFormController extends ViewEditFormController {

  public entityId: string
  public permissions: string[] = []
  public isCreate: boolean = false

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams,
    public entityIdParamName: string
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.entityId = this.$stateParams.id || this.$stateParams[this.entityIdParamName]
    super.$onInit()
    this.isCreate = !this.entityId
    if (this.isCreate) {
      this.isEditing = true
    } else {
      this.sync()
    }
  }

  canEdit (): boolean {
    return this.hasPermission(PERMISSIONS.EDIT)
  }

  hasPermission (permission: string): boolean {
    return this.permissions.indexOf(permission) > -1
  }

  onSync (): Promise<any> {
    return this.getEntity()
  }

  getEntity (): Promise<any> {
    return Promise.resolve({})
  }

  getDefaultModel (): any {
    return this.backendModel || {}
  }

  goToEntityList (): void {
    this.$state.go('^')
  }

  cancel (): void {
    if (this.isCreate) {
      this.goToEntityList()
    } else {
      this.cancelEdit()
    }
  }

  afterSubmitSuccess (backendModel: any): Promise<any> {
    this.isCreate = false
    return super.afterSubmitSuccess(backendModel)
  }
}
