class CostBuilder {

  public form: ng.IFormController
  public name: string
  public paymentMethod: string
  public onPaymentMethodChange: (payload: {paymentMethod: string}) => void
  public paymentMethods: string[]
  public isRequired: boolean = true
  public isDisabled: boolean = false
  public labelsPrefix: string = 'COMMON.PAYMENT_METHODS'

  onChange(value: string): void {
    this.onPaymentMethodChange({paymentMethod: value})
  }
}

export default {
  templateUrl: require('./cost-builder.pug'),
  controller: CostBuilder,
  bindings: {
    form: '<',
    name: '@',
    paymentMethod: '<',
    onPaymentMethodChange: '&',
    paymentMethods: '<',
    isRequired: '<',
    isDisabled: '<',
    labelsPrefix: '@?'
  }
}
