import templateUrl from './autocomplete-input.pug'
import clamp from 'lodash-es/clamp'
import debounce from 'lodash-es/debounce'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import BaseController from 'presentation/common/BaseController'

const DEFAULT_MAX_SUGGESTION_COUNT = 3
const MIN_CHARACTER_COUNT = 3
const SUGGESTIONS_REQUEST_DELAY_MS = 600
const TAB_KEYCODE = 9
const ENTER_KEY_CODE = 13
const ESCAPE_KEY_CODE = 27
const ARROW_UP_KEY_CODE = 38
const ARROW_DOWN_KEY_CODE = 40

const SUGGESTIONS_LIST_CLASS = '.c-autocomplete-input_suggestions'
const NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_SCROLLING = 4
const SUGGESTION_HEIGHT = 50

/*
 * AUTOCOMPLETE INPUT
 *
 * This component requests suggestions from its parent and these may be async. We debounce these requests.
 * Pending requests for suggestions are cancelled when suggestions are no longer needed. There are three scenarios:
 * 1) When the user clears the input OR falls below the minimum char count
 * 2) When the user selects a suggestion without waiting for others to come back
 * 3) When the user changes focus away from the field
 * See below for details.
 */
class AutocompleteInputController extends BaseController {
  constructor ($scope, $document, $filter) {
    super($scope)
    this.$filter = $filter
    this.$document = $document
    this.searchText = '' // Search text is only used to choose a suggestion object
    this.searchTextChangedBySuggestion = false // Latch used to detect suggestion being picked
    this.localSuggestionModel = null
    this.localSuggestions = []
    this.defaultMaxSuggestionCount = DEFAULT_MAX_SUGGESTION_COUNT

    this.breakChromeAutofill = `${Date.now()}-chrome-please-no`
    this.focusedSuggestionIndex = null
    this.isLoadingSuggestions = false
    this.isFocused = false
    this.$scope.$watch('$ctrl.searchText', (value, previous) => this.onSearchTextChange(value, previous))
  }

  $onInit () {
    this.buildPlaceholders()
    const delay = (typeof this.suggestionDelay === 'number') ? this.suggestionDelay : SUGGESTIONS_REQUEST_DELAY_MS
    this.minCharsCount = (typeof this.minCharsLimit === 'number') ? this.minCharsLimit : MIN_CHARACTER_COUNT
    this.debouncedOnSuggestionsRequired = debounce((...args) => this.notifySuggestionsRequired(...args), delay)
    if (!this.suggestionsCount) {
      this.suggestionsCount = NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_SCROLLING
    }
  }

  buildPlaceholders () {
    if (!this.isRequired && this.placeholder && !this.hideOptional) {
      this.placeholder += ' ' + this.$filter('translate')('COMMON.OPTIONAL_SUFFIX')
    }

    if (!this.isRequired && !this.placeholder && !this.hideOptional) {
      this.placeholder += this.$filter('translate')('COMMON.OPTIONAL')
    }

    if (!this.isRequired && !this.hideOptional) {
      this.label += ' ' + this.$filter('translate')('COMMON.OPTIONAL_SUFFIX')
    }
  }

  // NB. These are generic onChanges and doCheck implementations
  $onChanges (changeDescriptorsByKey) {
    const newModelValue = getValueFromChangeDescriptors(changeDescriptorsByKey, 'model', null)
    if (newModelValue !== this.localSuggestionModel) {
      this.onModelChange(newModelValue)
    }
  }

  $doCheck () { // NB. Object reference bindings (like suggestions) don't fire $onChanges
    // NB. We compare suggestions identity not equality, so we know when empty results have come back.
    if (this.suggestions && (this.suggestions !== this.localSuggestions)) {
      this.onSuggestionsChange(this.suggestions)
    }
  }

  onModelChange (newModelValue) {
    this.localSuggestionModel = newModelValue
    this.searchText = this.localSuggestionModel ? this.localSuggestionModel.description : this.searchText
    this.searchTextChangedBySuggestion = true
  }

  onSearchTextChange (newSearchText) {
    // We exempt when the text is changed by choosing a suggestion, and require a min character count
    if (!this.searchTextChangedBySuggestion && newSearchText.length >= this.minCharsCount) {
      this.debouncedOnSuggestionsRequired({value: newSearchText})
    } else if (!this.searchTextChangedBySuggestion && newSearchText.length < this.minCharsCount) {
      this.onChange({suggestion: null})
      this.cancelSuggestionsRequired() // (1) Cancel when cleared or too few chars
      this.onSuggestionsClearRequired()
    }
    this.searchTextChangedBySuggestion = false
  }

  onSuggestionsChange (suggestions) {
    this.localSuggestions = suggestions
    this.showSuggestions = !!suggestions.length && this.isFocused // Always show new suggestions if we have them
    this.isLoadingSuggestions = false
  }

  onSuggestionAccepted (suggestion) {
    this.onChange({suggestion: suggestion})
    this.cancelSuggestionsRequired() // (2) Cancel when suggestion chosen
  }

  notifySuggestionsRequired (...args) {
    this.isLoadingSuggestions = true
    this.onSuggestionsRequired(...args)
    this.$scope.$digest() // Is called outside of digest cycle
  }

  cancelSuggestionsRequired () {
    this.isLoadingSuggestions = false
    this.debouncedOnSuggestionsRequired.cancel()
  }

  onFocus () {
    this.isFocused = true
    this.showSuggestions = true

    this.onInputFocus()
  }

  onBlur () {
    this.isFocused = false
    this.showSuggestions = false
    this.focusedSuggestionIndex = null
    this.cancelSuggestionsRequired() // (3) Cancel when user changes focus

    this.setValidity()
  }

  onClear () {
    this.searchText = ''
    this.setValidity()
    this.onChange({suggestion: {description: null}})
  }

  setValidity () {
    const isItNotInTheList = !(this.searchText && !this.localSuggestions.length)
    if (this.form) {
      this.form[this.name].$setValidity('backendInvalid', isItNotInTheList)
    }
  }

  onKeyDown ($event) {
    const keyCode = $event.keyCode

    let newFocusIndex
    if (keyCode === ARROW_UP_KEY_CODE) {
      newFocusIndex = this.getIncrementedFocusIndex(-1)
    } else if (keyCode === ARROW_DOWN_KEY_CODE) {
      newFocusIndex = this.getIncrementedFocusIndex(+1)
    } else if (keyCode === ENTER_KEY_CODE) {
      this.conditionallyAcceptCurrentSuggestion()
      $event.preventDefault()
      newFocusIndex = null
    } else if (keyCode === TAB_KEYCODE) {
      this.conditionallyAcceptCurrentSuggestion()
      // newFocusIndex = null
    } else if (keyCode === ESCAPE_KEY_CODE) {
      newFocusIndex = null
    } else {
      this.searchTextChangedBySuggestion = false
    }

    const focusIndexChanged = typeof newFocusIndex !== 'undefined' && newFocusIndex !== this.focusedSuggestionIndex
    if (focusIndexChanged) {
      this.focusedSuggestionIndex = newFocusIndex
      this.showSuggestions = newFocusIndex !== null
      $event.preventDefault() // Only prevent the event if we actually did a change in response
    }

    const suggestionsList = this.$document[0].querySelector(SUGGESTIONS_LIST_CLASS)

    if (newFocusIndex > (this.suggestionsCount - 1) || keyCode === ARROW_UP_KEY_CODE) {
      if (suggestionsList) {
        suggestionsList.scrollTop = newFocusIndex * SUGGESTION_HEIGHT
      }
    }
  }

  getIncrementedFocusIndex (increment) {
    if (this.focusedSuggestionIndex === null && increment > 0) {
      return 0 // Select first suggestion
    } else if (this.focusedSuggestionIndex === null && increment <= 0) {
      return null // Keep none selected
    } else if (this.focusedSuggestionIndex !== null) {
      const incrementedIndex = this.focusedSuggestionIndex + increment
      if (incrementedIndex === -1) {
        return null // Select none
      } else {
        return clamp(incrementedIndex, 0, this.getMaxSuggestionIndex()) // Select within available range
      }
    }
  }

  conditionallyAcceptCurrentSuggestion () {
    const suggestionForIndex = this.localSuggestions[this.focusedSuggestionIndex]
    if (suggestionForIndex) {
      this.onSuggestionAccepted(suggestionForIndex)
    } else if (this.localSuggestions.length === 1) {
      this.onSuggestionAccepted(this.localSuggestions[0])
      this.showSuggestions = false
    }
  }

  focusSuggestionIndex (rawIndex) {
    const maxIndex = this.getMaxSuggestionIndex()
    this.focusedSuggestionIndex = clamp(rawIndex, 0, maxIndex)
  }

  getMaxSuggestionIndex () {
    const maxSuggestionCount = this.maxSuggestionCount || this.defaultMaxSuggestionCount
    const shownSuggestionCount = Math.min(this.localSuggestions.length, maxSuggestionCount)
    return shownSuggestionCount ? shownSuggestionCount - 1 : 0 // Indices are zero-offset
  }
}

export default {
  templateUrl,
  controller: AutocompleteInputController,
  bindings: {
    name: '@',
    form: '<?',
    model: '<', // Ensure is a reference to a primitive, not object
    onChange: '&',
    onInputFocus: '&',
    isRequired: '<?',
    isDisabled: '<?',
    label: '@?',
    placeholder: '@?',
    hideOptional: '<?',
    backendValidationModel: '<?',
    suggestions: '<',
    onSuggestionsRequired: '&',
    onSuggestionsClearRequired: '&',
    maxSuggestionCount: '<?',
    suggestionDelay: '<?',
    minCharsLimit: '<?',
    showSuggestionsOnFocus: '<?',
    suggestionsCount: '<?'
  }
}
