import templateUrl from './filters-button.pug'

class FiltersButton {
  constructor ($scope, $filter) {
    this.$scope = $scope
    this.$filter = $filter
  }

  $onInit () {
    if (!this.title) {
      this.title = this.$filter('translate')('COMMON.FILTERS')
    }
  }

  onResetHandler ($event) {
    $event.stopPropagation()
    this.onReset()
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$filter', FiltersButton],
  bindings: {
    title: '@?',
    activeFiltersCounter: '<?',
    onFilter: '&?',
    onReset: '&?'
  }
}
