import { StateService } from '@uirouter/core'
import envConfig from 'common/envConfig'
import BaseController from 'presentation/common/BaseController'
import localStorageService from 'data/services/localStorageService'
import storefrontOrdersCollection, { StorefrontOrdersSorting } from 'data/collections/storefrontOrdersCollection'
import businessesCollection from 'data/collections/businessesCollection'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import { ORDER_POPUP_SHOW_ACTION } from 'presentation/storefront/order-popup/orderPopup'
import { STOREFRONT_ORDER_STATUSES, StorefrontOrdersStatusesType } from 'common/constants/StorefrontOrderStatuses'
import { STOREFRONT_ORDER_STATUS_UPDATED } from 'common/constants/StorefrontOrderEvents'
import { compareDates } from 'data/common/comparator'
import { StorefrontDeliveryCreationStatusesType } from 'common/constants/StorefrontDeliveryCreationStatuses'
import { ShippingMethods } from 'common/constants/ShippingMethods'
import branchesCollection from 'data/collections/branchesCollection'
import StorefrontOrderDeliveryInformation from 'data/domain-objects/storefront/StorefrontOrderDeliveryInformation'
import StorefrontOrder from 'data/domain-objects/storefront/StorefrontOrder'
import StorefrontOrderBranch from 'data/domain-objects/storefront/StorefrontOrderBranch'
import StorefrontOrderContactDetails from 'data/domain-objects/storefront/StorefrontOrderContactDetails'
import StorefrontBusinessDetails from 'data/domain-objects/storefront/StorefrontBusinessDetails'

const POLLING_INTERVAL = 10000
const MIN_SEARCH_QUERY_LENGTH = 2
const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'
const STOREFRONT_ORDERS_POLLING_TIMEOUT_ID_STORAGE_KEY = 'storefrontOrdersPollingTimeoutID'
const ACTIVE_ORDERS_SINGER_STORAGE_KEY = 'activeOrdersSinger'
const ORDER_STATUSES_BASE_TRANSLATION_KEY = 'COMMON.ORDER_STATUSES'
const UNPROCESSED_ORDERS_AUDIO_KEY = 'storefrontUnprocessedOrdersAudio'

const AUDIO_FILE_PATH = '/assets/audio/new-order.mp3'

interface IDeliveryInformation {
  deliveryCreationStatuses?: typeof StorefrontDeliveryCreationStatusesType
  method: 'delivery' | 'collection'
  deliveries: StorefrontOrderDeliveryInformation[]
}

interface IStorefrontOrderRow {
  created: Date,
  when: any,
  type: StorefrontOrder,
  pos: string,
  branch: StorefrontOrderBranch[],
  customer: StorefrontOrderContactDetails,
  amount: StorefrontOrder,
  status: StorefrontOrdersStatusesType,
  delivery: IDeliveryInformation
  action: IActionsColumn,
}

interface IActionsColumn {
  viewOrder: () => void,
  acceptOrder: () => void,
  orderStatus: StorefrontOrdersStatusesType,
  showAcceptButton: boolean,
  autoPrint: boolean,
  orderId: string
}

enum StorefrontOrderGroups {
  NEW_ASAP = 'NEW_ASAP',
  NEW_SCHEDULED = 'NEW_SCHEDULED',
  ACCEPTED = 'ACCEPTED',
  READY_FOR_COLLECTION = 'READY_FOR_COLLECTION',
  COLLECTED = 'COLLECTED'
}

class OrdersController extends BaseController {

  public pollingTimeout: number = parseInt(localStorageService.getValue(STOREFRONT_ORDERS_POLLING_TIMEOUT_ID_STORAGE_KEY) || '0')
  public formatters: any = {}

  public searchQuery: string = null
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public branchId: string = null

  public branchNamesById: any = {}
  public orderPreparationTime: number
  public deliveryTime: number

  public orders: StorefrontOrder[] = []

  public rows: any = {
    [StorefrontOrderGroups.NEW_ASAP]: [] as IStorefrontOrderRow[],
    [StorefrontOrderGroups.NEW_SCHEDULED]: [] as IStorefrontOrderRow[],
    [StorefrontOrderGroups.ACCEPTED]: [] as IStorefrontOrderRow[],
    [StorefrontOrderGroups.READY_FOR_COLLECTION]: [] as IStorefrontOrderRow[],
    [StorefrontOrderGroups.COLLECTED]: [] as IStorefrontOrderRow[]
  }

  public groups: StorefrontOrderGroups[] = Object.keys(this.rows) as StorefrontOrderGroups[]
  public groupTypes: typeof StorefrontOrderGroups = StorefrontOrderGroups

  public deliveryCreationStatuses: typeof StorefrontDeliveryCreationStatusesType = StorefrontDeliveryCreationStatusesType

  public showError: boolean

  public businessSettings: any
  public branchSettings: any
  public currentOrder: StorefrontOrder
  public currentOrderId: string
  public businessDetails: StorefrontBusinessDetails
  public receiptTemplate: any
  public showLogoInReceipt: boolean
  public autoPrint: boolean
  public showAcceptButton: boolean
  public isAcceptingOrder: boolean = false
  public audioBtnWasClicked: boolean = false

  private singerId: string = null
  private audio: HTMLAudioElement = window[UNPROCESSED_ORDERS_AUDIO_KEY] ? window[UNPROCESSED_ORDERS_AUDIO_KEY] : new Audio(AUDIO_FILE_PATH)

  private requestArchievedOrders: boolean = false

  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
    public $filter: ng.IFilterService
  ) {
    super($scope, $state)

    if (!window[UNPROCESSED_ORDERS_AUDIO_KEY]) {
      this.audio.loop = true
      this.audio.load()
    }
  }

  $onInit(): void {
    this.getBranches().then((): void => {
      if (this.businessId) {
        this.restartPolling()
      }
    })

    this.formatters = this.getFormatters()
    this.singerId = Date.now().toString()
    this.becomeSinger()

    document.addEventListener('visibilitychange', (): void => {
      if (!document.hidden || envConfig.alwaysRingOnNewOrder) {
        this.becomeSinger()
      }
    })

    this.$scope.$on(STOREFRONT_ORDER_STATUS_UPDATED, () => this.restartPolling())
  }

  get displayAudioButton(): boolean {
    return this.isIOS() && !this.audioBtnWasClicked && !!this.rows[StorefrontOrderGroups.NEW_ASAP].length && this.audio.paused
  }

  isIOS(): boolean {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }

  becomeSinger(): void {
    window.localStorage.setItem(ACTIVE_ORDERS_SINGER_STORAGE_KEY, this.singerId)
  }

  isSinger(): boolean {
    return window.localStorage.getItem(ACTIVE_ORDERS_SINGER_STORAGE_KEY) === this.singerId
  }

  stopBeingSinger(): void {
    if (this.isSinger()) {
      window.localStorage.removeItem(ACTIVE_ORDERS_SINGER_STORAGE_KEY)
    }
  }

  conditionallyMakeSomeNoize(): void {
    const hasUnprocessedOrders = !!this.rows[StorefrontOrderGroups.NEW_ASAP].length

    if (this.isLoaded && this.isSinger()) {

      if (hasUnprocessedOrders && this.audio.paused) {
        this.audio.play()
        window[UNPROCESSED_ORDERS_AUDIO_KEY] = this.audio
      } else if (!hasUnprocessedOrders && !this.audio.paused) {
        this.audio.pause()
      }
    }
  }

  playAudio(): void {
    this.audio.play()
    this.audioBtnWasClicked = true
  }

  acceptOrder(orderId: string): void {
    this.isAcceptingOrder = true
    this.currentOrderId = orderId

    storefrontOrdersCollection.acceptOrder(this.businessId, orderId)
      .then(() => this.getOrders())
      .then(() => {
        if (this.autoPrint) {
          return Promise.all([
            storefrontOrdersCollection.getById(this.businessId, orderId),
            businessesCollection.getStorefrontDetails(this.businessId),
          ])
            .then(([order, businessDetails]: [StorefrontOrder, StorefrontBusinessDetails]) => {
              this.currentOrder = order
              this.businessDetails = businessDetails
            })
            .then(() => {
              this.$scope.$digest()
            })
            .then(() => {
              window.setTimeout(() => {
                window.print()
                this.currentOrder = null
                this.currentOrderId = null
                this.$scope.$digest()
              }, 500)
            })
        }
      })
      .then(() => {
        this.isAcceptingOrder = false
        this.$scope.$digest()
      })
  }

  getBranches(): Promise<void> {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then((response: any[]): void => {
        this.branchNamesById = response.reduce((accum: object, business: any): object => {
          business.branches.forEach((branch: DBMappedNamedEntity): void => {
            accum[branch.id] = branch.name
          })

          return accum
        }, {})
      })
      .catch((error: Error): void => this.logger.error('Cannot get branches data', error))
  }

  onSync(): Promise<any> {
    return this.getOrders()
      .then(() => {
        this.pollingTimeout = window.setTimeout(() => this.sync(), POLLING_INTERVAL)
        localStorageService.setValue(STOREFRONT_ORDERS_POLLING_TIMEOUT_ID_STORAGE_KEY, this.pollingTimeout)
      })
  }

  showArchievedOrders(): void {
    this.requestArchievedOrders = true
    this.restartPolling()
  }

  getOrders(): Promise<void> {
    const statuses = [
      STOREFRONT_ORDER_STATUSES.SUBMITTED,
      STOREFRONT_ORDER_STATUSES.ACCEPTED,
      STOREFRONT_ORDER_STATUSES.READY_FOR_COLLECTION,
      STOREFRONT_ORDER_STATUSES.COLLECTED
    ]

    const COUNT_OF_DAYS_IN_A_WEEK = 7
    const startFrom = new Date()

    this.showError = false
    startFrom.setDate(startFrom.getDate() - COUNT_OF_DAYS_IN_A_WEEK)


    return Promise.all([
      this.getSettingsAndApply(),
      storefrontOrdersCollection.getOrders(
        this.businessId,
        this.branchId,
        this.searchQuery,
        statuses,
        1000,
        0,
        [StorefrontOrdersSorting.desiredTime, StorefrontOrdersSorting.createdReversed],
        !this.requestArchievedOrders && startFrom
      )
    ])
      .then(([settingsResult, ordersResponse]: [undefined, any]) => {
        // time values are stored in minutes, we need to convert them into miliseconds
        this.orders = ordersResponse.records
        this.generateRows()
        this.conditionallyMakeSomeNoize()
        this.$scope.$digest()
      })
      .catch((error: Error): void => {
        this.logger.error('An error occured while getting Storefront orders', error)
        this.isLoaded = true
        this.showError = true
        this.isLoading = false
      })
      .then(() => {
        this.$scope.$digest()
      })
  }

  getSettingsAndApply(): Promise<void> {
    const RECEIPT_TEMPLATE_SETTING_KEY = 'receipts__template'
    const RECEPT_SHOW_LOGO_SETTING_KEY = 'receipts__printRestaurantLogo'
    const ORDER_PREPARATION_TIME_KEY = 'orderPreparationTime'
    const AUTO_PRINT_RECEIPT_SETTING_KEY = 'receipts__autoPrint'
    const SHOW_ACCEPT_BUTTONS_SETTINGS_KEY = 'receipts__displayAcceptAndPrint'
    const DELIVERY_TYME_KEY = 'storefront__deliveryTime'


    const getSettingsPromises = [businessesCollection.getStorefrontSettings(this.businessId)]
    if (this.branchId) {
      getSettingsPromises.push(branchesCollection.getStorefrontSettings(this.businessId, this.branchId))
    }

    return Promise.all(getSettingsPromises)
      .then((settingsResponse: any[]) => {
        this.businessSettings = settingsResponse[0]
        if (settingsResponse[1]) {
          this.branchSettings = settingsResponse[1]
        }
        const specificSettings = this.branchSettings || this.businessSettings
        this.orderPreparationTime = specificSettings[ORDER_PREPARATION_TIME_KEY].value * 60 * 1000
        this.deliveryTime = specificSettings[DELIVERY_TYME_KEY].value * 60 * 1000

        this.autoPrint = this.businessSettings[AUTO_PRINT_RECEIPT_SETTING_KEY].value
        this.receiptTemplate = this.businessSettings[RECEIPT_TEMPLATE_SETTING_KEY].value
        this.showLogoInReceipt = this.businessSettings[RECEPT_SHOW_LOGO_SETTING_KEY].value
        this.showAcceptButton = this.businessSettings[SHOW_ACCEPT_BUTTONS_SETTINGS_KEY].value
      })
      .then(() => {
        this.$scope.$digest()
      })
  }

  generateRows(): void {
    const asapOrdersCondition = (order: StorefrontOrder): boolean => {
      const now = new Date().getTime()

      return order.isASAP ||
        (!order.isASAP && (
          (order.shipping.method === ShippingMethods.collection && now >= order.shipping.desiredTime.getTime() - this.orderPreparationTime) ||
          (order.shipping.method === ShippingMethods.delivery && now >= order.shipping.desiredTime.getTime() - this.orderPreparationTime - this.deliveryTime)
        ))
    }

    const collectedOrders = this.orders.filter((order: StorefrontOrder): boolean => order.status === StorefrontOrdersStatusesType.collected)
    const acceptedOrders = this.orders.filter((order: StorefrontOrder): boolean => order.status === StorefrontOrdersStatusesType.accepted)
    const readyForCollectionOrders = this.orders.filter((order: StorefrontOrder): boolean => order.status === StorefrontOrdersStatusesType.readyForCollection)
    const submittedOrders = this.orders.filter((order: StorefrontOrder): boolean => order.status === StorefrontOrdersStatusesType.submitted)

    const asapOrders = submittedOrders.filter((order: StorefrontOrder): boolean => asapOrdersCondition(order))
    this.sortAsapOrders(asapOrders)

    const scheduledOrders = submittedOrders.filter((order: StorefrontOrder): boolean => !asapOrdersCondition(order));

    [collectedOrders, acceptedOrders, readyForCollectionOrders].forEach((orders: StorefrontOrder[]) => {
      this.sortOrdersByDate(orders)
    })

    this.rows[StorefrontOrderGroups.NEW_ASAP] = this.getRowsFromOrders(asapOrders)
    this.rows[StorefrontOrderGroups.NEW_SCHEDULED] = this.getRowsFromOrders(scheduledOrders)
    this.rows[StorefrontOrderGroups.ACCEPTED] = this.getRowsFromOrders(acceptedOrders)
    this.rows[StorefrontOrderGroups.READY_FOR_COLLECTION] = this.getRowsFromOrders(readyForCollectionOrders)
    this.rows[StorefrontOrderGroups.COLLECTED] = this.getRowsFromOrders(collectedOrders)
  }

  makeRowFromOrder(order: StorefrontOrder): Partial<IStorefrontOrderRow> {
    const result: Partial<IStorefrontOrderRow> = {
      created: order.createdAt,
      when: order,
      type: order,
      pos: order.posReference,
      branch: order.branches,
      customer: order.contactDetails,
      amount: order
    }
    if (order.status !== STOREFRONT_ORDER_STATUSES.SUBMITTED) {
      result.delivery = {
        deliveries: order.shipping.deliveries,
        method: order.shipping.method,
        deliveryCreationStatuses: this.deliveryCreationStatuses
      }
    }
    result.action = {
      viewOrder: (): void => this.viewOrder(order.id),
      acceptOrder: (): void => this.acceptOrder(order.id),
      orderStatus: order.status,
      showAcceptButton: this.showAcceptButton,
      autoPrint: this.autoPrint,
      orderId: order.id
    }

    return result
  }

  sortOrdersByDate(orders: StorefrontOrder[]): void {
    orders.sort((b: StorefrontOrder, a: StorefrontOrder): number => {
      return compareDates(a.createdAt, b.createdAt)
    }) // pay attention — DESC sorting (from newest to oldest)
  }

  sortAsapOrders(asapOrders: StorefrontOrder[]): void {
    // asap orders first
    asapOrders.sort((b: StorefrontOrder, a: StorefrontOrder): number => b.isASAP === a.isASAP ? 0 : (b.isASAP ? -1 : 1))
  }

  getRowsFromOrders(orders: StorefrontOrder[]): IStorefrontOrderRow[] {
    return orders.map((order: StorefrontOrder): any => this.makeRowFromOrder(order))
  }

  getFormatters(): { [key in keyof Partial<IStorefrontOrderRow>]: (value: any) => string } {
    return {
      created: (value: Date): string => this.$filter<ISpecificTimeFilter>('specificTimeTo')(value.toISOString(), true, true),
      when: (value: StorefrontOrder): string => '',
      type: (value: StorefrontOrder): string => '',
      pos: (value: string): string => {
        return value
      },
      branch: (value: StorefrontOrderBranch[]): string => value
        .map((branch: StorefrontOrderBranch): string => this.branchNamesById[branch.id])
        .join(', '),
      customer: (value: StorefrontOrderContactDetails): string => '',
      amount: (value: StorefrontOrder): string => '',
      status: (value: StorefrontOrdersStatusesType): string => this.$filter<ITranslateFilter>('translate')(`${ORDER_STATUSES_BASE_TRANSLATION_KEY}.${value}`),
      delivery: (value: StorefrontOrderDeliveryInformation[]): string => '',
      action: (value: any): string => null
    }
  }

  getFromattedOrderAmount(order: StorefrontOrder): string {
    return
  }

  stopPolling(): void {
    if (window[UNPROCESSED_ORDERS_AUDIO_KEY]) {
      window[UNPROCESSED_ORDERS_AUDIO_KEY].pause()
    }

    clearTimeout(this.pollingTimeout)
    localStorageService.setValue(STOREFRONT_ORDERS_POLLING_TIMEOUT_ID_STORAGE_KEY, 0)
  }

  restartPolling(): void {
    this.stopPolling()
    this.sync()
  }

  onBusinessSelected(business: any): void {
    this.businessId = business.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)
    this.branchId = null
    if (this.businessId) {
      this.restartPolling()
    } else {
      this.audio.pause()
      this.orders = []
    }
  }

  onBranchSelected(branch: any): void {
    this.branchId = branch.branchId

    if (this.businessId) {
      this.restartPolling()
    } else {
      this.audio.pause()
      this.orders = []
    }
  }

  onSearchQueryChanged(searchQuery: string): void {
    if (searchQuery.length >= MIN_SEARCH_QUERY_LENGTH || !searchQuery) {
      this.searchQuery = searchQuery
      this.restartPolling()
    }
  }

  viewOrder(orderId: string): void {
    this.$scope.$emit(ORDER_POPUP_SHOW_ACTION, {
      businessId: this.businessId,
      orderId
    })
  }
}

export default {
  templateUrl: require('./orders.pug'),
  controller: OrdersController
}
