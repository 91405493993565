import {ProductPropertyValue} from 'data/domain-objects/Product'
import * as PRODUCT_EVENTS from 'common/constants/ProductEvents'
import BasePopupController from 'presentation/common/BasePopupController'

export const PRODUCT_PROPERTY_VALUE_DIALOG_ID = 'product-property-value-dialog'

class ProductOptionDialogController extends BasePopupController {
  public propertyValue: ProductPropertyValue
  public onSubmit: (payload: {propertyValue: ProductPropertyValue}) => void

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IScope
  ) {
    super($scope, $rootScope, PRODUCT_EVENTS.ADD_PRODUCT_PROPERTY_VALUE_EVENT, PRODUCT_PROPERTY_VALUE_DIALOG_ID)

    this.setupPopupOpenEventListener((payload: any): void => {
      this.resetModel()

      if (payload && payload.model) {
        this.propertyValue = ProductPropertyValue.build(payload.model)
      }
    })
  }

  resetModel (): void {
    this.propertyValue = ProductPropertyValue.build({})
  }

  submit (): void {
    this.onSubmit({propertyValue: this.propertyValue})
    this.close()
  }

  cancel (): void {
    this.close()
  }
}

export default {
  templateUrl: require('./product-value-dialog.pug'),
  controller: ProductOptionDialogController,
  bindings: {
    onClose: '&?',
    onSubmit: '&'
  }
}
