import templateUrl from './linked-deliveries-area.pug'

class LinkedDeliveriesArea {
  onCheckboxClick (value) {
    if (value) {
      this.onCheckAll()
    } else {
      this.onUncheckAll()
    }
  }
}

export default {
  templateUrl,
  controller: LinkedDeliveriesArea,
  transclude: true,
  bindings: {
    areaName: '@',
    checkState: '<',
    onCheckAll: '&',
    onUncheckAll: '&'
  }
}
