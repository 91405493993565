import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import { StorefrontSubscriptionPlans } from 'common/constants/StorefrontSubscriptionPlans'
import { StorefrontSubscriptionStatuses } from 'common/constants/StorefrontSubscriptionStatuses'
import StorefrontBusinessWithSubscription from 'data/domain-objects/storefront/StorefrontBusinessWithSubscription'

const storefrontBusinessesWithSubscriptionsService = new CrudService(endpoints.STOREFRONT_BUSINESSES_WITH_SUBSCRIPTIONS)
const storefrontSubscriptionService = new CrudService(endpoints.STOREFRONT_SUBSCRIPTION)
const storefrontChargeBySubscriptionPlan = new CrudService(endpoints.STOREFRONT_CHARGE_BY_SUBSCRIPTION_PLAN)

export default {
  getSubscriptions: (): Promise<StorefrontBusinessWithSubscription[]> => {
    return storefrontBusinessesWithSubscriptionsService.get()
    .then((response: any[]) => {
      return (response || []).map(StorefrontBusinessWithSubscription.build)
    })
  },
  subscribe: (plan: StorefrontSubscriptionPlans, businessId: string): Promise<StorefrontBusinessWithSubscription> => {
    return storefrontSubscriptionService.create({plan}, [businessId])
    .then(StorefrontBusinessWithSubscription.build)
  },
  cancelCurrentSubscription: (businessId: string): Promise<StorefrontBusinessWithSubscription> => {
    return storefrontSubscriptionService.delete([businessId])
    .then(StorefrontBusinessWithSubscription.build)
  },
  chargeMeNow: (businessId: string): Promise<StorefrontBusinessWithSubscription> => {
    return storefrontChargeBySubscriptionPlan.create(null, [businessId])
    .then(StorefrontBusinessWithSubscription.build)
  },
  getIsStorefrontBusinessSubscriptionPurchased: (businessId: string): any => {
    return storefrontBusinessesWithSubscriptionsService.get()
    .then((response: any[]) => {
      return !!(response || []).filter((business: any) => {
        return business.businessId === businessId &&
               business.subscription.plan !== null &&
               business.status !== StorefrontSubscriptionStatuses.DISABLED
      }).length
    })
  }
}
