import DateUtils from 'common/utils/dateUtils'
import Address from '../Address'

export default class StorefrontOrderBranch {
  constructor(
    public id: string,
    public name: string,
    public businessName: string,
    public address: Address,
    public deliveryEstimatedPickupTime: Date
  ) { }

  static build(dto: any = {}): StorefrontOrderBranch {
    return new StorefrontOrderBranch(
      dto.id || dto,
      dto.name,
      dto.businessName,
      Address.build(dto.address),
      dto.deliveryEstimatedPickupTime ? DateUtils.makeSafeDateFromString(dto.deliveryEstimatedPickupTime) : null
    )
  }

  getFullName(): string {
    return `${this.businessName} - ${this.name}`
  }
}
