export default [
  {
    iconClass: 'dicon-key',
    label: 'COMMON.DRIVER.ACCOUNT'
  },
  {
    iconClass: 'dicon-id-card',
    label: 'COMMON.DRIVER.LICENSE'
  },
  {
    iconClass: 'dicon-car',
    label: 'COMMON.DRIVER.VEHICLE'
  },
  {
    iconClass: 'dicon-cogs',
    label: 'COMMON.DRIVER.FLEETS'
  },
  {
    iconClass: 'dicon-checkmark',
    label: 'COMMON.DRIVER.ACTIVE'
  }
]
