import BaseController from 'presentation/common/BaseController'
import * as PaymentProviders from 'common/constants/BalanceTopUpPaymentMethods'
import featuresModel from 'data/models/featuresModel'
import paymentsCollection from 'data/collections/paymentsCollection'
import { StateService } from '@uirouter/core'

interface IPaymentOption {
  value: string,
  label: string
}

interface IOnPaymentMethodChange {
  paymentInfo: {
    stripeCardId: string,
    selectedPaymentMethod: string
  }
}

class PaymentMethodsPickerController extends BaseController {

  /* Bindings START */
  public placeholder: string
  public onPaymentMethodChange: (result: IOnPaymentMethodChange) => void
  /* Bindings END */

  public KNET_PAYMENT_METHOD_KEY: string = 'KNET'
  public selectedPaymentMethod: string = null
  public stripeEnabled: boolean = false
  public stripePaymentCards: any[] = []
  public paymentMethodsDefinition: IPaymentOption[] = []
  private stripeCardId: string = null

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    private $filter: ng.IFilterService
  ) {
    super($scope, $state)
    this.stripeEnabled = featuresModel.isFeatureEnabled('STRIPE_PAYMENTS')
  }

  $onInit (): void {
    Promise.all([
      this.getAvailableStripePaymentCards(),
      this.getAvailablePaymentProviders()
    ]).then(() => this.$scope.$digest())

    this.$scope.$watch('$ctrl.selectedPaymentMethod', (value: any) => value && this.onSelectPaymentMethod())
  }

  onSelectPaymentMethod (): void {
    let paymentMethod = this.selectedPaymentMethod
    if (this.stripeEnabled && this.stripePaymentCards.find((card: any) => card.id === this.selectedPaymentMethod)) {
      this.stripeCardId = this.selectedPaymentMethod
      paymentMethod = PaymentProviders.STRIPE
    } else {
      this.stripeCardId = null
    }
    this.onPaymentMethodChange({
      paymentInfo: {
        stripeCardId: this.stripeCardId,
        selectedPaymentMethod: paymentMethod
      }
    })
  }

  getAvailablePaymentProviders (): Promise<any> {
    return paymentsCollection.getAvailablePaymentProviders()
      .then((providers: any[]) => this.buildPaymentMethodsDefinition(providers))
      .catch((err: Error) => this.logger.error('Cannot get payments methods', err))
  }

  getAvailableStripePaymentCards (): Promise<any> {
    if (this.stripeEnabled) {
      return paymentsCollection.getPaymentCards()
        .then((cards: any[]) => this.stripePaymentCards = cards.sort((card: any) => card.isDefault ? -1 : 1))
        .catch((err: Error) => this.logger.error('Cannot get payment cards', err))
    } else {
      return Promise.resolve()
    }
  }

  buildPaymentMethodsDefinition (paymentProviders: any[]): void {
    if (paymentProviders && paymentProviders.length) {
      this.selectedPaymentMethod = paymentProviders[0]

      const reversedPaymentProviders = {}
      Object.keys(PaymentProviders).forEach((key: string) => {
        reversedPaymentProviders[ PaymentProviders[key] ] = key
      })
      paymentProviders.forEach((provider: any) => {
        const providerLabel = reversedPaymentProviders[provider] || provider
        const paymentOption = {
          value: provider,
          label: this.$filter<ITranslateFilter>('translate')(`COMPONENTS.USER_BALANCE_TOP_UP.PAYMENT_METHODS.${providerLabel}`)
        }
        this.paymentMethodsDefinition.push(paymentOption)
      })
    }
  }
}

export default {
  templateUrl: require('./payment-methods-picker.pug'),
  controller: PaymentMethodsPickerController,
  bindings: {
    placeholder: '@',
    onPaymentMethodChange: '&'
  }
}
