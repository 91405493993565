// import templateUrl from
import imagesCollection from 'data/collections/imagesCollection'

interface IImageUploadResponse {
  url: string,
  id: string
}

class GalleryImagePickerController {
  public errorMessage: string = null

  // bindings
  public imageUrl: string
  public isEditing: string
  public isRequired: string // optional
  public buttonText: string
  public helpMessage: string // optional
  public onImageSelect: (payload: {imageUrl: string}) => void

  constructor (
    public $scope: ng.IScope,
    public $element: ng.IAugmentedJQuery = null,
    private $filter: ng.IFilterService
  ) {}

  $onInit (): void {
    this.$scope.$watch('$ctrl.file', (file: any) => this.uploadTheImage(file))
  }

  uploadTheImage (file: any): void {
    if (file) {
      this.errorMessage = null
      imagesCollection.upload(file)
        .then((response: IImageUploadResponse) => this.handleSuccessImageUpload(response))
        .catch((error: Error) => this.handleImageUploadError(error))
        .then(() => this.$scope.$apply())
    }
  }

  handleImageUploadError (error: any): void {
    if (error) {
      if (error.message) {
        this.errorMessage = error.message
      } else if (error.status === 413) {
        this.errorMessage = this.$filter<ITranslateFilter>('translate')('COMMON.ERROR.IMAGE_TOO_LARGE')
      } else {
        this.errorMessage = this.$filter<ITranslateFilter>('translate')('COMMON.ERROR.UNKNOWN')
      }
    } else {
      this.errorMessage =
      this.errorMessage = String(error)
    }
    this.imageUrl = ''
  }

  handleSuccessImageUpload (response: IImageUploadResponse): void {
    this.imageUrl = response.url
    this.onImageSelect({imageUrl: this.imageUrl})
  }

  openFileUploadDialog (): void {
    this.$element.find('input')[0].click()
  }
}

export default {
  templateUrl: require('./image-uploader.pug'),
  controller: GalleryImagePickerController,
  bindings: {
    imageUrl: '<',
    isEditing: '<',
    isRequired: '<', // optional
    buttonText: '@',
    helpMessage: '@', // optional
    onImageSelect: '&'
  }
}
