import templateUrl from './kanban-table.pug'
import envConfig from 'common/envConfig'

const HARD_LAZY_LOAD_LIMIT = envConfig.kanban.maximumNumberOfCardsInTheColumnForLazyLoading

class KanbanTable {
  constructor ($scope, $filter, kanbanDataService) {
    this.$scope = $scope
    this.$filter = $filter
    this.kanbanDataService = kanbanDataService
  }

  /**
   * This will automatically collapse columns with no deliveries.
   * And will expand them, when new deliveries appears.
   *
   * Manually collapsed columns will not be auto expanded.
   */
  toggleAutoCollapse () {
    Object.keys(this.deliveries).forEach(status => {
      const column = this.columnsConfig.find(col => col.status === status)
      if (column) {
        if (this.deliveries[status].length === 0 && !column.collapsed) {
          column.collapsed = true
          column.autoCollapsed = true
        }

        if (this.deliveries[status].length > 0 && column.collapsed && column.autoCollapsed) {
          column.collapsed = false
          delete column.autoCollapsed
        }
      }
    })
  }

  $onInit () {
    this.columnsConfig = this.kanbanDataService.kanbanColumnsList
      .filter(status => this.settings.board.hiddenColumns.indexOf(status) === -1)
      .map(status => {
        return {
          status,
          title: this.$filter('translate')(`PAGES.KANBAN.DELIVERY_COLUMN_TITLES.${status.toUpperCase()}`),
          collapsed: false
        }
      })
  }

  $onChanges (changes) {
    if (changes.hasOwnProperty('deliveries') && changes.deliveries.currentValue) {
      this.toggleAutoCollapse()
    }
  }

  collapseColumn (status) {
    this.columnsConfig.forEach(column => {
      if (column.status === status) {
        column.collapsed = !column.collapsed
      }
    })
  }

  lazyLoadStatus (status) {
    if (status && this.deliveries && this.deliveries[status].length >= this.settings.board.columnSize && this.deliveries[status].length < HARD_LAZY_LOAD_LIMIT) {
      this.lazyLoad({status, skip: this.deliveries[status].length})
    }
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$filter', 'kanbanDataService', KanbanTable],
  bindings: {
    deliveries: '<',
    counters: '<',
    settings: '<',
    lazyLoad: '&'
  }
}
