import templateUrl from './kanban-settings.pug'
import localeHelper from 'common/localeHelper'

const DEFAULT_TAB = 'board'
const CARD_SIZES = [1, 2, 3, 4, 5] // eslint-disable-line

class KanbanSettings {
  constructor ($scope, $filter, kanbanDataService) {
    this.$scope = $scope
    this.$filter = $filter
    this.kanbanDataService = kanbanDataService

    this.cardSizes = CARD_SIZES

    this.tab = DEFAULT_TAB
    this.settings = this.kanbanDataService.settings
    this.demoSettings = this.settings

    this.demoCard = {
      id: 'demo',
      ref: 'D-0008864',
      sender: {
        type: 'branch',
        businessName: 'Big Business',
        branchName: 'Branch 7729',
        area: 'Faiha'
      },
      recipient: {
        type: 'consumer',
        area: 'Faiha'
      },
      consumer: {
        firstName: 'John',
        lastName: 'Galt',
        countryDiallingCode: '965',
        phoneNumber: '1161248970'
      },
      driver: {
        firstName: 'Michael',
        lastName: 'Schumacher',
        countryDiallingCode: '965',
        phoneNumber: '123 456 78'
      },
      payment: {
        method: 'cash',
        cost: {
          currencyCode: 'KWD',
          amount: 1
        },
        posReference: 'POS12'
      },
      fleet: {
        name: 'Fleet of 1725387649'
      },
      status: 'atPickup',
      etaPickup: {time: '2018-07-16T11:46:00.058Z'},
      etaDropoff: {time: '2018-07-18T12:22:00.058Z'},
      type: 'delivery',
      statusUpdatedAt: new Date().toISOString(),
      permissions: [
        'allowManagerTransferDelivery'
      ]
    }
  }

  $onInit () {
    this.prepareData()
    this.onLocaleChangeSubscription = localeHelper.subscribeOnLocaleChange(() => this.prepareData())
    this.$scope.$watch('$ctrl.cardsConfig', () => this.prepareDemoSettings(), true)
  }

  $onDestroy () {
    this.onLocaleChangeSubscription.remove()
  }

  prepareData () {
    this.prepareColumnsList()
    this.prepareCardsList()
    this.prepareMapList()
  }

  prepareDemoSettings () {
    this.cardsConfig.forEach(card => {
      this.demoSettings.cards[card.name] = card.visible
    })
    this.demoSettings = {
      cards: Object.assign({}, this.demoSettings.cards)
    }
  }

  saveSettings () {
    this.settings.board.hiddenColumns = []
    this.columnsConfig.forEach(column => {
      if (!column.visible) {
        this.settings.board.hiddenColumns.push(column.status)
      }
    })

    this.settings.map.hiddenStatuses = []
    this.mapConfig.forEach(status => {
      if (!status.visible) {
        this.settings.map.hiddenStatuses.push(status.name)
      }
    })

    this.settings.cards = this.demoSettings.cards

    this.form.$setPristine()
    this.kanbanDataService.settings = this.settings
  }

  prepareCardsList () {
    this.cardsConfig = this.kanbanDataService.kanbanCardsList.map(card => {
      return {
        name: card,
        title: this.$filter('translate')(`PAGES.KANBAN_SETTINGS.CARDS_TITLES.${card.toUpperCase()}`),
        visible: this.settings.cards[card]
      }
    })
  }

  prepareMapList () {
    this.mapConfig = this.kanbanDataService.kanbanMapStatusesList.map(status => {
      return {
        name: status,
        title: this.$filter('translate')(`PAGES.KANBAN_SETTINGS.MAP_STATUSES.${status.toUpperCase()}`),
        visible: this.settings.map.hiddenStatuses.indexOf(status) === -1
      }
    })
  }

  prepareColumnsList () {
    this.columnsConfig = this.kanbanDataService.kanbanColumnsList.map(status => {
      return {
        status,
        title: this.$filter('translate')(`PAGES.KANBAN_SETTINGS.BOARD_COLUMN_TITLES.${status.toUpperCase()}`),
        visible: this.settings.board.hiddenColumns.indexOf(status) === -1
      }
    })
  }

  switchTab (tabName) {
    if (this.tab !== tabName) {
      this.tab = tabName
    }
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$filter', 'kanbanDataService', KanbanSettings]
}
