export default {
  templateUrl: require('./inherited-titled-panel.pug'),
  transclude: true,
  bindings: {
    onOverrideModeChange: '&',
    useDefault: '<',
    isEditing: '<',
    titleKey: '@?',
    title: '@?'
  }
}
