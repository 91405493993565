import { StateService } from '@uirouter/core'
import BaseController from 'presentation/common/BaseController'
import businessesCollection from 'data/collections/businessesCollection'
import { payoutsCollection } from 'data/collections/payoutsCollection'
import collectEntityFieldByIds from 'data/common/collectByIds'
import * as bankAccountTypes from 'common/constants/BankAccountTypes'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import filesCollection from 'data/collections/filesCollection'
import { BusinessRegistrationData } from 'data/domain-objects/BusinessRegistrationData'
import userModel from 'data/models/userModel'
import User from 'data/domain-objects/User'
import localEnvConfig from 'common/envConfig'

interface IOnBankInfoLoadedPayload {
  bankId: string,
  bankName: string,
  accountType: string,
  accountNumber: string,
  registeredName: string
}

enum DocumentType {
  CIVIL_ID = 'DOCUMENT_TYPE_CIVIL_ID',
  BUSINESS_LICENSE = 'DOCUMENT_TYPE_BUSINESS_LICENSE',
  AUTHORIZED_SIGNATURE = 'DOCUMENT_TYPE_AUTHORIZED_SIGNATURE'
}

enum DocumentDateType {
  ISSUING = 'DOCUMENT_DATE_TYPE_ISSUING',
  EXPIRY = 'DOCUMENT_DATE_TYPE_EXPIRY'
}

class BankAccountInfo extends BaseController {
  public businessId: string = null
  public bankInfoLoaded: (payload: IOnBankInfoLoadedPayload) => void

  public dialogId: string = 'bank-account-info-dialog'

  public bankIds: string[] = []
  public accountTypes: any = bankAccountTypes
  public bankNamesById: any = {}

  public showLoadingError: boolean = false
  public showSubmitError: boolean = false
  public businessRegistrationData: BusinessRegistrationData

  public isPostingFile: boolean = false
  public isSubmitting: boolean = false
  public documentTypes: typeof DocumentType = DocumentType
  public currentlyPostingDocType: DocumentType

  public documentDateTypes: typeof DocumentDateType = DocumentDateType

  public datePattern: RegExp = /^(19|20)\d\d(-)(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/

  public form: ng.IFormController
  public badFormatErrorsMapping: {} = {
    [DocumentType.AUTHORIZED_SIGNATURE]: false,
    [DocumentType.BUSINESS_LICENSE]: false,
    [DocumentType.CIVIL_ID]: false
  }
  public isBadFormatError: boolean = false

  private user: User

  constructor($scope: ng.IScope, $state: StateService) {
    super($scope, $state)
  }

  get hasAlreadyFilledTheForm(): boolean {
    return !!(this.businessRegistrationData && this.businessRegistrationData.id)
  }

  $onChanges(): void {
    if (this.businessId) {
      this.sync()
    }
  }

  onSync(): Promise <any> {
    this.showLoadingError = false
    return Promise.all([
      payoutsCollection.getBanks(),
      businessesCollection.getRegistrationData(this.businessId),
      userModel.getUser()
    ]).then(([banks, businessRegistrationData, user]: [any, BusinessRegistrationData, User]): void => {
      if (banks) {
        this.bankIds = banks.map((bank: any): string => bank.id)
        this.bankNamesById = collectEntityFieldByIds(banks)
      }

      this.businessRegistrationData = businessRegistrationData
      this.user = user

      this.prepopulateEmptyContactsData()
      this.setDocumentsValidity()

      this.bankInfoLoaded({
        bankId: businessRegistrationData.bankId,
        bankName: this.bankNamesById[businessRegistrationData.bankId],
        accountType: businessRegistrationData.type,
        accountNumber: businessRegistrationData.iban,
        registeredName: businessRegistrationData.name.en
      })
    })
    .catch((error: Error): void => {
      this.logger.error('Cannot load bank account details', error)
      this.showLoadingError = true
    })
  }

  editBankInfo(): void {
    modalDialogStateService.emitOpenModalDialog({ dialogId: this.dialogId })
  }

  closeModal(): void {
    modalDialogStateService.emitCloseModalDialog({ dialogId: this.dialogId })
  }

  submitBankInfo(): void {
    this.showSubmitError = false
    this.closeModal()
    this.isSubmitting = true
    businessesCollection.updateRegistrationData(this.businessId, this.businessRegistrationData)
      .catch((error: Error): void => {
        this.logger.error('Cannot update storefront settings', error)
        this.showSubmitError = true
      })
      .then(() => {
        this.$scope.$digest()
        return this.sync()
      })
      .finally(() => {
        this.isSubmitting = false
        this.$scope.$digest()
      })
  }

  onFileChanged(file: File, documentType: DocumentType): void {
    if (file) {
      this.isPostingFile = true
      this.currentlyPostingDocType = documentType
      Object.keys(this.badFormatErrorsMapping).forEach((docType: DocumentType) => {
        this.badFormatErrorsMapping[docType] = false
      })
      filesCollection.postFile(file)
        .then(({id, url}: {id: string, url: string}) => {
          if (documentType === DocumentType.CIVIL_ID) {
            this.businessRegistrationData.civilId.files.push(url)
          } else if (documentType === DocumentType.AUTHORIZED_SIGNATURE) {
            this.businessRegistrationData.authorizedSignature.files.push(url)
          } else if (documentType === DocumentType.BUSINESS_LICENSE) {
            this.businessRegistrationData.license.files.push(url)
          }

          this.setDocumentsValidity()
        })
        .catch((error: any) => {
          if (error.status === 400) {
            this.badFormatErrorsMapping[documentType] = true
          }
        })
        .catch((error: any) => {
          this.logger.error('Error while uploading a file', error)
        })
        .finally(() => {
          this.isPostingFile = false
          this.currentlyPostingDocType = null
          this.$scope.$digest()
        })
      }
  }

  public removeFile(fileUrl: string, documentType: DocumentType): void {
    let filesArray: string[]
    if (documentType === DocumentType.AUTHORIZED_SIGNATURE) {
      filesArray = this.businessRegistrationData.authorizedSignature.files
    } else if (documentType === DocumentType.BUSINESS_LICENSE) {
      filesArray = this.businessRegistrationData.license.files
    } else if (documentType === DocumentType.CIVIL_ID) {
      filesArray = this.businessRegistrationData.civilId.files
    }
    const fileUrlIndex: number = filesArray.indexOf(fileUrl)
    if (fileUrlIndex > -1) {
      filesArray.splice(fileUrlIndex, 1)
    }
    this.setDocumentsValidity()
  }

  public setDocumentsValidity(): void {
    const isValid: boolean = !!this.businessRegistrationData.authorizedSignature.files.length &&
                             !!this.businessRegistrationData.license.files.length &&
                             !!this.businessRegistrationData.civilId.files.length
    this.form.$setValidity('DOCUMENTS', isValid, null)
  }

  private prepopulateEmptyContactsData(): void {
    const contacts = this.businessRegistrationData.contacts[0]
    contacts.countryDiallingCode = contacts.countryDiallingCode || this.user.countryDiallingCode || localEnvConfig.defaultCountryCode
    contacts.phoneNumber = contacts.phoneNumber || this.user.phoneNumber
    contacts.email = contacts.email || this.user.email
    contacts.firstName = contacts.firstName || this.user.firstName
    contacts.lastName = contacts.lastName || this.user.lastName
  }
}

export default {
  templateUrl: require('./bank-account-info.pug'),
  controller: BankAccountInfo,
  bindings: {
    businessId: '<',
    bankInfoLoaded: '&'
  }
}
