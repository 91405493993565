import TariffPeriod from 'data/domain-objects/TariffPeriod'
import Fleet from 'data/domain-objects/Fleet'
import Cost from 'data/domain-objects/Cost'
import DistanceSurcharge from 'data/domain-objects/DistanceSurcharge'
import DurationSurcharge from 'data/domain-objects/DurationSurcharge'
import StatusSurcharge from 'data/domain-objects/StatusSurcharge'
import * as CurrencyCodes from 'common/constants/CurrencyCodes'
import * as MeasureUnits from 'common/constants/MeasureUnits'
import * as PriceModels from 'common/constants/PriceModels'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import WaitingTimeSurcharge from './WaitingTimeSurcharge'
import AddressAreaSurcharge from 'data/domain-objects/AddressAreaSurcharge'
import SpeedSurcharge from 'data/domain-objects/SpeedSurcharge'
import { AllocationTypes } from 'common/constants/AllocationTypes'

export default class Tariff {
  private _distanceUnits: string
  private _priceUnits: string
  public applicablePromoCodeIds: string[]

  constructor (
    public id: string = '',
    private _name: string = '',
    public tier: DBMappedNamedEntity,
    public tariffPeriod: TariffPeriod,
    public allocation: { type: AllocationTypes } = { type: AllocationTypes.hailing }, // wrap it in class in case of adding one more field
    public fleet: Fleet,
    public basePrice: Cost,
    public cancellationFee: Cost,
    public isPricePromised: boolean,
    public isCapped: boolean,
    public cappingPercentage: string,
    public distanceSurcharges: DistanceSurcharge[],
    public durationSurcharges: DurationSurcharge[],
    public statusSurcharges: StatusSurcharge[],
    public waitingTimeSurcharges: WaitingTimeSurcharge[],
    public speedSurcharges: SpeedSurcharge[],
    public slaApplicabilityType: DBMappedNamedEntity,
    public batchingApplicabilityType: DBMappedNamedEntity,
    public addressAreaSurcharges: AddressAreaSurcharge[],
    public restrictedToBranches: any[],
    public cancellationThresholdInSeconds: number,
    public deliveryStateApplicabilityType: DBMappedNamedEntity,
    public applicablePromoCodes: DBMappedNamedEntity[],
    public matchingPromoCodeRequired: boolean,
    public applicableAreas: any[],
    public deliveryScheduleApplicabilityTypes: any[],
    public usePrecalculatedRoute: boolean,
    public isCancellationFeeEnabled: boolean,
    public isCancellationThresholdEnabled: boolean,
    public surgeImmunity: boolean
  ) {
    this._distanceUnits = this.distanceSurcharges.length ? this.distanceSurcharges[0].offset.unitCode || MeasureUnits.KMET : MeasureUnits.KMET
    this._priceUnits = this.basePrice.currencyCode || CurrencyCodes.KWD
    this.applicablePromoCodeIds = this.applicablePromoCodes.map((promoCode: DBMappedNamedEntity) => promoCode.id)
  }

  static build (dto: any = {}): Tariff {

    const applicableAreas = (dto.applicableAreas || []).map((areaConfig: any) => {
      if (areaConfig.scheduledRide && areaConfig.scheduledRide.hasOwnProperty('cancellationFee')) {
        areaConfig.scheduledRide.cancellationFee = Cost.build(areaConfig.scheduledRide.cancellationFee || {})
      }
      return areaConfig
    })

    const deliveryScheduleApplicabilityTypes = (dto.deliveryScheduleApplicabilityTypes || []).map((applicabilityType: any) => {
      return (typeof applicabilityType === 'object' && applicabilityType.id) ? applicabilityType.id : applicabilityType
    })

    const allocation: { type: AllocationTypes } = { type: AllocationTypes.hailing }
    if (dto.allocation && dto.allocation.type && Object.keys(AllocationTypes).includes(dto.allocation.type)) {
      allocation.type = dto.allocation.type
    }

    return new Tariff(
      dto.id,
      dto.name,
      typeof dto.tier !== 'undefined' && dto.tier !== null ? DBMappedNamedEntity.build(dto.tier) : undefined,
      typeof dto.tariffPeriod !== 'undefined' ? TariffPeriod.build(dto.tariffPeriod) : undefined,
      allocation,
      Fleet.build(dto.fleet),
      dto.basePrice !== null ? Cost.build(dto.basePrice) : Cost.build(),
      dto.cancellationFee !== null && typeof dto.cancellationFee !== 'undefined' ? Cost.build(dto.cancellationFee) : null,
      dto.pricePromised,
      !!dto.capped,
      typeof dto.cappingPercentage !== 'undefined' ? dto.cappingPercentage : null,
      Array.isArray(dto.distanceSurcharges) && dto.distanceSurcharges.map(DistanceSurcharge.build) || [],
      Array.isArray(dto.durationSurcharges) && dto.durationSurcharges.map(DurationSurcharge.build) || [],
      Array.isArray(dto.statusSurcharges) && dto.statusSurcharges.map(StatusSurcharge.build) || [],
      Array.isArray(dto.waitingTimeSurcharges) && dto.waitingTimeSurcharges.map(WaitingTimeSurcharge.build) || [],
      Array.isArray(dto.speedSurcharges) && dto.speedSurcharges.map(SpeedSurcharge.build) || [],
      dto.slaApplicabilityType && DBMappedNamedEntity.build(dto.slaApplicabilityType) || new DBMappedNamedEntity('', 'All'),
      dto.batchingApplicabilityType && DBMappedNamedEntity.build(dto.batchingApplicabilityType) || new DBMappedNamedEntity('', 'All'),
      Array.isArray(dto.addressAreaSurcharges) && dto.addressAreaSurcharges.map(AddressAreaSurcharge.build) || [],
      dto.restrictedToBranches || [],
      typeof dto.cancellationThresholdInSeconds !== 'undefined' ? dto.cancellationThresholdInSeconds : null,
      dto.stateApplicabilityType && DBMappedNamedEntity.build(dto.stateApplicabilityType) || new DBMappedNamedEntity('', 'Any'),
      Array.isArray(dto.applicablePromoCodes) && dto.applicablePromoCodes.map(DBMappedNamedEntity.build) || [],
      !!dto.matchingPromoCodeRequired,
      applicableAreas,
      deliveryScheduleApplicabilityTypes,
      dto.usePrecalculatedRoute,
      dto.cancellationFee !== null && typeof dto.cancellationFee !== 'undefined',
      dto.cancellationThresholdInSeconds !== null && typeof dto.cancellationThresholdInSeconds !== 'undefined',
      !!dto.surgeImmunity
    )
  }

  get name (): string {
    if (typeof this._name !== 'undefined' && this._name !== null) {
      return this._name
    } else if (this.tariffPeriod) {
      return this.tariffPeriod.name
    } else {
      return ''
    }
  }

  set name (value: string) {
    this._name = value
  }

  get distanceUnits (): string {
    return this._distanceUnits
  }

  set distanceUnits (value: string) {
    this._distanceUnits = value
    this.distanceSurcharges.map((surcharge: DistanceSurcharge) => {
      surcharge.offset.unitCode = value
      surcharge.step.unitCode = value
    })
  }

  get speedUnits (): string {
    if (this.distanceUnits === MeasureUnits.KMET) {
      return MeasureUnits.KPH
    } else if (this.distanceUnits === MeasureUnits.MILE) {
      return MeasureUnits.MPH
    }
    return ''
  }

  get priceUnits (): string {
    return this._priceUnits
  }

  set priceUnits (value: string) {
    this._priceUnits = value
    this.basePrice.currencyCode = value
    this.cancellationFee.currencyCode = value
  }

  get priceModelTranslationKey (): string {
    return `COMMON.PRICE_MODELS.${this.priceModel}`
  }

  get priceModel (): string {
    if (this.isPricePromised) {
      return PriceModels.PRICE_PROMISED
    } else {
      return this.usePrecalculatedRoute ? PriceModels.FIXED_DISTANCE_AND_DURATION : PriceModels.ACTUAL_PRICE
    }
  }

  set priceModel (value: string) {
    this.isPricePromised = value === PriceModels.PRICE_PROMISED

    if (value === PriceModels.ACTUAL_PRICE) {
      this.usePrecalculatedRoute = false
    }

    if (value === PriceModels.FIXED_DISTANCE_AND_DURATION) {
      this.usePrecalculatedRoute = true
    }
  }

  toJSON (): any {
    const result = {
      id: this.id ? this.id : undefined,
      name: this.name,
      tierId: this.tier ? this.tier.id : undefined,
      tariffPeriodId: this.tariffPeriod ? this.tariffPeriod.id : undefined,
      allocation: this.allocation,
      usePrecalculatedRoute: this.usePrecalculatedRoute,
      capped: this.isCapped,
      cappingPercentage: this.isCapped ? this.cappingPercentage : undefined,
      pricePromised: !!this.isPricePromised, // we need this type assertion here because backend treats `undefined` value wrong
      batchingApplicabilityTypeId: this.batchingApplicabilityType.id || null,
      slaApplicabilityTypeId: this.slaApplicabilityType.id || null,
      stateApplicabilityTypeId: this.deliveryStateApplicabilityType.id || null,
      cancellationThresholdInSeconds: this.cancellationThresholdInSeconds || undefined,
      cancellationFee: this.cancellationFee || undefined,
      basePrice: this.basePrice,
      distanceSurcharges: this.distanceSurcharges,
      durationSurcharges: this.durationSurcharges,
      waitingTimeSurcharges: this.waitingTimeSurcharges,
      statusSurcharges: this.statusSurcharges,
      addressAreaSurcharges: this.addressAreaSurcharges,
      restrictedToBranches: this.restrictedToBranches,
      applicablePromoCodeIds: this.applicablePromoCodeIds,
      matchingPromoCodeRequired: this.matchingPromoCodeRequired,
      applicableAreas: this.applicableAreas.map((areaSettings: any) => Object.assign({}, areaSettings, {
        pickupArea: areaSettings.pickupArea.id,
        dropoffArea: (areaSettings.dropoffArea && areaSettings.dropoffArea.id) || null
      })),
      deliveryScheduleApplicabilityTypes: this.deliveryScheduleApplicabilityTypes,
      surgeImmunity: !!this.surgeImmunity
    }
    return result
  }


}
