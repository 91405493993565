import templateUrl from './branch-onboarding-panels.pug'
import BaseController from 'presentation/common/BaseController'
import businessesCollection from 'data/collections/businessesCollection'

class BranchOnboardingPanelsController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.businessesWithoutBranches = []
    this.sync()
  }

  onSync () {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then(response => this.handleBusinessesSuccess(response))
      .catch(err => this.logger.error('Cannot get businesses with their branches', err))
  }

  handleBusinessesSuccess (businesses) {
    this.businessesWithoutBranches = BranchOnboardingPanelsController.getBusinessesWithoutBranches(businesses)
  }

  static getBusinessesWithoutBranches (businesses) {
    return businesses.filter(business => !(business.branches && business.branches.length))
  }
}

export default {
  templateUrl,
  controller: BranchOnboardingPanelsController
}
