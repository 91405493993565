import templateUrl from './delivery-change-driver-dialog.pug'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import ChangeDriverBaseController from 'presentation/common/ChangeDriverBaseController'

export class DeliveryChangeDriverDialog extends ChangeDriverBaseController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DELIVERY_ACTIONS.CHANGE_DRIVER, deliveryDialogIds.CHANGE_DRIVER_DIALOG_ID)
  }

  onPopupOpen (delivery) {
    this.deliveryId = delivery.id
    this.loadAvailableDrivers()
  }

  beforeLoadAvailableDrivers () { // could be re-defined
    return deliveriesCollection.getAvailableDriversToTransfer(this.deliveryId)
  }

  beforeChangeDriver () { // could be re-defined
    return deliveriesCollection.transferDeliveryToDriver(this.deliveryId, this.selectedDriver.id)
  }

  handleTransferDeliveryToDriverFailed (error) {
    this.logger.error(`Cannot transfer delivery with ID: ${this.deliveryId} to driver ${this.selectedDriver.fullName}`, error)
  }
}

export default {
  templateUrl,
  controller: DeliveryChangeDriverDialog,
  bindings: {
    onConfirm: '&',
    onConfirmFailed: '&'
  }
}
