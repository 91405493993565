import Cost from 'data/domain-objects/Cost'
import StatusSurcharge from 'data/domain-objects/StatusSurcharge'
import DistanceSurcharge from 'data/domain-objects/DistanceSurcharge'
import DurationSurcharge from 'data/domain-objects/DurationSurcharge'
import AddressAreaSurcharge from 'data/domain-objects/AddressAreaSurcharge'
import WaitingTimeSurcharge from 'data/domain-objects/WaitingTimeSurcharge'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

type TMixedSurcharge = DistanceSurcharge | DurationSurcharge | StatusSurcharge | WaitingTimeSurcharge | AddressAreaSurcharge

class TariffSurchargeController {
  public decimalPointsCount: number = 0

  public areas: DBMappedNamedEntity[]
  public priceUnit: string
  public surcharges: TMixedSurcharge[]

  public onAddSurchargeClick: () => void
  public onSurchargeAreasChanged: () => void
  public onRemoveSurchargeClick: (payload: {index: number}) => void

  constructor (
    public $scope: ng.IScope
  ) {}

  $onChanges (simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.hasOwnProperty('priceUnit')) {
      this.decimalPointsCount = Cost.getDecimalPointsCountForCurrency(this.priceUnit)
    } else if (this.areas) {
      this.decimalPointsCount = 3 // multiplier, which is applied for areas should have 3 digits
    }
  }

  public addAreaToSurcharge (areaToAdd: DBMappedNamedEntity, surcharge: AddressAreaSurcharge): void {
    if (areaToAdd !== null) {
      surcharge.areas.push(areaToAdd)
      this.onSurchargeAreasChanged()
    }
  }

  public removeAreaFromSurcharge (areaToRemove: DBMappedNamedEntity, surcharge: AddressAreaSurcharge): void {
    if (areaToRemove !== null) {
      const areaIndex = surcharge.areas.findIndex((area: any): boolean => area.id === areaToRemove.id)
      surcharge.areas.splice(areaIndex, 1)
      this.onSurchargeAreasChanged()
    }
  }

  public onRemoveSurcharge (index: number): void {
    this.onRemoveSurchargeClick({index})
  }

  public onAddSurcharge (): void {
    this.onAddSurchargeClick()
  }
}

export default {
  controller: TariffSurchargeController,
  templateUrl: require('./tariff-surcharge.pug'),
  replace: true,
  bindings: {
    unit: '@',
    label: '@',
    stepUnit: '@',
    fieldHelp: '@',
    priceUnit: '@',
    basePrice: '<',
    isEditing: '<',
    surcharges: '<',
    onAddSurchargeClick: '&',
    onRemoveSurchargeClick: '&',
    onSurchargeAreasChanged: '&',
    offsetUnit: '@?',
    speedUnit: '@?',
    states: '<?', // some surcharges require particular delivery/ride states. If this not true, please remove this param and use DeliveryStatuses.getAll()
    areas: '<?'
  }
}
