import driverAnalyticsCollection from 'data/collections/reportDriverAnalyticsCollection'
import moment from 'moment'
import envConfig from 'common/envConfig'
import BaseReport from 'presentation/reports/common/baseReport'
import templateUrl from './driver-analytics-report.pug'

const DAY_START_PERIOD = {hour: 0, minute: 0, second: 0, millisecond: 0}
const DAY_END_PERIOD = {hour: 23, minute: 59, second: 59, millisecond: 0}
// DPS-147 filter out columns when fleet is selected
// N.B. this thing tightly coupled with BE, cause columns configuration is stored in BE
const COLUMNS_TO_SKIP_WHEN_FLEET_SELECTED = ['distanceCoveredOverOverallTime', 'cashDeliveriesCount', 'cardDeliveriesCount', 'aveDeliveryDistance', 'orderValue']

class DriverAnalyticsReport extends BaseReport {
  constructor ($scope, $interval, $filter, $document) {
    super($scope, $interval, $filter, $document)
  }

  $onInit () {
    this.filters = {
      orderBy: null,
      sortDirection: envConfig.reports.defaultSortOrder
    }

    this.initialStartDate = new Date()
    this.dates = {
      startDate: moment(this.initialStartDate).set(DAY_START_PERIOD),
      endDate: moment(this.initialStartDate).set(DAY_END_PERIOD)
    }

    this.fleets = {}
    this.drivers = {}

    this.fleets.filters = Object.assign({}, this.filters)
    this.drivers.filters = Object.assign({}, this.filters)

    // request new data when user changes dates filter
    this.$scope.$watch('$ctrl.filters', () => {
      this.updateData()
    }, true)

    this.changeDates(this.dates)
    this.updateData()
  }

  changeDates (dates) {
    const start = moment(dates.dateFrom).set(DAY_START_PERIOD).valueOf()
    const finish = moment(dates.dateTo).set(DAY_END_PERIOD).valueOf()
    if (this.filters.start !== start || this.filters.finish !== finish) {
      this.filters.start = start
      this.filters.finish = finish
    }
  }

  sortFleets (params = {}) {
    const orderBy = params.orderBy || this.fleets.filters.orderBy
    const orderDirection = params.orderDirection || this.fleets.filters.sortDirection
    this.fleets.displayData = this.formatData(this.sortData(this.rawFleetsData, this.fleets.columnsConfig, orderBy, orderDirection), this.fleets.columnsConfig)
  }

  sortDrivers (params = {}) {
    const orderBy = params.orderBy || this.drivers.filters.orderBy
    const orderDirection = params.orderDirection || this.drivers.filters.sortDirection
    this.drivers.displayData = this.formatData(this.sortData(this.rawDriversData, this.drivers.columnsConfig, orderBy, orderDirection), this.drivers.columnsConfig)
  }

  exportToCSV (dataSource) {
    this.exportData('driver-analytics-' + dataSource, this[dataSource].displayData, this[dataSource].columnsConfig, 'PAGES.REPORTS.DRIVER_ANALYTICS.HEADERS.')
  }

  filterColumns () {
    const fleetColumnsConfig = (this.fleetId) ? this.fleetsColumnsConfig.filter(column => COLUMNS_TO_SKIP_WHEN_FLEET_SELECTED.indexOf(column.data[0]) === -1) : this.fleetsColumnsConfig
    const driverColumnsConfig = (this.fleetId) ? this.driversColumnsConfig.filter(column => COLUMNS_TO_SKIP_WHEN_FLEET_SELECTED.indexOf(column.data[0]) === -1) : this.driversColumnsConfig

    this.handleColumns(fleetColumnsConfig, this.fleets)
    this.handleColumns(driverColumnsConfig, this.drivers)
  }

  onFleetSelect (fleet) {
    this.fleetId = fleet.id
    this.filterByFleet()
  }

  filterByFleet () {
    this.filterColumns()

    this.sortDrivers()
    this.sortFleets()

    if (!this.fleetId) {
      return
    }
    const fleetName = this.availableFleetsById[this.fleetId]

    this.drivers.displayData = this.drivers.displayData.filter(record => {
      return record.fleet === fleetName
    })

    this.fleets.displayData = this.fleets.displayData.filter(record => {
      return record.fleet === fleetName
    })
  }

  handleFleetsDropdown () {
    this.availableFleets = []
    this.availableFleetsById = {}
    let previouslySelectedFleetIDStillExists = false

    this.rawFleetsData.map(fleetData => {
      if (! this.availableFleetsById.hasOwnProperty(fleetData.id)) {
        this.availableFleetsById[fleetData.id] = fleetData.fleet
        this.availableFleets.push(fleetData.fleet)
        if (fleetData.id === this.fleetId) {
          previouslySelectedFleetIDStillExists = true
        }
      }
    })

    if (!previouslySelectedFleetIDStillExists) {
      this.fleetId = null
    }
  }

  handleResponse (driverAnalyticsDTOResponse) {
    this.fleetsColumnsConfig = driverAnalyticsDTOResponse.fleets.metadata.columns
    this.driversColumnsConfig = driverAnalyticsDTOResponse.drivers.metadata.columns
    this.filterColumns()

    this.rawDriversData = Object.values(driverAnalyticsDTOResponse.drivers.records)
    this.rawFleetsData = Object.values(driverAnalyticsDTOResponse.fleets.records)

    this.handleFleetsDropdown()

    this.sortAndFormat()
  }

  onUpdateData () {
    const filters = Object.assign({}, this.filters)

    return driverAnalyticsCollection.get(filters)
      .then(driverAnalyticsDTOResponse => {
        this.handleResponse(driverAnalyticsDTOResponse)
      })
      .catch(err => {
        this.logger.error(err)
      })
  }

  sortAndFormat () {
    this.filterByFleet()
  }

  $onDestroy () {
    this.onDestroy(driverAnalyticsCollection)
  }
}

export default {
  templateUrl,
  controller: DriverAnalyticsReport
}
