import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import surgePricingDashboard from 'presentation/logistics/surge-pricing/surge-pricing-dashboard/surgePricingDashboard'
import priceSurgeForm from 'presentation/logistics/surge-pricing/price-surge-form/priceSurgeForm'

export default declareAppModule('surgePricing', (module: ng.IModule) => module
  .component('surgePricingDashboard', surgePricingDashboard)
  .component('priceSurgeForm', priceSurgeForm)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'surge-pricing',
      componentName: 'surgePricingDashboard',
      stateKey: 'SURGE_PRICING',
      requiresPermission: 'PRICE_SURGE'
    })
    declareState($stateProvider, {
      parent: 'main.logistics.surge-pricing',
      stateName: 'price-surge',
      idParam: 'surgeId',
      componentName: 'priceSurgeForm',
      stateKey: 'PRICE_SURGE',
      requiresPermission: 'PRICE_SURGE_VIEW'
    })
    declareState($stateProvider, {
      parent: 'main.logistics.surge-pricing',
      stateName: 'create',
      componentName: 'priceSurgeForm',
      stateKey: 'PRICE_SURGE_CREATE',
      requiresPermission: 'PRICE_SURGE_CREATE'
    })
  }))
