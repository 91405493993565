import Cost from './Cost'

export default class PromoCode {
  constructor(
    public id: string = '',
    public name: string = '',
    public description: string = '',
    public createdAt: string = '',
    public createdBy: {fullName: string, email: string} = null,
    public fixedDiscount: Cost,
    public percentDiscount: number = 0,
    public totalLimit: number = 0,
    public totalUses: number = 0,
    public personalLimit: number = 0,
    public validFrom: Date,
    public expiresAt: Date
  ) {}

  static build (dto: any = {}): PromoCode {
    return new PromoCode(
      dto.id || '',
      dto.name || '',
      dto.description || '',
      dto.createdAt || '',
      dto.createdBy || null,
      dto.fixedDiscount ? Cost.build(dto.fixedDiscount) : Cost.build(),
      dto.percentDiscount || 0,
      dto.totalLimit || 0,
      dto.totalUses || 0,
      dto.personalLimit || 0,
      dto.validFrom ? new Date(dto.validFrom) : null,
      dto.expiresAt ? new Date(dto.expiresAt) : null
    )
  }
}
