import templateUrl from './radio-link.pug'

class RadioLinkController {
  onClick () {
    this.model = this.value
    this.onUpdate({
      value: this.value,
      itemId: this.itemId
    })
  }
}

export default {
  templateUrl,
  controller: RadioLinkController,
  bindings: {
    model: '=',
    value: '<',
    isDisabled: '<',
    baseTranslationKey: '@',
    onUpdate: '&',
    itemId: '@?'
  }
}
