import templateUrl from './pickup-time-settings.pug'
import envConfig from 'common/envConfig'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SHOW_VALUE = envConfig.businessAndBranchSettings.defaultDisplaySettingsValue

const SETTINGS_KEYS = [
  'showPickupTimeSettings',
  'showPickupTimeASAP',
  'showPickupTimeInMinutes',
  'defaultPickupTimeInMinutes',
  'defaultPickupTimeMode',
  'showPickupDateTime'
]

class PickupTimeSettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)

    this.dropdownValues = envConfig.businessAndBranchSettings.availableDisplaySettingsValues
    this.defaultSelectionValues = envConfig.businessAndBranchSettings.availablePickupTimeValues

    this.branchIds = []
    this.branchNamesById = {}
    this.isSingleOption = false

    this.minutesRange = envConfig.requestedTime.inMinutes
  }

  handleModelUpdate () {
    super.handleModelUpdate()
    this.onOptionsChange()
  }

  onOptionsChange () {
    this.checkIsSingleOption()
    this.setDefaultSelectionToFirstEnabledOption()
    this.model.showPickupTimeSettings.value = this.isSingleOption ? this.model.showPickupTimeSettings.value : SHOW_VALUE

    if (this.isSingleOption && this.model.showPickupDateTime.value) {
      this.model.showPickupTimeSettings.value = SHOW_VALUE
    }
  }

  checkIsSingleOption () {
    this.isSingleOption = [
      this.model.showPickupTimeASAP.value,
      this.model.showPickupTimeInMinutes.value,
      this.model.showPickupDateTime.value
    ].filter(item => item).length <= 1
  }

  setDefaultSelectionToFirstEnabledOption () {
    if (this.model.defaultPickupTimeMode.value) { // If there's exists mode which is set as default
      const enabledOptions = []

      // We need to know which modes are still available
      if (this.model.showPickupTimeASAP.value) {
        enabledOptions.push(this.defaultSelectionValues.asap)
      }
      if (this.model.showPickupTimeInMinutes.value) {
        enabledOptions.push(this.defaultSelectionValues.inMinutes)
      }
      if (this.model.showPickupDateTime.value) {
        enabledOptions.push(this.defaultSelectionValues.dateTime)
      }

      // And then if there's no enabled modes or previously selected default mode is disabled
      if (!enabledOptions.length || enabledOptions.indexOf(this.model.defaultPickupTimeMode.value) < 0) {
        // Set default mode to first available option
        this.model.defaultPickupTimeMode.value = enabledOptions[0]
      }
    }
  }
}

export default {
  templateUrl,
  controller: PickupTimeSettingsController,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
