import templateUrl from './credential-input.pug'
import BaseController from 'presentation/common/BaseController'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'

class CredentialInputController extends BaseController {
  constructor ($scope) {
    super($scope)
  }

  $onInit () {
    const localCredentialDescriptor = CredentialInputController.getLocalCredentialDescriptorGivenBinding(this.credential)
    this.localCredential = localCredentialDescriptor.localCredential
    this.usingPhone = localCredentialDescriptor.usingPhone
    this.usingEmail = localCredentialDescriptor.usingEmail

    this.$scope.$watch('$ctrl.localCredential', () => this.notifyCredentialChanged(), true)
    this.conditionallySetDefaultDiallingCode()
  }

  static getLocalCredentialDescriptorGivenBinding (credential) {
    // Decide local state based on the credential provided
    const usePhone = !credential.email // Default to phone
    const useEmail = !usePhone

    return {
      localCredential: {
        phoneNumber: usePhone ? credential.phoneNumber : '',
        countryDiallingCode: usePhone ? credential.countryDiallingCode : '',
        email: useEmail ? credential.email : ''
      },
      usingPhone: usePhone,
      usingEmail: useEmail
    }
  }

  conditionallySetDefaultDiallingCode () {
    if (!this.localCredential.countryDiallingCode) {
      diallingCodesCollection.getDefault()
        .then(defaultDiallingCode => {
          this.localCredential.countryDiallingCode = defaultDiallingCode
          this.$scope.$digest()
        })
        .catch(err => this.logger.error('Cannot get default country dialling code', err))
    }
  }

  setUsingPhone () {
    this.usingPhone = true
    this.usingEmail = false
    this.notifyCredentialChanged()
  }

  setUsingEmail () {
    this.usingPhone = false
    this.usingEmail = true
    this.notifyCredentialChanged()
  }

  notifyCredentialChanged () {
    const credential = this.getChosenCredential()
    this.onCredentialChanged({credential})
  }

  getChosenCredential () {
    const chosenCredential = {}
    if (this.usingPhone) {
      chosenCredential.phoneNumber = this.localCredential.phoneNumber
      chosenCredential.countryDiallingCode = this.localCredential.countryDiallingCode
    } else {
      chosenCredential.email = this.localCredential.email
    }
    return chosenCredential
  }
}

export default {
  templateUrl,
  controller: CredentialInputController,
  bindings: {
    credential: '<',
    onCredentialChanged: '&'
  }
}
