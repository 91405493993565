import templateUrl from './delivery-address-picker.pug'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'

const ADDRESS_TYPES = {
  CONSUMER: 'CONSUMER',
  BRANCH: 'BRANCH'
}

class DeliveryAddressPicker {
  constructor ($scope) {
    this.$scope = $scope
  }

  $onInit () {
    this.addressTypes = [ADDRESS_TYPES.CONSUMER, ADDRESS_TYPES.BRANCH]
    this.newAddressType = null
    this.selectedConsumerAddressId = null
    this.selectedBranchId = null
    this.disallowedBranchId = this.getBranchIdForAddressId(this.disallowedAddressId)

    this.$scope.$watch('$ctrl.newAddressType', (newVal, oldVal) => this.handleAddressTypeChanged(newVal, oldVal))
    this.$scope.$watch('$ctrl.selectedBranch', newVal => {
      if (newVal) {
        this.onBranchSelected(this.selectedBranch)
      }
    })
  }

  $onChanges (changeDescriptors) {
    const changedAddressId = getValueFromChangeDescriptors(changeDescriptors, 'addressId')
    const changedBranches = getValueFromChangeDescriptors(changeDescriptors, 'branches')
    const changedConsumer = getValueFromChangeDescriptors(changeDescriptors, 'consumer')

    // Data may arrive at different times, so we sync with the latest information each time
    if (changedAddressId || changedBranches || changedConsumer) {
      this.syncUIState(this.addressId, this.consumer, this.branches)
    }

    if (changeDescriptors.disallowedAddressId) {
      this.disallowedBranchId = this.getBranchIdForAddressId(this.disallowedAddressId)
    }
  }

  syncUIState (addressId, consumer, branches) {
    const isConsumerAddress = this.getIsConsumerAddress(consumer, addressId)
    const branchWithAddress = this.getBranchWithAddress(branches, addressId)
    this.newAddressType = this.getAddressTypeForAddressId(addressId) || null
    this.selectedConsumerAddressId = isConsumerAddress ? addressId : null
    this.selectedBranchId = branchWithAddress ? branchWithAddress.id : null
  }

  handleAddressTypeChanged (newAddressType, previousAddressType) {
    if (newAddressType !== previousAddressType && !!previousAddressType) { // Ignore if unchanged or first assignment
      this.newAddressType = newAddressType
      this.selectedConsumerAddressId = null // NB. We clear the selection when type changed
      this.selectedBranchId = null
      this.onAddressIdChosen({addressId: null})
    }
  }

  onConsumerAddressSelected (consumerAddressId) {
    this.newAddressType = ADDRESS_TYPES.CONSUMER
    this.selectedConsumerAddressId = consumerAddressId
    this.selectedBranchId = null
    this.onAddressIdChosen({addressId: consumerAddressId})
  }

  onBusinessBranchSelect (businessOrBranch) {
    const branchId = businessOrBranch.branchId || null
    this.newAddressType = ADDRESS_TYPES.BRANCH
    this.selectedConsumerAddressId = null
    this.selectedBranchId = branchId
    const branchAddressId = branchId ? businessOrBranch.addressId : null
    this.onAddressIdChosen({addressId: branchAddressId})
  }

  getAddressTypeForAddressId (addressId) {
    const isConsumerAddress = this.getIsConsumerAddress(this.consumer, addressId)
    const branchWithAddress = this.getBranchWithAddress(this.branches, addressId)

    if (isConsumerAddress) {
      return ADDRESS_TYPES.CONSUMER
    } else if (branchWithAddress) {
      return ADDRESS_TYPES.BRANCH
    } else {
      return null
    }
  }

  getIsConsumerAddress (consumer, addressId) {
    return !!consumer && consumer.getHasAddress(addressId)
  }

  getBranchWithAddress (branches, addressId) {
    const branchesList = branches || []
    for (let i = 0, len = branchesList.length; i < len; i++) {
      if (branchesList[i].addressId === addressId) {
        return branchesList[i]
      }
    }
    return null
  }

  getBranchIdForAddressId (addressId) {
    const branch = this.getBranchWithAddress(this.branches, addressId)
    return branch ? branch.id : null
  }
}

export default {
  templateUrl,
  controller: DeliveryAddressPicker,
  bindings: {
    addressId: '<',
    consumer: '<',
    branches: '<',
    hasDataSyncError: '<',
    form: '<',
    name: '@',
    baseTranslationKey: '@',
    onAddressIdChosen: '&',
    disallowedAddressId: '<?'
  }
}
