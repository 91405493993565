import templateUrl from './delivery-batch-dialog.pug'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import BasePopupController from 'presentation/common/BasePopupController'
import DeliveryDataService from 'data/common/deliveryDataService'

class DeliveryBatchDialog extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DELIVERY_ACTIONS.BATCHING, deliveryDialogIds.BATCHING_DIALOG_ID)
    this.BATCHING_DIALOG_ID = deliveryDialogIds.BATCHING_DIALOG_ID
    this.hasError = null
    this.delivery = null

    this.setupPopupOpenEventListener(delivery => {
      this.deliveryId = delivery.id
      this.batchWithDeliveryId = null
      this.disabledConfirm = false
      this.getFullDeliveryData()
    })
  }

  getFullDeliveryData () {
    this.delivery = null
    this.isLoading = true
    deliveriesCollection.getById(this.deliveryId)
      .then(delivery => {
        this.delivery = delivery
        this.fleetId = delivery.fleetId
        this.allowBatching = delivery.canBatch
        this.defaultBatchOption = this.allowBatching ? 'auto' : 'no'
      })
      .catch(error => {
        this.logger.error('Cannot get delivery data', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  confirm () {
    if (this.deliveryId) {
      const batchWithDeliveryId = this.allowBatching ? this.batchWithDeliveryId : null
      deliveriesCollection.batchDelivery(this.deliveryId, this.allowBatching, batchWithDeliveryId)
        .then(() => {
          this.hasError = null
          this.onConfirm()
          DeliveryDataService.publishDeliverySyncEvent()
          this.close()
        })
        .catch(error => {
          this.hasError = error
          this.$scope.$digest()
        })
    }
  }
}

export default {
  templateUrl,
  controller: DeliveryBatchDialog,
  bindings: {
    onConfirm: '&'
  }
}
