import Cost from 'data/domain-objects/Cost'
import Duration from 'data/domain-objects/Duration'
import BaseSurcharge from 'data/domain-objects/BaseSurcharge'
import * as MeasureUnits from 'common/constants/MeasureUnits'
import {valueToAmount} from 'data/common/costFixer'

export default class SpeedSurcharge extends BaseSurcharge {
  constructor (
    public price: Cost,
    public step: Duration,
    public minSpeed: number,
    public maxSpeed: number,
    public status: string
  ) {
    super(price, step)
  }

  static build (dto: any = {}): SpeedSurcharge {
    const step = dto.step || {unitCode: MeasureUnits.MINU}
    return new SpeedSurcharge(
      Cost.build(dto.price),
      Duration.build(step),
      dto.minSpeed && parseFloat(dto.minSpeed) || 0,
      dto.maxSpeed && parseFloat(dto.maxSpeed) || 0,
      dto.status || ''
    )
  }

  toJSON(): any {
    return valueToAmount(this, ['price'])
  }
}
