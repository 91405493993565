import templateUrl from './entity-delivery-settings.pug'
import ViewEditFormController from 'presentation/common/ViewEditFormController'
import permissionsModel from 'data/models/permissionsModel'
import * as SMSContentCodes from 'common/constants/SMSContentCodes'
import * as Locales from 'common/constants/Locales'
import userModel from 'data/models/userModel'

class EntityDeliverySettings extends ViewEditFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.isSaved = false
    this.locales = Locales
    this.smsContentCodes = SMSContentCodes
    this.user = userModel.getLocalUser()
  }

  getSmsEnabledSetting (contentCode) {
    if (!this.model) {
      return
    }

    return this.model[`smsEnabled__${contentCode}`]
  }

  getSmsTemplateSetting (contentCode, locale) {
    if (!this.model) {
      return
    }

    return this.model[`smsTemplate__${contentCode}__${locale}`]
  }

  getDefaultSmsTemplate (contentCode, locale) {
    if (!this.defaultSmsTemplates) {
      return
    }
    return this.defaultSmsTemplates.find(smsTemplate => {
      return smsTemplate.contentCode === contentCode && smsTemplate.locale === locale
    })
  }

  $onInit () {
    this.isEditing = !!permissionsModel.getHasPermission(this.permissionToEditKey)
  }

  beforeSubmit () {
    this.isSaved = false
    return this.onSubmit()
  }

  afterSubmitSuccess () {
    this.isSaved = true
  }

  afterSubmitFailure () {
    return null
  }
}

export default {
  templateUrl,
  controller: EntityDeliverySettings,
  bindings: {
    entityType: '@',
    model: '=',
    permissionToEditKey: '@',
    defaultSmsTemplates: '<',
    onSubmit: '&',
    errorsKey: '@',
    updateSettingsFailed: '<',
    getSettingsFailed: '<'
  }
}
