import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import reportsDashboard from 'presentation/reports/dashboard/reportsDashboard'
import { StateProvider } from '@uirouter/angularjs'

import ordersByDayGraph from 'presentation/reports/live-orders/orders-by-day-graph/ordersByDayGraph'
import liveOrdersReport from 'presentation/reports/live-orders/liveOrdersReport'
import driverAnalyticsReport from 'presentation/reports/driver-analytics/driverAnalyticsReport'
import areaAnalyticsReport from 'presentation/reports/area-analytics/areaAnalyticsReport'

export default declareAppModule('reports', (module: ng.IModule) => module
  .component('reportsDashboard', reportsDashboard)
  .component('liveOrdersReport', liveOrdersReport)
  .component('ordersByDayGraph', ordersByDayGraph)
  .component('driverAnalyticsReport', driverAnalyticsReport)
  .component('areaAnalyticsReport', areaAnalyticsReport)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'reports',
      componentName: 'reportsDashboard',
      stateKey: 'REPORTS',
      requiresPermission: 'REPORTS'
    })

    declareState($stateProvider, {
      parent: 'main.reports',
      stateName: 'live-orders',
      componentName: 'liveOrdersReport',
      stateKey: 'REPORTS_LIVE_ORDERS',
      requiresPermission: 'REPORTS_LIVE_ORDERS'
    })

    declareState($stateProvider, {
      parent: 'main.reports',
      stateName: 'driver-analytics',
      componentName: 'driverAnalyticsReport',
      stateKey: 'REPORTS_DRIVER_ANALYTICS',
      requiresPermission: 'REPORTS_DRIVER_ANALYTICS'
    })

    declareState($stateProvider, {
      parent: 'main.reports',
      stateName: 'area-analytics',
      componentName: 'areaAnalyticsReport',
      stateKey: 'REPORTS_AREA_ANALYTICS',
      requiresPermission: 'REPORTS_AREA_ANALYTICS'
    })
  })
)
