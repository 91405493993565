import templateUrl from './packages-builder.pug'
import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import getRangedArray from 'presentation/_utilities/getRangedArray'

const MAX_PACKAGE_COUNT = 50

// NB. This control does not force the packages to be 'consolidated' with one package type per amount.
// This means it can emit separate objects for with multiple counts for the same package type.
class PackagesBuilderController {
  constructor ($scope) {
    this.$scope = $scope
  }

  $onInit () {
    this.localPackages = cloneDeep(this.packages || [])
    this.packageAmountValues = getRangedArray(1, MAX_PACKAGE_COUNT)

    this.$scope.$watch('$ctrl.localPackages', () => this.handlePackagesChanged(), true)
  }

  $onChanges (changeDescriptorsByKey) {
    if (changeDescriptorsByKey.packageTypes && changeDescriptorsByKey.packageTypes.currentValue) {
      this.packageTypeIds = this.packageTypes.map(type => type.id)
      this.packageTypeNamesById = this.packageTypes.reduce((accum, type) => (accum[type.id] = type.name) && accum, {})
    } else if (changeDescriptorsByKey.packageTypes && !changeDescriptorsByKey.packageTypes.currentValue) {
      this.packageTypeIds = []
      this.packageTypeNamesById = {}
    }
    // NB. The temporary presence of $$hashkey on localPackages causes an iteration of the changes loop each time
    if (changeDescriptorsByKey.packages && !isEqual(this.packages, this.localPackages)) {
      this.localPackages = cloneDeep(this.packages || [])

      if (this.localPackages.length === 0 && this.defaultPackage) {
        this.localPackages.push(this.defaultPackage)
      }
    }

    if (changeDescriptorsByKey.defaultPackage && this.defaultPackage && this.localPackages.length === 0) {
      this.localPackages.push(this.defaultPackage)
    }
  }

  addNewPackage () {
    this.localPackages.push({amount: 1, packageTypeId: this.packageTypeIds[0]})
  }

  removePackageAtIndex (index) {
    this.localPackages.splice(index, 1)
  }

  handlePackagesChanged () {
    this.form.$setValidity(this.name + 'HasPackages', !!this.localPackages.length)
    this.notifyPackagesChanged()
  }

  notifyPackagesChanged () {
    const clonedPackages = this.localPackages.map(p => {
      return {amount: p.amount, packageTypeId: p.packageTypeId} // Must clone values we want alone, to remove angular's $$hashkey
    })
    this.onPackagesChanged({packages: clonedPackages})
  }
}

export default {
  templateUrl,
  controller: PackagesBuilderController,
  bindings: {
    defaultPackage: '<',
    form: '<',
    name: '@',
    packages: '<',
    packageTypes: '<',
    onPackagesChanged: '&',
    isEditing: '<',
    isDisabled: '<',
    isRequired: '<'
  }
}
