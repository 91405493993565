import Cost from 'data/domain-objects/Cost'
import RequestedTime from 'data/domain-objects/RequestedTime'
import config from './constants'
import * as PaymentMethods from 'common/constants/PaymentMethods'
import * as DeliveryTypes from 'common/constants/DeliveryTypes'
import Consumer from './Consumer'
import Branch from './Branch'

export default class NewDeliveryModel {
  public isNextDay: boolean = false
  public consumer: any = null
  public branch: any = null

  public deliveryType: string = DeliveryTypes.PICK_UP_FROM_BRANCH
  public cost: Cost = Cost.build()
  public paymentMethod: string = PaymentMethods.CASH
  public status: string = config.defaultNewDeliveryStatus
  public posReference: string = null
  public requestedPickupTime: RequestedTime
  public packages: any = null
  public fleetId: string = null
  public balanceAccountId: string = null
  public batchWithDeliveryId: string = null
  public requireSignature: boolean = false
  public requirePhotoIdentification: boolean = false
  public allowBatching: boolean = true
  public showBatchingOptions: boolean = true
  public currencyCode: string = config.defaultCurrencyCode

  constructor (isNextDay: boolean) {
    this.isNextDay = isNextDay
    this.resetConsumer()
    this.resetBranch()
    this.resetDelivery()
  }

  resetConsumer (): void {
    this.consumer = {
      id: null,
      addressId: null
    }
  }

  resetBranch (): void {
    this.branch = {
      id: null,
      addressId: null
    }
  }

  resetDelivery (): void {
    this.deliveryType = DeliveryTypes.PICK_UP_FROM_BRANCH
    this.cost = Cost.build()
    this.paymentMethod = PaymentMethods.CASH
    this.status = config.defaultNewDeliveryStatus
    this.posReference = null
    this.requestedPickupTime = new RequestedTime(this.isNextDay)
    this.packages = null
    this.fleetId = null
    this.balanceAccountId = null
    this.batchWithDeliveryId = null
    this.requireSignature = false
    this.requirePhotoIdentification = false
    this.allowBatching = true
    this.showBatchingOptions = true
    this.currencyCode = config.defaultCurrencyCode
  }

  toggleDeliveryType (): void {
    this.deliveryType = (this.deliveryType === DeliveryTypes.PICK_UP_FROM_CONSUMER) ? DeliveryTypes.PICK_UP_FROM_BRANCH : DeliveryTypes.PICK_UP_FROM_CONSUMER
  }

  setToPickUpFromConsumer (): void {
    this.deliveryType = DeliveryTypes.PICK_UP_FROM_CONSUMER
  }

  setToPickUpFromBranch (): void {
    this.deliveryType = DeliveryTypes.PICK_UP_FROM_BRANCH
  }

  setStatus (status: string): void {
    this.status = status
  }

  setConsumer (consumer: Consumer): void {
    this.consumer.id = consumer.id
  }

  setBranch (branch: Branch): void {
    this.branch.id = branch.id
    this.branch.addressId = branch.addressId
  }

  setCurrencyCode (currencyCode: string): void {
    this.cost.currencyCode = currencyCode
  }

  setBalanceAccountId (balanceAccountId: string): void {
    this.balanceAccountId = balanceAccountId
  }

  getDeliveryDescriptorForApi (): any {
    const now = new Date()
    const sender: any = {}
    const recipient: any = {}

    if (this.deliveryType === DeliveryTypes.PICK_UP_FROM_CONSUMER) {
      sender.type = 'consumer'
      sender.id = this.consumer.id
      sender.addressId = this.consumer.addressId
      recipient.type = 'branch'
      recipient.id = this.branch.id
      recipient.addressId = this.branch.addressId
    }

    if (this.deliveryType === DeliveryTypes.PICK_UP_FROM_BRANCH) {
      sender.type = 'branch'
      sender.id = this.branch.id
      sender.addressId = this.branch.addressId
      recipient.type = 'consumer'
      recipient.id = this.consumer.id
      recipient.addressId = this.consumer.addressId
    }

    const cost = (this.cost.amount) ? this.cost : Cost.build(this.cost)

    const descriptor = {
      status: this.status,
      sender,
      recipient,
      originatingConsumer: this.consumer.id,
      payment: {
        method: this.paymentMethod,
        posReference: this.posReference,
        cost: {
          amount: cost.amount,
          currencyCode: cost.currencyCode
        }
      },
      fleetId: this.fleetId,
      pickupTime: this.requestedPickupTime.toISOStringGivenNow(now),
      packages: this.packages,
      batchWithDeliveryId: this.batchWithDeliveryId || null,
      balanceAccountId: this.balanceAccountId || null,
      proofOfDelivery: {
        signature: {
          required: this.requireSignature
        },
        photoIdentification: {
          required: this.requirePhotoIdentification
        }
      },
      canBatch: this.allowBatching
    }

    return descriptor
  }
}
