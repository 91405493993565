import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import request from './request/request'
import requestConfirm from './request-confirm/requestConfirm'
import requestForm from './request-form/requestForm'
import requestSummary from './request-summary/requestSummary'
import requests from './requests/requests'
import confirmedRequests from 'presentation/requests/confirmed-requests/confirmedRequests'

export default declareAppModule('requests', (module: ng.IModule) => module
  .component('request', request)
  .component('requestConfirm', requestConfirm)
  .component('requestForm', requestForm)
  .component('requestSummary', requestSummary)
  .component('requests', requests)
  .component('confirmedRequests', confirmedRequests)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'requests',
      componentName: 'requests',
      stateKey: 'REQUESTS'
    })

    declareState($stateProvider, {
      parent: 'main.requests',
      stateName: 'confirmed-requests',
      componentName: 'confirmedRequests',
      stateKey: 'REQUESTS'
    })

    declareState($stateProvider, {
      parent: 'main.requests',
      stateName: 'create',
      params: ['talabatData'],
      componentName: 'requestConfirm',
      stateKey: 'REQUEST_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.requests',
      stateName: 'request',
      idParam: 'requestId',
      componentName: 'request',
      stateKey: 'REQUEST'
    })

    declareState($stateProvider, {
      parent: 'main.requests.request',
      stateName: 'confirm',
      componentName: 'requestConfirm',
      stateKey: 'REQUEST_CONFIRM'
    })
  })
)
