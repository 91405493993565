export default function bindableTemplate ($compile) {
  return {
    restrict: 'A',
    scope: {
      template: '<bindableTemplate'
    },
    link: function ($scope, $element) {
      $scope.$watch('template', () => {
        // We compiling template with parent scope to make initial scope applied to current DOM Element accessible
        const compiledTemplate = $compile($scope.template)($scope.$parent)
        $element.empty().append(compiledTemplate)
      })
    }
  }
}
