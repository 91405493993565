import envConfig from 'common/envConfig'
import templateUrl from './issues-dashboard.pug'
import issuesCollection from 'data/collections/issuesCollection'
import * as issueTypes from 'common/constants/IssueTypes'
import * as issueLevels from 'common/constants/IssueLevels'
import * as issueTrackerEvents from 'common/constants/IssueTrackerEvents'
import uiSettingsService from 'data/services/uiSettingsService'
import PaginationParameters from 'data/domain-objects/PaginationParameters'
import fleetsCollection from 'data/collections/fleetsCollection'
import Logger from 'common/Logger'

const UPDATE_INTERVAL_MS = envConfig.issuesPageUpdateIntervalMs
const REFRESH_ISSUES_AFTER_RESOLVE_TIMEOUT = 200
const DataSourceTypes = {
  RESOLVED_ISSUES: 'DATASOURCE_TYPE_RESOLVED_ISSUES',
  ACTIVE_ISSUES: 'DATASOURCE_TYPE_ACTIVE_ISSUES'
}
const logger = new Logger('Issue Dashboard Controller')

class IssuesDashboardController {
  constructor ($scope, $filter) {
    this.$scope = $scope
    this.$filter = $filter
    this.logger = logger
    this.issueTypes = issueTypes
    this.issueLevels = issueLevels
    this.issueTrackerUiSettings = uiSettingsService.issueTrackerSettings
    this.paginationParams = new PaginationParameters()
    this.DataSourceTypes = DataSourceTypes
    this.currentDataSourceType = DataSourceTypes.ACTIVE_ISSUES
    this.currentFilters = this.issueTrackerUiSettings.activeIssuesFilters
    this.selectedFleets = []

    this.resolvedIssues = []
    this.activeIssues = []
    this.fleets = []

    this.showActionError = false
    this.isLoading = true
  }

  /* -----LIFECYCLE HOOKS START----- */
  $onInit () {
    this.getIssues()
    this.getFleets()
  }

  $onDestroy () {
    this.conditionallyCancelUpdateTimeout()
  }
  /* -----LIFECYCLE HOOKS END----- */

  /* -----GETTERS AND SETTERS START----- */
  get filtersChangedCounter () {
    let counter = 0
    if (this.currentFilters.searchQuery) {
      counter++
    }
    if (this.currentFilters.selectedBranchId || this.currentFilters.selectedBusinessId) {
      counter++
    }
    return counter
  }
  /* -----GETTERS AND SETTER END----- */

  /* -----ISSUE EVENTS HANDLING START----- */
  handleSuccessfulIssueResolution () {
    setTimeout(() => this.handleSuccessfulIssueAction(), REFRESH_ISSUES_AFTER_RESOLVE_TIMEOUT)
  }

  handleSuccessfulIssueAction () {
    this.showActionError = false
    this.getIssues()
  }

  handleFailedIssueAction (error) {
    this.showActionError = true
    this.logger.error('Cannot proceed action with issue', error)
  }
  /* -----ISSUE EVENT HANDLING END----- */

  /* -----GET DATA START----- */
  getIssues () {
    this.conditionallyCancelUpdateTimeout()
    let deferred
    this.isLoading = true
    if (this.currentDataSourceType === DataSourceTypes.ACTIVE_ISSUES) {
      deferred = this.getActiveIssues()
    } else if (this.currentDataSourceType === DataSourceTypes.RESOLVED_ISSUES) {
      deferred = this.getResolvedIssues()
    }
    if (deferred) {
      deferred.then(() => {
        this.isLoading = false
        this.$scope.$digest()
        this.scheduleUpdate()
      })
    }
  }

  getActiveIssues () {
    this.activeIssues = []
    return issuesCollection.getAll(
      this.currentFilters.searchQuery,
      this.currentFilters.selectedFleetIds,
      this.currentFilters.selectedBusinessId,
      this.currentFilters.selectedBranchId,
      this.showAllIssues ? [] : this.currentFilters.selectedIssueTypes,
      this.showAllIssues ? [] : this.currentFilters.selectedIssueLevels,
      this.paginationParams.skip,
      this.paginationParams.limit
    )
      .then(response => {
        this.paginationParams.count = response.metadata.count
        this.tagStatistics = response.metadata.stat
        this.activeIssues = response.records
      })
      .catch(error => this.logger.error('Cannot get active issues', error))
  }

  getResolvedIssues () {
    this.resolvedIssues = []
    return issuesCollection.getResolvedIssues(
      this.currentFilters.searchQuery,
      this.currentFilters.selectedFleetIds,
      this.currentFilters.selectedBusinessId,
      this.currentFilters.selectedBranchId,
      this.showAllIssues ? [] : this.currentFilters.selectedIssueTypes,
      this.showAllIssues ? [] : this.currentFilters.selectedIssueLevels,
      this.paginationParams.skip,
      this.paginationParams.limit
    )
      .then(response => {
        this.paginationParams.count = response.metadata.count
        this.tagStatistics = response.metadata.stat
        this.resolvedIssues = response.records
      })
      .catch(error => this.logger.error('Cannot get resolved issues', error))
  }

  getFleets () {
    return fleetsCollection.getAll()
      .then(fleets => {
        this.fleets = fleets
        this.selectedFleets = this.fleets.filter(fleet => this.currentFilters.selectedFleetIds.indexOf(fleet.id) > -1)
      })
      .catch(error => this.logger.error('Cannot get all fleets', error))
  }

  scheduleUpdate () {
    this.conditionallyCancelUpdateTimeout()
    this.updateTimeout = setTimeout(() => this.getIssues(), UPDATE_INTERVAL_MS)
  }
  /* -----GET DATA END----- */

  /* -----FILTERS MANIPULATION START----- */
  switchDataSource (dataSourceType) {
    if (dataSourceType !== this.currentDataSourceType) {
      if (dataSourceType === DataSourceTypes.ACTIVE_ISSUES) {
        this.currentDataSourceType = DataSourceTypes.ACTIVE_ISSUES
        this.currentFilters = this.issueTrackerUiSettings.activeIssuesFilters
      } else if (dataSourceType === DataSourceTypes.RESOLVED_ISSUES) {
        this.currentDataSourceType = DataSourceTypes.RESOLVED_ISSUES
        this.currentFilters = this.issueTrackerUiSettings.resolvedIssuesFilters
      }
      this.selectedFleets = this.fleets.filter(fleet => this.currentFilters.selectedFleetIds.indexOf(fleet.id) > -1)

      this.paginationParams.skip = 0
      this.getIssues()
    }
  }

  onFleetFilterChanged (selectedFleets) {
    this.currentFilters.selectedFleetIds = selectedFleets.map(fleet => fleet.id)
    this.saveTrackerUiSettings()
    this.paginationParams.skip = 0
    this.getIssues()
  }

  onFiltersChanged () {
    this.saveTrackerUiSettings()
    this.paginationParams.skip = 0
    this.getIssues()
  }

  handleIssueLevelFilterClick (issueLevel, value) {
    this.paginationParams.skip = 0
    this.currentFilters.selectedIssueLevels = IssuesDashboardController.modifyTagsListDependingOnNewTagValue(this.currentFilters.selectedIssueLevels, issueLevel, value)
    this.getIssues()
  }

  handleIssueTypeFilterClick (issueType, value) {
    this.paginationParams.skip = 0
    this.currentFilters.selectedIssueTypes = IssuesDashboardController.modifyTagsListDependingOnNewTagValue(this.currentFilters.selectedIssueTypes, issueType, value)
    this.getIssues()
  }

  handleAllIssuesClick (value) {
    this.showAllIssues = value
    this.paginationParams.skip = 0
    this.getIssues()
  }

  resetFilters () {
    this.currentFilters.searchQuery = ''
    this.currentFilters.selectedBusinessId = ''
    this.currentFilters.selectedBranchId = ''
    this.saveTrackerUiSettings()
    this.paginationParams.skip = 0
    this.getIssues()
  }
  /* -----FILTERS MANIPULATION END----- */

  onPaginationUpdate (limit, offset) {
    this.paginationParams.limit = limit
    this.paginationParams.skip = offset
    this.getIssues()
  }

  openFilters () {
    this.$scope.$broadcast(issueTrackerEvents.OPEN_FILTERS)
  }

  saveTrackerUiSettings () {
    uiSettingsService.issueTrackerSettings = this.issueTrackerUiSettings
  }

  conditionallyCancelUpdateTimeout () {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout)
    }
  }

  static modifyTagsListDependingOnNewTagValue (tagList, tagName, tagValue) {
    const localTagList = tagList.slice()
    const tagIndexInList = tagList.indexOf(tagName)

    if (tagValue) {
      if (tagIndexInList < 0) {
        localTagList.push(tagName)
      }
    } else if (tagIndexInList >= 0) {
      localTagList.splice(tagIndexInList, 1)
    }

    return localTagList
  }
}

export default {
  templateUrl,
  controller: IssuesDashboardController
}
