import BaseFormController from './BaseFormController'
import cloneDeep from 'lodash-es/cloneDeep'
import ValidationUtils from 'common/utils/validationUtils'
import {StateService} from '@uirouter/core'

export default class ViewEditFormController extends BaseFormController {

  public isEditing: boolean = false
  public isFormChanged: boolean = false
  public backendModel: any = {}

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.$scope.$watch('$ctrl.model', (updatedEntity: any) => {
      this.isFormChanged = ValidationUtils.getIsEntityChanged(updatedEntity, this.backendModel)
    }, true)

    this.setDefaultModel()
  }

  setDefaultModel (): void {
    this.setModel(this.getDefaultModel())
  }

  getDefaultModel (): any {
    return {}
  }

  setModel (model: any): void {
    this.backendModel = cloneDeep(model)
    this.model = cloneDeep(model)
  }

  startEdit (): void {
    this.isEditing = true
  }

  cancelEdit (): void {
    this.model = cloneDeep(this.backendModel)
    this.isEditing = false
  }

  afterSubmitSuccess (backendModel: any): Promise<any> {
    if (backendModel) {
      this.backendModel = cloneDeep(backendModel)
      this.model = cloneDeep(backendModel)
    }
    this.isEditing = false
    this.isFormChanged = false
    return Promise.resolve()
  }
}
