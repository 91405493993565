import DBMappedNamedEntity from './DBMappedNamedEntity'
import DeliveryTimeSetting from './DeliveryTimeSetting'

export default class AreaDeliveryTimeSettings {
  constructor (
    public area: DBMappedNamedEntity,
    public deliveryTime: DeliveryTimeSetting
  ) {}

  static build(dto: any): AreaDeliveryTimeSettings {
    return new AreaDeliveryTimeSettings(
      DBMappedNamedEntity.build(dto.area || {}),
      DeliveryTimeSetting.build(dto.deliveryTime || {})
    )
  }
}
