import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

const tiersService = new CrudService(endpoints.TIERS)

export function tiersCollectionFactory (
  tiersService
) {
  return {
    getAvailableTiers: function () {
      return tiersService.get([], {useCache: true})
        .then(tiers => tiers.map(DBMappedNamedEntity.build))
    }
  }
}

export default tiersCollectionFactory(
  tiersService
)
