import Cost from '../Cost'

export default class StorefrontSubscriptionDefinition {
  constructor (
    public readonly chargeAttempts: number,
    public readonly trialPeriod: number,
    public readonly cost: Cost
  ) { }

  public static build(data: any = {}): StorefrontSubscriptionDefinition {

    return new StorefrontSubscriptionDefinition(
      data.chargeAttempts || 0,
      data.trialPeriod || 0,
      Cost.build(data.cost)
    )
  }
}
