import cloneDeep from 'lodash-es/cloneDeep'
import templateUrl from './service-form.pug'
import packageTypesCollection from 'data/collections/packageTypesCollection'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import servicesCollection from 'data/collections/servicesCollection'

const DEFAULT_SERVICE_ENTITY = {
  name: '',
  description: '',
  packages: [],
  logoUrl: ''
}

class serviceFormController extends CreateViewEditFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state, $stateParams, 'serviceId')
    this.availablePackages = []
    this.getAvailablePackagesTypes()
    if (this.isCreate) {
      this.isEditing = true
    }
  }

  getAvailablePackagesTypes () {
    return packageTypesCollection.getAll()
      .then(availablePackages => {
        this.availablePackages = availablePackages
        this.$scope.$digest()
      })
      .catch(err => this.logger.error('Cannot get package types', err))
  }

  getEntity () {
    const namedEntityId = this.$stateParams[this.entityIdParamName]
    const serviceId = (namedEntityId || this.$stateParams.id)

    return servicesCollection.get(serviceId)
      .then(entity => {
        this.logger.info(`Retrieved service for id ${serviceId}:`, entity)
        this.backendModel = entity
        this.setDefaultModel()
        this.$scope.$digest()
      })
      .catch(error => this.logger.error('Could not get service:', error))
  }

  getDefaultModel () {
    return this.backendModel || cloneDeep(DEFAULT_SERVICE_ENTITY)
  }

  onPackagesUpdate (updatedPackages) {
    this.model.packages = updatedPackages
  }

  updateLogoUrl (logoUrl) {
    this.model.logoUrl = logoUrl
  }

  beforeSubmit () {
    if (this.isCreate) {
      return servicesCollection.create(this.model)
    }

    return servicesCollection.update(this.model.id, this.model)
  }

  afterSubmitSuccess () {
    this.$state.go('main.logistics.services')
  }
}

export default {
  templateUrl,
  controller: serviceFormController
}
