import moment from 'moment'
import cloneDeep from 'lodash-es/cloneDeep'
import BaseController from './BaseController'

export default class TableWithFiltersController extends BaseController {
  constructor ($scope, $state, $stateParams, $filter, preventInitialSync = false) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.$filter = $filter

    this.dateRange = null
    this.searchQuery = ''
    this.entityFilter = ''
    this.entityFilterIds = []
    this.entityFilterNamesByIds = {}

    this.rows = []
    this.displayedRows = []

    this.sorting = this.getSorting()
    this.formatters = this.getFormatters()

    this.defaultSortingKey = null
    this.defaultSortingDirection = null

    $scope.$watchGroup([
      '$ctrl.dateRange',
      '$ctrl.searchQuery',
      '$ctrl.entityFilter'
    ], () => this.handleFiltersUpdate())

    if (!preventInitialSync) {
      this.sync()
    }
  }

  onSync () {
    return Promise.all([
      this.getFilterLookups(),
      this.getRowLookups(),
      this.syncRows()
    ]).then(() => this.syncFilterValues())
  }

  syncRows () {
    return this.getRows()
      .then(rows => this.handleRows(rows))
      .catch(err => this.logger.error('Cannot get rows', err))
  }

  getRows () { // Required to re-define in your controller
    return Promise.resolve([])
  }

  handleRows (rows) {
    this.rows = rows
    this.displayedRows = cloneDeep(rows)
  }

  syncFilterValues () {
    // Optionally re-define in your controller
  }

  getSorting () { // Required to re-define in your controller
    return {}
  }

  getFormatters () { // Required to re-define in your controller
    return {}
  }

  getFilterLookups () { // Required to re-define in your controller
    return []
  }

  getRowLookups () { // Optionally re-define in your controller
    return Promise.resolve()
  }

  handleFiltersUpdate () {
    if (this.rows) {
      this.displayedRows = this.rows.filter(row => this.checkItemWithFilterValues(row))
    }
  }

  checkItemWithFilterValues (item) {
    const formatters = this.getFormatters()

    const isDateMatched = this.checkByDateFilter(item, formatters)
    const isEntityMatched = this.checkByEntityFilter(item, formatters)
    const isSearchQueryMatched = this.checkBySearchFilter(item, formatters)

    return isDateMatched && isEntityMatched && isSearchQueryMatched
  }

  checkByDateFilter (item) {
    let isDateMatched = true

    if (this.dateRange && item.date) {
      if (this.dateRange.startDate) {
        const isNotEarlierThanStartDate = moment(this.dateRange.startDate).unix() <= moment(item.date).unix()
        isDateMatched = isDateMatched && isNotEarlierThanStartDate
      }

      if (this.dateRange.endDate) {
        const isNotLaterThanEndDate = moment(item.date).unix() <= moment(this.dateRange.endDate).unix()
        isDateMatched = isDateMatched && isNotLaterThanEndDate
      }
    }

    return isDateMatched
  }

  checkByEntityFilter () { // Required to re-define in your controller
    return true
  }

  checkBySearchFilter (item, formatters) {
    let isSearchQueryMatched = true

    if (this.searchQuery !== '') {
      isSearchQueryMatched = false
      Object.keys(item).forEach(key => {
        let formattedFieldValue = formatters && formatters[key] ? formatters[key](item[key]) : item[key]

        if (typeof formattedFieldValue !== 'string') {
          formattedFieldValue = ''
        }

        if (formattedFieldValue.toLocaleLowerCase().search(this.searchQuery.toLocaleLowerCase()) >= 0) {
          isSearchQueryMatched = true
        }
      })
    }

    return isSearchQueryMatched
  }

  onSearchFieldChange (searchQuery) {
    this.searchQuery = searchQuery
  }
}
