import groupBy from 'lodash-es/groupBy'
import orderBy from 'lodash-es/orderBy'
import KanbanDeliveryResponse from './KanbanDeliveryResponse'

export default class GroupedDeliveries {

  public notReady: KanbanDeliveryResponse[] = []
  public pending: KanbanDeliveryResponse[] = []
  public allocating: KanbanDeliveryResponse[] = []
  public allocated: KanbanDeliveryResponse[] = []
  public atPickup: KanbanDeliveryResponse[] = []
  public collected: KanbanDeliveryResponse[] = []
  public atDropoff: KanbanDeliveryResponse[] = []
  public delivered: KanbanDeliveryResponse[] = []
  public issue: KanbanDeliveryResponse[] = []
  public cancelled: KanbanDeliveryResponse[] = []

  constructor (
    deliveries: KanbanDeliveryResponse[] = []
  ) {
    const deliveriesGroupedByStatus = groupBy(deliveries, 'status')

    this.notReady = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.notReady)
    this.pending = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.pending)
    this.allocating = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.allocating)
    this.allocated = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.allocated)
    this.atPickup = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.atPickup)
    this.collected = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.collected)
    this.atDropoff = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.atDropoff)
    this.delivered = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.delivered)
    this.issue = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.issue)
    this.cancelled = GroupedDeliveries.orderByUpdatedDesc(deliveriesGroupedByStatus.cancelled)
  }

  static orderByUpdatedDesc (deliveries: KanbanDeliveryResponse[] = []): KanbanDeliveryResponse[] {
    // NB. We use statusUpdatedAt because 'updatedAt' is effectively random.
    return orderBy(deliveries, 'statusUpdatedAt', 'desc')
  }
}
