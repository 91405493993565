import {urlToOriginWithPath} from 'common/utils/urlUtils'
import Cost from './Cost'

export default class PaymentWithRedirection {
  public amount: any
  public returnUrl: string

  constructor (
    cost: Cost = Cost.build(),
    paymentProcessedPath: string = ''
  ) {
    this.amount = {
      currencyCode: cost.currencyCode,
      value: cost.amount
    }

    this.returnUrl = urlToOriginWithPath(paymentProcessedPath)
  }
}
