import Cost from './Cost'
import BalanceAccount from './BalanceAccount'

export default class EarningSummary {
  constructor(
    public balanceAccounts: BalanceAccount[],
    public currentBalance: Cost,
    public lastPayout: any
  ) {}

  static build(dto: any): EarningSummary {
    return new EarningSummary(
      (dto.balanceAccounts || []).map(BalanceAccount.build),
      Cost.build(dto.currentBalance || {}),
      dto.lastPayout || null
    )
  }
}
