class MatchIndicator {

  // bindings
  public message: string
  public value: string
  public matchValue: string

  // properties
  public matchStatusBit: number

  constructor () {
    /**
     * 0 - red (empty value)
     * 1 - no match
     * 2 - match
     */
    this.matchStatusBit = 0
  }

  $onChanges (): void {
    this.calculateMatch()
  }

  private calculateMatch (): void {
    if (!this.value) {
      this.matchStatusBit = 0
    } else {
      this.matchStatusBit = (this.value.indexOf(this.matchValue) > -1) ? 2 : 1
    }
  }
}

export default {
  templateUrl: require('./match-indicator.pug'),
  controller: MatchIndicator,
  bindings: {
    message: '<',
    value: '<',
    matchValue: '<'
  }
}
