import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import Area from 'data/domain-objects/Area'

const areaService = new CrudService(endpoints.AREA)
const areasService = new CrudService(endpoints.AREAS)
const addressAreasService = new CrudService(endpoints.ADDRESS_AREAS)
const storefrontAddressAreasService = new CrudService(endpoints.STOREFRONT_ADDRESS_AREAS)

export function areasCollectionFactory (
  areaService,
  areasService,
  addressAreasService
) {
  const useCacheParams = {useCache: true}

  return {
    getAll: () => {
      return areasService.get()
    },
    getAllCached: () => {
      return areasService.get([], useCacheParams)
    },
    getArea: areaId => {
      return areaService.get([areaId])
        .then(area => Area.build(area))
    },
    createArea: area => {
      return areaService.create(area.toPostJSON())
    },
    updateArea: (areaId, area) => {
      return areaService.update(area.toPutJSON(), [areaId])
        .then(area => Area.build(area))
    },
    'delete': areaId => {
      return areaService.delete([areaId])
    },
    getRealGeographicalAreasForStorefrontCached: () => {
      return storefrontAddressAreasService.get([], {useCache: true})
        .then(areas => areas.map(DBMappedNamedEntity.build)
          .sort((areaA, areaB) => {
            const nameA = areaA.name.toLowerCase()
            const nameB = areaB.name.toLowerCase()
            if (nameA < nameB) {
              return -1
            } else if (nameA > nameB) {
              return 1
            }
            return 0
          })
        )
    },
    getRealGeographicalAreasCached: () => {
      return addressAreasService.get([], {useCache: true})
        .then(areas => areas.map(DBMappedNamedEntity.build)
          .sort((areaA, areaB) => {
            const nameA = areaA.name.toLowerCase()
            const nameB = areaB.name.toLowerCase()
            if (nameA < nameB) {
              return -1
            } else if (nameA > nameB) {
              return 1
            }
            return 0
          })
        )
    }
  }
}

export default areasCollectionFactory(
  areaService,
  areasService,
  addressAreasService,
)
