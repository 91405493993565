import PhoneNumber from '../PhoneNumber'

export default class StorefrontOrderContactDetails extends PhoneNumber {
  constructor(
    public countryDiallingCode: string = '',
    public phoneNumber: string = '',
    public firstName: string = '',
    public lastName: string = ''
  ) {
    super(countryDiallingCode, phoneNumber)
  }

  static build(dto: any = {}): StorefrontOrderContactDetails {
    return new StorefrontOrderContactDetails(
      dto.countryDiallingCode,
      dto.phoneNumber,
      dto.firstName,
      dto.lastName
    )
  }

  getFullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  getFullNameAndPhoneNumber(): string {
    return `${this.getFullName()} (${this.getFormattedPhone()})`
  }
}
