import templateUrl from './editable-list-item-radio.pug'

class EditableListItemRadioController {
  onValueChanged (value) {
    if (this.onChange) {
      this.onChange({value})
    }
  }
}

export default {
  controller: EditableListItemRadioController,
  templateUrl,
  bindings: {
    title: '@',
    name: '@',
    values: '=',
    disabledValues: '<',
    labelKeys: '=',
    labelKeysPrefix: '@',
    model: '=',
    form: '=',
    isEditing: '=',
    isRequired: '=',
    isDisabled: '=',
    valueMessage: '@',
    onChange: '&?'
  }
}
