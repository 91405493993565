import templateUrl from './editable-list-item.pug'

export default {
  templateUrl,
  bindings: {
    backendValidationModel: '=',
    decimalPointsCount: '=',
    form: '=',
    isEditing: '<',
    isDisabled: '<',
    model: '=',
    name: '@',
    placeholder: '@',
    prefix: '@',
    isRequired: '<',
    suffix: '@',
    title: '@',
    type: '@',
    valueMessage: '@',
    valueWarning: '@',
    regexValidatePattern: '<?',
    hostClass: '@?',
    onBlur: '&?',
    minlength: '<?'
  }
}
