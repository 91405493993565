import { StateService } from '@uirouter/core'

enum CategorieLayoutModes {
  TEXT = 'text',
  TILES = 'tiles',
  WIDE_IMAGE = 'wideImages'
}

enum MenuLayoutModeOptionType {
  ACCORDION = 'accordion',
  CENTERED_ACCORDION = 'centeredAccordion',
  HORIZONTAL = 'horizontal'
}

enum ProductLayoutType {
  WIDE = 'wide',
  NARROW_LEFT = 'narrowLeft',
  NARROW_RIGHT = 'narrowRight',
  TILES = 'tiles'
}

const MENU_LAYOUT_MODE_OPTIONS: MenuLayoutModeOptionType[] = [
  MenuLayoutModeOptionType.ACCORDION,
  MenuLayoutModeOptionType.CENTERED_ACCORDION,
  MenuLayoutModeOptionType.HORIZONTAL
]


class BusinessStorefrontMenuDisplaySettingsController {
  // inputs
  public allSettings: any
  public form: ng.IFormController
  public onChange: (payload: {value: string}) => void

  // props
  public categoriesLayoutModeOptions: ISelectWithImageOption[] = []
  public categoriesLayoutModes: typeof CategorieLayoutModes = CategorieLayoutModes
  public menuLayoutModeOptions: MenuLayoutModeOptionType[] = MENU_LAYOUT_MODE_OPTIONS
  public productLayoutTypes: typeof ProductLayoutType = ProductLayoutType
  public productLayoutOptions: ISelectWithImageOption[] = []
  public showSubCategoriesWarning: boolean = false


  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
  ) {
    this.categoriesLayoutModeOptions = [
      { value: CategorieLayoutModes.TEXT, iconId: CategorieLayoutModes.TEXT },
      { value: CategorieLayoutModes.TILES, iconId: CategorieLayoutModes.TILES },
      { value: CategorieLayoutModes.WIDE_IMAGE, iconId: CategorieLayoutModes.WIDE_IMAGE }
    ]

    this.productLayoutOptions = [
      { value: ProductLayoutType.NARROW_LEFT, iconId: ProductLayoutType.NARROW_LEFT },
      { value: ProductLayoutType.NARROW_RIGHT, iconId: ProductLayoutType.NARROW_RIGHT },
      { value: ProductLayoutType.WIDE, iconId: ProductLayoutType.WIDE },
      { value: ProductLayoutType.TILES, iconId: ProductLayoutType.TILES }
    ]
  }

  public $onChanges(): void {
    this.showSubCategoriesWarning = this.allSettings && this.allSettings.appearance__categoriesLayoutMode === CategorieLayoutModes.TEXT
  }

  public setCategoriesLayoutMode(value: CategorieLayoutModes): void {
    this.allSettings.appearance__categoriesLayoutMode = value
    this.showSubCategoriesWarning = value === CategorieLayoutModes.TEXT
    this.onChange({value})
  }


  public setProductLayoutType(value: ProductLayoutType): void {
    this.allSettings.appearance__productsLayoutMode = value
    this.onChange({value})
  }

}

export default {
  controller: BusinessStorefrontMenuDisplaySettingsController,
  templateUrl: require('./business-storefront-menu-display-settings.pug'),
  bindings: {
    form: '<',
    allSettings: '<',
    translationsKeyPrefix: '@',
    onChange: '&',
    isDisabled: '<?',
    isEditing: '<?'
  }
}
