import find from 'lodash-es/find'
import groupBy from 'lodash-es/groupBy'
import uniq from 'lodash-es/uniq'
import flatten from 'lodash-es/flatten'
import endpoints from 'common/endpoints'
import Logger from 'common/Logger'
import CrudService from 'data/services/CrudService'
import Job from 'data/domain-objects/Job'
import Task from 'data/domain-objects/Task'
import Address from 'data/domain-objects/Address'
import Cost from 'data/domain-objects/Cost'
import deliveriesCollection from './deliveriesCollection'
import * as PaymentMethods from 'common/constants/PaymentMethods'

const jobService = new CrudService(endpoints.JOB)
const logger = new Logger('Job Collection')

export function jobCollectionFactory () {
  function get (id) {
    return jobService.get([id])
      .then(jobDto => {
        return getDeliveriesForJob(jobDto)
          .then(deliveries => {
            return castToJobDO(jobDto, deliveries)
          })
      })
  }

  function getDeliveriesForJob (jobDto) {
    const deliveryIds = getDeliveryIdsForJob(jobDto)
    return Promise.all(deliveryIds.map(id => deliveriesCollection.getById(id)))
  }

  function castToJobDO (jobDto, deliveries) {
    const tasks = getTasksForJob(jobDto)
    return Job.build(jobDto, tasks, deliveries)
  }

  function getTasksForJob (jobDto) {
    const doneTasks = (jobDto.completedTasks || [])
    const todoTasks = jobDto.tasks || []
    const inProgressTasks = todoTasks.splice(0, 1) // First 'to do' is in progress. Take it out.

    const doneTaskDOs = doneTasks.map(task => getTaskDO(task, jobDto, Task.STATUS.DONE))
    const inProgressTaskDOs = inProgressTasks.map(task => getTaskDO(task, jobDto, Task.STATUS.IN_PROGRESS))
    const todoTaskDOs = todoTasks.map(task => getTaskDO(task, jobDto, Task.STATUS.TO_DO))

    return doneTaskDOs.concat(inProgressTaskDOs).concat(todoTaskDOs).filter(task => task !== null)
  }

  function getTaskDO (task, jobDto, status) {
    const pointDto = jobDto.points[task.point]
    if (!pointDto) {
      logger.warn('Point DTO could not be found in job for task:', task, jobDto)
      return null
    } else { // Free Ride points may not have address es or etas
      const address = pointDto.address ? Address.build(pointDto.address) : null
      const eta = address ? find(jobDto.etas, {id: address.id}) || null : null
      const partyName = pointDto.name.length ? pointDto.name[0].value : '' // 'name' is an array of name descriptors
      const deliveryDescriptors = pointDto.deliveries
      const costs = getSummedCostsForDeliveryDescriptors(deliveryDescriptors)
      const cashOffSystem = isCacheOffSystem(task.action, deliveryDescriptors)
      return Task.build(task, status, address, eta, partyName, deliveryDescriptors, costs, cashOffSystem)
    }
  }

  function isCacheOffSystem (action, deliveryDescriptors) {
    if (action === 'credit') {
      for (let i = 0, len = deliveryDescriptors.length; i < len; i++) {
        if (deliveryDescriptors[i].payment.cashOffSystem) {
          return true
        }
      }
    }
    return false
  }

  function getDeliveryIdsForJob (jobDto) {
    // Deliveries are on the job points in an array. They may be duplicated across points so we de-dupe.
    const nestedDeliveryIds = Object.keys(jobDto.points).map(pointName => {
      const point = jobDto.points[pointName]
      return point.deliveries.map(delivery => delivery.id)
    })
    return uniq(flatten(nestedDeliveryIds))
  }

  function getSummedCostsForDeliveryDescriptors (deliveryDescriptors) {
    // Returns an array of costs, with one cost per currency code,
    // where the amount is the sum of costs for the currency code.
    const allCosts = deliveryDescriptors
      // hack for DP-4076
      .map(delivery => {
        if (delivery.payment.cashOffSystem) {
          delivery.payment.method = PaymentMethods.CASH_OFF_SYSTEM
        }
        return delivery
      })
      .filter(delivery => delivery.payment.value && delivery.payment.method !== PaymentMethods.CARD && delivery.payment.method !== PaymentMethods.CASH_OFF_SYSTEM) // There may not be a value
      .map(delivery => Cost.build(delivery.payment.value))

    const costsByCurrencyCode = groupBy(allCosts, 'currencyCode')
    return Object.keys(costsByCurrencyCode).map(currencyCode => {
      return sumCosts(costsByCurrencyCode[currencyCode], currencyCode)
    })
  }

  function sumCosts (costs, currencyCode) {
    const totalAmount = costs.reduce((accum, cost) => {
      return accum + cost.amount
    }, 0)
    return Cost.build({amount: totalAmount, currencyCode})
  }

  return {
    get
  }
}

export default jobCollectionFactory()
