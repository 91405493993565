import groupBy from 'lodash-es/groupBy'
import templateUrl from './next-day-linked-dashboard.pug'
import BaseController from 'presentation/common/BaseController'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import * as CHECK_STATE_BITS from 'common/constants/CheckStates'

const INBOUND = 'in'

const LIMIT_PER_PAGE = 20

class NextDayLinkedDashboard extends BaseController {
  constructor ($scope, $filter, $rootScope) {
    super($scope)
    this.$rootScope = $rootScope

    this.skip = 0
    this.limit = LIMIT_PER_PAGE

    this.areasFilter = []
    this.inOutFilter = INBOUND
    this.timeSlotsCheckLinksVisibility = {}
    this.areasCheckLinksVisibility = {}
    this.deliveriesCountPerTimeSlot = {}
    this.deliveryToPrint = {}
    this.deliveriesTotalCount = null
    this.showTransferDeliveriesError = false
    this.selectedDeliveriesCount = 0
    this.otherLabel = $filter('translate')('COMMON.OTHER')

    this.onTransfer = () => this.sync()
  }

  $onInit () {
    this.$scope.$watch('$ctrl.isAssignedFilter', () => this.sync())
    this.sync()
  }

  onSearchFilterChange (value) {
    this.searchFilter = value
  }

  onAreasFilterChange (areas) {
    this.areasFilter = areas.map(area => area.id)
    this.sync()
  }

  onInOutFilterChange (value) {
    this.inOutFilter = value
    this.sync()
  }

  onSync () {
    this.showTransferDeliveriesError = false

    return deliveriesCollection.getNextDayLinkedDeliveries({
      start: (new Date(0)).toISOString(),
      finish: null,
      skip: this.skip,
      limit: this.limit,
      type: this.inOutFilter,
      areaIds: this.areasFilter && this.areasFilter.join && this.areasFilter.join(','),
      driverAssigned: !!this.isAssignedFilter
    })
      .then(response => {
        this.deliveriesTotalCount = response.metadata.count
        this.groupedRecords = this.groupRecords(response.records)
        this.recalculateCheckLinksVisibility()
      })
  }

  changeOffset (offset, limit) {
    this.limit = limit
    this.skip = offset
    this.sync()
  }

  groupRecords (records) {
    const recordsGroupedByPickupSlot = groupBy(records, record => {
      const dateObject = new Date(record.pickupTime)
      dateObject.setMinutes(0)
      dateObject.setSeconds(0)
      dateObject.setMilliseconds(0)
      const sanitizedPickupTimeISOString = dateObject.toISOString()
      return sanitizedPickupTimeISOString || this.otherLabel
    })
    const fullyGroupedRecords = {}
    Object.keys(recordsGroupedByPickupSlot).forEach(pickupSlot => {
      this.deliveriesCountPerTimeSlot[pickupSlot] = recordsGroupedByPickupSlot[pickupSlot].length
      fullyGroupedRecords[pickupSlot] = groupBy(recordsGroupedByPickupSlot[pickupSlot], record => record.area)
    })

    return fullyGroupedRecords
  }

  getFlattenedDeliveries () {
    let deliveries = []
    Object.keys(this.groupedRecords).forEach(timeSlotKey => {
      Object.keys(this.groupedRecords[timeSlotKey]).forEach(areaName => {
        deliveries = deliveries.concat.apply(deliveries, this.groupedRecords[timeSlotKey][areaName])
      })
    })

    return deliveries
  }

  assignSelected () {
    this.showTransferDeliveriesError = false

    const deliveriIds = this.getFlattenedDeliveries()
      .filter(delivery => delivery.checked)
      .map(delivery => delivery.id)

    this.$rootScope.$broadcast(DELIVERY_ACTIONS.TRANSFER_DELIVERIES, deliveriIds)
  }

  onDeliveryCheck (timeSlot, areaName, index, value) {
    this.groupedRecords[timeSlot][areaName][index].checked = value
    this.recalculateCheckLinksVisibility()
  }

  checkAllDeliveriesForTimeSlotAndArea (timeSlot, area) {
    this.groupedRecords[timeSlot][area].forEach(delivery => delivery.checked = true)
    this.recalculateCheckLinksVisibility()
  }

  uncheckAllDeliveriesForTimeSlotAndArea (timeSlot, area) {
    this.groupedRecords[timeSlot][area].forEach(delivery => delivery.checked = false)
    this.recalculateCheckLinksVisibility()
  }

  checkAllDeliveriesForTimeSlot (timeSlot) {
    Object.keys(this.groupedRecords[timeSlot]).forEach(area => {
      this.checkAllDeliveriesForTimeSlotAndArea(timeSlot, area)
    })
  }

  uncheckAllDeliveriesForTimeSlot (timeSlot) {
    Object.keys(this.groupedRecords[timeSlot]).forEach(area => {
      this.uncheckAllDeliveriesForTimeSlotAndArea(timeSlot, area)
    })
  }

  recalculateCheckLinksVisibility () { // It's a kind of magic... *sing in fake mustaches* ...Maaaagiiiiiic!
    const timeSlots = Object.keys(this.groupedRecords)
    this.selectedDeliveriesCount = 0

    timeSlots.forEach(timeSlot => {
      const areas = Object.keys(this.groupedRecords[timeSlot])

      let deliveriesInTimeSlot = 0
      let checkedDeliveriesInTimeSlot = 0

      this.areasCheckLinksVisibility[timeSlot] = {}

      areas.forEach(area => {
        const deliveriesInArea = this.groupedRecords[timeSlot][area]
        const checkedDeliveriesInArea = deliveriesInArea.filter(delivery => delivery.checked).length

        this.selectedDeliveriesCount += checkedDeliveriesInArea

        deliveriesInTimeSlot += deliveriesInArea.length
        checkedDeliveriesInTimeSlot += deliveriesInArea.filter(delivery => delivery.checked).length

        this.areasCheckLinksVisibility[timeSlot][area] = NextDayLinkedDashboard.calculateVisibilityBit(deliveriesInArea.length, checkedDeliveriesInArea)
      })

      this.timeSlotsCheckLinksVisibility[timeSlot] = NextDayLinkedDashboard.calculateVisibilityBit(deliveriesInTimeSlot, checkedDeliveriesInTimeSlot)
    })
  }

  static calculateVisibilityBit (totalItemsCount, checkedItemsCount) {
    if (checkedItemsCount === 0) {
      return CHECK_STATE_BITS.NOTHING_CHECKED
    } else if (checkedItemsCount !== totalItemsCount) {
      return CHECK_STATE_BITS.PARTIALLY_CHECKED
    } else {
      return CHECK_STATE_BITS.ALL_CHECKED
    }
  }

  printDelivery (delivery) {
    if (this.deliveryToPrint && this.deliveryToPrint.id === delivery.id) {
      window.print()
    } else {
      this.deliveryToPrint = delivery
    }
  }

  onTransferFailed (error) {
    this.logger.error('Cannot transfer deliveries', error)
    this.showTransferDeliveriesError = true
  }
}

export default {
  templateUrl,
  controller: NextDayLinkedDashboard
}
