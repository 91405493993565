import templateUrl from './request-password-reset.pug'
import authService from 'data/services/authService'
import BaseController from 'presentation/common/BaseController'

class RequestPasswordResetController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
  }

  $onInit () {
    this.credential = { // Set credentials if passed
      email: this.$state.params.email || '',
      phoneNumber: this.$state.params.phoneNumber || '',
      countryDiallingCode: this.$state.params.countryDiallingCode || ''
    }
  }

  onCredentialChanged (credential) {
    this.credential = credential
    this.requestFailed = false
  }

  submit () {
    authService.requestPasswordReset(this.credential)
      .then(() => this.handleSuccessfulRequest())
      .then(() => this.redirectToChangePassword())
      .catch(err => this.handleFailedRequest(err))
      .then(() => this.$scope.$digest())
  }

  handleSuccessfulRequest () {
    this.logger.info('Request Password Reset Succeeded')
    this.requestFailed = false
  }

  handleFailedRequest (err) {
    this.logger.info('Request Password Reset Failed', err)
    this.requestFailed = true
  }

  redirectToChangePassword () {
    this.$state.go('auth.reset-password')
  }
}

export default {
  templateUrl,
  controller: RequestPasswordResetController
}
