import { StorefrontSubscriptionStatuses } from 'common/constants/StorefrontSubscriptionStatuses'
import { StorefrontSubscriptionPlans } from 'common/constants/StorefrontSubscriptionPlans'
import storefrontSubscriptionsCollection from 'data/collections/storefrontSubscriptionsCollection'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import { CONFIRM_PLAN_CHANGE_DIALOG_ID } from './confirm-plan-change-dialog/confirmPlanChangeDialog'
import StorefrontBusinessWithSubscription from 'data/domain-objects/storefront/StorefrontBusinessWithSubscription'

enum ErrorCodes {
  NOT_ENOUGH_MONEY = 'ERR_ENTERPRISE_WEB_SUBSCRIPTION_NOT_ENOUGH_MONEY',
  CHARGE_NOT_ALLOWED = 'ERR_ENTERPRISE_WEB_SUBSCRIPTION_MANUALLY_CHARGE_NOT_ALLOWED',
  UNKNOWN = 'UNKNONW'
}


class SubscriptionsControlController {

  // bindings
  public business: StorefrontBusinessWithSubscription
  public subscriptionCreated: (value: {businessWithSubscription: StorefrontBusinessWithSubscription}) => void
  public subscriptionCancelled: (value: {businessWithSubscription: StorefrontBusinessWithSubscription}) => void
  public userChargedManually: (value: {businessWithSubscription: StorefrontBusinessWithSubscription}) => void

  // properties
  public storefrontSubscriptionStatuses: typeof StorefrontSubscriptionStatuses = StorefrontSubscriptionStatuses
  public subscriptionPlans: typeof StorefrontSubscriptionPlans = StorefrontSubscriptionPlans

  public selectedPlan: StorefrontSubscriptionPlans
  public activatedPlan: StorefrontSubscriptionPlans

  public businesessSuggestedSubscriptionsPlan: string[] = []

  // Errors
  public isError: boolean = false
  public isNotEnoughMoneyError: boolean = false
  public isUnknownError: boolean = false
  public isChargeNotAllowedError: boolean = false
  private onCloseModalDialog: IPubSubscription = modalDialogStateService.onCloseModalDialog((payload: any) => {
    if (payload && payload.businessId === this.business.businessId) {
      this.subscribe()
    }
  })

  constructor (
    public $scope: ng.IScope
  ) { }

  get showTrial(): boolean {
    return this.business.status === StorefrontSubscriptionStatuses.DISABLED && !this.business.subscription.plan
  }

  get showChargeFailed(): boolean {
    return (
         this.business.status === this.storefrontSubscriptionStatuses.DISABLED
      && !!this.business.subscription.plan
      && this.business.subscription.isChargeFailed
    )
  }

  get showNextCharge(): boolean {
    return (
         this.business.status === this.storefrontSubscriptionStatuses.ACTIVE
      || this.business.status === this.storefrontSubscriptionStatuses.PENDING
    )
    && !!this.business.subscription.plan
    && !!this.business.subscription.nextPlanChargeDate
  }

  get showActiveUntil(): boolean {
    return this.business.status === this.storefrontSubscriptionStatuses.ACTIVE
        && !!this.business.subscription.plan
        && !this.business.subscription.nextPlanChargeDate
        && !!this.business.subscription.activeUntilDate
  }

  get showSelectPlanLabel(): boolean {
    return !this.business.subscription.plan && this.business.status === StorefrontSubscriptionStatuses.DISABLED
  }

  get showPaidLabel(): boolean {
    return this.business.status === StorefrontSubscriptionStatuses.ACTIVE && !!this.business.subscription.nextPlanChargeDate
  }

  get showCancelledLabel(): boolean {
    return this.business.status === StorefrontSubscriptionStatuses.ACTIVE && !this.business.subscription.nextPlanChargeDate
  }

  get showStorefrontDisabledLabel(): boolean {
    return !!this.business.subscription.plan && this.business.status === StorefrontSubscriptionStatuses.DISABLED
  }

  get showNotEnoughFoundsLabel(): boolean {
    return this.business.status === StorefrontSubscriptionStatuses.PENDING
  }

  get showTopUpButton(): boolean {
    return this.business.status === StorefrontSubscriptionStatuses.PENDING ||
           !!this.business.subscription.plan && this.business.status === StorefrontSubscriptionStatuses.DISABLED
  }

  get showChargeButton(): boolean {
    return this.business.status === StorefrontSubscriptionStatuses.PENDING
  }

  get showRestore(): boolean {
    return  this.activatedPlan === this.selectedPlan &&
            (    this.business.subscription.isCancelled
              || (this.business.subscription.isChargeFailed && this.business.status === StorefrontSubscriptionStatuses.DISABLED)
            )
  }

  get showSave(): boolean {
    return !this.showRestore
  }

  $onChanges (simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.business) {
      this.activatedPlan = this.selectedPlan = this.business.subscription.plan
      this.businesessSuggestedSubscriptionsPlan = Object.keys(this.business.subscriptionPlans)
    }
  }

  $onDestroy(): void {
    this.onCloseModalDialog.remove()
  }

  private resetErrors(): void {
    this.isError = false
    this.isNotEnoughMoneyError = false
    this.isUnknownError = false
  }

  public selectPlan (plan: StorefrontSubscriptionPlans): void {
    this.resetErrors()
    this.selectedPlan = plan
  }

  private openPlanChangeConfirmationPopUp(): void {
    modalDialogStateService.emitOpenModalDialog({
      dialogId: CONFIRM_PLAN_CHANGE_DIALOG_ID,
      businessId: this.business.businessId,
      titleKey: 'COMPONENTS.CONFIRM_PLAN_CHANGE_DIALOG.CHANGE_CONFIRMATION.TITLE',
      messageKey: 'COMPONENTS.CONFIRM_PLAN_CHANGE_DIALOG.CHANGE_CONFIRMATION.MESSAGE'
    })
  }

  public openRestorePlanPopUp(): void {
    modalDialogStateService.emitOpenModalDialog({
      dialogId: CONFIRM_PLAN_CHANGE_DIALOG_ID,
      businessId: this.business.businessId,
      titleKey: 'COMPONENTS.CONFIRM_PLAN_CHANGE_DIALOG.RESTORE_CONFIRMATION.TITLE',
      messageKey: 'COMPONENTS.CONFIRM_PLAN_CHANGE_DIALOG.RESTORE_CONFIRMATION.MESSAGE'
    })
  }

  public confirmAndRestore(): void {
    if (this.activatedPlan) {
      this.openRestorePlanPopUp()
    }
  }

  public confirmAndSubscribe(): void {
    if (this.activatedPlan) {
      this.openPlanChangeConfirmationPopUp()
    } else {
      this.subscribe()
    }
  }

  private subscribe(): void {
    if (this.selectedPlan) {
      this.resetErrors()
      storefrontSubscriptionsCollection.subscribe(this.selectedPlan, this.business.businessId)
      .then((result: StorefrontBusinessWithSubscription) => {
        this.subscriptionCreated({businessWithSubscription: result})
      })
      .catch((error: any) => {
        this.isError = true
        if (error.code === ErrorCodes.NOT_ENOUGH_MONEY) {
          this.isNotEnoughMoneyError = true
        } else if (error.code === ErrorCodes.CHARGE_NOT_ALLOWED) {
          this.isChargeNotAllowedError = true
        } else {
          this.isUnknownError = true
        }
      })
      .finally(() => {
        this.$scope.$digest()
      })
    }
  }

  public chargeMeNow(): void {
    this.resetErrors()
    storefrontSubscriptionsCollection.chargeMeNow(this.business.businessId)
    .then((result: StorefrontBusinessWithSubscription) => {
      this.userChargedManually({businessWithSubscription: result})
    })
    .catch((error: any) => {
      this.isError = true
      if (error.code === ErrorCodes.NOT_ENOUGH_MONEY) {
        this.isNotEnoughMoneyError = true
      } else if (error.code === ErrorCodes.CHARGE_NOT_ALLOWED) {
        this.isChargeNotAllowedError = true
      } else {
        this.isUnknownError = true
      }
    })
    .finally(() => {
      this.$scope.$digest()
    })
  }

  public cancelCurrentSubscription(): void {
    this.resetErrors()
    storefrontSubscriptionsCollection.cancelCurrentSubscription(this.business.businessId)
    .then((result: StorefrontBusinessWithSubscription) => {
      this.subscriptionCancelled({businessWithSubscription: result})
    })
  }

}

export default {
  controller: SubscriptionsControlController,
  templateUrl: require('./subscriptions-control.pug'),
  bindings: {
    business: '<',
    subscriptionCreated: '&',
    subscriptionCancelled: '&',
    userChargedManually: '&'
  }
}
