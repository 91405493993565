import differenceWith from 'lodash-es/differenceWith'
import pull from 'lodash-es/pull'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import areasCollection from 'data/collections/areasCollection'
import * as AreasSettingsConstants from 'common/constants/AreasSettings'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
import { StateService } from '@uirouter/core'

const SETTINGS_KEYS = [
  'showAreas',
  'selectedAreas'
]

class AreasSettingsController extends SettingsGroupController {
  /* Bindings START */
  public form: any
  public model: any
  public isEditing: boolean
  /* Bindings END */

  public availableAreas: DBMappedNamedEntity[]
  public allAreas: DBMappedNamedEntity[]
  public visibilityDropdownValues: string[]

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state, SETTINGS_KEYS)
    this.availableAreas = []
    this.visibilityDropdownValues = [AreasSettingsConstants.SHOW_AREA_SETTINGS_SHOW_ALL, AreasSettingsConstants.SHOW_AREA_SETTINGS_SHOW_SELECTED]
    this.getAreas()
  }

  removeArea = (area: string): void => {
    if (!this.useDefault) {
      this.model.selectedAreas.value = pull(this.model.selectedAreas.value, area)
      this.handleAreas()
    }
  }

  getAreas = (): void => {
    areasCollection.getRealGeographicalAreasCached()
      .then((areas: DBMappedNamedEntity[]) => {
        this.allAreas = areas
        this.handleAreas()
      })
  }

  onChange = (suggestion: DBMappedNamedEntity | null): void => {
    if (suggestion) {
      this.model.selectedAreas.value.push(suggestion.name)
      this.model.selectedAreas.value = this.model.selectedAreas.value.sort()
      this.handleAreas()
    }
  }

  onOverrideModeChange = (useDefault: boolean): void => {
    super.onOverrideModeChange(useDefault)
    this.handleAreas()
  }

  handleAreas = (): void => {
    this.availableAreas = differenceWith(this.allAreas, this.model.selectedAreas.value, (areaObj: DBMappedNamedEntity, areaName: string): boolean => {
      return areaObj.name === areaName
    })
    this.form.$setDirty()
  }
}

export default {
  templateUrl: require('./areas-settings.pug'),
  controller: AreasSettingsController,
  bindings: {
    form: '<',
    model: '<',
    isEditing: '<'
  }
}
