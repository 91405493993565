class SearchFieldController {
  public debounceDelay: number = 300
  public debounceTimeout: ng.IPromise<any>
  public value: string
  public modelValue: string
  public initialValue: string
  public previousValue: string
  public hostClass: string
  public onChange: (data: {value: string}) => void
  public isDisabled: boolean

  constructor (
    public $timeout: ng.ITimeoutService
  ) {}

  $onInit (): void {
    this.debounceDelay = 300
    this.debounceTimeout = null
    this.value = this.initialValue ? String(this.initialValue) : ''
    this.previousValue = this.initialValue ? null : ''
  }

  keydown (): void {
    if (this.debounceTimeout) {
      this.$timeout.cancel(this.debounceTimeout)
    }

    this.debounceTimeout = this.$timeout(() => this.submit(), this.debounceDelay)
  }

  $onChanges (simpleChange: any): void {
    if (simpleChange.modelValue) {
      this.value = simpleChange.modelValue.currentValue
      this.previousValue = simpleChange.modelValue.currentValue
    }
  }

  submit (): void {
    if (this.value === this.previousValue) {
      return
    }

    this.previousValue = this.value

    this.onChange({
      value: this.value.trim()
    })
  }

  clear (): void {
    this.value = ''
    this.submit()
  }
}

export default {
  templateUrl: require('./search-field.pug'),
  controller: SearchFieldController,
  bindings: {
    modelValue: '<?',
    initialValue: '@?',
    placeholder: '@',
    onChange: '&',
    hostClass: '@?',
    isDisabled: '<?'
  }
}
