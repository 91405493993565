import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'
import localStorageService from 'data/services/localStorageService'
import storefrontOrdersCollection from 'data/collections/storefrontOrdersCollection'
import { getItemFromLocalStorage } from 'common/utils/storageUtils'
import { AUTH_HEADER, AUTH_STORAGE_KEY } from 'common/agent'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'

class SalesReportController extends BaseController {
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public branchId: string
  public startDate: Date
  public endDate: Date
  public maxDate: Date = new Date()

  constructor (
    $scope: angular.IScope,
    $state: StateService
  ) {
    super($scope, $state)
  }

  public get salesReportLink(): string {
    const startDate = this.startDate ? this.startDate.toISOString() : null
    const endDate = this.endDate ? this.endDate.toISOString() : null

    return storefrontOrdersCollection.getSalesReportCSVLink(this.businessId, startDate, endDate, this.branchId)
  }

  public linkReport(event: MouseEvent): void {
    event.preventDefault()
    const authToken = getItemFromLocalStorage(AUTH_STORAGE_KEY)
    document.cookie = `${AUTH_HEADER}=${authToken}`
    window.open(this.salesReportLink, '_blank')
  }

  public onBusinessSelected (business: any): void {
    this.businessId = business.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)
    this.branchId = null
  }

  public onBranchSelected (branch: any): void {
    this.branchId = branch.branchId
  }

  public changeDates(payload: any): void {
    this.startDate = payload.dateFrom
    this.endDate = payload.dateTo
  }


}

export default {
  controller: SalesReportController,
  templateUrl: require('./sales-report.pug')
}
