import differenceWith from 'lodash-es/differenceWith'
import pull from 'lodash-es/pull'
import templateUrl from './multiple-areas-picker.pug'
import areasCollection from 'data/collections/areasCollection'

class MultipleAreasPicker {
  constructor ($scope) {
    this.$scope = $scope

    this.allAreas = []
    this.availableAreas = []

    this.getAreas()
  }

  $onInit () {
    this.selectedAreas = this.selectedAreas || []
  }

  $onChanges (changesDescriptor) {
    if (changesDescriptor.selectedAreas && changesDescriptor.selectedAreas.currentValue) {
      this.selectedAreas = changesDescriptor.selectedAreas.currentValue.map(area => {
        if (typeof area === 'string') {
          return area
        } else {
          return (area && area.name) || ''
        }
      })
    }
  }

  removeArea (area) {
    if (!this.isDisabled) {
      this.selectedAreas = pull(this.selectedAreas, area)
      this.handleAreas()
    }
  }

  getAreas () {
    areasCollection.getRealGeographicalAreasCached()
      .then(areas => {
        this.allAreas = areas
        this.handleAreas()
      })
  }

  onPickSuggestedArea (suggestion) {
    if (suggestion) {
      this.selectedAreas.push(suggestion.name)
      this.selectedAreas = this.selectedAreas.sort()
      this.handleAreas()
    }
  }

  handleAreas () {
    this.availableAreas = differenceWith(this.allAreas, this.selectedAreas, (areaObj, areaName) => {
      return areaObj.name === areaName
    })

    if (this.form && this.form.$setDirty) {
      this.form.$setDirty()
    }

    this.onChange({areas: this.selectedAreas})
    this.onChangeWithIds({
      areas: this.selectedAreas.map(areaName => this.allAreas.find(areaObj => areaObj.name === areaName))
    })
  }
}

export default {
  templateUrl,
  controller: MultipleAreasPicker,
  bindings: {
    form: '<',
    selectedAreas: '<',
    onChange: '&',
    onChangeWithIds: '&',
    isDisabled: '<'
  }
}
