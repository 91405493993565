import BasePopupController from 'presentation/common/BasePopupController'
import PlaceExit from 'data/domain-objects/PlaceExit'
import MultiLangString from 'data/domain-objects/MultiLangString'
import MultilangAddress from 'data/domain-objects/MultilangAddress'

export const EXIT_POPUP_ID = 'exit-popup'
export const EXIT_POPUP_SHOW_ACTION = 'exit-popup-show-action'

class ExitPopupController extends BasePopupController {
  public exit: PlaceExit
  public originalExit: PlaceExit
  public EXIT_POPUP_ID: string = EXIT_POPUP_ID

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService
  ) {
    super($scope, $rootScope, EXIT_POPUP_SHOW_ACTION, EXIT_POPUP_ID)
  }

  public get hasChanged(): boolean {
    return JSON.stringify(this.exit) !== JSON.stringify(this.originalExit)
  }

  $onInit (): void {
    this.setupPopupOpenEventListener((payload: {exit: PlaceExit}): void => {
      this.exit = payload.exit
      this.initOriginalExit()
    })
  }

  initOriginalExit(): void {
    this.originalExit = new PlaceExit(
      MultiLangString.build(this.exit.name),
      MultiLangString.build(this.exit.pickupInstructions),
      MultilangAddress.build(this.exit.address)
    )
  }

  applyChanges(): void {
    this.close()
  }

  resetChanges(): void {
    Object.keys(this.exit).forEach((key: string) => {
      this.exit[key] = this.originalExit[key]
    })
  }

  cancel(): void {

    this.close()
  }

  setExitToOriginalExit(): void {
    // instead of changin the referrence we change inner props
    Object.keys(this.exit).forEach((key: string) => {
      if (key !== '_id') {
        this.exit[key] = this.originalExit[key]
      }
    })
  }


  onClose (): void {
    this.exit = null
  }
}

export default {
  templateUrl: require('./exit-popup.pug'),
  controller: ExitPopupController
}
