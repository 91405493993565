/* globals google */
import get from 'lodash-es/get'
import template from './delivery-locations-map.pug'
import BaseMapController from 'presentation/common/BaseMapController'
import envConfig from 'common/envConfig'
import {GoogleMapsPoint} from 'common/utils/mapUtils'

class DeliveryLocationsMapController extends BaseMapController {
  constructor ($scope, $element) {
    const mapElement = $element[0].querySelector('.js_map_container')
    const markerType = envConfig.googleMapsSettings.markerTypes.dot.name
    super($scope, mapElement, markerType)

    this.combinedDeliveries = []
    this.deliveries = []
    this.$scope = $scope
    this.deliveriesProcessFirstTime = false
  }

  $onInit () {
    this.initMap(!!this.doInitClusterer)
    this.syncDriverLocationsFromDeliveries()
  }

  $onChanges (simpleChange) {
    if (simpleChange.deliveries) {
      this.syncDriverLocationsFromDeliveries()
    }
  }

  viewAllMarkers () {
    const markers = this.getAllMarkers()
    if (!this.$scope.map) {
      return
    }
    const bounds = new google.maps.LatLngBounds()
    markers.forEach(marker => {
      bounds.extend(new GoogleMapsPoint(marker.location.latitude, marker.location.longitude))
    })
    if (markers.length > 0) {
      this.$scope.map.fitBounds(bounds)
    }
  }

  $onDestroy () {
    this.clearMapListeners()
  }

  handleDeliveryClick (delivery) {
    if (this.onDeliveryClick) {
      this.onDeliveryClick(delivery)
      this.$scope.$apply() // Manually trigger digest cycle as click events originating in Google Maps occur outside of Angular.
    }
  }

  syncDriverLocationsFromDeliveries () {
    this.getCombinedDeliveries()
    this.addDeliveryMarkersToMap()
    this.setZoomState()
  }

  getAllMarkers () {
    return this.combinedDeliveries.map(delivery => this.getMarkerObject(delivery)).filter(marker => !!marker)
  }

  getCombinedDeliveries () {
    this.combinedDeliveries = []

    if (this.deliveries) {
      if (this.deliveries.allocated) {
        this.combinedDeliveries = this.combinedDeliveries.concat(this.deliveries.allocated)
      }

      if (this.deliveries.atPickup) {
        this.combinedDeliveries = this.combinedDeliveries.concat(this.deliveries.atPickup)
      }

      if (this.deliveries.collected) {
        this.combinedDeliveries = this.combinedDeliveries.concat(this.deliveries.collected)
      }

      if (this.deliveries.atDropoff) {
        this.combinedDeliveries = this.combinedDeliveries.concat(this.deliveries.atDropoff)
      }
    }
  }

  setZoomState () {
    if (!this.deliveriesProcessFirstTime && this.combinedDeliveries.length > 0) {
      this.deliveriesProcessFirstTime = true
      this.viewAllMarkers()
    }

    if (this.autoSetMapZoom) {
      this.viewAllMarkers()
    }
  }

  addDeliveryMarkersToMap () {
    const markers = this.getAllMarkers()
    this.checkAndRedrawMarkers(markers) // BaseMapController method
  }

  getMarkerObject (delivery) {
    let marker = null
    const latitude = get(delivery, 'lastKnownLocation.location.latitude', false)
    const longitude = get(delivery, 'lastKnownLocation.location.longitude', false)
    if (latitude && longitude && delivery.driver) {
      marker = {
        id: delivery.id,
        location: {
          latitude,
          longitude
        },
        status: delivery.status,
        onClick: () => this.handleDeliveryClick(delivery)
      }
    }

    return marker
  }
}

export default {
  templateUrl: template,
  controller: DeliveryLocationsMapController,
  bindings: {
    deliveries: '<',
    onDeliveryClick: '<?',
    autoSetMapZoom: '<?',
    doInitClusterer: '<?'
  }
}
