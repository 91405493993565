import templateUrl from './address-radio-select.pug'

export default {
  templateUrl: templateUrl,
  bindings: {
    addresses: '<',
    verifiedAddressesIds: '<',
    model: '=',
    title: '@',
    name: '@',
    isRequired: '=',
    form: '='
  }
}
