import permissionsModel from 'data/models/permissionsModel'
import localStorageService from 'data/services/localStorageService'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'

class StorefrontDashboardController {
  public showCategories: boolean
  public showProducts: boolean
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)

  constructor () { // @todo make correct permissions checking
    this.showCategories = permissionsModel.getHasPermission('STOREFRONT_CATEGORIES') || true
    this.showProducts = permissionsModel.getHasPermission('PRODUCTS') || true
  }

  onBusinessSelected(business: any): void {
    this.businessId = business.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)
  }
}

export default {
  templateUrl: require('./storefront-dashboard.pug'),
  controller: StorefrontDashboardController
}
