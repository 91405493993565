import PromoCode from 'data/domain-objects/PromoCode'

class PromoCodeEditorController {
  /* Bindings START */
  public promoCode: PromoCode
  public isEditing: boolean
  public form: ng.IFormController
  /* Bindings END */

  public dateFormat: string = 'yyyy/MM/dd HH:mm'

  onDateRangeChanged(dateRange: any): void {
    this.promoCode.validFrom = dateRange.dateFrom
    this.promoCode.expiresAt = dateRange.dateTo
  }
}

export default {
  templateUrl: require('./promo-code-editor.pug'),
  controller: PromoCodeEditorController,
  bindings: {
    promoCode: '<',
    isEditing: '<',
    form: '<'
  }
}
