import envConfig from 'common/envConfig'
import templateUrl from './settings-block.pug'

class SettingsController {
  $onInit () {
    this.dropdownModel = this.dropdownModel || envConfig.businessAndBranchSettings.defaultDisplaySettingsValue
    this.dropdownValues = this.dropdownValues || envConfig.businessAndBranchSettings.availableDisplaySettingsValues
  }
}

export default {
  templateUrl,
  controller: SettingsController,
  transclude: true,
  bindings: {
    form: '=?',
    title: '@',
    useDefault: '=',
    dropdownModel: '=',
    dropdownValues: '<?',
    onToggleChange: '&?',
    onDropdownChange: '&?',
    hostClass: '@?',
    conditionallyHideContent: '<?'
  }
}
