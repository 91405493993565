import config from './constants'
import localeHelper from 'common/localeHelper'
import PhoneNumber from './PhoneNumber'
import DBMappedNamedEntity from './DBMappedNamedEntity'
import AddressField from './AddressField'
import PlaceExit from './PlaceExit'
import GeoJSONPolygon from './GeoJSONPolygon'
import MultiLangString from './MultiLangString'
import BaseAddressTemplate from './BaseAddressTemplate'
import MultilangAddress from './MultilangAddress'
import Address from './Address'

export default class Place implements IPostPutJsonAdapter {
  constructor(
    public id: string = '',
    public name: MultiLangString = new MultiLangString(),
    public category: DBMappedNamedEntity = new DBMappedNamedEntity(),
    public bounds: GeoJSONPolygon = new GeoJSONPolygon(),

    public directions: MultiLangString = new MultiLangString(),
    public comments: MultiLangString = new MultiLangString(),

    public phoneNumber: PhoneNumber = PhoneNumber.build({countryDiallingCode: config.defaultCountryCode.toString()}),
    public webSite: string = '',
    public email: string = '',
    public address: MultilangAddress =  {
      en: BaseAddressTemplate.build(),
      ar: BaseAddressTemplate.build()
    },
    public exits: PlaceExit[] = []
  ) {}

  // The following methods are need to keep contract with BE
  toPutJSON(): any {
    const placeObj = this.toJSON()

    return {
      ...placeObj,

      id: undefined
    }
  }

  toPostJSON(): any {
    const placeObj = this.toJSON()

    return {
      ...placeObj,
      directions: this.directions.ar || this.directions.en ? this.directions : undefined,
      comments: this.comments.ar || this.comments.en ? this.comments : undefined,

      id: undefined
    }
  }

  // When we do POST/PUT we need to change some things
  toJSON (): any {
    // cut out address from place and put it in separate variable. modifiedPlace is also a new variablw which contains place without address
    const {
      address,
      ...modifiedPlace
    } = this

    const mergedAddress: BaseAddressTemplate = MultilangAddress.getMergedAddress(this.address)

    return {
      ...modifiedPlace,
      phoneNumber: this.phoneNumber.phoneNumber.length ? this.phoneNumber.phoneNumber : undefined,
      countryDiallingCode: this.phoneNumber.phoneNumber.length ? this.phoneNumber.countryDiallingCode : undefined,
      categoryId: this.category.id,
      category: undefined,
      location: mergedAddress.location,
      fields: mergedAddress.fields,
      addressFormatId: mergedAddress.addressFormatId
    }
  }

  public static build (dto: any = {}): Place {

    return new Place(
      dto.id || '',
      MultiLangString.build(dto.name || {}),
      DBMappedNamedEntity.build(dto.category || {}),
      GeoJSONPolygon.build(dto.bounds),
      MultiLangString.build(dto.directions || {}),
      MultiLangString.build(dto.comments || {}),
      new PhoneNumber(dto.countryDiallingCode || config.defaultCountryCode.toString(), dto.phoneNumber),
      dto.webSite || '',
      dto.email || '',
      MultilangAddress.buildFromMultilangFields(dto),
      (Array.isArray(dto.exits) ? dto.exits : []).map(PlaceExit.build)
    )
  }

}
