import { addressDialogIds } from 'common/constants/DialogIds'
import BasePopupController from 'presentation/common/BasePopupController'
import { ADDRESS_EDITOR_OPEN_FIND_ADDRESS_EVENT } from 'common/constants/PopupEvents'
import addressLookupService from 'data/services/addressLookupService'
import Address from 'data/domain-objects/Address'
import { PointOfInterest } from 'data/domain-objects/PointOfInterest'

const PACI_SEARCH_MODE = 'PACI_SEARCH_MODE'
const POI_SEARCH_MODE = 'POI_SEARCH_MODE'

class FindAddressDialogController extends BasePopupController {
  /* Bindings START */
  public onAccept: (payload: {address: Address}) => void
  /* Bindings END */

  public readonly FIND_ADDRESS_DIALOG_ID: string
  public readonly PACI_SEARCH_MODE: string
  public readonly POI_SEARCH_MODE: string

  public selectedSearchMode: string
  private _paciCode: string = ''
  public poiString: string = ''
  public foundAddress: Address
  public foundPOIs: PointOfInterest[] = []
  public noResultMessage: string = ''
  public isLoading: boolean = false

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $filter: ng.IFilterService
  ) {
    super($scope, $rootScope, ADDRESS_EDITOR_OPEN_FIND_ADDRESS_EVENT, addressDialogIds.FIND_ADDRESS_DIALOG_ID)
    this.FIND_ADDRESS_DIALOG_ID = addressDialogIds.FIND_ADDRESS_DIALOG_ID
    this.PACI_SEARCH_MODE = PACI_SEARCH_MODE
    this.POI_SEARCH_MODE = POI_SEARCH_MODE

    this.selectedSearchMode = PACI_SEARCH_MODE

    this.setupPopupOpenEventListener(() => {
      this.resetComponentState()
    })
  }

  get paciCode(): string {
    return this._paciCode ? this._paciCode.substring(0, 8) : ''
  }
  set paciCode(value: string) {
    if (/^\d{0,8}$/.test(value)) {
      this._paciCode = value + ''
    }
  }

  public resetComponentState(): void {
    this.foundAddress = undefined
    this.foundPOIs = []
    this.isLoading = false
    this.poiString = ''
    this.paciCode = ''
    this.noResultMessage = ''
    this.selectedSearchMode = this.PACI_SEARCH_MODE
  }

  public setSearchMode (searchMode: string): void {
    this.selectedSearchMode = searchMode
  }

  public searchAddressByPaci(): void {
    this.foundAddress = undefined
    this.noResultMessage = ''
    if (this.paciCode.length) {
      this.isLoading = true
      addressLookupService.getAddressDescriptorsForPaciCode(this.paciCode)
      .then((result: any[]) => {
        // TODO: when dynamic-address component is deleted please move this wrapping step into addressLookupService
        const addresses: Address[] = result.map((addressDto: any) => Address.build(addressDto))
        if (addresses.length) {
          this.foundAddress = addresses[0]
        } else {
          this.noResultMessage = this.$filter<ITranslateFilter>('translate')('COMPONENTS.FIND_ADDRESS_DIALOG.NO_RESULT')
        }
      })
      .catch((error: Error) => {
        this.logger.error('Error occurred while searching address: ', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
    }
  }

  public searchPoi(): void {
    this.foundPOIs = []
    this.noResultMessage = ''
    if (this.poiString.length) {
      this.isLoading = true
      addressLookupService.getAddressDescriptorsForSearchText(this.poiString)
      .then((result: any[]) => {
        const pointsOfInterest: PointOfInterest[] = result.map((poiDto: any) => PointOfInterest.build(poiDto))
        this.foundPOIs = pointsOfInterest
        if (!this.foundPOIs.length) {
          this.noResultMessage = this.$filter<ITranslateFilter>('translate')('COMPONENTS.FIND_ADDRESS_DIALOG.NO_RESULT')
        }
      })
      .catch((error: Error) => {
        this.logger.error('Error occurred while searching address: ', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
    }
  }

  public acceptAddress(): void {
    this.onAccept({address: this.foundAddress})
    this.close()
  }

  public acceptPoiAddress(poi: PointOfInterest): void {
    const poiAddress: Address = Address.build({
      addressFormatId: poi.addressFormatId,
      location: poi.location,
      fields: poi.fields
    })
    this.onAccept({address: poiAddress})
    this.close()
  }
}

export default {
  templateUrl: require('./find-address-dialog.pug'),
  controller: FindAddressDialogController,
  bindings: {
    onAccept: '&'
  }
}
