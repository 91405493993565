import ValidationUtils from 'common/utils/validationUtils'
// TODO: think if we should get rid of this file as it seems that it is used only in one place
/*
 * This functionality is now duplicated in BaseFormController. There are only a small number of places that additionally
 * rely on this class directly.
 */
export default class FormModel {
  constructor () {
    this.resetValidation()
  }

  resetValidation () {
    this.backendValidationErrors = {}
    this.submitFailedOther = false
    this.submitFailedValidation = false
  }

  notifySubmitSuccess () {
    this.submitFailedValidation = false
    this.submitFailedOther = false
  }

  notifySubmitError (error) {
    const errorDescriptorArraysByFieldName = error && error.validationErrors
    if (errorDescriptorArraysByFieldName && Object.keys(errorDescriptorArraysByFieldName).length > 0) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(errorDescriptorArraysByFieldName)
      this.submitFailedValidation = true
      this.submitFailedOther = false
    } else {
      this.backendValidationErrors = {}
      this.submitFailedValidation = false
      this.submitFailedOther = true
    }
  }
}
