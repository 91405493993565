import templateUrl from './delivery-type.pug'
import BaseController from 'presentation/common/BaseController'
import * as DeliveryTypes from 'common/constants/DeliveryTypes'

class deliveryTypeController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.deliveryTypes = DeliveryTypes
  }
}

export default {
  templateUrl,
  controller: deliveryTypeController,
  bindings: {
    handleChangeDeliveryTypeClick: '<',
    selectedDeliveryType: '<'
  }
}
