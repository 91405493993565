import templateUrl from './setup-driver-account.pug'
import BaseController from 'presentation/common/BaseController'
import driverAccountModel from 'data/models/driverAccountModel'
import driverWizardRouteSteps from 'presentation/logistics-drivers/common/driverWizardRouteSteps'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'

class DriverSetupAccountController extends BaseController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.errorEmail = false
    this.model = {}
    this.wizardRouteSteps = driverWizardRouteSteps
  }

  $onInit () {
    this.targetState = this.$state.get(this.$stateParams.targetState) ? this.$stateParams.targetState : ''
    this.getDefaultDiallingCode()
  }

  getDefaultDiallingCode () {
    return diallingCodesCollection.getDefault()
      .then(defaultDiallingCode => {
        this.model.countryDiallingCode = defaultDiallingCode
        this.$scope.$digest()
      })
      .catch(err => this.logger.error('Cannot get default county dialling code', err))
  }

  submit () {
    this.isEditing = true
    driverAccountModel.create(this.model)
      .then(response => this.handleSubmitSuccess(response))
      .catch(err => this.handleError(err))

      .then(() => {
        this.isEditing = false
        this.$scope.$digest()
      })
  }

  backToAllDrivers () {
    this.$state.go('main.logistics.drivers')
  }

  handleError (err) {
    this.logger.error('Error creating driver account', err)
    if (err && err.validationErrors && err.validationErrors.email) {
      this.errorEmail = true
    } else {
      this.errorEmail = false
    }
  }

  handleSubmitSuccess (response) {
    if (Array.isArray(response)) {
      this.logger.info('Driver account may already exist', response)

      this.$state.go('main.logistics.drivers.existing-accounts')
    } else {
      this.logger.info('Received new driver account details', response)

      this.$state.go('main.logistics.drivers.driver.add-license', {
        driverId: response.id,
        targetState: 'main.logistics.drivers',
        fleetId: this.$stateParams.fleetId
      })
    }
  }
}

export default {
  templateUrl,
  controller: DriverSetupAccountController
}
