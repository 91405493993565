/* eslint-disable no-magic-numbers, no-param-reassign */

// stolen and optimized from https://gist.github.com/james2doyle/5694700

const FRAMES_PER_SECOND = 1000 / 60
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) {
    return c / 2 * t * t + b
  }
  t--
  return -c / 2 * (t * (t - 2) - 1) + b
}

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (function () {
  return window.requestAnimationFrame || (callback => window.setTimeout(callback, FRAMES_PER_SECOND))
}())

function scrollTo (to, callback, duration = 500) {
  // because it's hard to detect the scrolling element, just move them all
  const move = amount => {
    document.documentElement.scrollTop = amount
    document.body.parentNode.scrollTop = amount
    document.body.scrollTop = amount
  }

  const position = () => {
    return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop
  }

  const start = position()
  const change = to - start
  const increment = 20
  let currentTime = 0

  const animateScroll = () => {
    // increment the time
    currentTime += increment
    // find the value with the quadratic in-out easing function
    const val = Math.easeInOutQuad(currentTime, start, change, duration)
    // move the document.body
    move(val)
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll)
    } else if (callback && typeof callback === 'function') {
      // the animation is done so lets callback
      callback()
    }
  }
  animateScroll()
}

export default scrollTo
