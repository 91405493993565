import envConfig from 'common/envConfig'
import endpoints from 'common/endpoints'
import Issue from 'data/domain-objects/Issue'
import CrudService from 'data/services/CrudService'

const issuesService = new CrudService(endpoints.ISSUES)
const issueResolveService = new CrudService(endpoints.ISSUE_RESOLVE)
const resolvedIssuesService = new CrudService(endpoints.ISSUES_RESOLVED)

export function issuesCollectionFactory (issuesService, issueResolveService, resolvedIssuesService) {
  function getAll (query = '', fleetIds = [], businessIds = [], branchIds = [], issueTypes = [], issueLevels = [], skip = 0, limit = envConfig.issuesDefaultPerPageLimit) {
    return issuesService.get([], {
      q: query,
      fleet: fleetIds.join(','),
      business: Array.isArray(businessIds) ? businessIds.join(',') : businessIds,
      branch: Array.isArray(branchIds) ? branchIds.join(',') : branchIds,
      type: issueTypes.join(','),
      level: issueLevels.join(','),
      skip,
      limit
    })
      .then(response => Object.assign({}, response, {
        records: response.records.map(record => Issue.build(record))
      }))
  }

  function resolveIssue (id, comment = '') {
    return issueResolveService.create({
      id,
      comment
    })
  }

  function getResolvedIssues (query = '', fleetIds = [], businessIds = [], branchIds = [], issueTypes = [], issueLevels = [], skip = 0, limit = envConfig.issuesDefaultPerPageLimit) {
    return resolvedIssuesService.get([], {
      q: query,
      fleet: fleetIds.join(','),
      business: Array.isArray(businessIds) ? businessIds.join(',') : businessIds,
      branch: Array.isArray(branchIds) ? branchIds.join(',') : branchIds,
      type: issueTypes.join(','),
      level: issueLevels.join(','),
      skip,
      limit
    })
      .then(response => Object.assign({}, response, {
        records: response.records.map(record => Issue.build(record))
      }))
  }

  return {
    getAll,
    resolveIssue,
    getResolvedIssues
  }
}

export default issuesCollectionFactory(issuesService, issueResolveService, resolvedIssuesService)
