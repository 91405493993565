export default class IssuesFilters {
  constructor (
    public selectedFleetIds: string[] = [],
    public selectedIssueLevels: string[] = [],
    public selectedIssueTypes: string[] = [],
    public searchQuery: string = '',
    public selectedBranchId: string= '',
    public selectedBusinessId: string = ''
  ) {}

  static build (dto: any = {}): IssuesFilters {
    return new IssuesFilters(
      dto.selectedFleetIds,
      dto.selectedIssueLevels,
      dto.selectedIssueTypes,
      dto.searchQuery,
      dto.selectedBranchId,
      dto.selectedBusinessId
    )
  }
}
