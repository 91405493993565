import reduce from 'lodash-es/reduce'
import template from './tariff-period-summary.pug'

class TariffPeriodSummary {
  constructor ($scope, $state) {
    this.$state = $state
    this.$scope = $scope
  }

  $onInit () {
    this.selectedDays = []

    // Keep selected days in sync with tariff period days
    this.$scope.$watch('$ctrl.tariffPeriod', () => {
      const selectedDaysByDayIndex = this.tariffPeriod ? this.tariffPeriod.days : {}
      this.selectedDays = this.getSelectedDayIndicesFromDaysMap(selectedDaysByDayIndex)
    })
  }

  viewTariff () {
    this.$state.go('main.logistics.tariff-periods.tariff-period', {tariffPeriodId: this.tariffPeriod.id})
  }

  getSelectedDayIndicesFromDaysMap (selectedDaysByDayIndex) {
    return reduce(selectedDaysByDayIndex, (accum, isDaySelected, dayIndex) => {
      if (isDaySelected) {
        accum.push(dayIndex)
      }
      return accum
    }, [])
  }
}

export default {
  templateUrl: template,
  controller: TariffPeriodSummary,
  bindings: {
    tariffPeriod: '='
  }
}
