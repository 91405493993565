import {StorefrontOrdersStatusesType} from 'common/constants/StorefrontOrderStatuses'
import Consumer from '../Consumer'

export enum StorefrontOrderEventTypes {
  statusChange = 'status',
  payment = 'payment',
  unknown = 'unknown'
}

export enum StorefrontOrderPaymentEventTypes {
  refundInitiated = 'paymentRefundInitiated',
  refundCompleted = 'paymentRefundProcessed'
}

export default class StorefrontOrderEvent {
  constructor (
    public type: StorefrontOrderEventTypes,
    public value: StorefrontOrdersStatusesType | string,
    public previousValue: string,
    public timestamp: string,
    public changedBy: Consumer
  ) {}

  static build (dto: any): StorefrontOrderEvent {
    return new StorefrontOrderEvent(
      dto.type,
      dto.value,
      dto.previousValue,
      dto.timestamp,
      Consumer.build(dto.changedBy || {})
    )
  }
}
