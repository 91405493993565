import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import scheduleDashboard from './schedule-dashboard/scheduleDashboard'
import scheduleSummary from './schedule-summary/scheduleSummary'
import scheduleForm from './schedule-form/scheduleForm'

export default declareAppModule('logistics.schedule', (module: ng.IModule) => module
  .component('scheduleDashboard', scheduleDashboard)
  .component('scheduleSummary', scheduleSummary)
  .component('scheduleForm', scheduleForm)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'schedule',
      stateKey: 'SCHEDULE',
      componentName: 'scheduleDashboard',
      requiresPermission: 'SCHEDULE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.schedule',
      stateName: 'create',
      stateKey: 'CREATE_SCHEDULE',
      componentName: 'scheduleForm',
      requiresPermission: 'SCHEDULE_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.schedule',
      stateName: 'details',
      stateKey: 'SCHEDULE_DETAILS',
      params: ['targetState'],
      idParam: 'scheduleId',
      componentName: 'scheduleForm',
      requiresPermission: 'SCHEDULE'
    })
  })
)
