import get from 'lodash-es/get'
import templateUrl from './proof-of-delivery.pug'
import BaseController from 'presentation/common/BaseController'

class ProofOfDeliveryController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.resetProofTypeVisibilitySettings()
  }

  resetProofTypeVisibilitySettings () {
    this.showPhotoIdentification = false
    this.showSignature = false
  }

  $onChanges (changeDescriptors) {
    if (changeDescriptors.settings) {
      this.applyProofTypeVisibilitySettings()
    }
  }

  applyProofTypeVisibilitySettings () {
    this.resetProofTypeVisibilitySettings()
    this.showPhotoIdentification = get(this.settings, 'proofOfDeliveryPhotoIdentificationEnabled.value', false)
    this.showSignature = get(this.settings, 'proofOfDeliverySignatureEnabled.value', false)
  }
}

export default {
  templateUrl,
  controller: ProofOfDeliveryController,
  bindings: {
    form: '=',
    requireSignature: '=',
    requirePhotoIdentification: '=',
    settings: '<'
  }
}
