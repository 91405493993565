import get from 'lodash-es/get'
import templateUrl from './batching-options.pug'
import deliveriesCollection from 'data/collections/deliveriesCollection'

class BatchingOptions {
  constructor ($scope) {
    this.$scope = $scope
    this.showBatchingOptions = true
    this.batchingType = 2
    this.suggestions = []
    this.batchWithDeliveryId = null

    this.$scope.$watch('$ctrl.batchingType', val => {
      if (typeof val !== 'undefined') {
        this.allowBatching = this.batchingType === 2
        if (this.batchingType === 1) {
          this.batchWithDeliveryId = null
          this.batchWithDeliveryAutoCompleteModel = null
        }
      }
    })
  }

  $onChanges (changeDescriptorsByKey) {
    if (changeDescriptorsByKey.defaultOption) {
      this.batchingType = this.defaultOption === 'auto' ? 2 : 1
    }

    if (changeDescriptorsByKey.fleetId && this.fleetId) {
      this.getDeliveriesForBatching()
    }
  }

  getDeliveriesForBatching () {
    this.batchingLoading = true
    deliveriesCollection.getBatchableDeliveries(this.fleetId)
      .then(response => this.handleDeliveriesForBatching(response))
      .catch(err => this.logger.error('Cannot get deliveries for batching', err))
      .then(() => {
        this.batchingLoading = false
        this.$scope.$digest()
      })
  }

  handleDeliveriesForBatching (allDeliveries) {
    const deliveries = (this.deliveryId) ? allDeliveries.filter(delivery => delivery.id !== this.deliveryId) : allDeliveries

    // fill map
    this.suggestions = deliveries.map(delivery => {
      const deliveryRef = get(delivery, 'ref', '')
      const driverName = get(delivery, 'driver.fullName', null)
      const posReference = get(delivery, 'payment.posReference', 'N/A')
      const recipientName = get(delivery, 'recipient.name', null)
      const recipientArea = get(delivery, 'recipient.areaName', null)

      const renderableRecipientArea = (recipientArea) ? `${recipientArea}: ` : ''
      const renderableRecipientAreaAndName = (recipientName) ? `(${renderableRecipientArea}${recipientName})` : ''
      const renderableDriverName = (driverName) ? ` (${driverName})` : ''

      delivery.description = `${deliveryRef}${renderableDriverName} - POS: ${posReference} ${renderableRecipientAreaAndName}`

      return delivery
    })
  }

  handleChangeBatchWithDeliverySuggestionSelect (suggestion) {
    this.batchWithDeliveryAutoCompleteModel = suggestion ? suggestion.description : ''
    this.batchWithDeliveryId = suggestion ? suggestion.id : null
  }

  onAutoBatchingOptionFocus () {
    this.batchingType = 2
  }
}

export default {
  templateUrl,
  controller: BatchingOptions,
  bindings: {
    form: '=',
    batchWithDeliveryId: '=',
    allowBatching: '=',
    defaultOption: '<',
    fleetId: '<',
    deliveryId: '<?'
  }
}
