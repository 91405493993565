import MultiLangString from './MultiLangString'

export default class ProductPreparationData {
  constructor(
    public expectedTime: number,
    public extraTimeMessage: MultiLangString,
    public extraTimeRequired: boolean = false
  ) {}

  static build(dto: any): ProductPreparationData {
    return new ProductPreparationData(
      dto.expectedTime || null,
      MultiLangString.build(dto.extraTimeMessage || {}),
      !!dto.extraTimeRequired
    )
  }
}
