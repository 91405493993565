import BaseAddressTemplate from './BaseAddressTemplate'
import AddressField from './AddressField'

export default class MultilangAddress {
  constructor(
    public en: BaseAddressTemplate = new BaseAddressTemplate(),
    public ar: BaseAddressTemplate = new BaseAddressTemplate()
  ) {}

  public static build(dto: any = {}): MultilangAddress {
    return new MultilangAddress(
      BaseAddressTemplate.build(dto.en),
      BaseAddressTemplate.build(dto.ar)
    )
  }

  public static buildFromMultilangFields(dto: Partial<BaseAddressTemplate> = {}): MultilangAddress {
    const arabicAddressFields: AddressField[] = []
    const englishAddressFields: AddressField[] = []

    if (Array.isArray(dto.fields)) {
      for (const field of dto.fields) {
        const addressField = AddressField.build(field)
        if (field.languageCode && field.languageCode === 'ar') {
          arabicAddressFields.push(addressField)
        } else if (field.languageCode && field.languageCode === 'en') {
          englishAddressFields.push(addressField)
        }
      }
    }
    if (!arabicAddressFields.length) {
      englishAddressFields.forEach((field: AddressField) => {
        arabicAddressFields.push(new AddressField(
          field.formatFieldId,
          field.name,
          ''
        ))
      })
    } else if (!englishAddressFields.length) {
      arabicAddressFields.forEach((field: AddressField) => {
        englishAddressFields.push(new AddressField(
          field.formatFieldId,
          field.name,
          ''
        ))
      })
    }

    const address: MultilangAddress = {
      en: BaseAddressTemplate.build({
        addressFormatId: dto.addressFormatId,
        location: dto.location,
        field: dto.fields
      }),
      ar: BaseAddressTemplate.build({
        addressFormatId: dto.addressFormatId,
        location: dto.location,
        field: dto.fields
      })
    }
    address.ar.fields = arabicAddressFields
    address.en.fields = englishAddressFields

    return address
  }

  public static getMergedAddress(address: MultilangAddress): BaseAddressTemplate {
    const allFields: AddressField[] = []
    Object.keys(address).forEach((lang: string) => {
      const fields = address[lang].fields.map((addressField: AddressField) => {
        const newField = AddressField.build(addressField)
        newField.languageCode = lang
        return newField
      })
      allFields.push(...fields)
    })

    return new BaseAddressTemplate(
      address.en.addressFormatId,
      allFields,
      address.en.location
    )
  }
}
