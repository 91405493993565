export enum StorefrontDeliveryCreationStatusesType {
  notRequired = 'notRequired',
  pending = 'pending',
  completed = 'completed',
  failed = 'failed'
}

export const STOREFRONT_DELIVERY_CREATION_STATUSES = {
  NOT_REQUIRED: StorefrontDeliveryCreationStatusesType.notRequired,
  PENDING: StorefrontDeliveryCreationStatusesType.pending,
  COMPLETED: StorefrontDeliveryCreationStatusesType.completed,
  FAILED: StorefrontDeliveryCreationStatusesType.failed
}
