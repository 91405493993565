export default class Schedule {
  constructor (
    public id: string = '',
    public name: string = '',
    public description: string = '',
    public areaId: string = '',
    public timePeriodId: string = '',
    public driversNumber: number = 0) {
  }

  static build (dto: any = {}): Schedule {
    return new Schedule(
      dto.id,
      dto.name,
      dto.description,
      dto.areaId,
      dto.timePeriodId,
      dto.driversNumber
    )
  }
}
