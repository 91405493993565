import templateUrl from './list-select.pug'
/**
 * The story of Safari and isDisabledHack:
 *
 * Safari on desktop and mobile will steal focus from the active element
 * and put it into select after rendering.
 * To overcome this issue we render select in disabled state, and then
 * after a short timeout we remove disabled state. That fixes focus issue
 * in Safari.
 *
 * This is needed only on New Delivery Page with auto scrolling feature.
 */
class ListSelectController {
  constructor ($element, $timeout) {
    this.$element = $element
    this.$timeout = $timeout
    this.isDisabledHack = true
  }

  getValueLabel (valueIndex) {
    // this case handles the situation when we pass an object as a model.
    // In this case we also should pass the key of the object which should represent it's label in dropdown list
    if (this.valueLabelKey && this.values.length && typeof this.values[valueIndex] === 'object') {
      return this.values[valueIndex][this.valueLabelKey]
    }
    return this.valuesMap ? this.valuesMap[this.values[valueIndex]] : this.values[valueIndex]
  }

  focus () {
    const DOMSelectElement = this.$element.find('select')[0]
    if (this.focusOnInit && DOMSelectElement && typeof DOMSelectElement.focus === 'function') {
      DOMSelectElement.focus()
    }
  }

  $onChanges (changes) {
    if (changes.hasOwnProperty('isDisabled') && !changes.isDisabled.isFirstChange()) {
      this.isDisabledHack = !!this.isDisabled
    }
  }

  $onInit () {
    this.$timeout(() => {
      this.isDisabledHack = !!this.isDisabled
      this.focus()
    })
  }

  isModelExists () {
    return typeof this.model !== 'undefined'
  }
}

export default {
  templateUrl,
  controller: ListSelectController,
  bindings: {
    name: '@',
    values: '<',
    valuesMap: '<',
    valueLabelKey: '<?',
    disabledMap: '<?',
    model: '=',
    placeholder: '@',
    form: '=',
    isDisabled: '<',
    validateAllowedValues: '<?',
    isRequired: '<',
    noValueLabel: '@',
    noDefaultValue: '<',
    translateValueFromMap: '=',
    floatingLabelText: '@',
    valuePrefixTranslationKey: '@',
    disallowedValuesArray: '<?',
    hideOptional: '<?',
    onChange: '&?',
    modelId: '<?',
    hostClass: '@?',
    focusOnInit: '<'
  }
}
