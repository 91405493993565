/* globals google: false */
import Logger from 'common/Logger'
import ParseUtils from 'common/utils/parseUtils'

const DEFAULT_ZOOM_LEVEL = 15
const DEFAULT_POLYGON_HEX_COLOUR = '#00caff'
const logger = new Logger('Google Maps Service')

class GoogleMapsPoint {
  constructor (latitude, longitude) {
    const latitudeFloat = ParseUtils.safeParseNumber(latitude, 0)
    const longitudeFloat = ParseUtils.safeParseNumber(longitude, 0)
    this.lat = latitudeFloat
    this.lng = longitudeFloat // Note that this is 'lng'
  }
}

class GoogleMapsPolygon {
  constructor (googleMapsPointPath) {
    const polygonOptions = new GoogleMapsPolygonOptions(googleMapsPointPath)
    return new google.maps.Polygon(polygonOptions)
  }
}

class GoogleMapsLatLngBounds {
  constructor (googleMapsPointPath) {
    const latLngBounds = new google.maps.LatLngBounds()
    googleMapsPointPath.forEach(point => latLngBounds.extend(point))
    return latLngBounds
  }
}

class GoogleMapsOptions {
  constructor (isEditing, optionalCentreGoogleMapsPoint, fullScreenControl = false, zoomOnScroll = false) {
    const options = {
      mapTypeId: google.maps.MapTypeId.ROADMAP, // Always reset back to road map when resetting the options
      gestureHandling: 'cooperative',
      draggable: true,
      disableDefaultUI: false,
      disableDoubleClickZoom: !isEditing,
      scrollwheel: zoomOnScroll,
      streetViewControl: false,
      clickableIcons: false, // Disable clickable points of interest
      fullscreenControl: fullScreenControl
    }
    if (optionalCentreGoogleMapsPoint) {
      options.center = optionalCentreGoogleMapsPoint
      options.zoom = DEFAULT_ZOOM_LEVEL // Only set the zoom if we have something to zoom to
    }
    return options
  }
}

class GoogleMapsDrawingOptions {
  constructor (map, canDrawPolygon) {
    return {
      drawingControl: false,
      drawingMode: canDrawPolygon ? google.maps.drawing.OverlayType.POLYGON : null,
      polygonOptions: new GoogleMapsPolygonOptions(),
      map: map
    }
  }
}

class GoogleMapsPolygonOptions {
  constructor (googleMapsPointPath) {
    return {
      paths: Array.isArray(googleMapsPointPath) ? googleMapsPointPath : [],
      strokeColor: DEFAULT_POLYGON_HEX_COLOUR,
      strokeOpacity: 0.75,
      strokeWeight: 2,
      fillColor: DEFAULT_POLYGON_HEX_COLOUR,
      fillOpacity: 0.25,
      clickable: true,
      draggable: true
    }
  }
}

function addMarker (map, latitude, longitude, title = '', icon) {
  const markerConfig = {
    position: {lat: latitude, lng: longitude},
    draggable: false,
    map,
    title
  }

  if (icon) {
    markerConfig['icon'] = icon
  }
  return new google.maps.Marker(markerConfig)
}

function removeShapeFromMap (shape) {
  if (shape && shape.setMap) {
    shape.setMap(null)
  }
}

function fitMapToBounds (map, polygon) {
  const newBounds = getBoundsForPolygon(polygon)
  if (!newBounds.isEmpty()) {
    map.fitBounds(newBounds)
  }
}

function getBoundsForPolygon (polygon) {
  const polygonPointPath = polygon.map(latLong => new GoogleMapsPoint(latLong.lat, latLong.long))
  return new GoogleMapsLatLngBounds(polygonPointPath)
}

function setMapDrawable (map, mapDrawingManager, isDrawable) {
  const drawingOptions = new GoogleMapsDrawingOptions(map, isDrawable)
  mapDrawingManager.setOptions(drawingOptions)
}

function getGoogleMapsPolygonForLatLongPolygon (polygon) {
  const polygonPointPath = polygon.map(latLong => new GoogleMapsPoint(latLong.lat, latLong.long))
  let shape = new GoogleMapsPolygon([])
  try {
    shape = new GoogleMapsPolygon([polygonPointPath])
  } catch (error) {
    logger.error('Could not init shape for polygon point path', error)
  }
  return shape
}

function getGoogleMapsPolygonForGeoJSONPolygon (geoJSONPolygon) {
  const polygonPointPath = geoJSONPolygon.coordinates.map(ring => {
    return ring.map(ringCoordinate => new GoogleMapsPoint(ringCoordinate[1], ringCoordinate[0]))
  })
  let shape = new GoogleMapsPolygon([])
  try {
    shape = new GoogleMapsPolygon(polygonPointPath)
  } catch (error) {
    logger.error('Could not init shape for polygon point path', error)
  }
  return shape
}

function getLatLongPolygonForGoogleMapsPolygon (googleMapsPolygon) {
  const latLngsArray = googleMapsPolygon.getPath().getArray()
  return latLngsArray.map(latLng => ({lat: latLng.lat(), 'long': latLng.lng()}))
}

function getPolygonTriangle (polygon, centerPoint, scale) { // 0 <= scale <= 1
  // @todo: add the calculation of the distance to the nearest polygon path to avoid traingle and polygon intersection

  let fixedScale = scale
  if (scale > 1) {
    fixedScale = 1
  } else if (scale < 0) {
    fixedScale = 0
  }

  const paths = polygon.getPaths().getArray()

  const distances = []
  for (let k = 0; k < paths.length; k++) {
    const vertices = paths[k]
    const polygonDistances = []
    for (let i = 0; i < vertices.getLength(); i++) {
      const xy = vertices.getAt(i)
      const distance = google.maps.geometry.spherical.computeDistanceBetween(xy, centerPoint)
      polygonDistances.push(distance)
    }

    const minPolyDist = Math.min(...polygonDistances)
    const minPolyDistIndex = polygonDistances.findIndex(dist => dist === minPolyDist)

    distances.push({polygonId: k, minDist: minPolyDist, minDistId: minPolyDistIndex})
  }

  const minDistValue = Math.min(...distances.map(distance => distance.minDist))
  const minDistObj = distances.find(distance => distance.minDist === minDistValue)

  const basicPoint = paths[minDistObj.polygonId].getAt(minDistObj.minDistId)
  const distance = fixedScale * Math.sqrt(Math.pow((basicPoint.lat() - centerPoint.lat()), 2) + Math.pow((basicPoint.lng() - centerPoint.lng()), 2))

  const newPolygonArray = []

  let angle = Math.atan((basicPoint.lng() - centerPoint.lng()) / (basicPoint.lat() - centerPoint.lat()))
  const anglesCount = 3
  const oneHundredAndTwentyDegrees = Math.PI * (2 / 3) // eslint-disable-line

  for (let i = anglesCount; i > 0; i--) {
    const x = centerPoint.lat() + Math.cos(angle) * distance
    const y = centerPoint.lng() + Math.sin(angle) * distance
    newPolygonArray.push(new google.maps.LatLng({lat: x, lng: y}))
    angle += oneHundredAndTwentyDegrees // keep counter clockwise to make a hole! Otherwise you will get polygon inside another
  }

  return newPolygonArray
}

export {
  GoogleMapsPoint,
  GoogleMapsOptions,
  GoogleMapsDrawingOptions,
  GoogleMapsPolygon,
  GoogleMapsLatLngBounds
}

export default {
  removeShapeFromMap,
  fitMapToBounds,
  setMapDrawable,
  getGoogleMapsPolygonForLatLongPolygon,
  getGoogleMapsPolygonForGeoJSONPolygon,
  getLatLongPolygonForGoogleMapsPolygon,
  addMarker,
  getPolygonTriangle
}
