import envConfig from 'common/envConfig'

export const urlToOriginWithPath = (path: string): string => {
  const origin: string = window.location.origin
  const hashBang = '/#!'
  return `${origin}${hashBang}${path}`
}

export const isSameDomain = (domain: string): boolean => {
  return window.location.hostname.includes(domain)
}
