import ContactDetails from './ContactDetails'

export default class MultilangContact {
  constructor(
    public en: ContactDetails  = new ContactDetails(),
    public ar: ContactDetails = new ContactDetails()
  ) {}

  static build(contactDTO: any = {}): MultilangContact {
    return new MultilangContact(
      contactDTO.en,
      contactDTO.ar
    )
  }
}
