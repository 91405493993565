export default class SmsTemplate {
  constructor (
    public id: string = '',
    public body: string = '',
    public contentCode: string = '',
    public locale: string = ''
  ) {}

  static build (dto: any = {}): SmsTemplate {
    return new SmsTemplate(
      dto.id || '',
      dto.body || '',
      dto.contentCode || '',
      dto.locale || ''
    )
  }
}
