import template from './driver-assign-to-fleets.pug'
import driverModel from 'data/models/driverModel'
import DriverBaseFormController from 'presentation/logistics-drivers/common/DriverBaseFormController'
import fleetCollection from 'data/collections/fleetsCollection'

class DriverAssignToFleetsController extends DriverBaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state, $stateParams)
  }

  $onInit () {
    this.isCreatingForSpecifiedFleet = !!this.$stateParams.fleetId

    if (this.isCreatingForSpecifiedFleet) {
      this.fleetName = ''
      this.model.fleets = [this.$stateParams.fleetId]
      this.getFleetName(this.$stateParams.fleetId)
    }
  }

  handleDriverFound (driver) {
    super.handleDriverFound(driver)
    if (this.isCreatingForSpecifiedFleet) {
      this.model.fleets = [this.$stateParams.fleetId]
    }
  }

  getFleetName (fleetId) {
    fleetCollection.getCached(fleetId)
      .then(fleet => {
        this.fleetName = fleet.name
        this.$scope.$digest()
      })
      .catch(err => this.logger.error('Cannot get fleet name for new driver', err))
  }

  beforeSubmit () {
    return this.makeDriverOnboarded()
  }

  makeDriverOnboarded () {
    return driverModel.updateDriverAsOnBoard()
      .catch(error => this.handleDriverNotOnboard(error))
  }

  handleDriverNotOnboard (error) {
    this.logger.error('Failed to set driver as onboard:', error)
  }

  afterSubmitSuccess () {
    this.goToViewDriver(true)
  }
}

export default {
  templateUrl: template,
  controller: DriverAssignToFleetsController
}
