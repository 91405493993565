import branchesCollection from 'data/collections/branchesCollection'
import Branch from 'data/domain-objects/Branch'
import Logger from 'common/Logger'

class BalancesPicker {

  // bindingds
  public branchId: string = null
  public consumerBalanceId: string = null
  public onBalanceIdChange: (payload: {balanceId: string}) => void
  public allowConsumerBalance: boolean = false

  // internal
  public logger: Logger = new Logger('BalancesPicker')
  public selectedId: string = null
  public loadingBranchBalances: boolean
  private balances: ISuggestion[] = []
  public balancesValues: string[]
  public balancesValuesMap: {} = {}

  constructor (
    private $scope: ng.IScope,
    private $filter: ng.IFilterService
  ) {}

  $onChanges(changes: ng.IOnChangesObject): void {

    if (changes.consumerBalanceId && this.allowConsumerBalance) {
      this.initConsumerBalanceSuggestion()
    }

    if (changes.branchId) {
      this.loadBalances()
    }
  }

  initConsumerBalanceSuggestion(): void {
    this.balances = this.allowConsumerBalance ? [ this.getConsumerBalanceSuggestion() ] : []
    this.autoSelectFirstSuggestion()
  }

  loadBalances(): void {
    if (this.branchId) {
      branchesCollection.getCached(this.branchId)
        .then((branchDetails: Branch) => this.getChargeToBranchBalances(branchDetails.businessId, branchDetails.id))
    } else {
      this.initConsumerBalanceSuggestion()
      this.prepareDataForListSelect()
    }
  }

  prepareDataForListSelect(): void {
    this.balancesValues = []
    this.balancesValuesMap = {}
    this.balances.forEach((balance: any) => {
      this.balancesValues.push(balance.id)
      this.balancesValuesMap[ balance.id ] = balance.name
    })
  }

  getConsumerBalanceSuggestion(): ISuggestion {
    return {
      id: this.consumerBalanceId,
      name: this.$filter<ITranslateFilter>('translate')('COMPONENTS.RIDE.RIDE_CREATE.CONSUMER_BALANCE')
    }
  }

  autoSelectFirstSuggestion(): void {
    if (this.balances.length > 0) {
      const selectedBalance: ISuggestion = this.balances[0]
      this.selectedId = selectedBalance.id
      this.onValueChange({value: this.selectedId })
    }
  }

  getChargeToBranchBalances (businessId: string, branchId: string): void {

    if (!this.loadingBranchBalances) {
      this.loadingBranchBalances = true

      branchesCollection.getBalances(businessId, branchId)
        .then((balances: any) => {

          this.balances = balances
            .filter((balance: any) => balance.isEnoughFundsToCreateDelivery)
            .map((balance: any) => {
              return {
                id: balance.balanceAccountId,
                name: balance.balanceAccountName
              }
            })

          if (this.allowConsumerBalance) {
            this.balances.push(this.getConsumerBalanceSuggestion())
          }
        })
        .catch((err: Error) => this.logger.error('Cannot get branch balances', err))
        .then(() => {
          this.autoSelectFirstSuggestion()
          this.prepareDataForListSelect()
          this.loadingBranchBalances = false
          this.$scope.$digest()
        })
    }
  }

  onValueChange(selectedValue: any): void {
    const balanceAccountId = selectedValue && selectedValue.value || null
    this.onBalanceIdChange({balanceId: balanceAccountId})
  }
}

export default {
  templateUrl: require('./balances-picker.pug'),
  controller: BalancesPicker,
  bindings: {
    branchId: '<',
    onBalanceIdChange: '&',
    consumerBalanceId: '<',
    allowConsumerBalance: '<',
    isRequired: '<'
  }
}
