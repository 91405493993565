import PlaceExit from 'data/domain-objects/PlaceExit'
import localeHelper from 'common/localeHelper'
import AddressField from 'data/domain-objects/AddressField'
import { EXIT_POPUP_SHOW_ACTION } from '../exit-popup/exitPopup'

class ExitMarkerInfoWindowController {
  // inputs
  public exit: PlaceExit
  public isEditing: boolean

  // properties
  public currentLanguage: string = localeHelper.getLanguage()
  public exitName: string

  constructor(
    private $scope: ng.IScope,
  ) {
    $scope.$watch(() => localeHelper.getLanguage(), (newValue: string) => {
      this.currentLanguage = newValue
    })
  }

  $onInit(): void {
    this.exitName = this.exit.name[this.currentLanguage]
  }

  public saveName(): void {
    this.exit.name[this.currentLanguage] = this.exitName
    const exitField: AddressField = this.exit.address[this.currentLanguage].fields.find((field: AddressField) => field.name === 'exit')
    if (exitField) {
      exitField.value = this.exitName
    }
  }

  public openEditPopup(): void {
    this.$scope.$emit(EXIT_POPUP_SHOW_ACTION, { exit: this.exit })
  }
}

export default {
  templateUrl: require('./exit-marker-info-window.pug'),
  controller: ExitMarkerInfoWindowController,
  bindings: {
    exit: '<',
    isEditing: '<'
  }
}
