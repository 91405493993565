import templateUrl from './team-member-create.pug'
import TeamMember from 'data/domain-objects/TeamMember'
import rolesCollection from 'data/collections/rolesCollection'
import BaseFormController from 'presentation/common/BaseFormController'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'
import teamMembersCollection from 'data/collections/teamMembersCollection'

class TeamMemberCreateController extends BaseFormController {
  constructor ($scope, $state) {
    super($scope, $state)

    this.businesses = []
    this.branches = []
    this.fleets = []
    this.roles = []
    this.roleIds = []
    this.rolesById = {}
    this.rolesByIdMappedToEntities = {}
    this.submitFailed = false
    this.teamMember = TeamMember.build()
    this.addRoleToTeamMember = (roleId, entities) => this.teamMember.addRole(roleId, entities)

    this.handleRemoveRole = roleId => this.teamMember.removeRole(roleId)

    this.getAllData()
  }

  getAllData () {
    Promise.all([
      diallingCodesCollection.getDefault(),
      rolesCollection.getAllAssignableWithSupportingData()
    ])
      .then(entityCollections => this.handleAllDataReceived(entityCollections))
      .catch(err => this.logger.error('Cannot get all data', err))
      .then(() => {
        this.$scope.$digest()
      })
  }

  handleAllDataReceived (entityCollections) {
    const [defaultDialingCode, dataBundle] = entityCollections

    this.branches = dataBundle.branches
    this.businesses = dataBundle.businesses
    this.fleets = dataBundle.fleets
    this.roleTypesById = dataBundle.rolesById
    this.roles = dataBundle.roles
    this.roleIds = dataBundle.roleIds
    this.rolesById = dataBundle.rolesById
    this.rolesByIdMappedToEntities = dataBundle.rolesByIdMappedToEntities

    this.teamMember = TeamMember.build(
      {countryDiallingCode: defaultDialingCode},
      dataBundle.rolesById,
      dataBundle.businessesMap,
      dataBundle.branchesMap,
      dataBundle.fleetsMap
    )
  }

  beforeSubmit () {
    return teamMembersCollection.create(this.teamMember.toPostJson())
  }

  afterSubmitSuccess () {
    this.$state.go('main.users.user-management')
  }

  afterSubmitFailure (error) {
    this.notifySubmitError(error)
    this.submitFailed = true
  }
}

export default {
  controller: TeamMemberCreateController,
  templateUrl
}
