import Logger from 'common/Logger'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'
// TODO: If we are using such component which operates with abstract entity then we should have either:
// TODO: Entity class which we build outside and pass here or we should pass all required fields as separate bindings
// TODO: ...continue We need this because current implementation implies that we should mutate somehow our DOs
// TODO: (Branch, Driver, Service etc.. etc..) to fit entity picker which is very very bad
const logger = new Logger('Entity Picker')

class EntityPickerController {

  constructor ($scope: ng.IScope) {
    this.$scope = $scope
    this.availableEntities = []
    this.selectedEntityIds = []
    this.selectedEntitiesStatusMap = {}
    this.availableEntitiesStatusMap = {}
    this.allAvailableEntitiesSelected = false
    this.selectAllEntities = (): void => this.selectEntities()
    this.removeAllEntities = (): void => this.removeEntities()
    this.selectHighlightedEntities = (): void => this.selectEntities(true)
    this.removeHighlightedEntities = (): void => this.removeEntities(true)
  }
  public $scope: ng.IScope
  public model: string[]
  public isEditing: boolean
  public entityName: string
  public crudService: CrudService
  public selectedEntityIds: string[]
  public availableEntities: DBMappedNamedEntity[]
  public availableEntitiesList: DBMappedNamedEntity[]
  public selectedEntitiesStatusMap: object
  public availableEntitiesStatusMap: object
  public allAvailableEntitiesSelected: boolean
  public selectAllEntities: () => void
  public removeAllEntities: () => void
  public selectHighlightedEntities: () => void
  public removeHighlightedEntities: () => void

  static handleGetAllEntitiesFail (error: Error): void {
    logger.error('Failed to receive all available entities:', error)
  }

  static compareEntitiesByNames (entityA: any, entityB: any): number {
    const entityNameA = (entityA.name || entityA.formattedName || entityA.description).toLocaleLowerCase()
    const entityNameB = (entityB.name || entityB.formattedName || entityB.description).toLocaleLowerCase()

    if (entityNameA === entityNameB) {
      return 0
    } else if (entityNameA > entityNameB) {
      return 1
    } else {
      return -1
    }
  }

  $onInit (): void {
    if (this.entityName) {
      this.crudService = new CrudService(endpoints[this.entityName.toUpperCase()])
    }

    this.$scope.$watchGroup(['$ctrl.model', '$ctrl.availableEntitiesList'], ([model, availableEntities]: any): void => {
      if (Array.isArray(model)) {
        this.selectedEntityIds = model
      } else {
        logger.warn('Non-array model value passed to entity picker. Picker may be unable to update model until array passed.')
        this.selectedEntityIds = []
      }

      if (this.entityName) {
        this.getAllAvailableEntities() // TODO: this should not be here! We should get it from the outside. Such component should be as much stupid as possible
      } else {
        const availableEntitiesList = Array.isArray(availableEntities) ? availableEntities : []
        this.handleGetAllEntitiesSuccess(availableEntitiesList)
      }
    })

    this.$scope.$watch('$ctrl.availableEntitiesList.length', () => this.setAvailableEntitiesStatus())
  }

  getAllAvailableEntities (): void {
    logger.info('Requesting all available entities for entity:', this.entityName)

    this.crudService.get()
      .then((response: any) => this.handleGetAllEntitiesSuccess(response))
      .catch((error: Error) => EntityPickerController.handleGetAllEntitiesFail(error))
      .then(() => this.$scope.$digest())
  }

  handleGetAllEntitiesSuccess (response: DBMappedNamedEntity[]): void {
    logger.info('Received all available entities for entity:', this.entityName, response)

    this.availableEntities = response.sort(EntityPickerController.compareEntitiesByNames)
    this.setAvailableEntitiesStatus()
    this.resetSelectedEntitiesStatus()
  }

  setAvailableEntitiesStatus (): void {
    this.availableEntitiesStatusMap = {}
    let selectedCount = 0

    this.availableEntities.forEach((entity: any) => {
      const entityId = entity.id
      let selected = false

      if (this.selectedEntityIds.indexOf(entityId) !== -1) {
        selected = true
        selectedCount++
      }

      this.availableEntitiesStatusMap[entityId] = {
        highlighted: false,
        selected
      }
    })

    this.allAvailableEntitiesSelected = (selectedCount === this.availableEntities.length)
  }

  resetSelectedEntitiesStatus (): void {
    this.selectedEntitiesStatusMap = {}

    if (this.selectedEntityIds) {
      this.selectedEntityIds.forEach((entityId: string) => {
        this.selectedEntitiesStatusMap[entityId] = {
          highlighted: false
        }
      })
    }
  }

  toggleAvailableEntityHighlighted (id: string): void {
    if (this.isEditing) {
      this.availableEntitiesStatusMap[id].highlighted = !this.availableEntitiesStatusMap[id].highlighted
    }
  }

  toggleSelectedEntityHighlighted (id: string): void {
    if (this.isEditing) {
      this.selectedEntitiesStatusMap[id].highlighted = !this.selectedEntitiesStatusMap[id].highlighted
    }
  }

  selectEntities (useHighlighted?: boolean): void {
    if (this.isEditing) {
      this.availableEntities.forEach((entity: any)  => {
        if (!useHighlighted || (useHighlighted && this.availableEntitiesStatusMap[entity.id].highlighted)) {
          this.selectedEntityIds.push(entity.id)
        }
      })

      this.setAvailableEntitiesStatus()
      this.resetSelectedEntitiesStatus()
    }
  }

  removeEntities (useHighlighted?: boolean): void {
    if (this.isEditing) {
      if (useHighlighted) {
        this.availableEntities.forEach((entity: any) => {
          const selectedEntity = this.selectedEntitiesStatusMap[entity.id]
          if (selectedEntity && selectedEntity.highlighted) {
            const index = this.selectedEntityIds.indexOf(entity.id)
            this.selectedEntityIds.splice(index, 1)
          }
        })
      } else {
        this.selectedEntityIds.splice(0)
      }

      this.setAvailableEntitiesStatus()
      this.resetSelectedEntitiesStatus()
    }
  }
}

export default {
  templateUrl: require('./entity-picker.pug'),
  controller: EntityPickerController,
  bindings: {
    model: '=',
    isEditing: '=',
    entityName: '@?', // optional
    availableEntitiesList: '<?', // optional,
    hostClass: '@?',
    availableListTitle: '@',
    selectedListTitle: '@'
  }
}
