import moment from 'moment'
import envConfig from 'common/envConfig'

class EditableListDateRangeController {
  public startDate: Date
  public finishDate: Date
  public readonlyValue: string = ''
  public showTime: boolean

  private format: string

  constructor (
    public $scope: ng.IScope
  ) {}

  $onInit(): void {
    this.$scope.$watchGroup(['$ctrl.startDate', '$ctrl.finishDate'], () => this.formatDate())
  }

  $onChanges(): void {
    this.format = this.showTime ? envConfig.defaultDateFormat : envConfig.dateOnlyDateFormat
  }

  formatDate (): void {
    if (this.startDate && this.finishDate) {
      this.readonlyValue = `${moment(this.startDate).format(this.format)} — ${moment(this.finishDate).format(this.format)}`
    }
  }

  onChangeDate(payload: {dateFrom: Date, dateTo: Date}, form: ng.IFormController): void {
    this.startDate = payload.dateFrom
    this.finishDate = payload.dateTo

    if (form && form.$setDirty) {
      form.$setDirty()
    }
  }
}

export default {
  templateUrl: require('./editable-list-date-range.pug'),
  controller: EditableListDateRangeController,
  bindings: {
    form: '=',
    title: '@',
    minDate: '<?',
    maxDate: '<?',
    showTime: '<?',
    startDate: '=',
    finishDate: '=',
    placeholder: '@?',
    emptyText: '@?',
    hostClass: '@?',
    isEditing: '<',
    isDisabled: '<'
  }
}
