import templateUrl from './local-auto-complete-input.pug'
import cloneDeep from 'lodash-es/cloneDeep'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import envConfig from 'common/envConfig'

const MAX_NUMBER_OF_SUGGESTIONS = envConfig.localAutocompleteMaxNumberOfItems
const SUGGESTIONS_DELAY = 10
const MIN_CHARS_LIMIT = 1

class LocalAutoCompleteInput {
  constructor ($scope) {
    this.$scope = $scope
    this.localSuggestions = []
    this.filteredSuggestions = []
    this.suggestions = []

    this.suggestionDelay = SUGGESTIONS_DELAY
    this.minCharsLimit = MIN_CHARS_LIMIT
    this.maxSuggestionCount = MAX_NUMBER_OF_SUGGESTIONS
  }

  mapSuggestions (suggestions) {
    this.localSuggestions = suggestions.map(item => {
      return item.description ? item : {description: item}
    })
    this.filteredSuggestions = cloneDeep(this.localSuggestions)
  }

  $onChanges (changeDescriptorsByKey) {
    const model = getValueFromChangeDescriptors(changeDescriptorsByKey, 'model', null)
    const suggestions = getValueFromChangeDescriptors(changeDescriptorsByKey, 'suggestions', null)

    if (model) {
      this.model = {description: model}
    }

    if (suggestions) {
      this.mapSuggestions(suggestions)

      if (this.model && this.model.description) {
        const matchingSuggestion = this.localSuggestions.find(suggestion => suggestion.description === this.model.description)

        if (this.localSuggestions.length && !matchingSuggestion) {
          this.model = {description: null}
        }
      }
    }
  }

  onLocalSuggestionsRequired (suggestion) {
    this.filteredSuggestions = this.localSuggestions.filter(item => item.description.toLowerCase().indexOf(suggestion.toLowerCase()) > -1)

    this.$scope.$digest()
  }

  onLocalSuggestionsClearRequired () {
    this.mapSuggestions(this.suggestions)
    this.onSuggestionsClearRequired()
  }

  onLocalChange (suggestion) {
    if (this.keepSelected) {
      this.model = {description: suggestion.description}
    }
    this.onChange({suggestion: (suggestion && !this.returnObject) ? suggestion.description : suggestion})
  }
}

export default {
  templateUrl,
  controller: LocalAutoCompleteInput,
  bindings: {
    hostClass: '@?',
    name: '@',
    form: '<',
    model: '<', // Ensure is a reference to a primitive, not object
    onChange: '&',
    onInputFocus: '&',
    isRequired: '<?',
    isDisabled: '<?',
    label: '@?',
    placeholder: '@?',
    hideOptional: '<?',
    backendValidationModel: '<?',
    suggestions: '<',
    onSuggestionsClearRequired: '&',
    maxSuggestionCount: '<?',
    suggestionDelay: '<?',
    minCharsLimit: '<?',
    returnObject: '<',
    keepSelected: '<',
    suggestionsCount: '<?',
    disabledPlaceholder: '<?'
  }
}
