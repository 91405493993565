import templateUrl from './date-time-picker.pug'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import {MathUtils} from 'common/utils/mathUtils'
import DateUtils from 'common/utils/dateUtils'
import BasePopupController from 'presentation/common/BasePopupController'

export const DIALOG_ID = 'date-time-picker-dialog-id'
export const DATE_TIME_PICKER_OPEN_EVENT = 'date-time-picker-open-event'
export const DATE_TIME_PICKER_CLOSE_EVENT = 'date-time-picker-close-event'
export const DATE_TIME_PICKER_CHANGE_DATE_EVENT = 'date-time-picker-change-date-event'

const DEFAULT_MIN_STEP = 5

class DateTimePicker extends BasePopupController {
  constructor ($scope, $rootScope, $filter) {
    super($scope, $rootScope, DATE_TIME_PICKER_OPEN_EVENT, DIALOG_ID)

    this.$filter = $filter
    this.dialogId = DIALOG_ID
    this.resetPickerState()
    this.isButtonDisabled = true

    this.setupPopupOpenEventListener(payload => {
      if (!payload) {
        this.logger.warn('Empty Date-Time picker configuration', payload)
      } else {
        this.initConfig(payload)
        this.buttons = payload.hasOwnProperty('buttons') && payload.buttons.length ? payload.buttons : []
      }
    })
  }

  onClose () {
    this.resetPickerState()
    this.$rootScope.$broadcast(DATE_TIME_PICKER_CLOSE_EVENT)
  }

  resetPickerState () {
    this.initialDateValue = null
    this.isFrom = false
    this.isTo = false
    this.calculatedMinDate = null
    this.calculatedMaxDate = null
    this.minHours = null
    this.minMinutes = null
    this.maxHours = null
    this.maxMinutes = null
    this.activeQuickButtonIndex = null

    const now = new Date()

    this.time = {
      hours: now.getHours(),
      minutes: now.getMinutes()
    }

    // Following values could be re-defined via opening event payload object
    this.showDate = true
    this.showTime = true
    this.isRange = false
    this.minDate = null
    this.maxDate = null
    this.valueFrom = null
    this.valueTo = null
    this.selectedDate = null
    this.minutesChangeStep = DEFAULT_MIN_STEP
    this.buttons = []
  }

  $onDestroy () {
    if (typeof this.unsubscribe === 'function') {
      this.unsubscribe()
    }
    super.$onDestroy()
  }

  initConfig (config) {
    this.showDate = config.hasOwnProperty('showDate') ? !!config.showDate : this.showDate
    this.showTime = config.hasOwnProperty('showTime') ? !!config.showTime : this.showTime
    this.isRange = config.hasOwnProperty('isRange') ? !!config.isRange : this.isRange
    this.minDate = config.hasOwnProperty('minDate') ? config.minDate : this.minDate
    this.maxDate = config.hasOwnProperty('maxDate') ? config.maxDate : this.maxDate
    this.valueFrom = config.hasOwnProperty('valueFrom') ? config.valueFrom : this.valueFrom
    this.valueTo = config.hasOwnProperty('valueTo') ? config.valueTo : this.valueTo
    this.minutesChangeStep = config.hasOwnProperty('minutesChangeStep') ? config.minutesChangeStep : this.minutesChangeStep

    if (this.isRange) {
      this.isFrom = true
      this.selectedDate = null
    } else {
      this.isFrom = false
      this.selectedDate = this.initialDateValue
    }
    this.isTo = false

    this.initialDateValue = config.selectedDate ? new Date(config.selectedDate) : this.initialDateValue
    this.selectedDate = config.hasOwnProperty('selectedDate') ? config.selectedDate : this.selectedDate

    if (this.isFrom) {
      this.selectedDate = this.valueFrom ? this.valueFrom : this.selectedDate
    } else {
      this.selectedDate = this.valueTo ? this.valueTo : this.selectedDate
    }

    this.selectedDate = this.getNewDateWithRoundedToStepMinutes(this.selectedDate)

    this.calculatedMinDate = this.isRange && !this.isFrom && this.valueFrom ? this.valueFrom : this.minDate
    this.calculatedMaxDate = this.isRange && this.isFrom && this.valueTo ? this.valueTo : this.maxDate

    this.recalculateTimeLimits(this.initialDateValue)

    this.title = this.$filter('translate')(`COMPONENTS.DATE_TIME_PICKER.TITLES.${this.getTitleSuffix()}`)
    this.setButtonState()
  }

  getNewDateWithRoundedToStepMinutes (date) {
    if (date) {
      const localDate = new Date(date)
      const currentDateRoundedMinutes = Math.ceil(localDate.getMinutes() / this.minutesChangeStep) * this.minutesChangeStep
      localDate.setMinutes(currentDateRoundedMinutes)
      return localDate
    }

    return null
  }

  applyConfigurationByButtonIndex (buttonIndex) {
    if (this.buttons[buttonIndex]) {
      this.initConfig(this.buttons[buttonIndex])
      this.activeQuickButtonIndex = buttonIndex
    }
  }

  getTitleSuffix () {
    if (this.showDate && !this.showTime) {
      return 'SELECT_DATE'
    } else if (!this.showDate && this.showTime) {
      return 'SELECT_TIME'
    }

    return 'SELECT_DATE_AND_TIME'
  }

  onDateSelected (date) {
    const dateWithTime = this.changeTimeForGivenDate(date, this.time.hours, this.time.minutes)

    if (this.isRange) {
      if (this.isFrom) {
        this.valueFrom = dateWithTime
      } else {
        this.valueTo = dateWithTime
      }
    }

    this.selectedDate = dateWithTime

    this.recalculateTimeLimits(date)
    this.setButtonState()
  }

  recalculateTimeLimits (selectedDate) {
    if (this.minDate && selectedDate && DateUtils.areTheSameDates(this.minDate, selectedDate)) {
      this.minHours = this.minDate.getHours()
      this.minMinutes = this.minDate.getMinutes()
      this.maxHours = null
      this.maxMinutes = null
    } else if (this.maxDate && selectedDate && DateUtils.areTheSameDates(this.maxDate, selectedDate)) {
      this.minHours = null
      this.minMinutes = null
      this.maxHours = this.maxDate.getHours()
      this.maxMinutes = this.maxDate.getMinutes()
    } else {
      this.minHours = null
      this.minMinutes = null
      this.maxHours = null
      this.maxMinutes = null
    }
  }

  onTimeChanged (hours, minutes) {
    if (this.selectedDate || this.valueFrom || this.valueTo) { // Set the time for Date object if there's at least one of them
      if (this.isRange) { // separate for ranges
        if (this.isFrom) {
          this.valueFrom = this.changeTimeForGivenDate(this.valueFrom, hours, minutes)
        } else {
          this.valueTo = this.changeTimeForGivenDate(this.valueTo, hours, minutes)
        }
      } else { // once for single date
        this.selectedDate = this.changeTimeForGivenDate(this.selectedDate, hours, minutes)
      }
    }

    this.time.hours = hours
    this.time.minutes = minutes
  }

  changeTimeForGivenDate (date, hours, minutes) {
    const localDate = date ? new Date(date) : new Date()

    localDate.setHours(hours)
    localDate.setMinutes(MathUtils.ceilToStep(minutes, this.minutesChangeStep))

    if (this.minDate && localDate.getTime() < this.minDate.getTime()) {
      return new Date(this.minDate)
    }

    if (this.maxDate && localDate.getTime() > this.maxDate.getTime()) {
      return new Date(this.maxDate)
    }

    return localDate
  }

  confirmDate () {
    if (this.isRange && this.isFrom) {
      this.goToSelectToValue()
    } else {
      const payload = {}

      if (this.isRange) {
        payload.dateFrom = this.valueFrom
        payload.dateTo = this.valueTo
      } else {
        payload.date = this.selectedDate
      }

      this.$rootScope.$broadcast(DATE_TIME_PICKER_CHANGE_DATE_EVENT, payload)

      modalDialogStateService.emitCloseModalDialog({
        dialogId: this.dialogId
      })
    }
  }

  goToSelectFromValue () {
    this.isFrom = true
    this.isTo = false
    this.calculatedMinDate = this.minDate
    this.calculatedMaxDate = this.valueTo ? this.valueTo : this.maxDate
    this.setButtonState()
  }

  goToSelectToValue () {
    this.isFrom = false
    this.isTo = true
    this.calculatedMaxDate = this.maxDate
    this.calculatedMinDate = this.valueFrom
    this.setButtonState()
  }

  setButtonState () {
    this.buttonCaption = this.$filter('translate')(`COMMON.${(this.isRange && this.isFrom) ? 'NEXT' : 'CONFIRM'}`)

    this.isButtonDisabled = true

    if (!this.isRange && this.selectedDate) {
      this.isButtonDisabled = false
    } else if (this.isRange && this.isFrom) {
      this.isButtonDisabled = !this.valueFrom
    } else if (this.isRange && !this.isFrom) {
      this.isButtonDisabled = !this.valueTo
    }
  }
}

export default {
  templateUrl,
  controller: DateTimePicker
}
