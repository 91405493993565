export class RequestConfirmation {
  public requireSignature: boolean = false
  public requirePhotoIdentification: boolean = false

  public createdAt: string = ''
  public fleetId: string = ''
  public id: string = ''
  public numberOfItems: string = ''
  public orderCost: any = {}
  public packages: any[] = []
  public pickupTime: string = ''
  public posReference: string = ''
  public recipient: any = {}
  public sender: any = {}
  public balanceAccountId: string = ''
  public proofOfDelivery: any = {}

  constructor (
    requestDTO: any = {}
  ) {
    this.createdAt = requestDTO.createdAt
    this.fleetId = requestDTO.fleetId
    this.id = requestDTO.id
    this.numberOfItems = requestDTO.numberOfItems
    this.orderCost = Object.assign({}, requestDTO.orderCost)
    this.packages = requestDTO.packages.slice(0)
    this.pickupTime = requestDTO.pickupTime.toISOStringGivenNow(new Date())
    this.posReference = requestDTO.posReference
    this.recipient = JSON.parse(JSON.stringify(requestDTO.recipient))
    this.sender = JSON.parse(JSON.stringify(requestDTO.sender))
    this.balanceAccountId = requestDTO.balanceAccountId
    this.proofOfDelivery = {
      signature: {
        required: this.requireSignature
      },
      photoIdentification: {
        required: this.requirePhotoIdentification
      }
    }
    this.recipient.address = this.recipient.address.id
  }
}
