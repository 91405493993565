import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import {DEFAULT_LOCATION_LATITUDE, DEFAULT_LOCATION_LONGITUDE} from 'data/services/locationsService'
import localeHelper from 'common/localeHelper'

const addressLookupCrudService = new CrudService(endpoints.ADDRESSES_LOOKUP)

export default {
  getAddressDescriptorsForPaciCode: function (paciCode) {
    const searchQueryObject = {
      query: `${paciCode}` // Ensure API always gets string
    }
    const searchQueryJsonString = JSON.stringify(searchQueryObject)
    return addressLookupCrudService.get([], {source: 'paci', query: searchQueryJsonString}, true)
  },
  getAddressDescriptorsForSearchText: function (searchText) {
    const currentLanguageCode = localeHelper.getLanguage()
    const searchQueryObject = {
      query: searchText,
      lat: DEFAULT_LOCATION_LATITUDE,
      lng: DEFAULT_LOCATION_LONGITUDE,
      lang: currentLanguageCode
    }
    const searchQueryJsonString = JSON.stringify(searchQueryObject)
    return addressLookupCrudService.get([], {source: 'poi', query: searchQueryJsonString}, true)
  }
}
