export default class Duration {
  constructor (
    public unitCode: string = '',
    public amount: number = 0
  ) {}

  public static build (dto: any = {}): Duration {
    return new Duration(
      dto.unitCode || '',
      isNaN(parseInt(dto.amount)) ? 0 : parseInt(dto.amount)
    )
  }
}
