import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'
import businessesCollection from 'data/collections/businessesCollection'
import branchesCollection from 'data/collections/branchesCollection'

interface IBranchSettingsWithId {
  branchId: string,
  settings?: object
}

class BranchesOrderProcessingManagement extends BaseController {
  private businessId: string = null
  public showBranchesError: boolean = false
  public showSettingsError: boolean = false
  public branches: IBranchSettingsWithId[] = []

  constructor ($scope: ng.IScope, $state: StateService) {
    super($scope, $state)
  }

  $onChanges(): void {
    if (this.businessId) {
      this.sync()
    }
  }

  onSync(): Promise<any> {
    this.showBranchesError = false
    this.showSettingsError = false
    return businessesCollection.getWithBranches(this.businessId)
      .then((business: any): Promise<any> => {
        this.branches = business.branches
        return Promise.all(this.branches.map((branch: any): Promise<any> => branchesCollection.getStorefrontSettings(this.businessId, branch.id)))
      })
      .then((responses: any) => {
        this.branches.map((branch: any, index: number): IBranchSettingsWithId => Object.assign(branch, { settings: responses[index] }))
      })
      .catch((err: Error): void => {
        this.logger.error('Cannot get branches with their settings for selected business')
        this.showBranchesError = true
      })
  }

  public onBusyModeValueChanged(branch: any, busyMode: boolean): void {
    branch.settings.orderProcessing__busyMode.value = busyMode
    this.onBranchSettingsUpdated(branch)
  }

  public onBranchSettingsUpdated(branch: any): void {
    this.showSettingsError = false
    branchesCollection.updateStorefrontSettings(branch.settings, this.businessId, branch.id)
      .then((settings: any) => {
        branch.settings = settings
        this.$scope.$digest()
      })
      .catch((err: Error): void => {
        this.logger.error('Cannot update branch settings')
        this.showSettingsError = true
      })
  }
}

export default {
  templateUrl: require('./branches-order-processing-management.pug'),
  controller: BranchesOrderProcessingManagement,
  bindings: {
    businessId: '<'
  }
}
