import get from 'lodash-es/get'
import templateUrl from './branch-balance-history-table.pug'
import Cost from 'data/domain-objects/Cost'
import envConfig from 'common/envConfig'
import branchesCollection from 'data/collections/branchesCollection'
import BalanceHistoryBaseController from 'presentation/common/BalanceHistoryBaseController'

const COLUMNS_CONFIGURATION = [
  {name: 'date', title: 'DATE', sortable: true, sortKey: 'createdAt', icon: 'time'},
  {name: 'draewilId', title: 'DRAEWIL_ID'},
  {name: 'pos', title: 'POS'},
  {name: 'pickup', title: 'PICK_UP'},
  {name: 'fleet', title: 'FLEET'},
  {name: 'driver', title: 'DRIVER'},
  {name: 'operation', title: 'OPERATION', sortable: true, sortKey: 'operation', icon: 'amount'},
  {name: 'description', title: 'STATUS'}
]

class BranchBalanceHistoryTableController extends BalanceHistoryBaseController {
  constructor ($scope, $state, $filter, $stateParams) {
    super($scope, $state, $filter)
    this.$stateParams = $stateParams
    this.branchId = this.$stateParams.branchId
    this.columns = COLUMNS_CONFIGURATION
    this.sync()
  }

  onSync () {
    const filters = Object.assign({}, this.filters)
    if (!this.filtersInitialized) {
      filters.includeFilters = true
      this.filtersInitialized = true
    }

    return branchesCollection.getHistory(this.branchId, filters)
      .then(response => this.processData(response))
  }

  processMetaData (metadata) {
    this.rowsTotalCount = metadata.count
  }

  processData (response) {
    this.processMetaData(response.metadata)

    this.rows = response.records.map(row => {
      const date = (row.createdAt) ? this.$filter('date')(new Date(row.createdAt), envConfig.angularDefaultDateFormat) : ''
      const businessName = get(row, 'sender.business', '')
      const senderName = get(row, 'sender.name') || ''
      const fullName = (businessName ? `${businessName} ` : '') + senderName
      const fleet = row.fleet
      const link = row.deliveryId && row.type ? `<a href="/#!/deliveries/overview/${row.deliveryId}">${row.draewilId}</a>` : null

      return [
        date,
        link,
        row.pos,
        fullName,
        fleet,
        row.driver,
        Cost.build(row.operation ? row.operation : {}).getFormattedCost(),
        row.description
      ]
    })
  }
}

export default {
  bindings: {
    triggerDataReload: '<?'
  },
  templateUrl,
  controller: BranchBalanceHistoryTableController
}
