import Address from '../Address'
import StorefrontOrderDeliveryInformation from './StorefrontOrderDeliveryInformation'
import { ShippingMethods } from 'common/constants/ShippingMethods'
import { ShippingProviders } from 'common/constants/ShippingProviders'
import DateUtils from 'common/utils/dateUtils'

export default class StorefrontOrderShippingDetails {
  constructor(
    public actualTime: Date,
    public desiredTime: Date,
    public address: Address,
    public deliveries: StorefrontOrderDeliveryInformation[],
    public method: ShippingMethods,
    public provider: ShippingProviders
  ) { }

  static build(dto: any): StorefrontOrderShippingDetails {
    return new StorefrontOrderShippingDetails(
      dto.actualTime ? DateUtils.makeSafeDateFromString(dto.actualTime) : null,
      dto.desiredTime ? DateUtils.makeSafeDateFromString(dto.desiredTime) : null,
      Address.build(dto.address),
      (dto.deliveries || []).map(StorefrontOrderDeliveryInformation.build),
      dto.method,
      dto.provider
    )
  }
}
