import uiSettingsService from 'data/services/uiSettingsService'
import templateUrl from './header-logged-in.pug'

class HeaderLoggedInController {
  constructor ($scope, $rootScope, $state) {
    this.$rootScope = $rootScope
    this.$state = $state
    this.sidebarIsExpanded = uiSettingsService.isSidebarExpanded
    this.toggleSidebarExpandedState = () => uiSettingsService.isSidebarExpanded = !uiSettingsService.isSidebarExpanded
    this.localeOptionsVisible = false

    $scope.$watch(() => uiSettingsService.isSidebarExpanded, isSidebarExpanded => {
      this.sidebarIsExpanded = isSidebarExpanded
    })
  }

  // https://ui-router.github.io/ng1/docs/latest/interfaces/transition.transitionoptions.html#reload
  // {reload: true} will reload current state with all its parents. This will lead to blinking effect because it will reload layout-logged-in component
  // {reload: 'state-name'} will reload 'state-name' with all its children. This will not reload layout-logged-in component

  createNextDay () {
    this.$state.go('main.deliveries.create-next-day', null, {reload: 'main.deliveries.create-next-day'})
  }

  createDelivery () {
    this.$state.go('main.deliveries.create', null, {reload: 'main.deliveries.create'})
  }

  createRide () {
    this.$state.go('main.ride-create', null, {reload: 'main.ride-create'})
  }
}

export default {
  templateUrl,
  controller: HeaderLoggedInController,
  bindings: {
    noSidebar: '<'
  }
}
