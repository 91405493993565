import templateUrl from './calendar.pug'
import localeHelper from 'common/localeHelper'
import * as DateConstants from 'common/constants/DateConstants'
import DateUtils from 'common/utils/dateUtils'

const stickyMonthChangeIntervalMS = 300

class CalendarController {
  constructor ($scope) {
    this.$scope = $scope
    this.now = new Date()
    this.displayingDate = this.now
    this.selectedDate = null

    this.displayingYear = null

    this.months = []
    this.displayingMonthIndex = null
    this.monthsTranslationKeys = DateConstants.MONTHS_TRANSLATION_KEYS
    this.isCurrentMonthShown = true
    this.showPrevMonthButton = true
    this.showNextMonthButton = true

    this.weeks = []
    this.weekDaysTranslationKeys = CalendarController.getWeekDaysTranslationKeys()

    this.stickTimeoutId = null
  }

  $onChanges () {
    this.calculatePreSelectedDate()

    if (!this.isRange && this.date) {
      this.setCalendarMonthByDate(this.date)
    } else if (this.isRange && (this.dateFrom || this.dateTo)) {
      this.setCalendarMonthByDate(this.dateFrom || this.dateTo)
    } else if (this.minDate) {
      this.setCalendarMonthByDate(this.minDate)
    } else {
      this.goToCurrentMonth()
    }
  }

  calculatePreSelectedDate () {
    let selectedDate = null

    if (this.dateFrom) {
      selectedDate = new Date(this.dateFrom)
    } else {
      selectedDate = this.date ? new Date(this.date) : selectedDate
    }

    this.selectedDate = selectedDate
  }

  calculateMonthButtonsVisibility () {
    const lastDayOfPrevMonth = new Date(this.displayingYear, this.displayingMonthIndex, 0, 0, 0, 0)
    const firstDayOfNextMonth = new Date(this.displayingYear, this.displayingMonthIndex + 1, 1, 0, 0, 0)

    this.showPrevMonthButton = !this.minDate || DateUtils.isDateNotEarlier(lastDayOfPrevMonth, this.minDate)
    this.showNextMonthButton = !this.maxDate || DateUtils.isDateNotLater(firstDayOfNextMonth, this.maxDate)

    const now = new Date()
    const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
    const lastDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 0, 0, 0)
    const currentMonthAllowedByMinDate = !this.minDate || DateUtils.isDateNotEarlier(lastDayOfCurrentMonth, this.minDate)
    const currentMonthAllowedByMaxDate = !this.maxDate || DateUtils.isDateNotLater(firstDayOfCurrentMonth, this.maxDate)
    this.showGoToCurrentMonthLink = !this.isCurrentMonthShown && currentMonthAllowedByMinDate && currentMonthAllowedByMaxDate
  }

  setCalendarMonthByDate (date) {
    this.displayingDate = date
    this.displayingMonthIndex = date.getMonth()
    this.displayingYear = date.getFullYear()
    this.weeks = CalendarController.getVisibleWeeks(date)

    this.isCurrentMonthShown = this.displayingMonthIndex === this.now.getMonth() && this.displayingYear === this.now.getFullYear()

    this.calculateMonthButtonsVisibility()
  }

  static getStartOfTheWeek () {
    return DateConstants.START_OF_THE_WEEK_BY_LOCALE[localeHelper.getLocale()] || 0
  }

  static getWeekDaysTranslationKeys () {
    const localWeekDaysKeys = DateConstants.WEEKDAYS_TRANSLATION_KEYS.slice(0)
    const startOfWeek = CalendarController.getStartOfTheWeek()

    if (startOfWeek) {
      localWeekDaysKeys.push(localWeekDaysKeys.splice(0, startOfWeek))
    }

    return localWeekDaysKeys
  }

  static getVisibleWeeks (date) {
    const startMonth = date.getMonth()
    const startYear = date.getFullYear()
    const startOfWeek = CalendarController.getStartOfTheWeek()

    const beginningOfTheMonth = new Date(startYear, startMonth, 1, 0, 0, 0)

    if (beginningOfTheMonth.getDay() < startOfWeek) {
      // day is sunday, let's get back to the previous week
      beginningOfTheMonth.setDate(-DateConstants.WORKING_DAYS_COUNT)
    } else {
      // day is not sunday, let's get back to the start of the week
      beginningOfTheMonth.setDate(beginningOfTheMonth.getDate() - (beginningOfTheMonth.getDay() - startOfWeek))
    }

    const weeks = []
    while (weeks.length < DateConstants.MAX_WEEKS_PER_MONTH) {
      if (beginningOfTheMonth.getFullYear() === startYear && beginningOfTheMonth.getMonth() > startMonth) {
        break
      }
      const week = []
      for (let i = 0; i < DateConstants.WEEK_LENGTH; i++) {
        if (beginningOfTheMonth.getMonth() === startMonth || this.forceShowOtherMonthDates) {
          week.push(new Date(beginningOfTheMonth))
        } else {
          week.push(null)
        }

        beginningOfTheMonth.setDate(beginningOfTheMonth.getDate() + 1)
      }
      weeks.push(week)
    }

    return weeks
  }

  isNow (date) {
    return DateUtils.areTheSameDates(date, this.now)
  }

  isSelectedDate (date) {
    return DateUtils.areTheSameDates(date, this.selectedDate)
      || DateUtils.areTheSameDates(date, this.dateFrom)
      || DateUtils.areTheSameDates(date, this.dateTo)
  }

  isInRangeOfDates (date) {
    if (date && this.dateFrom && this.dateTo) {
      const dateFromTimestamp = this.dateFrom.getTime()
      const dateToTimestamp = this.dateTo.getTime()
      const dateTimestamp = date.getTime()
      return dateTimestamp >= dateFromTimestamp && dateTimestamp <= dateToTimestamp
    }

    return false
  }

  isDateFrom (date) {
    return DateUtils.areTheSameDates(date, this.dateFrom)
  }

  isDateTo (date) {
    return DateUtils.areTheSameDates(date, this.dateTo)
  }

  isAllowedToSelect (date) {
    if (date) {
      const isDateNotEarlierThanMinDate = this.minDate ? DateUtils.isDateNotEarlier(date, this.minDate) : true
      const isDateNotLaterThanMaxDate = this.maxDate ? DateUtils.isDateNotLater(date, this.maxDate) : true
      return isDateNotEarlierThanMinDate && isDateNotLaterThanMaxDate
    }

    return false
  }

  selectDate (date) {
    this.selectedDate = date
    this.onChange({date: new Date(date)})
  }

  changeMonth (direction) {
    const year = this.displayingDate.getFullYear()
    const monthIndex = this.displayingDate.getMonth()
    const newDateToDisplay = new Date(year, monthIndex + direction, 1)
    this.setCalendarMonthByDate(newDateToDisplay)
  }

  previousMonth () {
    this.changeMonth(-1)
  }

  nextMonth () {
    this.changeMonth(1)
  }

  goToCurrentMonth () {
    this.setCalendarMonthByDate(this.now)
  }

  stickMonthChange (direction) {
    this.stickTimeoutId = setTimeout(() => {
      this.changeMonth(direction)
      this.$scope.$digest()

      if (this.stickTimeoutId) {
        this.stickMonthChange(direction)
      }
    }, stickyMonthChangeIntervalMS)
  }

  stickPreviousMonth () {
    this.stickMonthChange(-1)
  }

  stickNextMonth () {
    this.stickMonthChange(1)
  }

  unstickMonthSwitching () {
    clearTimeout(this.stickTimeoutId)
    this.stickTimeoutId = null
  }
}

export default {
  templateUrl,
  controller: CalendarController,
  bindings: {
    date: '<?',
    dateFrom: '<?',
    dateTo: '<?',
    minDate: '<?',
    maxDate: '<?',
    isDisabled: '<',
    forceShowOtherMonthDates: '<',
    onChange: '&'
  }
}
