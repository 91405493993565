import templateUrl from './collapsible-titled-panel.pug'
import userSettings from 'data/services/userSettingsService'

class CollapsibleTitledPanelController {
  constructor () {
    this.expanded = true
  }

  $onInit () {
    // This check (and line 17) needs to be a type check as undefined could incorrectly set the expanded state to false.
    if (typeof this.defaultExpandedState === 'boolean') {
      this.expanded = this.defaultExpandedState
    }

    if (this.expandedStateUserSettingKey) {
      const setting = userSettings.getValue(this.expandedStateUserSettingKey)
      if (typeof setting === 'boolean') {
        this.expanded = setting
      }
    }
  }

  $onChanges (simpleChanges) {
    if (simpleChanges.hasOwnProperty('internalIsExpanded')) {
      this.expanded = simpleChanges.internalIsExpanded.currentValue
    }
  }

  onClick () {
    this.expanded = !this.expanded

    if (this.expandedStateUserSettingKey) {
      userSettings.setValue(this.expandedStateUserSettingKey, this.expanded)
    }
  }
}

export default {
  templateUrl,
  transclude: true,
  controller: CollapsibleTitledPanelController,
  bindings: {
    expandedStateUserSettingKey: '<?',
    defaultExpandedState: '<?',
    title: '@',
    spaceAbove: '<',
    spaceBeneath: '<?',
    internalIsExpanded: '<?'
  }
}
