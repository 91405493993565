import PlaceExit from 'data/domain-objects/PlaceExit'

class ExitEditorController {

  public exit: PlaceExit

  constructor (
    public $scope: ng.IScope,
  ) {}

  $onInit (): void {
  }

}

export default {
  templateUrl: require('./exit-editor.pug'),
  controller: ExitEditorController,
  bindings: {
    exit: '<'
  }
}
