import Driver from './Driver'
import ETATime from './ETATime'

export default class AllocationBooking {
  constructor(
    public used: boolean,
    public driver: Driver = null,
    public etaPickupTime: ETATime
  ) { }

  public static build(dto: any = {}): AllocationBooking {
    return new AllocationBooking(
      !!dto.used,
      dto.driver ? Driver.build(dto.driver) : null,
      dto.etaPickupTime ? ETATime.build(dto.etaPickupTime) : null
    )
  }
}
