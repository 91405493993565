import sortBy from 'lodash-es/sortBy'
import businessesCollection from 'data/collections/businessesCollection'
import Logger from 'common/Logger'
const logger = new Logger('Business and Branch select')

interface IBusinessBranchOnChange {
  businessId: string,
  branchId: string,
  addressId?: string
}

interface IBusinessOrBranchSuggestion {
  id: string,
  type: string,
  addressId?: string
}

interface IBusinessOrBranch {
  id: string,
  businessId?: string,
  type: string
  name: string
  className: string
  addressId?: string
}

class BusinessBranchSelectController {
  public branches: IBusinessOrBranch[] = []
  public visibleBranches: IBusinessOrBranch[] = []
  public selectedId: string = null
  public businessId: string = null
  public placeholder: string
  public hostClass: string
  public onlyBranches: boolean
  public onlyBusinesses: boolean
  public selectSingle: boolean
  public disabledBranchesList: string[]
  public disabledAddressIdList: string[]
  public onSelect: (data: {value: IBusinessBranchOnChange}) => void

  constructor (
    public $scope: ng.IScope,
    private $filter: ng.IFilterService
  ) {}

  $onInit (): void {
    if (!this.placeholder) {
      this.placeholder = this.$filter<ITranslateFilter>('translate')('COMPONENTS.BUSINESS_BRANCH_SELECT.ALL_BRANCHES')
    }

    if (!this.hostClass) {
      this.hostClass = 'business-branch-select'
    }

    this.getBusinessBranchesData().then(() => this.$scope.$digest())
  }

  getBusinessBranchesData(): Promise<void> {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then((businessesAndBranches: any) => {
        this.branches = []
        this.visibleBranches = []

        const allBranchesTitle = this.$filter<ITranslateFilter>('translate')('COMPONENTS.BUSINESS_BRANCH_SELECT.ALL_BRANCHES')

        sortBy(businessesAndBranches, ['name']).forEach((business: any) => {
          if (!this.onlyBranches) {
            this.branches.push({
              id: business.id,
              type: 'business',
              name: this.onlyBusinesses ? business.name : `${business.name} — ${allBranchesTitle}`,
              className: 'bbs_business'
            })
          }

          if (!this.onlyBusinesses) {
            sortBy(business.branches, ['name']).forEach((branch: any) => {
              this.branches.push({
                id: branch.id,
                businessId: branch.businessId,
                type: 'branch',
                name: `${business.name} - ${branch.name}`,
                className: this.onlyBranches ? '' : 'bbs_branch',
                addressId: this.onlyBranches ? branch.addressId : null
              })
            })
          }
        })

        if (this.selectSingle && this.branches.length === 1) {
          this.onChange(this.branches[0])
        }

        this.filterOutDisabled()
      })
      .catch((err: Error) => logger.error('Cannot get all businesses with branches', err))
  }

  $onChanges (changes: ng.IOnChangesObject): void {
    if (changes.disabledBranchesList || changes.disabledAddressIdList || changes.businessId) {
      this.filterOutDisabled()
    }
  }

  filterOutDisabled (): void {
    const disabledBranchesList = Array.isArray(this.disabledBranchesList) && this.disabledBranchesList || []
    const disabledAddressIdList = Array.isArray(this.disabledAddressIdList) && this.disabledAddressIdList || []
    this.visibleBranches = this.branches
      .filter((branch: any) => {
        return disabledBranchesList.indexOf(branch.id) === -1
          && disabledAddressIdList.indexOf(branch.addressId) === -1
          && (this.businessId ? branch.businessId === this.businessId : true)
      })
  }

  onChange (suggestion: IBusinessOrBranchSuggestion): void {
    let result: IBusinessBranchOnChange = {
      businessId: '',
      branchId: ''
    }
    if (suggestion !== null) {
      result = {
        businessId: suggestion.type === 'branch' ? '' : suggestion.id,
        branchId: suggestion.type === 'branch' ? suggestion.id : ''
      }
      if (this.onlyBranches) {
        result.addressId = suggestion.addressId
      }
    }

    this.selectedId = result.businessId || result.branchId || null
    this.onSelect({value: result})
  }
}

export default {
  templateUrl: require('./business-branch-select.pug'),
  controller: BusinessBranchSelectController,
  bindings: {
    selectedId: '<?',
    hostClass: '@?',
    businessId: '<?',
    disabledBranchesList: '<?',
    disabledAddressIdList: '<?',
    onlyBranches: '<?',
    onlyBusinesses: '<?',
    selectSingle: '<?',
    onSelect: '&',
    placeholder: '@?',
    isDisabled: '<?'
  }
}
