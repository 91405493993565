import IssuesFilters from 'data/domain-objects/IssuesFilters'

export default class IssueTrackerSettings {
  constructor (
    public activeIssuesFilters: IssuesFilters = new IssuesFilters(),
    public resolvedIssuesFilters: IssuesFilters = new IssuesFilters()
  ) {}

  static build (dto: any = {}): IssueTrackerSettings {
    return new IssueTrackerSettings(
      IssuesFilters.build(dto.activeIssuesFilters),
      IssuesFilters.build(dto.resolvedIssuesFilters)
    )
  }
}
