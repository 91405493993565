import templateUrl from './delete-dialog.pug'
import Logger from 'common/Logger'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'

export const DIALOG_ID = 'DELETE_DIALOG'
const logger = new Logger('Delete dialog')

// how many symbols do we want user to enter in order to confirm entity deletion
const CONFIRMATION_SYMBOLS_COUNT = 5

class DeleteDialogController {
  constructor ($scope) {
    this.$scope = $scope
    this.DIALOG_ID = DIALOG_ID
  }

  $onInit () {
    this.openModalDialogSubscription = modalDialogStateService.onOpenModalDialog(params => {
      this.payloadParams = {payload: params.payload}
      this.entityId = params.entityId
      this.secretCode = this.entityId.substring(this.entityId.length - CONFIRMATION_SYMBOLS_COUNT)
      this.secretRE = new RegExp(`^${this.secretCode}$`)
    })
  }

  $onDestroy () {
    this.openModalDialogSubscription.remove()
  }

  onDeleteBtnClick () {
    const payload = Object.assign({id: this.entityId}, this.payloadParams)

    this.onDelete(payload)
      .then(() => {
        this.hideDialog()
      })
      .catch(err => {
        logger.error('Error while deleting entity', this.entityId, err)
      })
      .then(() => {
        this.$scope.$apply()
      })
  }

  hideDialog () {
    this.secret_check = ''
    modalDialogStateService.emitCloseModalDialog({dialogId: DIALOG_ID})
  }
}

export default {
  templateUrl,
  controller: DeleteDialogController,
  bindings: {
    permission: '@',
    onDelete: '&' // must return promise
  }
}
