export default class ContactDetails {
  constructor(
    public fullName: string = '',
    public email: string = '',
    public phoneNumber: string = '',
    public countryDiallingCode: string = ''
  ) {}

  static build(contactDetailsDTO: any): ContactDetails {
    return new ContactDetails(
      contactDetailsDTO.fullName,
      contactDetailsDTO.email,
      contactDetailsDTO.phoneNumber,
      contactDetailsDTO.countryDiallingCode
    )
  }
}
