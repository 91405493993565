import templateUrl from './resolve-issue-dialog.pug'
import issuesCollection from 'data/collections/issuesCollection'
import {RESOLVE_ISSUE_DIALOG_EVENT} from 'common/constants/IssueEvents'
import BasePopupController from 'presentation/common/BasePopupController'

const RESOLVE_ISSUE_DIALOG_ID = 'resolve-issue-dialog'

class ResolveIssueDialogController extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, RESOLVE_ISSUE_DIALOG_EVENT, RESOLVE_ISSUE_DIALOG_ID)
    this.issueId = ''
    this.resolveComment = ''
    this.resolveIssueDialogId = RESOLVE_ISSUE_DIALOG_ID

    this.setupPopupOpenEventListener(issueId => {
      this.issueId = issueId
      this.resolveComment = ''
    })
  }

  resolve () {
    issuesCollection.resolveIssue(this.issueId, this.resolveComment)
      .then(() => {
        this.afterIssueResolved()
        this.close()
      })
      .catch(error => {
        this.logger.error('Cannot resolve delivery', error)
        this.onIssueResolutionFailed({error})
      })
  }
}

export default {
  templateUrl,
  controller: ResolveIssueDialogController,
  bindings: {
    afterIssueResolved: '&',
    onIssueResolutionFailed: '&'
  }
}
