import templateUrl from './delivery-info-page.pug'
import envConfig from 'common/envConfig'
import Logger from 'common/Logger'
import DeliveryDataService from 'data/common/deliveryDataService'
const logger = new Logger('Delivery Info Page')
const DEFAULT_REFRESH_DELIVERIES_INTERVAL_MS = 15000
const REFRESH_DELIVERY_INTERVAL_MS = envConfig.deliveryRefreshInterval || DEFAULT_REFRESH_DELIVERIES_INTERVAL_MS

class DeliveryInfoPage {
  constructor ($scope, $state, $stateParams) {
    this.$stateParams = $stateParams
    this.$scope = $scope
    this.$state = $state
  }

  $onInit () {
    this.deliveryId = this.$stateParams.deliveryId
    this.isConsumer = this.$state.$current.name.indexOf('main.orders.order') > -1
    this.syncDelivery(this.$stateParams.deliveryId || null)
    this.deliverySyncListener = DeliveryDataService.subscribeOnDeliverySync(() => {
      this.syncDelivery(this.$stateParams.deliveryId || null)
    })
  }

  clearRefreshTimeout () {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  $onDestroy () {
    this.clearRefreshTimeout()
    this.deliverySyncListener.remove()
  }

  syncDelivery (deliveryId) {
    this.clearRefreshTimeout()
    DeliveryDataService.getById(deliveryId, false)
      .then(delivery => {
        this.delivery = delivery
        this.deliveryId = delivery.id
        this.$scope.$digest()
      })
      .catch(error => {
        logger.error('Error loading delivery', error)
        this.delivery = null
        this.deliveryId = null
      })
      .then(() => {
        this.refreshTimeout = setTimeout(() => {
          if (this.deliveryId !== null) {
            this.syncDelivery(this.deliveryId)
          }
        }, REFRESH_DELIVERY_INTERVAL_MS)
      })
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$state', '$stateParams', DeliveryInfoPage]
}

