import endpoints from 'common/endpoints'
import agent from 'common/agent'
import Logger from 'common/Logger'

const logger = new Logger('Roles Service')

export function rolesServiceFactory (endpoints, agent) {
  function getRoleIdForHostname (hostname) {
    logger.info('Fetching roles for hostname:', hostname)
    return agent.get(endpoints.ROLES_SEARCH.endpointPath, null, {hostname})
      .then(getDefaultRoleIdGivenRoleDescriptors)
      .catch(handleFailedGetRoles)
  }

  function getRoleNameForHostname (hostname) {
    logger.info('Fetching roles for hostname:', hostname)
    return agent.get(endpoints.ROLES_SEARCH.endpointPath, null, {hostname})
      .then(getDefaultRoleNameGivenRoleDescriptors)
      .catch(handleFailedGetRoles)
  }

  function getDefaultRoleIdGivenRoleDescriptors (roleDescriptors) {
    const defaultRoleDescriptor = Array.isArray(roleDescriptors) ? roleDescriptors[0] : {}
    return defaultRoleDescriptor.id || ''
  }

  function getDefaultRoleNameGivenRoleDescriptors (roleDescriptors) {
    const defaultRoleDescriptor = Array.isArray(roleDescriptors) ? roleDescriptors[0] : {}
    return defaultRoleDescriptor.name || ''
  }

  function handleFailedGetRoles (error) {
    logger.error('Failed to fetch roles for hostname. Providing blank role ID.', error)
    return ''
  }

  return {
    getRoleIdForHostname,
    getRoleNameForHostname
  }
}

export default rolesServiceFactory(endpoints, agent)
