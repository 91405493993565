import templateUrl from './editable-list-currency-and-cost.pug'

export default {
  templateUrl,
  bindings: {
    backendValidationModel: '=',
    title: '@',
    prefix: '@',
    suffix: '@',
    valueMessage: '@',
    valueWarning: '@',
    regexValidatePattern: '<', // Optional
    form: '=',
    name: '@',
    model: '=',
    isEditing: '=',
    isRequired: '<',
    isDisabled: '<', // Optional
    placeholder: '@',
    min: '<', // Optional
    max: '<', // Optional
    allowNegative: '<', // Optional
    fixedCurrency: '<', // Optional
    hostClass: '@?',
    hideZeroValue: '<?'
  }
}
