import DateUtils from 'common/utils/dateUtils'
import Cost from '../Cost'
import MultiLangString from '../MultiLangString'

export default class StorefrontPromoCode {
  constructor(
    public id: string,
    public name: string,
    public totalLimit: number,
    public fixedDiscount: Cost,
    public percentDiscount: number,
    public validFrom: Date,
    public expiresAt: Date,
    public minimumOrderAmount: Cost,
    public isFreeDelivery: boolean,
    public hasHomepagePromotion: boolean,
    public title: MultiLangString,
    public description: MultiLangString,
    public promotionImageUrl: string,
    public buttonText: MultiLangString,
    public buttonTextColor: string,
    public buttonColor: string,
    public isButtonSolidBackground: boolean,
  ) {}

  static build (dto: any): StorefrontPromoCode {
    const validFrom = new Date(dto.validFrom)
    const expiresAt = new Date(dto.expiresAt)

    return new StorefrontPromoCode(
      dto.id,
      dto.name,
      dto.totalLimit,
      Cost.build(dto.fixedDiscount || {}),
      dto.percentDiscount || null,
      DateUtils.isValidDateObject(validFrom) ? (validFrom.getTime() ? validFrom : null) : null,
      DateUtils.isValidDateObject(expiresAt) ? (expiresAt.getTime() ? expiresAt : null) : null,
      Cost.build(dto.minimumOrderAmount || {}),
      !!dto.isFreeDelivery,
      !!dto.hasHomepagePromotion,
      MultiLangString.build(dto.title || {}),
      MultiLangString.build(dto.description || {}),
      dto.promotionImageUrl || '',
      MultiLangString.build(dto.buttonText || {}),
      dto.buttonTextColor,
      dto.buttonColor,
      !!dto.isButtonSolidBackground,
    )
  }

  toJSON(): any {
    return {
      ...this,
      id: undefined
    }
  }
}
