import orderBy from 'lodash-es/orderBy'
import cloneDeep from 'lodash-es/cloneDeep'
import findIndex from 'lodash-es/findIndex'
import GroupedDeliveries from 'data/domain-objects/GroupedDeliveries'
import KanbanDeliveryResponse from 'data/domain-objects/KanbanDeliveryResponse'
import {KanbanFiltersSettings} from 'data/domain-objects/KanbanSettings'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import areasCollection from 'data/collections/areasCollection'
import uiSettingsService from 'data/services/uiSettingsService'
import Logger from 'common/Logger'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import specificTimeToFilter from 'presentation/_core/specific-time-to/specificTimeToFilter'

const specificTimeTo = specificTimeToFilter()
const logger = new Logger('KanbanDataService')
const MS_IN_HOUR = 3600000
const LIMIT_FOR_USERS_WITH_OLD_FORMAT_SETTINGS = 30

class KanbanDataService {
  constructor () {
    this.settingsValues = uiSettingsService.kanbanSettings

    this.statusesList = [
      'notReady',
      'pending',
      'allocating',
      'allocated',
      'atPickup',
      'collected',
      'atDropoff',
      'delivered',
      'issue',
      'cancelled'
    ]

    this.сolumnsList = [
      'notReady',
      'pending',
      'allocating',
      'allocated',
      'atPickup',
      'collected',
      'atDropoff',
      'delivered',
      'issue'
    ]

    this.cardsList = [
      'driver_name',
      'driver_phone',
      'business',
      'branch',
      'area',
      'draewil_id',
      'pos',
      'estimations',
      'price',
      'recepient_name'
    ]

    this.mapStatusesList = [
      'allocated',
      'atPickup',
      'collected',
      'atDropoff'
    ]

    this.resetDeliveriesCache()
    this.defaultFiltersSettings = new KanbanFiltersSettings()
  }

  resetDeliveriesCache () {
    this.initialKanbanDataLoaded = false
    this.deliveriesCache = null
  }

  get kanbanMapStatusesList () {
    return this.mapStatusesList.slice(0)
  }

  get kanbanColumnsList () {
    return this.сolumnsList.slice(0)
  }

  get kanbanCardsList () {
    return this.cardsList.slice(0)
  }

  get settings () {
    return cloneDeep(this.settingsValues)
  }

  set settings (newSettings) {
    this.settingsValues = cloneDeep(newSettings)
    uiSettingsService.kanbanSettings = this.settingsValues
  }

  get filters () {
    return Object.assign({}, this.settingsValues.filters)
  }

  set filters (value) {
    this.settingsValues.filters = Object.assign({}, value)
    uiSettingsService.kanbanSettings = this.settingsValues
  }

  getChangedFiltersCount () {
    let changedCounter = 0
    for (const filter in this.filters) {
      if (this.filters[filter] !== this.defaultFiltersSettings[filter]) {
        changedCounter += 1
      }
    }
    return changedCounter
  }

  resetFilters () {
    this.filters = this.defaultFiltersSettings
  }

  getAreasList () {
    return areasCollection.getRealGeographicalAreasCached()
  }

  syncDeliveries (resetCache = false, status = null, skip = 0) {
    if (resetCache) {
      this.deliveriesCache = null
    }

    let statuses = []
    if (status === null) {
      statuses = this.statusesList.filter(status => this.settingsValues.board.hiddenColumns.indexOf(status) === -1)
    } else {
      statuses = [status]
    }

    if (this.settingsValues.board.hiddenColumns.indexOf(DELIVERY_STATUSES.ISSUE) > -1) {
      statuses = statuses.filter(status => (status !== DELIVERY_STATUSES.CANCELLED))
    }

    const filtersValues = this.filters // every time we ask for filter we create new object
    const initialLoad = this.deliveriesCache === null

    const requestParams = {
      businessId: filtersValues.businessId,
      branchId: filtersValues.branchId,
      pickupArea: filtersValues.pickupArea,
      dropoffArea: filtersValues.dropoffArea,
      fleetId: filtersValues.fleetId,
      startDate: new Date(Date.now() - this.settingsValues.board.period * MS_IN_HOUR),
      endDate: null,
      searchQuery: (filtersValues.searchQuery) ? filtersValues.searchQuery.replace(/\s/g, '') : null,
      limit: (typeof this.settingsValues.board.columnSize === 'number') ? this.settingsValues.board.columnSize : LIMIT_FOR_USERS_WITH_OLD_FORMAT_SETTINGS,
      initialLoad,
      statuses,
      skip,
      deliveriesType: filtersValues.deliveriesType
    }

    return deliveriesCollection.getKanbanData(requestParams)
      .then(flatListOfdeliveries => {
        if (!initialLoad) {
          this.initialKanbanDataLoaded = true

          // MERGE OF UPDATED DATA HAPPENS HERE
          flatListOfdeliveries.forEach(delivery => {
            const deliveryCacheIndex = findIndex(this.deliveriesCache, ['id', delivery.id])
            if (deliveryCacheIndex > -1) {
              this.deliveriesCache[deliveryCacheIndex] = delivery
            } else {
              this.deliveriesCache.push(delivery)
            }
          })
        } else {
          this.deliveriesCache = flatListOfdeliveries.slice(0)
        }

        this.deliveriesCache = this.deliveriesCache.map(delivery => {
          delivery.updatedAt = specificTimeTo(delivery.statusUpdatedAt, true, true, '')
          return KanbanDeliveryResponse.build(delivery)
        })

        const groupedDeliveries = new GroupedDeliveries(this.deliveriesCache)

        // MERGE OF ISSUES COLUMN
        if (groupedDeliveries.issue) {
          groupedDeliveries.issue = orderBy(groupedDeliveries.issue.concat(groupedDeliveries.cancelled), 'statusUpdatedAt', 'desc')
          delete groupedDeliveries.cancelled
        }

        const counters = {total: 0}
        Object.keys(groupedDeliveries).forEach(status => {
          counters[status] = groupedDeliveries[status].length
          counters.total += groupedDeliveries[status].length
        })

        return {
          counters,
          groupedByStatus: groupedDeliveries
        }
      })
      .catch(err => logger.error('Error syncing deliveries', err))
  }
}

export default KanbanDataService
