import templateUrl from './fleets-dashboard.pug'
import comparator from 'data/common/comparator'
import fleetsCollection from 'data/collections/fleetsCollection'
import BaseController from 'presentation/common/BaseController'

class FleetsDashboardController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.sync()
  }

  onSync () {
    return fleetsCollection.getAll()
      .then(fleets => {
        this.fleets = fleets.sort((fleetA, fleetB) => comparator.string(fleetA.name, fleetB.name))
      })
  }

  deleteFleet (params) {
    return fleetsCollection.delete(params.id)
      .catch(err => this.logger.error('Cannot delete fleet', err))
      .then(() => {
        this.sync()
      })
  }
}

export default {
  templateUrl,
  controller: FleetsDashboardController
}
