import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'

import {StateProvider} from '@uirouter/angularjs'

import storefrontDashboard from './dashboard/storefrontDashboard'
import branchesOrderProcessingManagement from './branches-order-processing-management/branchesOrderProcessingManagement'
import productPropertyDialog from './product-property-dialog/productPropertyDialog'
import productOptionDialog from './product-option-dialog/productOptionDialog'
import productExtraDialog from './product-extra-dialog/productExtraDialog'
import productValueDialog from './product-value-dialog/productValueDialog'
import subscriptionsControl from './subscriptions-control/subscriptionsControl'
import subscriptions from './subscriptions/subscriptions'
import categoryForm from './category-form/categoryForm'
import productForm from './product-form/productForm'
import categories from './categories/categories'
import products from './products/products'
import orderHistory from './order-history/orderHistory'
import orderPopup from './order-popup/orderPopup'
import orderPage from './order-page/orderPage'
import orders from './orders/orders'
import order from './order/order'
import printableOrder from './printable-order/printable-order'
import confirmPlanChangeDialog from './subscriptions-control/confirm-plan-change-dialog/confirmPlanChangeDialog'
import payouts from './payouts/payouts'
import bankAccountInfo from './payouts/bank-account-info/bankAccountInfo'
import payoutsManagement from './payouts/payouts-management/payoutsManagement'
import storefrontTransactionsReportPage from './storefront-transactions-report-page/storefrontTransactionsReportPage'
import deliveryTime from './delivery-time/deliveryTime'
import storefrontPromoCodes from './storefront-promo-codes/storefrontPromoCodes'
import storefronPromoCodeForm from './storefront-promo-code-form/storefronPromoCodeForm'
import printOrderA4Template from './print-order-a4-template/printOrderA4Template'
import printOrderThermoTemplate from './print-order-thermo-template/printOrderThermoTemplate'
import salesReport from './sales-report/salesReport'
import categorySummary from './category-summary/categorySummary'
import categoryInheritedSettings from './category-inherited-settings/categoryInheritedSettings'

export default declareAppModule('storefront', (module: ng.IModule) => module
    .component('confirmPlanChangeDialog', confirmPlanChangeDialog)
    .component('storefrontDashboard', storefrontDashboard)
    .component('branchesOrderProcessingManagement', branchesOrderProcessingManagement)
    .component('productPropertyDialog', productPropertyDialog)
    .component('productOptionDialog', productOptionDialog)
    .component('productExtraDialog', productExtraDialog)
    .component('productValueDialog', productValueDialog)
    .component('subscriptionsControl', subscriptionsControl)
    .component('subscriptions', subscriptions)
    .component('categoryForm', categoryForm)
    .component('productForm', productForm)
    .component('categories', categories)
    .component('products', products)
    .component('orderHistory', orderHistory)
    .component('orderPopup', orderPopup)
    .component('orderPage', orderPage)
    .component('orders', orders)
    .component('order', order)
    .component('printableOrder', printableOrder)
    .component('printOrderA4Template', printOrderA4Template)
    .component('printOrderThermoTemplate', printOrderThermoTemplate)
    .component('payouts', payouts)
    .component('bankAccountInfo', bankAccountInfo)
    .component('payoutsManagement', payoutsManagement)
    .component('storefrontTransactionsReportPage', storefrontTransactionsReportPage)
    .component('deliveryTime', deliveryTime)
    .component('storefrontPromoCodes', storefrontPromoCodes)
    .component('storefronPromoCodeForm', storefronPromoCodeForm)
    .component('salesReport', salesReport)
    .component('categorySummary', categorySummary)
    .component('categoryInheritedSettings', categoryInheritedSettings)
    .config(($stateProvider: StateProvider) => {

      // Main storefront state

      declareState($stateProvider, {
        parent: 'main',
        stateName: 'storefront',
        componentName: 'storefrontDashboard',
        stateKey: 'STOREFRONT',
        requiresPermission: 'STOREFRONT'
      })

      // List states

      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'categories',
        componentName: 'categories',
        stateKey: 'STOREFRONT_CATEGORIES',
        requiresPermission: 'STOREFRONT'
      })
      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'products',
        componentName: 'products',
        params: ['categoryId'],
        stateKey: 'PRODUCTS',
        requiresPermission: 'STOREFRONT'
      })
      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'orders',
        componentName: 'orders',
        stateKey: 'STOREFRONT_ORDERS',
        requiresPermission: 'STOREFRONT'
      })
      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'order-history',
        componentName: 'order-history',
        stateKey: 'STOREFRONT_ORDER_HISTORY',
        requiresPermission: 'STOREFRONT'
      })

      // Category states

      declareState($stateProvider, {
        parent: 'main.storefront.categories',
        stateName: 'create',
        componentName: 'categoryForm',
        params: ['businessId'],
        stateKey: 'STOREFRONT_CATEGORY_CREATE',
        requiresPermission: 'STOREFRONT_CREATE'
      })
      declareState($stateProvider, {
        parent: 'main.storefront.categories',
        stateName: 'category',
        idParam: 'categoryId',
        params: ['businessId'],
        componentName: 'categoryForm',
        stateKey: 'STOREFRONT_CATEGORY',
        requiresPermission: 'STOREFRONT_READ'
      })

      // Product states

      declareState($stateProvider, {
        parent: 'main.storefront.products',
        stateName: 'create',
        componentName: 'productForm',
        params: ['businessId'],
        stateKey: 'PRODUCT_CREATE',
        requiresPermission: 'STOREFRONT_CREATE'
      })
      declareState($stateProvider, {
        parent: 'main.storefront.products',
        stateName: 'product',
        idParam: 'productId',
        params: ['businessId'],
        componentName: 'productForm',
        stateKey: 'PRODUCT',
        requiresPermission: 'STOREFRONT_READ'
      })

      // Order state

      declareState($stateProvider, {
        parent: 'main.storefront.orders',
        stateName: 'order',
        idParam: 'orderId',
        params: ['businessId'],
        componentName: 'orderPage',
        stateKey: 'STOREFRONT_ORDER',
        requiresPermission: 'STOREFRONT_READ'
      })

      // Subscriptions

      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'subscriptions',
        componentName: 'subscriptions',
        stateKey: 'STOREFRONT_SUBSCRIPTIONS',
        requiresPermission: 'STOREFRONT'
      })

      // Payouts

      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'payouts',
        componentName: 'payouts',
        stateKey: 'STOREFRONT_PAYOUTS',
        requiresPermission: 'STOREFRONT'
      })


      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'transactions',
        componentName: 'storefrontTransactionsReportPage',
        stateKey: 'STOREFRONT_TRANSACTIONS',
        requiresPermission: 'STOREFRONT'
      })

      // Delivery time

      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'delivery-time',
        componentName: 'deliveryTime',
        stateKey: 'STOREFRONT_DELIVERY_TIME',
        requiresPermission: 'STOREFRONT'
      })

      // Storefront Promo Codes

      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'promo-codes',
        componentName: 'storefrontPromoCodes',
        stateKey: 'STOREFRONT_PROMO_CODES',
        requiresPermission: 'STOREFRONT'
      })

      declareState($stateProvider, {
        parent: 'main.storefront.promo-codes',
        stateName: 'create',
        componentName: 'storefronPromoCodeForm',
        params: ['businessId'],
        stateKey: 'STOREFRONT_PROMO_CODE_CREATE',
        requiresPermission: 'STOREFRONT_CREATE'
      })

      declareState($stateProvider, {
        parent: 'main.storefront.promo-codes',
        stateName: 'promo-code',
        idParam: 'promoCodeId',
        params: ['businessId'],
        componentName: 'storefronPromoCodeForm',
        stateKey: 'STOREFRONT_PROMO_CODE',
        requiresPermission: 'STOREFRONT'
      })

      declareState($stateProvider, {
        parent: 'main.storefront',
        stateName: 'sales-report',
        componentName: 'salesReport',
        stateKey: 'STOREFRONT_SALES_REPORT',
        requiresPermission: 'STOREFRONT'
      })
    })
)
