import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import moment from 'moment'

const areaAnalyticsByAreaService = new CrudService(endpoints.AREA_ANALYTICS_REPORT_BY_AREA)
const areaAnalyticsByBranchService = new CrudService(endpoints.AREA_ANALYTICS_REPORT_BY_BRANCH)
const HARD_PAGINATION_LIMIT = 1000

function areaAnalyticsFactory (areaAnalyticsByAreaService, areaAnalyticsByBranchService) {
  function getReportData (type, filters) {
    const params = {
      start: moment(filters.dateRange.startDate).set({second: 0, millisecond: 0}).toISOString(),
      finish: moment(filters.dateRange.endDate).set({second: 0, millisecond: 0}).toISOString(),
      limit: filters.limit || HARD_PAGINATION_LIMIT // TODO proper pagination
    }

    if (type === 'area') {
      return areaAnalyticsByAreaService.get([], params)
    }

    if (type === 'branch') {
      return areaAnalyticsByBranchService.get([], params)
    }
  }

  return {
    getReportData
  }
}

export default areaAnalyticsFactory(areaAnalyticsByAreaService, areaAnalyticsByBranchService)
