import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import {StateProvider} from '@uirouter/angularjs'

import usersDashboard from 'presentation/users/dashboard/usersDashboard'
import userSummary from 'presentation/users/team-members/user-summary/userSummary'
import teamMemberCreate from 'presentation/users/team-members/team-member-create/teamMemberCreate'
import teamMemberEdit from 'presentation/users/team-members/team-member-edit/teamMemberEdit'
import teamMembersDashboard from 'presentation/users/team-members/dashboard/teamMembersDashboard'
import accessControlBuilder from 'presentation/users/team-members/access-control-builder/accessControlBuilder'
import consumerHistory from 'presentation/users/consumer-history/consumerHistory'

export default declareAppModule('users', (module: ng.IModule) => module
  .component('teamMemberEdit', teamMemberEdit)
  .component('userSummary', userSummary)
  .component('teamMemberCreate', teamMemberCreate)
  .component('teamMembersDashboard', teamMembersDashboard)
  .component('accessControlBuilder', accessControlBuilder)
  .component('usersDashboard', usersDashboard)
  .component('consumerHistory', consumerHistory)

  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'users',
      componentName: 'usersDashboard',
      stateKey: 'USERS'
    })

    declareState($stateProvider, {
      parent: 'main.users',
      stateName: 'customer',
      idParam: 'id',
      redirectTo: 'main.dashboard',
      abstract: true
    })

    declareState($stateProvider, {
      parent: 'main.users.customer',
      stateName: 'transactions',
      componentName: 'consumerHistory',
      requiresPermission: 'SUPERADMIN'
    })

    declareState($stateProvider, {
      parent: 'main.users',
      stateName: 'user-management',
      stateKey: 'USER_MANAGEMENT',
      componentName: 'teamMembersDashboard',
      requiresPermission: 'USER_MANAGEMENT'
    })

    declareState($stateProvider, {
      parent: 'main.users.user-management',
      stateName: 'user',
      stateKey: 'TEAM_MEMBER_EDIT',
      idParam: 'teamMemberId',
      componentName: 'teamMemberEdit',
      requiresPermission: 'USER_MANAGEMENT'
    })

    declareState($stateProvider, {
      parent: 'main.users.user-management',
      stateName: 'create',
      stateKey: 'INVITE_USER',
      componentName: 'teamMemberCreate',
      requiresPermission: 'USER_MANAGEMENT_CREATE'
    })

  }),
  [
  ]
)
