import templateUrl from './delivery-collected-dialog.pug'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import {DELIVERY_ACTIONS} from 'data/collections/deliveriesCollection'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import BasePopupController from 'presentation/common/BasePopupController'
import DeliveryDataService from 'data/common/deliveryDataService'

class DeliveryCollectedDialog extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DELIVERY_ACTIONS.MARK_COLLECTED, deliveryDialogIds.COLLECTED_CONFIRMATION_DIALOG_ID)
    this.COLLECTED_CONFIRMATION_DIALOG_ID = deliveryDialogIds.COLLECTED_CONFIRMATION_DIALOG_ID
    this.setupPopupOpenEventListener(delivery => this.deliveryId = delivery.id)
  }

  confirm () {
    if (this.deliveryId) {
      DeliveryDataService.publishDeliveryLoadingEvent()
      deliveriesCollection.overrideAsCollected(this.deliveryId)
        .then(() => {
          DeliveryDataService.publishDeliveryStatusChangeEvent(DELIVERY_STATUSES.COLLECTED)
          this.onConfirm()
          DeliveryDataService.publishDeliverySyncEvent()
        })
        .catch(error => {
          this.logger.error('Cannot override delivery as collected', error)
          this.onConfirmFailed({error})
        })
    }
  }
}

export default {
  templateUrl,
  controller: DeliveryCollectedDialog,
  bindings: {
    onConfirm: '&',
    onConfirmFailed: '&'
  }
}
