import templateUrl from 'presentation/_core-forms/form-item/form-item.pug'

export default {
  templateUrl,
  bindings: {
    label: '@',
    valueMessage: '@',
    isRequired: '<',
    hideOptional: '<?'
  },
  transclude: true
}
