import permissionsModel from 'data/models/permissionsModel'
import Logger from 'common/Logger'

const MATCH_ANGULAR_EXPRESSION_REGEX = /^{{(.*)}}$/
const logger = new Logger('If Has Permission Directive')

/* if-has-permission Directive
 *
 * This directive acts like ng-if and 'turns off' the current component if the user doesn't have the named permission.
 * This allows us to write components that fetch their own data but never run unless they have permission.
 * This approach is taken from https://stackoverflow.com/a/24065378
 * Note that because if-uses uses transclusion, it will clash with components that use ng-transclude themselves. This is
 * because Angular doesn't allow multiple elements to transclude on the same element.
 */
export default function ifHasPermission (ngIfDirective) {
  const ngIf = ngIfDirective[0] // Angular passes it wrapped in an array
  return {
    transclude: ngIf.transclude,
    priority: ngIf.priority, // This means we will be linked before any attribute value has been evaluated. See below.
    terminal: ngIf.terminal,
    restrict: ngIf.restrict,
    link: function ($scope, $element, $attrs) {
      const permissionKey = getValidPermissionKey($scope, $attrs.ifHasPermission)
      $attrs.ngIf = () => getVisibilityGivenPermissions(permissionKey)
      ngIf.link.apply(ngIf, arguments)
    }
  }
}

function getValidPermissionKey ($scope, permissionString) {
  const angularExpressionMatch = MATCH_ANGULAR_EXPRESSION_REGEX.exec(permissionString)
  if (!permissionString) {
    logger.warn('Empty permission string passed to directive')
    return ''
  } else if (angularExpressionMatch) { // If we were passed an angular expression, evaluate its value
    return $scope.$eval(angularExpressionMatch[1]) // First matched group is at index 1.
  } else {
    return permissionString
  }
}

function getVisibilityGivenPermissions (permissionKey) {
  // If there's no permissions key, default to showing element
  return !permissionKey || permissionsModel.getHasPermission(permissionKey)
}
