import * as KanbanEvents from 'presentation/kanban/kanbanEvents'
import KanbanDataService from '../kanbanData'
import KanbanSettings, { KanbanFiltersSettings } from 'data/domain-objects/KanbanSettings'
import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

const COLUMN_SIZES = [10, 30, 50] // eslint-disable-line
const PERIODS = [8, 24, 72] // eslint-disable-line
const CARD_SIZES = [1, 2, 3, 4, 5] // eslint-disable-line
const DELIVERIES_TYPES = ['all', 'b2c', 'c2b']

class KanbanFilters {

  public isOpened: boolean = false
  public columnSizes: number[] = COLUMN_SIZES
  public periods: number[] = PERIODS
  public cardSizes:  number[] = CARD_SIZES
  public deliveriesTypes: string[] = DELIVERIES_TYPES
  public areasList: DBMappedNamedEntity[] = []
  public filters: KanbanFiltersSettings
  public settings: KanbanSettings
  public selectedBranch: string = null

  public onFiltersChange: (data: {}) => void

  constructor (
    private $scope: ng.IScope,
    private kanbanDataService: KanbanDataService
  ) {}

  $onInit (): void {
    this.$scope.$on(KanbanEvents.EVENT_OPEN_FILTERS, () => {
      this.filters = this.kanbanDataService.filters
      this.settings = this.kanbanDataService.settings
      this.selectedBranch = this.filters.branchId || this.filters.businessId || null
      this.open()
    })

    this.kanbanDataService.getAreasList().then((areas: DBMappedNamedEntity[]) => {
      this.areasList = areas
    })
  }

  setSearchQuery (value: string): void {
    this.filters.searchQuery = value
  }

  selectArea (filter: string, area: DBMappedNamedEntity): void {
    this.filters[filter] = area ? area.id : null
  }

  onBusinessBranchSelect (businessOrBranch: any): void {
    this.filters.businessId = businessOrBranch.businessId
    this.filters.branchId = businessOrBranch.branchId
    this.selectedBranch = this.filters.branchId || this.filters.businessId || null
  }

  onFleetSelect (fleet: any): void {
    this.filters.fleetId = fleet.id || null
  }

  close (): void {
    this.isOpened = false
    this.onFiltersChange({
      filters: this.filters,
      settings: this.settings
    })
  }

  open (): void {
    this.isOpened = true
  }
}

export default {
  templateUrl: require('./kanban-filters.pug'),
  controller: KanbanFilters,
  bindings: {
    isOpened: '<',
    onFiltersChange: '&',
    showFleetsFilter: '<'
  }
}
