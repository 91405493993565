/* globals require, google */
import templateUrl from './delivery-overview-map.pug'
import BaseMapController from 'presentation/common/BaseMapController'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import envConfig from 'common/envConfig'

const SVG_MARKERS = {
  DRIVER: require('!html-loader!./pins/driver.svg'),
  PICKUP: require('!html-loader!./pins/pickup.svg'),
  DROPOFF: require('!html-loader!./pins/dropoff.svg')
}

const DRIVER_MARKER_ZINDEX = 10
const DRIVER_MARKER_SIZE = 40
const PICKUP_MARKER_SIZE = 50
const DROPOFF_MARKER_SIZE = 50
const AUTO_RESET_ZOOM_TIMEOUT_MS = 1000 * 60 * 2 // eslint-disable-line

class DeliveryOverviewMapController extends BaseMapController {
  constructor ($scope, $element) {
    const mapElement = $element[0].querySelector('.js_map_container')
    const markerType = envConfig.googleMapsSettings.markerTypes.pin.name
    super($scope, mapElement, markerType)
    this.zoomChanged = false
  }

  $onInit () {
    this.initMap()
    this.syncMapMarkers()
    this.defaultMapZoomLevel = this.$scope.map.getZoom()

    this.$scope.map.addListener('zoom_changed', () => {
      this.zoomChanged = !!(this.defaultMapZoomLevel !== this.$scope.map.getZoom())
      if (this.zoomChanged) {
        setTimeout(() => {
          this.resetZoom()
        }, AUTO_RESET_ZOOM_TIMEOUT_MS)
      }
      setTimeout(() => this.$scope.$digest())
    })
  }

  $onChanges () {
    this.syncMapMarkers()
  }

  viewAllMarkers () {
    if (!this.$scope.map) {
      return
    }
    const bounds = new google.maps.LatLngBounds()
    const markerIds = Object.keys(this.renderedMapMarkers)

    markerIds.forEach(markerId => {
      if (this.renderedMapMarkers[markerId].marker && this.renderedMapMarkers[markerId].marker.position) {
        bounds.extend(this.renderedMapMarkers[markerId].marker.position)
      }
    })

    if (markerIds.length > 0 && !this.zoomChanged) {
      this.$scope.map.fitBounds(bounds)
    }
  }

  resetZoom () {
    if (this.defaultMapZoomLevel !== this.$scope.map.getZoom()) {
      this.$scope.map.setZoom(this.defaultMapZoomLevel)
    }
    this.zoomChanged = false
    this.viewAllMarkers()
  }

  syncMapMarkers () {
    if (this.driverLocation) {
      // do not show driver location for delivered/cancelled/issue deliveries
      if (!(this.status === DELIVERY_STATUSES.ISSUE || this.status === DELIVERY_STATUSES.DELIVERED || this.status === DELIVERY_STATUSES.CANCELLED)) {
        this.renderMarkerAtLocation('driversCurrentLocation', this.driverLocation, 'current-location', () => {}, SVG_MARKERS.DRIVER, DRIVER_MARKER_SIZE, DRIVER_MARKER_ZINDEX)
      } else {
        this.removeRenderedMarkerFromMap('driversCurrentLocation')
      }
    }

    if (this.dropOffLocation) {
      this.renderMarkerAtLocation('deliveryDropoffPoint', this.dropOffLocation, 'end-point', () => {}, SVG_MARKERS.DROPOFF, DROPOFF_MARKER_SIZE, DRIVER_MARKER_ZINDEX - 1)
    }

    if (this.pickUpLocation) {
      this.renderMarkerAtLocation('deliveryCollectionPoint', this.pickUpLocation, 'start-point', () => {}, SVG_MARKERS.PICKUP, PICKUP_MARKER_SIZE, DRIVER_MARKER_ZINDEX - 2)
    }

    this.viewAllMarkers()
  }

  $onDestroy () {
    this.clearMapListeners()
  }
}

export default {
  templateUrl,
  controller: DeliveryOverviewMapController,
  bindings: {
    status: '<?',
    driverLocation: '<',
    pickUpLocation: '<',
    dropOffLocation: '<',
    resetButtonTitle: '@?',
    fullHeightMode: '<?',
    resetZoomEnabled: '<?'
  }
}
