import Logger from 'common/Logger'
import {placesCollection} from 'data/collections/placesCollection'
import {StateParams} from '@uirouter/core/'
import Place from 'data/domain-objects/Place'

const logger = new Logger('Place View Controller')

class PlaceViewController {

  private placeId: string
  public place: Place
  public placeForm: ng.IFormController
  public isEditing: boolean = false
  private isLoading: boolean = false
  public isSubmitting: boolean = false

  constructor (
    private $stateParams: StateParams,
    private $scope: ng.IScope
  ) {}

  $onInit(): void {
    this.placeId = this.$stateParams.placeId
    this.getPlace()
  }

  get isPlaceFetchFailed(): boolean {
    return !this.isLoading && !this.place
  }

  public startEditing(): void {
    this.isEditing = true
  }

  private getPlace(): void {
    this.isLoading = true
    placesCollection.getPlace(this.placeId)
      .then((place: Place) => {
        this.place = place
      })
      .catch((error: Error) => {
        logger.error('Cannot retrieve Place', error)
      })
      .then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
  }

  public updatePlace(): void {
    this.isSubmitting = true
    if (this.placeForm.$valid) {
      placesCollection.updatePlace(this.place)
        .catch((error: Error) => {
          logger.error('There were some problems while updating Place. Place Id: ', this.placeId, error)
        })
        .then(() => {
          this.isSubmitting = false
          this.isEditing = false
          this.$scope.$digest()
        })
    }
  }
}

export default {
  templateUrl: require('./place-view.pug'),
  controller: PlaceViewController
}
