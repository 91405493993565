import envConfig from 'common/envConfig'
import { StateService } from '@uirouter/core'
import { isSameDomain } from 'common/utils/urlUtils'
import { DomainBasedRoles } from 'common/constants/DomainBasedRoles'

class HeaderLoggedOutController {
  public registerRoute: string = envConfig.registrationRoute
  public domainBasedRoles: typeof DomainBasedRoles = DomainBasedRoles
  public domainBasedRole: DomainBasedRoles

  constructor (
    private $state: StateService
  ) {}

  $onInit(): void {
    if (isSameDomain(envConfig.platformDomains.tazDeliveryDomain)) {
      this.domainBasedRole = DomainBasedRoles.TAZ
    } else if (isSameDomain(envConfig.platformDomains.sahalatDeliveryDomain)) {
      this.domainBasedRole = DomainBasedRoles.SAHALAT_EXPRESS
    } else if (isSameDomain(envConfig.platformDomains.storefrontAdminDomain)) {
      this.domainBasedRole = DomainBasedRoles.ORDER_DIRECT_ADMIN
    } else {
      this.domainBasedRole = DomainBasedRoles.DRAEWIL_DELIVERY_USER
    }
  }

  goToRegisterState (): void {
    this.$state.go(this.registerRoute)
  }
}

export default {
  templateUrl: require('./header-logged-out.pug'),
  controller: HeaderLoggedOutController
}
