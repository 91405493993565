import { StorefrontSubscriptionPlans } from 'common/constants/StorefrontSubscriptionPlans'
import { StorefrontSubscriptionStatuses } from 'common/constants/StorefrontSubscriptionStatuses'
import StorefrontSubscriptionDefinition from './StorefrontSubscriptionDefinition'
import StorefrontSubscription from './StorefrontSubscription'

type MappedStorefrontSubscriptionPlansType = {
  [key in StorefrontSubscriptionPlans]?: StorefrontSubscriptionDefinition
}

export default class StorefrontBusinessWithSubscription {
  constructor (
    public readonly businessId: string = '',
    public readonly name: string = '',
    public readonly status: StorefrontSubscriptionStatuses = null,
    public readonly subscription: StorefrontSubscription = new StorefrontSubscription(),
    public readonly subscriptionPlans: MappedStorefrontSubscriptionPlansType
  ) { }

  public static build(dto: any = {}): StorefrontBusinessWithSubscription {

    // validating subscription plans
    let subscriptionPlans: MappedStorefrontSubscriptionPlansType = null
    const subscriptionPlansKeys: string[] = Object.keys(dto.subscriptionPlans)
    const validKeys: string[] = Object.values(StorefrontSubscriptionPlans)

    if (subscriptionPlansKeys.length) {

      subscriptionPlansKeys.forEach((key: any) => {
        if (validKeys.includes(key)) {
          subscriptionPlans = {
            ...subscriptionPlans,
            ...{ [key]: StorefrontSubscriptionDefinition.build(dto.subscriptionPlans[key]) }
          }
        }
      })
    }


    return new StorefrontBusinessWithSubscription(
      dto.businessId || '',
      dto.name || '',
      dto.status,
      StorefrontSubscription.build(dto.subscription),
      subscriptionPlans
    )
  }
}
