import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import servicesService from 'data/services/servicesService'

const serviceService = new CrudService(endpoints.SERVICE)

export function servicesCollectionFactory (serviceService, servicesService) {
  return {
    get: function (serviceId) {
      return serviceService.get([serviceId])
    },
    getAll: function (searchTerm) {
      const searchParams = {}
      if (searchTerm) {
        searchParams.search = searchTerm
      }
      return servicesService.get([], searchParams)
    },
    create: function (serviceObject) {
      return serviceService.create(serviceObject)
    },
    update: function (serviceId, serviceObject) {
      return serviceService.update(serviceObject, [serviceId])
    },
    'delete': function (serviceId) {
      return serviceService.delete([serviceId])
    }
  }
}

export default servicesCollectionFactory(serviceService, servicesService)
