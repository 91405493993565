import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import authService from 'data/services/authService'
import rolesCollection from 'data/collections/rolesCollection'
import PubSub from 'common/PubSub'
import Logger from 'common/Logger'
import agent from 'common/agent'
import localeHelper from 'common/localeHelper'
import {getItemFromLocalStorage} from 'common/utils/storageUtils'
import envConfig from 'common/envConfig'
import * as UserRoles from 'common/constants/UserRoles'

const localeService = new CrudService(endpoints.LOCALE)
const userService = new CrudService(endpoints.USER)

const USER_CHANGED_EVENT = 'user-changed'
const LOCALE_STORAGE_KEY = 'draewilLocale'
const userModelPubSub = new PubSub()
const logger = new Logger('User Model')
let userDataPromise = null
const UNAUTHORIZED_HTTP_RESPONSE_STATUS = 401

export function userModelFactory (userService, authService, agent, rolesCollection) {
  let localUser = null

  function init () {
    agent.onUnauthedRequest(() => {
      logger.warn('Unauthorized request. Clearing local user and notifying listeners.')
      const savedLocale = getItemFromLocalStorage(LOCALE_STORAGE_KEY, envConfig.defaultLocaleCode)
      const localePromise = (savedLocale) ? Promise.resolve() : getServerLocale()
      localePromise.then(() => {
        setLocalUser(null)
      })
    })
  }

  function getServerLocale () {
    return localeService.get().then(localeResponse => localeHelper.onLocaleChange(localeResponse.locale))
  }

  function onUserChanged (callback) {
    return userModelPubSub.subscribe(USER_CHANGED_EVENT, callback)
  }

  function logIn (credentials) {
    logger.info('Logging user in with credentials.')
    return authService.login(credentials)
      .catch(err => {
        logger.error('Could not log in user.', err)
        return Promise.reject()
      })
      .then(() => getUser()) // Fetch and sync user upon login. Don't resolve until done.
  }

  function logInViaToken (token) {
    logger.info('Logging user in with token.', token)
    return authService.loginViaToken(token)
      .then(response => getUser(false, true).then(() => response))
      .catch(error => {
        logger.warn('Could not log user in with token.', error)
      })
  }

  function logOut () {
    logger.info('Logging user out.')
    return authService.logout()
      .then(response => {
        setLocalUser(null) // Clear cached user after successful logout
        return response
      })
  }

  function claimUser (password) {
    logger.info('Claiming user given password.')
    return authService.claimUser(password)
      .then(getUser) // Return synced user upon success
  }

  function setUserLocale (locale) {
    return getUser(true)
      .then(user => {
        if (user) {
          return syncUserLocale(user, locale)
        } else {
          logger.warn('No logged in user, saving locale locally')
          return localeHelper.onLocaleChange(locale).then(() => null)
        }
      })
  }

  function syncUserLocale (user, locale) {
    user.overrideLocale = locale
    return userService
      .update(user, [user.id])
      .then(updatedUserData => localeHelper.onLocaleChange(updatedUserData.overrideLocale).then(() => {
        setLocalUser(updatedUserData)
      }))
  }

  function getUser (ignoreLocale, ignoreRoles) {
    if (userDataPromise !== null) {
      return userDataPromise
    }
    let userData = null

    userDataPromise = userService.get()
      .then(user => {
        userData = user
        const onLocaleChangePromise = (!ignoreLocale && user.overrideLocale)
          ? localeHelper.onLocaleChange(user.overrideLocale)
          : Promise.resolve()

        const rolesPromise = ignoreRoles ? Promise.resolve([]) : rolesCollection.getRolesByIds(userData.roles)

        return onLocaleChangePromise.then(() => {
          return rolesPromise
        })
      })
      .then(userRoles => {
        userData.rolesList = userRoles
        userData.isConsumer = false
        userData.isSuperAdmin = false
        userData.isFleetManager = false
        userRoles.forEach(role => {
          if (role.name === UserRoles.CONSUMER) {
            userData.isConsumer = true
          } else if (role.name === UserRoles.SUPERADMIN) {
            userData.isSuperAdmin = true
          } else if (role.name === UserRoles.FLEET_MANAGER) {
            userData.isFleetManager = true
          }
          if (role.name === 'Superadmin') {
            userData.isSuperAdmin = true
          }
        })
        setLocalUser(userData)
      })
      .catch(err => {
        if (err && err.status && err.status !== UNAUTHORIZED_HTTP_RESPONSE_STATUS) {
          logger.error('Can\'t fetch user info', err)
        }
      })
      .then(() => {
        userDataPromise = null
        return cloneDeep(localUser)
      })

    return userDataPromise
  }

  function getLocalUser () {
    return localUser
  }

  function setLocalUser (user) {
    if (!isEqual(localUser, user)) {
      localUser = user
      userModelPubSub.publish(USER_CHANGED_EVENT, cloneDeep(localUser))
    }
  }

  function getUserRole () {
    return localUser && localUser.rolesList && localUser.rolesList[0] || null
  }

  init()

  return {
    getUser,
    onUserChanged,
    logIn,
    logInViaToken,
    logOut,
    claimUser,
    setUserLocale,
    getLocalUser,
    getUserRole
  }
}

export default userModelFactory(userService, authService, agent, rolesCollection)
