export const MONTHS_TRANSLATION_KEYS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
]

export const WEEKDAYS_TRANSLATION_KEYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]

export const START_OF_THE_WEEK_BY_LOCALE = {
  en_gb: 0,
  ar_kw: 0,
  ru_ru: 1
}

export const WEEK_LENGTH = 7
export const MAX_WEEKS_PER_MONTH = 6
export const WORKING_DAYS_COUNT = 5
