import endpoints from 'common/endpoints'
import Logger from 'common/Logger'
import CrudService from 'data/services/CrudService'

const logger = new Logger('Create Driver Service')
const driverService = new CrudService(endpoints.DRIVER)
const driverFindByUserService = new CrudService(endpoints.DRIVER_FIND_BY_USER)

let lastSearchResults = []

function searchForExistingAccounts (model) {
  logger.info('Searching for existing drivers.')
  const user = {
    email: model.email,
    countryDiallingCode: model.countryDiallingCode,
    phoneNumber: model.phoneNumber
  }

  return driverFindByUserService.create(user)
    .then(userResponse => searchForExistingAccountsSuccess(model, userResponse))
    .catch(error => {
      logger.error('Searching for existing drivers failed', error)
      return Promise.reject(error)
    })
}

function getLastSearchResults () {
  return lastSearchResults
}

function searchForExistingAccountsSuccess (model, response) {
  let promisedResponse

  if (response.length > 0) {
    lastSearchResults = response
    promisedResponse = lastSearchResults
  } else {
    promisedResponse = createNewDriverAccount(model)
  }

  return promisedResponse
}

function createNewDriverAccount (model) {
  logger.info('Creating new driver account')

  return driverService.create(model)
    .then(createNewDriverAccountSuccess)
    .catch(createNewDriverAccountFailed)
}

function createDriverFromUser (user) {
  logger.info('Createing new driver from user:', user)

  const model = {
    userId: user.userId
  }

  return driverService.create(model)
    .then(createNewDriverAccountSuccess)
    .catch(createNewDriverAccountFailed)
}

function createNewDriverAccountSuccess (response) {
  logger.info('Created new driver account')
  return response
}

function createNewDriverAccountFailed (error) {
  logger.error('Failed to create new driver account', error)
}

export default {
  create: searchForExistingAccounts,
  createDriverFromUser,
  getLastSearchResults
}

