import templateUrl from './businesses-dashboard.pug'
import BaseController from 'presentation/common/BaseController'
import branchesCollection from 'data/collections/branchesCollection'
import businessesCollection from 'data/collections/businessesCollection'
import permissionsModel from 'data/models/permissionsModel'
import {PERMISSIONS} from 'data/models/permissionsModel'
import featuresModel from 'data/models/featuresModel'

const STOREFRONT_FEATURE_FLAG = 'STOREFRONT'

class BusinessesDashboard extends BaseController {
  constructor ($scope, $state, $filter) {
    super($scope, $state)
    this.$filter = $filter
    this.areBranchesExpanded = false
    this.isStorefrontEnabled = featuresModel.isFeatureEnabled(STOREFRONT_FEATURE_FLAG) && permissionsModel.getHasPermission(STOREFRONT_FEATURE_FLAG)
    this.sync()
  }

  viewBranch (businessId, branchId) {
    this.$state.go('main.businesses.business.branches.branch', {businessId, branchId})
  }

  viewBusiness (businessId) {
    this.$state.go('main.businesses.business', {businessId})
  }

  onSync () {
    return businessesCollection.getBusinessesWithTheirBranches()
      .then(businesses => {
        this.businesses = businesses.map(business => {
          business.canEdit = business.permissions.indexOf(PERMISSIONS.EDIT) > -1
          return business
        })
      })
      .catch(err => this.logger.error('Cannot get all businesses with branches', err))
  }

  toggleBranches () {
    this.areBranchesExpanded = !this.areBranchesExpanded
  }

  deleteEntity (params) {
    if (params.payload.branchId && params.payload.businessId) {
      return branchesCollection.delete(params.payload.businessId, params.payload.branchId)
        .catch(err => this.logger.error('Cannot delete branch with specified business and branch IDs', err))
        .then(() => this.sync())
        .catch(err => this.logger.error('Cannot get businesses', err))
    }

    if (params.payload.businessId) {
      return businessesCollection.delete(params.payload.businessId)
        .catch(err => this.logger.error('Cannot delete business', err))
        .then(() => this.sync())
        .catch(err => this.logger.error('Cannot get businesses', err))
    }

    return Promise.reject('Payload params for business/branch deletion not recongised')
  }
}

export default {
  templateUrl,
  controller: BusinessesDashboard
}
