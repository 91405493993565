import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const distanceUnitService = new CrudService(endpoints.UNITS_DISTANCE)

export function measureUnitsCollectionFactory (
  distanceUnitService
) {
  return {
    getAvailableDistanceUnitCodes: function () {
      return distanceUnitService.get([], {useCache: true})
        .then(distanceUnits => distanceUnits.map(distanceUnit => distanceUnit.code))
    }
  }
}

export default measureUnitsCollectionFactory(
  distanceUnitService
)
