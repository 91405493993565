import DriverBaseFormController from 'presentation/logistics-drivers/common/DriverBaseFormController'
import { StateService, StateParams } from '@uirouter/core'

class DriverAddLicenseController extends DriverBaseFormController {
  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams,
  ) {
    super($scope, $state, $stateParams)
  }

  updateLogoUrl (imageUrl: string): void {
    this.model.photoUrl = imageUrl
  }

  afterSubmitSuccess (): Promise<void> {
    return Promise.resolve().then(() => this.goToAddVehicle())
  }
}

export default {
  templateUrl: require('./driver-add-license.pug'),
  controller: DriverAddLicenseController
}
