import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const userSubscriptionService = new CrudService(endpoints.USER_SUBSCRIPTION)

export function userSubscriptionCollectionFactory (userSubscriptionService) {
  function get () {
    // The endpoint will return a 404 if there is no subscription, so catch this and return null instead
    return userSubscriptionService.get().catch(() => null)
  }

  return {
    get: get,
    create: subscription => userSubscriptionService.create(subscription),
    update: subscription => userSubscriptionService.update(subscription)
  }
}

export default userSubscriptionCollectionFactory(userSubscriptionService)
