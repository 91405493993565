import templateUrl from './editable-phone-input.pug'

export default {
  templateUrl: templateUrl,
  replace: true,
  bindings: {
    backendValidationModel: '=',
    form: '=',
    isDisabled: '<',
    isEditing: '<',
    isRequired: '<',
    phoneNumber: '=',
    phoneNumberBackendValidationModel: '<',
    countryDiallingCode: '=',
    countryDiallingCodeBackendValidationModel: '<',
    name: '@',
    placeholder: '@',
    title: '@',
    valueMessage: '@',
    valueWarning: '@',
    hostClass: '@?'
  }
}
