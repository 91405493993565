import CrudService from './CrudService'
import endpoints from 'common/endpoints'
import Logger from 'common/Logger'

const logger = new Logger('Services Service')
const servicesCrud = new CrudService(endpoints.SERVICES)
const packageTypesCrud = new CrudService(endpoints.PACKAGE_TYPES)

function getServicesWithPackages (ids = [], params = {}) {
  return Promise.all([
    servicesCrud.get(ids, params),
    packageTypesCrud.get()
  ])
    .then(matchPackagesToServices)
    .catch(error => {
      logger.error('Could not retrieve all services:', error)
      return []
    })
}

function matchPackagesToServices ([services, packageTypes]) {
  return services.map(service => {
    service.packages = service.packages.map(servicePackage => {
      const matchedPackage = packageTypes.find(packageType => packageType.id === servicePackage.packageTypeId)
      return Object.assign({}, servicePackage, matchedPackage)
    })

    return service
  })
}

export default {
  create: servicesCrud.create,
  get: getServicesWithPackages,
  update: servicesCrud.update,
  'delete': servicesCrud.delete
}
