import isObjectLike from 'lodash-es/isObjectLike'
import Cost from 'data/domain-objects/Cost'
import * as PaymentMethods from 'common/constants/PaymentMethods'

export default function formatPaymentCostFilter () {
  function formatPaymentCost (paymentInformation) {
    // Cost may be a Cost instance, or a cost descriptor with either amount or value
    if (paymentInformation && paymentInformation.cost && isObjectLike(paymentInformation.cost)) {
      let cost = paymentInformation.cost
      if (!(paymentInformation.cost instanceof Cost)) {
        cost = Cost.build({
          currencyCode: paymentInformation.cost.currencyCode,
          amount: typeof paymentInformation.cost.value === 'number' ? paymentInformation.cost.value : cost.amount
        })
      }

      if (paymentInformation.method) {
        if (paymentInformation.method === PaymentMethods.PAID_OFF_SYSTEM || paymentInformation.method === PaymentMethods.CASH_OFF_SYSTEM) {
          return 'N/A'
        }
      }

      return cost.getFormattedCost()
    } else {
      return '-'
    }
  }

  return formatPaymentCost
}
