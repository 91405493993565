import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'
import AreaEditController from './area-edit/AreaEdit'
import AreaCreateController from './area-create/AreaCreate'
import AreasDashboard from './dashboard/areasDashboard'

export default declareAppModule('logistics.areas', (module: ng.IModule) => module
  .component('areasDashboard', AreasDashboard)
  .component('areaCreate', AreaCreateController)
  .component('areaEdit', AreaEditController)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'areas',
      componentName: 'areasDashboard',
      stateKey: 'AREAS',
      requiresPermission: 'AREAS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.areas',
      stateName: 'create',
      componentName: 'areaCreate',
      stateKey: 'CREATE_AREA',
      requiresPermission: 'AREAS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.areas',
      stateName: 'area',
      idParam: 'areaId',
      params: ['targetState'],
      componentName: 'areaEdit',
      stateKey: 'AREA'
    })
  })
)
