import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import tariffPeriodsDashboard from './dashboard/tariffPeriodsDashboard'
import TariffPeriodSummary from './dashboard/tariff-period-summary/tariffPeriodSummary'
import tariffPeriod from './tariff-period/tariffPeriod'
import daysPicker from './days-picker/daysPicker'

export default declareAppModule('logistics.tariffPeriods', (module: ng.IModule) => module
  .component('tariffPeriodsDashboard', tariffPeriodsDashboard)
  .component('tariffPeriodSummary', TariffPeriodSummary)
  .component('tariffPeriod', tariffPeriod)
  .component('daysPicker', daysPicker)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.logistics',
      stateName: 'tariff-periods',
      componentName: 'tariffPeriodsDashboard',
      stateKey: 'TARIFF_PERIODS',
      requiresPermission: 'TARIFF_PERIODS'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.tariff-periods',
      stateName: 'create',
      params: ['targetState'],
      componentName: 'tariffPeriod',
      stateKey: 'CREATE_TARIFF_PERIOD',
      requiresPermission: 'TARIFF_PERIODS_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.logistics.tariff-periods',
      stateName: 'tariff-period',
      idParam: 'tariffPeriodId',
      componentName: 'tariffPeriod',
      stateKey: 'TARIFF_PERIOD'
    })
  })
)
