import templateUrl from './confirm-dialog.pug'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'

class ConfirmDialogController {
  onCancelButtonClick () {
    modalDialogStateService.emitCloseModalDialog({dialogId: this.dialogId})
  }

  onConfirmButtonClick () {
    this.onConfirm()
    if (!this.noCloseOnConfirm) {
      modalDialogStateService.emitCloseModalDialog({dialogId: this.dialogId})
    }
  }
}

export default {
  templateUrl,
  controller: ConfirmDialogController,
  transclude: true,
  bindings: {
    titleKey: '@',
    dialogId: '<',
    onConfirm: '&',
    hostClass: '@',
    noCloseOnConfirm: '<'
  }
}
