import templateUrl from './message-panel.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    title: '@',
    message: '<',
    hostClass: '@?'
  }
}
