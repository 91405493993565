import templateUrl from './combined-phone-input.pug'
import BaseController from 'presentation/common/BaseController'
import diallingCodesCollection from 'data/collections/diallingCodesCollection'
import envConfig from 'common/envConfig'

class CombinedPhoneInput extends BaseController {
  constructor ($scope) {
    super($scope)

    this.diallingCodeValues = []
    this.diallingCodeStringsByDiallingCode = {}
    this.loadAndMapDiallingCodes()
    this.phoneNumberMinLength = envConfig.phoneNumberMinLength
  }

  loadAndMapDiallingCodes () {
    diallingCodesCollection.getAll()
      .then(diallingCodes => {
        this.diallingCodeValues = diallingCodes
        this.diallingCodeStringsByDiallingCode = this.getDiallingCodeStringsByDiallingCode(diallingCodes)
        this.$scope.$apply()
      })
      .catch(err => this.logger.error('Cannot get country dialling codes', err))
  }

  getDiallingCodeStringsByDiallingCode (diallingCodes) {
    const diallingCodeStringsByDiallingCode = {}
    diallingCodes.forEach(diallingCode => diallingCodeStringsByDiallingCode[diallingCode] = '+' + diallingCode)
    return diallingCodeStringsByDiallingCode
  }
}

export default {
  templateUrl,
  controller: CombinedPhoneInput,
  bindings: {
    name: '@',
    phoneNumber: '=',
    phoneNumberBackendValidationModel: '<',
    countryDiallingCode: '=',
    countryDiallingCodeBackendValidationModel: '<',
    form: '<',
    isRequired: '<',
    isDisabled: '<',
    isAutofocus: '<',
    label: '@',
    placeholder: '@',
    icon: '@',
    focusOnInit: '<'
  }
}
