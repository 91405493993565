import templateUrl from './address-auto-suggestion-list.pug'
import BaseController from 'presentation/common/BaseController'
import get from 'lodash-es/get'
import difference from 'lodash-es/difference'
import addressCollection from 'data/collections/addressCollection'
import * as AreasSettings from 'common/constants/AreasSettings'

const AREA_FIELD_NAME = 'area'

class AddressAutoSuggestionListController extends BaseController {
  constructor ($scope, $timeout) {
    super($scope)
    this.$timeout = $timeout

    this.validityKey = 'requiredAddressValueForField' + this.fieldId // Must be unique per-field
    this.values = []
    this.hasError = false
    this.isAwaitingDependentValue = false
    this.floatingLabelText = ''
    // Reset will occur on first watch after controller init
    this.$scope.$watch('$ctrl.dependentValues', () => this.resetFieldGivenDependentValue())
  }

  $onInit () {
    if (this.label && this.label.length > 0) {
      const optionalSuffix = (this.isRequired ? '' : ' (optional)')
      this.floatingLabelText = this.label + optionalSuffix
    }
  }

  $onChanges () {
    this.localModel = this.model
  }

  resetFieldGivenDependentValue () {
    this.logger.info(`Resetting with format id ${this.formatId}, field id ${this.fieldId} and dependent value`, this.dependentValues)
    const areValuesRequired = (!this.hasDependentValues || (this.hasDependentValues && !!this.dependentValues))

    this.values = []
    this.hasError = false
    this.isAwaitingDependentValue = this.hasDependentValues && !this.dependentValues

    this.form.$setValidity(this.validityKey, !this.isRequired) // If we're required, set invalid until we're synced

    const promisedValues = areValuesRequired ? this.getAddressFieldValues() : Promise.resolve([])
    promisedValues.then(response => this.handleAddressFieldValues(response))
      .catch(err => this.handleAddressFieldValuesError(err))
      .then(response => this.setFormValidity(response))
      .then(() => this.$scope.$apply())
  }

  getAddressFieldValues () {
    const queryParams = this.safeJsonParse(this.dependentValues) // NB. This should instead be using a bind-by-reference instead of parsing a string
    return addressCollection.getAddressFields(this.formatId, this.fieldId, queryParams)
  }

  handleAddressFieldValues (response) {
    const values = Array.isArray(response) ? response : []
    this.logger.info(`Retrieved field values for field "${this.name}" with dependent value ${this.dependentValue}`, values)

    this.values = values
    // If this is area field - then we need to make an intersection with all areas and areas pre-defined in b&b settings
    if (this.name === AREA_FIELD_NAME && this.availableAreas && this.availableAreas.length) {
      const showSettings = get(this, 'settings.showAreas.value', null)

      const castItemsToDropdownObject = (list, className = null) => {
        return list.map(item => {
          const res = {
            description: item
          }
          if (className !== null) {
            res.className = className
          }
          return res
        })
      }

      if (showSettings === AreasSettings.SHOW_AREA_SETTINGS_SHOW_SELECTED) {
        this.values = castItemsToDropdownObject(this.availableAreas)
      } else if (showSettings === AreasSettings.SHOW_AREA_SETTINGS_SHOW_ALL) {
        this.values = castItemsToDropdownObject(difference(this.values, this.availableAreas))

        this.availableAreas = castItemsToDropdownObject(this.availableAreas, 'c-autocomplete-input_suggestion--predefined')
        const splitter = {description: ' ', className: 'c-autocomplete-input_suggestion--splitter'}
        this.availableAreas.push(splitter)

        this.values = this.availableAreas.concat(this.values)
      }
    }
  }

  setFormValidity () {
    // Form is invalid if we had an error and this field is required
    const isValid = (!this.hasError || (this.hasError && !this.isRequired))
    this.form.$setValidity(this.validityKey, isValid)
    // Reset touched-based validation so we're not instantly invalid. User hasn't had a chance to pick an option yet.
    if (this.form[this.name]) { // Upon destroy, we may have been removed from form
      this.form[this.name].$setUntouched()
    }
  }

  handleAddressFieldValuesError (error) {
    this.logger.error('Could not retrieve field values for format and fieldId:', this.formatId, this.fieldId, error)
    this.hasError = true
  }

  safeJsonParse (rawJsonString) {
    const safeJsonString = (typeof rawJsonString === 'string' && !!rawJsonString) ? rawJsonString : '{}'
    let parsedObject = {}
    try {
      parsedObject = JSON.parse(safeJsonString)
    } catch (e) {
      this.logger.warn('Error parsing JSON, defaulting to empty object:', safeJsonString)
    }
    return parsedObject
  }

  onAutocompleteValueChange (suggestion) {
    if (suggestion) {
      this.localModel = suggestion
    }

    // We use timeout here to prevent the situation where address will not pass the validation check
    // Because text field of auto-suggestion component will be still empty when external model watcher will be fired
    this.$timeout(() => {
      this.model = suggestion ? suggestion : ''
      this.onFieldChanged({fieldName: this.name, value: suggestion})
    })
  }
}

export default {
  templateUrl,
  controller: AddressAutoSuggestionListController,
  bindings: { // Note that this doesn't handle backend validation.
    formatId: '@',
    fieldId: '@',
    hasDependentValues: '=',
    dependentValues: '@', // NB. This should be passed by value, but it goes into an infinite digest loop.
    model: '<',
    onFieldChanged: '&',
    isRequired: '=',
    isEditing: '=',
    isDisabled: '=',
    form: '=',
    title: '@',
    name: '@',
    valueMessage: '@',
    placeholder: '@',
    renderInline: '=',
    label: '@',
    availableAreas: '<',
    settings: '<?'
  }
}
