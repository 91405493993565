import templateUrl from './tag.pug'

class TagController {
  $onChanges () {
    if (this.showCounter && typeof this.count !== 'number') {
      this.count = 0
    }
  }

  click () {
    if (!this.readOnly && !this.isDisabled) {
      this.isSelected = !this.isSelected
      this.onClick({value: this.isSelected})
    }
  }
}

export default {
  templateUrl,
  controller: TagController,
  bindings: {
    hostClass: '@',
    showCounter: '<',
    isDisabled: '<',
    readOnly: '<',
    onClick: '&',
    count: '<',
    label: '@'
  }
}
