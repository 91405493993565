import cloneDeep from 'lodash-es/cloneDeep'
import envConfig from 'common/envConfig'
import ValidationUtils from 'common/utils/validationUtils'
import branchesCollection from 'data/collections/branchesCollection'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import addressCollection from 'data/collections/addressCollection'
import Address from 'data/domain-objects/Address'
import {StateParams, StateService} from '@uirouter/core'
import BranchEntity from 'data/domain-objects/BranchEntity'

class BranchController extends CreateViewEditFormController {

  public branchAddress: Address = null
  public branchAddressInitialState: Address = null
  public isEditing: boolean = false
  public submitFailedValidation: boolean = false
  public submitFailedOther: boolean = false
  public isAddressLoading: boolean = false
  public isUserHasPermissionToEdit: boolean = false

  public businessId: string = ''
  public branchId: string = ''
  public entity: BranchEntity
  public backendEntity: BranchEntity

  public addressSubmitHandler: () => {}
  public backendModel: BranchEntity = null

  constructor (
    $scope: ng.IScope,
    $state: StateService,
    $stateParams: StateParams
  ) {
    super($scope, $state, $stateParams, 'branchId')
    this.backendValidationErrors = {}
  }

  $onInit (): void {
    this.businessId = this.$stateParams.businessId
    this.branchId = this.$stateParams.branchId
    super.$onInit()

    if (this.isCreate) {

      this.entity = this.getDefaultBranchEntity()
      this.branchAddress = Address.build({})
      this.branchAddressInitialState = Address.build(this.branchAddress)
    }
  }

  getDefaultBranchEntity(): BranchEntity {
    const branchEntity = new BranchEntity()
    branchEntity.defaultCountryCode = envConfig.defaultCountryCode.toString()
    return branchEntity
  }

  getDefaultModel (): BranchEntity {
    return this.backendModel || this.getDefaultBranchEntity()
  }

  setModel (model: BranchEntity): void {
    this.backendEntity = cloneDeep(model)
    this.entity = cloneDeep(model)
  }

  getEntity (): Promise<any> {
    return branchesCollection.getWithAllLanguages(this.branchId)
      .then((branch: any) => {
        this.permissions = branch.permissions
        this.isUserHasPermissionToEdit = this.canEdit()
        this.backendEntity = cloneDeep(branch)
        this.entity = BranchEntity.build(branch)
        this.backendEntity.defaultCountryCode = envConfig.defaultCountryCode.toString()
        this.entity.defaultCountryCode = envConfig.defaultCountryCode.toString()
      })
      .catch((error: Error) => {
        this.logger.warn(`Could not retrieve entity with ids  ${this.businessId} ${this.branchId}`, error)
      })
      .then(() => {
        if (this.entity.addressId) {
          return this.getBranchAddress()
        }
        this.$scope.$digest()
      })
  }

  getBranchAddress (): void {
    this.isAddressLoading = true
    addressCollection.get(this.entity.addressId)
      .then((address: Address) => {
        this.branchAddress = address
        this.branchAddressInitialState = Address.build(address)
      })
      .catch((error: Error) => {
        this.logger.error('Could not retrieve branch address: ', error)
      })
      .then(() => {
        this.isAddressLoading = false
        this.$scope.$digest()
      })
  }

  startEdit (): void {
    this.isEditing = true
  }

  cancelEdit (): void {
    if (this.isCreate) {
      this.$state.go('main.businesses')
      return
    }
    this.isEditing = false
    this.submitFailedValidation = false
    this.submitFailedOther = false
    this.entity = cloneDeep(this.backendEntity)
    this.branchAddress = Address.build(this.branchAddressInitialState)
  }

  saveBranchAddress (): Promise<any> {
    return addressCollection.createAddress(this.branchAddress)
  }

  saveBranch (): Promise<BranchEntity> {
    if (this.isCreate) {
      return branchesCollection.create(this.entity, this.businessId)
        .then((createdBranch: BranchEntity) => {
          this.branchId = createdBranch.id
          return this.getEntity().then(() => createdBranch)
        })
    } else {
      return branchesCollection.update(this.branchId, this.entity)
    }
  }

  beforeSubmit (): Promise<any> {
    return this.saveBranchAddress()
      .then((branchAddress: Address) => {
        this.entity.addressId = branchAddress.id
        return this.saveBranch()
      })
      .then((updatedBranch: BranchEntity) => {
        this.handleSubmitSucceeded(updatedBranch)
      })
      .catch((error: Error) => this.handleSubmitFailed(error))
      .then(() => this.$scope.$digest())
  }

  handleSubmitSucceeded (updatedBranch: BranchEntity): void {
    this.isEditing = false
    this.submitFailedValidation = false
    this.submitFailedOther = false
    this.backendEntity = updatedBranch
    this.entity = cloneDeep(updatedBranch)
  }

  handleSubmitFailed (error: any): Promise<void> {
    this.logger.error('Save entity failed', error)
    this.isEditing = true

    const errorDescriptorArraysByFieldName = error && error.validationErrors
    if (errorDescriptorArraysByFieldName) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(errorDescriptorArraysByFieldName)
      this.submitFailedValidation = true
      this.submitFailedOther = false
    } else {
      this.backendValidationErrors = {}
      this.submitFailedValidation = false
      this.submitFailedOther = true
    }
    return Promise.reject(error)
  }

  attachAddressSubmitHandler (handler: any): void {
    this.addressSubmitHandler = handler
  }
}

export default {
  templateUrl: require('./branch.pug'),
  controller: BranchController
}
