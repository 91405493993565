import BaseController from 'presentation/common/BaseController'
import fleetsCollection from 'data/collections/fleetsCollection'
import Fleet from 'data/domain-objects/Fleet'
import {StateParams, StateService} from '@uirouter/core'

class FleetBalanceController extends BaseController {
  public fleet: Fleet = null
  public fleetId: string = ''

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    private $stateParams: StateParams
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.fleetId = this.$stateParams.fleetId
    this.sync()
  }

  onSync (): Promise<any> {
    return fleetsCollection.get(this.fleetId)
      .then((fleet: Fleet) => this.fleet = fleet)
      .catch((err: Error) => this.logger.error('Cannot get fleet', err))
  }
}

export default {
  templateUrl: require('./fleet-balance.pug'),
  controller: FleetBalanceController
}
