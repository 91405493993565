import ReportUtils from 'data/domain-objects/ReportUtils'

export class DriverAnalyticsItem {
  public id: string
  constructor (dto: any = {}, columns: any) {
    Object.assign(this, ReportUtils.parseReportDTO(dto, columns))
  }
}

export class DriverAnalyticsParams {
  constructor (
    public limit: number,
    public orderDirection: number,
    public orderField: string,
    public skip: number,
    public columns: any[]
  ) {}

  static build(dto: any = {}): DriverAnalyticsParams {
    return new DriverAnalyticsParams(
      dto.limit || 0,
      dto.orderDirection || 0,
      dto.orderField || '',
      dto.skip || 0,
      dto.columns || []
    )
  }
}

export default class DriverAnalyticsData {

  public drivers: IRecordsAndMetadata<DriverAnalyticsParams, DriverAnalyticsParams>
  public fleets: IRecordsAndMetadata<DriverAnalyticsParams, DriverAnalyticsParams>

  constructor (dto: any = {}, columns: any = null) {
    this.drivers = {
      records: [],
      metadata: DriverAnalyticsParams.build(dto.drivers.metadata)
    }
    this.fleets = {
      records: [],
      metadata: DriverAnalyticsParams.build(dto.fleets.metadata)
    }

    let columnsDefinition = columns
    if (columnsDefinition === null) {
      columnsDefinition = {
        drivers: this.drivers.metadata.columns,
        fleets: this.fleets.metadata.columns
      }
    }

    for (let i = 0, len = dto.drivers.records.length; i < len; i++) {
      const record = ReportUtils.parseReportDTO(dto.drivers.records[i], columnsDefinition.drivers)
      this.drivers.records[record.id] = record
    }

    for (let i = 0, len = dto.fleets.records.length; i < len; i++) {
      const record = new DriverAnalyticsItem(dto.fleets.records[i], columnsDefinition.fleets)
      this.fleets.records[record.id] = record
    }
  }

  merge (liveOrdersData: any): void {
    for (const i in liveOrdersData.drivers.records) {
      if (liveOrdersData.drivers.records.hasOwnProperty(i)) {
        this.drivers.records[i] = liveOrdersData.drivers.records[i]
      }
    }
    for (const i in liveOrdersData.fleets.records) {
      if (liveOrdersData.fleets.records.hasOwnProperty(i)) {
        this.fleets.records[i] = liveOrdersData.fleets.records[i]
      }
    }
  }
}
