import templateUrl from './drivers-history-popup.pug'
import {SHOW_DRIVER_CHANGES_HISTORY_EVENT} from 'common/constants/IssueEvents'
import BasePopupController from 'presentation/common/BasePopupController'

const DRIVERS_CHANGES_HISTORY_DIALOG_ID = 'drivers-changes-history-dialog'

class DriversChangesHistoryDialogController extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, SHOW_DRIVER_CHANGES_HISTORY_EVENT, DRIVERS_CHANGES_HISTORY_DIALOG_ID)

    this.initialDriver = {}
    this.currentDriver = {}
    this.driversChangesHistoryDialogId = DRIVERS_CHANGES_HISTORY_DIALOG_ID

    this.setupPopupOpenEventListener(data => {
      this.initialDriver = data.initialDriver
      this.currentDriver = data.currentDriver
    })
  }
}

export default {
  templateUrl,
  controller: DriversChangesHistoryDialogController,
  bindings: {
    onDriverClick: '&'
  }
}
