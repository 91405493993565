import templateUrl from './select-consumer-address.pug'
import moment from 'moment'
import envConfig from 'common/envConfig'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import BaseController from 'presentation/common/BaseController'

class SelectConsumerAddressController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.handleBeginAddNewAddress = () => this.onBeginAddNewAddress()
    this.handleCancelNewAddress = () => this.onEndAddNewAddress()
    this.handleNewAddressAdded = updatedConsumer => this.onNewAddressAdded(updatedConsumer)
    this.model = {
      selectedAddressId: null
    }
  }

  $onChanges (changeDescriptorsByKey) {
    const newConsumer = getValueFromChangeDescriptors(changeDescriptorsByKey, 'consumer')
    if (newConsumer) {
      this.consumer = newConsumer
      this.consumer.addresses = this.consumer.addresses
        .filter(address => !address.detached)
        .map(address => {
          address.date = moment(address.createdAt).format(envConfig.dateOnlyDateFormat)
          return address
        })

      this.consumerVerifiedAddresses = this.consumer.addresses
        .filter(address => address.verified)
        .map(address => address.id)
    }
  }

  onBeginAddNewAddress () {
    this.isAddingNewAddress = true
  }

  onEndAddNewAddress () {
    this.isAddingNewAddress = false
  }

  onNewAddressAdded (updatedConsumer) {
    this.consumer = updatedConsumer
    this.onEndAddNewAddress()
    this.onAddressAdded({consumer: this.consumer})
  }
}

export default {
  templateUrl,
  controller: SelectConsumerAddressController,
  bindings: {
    settings: '<?',
    consumer: '<',
    model: '=',
    availableAreas: '<',
    isAddingNewAddress: '=',
    showAddressLocationMap: '<',
    expandAddressLocationMap: '<',
    onAddressAdded: '&'
  }
}
