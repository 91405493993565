import templateUrl from './user-balance-top-up.pug'
import Cost from 'data/domain-objects/Cost'
import envConfig from 'common/envConfig'
import userModel from 'data/models/userModel'
import userCollection from 'data/collections/userCollection'
import BaseController from 'presentation/common/BaseController'
import scrollTo from 'common/scroll'

class UserBalanceTopupController extends BaseController {
  constructor ($scope, $state, $stateParams, $filter) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.$filter = $filter
    this.userBalance = null
    this.successfulTopUpAmount = null
  }

  $onInit () {
    this.isShowingPaymentFailedMessage = this.$stateParams.paymentFailed
    this.isShowingPaymentSuccessMessage = this.$stateParams.paymentSuccess && this.$stateParams.topUpAmount

    // KNET incoming parameters
    this.paymentId = this.$stateParams.paymentid
    this.postDate = this.$stateParams.postdate
    this.resultCode = this.$stateParams.result
    this.transactionId = this.$stateParams.tranid
    this.trackId = this.$stateParams.trackid
    this.auth = this.$stateParams.auth
    this.refNumber = this.$stateParams.ref
    this.dateTransactionCreatedAt = this.$stateParams.operdate
    this.paymentAmount = Cost.build({
      currencyCode: envConfig.defaultCurrencyCode,
      amount: parseFloat(this.$stateParams.topUpAmount)
    })
    this.paymentType = this.$stateParams.paymentType
    this.isKnetDirectPayment = this.paymentType === 'KNET direct'

    this.castSuccessfulTopUpAmountToCost()
    this.syncBalance()

    this.message = (this.isShowingPaymentSuccessMessage)
      ? `${this.$filter('translate')('COMPONENTS.USER_BALANCE_TOP_UP.PAYMENT_SUCCESS.ADDED')} ${this.$filter('formatCost')(this.successfulTopUpAmount)} ${this.$filter('translate')('COMPONENTS.USER_BALANCE_TOP_UP.PAYMENT_SUCCESS.TO_BALANCE')}`
      : this.$filter('translate')('COMPONENTS.USER_BALANCE_TOP_UP.PAYMENT_FAILED.MESSAGE')

    // update user balance when parent component triggers update
    this.$scope.$watch('$ctrl.triggerDataReload', () => this.syncBalance())
  }

  castSuccessfulTopUpAmountToCost () {
    if (this.$stateParams.topUpAmount) {
      this.successfulTopUpAmount = Cost.build({
        currencyCode: envConfig.defaultCurrencyCode,
        amount: parseFloat(this.$stateParams.topUpAmount)
      })
    }
  }

  syncBalance () {
    userCollection.getBalance(userModel.getLocalUser().id)
      .then(balance => {
        const balanceDto = {
          amount: balance.lastBalance.value,
          currencyCode: balance.lastBalance.currencyCode
        }
        this.userBalance = Cost.build(balanceDto)
      })
      .then(() => this.$scope.$apply())
      .catch(err => this.logger.error('Cannot get user balance', err))
  }

  showNewPaymentFailedMessage () {
    this.isShowingPaymentFailedMessage = true
    scrollTo(0)
    this.$scope.$apply()
  }

  reload () {
    this.reloadFunc()
    this.syncBalance()
  }
}

export default {
  templateUrl,
  controller: UserBalanceTopupController,
  bindings: {
    triggerDataReload: '<?',
    reloadFunc: '&',
    presetTopUpAmounts: '<',
    paymentProcessedPath: '<'
  }
}
