import { MathUtils, Point } from 'common/utils/mathUtils'

export default class GeoJSONPolygon {
  public readonly type: string = 'Polygon'

  constructor (
    public coordinates: number[][][] = []
  ) {}

  public static build (dto: any = {}): GeoJSONPolygon {

    let coordinates = []

    if (Array.isArray(dto.coordinates)) {

      coordinates = dto.coordinates.map((ring: number[][]) => {
        const newRing = ring.slice()
        newRing.map((point: number[]) => point.slice())
        if (newRing[0][0] === newRing[newRing.length - 1][0] && newRing[0][1] === newRing[newRing.length - 1][1]) {
          newRing.pop()
        }

        return newRing
      })
    }

    return new GeoJSONPolygon(
      coordinates
    )
  }

  public toJSON(): any {
    const coordinates = this.coordinates.map((ring: number[][]) => {
      const newRing = ring.slice()
      newRing.map((point: number[]) => point.slice())
      newRing.push(newRing[0])
      return newRing
    })

    return {
      ...this,
      coordinates
    }
  }

}
