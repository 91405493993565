// Taken from https://stackoverflow.com/a/27050315
// The restricted input pattern must be passed as a string in to restricted-input-pattern="<character class pattern>"
// The character class pattern will then be wrapped in a disallowed character class declaration as below
// This attribute must be used on an input with ng-trim="false" to allow whitespace to be blocked by the regex
export default function restrictedInputPattern () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function ($scope, $element, $attrs, modelControl) {
      const pattern = $attrs.restrictedInputPattern
      const disallowedValuesRegex = new RegExp(pattern, 'g') // Prepend caret to disallow values and wrap in character class

      modelControl.$parsers.push(function (inputValue) {
        const transformedInput = inputValue ? inputValue.replace(disallowedValuesRegex, '') : null

        if (transformedInput !== inputValue) {
          modelControl.$setViewValue(transformedInput)
          modelControl.$render()
        }

        return transformedInput
      })
    }
  }
}
