import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import User from 'data/domain-objects/User'

const userService = new CrudService(endpoints.USER)
const userBalanceService = new CrudService(endpoints.USER_BALANCE)
const userTransactionsHistoryService = new CrudService(endpoints.USER_TRANSACTIONS_HISTORY)
const enterpriceWebUserService = new CrudService(endpoints.ENTERPRICE_WEB_USER)
const useCacheParams = {useCache: true}

export default {
  create (entity: any): Promise<User> {
    return enterpriceWebUserService.create(entity)
      .then((userDTO: object) => User.build(userDTO))
  },
  getCached (id: string): Promise<User> {
    return userService.get([id], useCacheParams)
      .then((userDTO: object)  => User.build(userDTO))
  },
  getBalance (id: string): Promise<any> {
    return userBalanceService.get([id])
  },
  getHistory (id: string, filters: any): Promise<any> {
    return userTransactionsHistoryService.get([id], filters)
  }
}
