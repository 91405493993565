import Logger from 'common/Logger'

const logger = new Logger('UserSettings Service')
const USER_SETTINGS_KEY = 'draewilUserSettings'

class UserSettings {
  constructor () {
    this.store = {}
    this.load()
  }

  getValue (key) {
    return this.store[key]
  }

  setValue (key, value) {
    this.store[key] = value
    this.save()
  }

  save () {
    window.localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify(this.store))
  }

  load () {
    const val = window.localStorage.getItem(USER_SETTINGS_KEY)
    if (val) {
      try {
        const settings = JSON.parse(val)
        this.store = settings
      } catch (e) {
        logger.warn(e)
      }
    }
  }
}

const userSettings = new UserSettings()
export default userSettings
