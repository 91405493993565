import StorefrontOrderEvent from './StorefrontOrderEvent'
import { StorefrontOrdersStatusesType } from 'common/constants/StorefrontOrderStatuses'
import StorefrontOrderCost from './StorefrontOrderCost'
import StorefrontOrderBranch from './StorefrontOrderBranch'
import StorefrontOrderContactDetails from './StorefrontOrderContactDetails'
import DateUtils from 'common/utils/dateUtils'
import Cost from '../Cost'
import StorefrontOrderShippingDetails from './StorefrontOrderShippingDetails'
import StorefrontDiscount from './StorefrontDiscount'
import StorefrontPromocode from './StorefrontAppliedPromoCode'
import StorefrontGoodsPreparationTime from './StorefrontGoodsPreparationTime'
import { ShippingMethods } from 'common/constants/ShippingMethods'
import { ShippingProviders } from 'common/constants/ShippingProviders'
import StorefrontOrderPayment from './StorefrontOrderPayment'

export default class StorefrontOrder {
  constructor(
    public id: string = '',
    public createdAt: Date,
    public updatedAt: Date,
    public branches: StorefrontOrderBranch[] = [],
    public branch: StorefrontOrderBranch,
    public goodsPreparationTime: StorefrontGoodsPreparationTime,
    public consumerId: string = '',
    public contactDetails: StorefrontOrderContactDetails,
    public posReference: string,
    public status: StorefrontOrdersStatusesType,
    public totalPrice: Cost,
    public cutlery: boolean = false,
    public items: any[] = [], // since this is not an exact Product format we used previously
    public shipping: StorefrontOrderShippingDetails,
    public surcharges: any,
    public payment: StorefrontOrderPayment,
    public qrData: string,
    public events: StorefrontOrderEvent[],
    public discounts: StorefrontDiscount[],
    public businessId: string,
    public cost: StorefrontOrderCost,
  ) { }

  public get isASAP(): boolean {
    return this.shipping.desiredTime === null
  }

  public get appliedPromocode(): StorefrontPromocode | null {
    if (this.discounts) {
      const discountWithPromocode = this.discounts.find((d: StorefrontDiscount): boolean => !!d.promoCode)

      if (discountWithPromocode) {
        return discountWithPromocode.promoCode
      }
    }

    return null
  }

  public get shouldCreateDeliveryWhileAccept(): boolean {
    return this.shipping.method === ShippingMethods.delivery &&
           this.shipping.provider !== ShippingProviders.other
  }

  public isToday(dateString: string): boolean {
    return DateUtils.getIsToday(new Date(dateString).getTime())
  }

  static build(dto: any = {}): StorefrontOrder {
    return new StorefrontOrder(
      dto.id,
      DateUtils.makeSafeDateFromString(dto.createdAt),
      DateUtils.makeSafeDateFromString(dto.updatedAt),
      (dto.branches || []).map(StorefrontOrderBranch.build),
      StorefrontOrderBranch.build(dto.branch),
      StorefrontGoodsPreparationTime.build(dto.goodsPreparationTime),
      dto.consumerId,
      StorefrontOrderContactDetails.build(dto.contactDetails || {}),
      dto.posReference,
      dto.status,
      Cost.build(dto.totalPrice),
      dto.cutlery,
      dto.items,
      dto.shipping ? StorefrontOrderShippingDetails.build(dto.shipping || {}) : null,
      dto.surcharges,
      StorefrontOrderPayment.build(dto.payment),
      dto.qrData || '',
      (dto.events || []).map(StorefrontOrderEvent.build),
      (dto.discounts || []).map(StorefrontDiscount.build),
      dto.businessId,
      StorefrontOrderCost.build(dto.cost || {})
    )
  }
}
