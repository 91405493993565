export default class LatLongPoint {
  constructor (public lat: number = 0, public long: number = 0) {}

  static build (dto: any = {}): LatLongPoint {
    return new LatLongPoint(
      parseFloat(dto.lat) || 0,
      parseFloat(dto.long) || 0
    )
  }
}
