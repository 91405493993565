import envConfig from 'common/envConfig'
import authService from 'data/services/authService'
import BaseFormController from 'presentation/common/BaseFormController'
import { StateService } from '@uirouter/core'

class ClaimAccountUnauthedController extends BaseFormController {

  public submitting: boolean = false
  public requestSuccess: boolean = false
  public requestFailedCode: boolean = false
  public requestFailedPassword: boolean = false
  public requestFailedOther: boolean = false
  public registerRoute: string = envConfig.registrationRoute
  public model: any = {
    code: '',
    newPassword: ''
  }

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)
  }

  beforeSubmit (): Promise<any> {
    // This has to use the 'reset password' API until the claim account API is provided.
    this.submitting = true
    return authService.setNewPassword(this.model.code, this.model.newPassword)
  }

  afterSubmitSuccess (): Promise<any> {
    this.submitting = false
    this.requestSuccess = true
    this.requestFailedCode = false
    this.requestFailedPassword = false
    this.requestFailedOther = false
    return Promise.resolve()
  }

  afterSubmitFailure (): Promise<any> {
    this.submitting = false
    this.requestSuccess = false
    this.requestFailedCode = !!this.backendValidationErrors.code
    this.requestFailedPassword = !!this.backendValidationErrors.newPassword
    this.requestFailedOther = !(this.requestFailedCode || this.requestFailedPassword)
    return Promise.resolve()
  }

  goToRegisterState (): void {
    this.$state.go(this.registerRoute)
  }
}

export default {
  templateUrl: require('./claim-account-unauthed.pug'),
  controller: ClaimAccountUnauthedController
}
