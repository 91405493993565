import templateUrl from './orders-by-day-graph.pug'
import moment from 'moment'
import get from 'lodash-es/get'
import maxBy from 'lodash-es/maxBy'
import envConfig from 'common/envConfig'
import DateUtils from 'common/utils/dateUtils'
import reportLiveOrdersCollection from 'data/collections/reportLiveOrdersCollection'
import BaseController from 'presentation/common/BaseController'

const UPDATE_INTERVAL_MS = get(envConfig, 'reports.ordersByDayGraph.updateIntervalInMs')
const DAY_COUNT = get(envConfig, 'reports.ordersByDayGraph.daysLimit')
const PERCENTAGE_CONVERSION_FACTOR = 100

class OrdersByDayGraph extends BaseController {
  constructor ($scope, $timeout) {
    super($scope)
    this.$timeout = $timeout

    this.total = 0
    this.days = []
    this.updateTimeout = null

    this.sync()
  }

  onSync () {
    return reportLiveOrdersCollection.getDaily(DAY_COUNT)
      .then(response => this.handleResponse(response, DAY_COUNT))
      .catch(err => this.logger.error('Could not fetch daily live orders for day count', DAY_COUNT, err))
      .then(() => this.scheduleUpdate()) // Schedule next update only when done, to guard against delayed responses
  }

  handleResponse (response, dayCount) {
    // Response may return fewer OR more days than we asked for, so only take as many as we asked for
    //
    // response.records is sorted in the wrong order. And sorting order is hardcoded on the backend
    // when I asked BE guys to fix sorting they challenged FE whether FE guys knows how to reverse array
    // I accepted the challenge… thus we have .reverse() below
    const partialRecordsForPeriod = Array.isArray(response.records) ? response.records.reverse().slice(0, dayCount) : []
    const fullRecordsForPeriod = OrdersByDayGraph.getFullRecordsForPeriod(partialRecordsForPeriod, dayCount)

    const periodStartDate = moment().subtract(dayCount, 'day')
    const totalOrdersCount = fullRecordsForPeriod.reduce((accum, record) => record.count + accum, 0)
    const maxCountDay = maxBy(fullRecordsForPeriod, 'count') || {}
    const maxOrdersCount = maxCountDay.count || 0

    this.total = totalOrdersCount
    this.days = fullRecordsForPeriod.map((record, index) => OrdersByDayGraph.getDayForRecord(record, index, periodStartDate, maxOrdersCount))
  }

  static getFullRecordsForPeriod (dayRecords, limit) {
    const missingDaysCount = limit - dayRecords.length
    const stubDayRecord = {count: 0}
    const missingDayRecords = missingDaysCount > 0 ? new Array(missingDaysCount).fill(stubDayRecord) : []
    return missingDayRecords.concat(dayRecords)
  }

  static getDayForRecord (record, index, periodStartDate, maxOrdersCount) {
    const dateForDay = (record.date) ? DateUtils.getFormattedDate(moment(record.date)) : null
    const dayOrdersCount = record.count || 0
    const safeMaxOrdersCount = maxOrdersCount || 1 // Guard against zero

    return {
      date: dateForDay,
      count: dayOrdersCount,
      maxOrderPercentage: Math.round(dayOrdersCount * PERCENTAGE_CONVERSION_FACTOR / safeMaxOrdersCount)
    }
  }

  scheduleUpdate () {
    this.$timeout.cancel(this.updateTimeout)
    this.updateTimeout = this.$timeout(() => this.sync(), UPDATE_INTERVAL_MS)
  }

  $onDestroy () {
    this.$timeout.cancel(this.updateTimeout)
  }
}

export default {
  templateUrl,
  controller: OrdersByDayGraph
}
