import find from 'lodash-es/find'
import templateUrl from './delivery-change-address.pug'
import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import BaseController from 'presentation/common/BaseController'
import branchesCollection from 'data/collections/branchesCollection'
import consumerCollection from 'data/collections/consumerCollection'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'
import addressCollection from 'data/collections/addressCollection'

const deliveryQuoteService = new CrudService(endpoints.DELIVERY_QUOTE)

class DeliveryChangeAddressController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)

    this.delivery = {}
    this.consumer = null
    this.hasDataSyncError = false // We'll set this if we can't get the required data
    this.submitFailed = false

    // General Data
    this.deliveryId = this.$state.params.deliveryId || null

    // Delivery Address Data
    this.newDeliveryAddressId = null
    this.isConsumerAddress = false
    this.newConsumerDeliveryAddress = null // Only used if it's a consumer address
    this.tempNewConsumerDeliveryAddressId = null // Keep ID for use after edit but before confirmation
  }

  $onInit () {
    this.isLoading = true
    // Event Handlers
    this.attachAddressSubmitHandler = function () {} // Required by dynamic address
    this.closeModalDialogSubscription = modalDialogStateService.onCloseModalDialog(() => this.handleCancelChangeDeliveryAddress())

    // Sync or redirect
    if (this.deliveryId) {
      this.promiseInitialData(this.deliveryId)
    } else {
      this.redirectGivenNoDelivery()
    }
  }

  redirectGivenNoDelivery () {
    this.logger.warn('Reached state without a delivery id, redirecting away.')
    this.$state.go('^')
  }

  promiseInitialData (deliveryId) {
    return deliveriesCollection.getById(deliveryId)
      .then(delivery => {
        return Promise.all([
          delivery,
          consumerCollection.get(delivery.originatingConsumer, true),
          branchesCollection.getAllWithoutAddresses()
        ])
      })
      .then(([delivery, consumer, branches]) => {
        this.delivery = delivery
        this.consumer = consumer
        this.branches = branches
        this.newDeliveryAddressId = delivery.recipient.addressId
        this.isConsumerAddress = this.getIsConsumerAddress(delivery.recipient.addressId)

        this.conditionallyChangeRideRecipientAddressId()

        this.isLoading = false
      })
      .catch(error => this.handleFailedInitialSync(error))
      .then(() => this.$scope.$digest())
  }

  conditionallyChangeRideRecipientAddressId () {
    /**
     * Ride has detached addresses. They will have different address id and won't match
     * with consumer addresses.
     * For Rides we will find original recipient address using plain getFormattedAddress() string comparisson
     * This will allow changing recipient address.
     */
    if (this.delivery.recipient.type === 'consumer') {
      const consumerRecipientAddress = this.consumer.addresses.find(address => address.id === this.delivery.recipient.address.id)
      if (!consumerRecipientAddress) {
        const recipientAddressName = this.delivery.recipient.address.getFormattedAddress()
        const originalAddress = this.consumer.addresses.find(address => {
          return address.getFormattedAddress() === recipientAddressName
        })
        if (originalAddress) {
          this.delivery.recipient.addressId = originalAddress.id
          this.isConsumerAddress = true
          this.newDeliveryAddressId = originalAddress.id
        }
      }
    }
  }

  handleFailedInitialSync (error) {
    this.logger.error('Could not sync initial delivery and consumer data', error)
    this.hasDataSyncError = true
  }

  // NB. In future the delivery address picker could pass us a recipient directly instead of us building it ourselves.
  onDeliveryAddressIdChosen (addressId) {
    this.newDeliveryAddressId = addressId
    this.isConsumerAddress = this.getIsConsumerAddress(addressId)
  }

  getIsConsumerAddress (addressId) {
    return !!this.consumer && this.consumer.getHasAddress(addressId)
  }

  getBranchForAddressId (addressId) {
    return (this.branches && find(this.branches, {address: {id: addressId}})) || null
  }

  onConsumerAddressEdited (address) {
    this.newConsumerDeliveryAddress = address
  }

  submit () {
    if (!this.isConsumerAddress) {
      this.quoteBranchAddress()
    } else if (this.isConsumerAddress && this.newConsumerDeliveryAddress) {
      this.quoteEditedConsumerAddress(this.newConsumerDeliveryAddress)
    }
  }

  /* To change the address, we need to get a new quote with the new address and show
   * it to the user to accept or decline. If they accept, we then persist the changes.
   * So submission is two separate processes:
   * 1) Creating the address and getting a new quote
   * 2) Persisting the changes back to the delivery (and address if required) */
  quoteEditedConsumerAddress (newConsumerDeliveryAddress) {
    // Consumer address may have been edited so we have to create a temporary new address first
    // And then we only save the changes back to the address if they accept the quote later
    return addressCollection.updateConsumerAddress(this.consumer.id, newConsumerDeliveryAddress.id, newConsumerDeliveryAddress)
      .then(newConsumerDeliveryAddress => {
        this.tempNewConsumerDeliveryAddressId = newConsumerDeliveryAddress.id
        return DeliveryChangeAddressController.promiseNewDeliveryQuote(this.delivery, newConsumerDeliveryAddress.id)
      })
      .then(newDeliveryQuote => {
        this.newDeliveryCost = newDeliveryQuote.totalPrice
        this.submitFailed = false
        modalDialogStateService.emitOpenModalDialog({dialogId: 'delivery-cost-confirmation'})
      })
      .catch(err => this.handleSubmitError(err))
      .then(() => this.$scope.$apply())
  }

  quoteBranchAddress () {
    return DeliveryChangeAddressController.promiseNewDeliveryQuote(this.delivery, this.newDeliveryAddressId)
      .then(newDeliveryQuote => {
        this.newDeliveryCost = newDeliveryQuote.totalPrice
        this.submitFailed = false
        modalDialogStateService.emitOpenModalDialog({dialogId: 'delivery-cost-confirmation'})
      })
      .catch(err => this.handleSubmitError(err))
      .then(() => this.$scope.$apply())
  }

  static promiseNewDeliveryQuote (delivery, newDropoffAddressId) {
    return deliveryQuoteService.get([], {
      dropoffAddressId: newDropoffAddressId,
      pickupAddressId: delivery.sender.addressId,
      pickupTime: delivery.pickupTime,
      fleetId: delivery.fleetId
    })
  }

  handleSubmitError (error) {
    this.logger.error('Could not get new quote for address object.', error)
    this.submitFailed = true
    this.tempNewConsumerDeliveryAddressId = null
  }

  changeDeliveryAddress () {
    if (!this.isConsumerAddress) {
      this.changeDeliveryToBranchAddress()
    } else if (this.isConsumerAddress && this.tempNewConsumerDeliveryAddressId) {
      this.changeDeliveryToConsumerAddress()
    }
  }

  changeDeliveryToBranchAddress () {
    const deliveryBranch = this.getBranchForAddressId(this.newDeliveryAddressId)
    const branchId = deliveryBranch ? deliveryBranch.id : null
    const newRecipient = {
      type: 'branch',
      id: branchId,
      addressId: this.newDeliveryAddressId
    }
    return deliveriesCollection.updateDeliveryRecipient(this.delivery.id, newRecipient)
      .then(response => this.handleUpdateSuccess(response))
      .catch(err => this.handleUpdateError(err))
      .then(() => this.$scope.$apply())
  }

  changeDeliveryToConsumerAddress () {
    const newRecipient = {
      type: 'consumer',
      id: this.delivery.originatingConsumer,
      addressId: this.tempNewConsumerDeliveryAddressId
    }

    return deliveriesCollection.updateDeliveryRecipient(this.delivery.id, newRecipient)
      .then(response => this.handleUpdateSuccess(response))
      .catch(err => this.handleUpdateError(err))
      .then(() => this.$scope.$apply())
  }

  static cancelChangeDeliveryAddress () {
    modalDialogStateService.emitCloseModalDialog({dialogId: 'delivery-cost-confirmation'})
  }

  handleCancelChangeDeliveryAddress () {
    this.newDeliveryCost = null
    this.tempNewConsumerDeliveryAddressId = null
  }

  handleUpdateSuccess () {
    DeliveryChangeAddressController.cancelChangeDeliveryAddress()
    this.submitFailed = false
    this.$state.go('^')
  }

  handleUpdateError () {
    DeliveryChangeAddressController.cancelChangeDeliveryAddress()
    this.submitFailed = true
  }

  $onDestroy () {
    this.closeModalDialogSubscription.remove()
  }
}

export default {
  templateUrl,
  controller: DeliveryChangeAddressController
}
