import LatLongPoint from 'data/domain-objects/LatLongPoint'
import Area from 'data/domain-objects/Area'

class AreaEditorController {
  // inputs
  public area: Area
  public isEditing: boolean
  public originalPolygon: LatLongPoint[]
  public form: ng.IFormController
  public centerMapCoordinates: LatLongPoint

  // outputs
  public onPolygonUpdated: (value: {polygon: LatLongPoint[]}) => {}

  onPolygonUpdate(polygon: LatLongPoint[]): void {
    this.onPolygonUpdated({polygon})
  }
}

export default {
  templateUrl: require('./editable-list-area-editor.pug'),
  controller: AreaEditorController,
  bindings: {
    area: '<',
    isEditing: '<',
    onPolygonUpdated: '&',
    originalPolygon: '<',
    centerMapCoordinates: '<?',
    form: '<'
  }
}
