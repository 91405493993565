import CrudService from 'data/services/CrudService'
import endpoints from 'common/endpoints'
import Surge from 'data/domain-objects/Surge'

class SurgesCollection {
  private priceSurgeService: CrudService = new CrudService(endpoints.PRICE_SURGE)
  private priceSurgesService: CrudService = new CrudService(endpoints.PRICE_SURGES)
  private priceSurgeStatusService: CrudService = new CrudService(endpoints.PRICE_SURGE_STATUS)
  private priceSurgesOrderService: CrudService = new CrudService(endpoints.PRICE_SURGES_ORDER)
  private priceSurgesSettingsService: CrudService = new CrudService(endpoints.PRICE_SURGES_SETTINGS)

  public getSettings(): Promise<ISurgePricingSettings> {
    return this.priceSurgesSettingsService.get()
  }

  public updateSettings(settings: ISurgePricingSettings): Promise<ISurgePricingSettings> {
    return this.priceSurgesSettingsService.update(settings)
  }

  public getSurges(): Promise<Surge[]> {
    return this.priceSurgesService.get()
      .then((surges: any[]): Surge[] => surges.map(Surge.build))
  }

  public getSurgeById(surgeId: string): Promise<Surge> {
    return this.priceSurgeService.get([surgeId])
      .then(Surge.build)
  }

  public createSurge(surge: Surge): Promise<Surge> {
    return this.priceSurgeService.create(surge)
      .then(Surge.build)
  }

  public updateSurge(surgeId: string, surge: Surge): Promise<Surge> {
    return this.priceSurgeService.update(surge, [surgeId])
      .then(Surge.build)
  }

  public deleteSurge(surgeId: string): Promise<void> {
    return this.priceSurgeService.delete([surgeId])
  }

  public setSurgeStatus(surgeId: string, isEnabled: boolean): Promise<boolean> {
    return this.priceSurgeStatusService.update({ enabled: isEnabled }, [surgeId])
  }

  public updateSurgesOrder(surgeIds: string[]): Promise<string[]> {
    return this.priceSurgesOrderService.update(surgeIds)
  }
}

export default new SurgesCollection()
