import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import accountDashboard from './dashboard/accountDashboard'
import changePasswordPanel from './dashboard/change-password-panel/changePasswordPanel'
import userDetailsPanel from './dashboard/user-details-panel/userDetailsPanel'
import verifyAccountCredential from './verify-account-credential/verifyAccountCredential'
import verifyCredentialsPanel from './dashboard/verify-credentials-panel/verifyCredentialsPanel'

export default declareAppModule('account', (module: ng.IModule) => module
  .component('accountDashboard', accountDashboard)
  .component('changePasswordPanel', changePasswordPanel)
  .component('userDetailsPanel', userDetailsPanel)
  .component('verifyAccountCredential', verifyAccountCredential)
  .component('verifyCredentialsPanel', verifyCredentialsPanel)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'account',
      stateKey: 'ACCOUNT',
      componentName: 'accountDashboard',
      requiresPermission: 'ACCOUNT'
    })

    declareState($stateProvider, {
      parent: 'main.account',
      stateName: 'verify-phone',
      params: ['code'],
      stateKey: 'VERIFY_PHONE',
      componentName: 'verifyAccountCredential',
      data: {
        baseTranslationKey: 'PAGES.VERIFY_PHONE',
        verifyingPhone: true
      }
    })

    declareState($stateProvider, {
      parent: 'main.account',
      stateName: 'verify-email',
      params: ['code'],
      stateKey: 'VERIFY_EMAIL',
      componentName: 'verifyAccountCredential',
      data: {
        baseTranslationKey: 'PAGES.VERIFY_EMAIL',
        verifyingEmail: true
      }
    })
  })
)
