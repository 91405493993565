import get from 'lodash-es/get'
import moment from 'moment'
import * as AreasSettings from 'common/constants/AreasSettings'

export const scrollToSectionIds = {
  SELECT_CONSUMER_ADDRESS_ID: 'deliveryCreateSelectConsumerAddress',
  CREATE_CONSUMER_ID: 'deliveryCreateConsumer',
  BRANCH_INFORMATION_ID: 'deliveryCreateBranchInformation',
  ORDER_DETAILS_ID: 'deliveryCreateOrderDetails',
  PICKUP_SLOT_ID: 'deliveryCreatePickupSlot',
  PICKUP_TIME_ID: 'deliveryCreatePickupTime',
  PACKAGE_DETAILS_ID: 'deliveryCreatePackageDetails',
  AVAILABLE_FLEETS_ID: 'deliveryCreateAvailableFleets',
  OTHER_INFORMATION_ID: 'deliveryOtherInformation',
  PROOF_OF_DELIVERY_ID: 'deliveryCreateProofOfDelivery'
}

export default class CreateDeliveryStateManager {
  constructor (isNextDay = false) {
    this.isNextDay = isNextDay
    this.elementIdToScrollTo = null
    this.showConsumerSearch = true
    this.showSelectConsumerAddress = false
    this.showCreateConsumer = false
    this.showBranchInformation = false
    this.showSubmitButton = true
    this.isValidRequestPickupTime = false
    this.isValidSelectedArea = true
    this.resetForBranchChange()
  }

  resetForBranchChange () {
    this.showConsumerAddressLocationMap = true
    this.expandConsumerAddressLocationMap = true
    this.showDeliveryType = false
    this.showOrderDetails = false
    this.showPickupTime = false
    this.showPickupSlot = false
    this.isDatePickerOpen = false
    this.showPackageDetails = false
    this.showAvailableFleets = false
    this.showOtherInformation = false
    this.showProofOfDelivery = false
    this.showInvalidAreaWarning = false
  }

  update (branches, delivery, settings, newConsumerPhoneNumber, consumerAddress, allAreas) {
    // Order is important in this function. Each of these functions needs to be called in the correct order to support
    // the auto scrolling feature.

    this.setSelectConsumerAddressForm(delivery)
    this.setShowCreateConsumerForm(newConsumerPhoneNumber)
    this.setShowBranchInformation(delivery)

    if (settings) {
      this.setShowInvalidAreaWarning(delivery, settings, consumerAddress, allAreas)
      if (this.isValidSelectedArea) {
        this.setConsumerAddressLocationMap(settings)
        this.setShowDeliveryType(delivery, settings)
      }
      this.setShowOrderDetails(delivery)
      this.setShowPickupTime(delivery, settings)
      this.setShowPackageDetails(delivery, settings)
      this.setShowAvailableFleets(delivery, settings)
      this.setShowOtherInformation(delivery, settings)
      this.setShowProofOfDelivery(delivery, settings)
    }
  }

  validateRequestPickupTime () {
    this.isValidRequestPickupTime = true
  }

  setConsumerAddressLocationMap (settings) {
    const COLLAPSED_STATE = 'collapsed'

    this.showConsumerAddressLocationMap =
      convertVisibilitySettingValueToBoolean(settings, 'showConsumerAddressLocationMap')

    const expandConsumerAddressLocationMap = get(settings, 'consumerAddressLocationMapState.value', true)
    if (expandConsumerAddressLocationMap === COLLAPSED_STATE) {
      this.expandConsumerAddressLocationMap = false
    }
  }

  setSelectConsumerAddressForm (delivery) {
    if (delivery.consumer.id) {
      this.showSelectConsumerAddress = true
      this.elementIdToScrollTo = scrollToSectionIds.SELECT_CONSUMER_ADDRESS_ID
    } else {
      this.showSelectConsumerAddress = false
    }
  }

  setShowCreateConsumerForm (newConsumerPhoneNumber) {
    if (newConsumerPhoneNumber) {
      this.showCreateConsumer = true
      this.elementIdToScrollTo = scrollToSectionIds.CREATE_CONSUMER_ID
    } else {
      this.showCreateConsumer = false
    }
  }

  setShowBranchInformation (delivery) {
    if (delivery.consumer.addressId) {
      this.showBranchInformation = true
      this.elementIdToScrollTo = scrollToSectionIds.BRANCH_INFORMATION_ID
    } else {
      this.showBranchInformation = false
    }
  }

  setShowDeliveryType (delivery, settings) {
    const showDeliveryTypeSetting = convertVisibilitySettingValueToBoolean(settings, 'showDeliveryType')

    if (delivery.consumer.addressId && delivery.branch.id && showDeliveryTypeSetting) {
      this.showDeliveryType = true
    } else {
      this.showDeliveryType = false
    }
  }

  setShowOrderDetails (delivery) {
    if (delivery.consumer.addressId && delivery.branch.id) {
      this.showOrderDetails = true
      this.elementIdToScrollTo = scrollToSectionIds.ORDER_DETAILS_ID
    } else {
      this.showOrderDetails = false
    }
  }

  setShowInvalidAreaWarning (delivery, settings, consumerAddress, allAreas) {
    if (!delivery.consumer.addressId || !delivery.branch.id || !consumerAddress) {
      this.showInvalidAreaWarning = false
    } else {
      const selectedConsumerAddressAreaObj = allAreas.find(area => consumerAddress.area === area.id)
      const selectedConsumerAddressArea = selectedConsumerAddressAreaObj ? selectedConsumerAddressAreaObj.name : ''

      this.showInvalidAreaWarning = settings.showAreas.value === AreasSettings.SHOW_AREA_SETTINGS_SHOW_SELECTED && settings.selectedAreas.value.length && settings.selectedAreas.value.indexOf(selectedConsumerAddressArea) === -1
    }
    this.isValidSelectedArea = !this.showInvalidAreaWarning
  }

  setShowPickupTime (delivery, settings) {
    const OPTION_DATE_TIME = 'date-time'
    const isPickupAtDateTime = get(settings, 'defaultPickupTimeMode.value', null) === OPTION_DATE_TIME
    const showPickupTimeSettings = convertVisibilitySettingValueToBoolean(settings, 'showPickupTimeSettings')

    if (delivery.posReference) {
      if (this.isNextDay) {
        this.showPickupSlot = true
        if (delivery.posHasBeenEntered) {
          this.elementIdToScrollTo = scrollToSectionIds.PICKUP_SLOT_ID
        }
      } else if (showPickupTimeSettings) {
        if (isPickupAtDateTime && !this.showPickupTime) {
          this.isDatePickerOpen = true
        }
        if (delivery.posHasBeenEntered) {
          this.elementIdToScrollTo = scrollToSectionIds.PICKUP_TIME_ID
        }
        this.showPickupTime = true
      }
    } else {
      this.showPickupTime = false
      this.showPickupSlot = false
    }

    if (this.isNextDay) {
      this.isValidRequestPickupTime = delivery.requestedPickupTime.timeSlot > 0
    } else {
      const chosenRequestPickupTimeMoment = moment(delivery.requestedPickupTime.toISOStringGivenNow(new Date()), moment.ISO_8601)
      this.isValidRequestPickupTime = chosenRequestPickupTimeMoment.isAfter(moment().startOf('day'))
    }
  }

  setShowPackageDetails (delivery, settings) {
    const showPackageDetails = convertVisibilitySettingValueToBoolean(settings, 'showPackageType')

    if (delivery.posReference && this.isValidRequestPickupTime && showPackageDetails) {
      this.showPackageDetails = true
      if (delivery.posHasBeenEntered) {
        this.elementIdToScrollTo = scrollToSectionIds.PACKAGE_DETAILS_ID
      }
    } else {
      this.showPackageDetails = false
    }
  }

  setShowAvailableFleets (delivery, settings) {
    const showAvailableFleetsSetting = convertVisibilitySettingValueToBoolean(settings, 'showFleetSettings')

    if (delivery.posReference && this.isValidRequestPickupTime && delivery.packages && delivery.packages.length && showAvailableFleetsSetting) {
      this.showAvailableFleets = true
      if (delivery.posHasBeenEntered) {
        this.elementIdToScrollTo = scrollToSectionIds.AVAILABLE_FLEETS_ID
      }
    } else {
      this.showAvailableFleets = false
    }
  }

  setShowOtherInformation (delivery, settings) {
    const showChargeToSetting = convertVisibilitySettingValueToBoolean(settings, 'showDeliveryCharge')
    const showBatchingSetting = convertVisibilitySettingValueToBoolean(settings, 'showBatching')
    const showNewDeliveryStatus = convertVisibilitySettingValueToBoolean(settings, 'showDeliveryStatus')

    if (delivery.posReference && delivery.fleetId && (showChargeToSetting || showBatchingSetting || showNewDeliveryStatus)) {
      this.showOtherInformation = true
      if (delivery.posHasBeenEntered) {
        this.elementIdToScrollTo = scrollToSectionIds.OTHER_INFORMATION_ID
      }
    } else {
      this.showOtherInformation = false
    }
  }

  setShowProofOfDelivery (delivery, settings) {
    const showProofOfDeliverySetting = convertVisibilitySettingValueToBoolean(settings, 'showProofOfDeliverySettings')
    const photoIdEnabled = get(settings, 'proofOfDeliveryPhotoIdentificationEnabled.value', false)
    const signatureEnabled = get(settings, 'proofOfDeliverySignatureEnabled.value', false)

    if (
      delivery.posReference &&
      delivery.fleetId &&
      showProofOfDeliverySetting &&
      (photoIdEnabled || signatureEnabled)) {
      this.showProofOfDelivery = true
      if (delivery.posHasBeenEntered) {
        this.elementIdToScrollTo = scrollToSectionIds.PROOF_OF_DELIVERY_ID
      }
    } else {
      this.showProofOfDelivery = false
    }
  }
}

export function convertVisibilitySettingValueToBoolean (settings, settingName) {
  const SETTING_SHOW = 'show'
  const SETTING_HIDE = 'hide'

  const settingValue = get(settings, settingName + '.value', null)
  let setting = true

  if (settingValue) {
    if (settingValue === SETTING_SHOW) {
      setting = true
    }
    if (settingValue === SETTING_HIDE) {
      setting = false
    }
  }

  return setting
}
