import MultiLangString from '../MultiLangString'
import { BusinessHours } from '../BusinessHours'
import Setting from '../Setting'

export enum StorefrontDisplayLogoOptions {
  NOT_DISPLAYING = 'notDisplaying',
  ROUND = 'round',
  SQUARE = 'square'
}

export class StorefrontCategoryAppearance {
  constructor (
    public logoUrl: string,
    public photoUrl: string,
    public coverImageUrl: string,
    public bannerImageUrl: MultiLangString,
    public displayLogo: StorefrontDisplayLogoOptions,
    public displayName: boolean,
    public coverOverlay: boolean,
    public displaySubtitle: boolean,
    public displayCoverImage: boolean,
  ) {}

  public static build(dto: any): StorefrontCategoryAppearance {
    return new StorefrontCategoryAppearance(
      dto.logoUrl || '',
      dto.photoUrl || '',
      dto.coverImageUrl || '',
      MultiLangString.build(dto.bannerImageUrl || {}),
      dto.displayLogo || StorefrontDisplayLogoOptions.NOT_DISPLAYING,
      !!dto.displayName,
      dto.hasOwnProperty('coverOverlay') ? !!dto.coverOverlay : true,
      !!dto.displaySubtitle,
      !!dto.displayCoverImage
    )
  }
}
import { hasChildById } from 'common/utils/treeUtils'

export default class StorefrontCategory {
  constructor (
    public id: string,
    public name: MultiLangString,
    public subtitle: MultiLangString,
    public businessHours: BusinessHours,
    public appearance: StorefrontCategoryAppearance,
    public categories: StorefrontCategory[],
    public settings: ISettingsGroup,
    public productCount?: number,
    public parent?: string,
  ) {}

  static build (dto: any): StorefrontCategory {
    const settings = dto.settings || {}

    Object.keys(settings).forEach((settingName: string): void => {
      settings[settingName] = Setting.build(settings[settingName])
    })

    return new StorefrontCategory(
      dto.id,
      MultiLangString.build(dto.name),
      MultiLangString.build(dto.subtitle),
      BusinessHours.build(dto.businessHours || {}),
      StorefrontCategoryAppearance.build(dto.appearance || {}),
      (dto.categories || []).map(StorefrontCategory.build),
      settings,
      dto.productCount || 0,
      dto.parent || null
    )
  }

  static sanitize (storefrontCategory: StorefrontCategory): Partial<StorefrontCategory> {
    delete storefrontCategory.productCount
    return storefrontCategory
  }

  static sanitizeNewCategory (storefrontCategory: StorefrontCategory): Partial<StorefrontCategory> {
    const sanitizedNewCategory = StorefrontCategory.sanitize(storefrontCategory)
    delete sanitizedNewCategory.id
    delete sanitizedNewCategory.categories
    return sanitizedNewCategory
  }

  hasChildCategoryById(categoryId: string): boolean {
    return hasChildById(this, 'categories', categoryId)
  }
}
