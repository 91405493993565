import templateUrl from './driver-form.pug'
import driverModel from 'data/models/driverModel'
import driverWizardRouteSteps from 'presentation/logistics-drivers/common/driverWizardRouteSteps'
import ViewEditFormController from 'presentation/common/ViewEditFormController'
import driverCollection from 'data/collections/driverCollection'

class DriverFormController extends ViewEditFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
  }

  $onInit () {
    this.isEditing = !!this.$stateParams.isEditing
    this.model = {}
    this.wizardRouteSteps = driverWizardRouteSteps
    this.checkIfDriverIsVerifiedAndDetermineView()
    super.$onInit()
  }

  updateLogoUrl (imageUrl) {
    this.model.photoUrl = imageUrl
  }

  goToDrivers () {
    this.$state.go('main.logistics.drivers')
  }

  getDefaultModel () {
    return this.backendModel || {}
  }

  getDriver () {
    driverModel.get(this.$stateParams.driverId)
      .then(driver => this.handleGetDriverSuccess(driver))
      .catch(error => this.handleGetDriverFail(error))
      .then(() => this.$scope.$apply())
  }

  handleGetDriverSuccess (driver) {
    if (!driver.onboarded) {
      this.recoverOnBoardingState(driver)
    } else {
      this.backendModel = driver
      this.setDefaultModel()
    }
  }

  handleGetDriverFail (error) {
    this.logger.error('Failed to get driver, redirecting back to all drivers', error)
    this.$state.go('main.logistics.drivers')
  }

  beforeSubmit () {
    return driverModel.update(this.model)
  }

  checkIfDriverIsVerifiedAndDetermineView () {
    driverCollection.checkIsVerified(this.$stateParams.driverId)
      .then(response => {
        if (!response.verified) {
          this.goToVerifyDriver()
        } else {
          this.getDriver()
        }
      })
      .catch(err => this.logger.error('Cannot check if driver is verified', err)) // erm, UK grammar-nazi attention required... not sure about my skills
  }

  goToVerifyDriver () {
    const driverId = this.$stateParams.driverId
    const urlParams = {driverId: driverId}

    driverCollection.sendVerification(driverId)
      .catch(err => this.logger.error('Cannot send driver verification', err))
    this.$state.go('main.logistics.drivers.driver.verify-account', urlParams) // NB. Should it be inside of .then() instead?
  }

  recoverOnBoardingState (driver) {
    const urlParams = {driverId: driver.id}

    if (!driver.licenseNumber || driver.licenseNumber.length === 0) {
      this.$state.go('main.logistics.drivers.driver.add-license', urlParams)
    } else if (!driver.vehicle || !driver.vehicle.make) {
      this.$state.go('main.logistics.drivers.driver.add-vehicle', urlParams)
    } else if (!driver.onboarded) {
      this.$state.go('main.logistics.drivers.driver.assign-to-fleets')
    }
  }

  cancel () {
    this.cancelEdit()
  }
}

export default {
  templateUrl,
  controller: DriverFormController
}
