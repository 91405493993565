const periodSortingByFromTimeStamp = (periodA: Period, periodB: Period): number => {
  const fromTimestampA = periodA.getFromTimestampGivenDate(new Date())
  const fromTimestampB = periodB.getFromTimestampGivenDate(new Date())
  const difference = fromTimestampA - fromTimestampB

  return difference
}

export class Period {
  constructor(
    public from: string,
    public to: string
  ) { }

  static build(DTO: any = {}): Period {
    return new Period(
      DTO.from,
      DTO.to
    )
  }

  getFormattedPeriod(): string {
    return `${this.from} - ${this.to}`
  }

  getTimestampPeriodGivenDate(date: Date): {from: number, to: number} {
    return {
      from: this.getFromTimestampGivenDate(date),
      to: this.getToTimestampGivenDate(date)
    }
  }

  getFromTimestampGivenDate(date: Date): number {
    return this.getTimestampForTimeGivenDate(date, this.from)
  }

  getToTimestampGivenDate(date: Date): number {
    return this.getTimestampForTimeGivenDate(date, this.to)
  }

  getTimestampForTimeGivenDate(date: Date, time: string): number {
    const [hours, minutes] = time.split(':')
    const resultDate = new Date(date)
    resultDate.setHours(parseInt(hours, 10))
    resultDate.setMinutes(parseInt(minutes, 10))
    resultDate.setSeconds(0)
    resultDate.setMilliseconds(0)
    return resultDate.getTime()
  }
}

export class BusinessHours {
  private weekdays: any[]

  public humanizedBusinessHours: BusinessHours

  constructor(
    public friday: Period[],
    public monday: Period[],
    public saturday: Period[],
    public sunday: Period[],
    public thursday: Period[],
    public tuesday: Period[],
    public wednesday: Period[]
  ) {
    this.weekdays = [ this.sunday, this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday ]
  }

  static build(DTO: any = {}, isHumanized: boolean = false): BusinessHours {
    return new BusinessHours(
      (DTO.friday || []).map(Period.build).sort(periodSortingByFromTimeStamp),
      (DTO.monday || []).map(Period.build).sort(periodSortingByFromTimeStamp),
      (DTO.saturday || []).map(Period.build).sort(periodSortingByFromTimeStamp),
      (DTO.sunday || []).map(Period.build).sort(periodSortingByFromTimeStamp),
      (DTO.thursday || []).map(Period.build).sort(periodSortingByFromTimeStamp),
      (DTO.tuesday || []).map(Period.build).sort(periodSortingByFromTimeStamp),
      (DTO.wednesday || []).map(Period.build).sort(periodSortingByFromTimeStamp)
    )
  }

  toJSON(): any {
    const weekdays = this.weekdays.map((weekday: Period[]) => {
      return weekday
      .map(Period.build)
      .filter((period: Period) => !!period.from && !!period.to)
    })

    return {
      sunday: weekdays[0],
      monday: weekdays[1],
      tuesday: weekdays[2],
      wednesday: weekdays[3],
      thursday: weekdays[4],
      friday: weekdays[5],
      saturday: weekdays[6]
    }
  }
}
