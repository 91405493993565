import templateUrl from './fancy-time-picker.pug'
import {MathUtils} from 'common/utils/mathUtils'

const MIN_STEP = 1
const TEN = 10

const MAX_HOURS_VALUE = 23
const MAX_MINUTES_VALUE = 59

const stickyChangeIntervalMS = 300

class FancyTimePicker {
  constructor ($scope) {
    this.$scope = $scope

    const now = new Date()
    const roundedMinutes = this.ceilToStep(now.getMinutes())

    this.values = {
      hours: FancyTimePicker.conditionallyFormatNumberLessThanTen(now.getHours()),
      minutes: FancyTimePicker.conditionallyFormatNumberLessThanTen(roundedMinutes)
    }

    this.valuesLimits = {
      hours: MAX_HOURS_VALUE,
      minutes: MAX_MINUTES_VALUE
    }

    this.buttonsVisibility = {
      hours: {
        increase: true,
        decrease: true
      },
      minutes: {
        increase: true,
        decrease: true
      }
    }
  }

  $onInit () {
    if (!this.minutesChangeStep) {
      this.minutesChangeStep = MIN_STEP
    }
  }

  $onChanges () {
    this.setupPickerFromModel()
    this.calculateButtonsVisibility()
  }

  setupPickerFromModel () {
    if (this.model) {
      const localModelDate = new Date(this.model)

      const hours = localModelDate.getHours() > this.valuesLimits.hours ? this.valuesLimits.hours : localModelDate.getHours()
      const minutes = this.ceilToStep(localModelDate.getMinutes()) > this.valuesLimits.minutes ? this.valuesLimits.minutes : this.ceilToStep(localModelDate.getMinutes())
      this.values = {
        hours: FancyTimePicker.conditionallyFormatNumberLessThanTen(hours),
        minutes: FancyTimePicker.conditionallyFormatNumberLessThanTen(minutes)
      }
    }
  }

  getConditionallyLimitedHours (hours) {
    if (this.maxHours !== null && hours > this.maxHours) {
      return this.maxHours
    }

    if (this.minHours !== null && hours < this.minHours) {
      return this.minHours
    }

    return hours
  }

  getConditionallyLimitedMinutes (hours, minutes) {
    if (this.maxHours !== null && this.maxMinutes !== null && hours === this.maxHours && minutes > this.maxMinutes) {
      return this.maxMinutes
    }

    if (this.minHours !== null && this.minMinutes !== null && hours === this.minHours && minutes < this.minMinutes) {
      return this.minMinutes
    }

    return minutes
  }

  validateValuesForLimits () {
    let hours = parseInt(this.values.hours)
    let minutes = parseInt(this.values.minutes)

    hours = this.getConditionallyLimitedHours(hours)
    minutes = this.getConditionallyLimitedMinutes(hours, minutes)

    this.values.hours = FancyTimePicker.conditionallyFormatNumberLessThanTen(hours)
    this.values.minutes = FancyTimePicker.conditionallyFormatNumberLessThanTen(minutes)
  }

  calculateButtonsVisibility () {
    const hours = parseInt(this.values.hours)
    const minutes = parseInt(this.values.minutes)

    this.buttonsVisibility.hours.increase = this.maxHours === null || hours < this.maxHours
    this.buttonsVisibility.hours.decrease = this.minHours === null || hours > this.minHours
    this.buttonsVisibility.minutes.increase = this.maxMinutes === null || (minutes < this.maxMinutes || this.buttonsVisibility.hours.increase)
    this.buttonsVisibility.minutes.decrease = this.minMinutes === null || (minutes > this.minMinutes || this.buttonsVisibility.hours.decrease)
  }

  ceilToStep (number) {
    return MathUtils.ceilToStep(number, this.minutesChangeStep)
  }

  static conditionallyFormatNumberLessThanTen (number) {
    return number >= TEN ? number.toString() : `0${number}`
  }

  changeValue (valueName, direction) {
    if (valueName && this.values.hasOwnProperty(valueName)) {
      let newValue = parseInt(this.values[valueName]) + (valueName === 'minutes' ? direction * this.minutesChangeStep : direction)

      if (newValue > this.valuesLimits[valueName]) {
        newValue = 0
      } else if (newValue < 0) {
        newValue = this.valuesLimits[valueName]
      }

      this.values[valueName] = FancyTimePicker.conditionallyFormatNumberLessThanTen(newValue)

      this.validateValuesForLimits()
    }

    this.onChange({
      hours: this.values.hours,
      minutes: this.values.minutes
    })

    this.calculateButtonsVisibility()
  }

  increaseValue (valueName) {
    this.changeValue(valueName, 1)
  }

  decreaseValue (valueName) {
    this.changeValue(valueName, -1)
  }

  stickValueChange (valueName, direction) {
    this.stickTimeoutId = setTimeout(() => {
      this.changeValue(valueName, direction)
      this.$scope.$digest()

      if (this.stickTimeoutId) {
        this.stickValueChange(valueName, direction)
      }
    }, stickyChangeIntervalMS)
  }

  stickValueIncrease (valueName) {
    this.stickValueChange(valueName, 1)
  }

  stickValueDecrease (valueName) {
    this.stickValueChange(valueName, -1)
  }

  unstickValueChange () {
    clearTimeout(this.stickTimeoutId)
    this.stickTimeoutId = null
  }
}

export default {
  templateUrl,
  controller: FancyTimePicker,
  bindings: {
    model: '<',
    onChange: '&',
    minHours: '<?',
    minMinutes: '<?',
    maxHours: '<?',
    maxMinutes: '<?',
    minutesChangeStep: '<?'
  }
}
