import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import localEnvConfig from 'common/envConfig'
import EarningSummary from 'data/domain-objects/EarningSummary'

const banksService: CrudService = new CrudService(endpoints.BANKS)
const earningSummaryService: CrudService = new CrudService(endpoints.STOREFRONT_EARNINGS_SUMMARY)
const manualPayoutRequestService: CrudService = new CrudService(endpoints.STOREFRONT_PAYOUT_REQUEST)

class PayoutsCollection {
  getBanks(): Promise<any> {
    return banksService.get([], {
      countryCode: localEnvConfig.defaultCountry
    })
  }

  getEarningSummary(businessId: string): Promise<EarningSummary> {
    return earningSummaryService.get([businessId])
      .then((response: any): EarningSummary => EarningSummary.build(response))
  }

  requestManualPayout(businessId: string, branchIds: string[]): Promise<any> {
    return manualPayoutRequestService.create({ branchIds }, [businessId])
  }
}

export const payoutsCollection = new PayoutsCollection()
