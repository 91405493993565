import templateUrl from './team-members-dashboard.pug'
import rolesCollection from 'data/collections/rolesCollection'
import BaseController from 'presentation/common/BaseController'
import teamMembersCollection from 'data/collections/teamMembersCollection'
import TeamMember from 'data/domain-objects/TeamMember'

class TeamMembersDashboardCollection extends BaseController {
  constructor ($scope) {
    super($scope)
    this.teamMembers = []
    this.sync()
  }

  onSync () {
    return Promise.all([
      rolesCollection.getAllWithSupportingData(),
      teamMembersCollection.getAll()
    ])
      .then(entityCollections => this.handleAllDataReceived(entityCollections))
      .catch(err => this.logger.error('Cannot get all data', err))
  }

  handleAllDataReceived (entityCollections) {
    const [dataBundle, teamMembers] = entityCollections

    this.teamMembers = teamMembers.map(teamMember => {
      return TeamMember.build(
        teamMember,
        dataBundle.rolesById,
        dataBundle.businessesMap,
        dataBundle.branchesMap,
        dataBundle.fleetsMap
      )
    })
  }
}

export default {
  controller: TeamMembersDashboardCollection,
  templateUrl
}
