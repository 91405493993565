import jobCollection from 'data/collections/jobCollection'
import driverCollection from 'data/collections/driverCollection'
import fleetsCollection from 'data/collections/fleetsCollection'
import uniqBy from 'lodash-es/uniqBy'
import Logger from 'common/Logger'
const logger = new Logger('DriverOverviewDataService')

class DriverOverviewDataService {
  handleDriverAndFleetsResponseSuccess (driver, fleets) {
    driver.fleetsList = fleets.filter(flt => {
      return driver.fleets.indexOf(flt.id) > -1
    }).map(fleet => fleet.name)

    driver.car = `${driver.vehicle.make} ${driver.vehicle.model} — ${driver.vehicle.registrationPlate}`

    return this.populateActiveJobs(driver)
  }

  populateActiveJobs (driver) {
    if (driver.activeJob) {
      const savedDriver = driver
      return jobCollection.get(driver.activeJob)
        .then(job => {
          savedDriver.job = job
          if (job.tasks) {
            // backend duplicate tasks so we filter in on FE. Because BE sucks and we are champions
            savedDriver.job.tasks = uniqBy(job.tasks, 'id')
          }
        })
        .catch(err => {
          logger.error(err)
        })
        .then(() => savedDriver)
    } else {
      return driver
    }
  }

  getDriverJobs (driverId) {
    return driverCollection.get(driverId)
      .then(driver => this.handleDriverAndFleetsResponseSuccess(driver, []))
  }

  getDriverInfo (driverId) {
    return Promise.all([
      driverCollection.get(driverId),
      fleetsCollection.getAllCached()
    ])
      .then(([driver, fleets]) => this.handleDriverAndFleetsResponseSuccess(driver, fleets))
  }
}

export default new DriverOverviewDataService()
