import templateUrl from './linked-delivery.pug'

export default {
  templateUrl,
  bindings: {
    delivery: '<',
    onCheckboxClick: '&',
    onPrint: '&'
  }
}
