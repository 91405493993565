import envConfig from 'common/envConfig'
import Consumer from 'data/domain-objects/Consumer'
import * as RideTypes from 'common/constants/RideTypes'

class RideAddressPicker {

  // bindingds
  public title: string
  public form: ng.IFormController
  public name: string
  public consumer: Consumer
  public onAddressCreated: (payload: {consumer: Consumer}) => void
  public onBranchSelect: (payload: {branch: ISuggestion}) => void
  public onSelectedAddressChange: (payload: {addressId: string}) => void
  public onAddressTypeChange: (payload: {addressType: string}) => void
  public isRequired: boolean = false
  public availableAddressTypes: string[] = []
  public selectedAddressId: string
  public addressType: string
  public disallowedAddressId: string
  public rideType: string

  // internal
  public selectedAddressType: string
  public disableAddressPickerForType: string
  public isAddingNewAddress: boolean = false
  public handleCancelNewAddress: () => void
  public handleNewAddressAdded: (consumer: Consumer) => void
  public rideTypes: any = RideTypes
  public isAddButtonDisabled: boolean = false
  public consumerAddressType: string = envConfig.addressTypes.consumer

  constructor (
    private $scope: ng.IScope,
    ) {}

  $onInit (): void {
    this.disableAddressPickerForType = envConfig.addressTypes.notKnown
    this.$scope.$watch('$ctrl.selectedAddressType', () => this.handleSelectedAddressTypeChange())

    this.handleCancelNewAddress = (): void => {
      this.isAddingNewAddress = false
      this.checkAddButtonDisabledState()
    }

    this.handleNewAddressAdded = (consumer: Consumer): void => {
      const addedAddresId = consumer.addresses[consumer.addresses.length - 1].id
      this.isAddingNewAddress = false
      this.checkAddButtonDisabledState()
      this.onAddressCreated({consumer})
      this.setSelectedAddress(addedAddresId)
      this.$scope.$digest()
    }
    this.checkAddButtonDisabledState()
  }

  $onChanges(changes: ng.IOnChangesObject): void {
    if (changes.rideType) {
      this.isAddingNewAddress = false
      this.checkAddButtonDisabledState()
    }

    if (changes.addressType) {
      this.selectedAddressType = this.addressType
    }
  }

  checkAddButtonDisabledState(): void {
    this.isAddButtonDisabled = this.isAddingNewAddress
      || this.selectedAddressType === this.disableAddressPickerForType
      || this.rideType === this.rideTypes.B2C
      || this.rideType === this.rideTypes.B2B
  }

  handleSelectedAddressTypeChange (): void {
    if (this.selectedAddressType === this.disableAddressPickerForType) {
      this.selectedAddressId = null
      this.setSelectedAddress(null)
    }
    this.checkAddButtonDisabledState()
    this.onAddressTypeChange({ addressType: this.selectedAddressType })
  }

  addNewAddress(): void {
    this.isAddingNewAddress = true
    this.checkAddButtonDisabledState()
  }

  setSelectedAddress (addressId: string): void {
    this.onSelectedAddressChange({ addressId })
  }

  onBusinessBranchSelect(branch: ISuggestion): void {
    this.onBranchSelect({branch})
  }
}

export default {
  templateUrl: require('./new-ride-address-picker.pug'),
  controller: RideAddressPicker,
  bindings: {
    title: '@',
    form: '<',
    name: '@',
    consumer: '<',
    availableAddressTypes: '<',
    selectedAddressId: '<?',
    addressType: '<?',
    onSelectedAddressChange: '&',
    onAddressTypeChange: '&',
    onAddressCreated: '&',
    onBranchSelect: '&',
    isRequired: '<',
    disallowedAddressId: '<?',
    rideType: '<',
    consumerFirstName: '<',
    consumerLastName: '<'
  }
}
