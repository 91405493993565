import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import PaymentWithRedirection from 'data/domain-objects/PaymentWithRedirection'

const paymentProviderService = new CrudService(endpoints.PAYMENT_PROVIDER)
const paymentKnetService = new CrudService(endpoints.PAYMENT_KNET)
const paymentTapService = new CrudService(endpoints.PAYMENT_TAP)
const paymentStripeService = new CrudService(endpoints.PAYMENT_STRIPE)
const paymentStripeCardsService = new CrudService(endpoints.PAYMENT_STRIPE_CARDS)
const paymentCybersourcePaymentService = new CrudService(endpoints.PAYMENT_CYBERSOURCE)

export function paymentsCollectionFactory () {
  return {
    getAvailablePaymentProviders () {
      return paymentProviderService.get()
        .then(providersDefinition => providersDefinition.map(definition => definition.method))
    },
    getPaymentCards: function () {
      return paymentStripeCardsService.get()
    },
    createStripePayment: function (cost, paymentProcessedPath, stripeCardId) {
      return paymentStripeService.create({cost, stripeCardId})
        .catch(() => this.processPaymentViaTap(cost, paymentProcessedPath))
    },
    createTapPayment: function (cost, paymentProcessedPath) {
      const tapPayment = new PaymentWithRedirection(cost, paymentProcessedPath)
      return paymentTapService.create(tapPayment)
    },
    createKnetPayment: function (cost, paymentProcessedPath) {
      const knetPayment = new PaymentWithRedirection(cost, paymentProcessedPath)
      return paymentKnetService.create(knetPayment)
    },
    createCybersourcePayment: function (cost, paymentProcessedPath) {
      const cybersourcePayment = new PaymentWithRedirection(cost, paymentProcessedPath)
      return paymentCybersourcePaymentService.create(cybersourcePayment)
    }
  }
}

export default paymentsCollectionFactory()
