import templateUrl from './basic-table.pug'
import {ReportTable} from 'presentation/_core-elements/report-table/reportTable'

class BasicTable extends ReportTable {
  constructor ($scope, $sce) {
    super($scope)

    this.$sce = $sce
    this.baseClass = 'c-reports-table'
  }

  $onInit () {
    this.$scope.$watch(() => this.simpleRecords, () => {
      this.checkRecords()
      this.generateSimpleTable()
    }, true)

    if (!this.customSortableColumns && this.columns) {
      this.columns = this.columns.map(col => {
        col.sortable = true
        col.sortKey = col.name
        return col
      })
    }
  }

  generateSimpleTable () {
    if (!this.simpleRecords) {
      return
    }
    let result = ''
    this.simpleRecords.forEach(row => {
      result += `<tr class="${this.baseClass}_row">`
      row.forEach(col => {
        const value = col || '-'
        result += `<td class="${this.baseClass}_cell">${value}</td>`
      })
      result += '</tr>'
    })

    if (this.$sce && this.$sce.trustAsHtml) {
      this.simpleTable = this.$sce.trustAsHtml(result)
    }
  }

  changeSorting (column) {
    if (column.sortable) {
      this.setSortingOrder(column.sortKey)
    }
  }

  checkRecords () {
    const hasRecords = this.records && this.records.length > 0
    const hasSimpleRecords = this.simpleRecords && this.simpleRecords.length > 0
    this.hasVisibleRecords = hasRecords || hasSimpleRecords
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$sce', BasicTable],
  bindings: {
    titles: '@',
    columns: '<',
    records: '<?',
    simpleRecords: '<?',
    orderBy: '=',
    orderDirection: '=',
    emptyTitle: '@',
    onChangeSort: '&?',
    hostClass: '@?',
    customSortableColumns: '<'
  }
}
