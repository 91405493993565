import cloneDeep from 'lodash-es/cloneDeep'
import templateUrl from './dynamic-table.pug'
import toUpperSnakeCase from 'common/toUpperSnakeCase'
import comparator from 'data/common/comparator'

function DynamicTableController () {
  const $ctrl = this

  function init () {
    if (!$ctrl.rows || !$ctrl.rows.length) {
      return
    }

    $ctrl.headers = {}
    initHeaders()

    $ctrl.internalRows = initRows($ctrl.rows)
    $ctrl.changeSorting = changeSorting

    initSorting()
  }

  function initHeaders () { // get maximal set of field keys for the headers in case if some rows doesn't have all fields
    const headersKeys = $ctrl.rows.reduce((prevHeadersSet, row) => {
      return (Object.keys(row).length > prevHeadersSet.length) ? Object.keys(row) : prevHeadersSet
    }, [])

    headersKeys.forEach(key => $ctrl.headers[key] = toUpperSnakeCase(key))
  }

  function initRows (rows) {
    return cloneDeep(rows).map(addMissingFieldsToRow)
  }

  function addMissingFieldsToRow (row) {
    const fulfilledRow = cloneDeep(row)
    Object.keys($ctrl.headers).forEach(key => {
      if (!fulfilledRow.id) {
        fulfilledRow.id = JSON.stringify(fulfilledRow)
      }

      if (!fulfilledRow.hasOwnProperty(key)) {
        fulfilledRow[key] = null
      }
    })
    return fulfilledRow
  }

  function initSorting () {
    if ($ctrl.defaultSortingKey && $ctrl.sorting[$ctrl.defaultSortingKey]) {
      $ctrl.currentSortingKey = $ctrl.defaultSortingKey
      $ctrl.currentSortingDirection = $ctrl.defaultSortingDirection ? $ctrl.defaultSortingDirection.toUpperCase() : 'ASC'
    }

    sort()
  }

  function changeSorting (columnKey) {
    if ($ctrl.currentSortingKey === columnKey) {
      $ctrl.currentSortingDirection = $ctrl.currentSortingDirection === 'ASC' ? 'DESC' : 'ASC'
    } else {
      $ctrl.currentSortingKey = columnKey
      $ctrl.currentSortingDirection = 'ASC'
    }

    sort()
  }

  function sort () {
    if (!$ctrl.currentSortingKey || !$ctrl.currentSortingDirection) {
      return
    }

    const rows = $ctrl.internalRows

    rows.sort(function (prevRow, nextRow) {
      let sortingResult = 0
      const sorting = $ctrl.sorting[$ctrl.currentSortingKey]

      if (sorting) {
        if (typeof sorting === 'function') {
          sortingResult = sorting(prevRow[$ctrl.currentSortingKey], nextRow[$ctrl.currentSortingKey])
        } else {
          sortingResult = comparator.basic(prevRow[$ctrl.currentSortingKey], nextRow[$ctrl.currentSortingKey])
        }
      }

      return $ctrl.currentSortingDirection === 'ASC' ? sortingResult : 0 - sortingResult
    })

    $ctrl.internalRows = rows
  }

  $ctrl.$onChanges = init
  $ctrl.$onInit = init
}

export default {
  templateUrl,
  controller: DynamicTableController,
  transclude: true,
  bindings: {
    rows: '<',
    sorting: '<', // optional
    formatters: '<', // optional
    defaultSortingKey: '=', // optional
    defaultSortingDirection: '=', // optional
    baseTranslationKey: '@',
    emptyText: '@', // optional,
    actions: '=?',
    hostClass: '@',
    ctx: '<'
  }
}
