import templateUrl from './driver-summary-table.pug'
import BaseController from 'presentation/common/BaseController'
import fleetsCollection from 'data/collections/fleetsCollection'
import scheduleCollection from 'data/collections/scheduleCollection'

class DriverSummaryTable extends BaseController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.fleetsAvailableForDriver = []
  }

  $onInit () {
    this.isNewDriver = this.$stateParams.isNewDriver
  }

  reloadData () {
    Promise.all([
      this.getFleetsAvailableForDriver(),
      this.getSchedulesAvailableForDriver()
    ]).then(() => this.$scope.$digest())
  }

  getFleetsAvailableForDriver () {
    return fleetsCollection.getAllCached()
      .then(fleets => {
        if (this.driver.fleets) {
          this.fleetsAvailableForDriver = fleets.filter(fleet => this.driver.fleets.indexOf(fleet.id) >= 0)
        }
      })
      .catch(err => this.logger.error('Cannot get fleets', err))
  }

  getSchedulesAvailableForDriver () {
    return scheduleCollection.getAll()
      .then(schedules => {
        if (this.driver.schedules) {
          this.schedulesAvailableForDriver = schedules.filter(schedule => this.driver.schedules.indexOf(schedule.id) >= 0)
        }
      })
      .catch(err => this.logger.error('Cannot get schedules', err))
  }

  $onChanges (changes) {
    if (changes.driver && changes.driver.currentValue) {
      this.reloadData()
    }
  }
}

export default {
  templateUrl,
  controller: DriverSummaryTable,
  bindings: {
    driver: '<'
  }
}
