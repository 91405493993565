import templateUrl from './fleet-balance-withdrawal.pug'
import envConfig from 'common/envConfig'
import Cost from 'data/domain-objects/Cost'
import withdrawalService from 'data/services/withdrawalService'
import fleetsCollection from 'data/collections/fleetsCollection'
import BaseController from 'presentation/common/BaseController'

class FleetBalanceWithdrawalController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.model = Cost.build()
    this.balanceAccountId = this.getCurrentBalanceId()
    this.currentBalance = this.getCurrentBalance()
    this.currencyCode = this.getCurrencyCode()
    this.withdrawalRequestWasSuccess = false
    this.requestIsSent = false
    this.availableOptions = []
    this.setWithdrawOptionsGivenCurrentBalance(this.currentBalance)
    this.minimum = this.availableOptions[0]
    this.maximum = this.availableOptions[this.availableOptions.length - 1]
  }

  getCurrentBalance () {
    return (this.fleet && this.fleet.balanceAccount) ? Cost.build(this.fleet.balanceAccount.lastBalance) : Cost.build()
  }

  getCurrentBalanceId () {
    return (this.fleet && this.fleet.balanceAccount) ? this.fleet.balanceAccount.id : ''
  }

  getCurrencyCode () {
    return this.currentBalance && this.currentBalance.currencyCode ? this.currentBalance.currencyCode : envConfig.defaultCurrencyCode
  }

  setWithdrawOptionsGivenCurrentBalance (currentBalance) {
    const availableAmounts = Array.isArray(envConfig.fleetBalanceWithdrawOptions) ? envConfig.fleetBalanceWithdrawOptions : []

    this.availableOptions = availableAmounts
      .map(amount => this.getCostsFromAmounts(amount))
      .filter(option => FleetBalanceWithdrawalController.isOptionNotLargerThanBalance(option, currentBalance))

    if (!this.availableOptions.length) {
      this.availableOptions.push(currentBalance)
    }
  }

  getCostsFromAmounts (amount) {
    return {
      amount,
      currencyCode: this.currencyCode
    }
  }

  static isOptionNotLargerThanBalance (option, currentBalance) {
    return currentBalance.amount ? currentBalance.amount >= option.amount : false
  }

  submit () {
    withdrawalService.sendWithdrawalRequest(this.balanceAccountId, this.model)
      .then(response => this.handleSubmitSuccess(response))
      .catch(() => this.handleSubmitError())
  }

  handleSubmitSuccess () {
    this.model.amount = 0
    this.requestIsSent = true
    this.withdrawalRequestWasSuccess = true
    this.$scope.$digest()
  }

  handleSubmitError () {
    this.requestIsSent = true
    this.withdrawalRequestWasSuccess = false
    this.$scope.$digest()
  }

  $onChanges (changes) {
    if (changes.fleetId && changes.fleetId.currentValue) {
      this.syncFleet(this.fleetId)
    }
  }

  syncFleet (fleetId) {
    fleetsCollection.getWithBalanceAccount(fleetId)
      .then(fleet => {
        this.fleet = fleet
        this.balanceAccountId = this.getCurrentBalanceId()
        this.currentBalance = this.getCurrentBalance()
        this.currencyCode = this.getCurrencyCode()
        this.setWithdrawOptionsGivenCurrentBalance(this.currentBalance)
        this.$scope.$digest()
      })
      .catch(error => this.logger.error('Could not sync fleet with id', fleetId, error))
  }
}

export default {
  templateUrl,
  controller: FleetBalanceWithdrawalController,
  bindings: {
    fleetId: '<'
  }
}
