import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'
import { AvailableFleet } from 'data/domain-objects/Fleet'

class FleetPicker {
  public name: string = ''
  public form: ng.IFormController
  public isRequired: boolean = false
  public isDisabled: boolean = false
  public fleets: AvailableFleet[]
  public fleetId: string
  public onFleetIdChanged: (data: {fleetId: string}) => void
  public showEta: boolean
  public showEtd: boolean
  public pricePrefix: string

  public localFleetId: string
  public localFleets: AvailableFleet[]
  public fleetIds: string[]
  public fleetNames: string[]

  $onInit (): void {
    this.localFleetId = this.fleetId
    this.localFleets = this.fleets
  }

  $doCheck (): void {
    if (this.fleets && this.fleets !== this.localFleets) {
      this.localFleets = this.fleets

      this.fleetIds = []
      this.fleetNames = []
      this.fleets.forEach((fleet: AvailableFleet) => {
        this.fleetIds.push(fleet.id)
        this.fleetNames.push(fleet.name)
      })
    }
  }

  $onChanges (changeDescriptorsByKey: ng.IOnChangesObject): void {
    this.localFleetId = getValueFromChangeDescriptors(changeDescriptorsByKey, 'fleetId', this.fleetId)
    this.localFleets = getValueFromChangeDescriptors(changeDescriptorsByKey, 'fleets', this.fleets)
  }

  handleLocalFleetIdChanged (): void {
    this.form.$setValidity(this.name + 'HasFleetId', !!this.localFleetId, null)
    this.onFleetIdChanged({fleetId: this.localFleetId})
  }
}

export default {
  templateUrl: require('./fleet-picker.pug'),
  controller: FleetPicker,
  bindings: {
    name: '@',
    form: '<',
    isRequired: '<?',
    isDisabled: '<?',
    fleets: '<',
    fleetId: '<',
    onFleetIdChanged: '&',
    showEta: '<?',
    showEtd: '<?',
    pricePrefix: '<?',
    showZeroPrice: '<?'
  }
}
