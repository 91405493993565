import template from './area-analytics-report.pug'
import areaReportCollection from 'data/collections/areaAnalyticsCollection'
import moment from 'moment'
import envConfig from 'common/envConfig'

class areaAnalyticsReport {
  constructor ($scope) {
    this.$scope = $scope

    this.areaColumns = [
      'area',
      'avgBatchSize',
      'avgDeliveryDistance',
      'avgDeliveryTime',
      'avgTotalMinutesPerDelivery',
      'daysActiveInPeriod',
      'deliveryAssignmentCount',
      'orderValue'
    ]

    this.areaDeliveryColumns = [
      'branch',
      'batchSize',
      'currencyAmount',
      'deliveryProcessingTime',
      'distanceCovered',
      'timeSpent'
    ]

    this.branchDeliveryColumns = [
      'area',
      'batchSize',
      'currencyAmount',
      'deliveryProcessingTime',
      'distanceCovered',
      'timeSpent'
    ]

    this.branchColumns = [
      'branch',
      'avgBatchSize',
      'avgDeliveriesPerDay',
      'avgDeliveryDistance',
      'avgDeliveryTime',
      'avgTotalMinutesPerDelivery',
      'daysActiveInPeriod',
      'deliveryAssignmentCount',
      'numberOfBatchedDeliveries',
      'orderValue'
    ]

    this.defaultStartDate = moment(Date.now()).subtract(envConfig.reports.areaAnalytics.defaultNumberOfDaysAgo, 'days')
    this.defaultEndDate = moment(Date.now())

    this.onResetDateRange()
  }

  onUpdateDateRange (payload) {
    this.filters.dateRange.startDate = payload.dateFrom
    this.filters.dateRange.endDate = payload.dateTo
    this.getReportsData('area')
    this.getReportsData('branch')
  }

  onResetDateRange () {
    this.filters = {
      dateRange: {
        startDate: moment(this.defaultStartDate),
        endDate: moment(this.defaultEndDate)
      }
    }
  }

  $onInit () {
    this.getReportsData('area')
    this.getReportsData('branch')
  }

  getReportsData (type = '') {
    const isLoadingKey = `${type}IsLoading`

    if (!this[isLoadingKey]) {
      this[isLoadingKey] = true

      areaReportCollection.getReportData(type, this.filters)
        .then(reportData => {
          const reportDataKeyName = `${type}Report`
          this[reportDataKeyName] = reportData
        })
        .catch(error => console.error(error))
        .then(() => {
          this[isLoadingKey] = false
          this.$scope.$digest()
        })
    }
  }
}

export default {
  templateUrl: template,
  controller: areaAnalyticsReport
}
