import moment, {Moment} from 'moment'
import envConfig from 'common/envConfig'

class RideReportCollection {
  private rideReportUrl: string = `${envConfig.api.prefix}${envConfig.api.paths.reportRidesRawCSV}?start={start}&finish={finish}`

  getRawCSVUrl (): string {
    const start: Moment = moment().subtract(30, 'days').set({hours: 0, minutes: 0, second: 0})
    const finish: Moment = moment().set({hours: 23, minutes: 59, second: 59})

    return this.rideReportUrl
      .replace('{start}', start.toISOString())
      .replace('{finish}', finish.toISOString())
  }
}

export default new RideReportCollection()
