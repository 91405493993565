import {StateService} from '@uirouter/core'
import BaseController from 'presentation/common/BaseController'
import localStorageService from 'data/services/localStorageService'
import storefrontPromoCodesCollection from 'data/collections/storefrontPromoCodesCollection'
import StorefrontPromoCode from 'data/domain-objects/storefront/StorefrontPromoCode'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'
const PROMO_CODE_STATE = 'main.storefront.promo-codes.promo-code'

class StorefrontPromoCodesController extends BaseController {
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public promoCodes: StorefrontPromoCode[]
  public searchQuery: string = ''

  constructor (
    public $scope: ng.IScope,
    public $state: StateService
  ) {
    super($scope, $state)

    if (this.businessId) {
      this.sync()
    }
  }

  onSync (): Promise<void> {
    return storefrontPromoCodesCollection.getAll(this.businessId, this.searchQuery)
      .then((response: IRecordsAndMetadata<any>): void => {
        this.promoCodes = response.records
      })
  }

  onBusinessSelected (businessOrBranch: any): void {
    this.businessId = businessOrBranch.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)

    if (this.businessId) {
      this.sync()
    } else {
      this.promoCodes = []
    }
  }

  doSearch (searchQuery: string): void {
    this.searchQuery = searchQuery
    this.sync()
  }

  viewPromoCode (promoCodeId: string): void {
    this.$state.go(PROMO_CODE_STATE, {
      businessId: this.businessId,
      promoCodeId
    })
  }

  deleteEntity (payload: {businessId: string, promoCodeId: string}): void | Promise<any> {
    return storefrontPromoCodesCollection.delete(payload.businessId, payload.promoCodeId)
      .catch((error: Error): void => this.logger.error('Cannot delete promo code', error))
      .then((): void => this.sync())
      .catch((error: Error): void => this.logger.error('Cannot get promo codes', error))
  }
}

export default {
  controller: StorefrontPromoCodesController,
  templateUrl: require('./storefront-promo-codes.pug')
}
