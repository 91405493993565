export default function getTransactionsMetadata (data = []) {
  return data.map(getMetadataFromSingleTransaction)
}

export function getMetadataFromSingleTransaction (transaction) {
  if (!transaction.metadata) {
    return {}
  }

  return transaction.metadata.reduce((metadata, field) => {
    metadata[field.name] = field.value
    return metadata
  }, {})
}

export function convertObjectToMetadataArray (object = {}) {
  return Object.keys(object).reduce((metadataArray, key) => {
    metadataArray.push({
      name: key,
      value: object[key]
    })

    return metadataArray
  }, [])
}
