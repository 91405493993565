import permissionsModel from 'data/models/permissionsModel'

class UsersDashboardController {

  public showTeamMembers: boolean

  constructor () {
    this.showTeamMembers = permissionsModel.getHasPermission('USER_MANAGEMENT')
  }

}

export default {
  templateUrl: require('./users-dashboard.pug'),
  controller: UsersDashboardController
}
