import templateUrl from './area-edit.pug'
import Logger from 'common/Logger'
import ValidationUtils from 'common/utils/validationUtils'
import areasCollection from 'data/collections/areasCollection'
import Area from 'data/domain-objects/Area'
import GeoJSONPolygon from 'data/domain-objects/GeoJSONPolygon'

const logger = new Logger('Area Controller')

class AreaEditController {
  constructor ($scope, $stateParams) {
    this.$scope = $scope
    this.$stateParams = $stateParams

    this.originalArea = null
    this.area = null
    this.isEditing = false
    this.submitFailedValidation = false
    this.submitFailedOther = false
    this.backendValidationErrors = {}
    this.isFormUnchanged = false

    $scope.$watch(() => this.area, (editedEntity, previousEntity) => {
      this.isFormUnchanged = !ValidationUtils.getIsEntityChanged(editedEntity, this.originalArea)
      ValidationUtils.resetBackendValidationForChanged(this.backendValidationErrors, editedEntity, previousEntity)
    }, true)
  }

  $onInit () {
    this.areaId = this.$stateParams.areaId
    this.getArea(this.areaId)
  }

  onPolygonEdited (polygon) {
    this.area.bounds = GeoJSONPolygon.build({coordinates: polygon})
    setTimeout(() => this.$scope.$digest())
  }

  getArea () {
    areasCollection.getArea([this.areaId])
      .then(area => {
        this.originalArea = area
        this.originalPolygon = GeoJSONPolygon.build(this.originalArea.bounds)
        this.area = Area.build(this.originalArea)
      })
      .catch(function () {
        logger.warn('Could not retrieve entity with id', this.areaId)
      })
      .then(() => this.$scope.$digest())
  }

  startEdit () {
    this.isEditing = true
  }

  cancelEdit () {
    this.isEditing = false
    this.submitFailedValidation = false
    this.submitFailedOther = false
  }

  submit () {
    areasCollection.updateArea(this.areaId, this.area)
      .then(area => {
        this.originalArea = area
        this.originalPolygon = this.originalArea.polygon.slice(0)
        this.area = Area.build(this.originalArea)
        this.handleSubmitSucceeded()
      })
      .catch(error => this.handleSubmitFailed(error))
      .then(() => this.$scope.$digest())
  }

  handleSubmitSucceeded () {
    this.isEditing = false
    this.submitFailedValidation = false
    this.submitFailedOther = false
  }

  handleSubmitFailed (error) {
    logger.error('Save entity failed', error)
    this.isEditing = true

    const errorDescriptorArraysByFieldName = error && error.validationErrors
    if (errorDescriptorArraysByFieldName) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(errorDescriptorArraysByFieldName)
      this.submitFailedValidation = true
      this.submitFailedOther = false
    } else {
      this.backendValidationErrors = {}
      this.submitFailedValidation = false
      this.submitFailedOther = true
    }
  }
}

export default {
  templateUrl,
  controller: AreaEditController
}
