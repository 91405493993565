import {ProductExtra} from 'data/domain-objects/Product'
import * as PRODUCT_EVENTS from 'common/constants/ProductEvents'
import BasePopupController from 'presentation/common/BasePopupController'

export const PRODUCT_EXTRA_DIALOG_ID = 'product-extra-dialog'

interface ICallbackPayload {
  extra: ProductExtra
}

class ProductExtraDialogController extends BasePopupController {
  public productExtra: ProductExtra
  public onSubmit: (payload: ICallbackPayload) => void

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IScope
  ) {
    super($scope, $rootScope, PRODUCT_EVENTS.ADD_PRODUCT_EXTRA_EVENT, PRODUCT_EXTRA_DIALOG_ID)

    this.setupPopupOpenEventListener((payload: any) => {
      this.resetModel()

      if (payload && payload.model) {
        this.productExtra = ProductExtra.build(payload.model)
      }
    })
  }

  resetModel (): void {
    this.productExtra = ProductExtra.build({})
  }

  submit (): void {
    this.onSubmit({extra: this.productExtra})
    this.close()
  }

  cancel (): void {
    this.close()
  }
}

export default {
  templateUrl: require('./product-extra-dialog.pug'),
  controller: ProductExtraDialogController,
  bindings: {
    onClose: '&?',
    onSubmit: '&'
  }
}
