import cloneDeep from 'lodash-es/cloneDeep'
import templateUrl from './business.pug'
import userModel from 'data/models/userModel'
import permissionsModel from 'data/models/permissionsModel'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import businessesCollection from 'data/collections/businessesCollection'
import envConfig from 'common/envConfig'

const DEFAULT_BUSINESS_ENTITY = {
  name: {
    en: '',
    ar: ''
  },
  contacts: {
    en: {
      fullName: '',
      email: '',
      countryDiallingCode: envConfig.defaultCountryCode.toString(),
      phoneNumber: ''
    }
  },
  logoUrl: ''
}

class BusinessController extends CreateViewEditFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state, $stateParams, 'businessId')
    this.isUserHasPermissionToEditBusiness = false
    this.isUserHasPermissionToCreateBusiness = permissionsModel.getHasPermission('BUSINESSES_CREATE')
  }

  $onInit () {
    this.backendModel = null
    super.$onInit()

    if (this.isCreate) {
      this.targetState = 'main.businesses'
      this.getBusinesses()
      this.getUser()
    }
  }

  getEntity (businessId) {
    const id = businessId || (this.$stateParams[this.entityIdParamName] || this.$stateParams.id)

    return businessesCollection.getWithAllLanguages(id)
      .then(business => {
        this.permissions = business.permissions
        this.backendModel = business
        this.isUserHasPermissionToEditBusiness = this.canEdit()
        this.setDefaultModel()
        this.$scope.$digest()
      })
      .catch(error => this.logger.error('Could not get service:', error))
  }

  getBusinesses () {
    businessesCollection.getAll()
      .then(businesses => this.handleBusinessesSuccess(businesses))
      .catch(error => BusinessController.handleBusinessesFailed(error))
      .then(() => this.$scope.$apply())
  }

  handleBusinessesSuccess (businesses) {
    this.businesses = businesses
    this.isCreatingFirstBusiness = !this.businesses.length
  }

  static handleBusinessesFailed () {
    this.logger.error('Businesses could not be fetched')
  }

  getUser () {
    userModel.getUser()
      .then(user => this.handleGetUserSuccess(user))
      .catch(error => BusinessController.handleGetUserFailed(error))
      .then(() => this.$scope.$apply())
  }

  handleGetUserSuccess (user) {
    this.logger.info('Prepopulating business point of contact from user:', user)
    this.model.contactName = `${user.firstName} ${user.lastName}`
    this.model.contactEmail = user.email
    this.model.contactCountryDiallingCode = user.countryDiallingCode
    this.model.contactPhoneNumber = user.phoneNumber
  }

  static handleGetUserFailed (error) {
    this.logger.error('Could not prepopulate business contact details from user:', error)
  }

  redirectToCreateBranchState (business) {
    this.$state.go('main.businesses.business.branches.create', {
      businessId: business.id,
      targetState: this.targetState
    })
  }

  getDefaultModel () {
    return this.backendModel || cloneDeep(DEFAULT_BUSINESS_ENTITY)
  }

  updateLogoUrl (logoUrl) {
    this.model.logoUrl = logoUrl
  }

  beforeSubmit () {
    if (this.model.contacts && this.model.contacts.hasOwnProperty('ar')) {
      delete this.model.contacts.ar
    }
    if (this.isCreate) {
      return businessesCollection.create(this.model)
        .then(business => this.getEntity(business.id))
    }

    return businessesCollection.update(this.model, this.model.id)
  }
}

export default {
  templateUrl,
  controller: BusinessController
}
