import templateUrl from './kanban-card.pug'
import {DELIVERY_STATUSES} from 'common/constants/DeliveryStatuses'
import moment from 'moment'
import Payment from 'data/domain-objects/Payment'
import {DELIVERY_ACTIONS, DELIVERY_TYPES} from 'data/collections/deliveriesCollection'
import userModel from 'data/models/userModel'

class KanbanCard {
  constructor ($scope, kanbanDataService, $filter) {
    this.$scope = $scope
    this.kanbanDataService = kanbanDataService
    this.actionsVisible = false
    this.$filter = $filter
    this.actions = DELIVERY_ACTIONS
    this.show = {}
  }

  $onInit () {
    if (typeof this.settings === 'undefined') {
      this.settings = this.kanbanDataService.settings
    }
  }

  convertOldDeliveryDataFormat (data) {
    const businessBranchNames = data.sender.name.split('-')
    this.card = {
      id: data.id,
      ref: data.ref,
      sender: {
        id: data.sender.id,
        type: data.sender.type,
        businessName: businessBranchNames[0],
        branchName: businessBranchNames[1],
        area: undefined // missing data
      },
      consumer: {
        id: data.recipient.id,
        type: data.recipient.type,
        firstName: data.recipient.consumerFirstName,
        lastName: data.recipient.consumerLastName
      },
      payment: data.payment,
      fleet: {
        name: data.fleet.name,
        logoUrl: data.fleet.logoUrl
      },
      status: data.status,
      permissions: ['allowManagerTransferDelivery'],
      etaPickup: data.etaPickup,
      etaDropoff: data.etaDropoff,
      type: data.type,
      updatedAt: data.updatedAt,
      statusUpdatedAt: data.statusUpdatedAt
    }
  }

  calculateValues () {
    if (!this.card) {
      return
    }

    const hideETAandETDStatusesList = [
      DELIVERY_STATUSES.NOT_READY,
      DELIVERY_STATUSES.PENDING,
      DELIVERY_STATUSES.ALLOCATING,
      DELIVERY_STATUSES.DELIVERED,
      DELIVERY_STATUSES.ISSUE,
      DELIVERY_STATUSES.CANCELLED
    ]

    if (this.card.type === DELIVERY_TYPES.RIDE) {
      this.card.isRide = true

      if (this.card.allocation && this.card.allocation.booking && this.card.allocation.booking.used && this.card.allocation.booking.driver) {
        this.card.isAllocationBooked = true
      }
    }

    if (this.card.consumer && (this.card.consumer.firstName || this.card.consumer.lastName)) {
      this.card.recipient = `${this.card.consumer.firstName || ''} ${this.card.consumer.lastName || ''}`
    } else {
      this.show.recepient_name = false
    }

    // this.show.estimations is a superclass for show.eta, show.etd and show.schedule. These subclasses are depending on implementation below
    this.show.eta = true
    this.show.etd = true

    if (hideETAandETDStatusesList.indexOf(this.card.status) > -1) {
      this.show.eta = false
      this.show.etd = false
    }

    if (this.card.status !== DELIVERY_STATUSES.ALLOCATED) {
      this.show.eta = false
    }

    if (this.card.pickupTime) {
      const pickupTimeIsToday = moment(this.card.pickupTime).isSame(moment(), 'day')
      const scheduleTimeFormat = pickupTimeIsToday ? 'HH:mm' : 'dd/MM/yy HH:mm'
      this.scheduleTime = this.$filter('date')(this.card.pickupTime, scheduleTimeFormat)

      if ([DELIVERY_STATUSES.NOT_READY, DELIVERY_STATUSES.PENDING, DELIVERY_STATUSES.ALLOCATING].indexOf(this.card.status) > -1) {
        this.show.schedule = true
        this.show.scheduleForNextDay = false
      }

      if ((this.card.isInbound || this.card.isOutbound) && this.card.status !== DELIVERY_STATUSES.DELIVERED) {
        this.show.schedule = false
        this.show.scheduleForNextDay = true
      }
    }

    if (this.card.status === DELIVERY_STATUSES.DELIVERED) {
      this.card.statusLabel = this.$filter('date')(this.card.statusUpdatedAt, 'dd/MM/yy HH:mm')
    } else if (this.card.status === DELIVERY_STATUSES.ISSUE || this.card.status === DELIVERY_STATUSES.CANCELLED) {
      this.card.cancelLabel = this.$filter('translate')(`COMMON.DELIVERY_STATUSES.${this.card.status}`)
    }

    // REAL data
    const driver = this.card.isAllocationBooked ? this.card.allocation.booking.driver : this.card.driver

    this.card.driverName = driver && (driver.firstName || driver.lastName) ? `${driver.firstName} ${driver.lastName}` : null
    this.card.driverPhone = driver && driver.countryDiallingCode && driver.phoneNumber ? `+${driver.countryDiallingCode} ${driver.phoneNumber}` : null

    this.card.business = this.card.sender.businessName
    this.card.branch = this.card.sender.branchName
    this.card.area = this.card.sender.area

    const paymentInfo = new Payment(this.card.payment.cost, this.card.payment.method)
    this.card.price = {
      amount: paymentInfo.amount ? paymentInfo.getFormattedCost() : '-',
      type: this.card.payment.method
    }

    this.card.pos = this.card.payment.posReference ? `(${this.card.payment.posReference})` : ''
    this.card.updatedAt = this.$filter('specificTimeTo')(this.card.statusUpdatedAt.toISOString(), true, true, '')
    this.card.eta = this.card.etaPickup && !!this.card.etaPickup.time ? this.$filter('specificTimeTo')(this.card.etaPickup.time.toISOString(), false, true, '') : ''
    if (this.card.isAllocationBooked && this.card.allocation.booking.etaPickupTime) {
      this.card.eta = this.card.allocation.booking.etaPickupTime.time ? this.$filter('specificTimeTo')(this.card.allocation.booking.etaPickupTime.time.toISOString(), false, true, '') : ''
    }
    this.card.etd = this.card.etaDropoff && !!this.card.etaDropoff.time ? this.$filter('specificTimeTo')(this.card.etaDropoff.time.toISOString(), false, true, '') : ''

    if (!this.card.etaPickup.time && this.show.eta) {
      this.show.eta = false
    }
    if (!this.card.etaDropoff.time && this.show.etd) {
      this.show.etd = false
    }

    if ([DELIVERY_STATUSES.NOT_READY, DELIVERY_STATUSES.PENDING].indexOf(this.card.status) > -1 && !!this.card.pickupTime) {
      this.show.scheduledIcon = true
    }
  }

  $onChanges (changes) {
    if (changes.hasOwnProperty('oldDeliveryDataFormat') && changes.oldDeliveryDataFormat.currentValue) {
      this.convertOldDeliveryDataFormat(changes.oldDeliveryDataFormat.currentValue)
      this.settings = this.kanbanDataService.settings
      this.show = Object.assign({}, this.settings.cards)
    }

    if (changes.hasOwnProperty('settings') && changes.settings.currentValue) {
      this.show = Object.assign({}, this.settings.cards)
    }

    this.calculateValues()
    this.processVisibilityButtons()
  }

  closeMenu () {
    this.actionsVisible = false
    this.menuActions = false
  }

  openMenu () {
    this.actionsVisible = true
    this.menuActions = true
  }

  processVisibilityButtons () {
    this.buttonsVisibility = {
      ready: false,
      pending: false,
      rehail: false,
      changeDriver: false,
      batching: false
    }

    if (this.card.permissions && this.card.permissions.indexOf('allowBatching') > -1) {
      this.buttonsVisibility.batching = true
    }

    if (!this.card) {
      return
    }

    if (this.card.status === DELIVERY_STATUSES.NOT_READY || this.card.status === DELIVERY_STATUSES.PENDING) {
      this.actionsVisible = true
    }

    if (this.card.status === DELIVERY_STATUSES.PENDING || this.card.status === DELIVERY_STATUSES.ALLOCATING || this.card.status === DELIVERY_STATUSES.ALLOCATED || this.card.status === DELIVERY_STATUSES.AT_PICKUP) {
      this.buttonsVisibility.pending = true
    }
    if (this.card.status === DELIVERY_STATUSES.NOT_READY) {
      this.buttonsVisibility.ready = true
    }

    if (this.card.permissions && this.card.permissions.indexOf('allowManagerTransferDelivery') > -1
      && (this.card.status === DELIVERY_STATUSES.PENDING || this.card.status === DELIVERY_STATUSES.ALLOCATING || this.card.status === DELIVERY_STATUSES.ALLOCATED || this.card.status === DELIVERY_STATUSES.AT_PICKUP)
    ) {
      this.buttonsVisibility.changeDriver = true
    }
    if ((userModel.getLocalUser().isSuperAdmin || userModel.getLocalUser().isFleetManager) && (this.card.status === DELIVERY_STATUSES.ISSUE || this.card.status === DELIVERY_STATUSES.CANCELLED)) {
      this.buttonsVisibility.rehail = true
    }
  }

  onActionClick (action) {
    if (this.card) {
      this.$scope.$emit(action, this.card)
    }
  }
}

export default {
  templateUrl,
  controller: ['$scope', 'kanbanDataService', '$filter', KanbanCard],
  bindings: {
    card: '<',
    settings: '<',
    oldDeliveryDataFormat: '<?'
  }
}
