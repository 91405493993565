import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import { BusinessHours, Period } from 'data/domain-objects/BusinessHours'

enum ScheduleTypes {
  alwaysOpened = 'alwaysOpened',
  sameAllDays = 'sameAllDays',
  custom = 'custom'
}

const getEmptyTimerange = (): ITimeRange => {
  return {
    from: '',
      to: ''
  }
}

const TIME_FORMAT = 'HH:mm'
const ZERO_TIME = '00:00'
const DAY_END = '24:00'

const FROM_FIELD_NAME = 'from'
const TO_FIELD_NAME = 'to'
const MONDAY = 'monday'

class EditableListBusinessHoursController {
  public form: ng.IFormController
  public model: any = {}
  public onChange: (payload: {value: any}) => void
  public selectedScheduleType: ScheduleTypes

  public customScheduleType: ScheduleTypes = ScheduleTypes.custom
  public alwaysOpenScheduleType: ScheduleTypes = ScheduleTypes.alwaysOpened

  public weekdays: Partial<BusinessHours> = {
    monday: [] as Period[],
    tuesday: [] as Period[],
    wednesday: [] as Period[],
    thursday: [] as Period[],
    friday: [] as Period[],
    saturday: [] as Period[],
    sunday: [] as Period[]
  }

  public dateTimePickerConfig: any = {
    isRange: false,
    showDate: false,
    showTime: true,
    selectedDate: new Date(),
    minutesChangeStep: 1
  }

  public scheduleTypes: ScheduleTypes[] = [
    ScheduleTypes.alwaysOpened,
    ScheduleTypes.sameAllDays,
    ScheduleTypes.custom
  ]

  constructor (public $scope: ng.IScope) {}

  $onInit (): void {
    this.$scope.$watch('$ctrl.weekdays', (): void => {
      if (this.model && this.weekdays) {
        this.handleBusinessHoursUpdate()
      }
    }, true)
  }

  $onChanges (): void {
    Object.keys(this.weekdays).forEach((weekday: string): void => {
      this.weekdays[weekday] = (this.model && this.model[weekday].length) ? cloneDeep(this.model[weekday]) : [getEmptyTimerange()]
    })

    this.detectScheduleType()
  }

  detectScheduleType(): void {
    const dayBegining = '00:00'
    const dayEnd = '24:00'
    const weekdayNames = Object.keys(this.weekdays)
    const monday = MONDAY
    const areAllTheSame = weekdayNames.every((weekday: string): boolean => JSON.stringify(this.weekdays[weekday]) === JSON.stringify(this.weekdays[monday]))
    const isAlwaysOpened = areAllTheSame &&
      this.weekdays[monday].length === 1 &&
      this.weekdays[monday][0].from === ZERO_TIME &&
      this.weekdays[monday][0].to === DAY_END

    this.selectedScheduleType = areAllTheSame ? (isAlwaysOpened ? ScheduleTypes.alwaysOpened : ScheduleTypes.sameAllDays) : ScheduleTypes.custom
  }

  onSheduleTypeChanged(value: ScheduleTypes): void {
    const weekdayNames = Object.keys(this.weekdays)
    const monday = MONDAY

    if (value !== ScheduleTypes.custom) {
      if (value === ScheduleTypes.alwaysOpened) {
        this.weekdays[monday] = [Period.build({
          from: ZERO_TIME,
          to: DAY_END
        })]
      }

      this.copyAllDaysFromMonday()
    }

    this.selectedScheduleType = value
    this.handleBusinessHoursUpdate()
  }

  copyAllDaysFromMonday(): void {
    const weekdayNames = Object.keys(this.weekdays)
    const monday = MONDAY

    weekdayNames.forEach((weekday: string): void => {
      this.weekdays[weekday] = cloneDeep(this.weekdays[monday])
    })
  }

  onTimeChanged(value: string, weekday: string): void {
    if (weekday === MONDAY && this.selectedScheduleType !== ScheduleTypes.custom) {
      this.copyAllDaysFromMonday()
    }

    this.detectScheduleType()
    this.onSheduleTypeChanged(this.selectedScheduleType)
  }

  resetWeekday (weekday: string): void {
    this.weekdays[weekday] = [getEmptyTimerange()]
  }

  addRowToWeekday (weekday: string): void {
    this.weekdays[weekday].push(getEmptyTimerange())
  }

  removeRowFromWeekday (weekday: string, index: number): void {
    this.weekdays[weekday].splice(index, 1)
  }

  handleBusinessHoursUpdate(): void {
    const businessHours = Object.assign({}, this.weekdays)

    if (!isEqual(this.model, businessHours)) {
      this.onChange({value: businessHours})

      if (this.form && this.form.$setDirty) {
        this.form.$setDirty()
      }
    }
  }
}

export default {
  controller: EditableListBusinessHoursController,
  templateUrl: require('./business-hours.pug'),
  bindings: {
    form: '<?',
    name: '@?',
    model: '<',
    onChange: '&',
    isEditing: '<',
    isDisabled: '<',
    isRequired: '<?',
    valueMessage: '@?',
    valueWarning: '@?'
  }
}
