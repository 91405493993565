import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'
import Ride from 'data/domain-objects/Ride'

const rideService = new CrudService(endpoints.RIDE)

export default {
  create (ride: Ride): Promise<Ride> {
    return rideService.create(ride.toRideDTO())
  }
}
