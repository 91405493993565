class EditableListToggle {
  public model: boolean

  onToggleChange(value: boolean): void {
    this.model = value
  }
}

export default {
  templateUrl: require('./editable-list-toggle.pug'),
  controller: EditableListToggle,
  bindings: {
    form: '=',
    title: '@',
    model: '=',
    isEditing: '<',
    isRequired: '<',
    isDisabled: '<',
    hostClass: '@',
    valuePrefixTranslationKey: '@',
    valueMessage: '@'
  }
}
