import envConfig from 'common/envConfig'
import templateUrl from './consumer-address-location-map-state.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'

const SETTINGS_KEYS = [
  'showConsumerAddressLocationMap',
  'consumerAddressLocationMapState']

const SHOW_VALUE = 'show'

class DeliveryMapStateSettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTINGS_KEYS)
    this.SHOW_VALUE = SHOW_VALUE
    this.consumerAddressLocationMapStates = envConfig.businessAndBranchSettings.consumerAddressLocationMapStates
  }
}

export default {
  templateUrl,
  controller: DeliveryMapStateSettingsController,
  bindings: {
    form: '=',
    model: '=',
    isEditing: '<'
  }
}
