class ToggleController {
  public turnedOn: boolean
  public onChange: (data: {value: boolean}) => void
  public form: ng.IFormController
  public preventTurningOn: boolean

  $onInit (): void {
    this.turnedOn = !!this.turnedOn
  }

  toggle (): void {
    if (!this.turnedOn) {
      if (this.preventTurningOn) {
        return
      }
    }

    this.turnedOn = !this.turnedOn
    this.onChange({value: this.turnedOn})
    if (this.form) {
      this.form.$setDirty()
    }
  }

}

export default {
  templateUrl: require('./toggle.pug'),
  controller: ToggleController,
  bindings: {
    form: '=',
    labelOn: '@',
    labelOff: '@',
    turnedOn: '<',
    onChange: '&?',
    hostClass: '@?',
    preventTurningOn: '<?'
  }
}
