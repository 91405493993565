import Logger from 'common/Logger'
import ValidationUtils from 'common/utils/validationUtils'
import Area from 'data/domain-objects/Area'
import areasCollection from 'data/collections/areasCollection'
import {StateParams, StateService} from '@uirouter/core'
import LatLongPoint from 'data/domain-objects/LatLongPoint'
import GeoJSONPolygon from 'data/domain-objects/GeoJSONPolygon'

const logger = new Logger('Create Area Controller')

class AreaCreateController {

  public targetState: string = ''

  private area: Area = null
  public submitFailedValidation: boolean = false
  public submitFailedOther: boolean = false
  private backendValidationErrors: any = null // TODO: get rid of any
  public isEditing: boolean = true

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams
  ) {
    this.backendValidationErrors = {}
  }

  $onInit (): void {
    this.area = new Area()

    this.targetState = this.$state.get(this.$stateParams.targetState) ? this.$stateParams.targetState : ''
    this.$scope.$watch(() => this.area, (updatedEntity: any, previousEntity: any) => {
      ValidationUtils.resetBackendValidationForChanged(this.backendValidationErrors, updatedEntity, previousEntity)
    }, true)
  }

  onPolygonCreated(polygon: number[][][]): void {
    this.area.bounds = GeoJSONPolygon.build({coordinates: polygon})
    setTimeout(() => this.$scope.$digest())
  }

  submit (): void {
    areasCollection.createArea(this.area)
      .then(() => this.handleSubmitSucceeded())
      .then(() => this.redirectToNextState())
      .catch((error: Error) => this.handleSubmitFailed(error))
      .then(() => this.$scope.$apply())
  }

  handleSubmitSucceeded (): void {
    this.submitFailedValidation = false
    this.submitFailedOther = false
  }

  handleSubmitFailed (error: any): void {
    logger.error('Submit failed', error)
    const errorDescriptorArraysByFieldName = error && error.validationErrors
    if (errorDescriptorArraysByFieldName) {
      this.backendValidationErrors = ValidationUtils.getValidationErrorsByField(errorDescriptorArraysByFieldName)
      this.submitFailedValidation = true
      this.submitFailedOther = false
    } else {
      this.backendValidationErrors = {}
      this.submitFailedValidation = false
      this.submitFailedOther = true
    }
  }

  redirectToNextState (): void {
    this.$state.go('main.logistics.areas')
  }
}

export default {
  templateUrl: require('./area-create.pug'),
  controller: AreaCreateController
}
