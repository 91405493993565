import isObjectLike from 'lodash-es/isObjectLike'
import Cost from 'data/domain-objects/Cost'

export default function formatCostFilter () {
  function formatCost (cost) {
    // Cost may be a Cost instance, or a cost descriptor with either amount or value
    if (isObjectLike(cost)) {
      if (cost instanceof Cost) {
        return cost.getFormattedCost()
      } else {
        const costDO = Cost.build({
          currencyCode: cost.currencyCode,
          amount: typeof cost.value === 'number' ? cost.value : cost.amount
        })
        return costDO.getFormattedCost()
      }
    } else {
      return '-'
    }
  }

  return formatCost
}
