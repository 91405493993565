import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'
import kanban from './kanban'
import KanbanDataService from './kanbanData'

import kanbanFilters from './kanban-filters/kanbanFilters'
import kanbanTable from './kanban-table/kanbanTable'
import kanbanPopup from './kanban-popup/kanbanPopup'
import kanbanCard from './kanban-card/kanbanCard'
import kanbanSettings from './kanban-settings/kanbanSettings'

export default declareAppModule('kanban', (module: ng.IModule) => module
  .component('kanbanFilters', kanbanFilters)
  .component('kanbanCard', kanbanCard)
  .component('kanbanTable', kanbanTable)
  .component('kanbanPopup', kanbanPopup)
  .component('kanban', kanban)
  .component('kanbanSettings', kanbanSettings)
  .service('kanbanDataService', KanbanDataService)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'kanban',
      stateKey: 'KANBAN',
      componentName: 'kanban',
      requiresPermission: 'DELIVERIES',
      data: {
        titleKey: 'PAGES.KANBAN.TITLE'
      }
    })

    declareState($stateProvider, {
      parent: 'main.kanban',
      stateName: 'settings',
      stateKey: 'KANBAN_SETTINGS',
      componentName: 'kanbanSettings',
      requiresPermission: 'DELIVERIES',
      data: {
        titleKey: 'PAGES.KANBAN_SETTINGS.TITLE'
      }
    })
  })
)
