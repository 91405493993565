import templateUrl from './fleet-tariffs-tariff.pug'
import BaseController from 'presentation/common/BaseController'
import fleetTariffsCollection from 'data/collections/fleetTariffsCollection'
import fleetsCollection from 'data/collections/fleetsCollection'

class FleetTariffsTariffController extends BaseController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.tariff = null
    this.isEditingTariffOnLoad = false
    this.targetState = $stateParams.targetState
    this.fleetId = $stateParams.fleetId
    this.fleet = null

    this.sync()
  }

  onSync () {
    return Promise.all([
      fleetTariffsCollection.getFleetTariff(this.$stateParams.fleetId, this.$stateParams.tariffId),
      fleetsCollection.get(this.$stateParams.fleetId)
    ]).then(([tariff, fleet]) => {
      this.fleet = fleet
      return this.handleTariffReceived(tariff)
    }).catch(error => this.handleTariffFailed(error))
  }

  handleTariffReceived (tariff) {
    this.tariff = tariff
  }

  handleTariffFailed (error) {
    this.logger.error('Failed to receive tariff:', error)
  }
}

export default {
  templateUrl,
  controller: FleetTariffsTariffController
}
