import templateUrl from './modal-dialog.pug'
import modalDialogStateService from './modalDialogStateService'

const HIDE_SCROLL_CLASS = 'drwl-hide-scroll'

class ModalDialogController {
  constructor ($transitions) {
    $transitions.onFinish({to: '*.**'}, () => {
      this.removeScrollClasses()
    })
  }

  $onInit () {
    this.isVisible = false
    this.openModalDialogSubscription = modalDialogStateService.onOpenModalDialog(payload => this.subscriptionOnOpen(payload))
    this.closeModalDialogSubscription = modalDialogStateService.onCloseModalDialog(payload => this.subscriptionOnClose(payload))
  }

  $onDestroy () {
    this.openModalDialogSubscription.remove()
    this.closeModalDialogSubscription.remove()
  }

  close (event) {
    event.stopPropagation()
    this.onClose()
    modalDialogStateService.emitCloseModalDialog({dialogId: this.dialogId})
  }

  subscriptionOnOpen (payload) {
    if (this.dialogId && (payload.dialogId && payload.dialogId === this.dialogId)) {
      document.body.classList.add(HIDE_SCROLL_CLASS)
      document.documentElement.classList.add(HIDE_SCROLL_CLASS)
      this.isVisible = true
      this.onOpen({payload})
    }
  }

  subscriptionOnClose (payload) {
    if (!this.dialogId || (payload.dialogId && payload.dialogId === this.dialogId)) {
      this.isVisible = false
      this.removeScrollClasses()
    }
  }

  removeScrollClasses () {
    // run digest first then class toggle, to prevent blinking effect
    setTimeout(() => {
      document.body.classList.remove(HIDE_SCROLL_CLASS)
      document.documentElement.classList.remove(HIDE_SCROLL_CLASS)
    }, 0)
  }
}

export default {
  templateUrl,
  controller: ModalDialogController,
  transclude: true,
  bindings: {
    hostClass: '@?',
    dialogId: '<',
    onClose: '&',
    onOpen: '&'
  }
}
