import templateUrl from './delivery-cost-confirmation.pug'

export default {
  templateUrl,
  bindings: {
    currentDeliveryCost: '<',
    newDeliveryCost: '<',
    onConfirm: '&',
    onCancel: '&'
  }
}
