import template from './dashboard.pug'
import userModel from 'data/models/userModel'
import BaseController from 'presentation/common/BaseController'
import permissionsModel from 'data/models/permissionsModel'

class DashboardController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.hasClaimedAccount = !!this.$state.params.hasClaimedAccount
    this.user = null
    const userIsClaimed = permissionsModel.getHasPermission('USER_IS_CLAMED')
    if (userIsClaimed) {
      this.sync()
    } else {
      userModel.logOut()
    }
  }

  $onInit () {
    this.show = {
      logistics: permissionsModel.getHasPermission('LOGISTICS'),
      kanban: permissionsModel.getHasPermission('TRACKING') || permissionsModel.getHasPermission('DELIVERIES'),
      reports: permissionsModel.getHasPermission('REPORTS')
    }
  }

  onSync () {
    return userModel.getUser()
      .then(data => this.user = data)
      .catch(err => this.logger.error('Cannot get user', err))
  }
}

export default {
  templateUrl: template,
  controller: DashboardController
}
