export const enum StorefrontOrdersStatusesType {
  submitted = 'submitted',
  accepted = 'accepted',
  readyForCollection = 'readyForCollection',
  collected = 'collected',
  completed = 'completed',
  declined = 'declined',
  cancelled = 'cancelled'
}

export const STOREFRONT_ORDER_STATUSES = {
  SUBMITTED: StorefrontOrdersStatusesType.submitted,
  ACCEPTED: StorefrontOrdersStatusesType.accepted,
  READY_FOR_COLLECTION: StorefrontOrdersStatusesType.readyForCollection,
  COLLECTED: StorefrontOrdersStatusesType.collected,
  COMPLETED: StorefrontOrdersStatusesType.completed,
  DECLINED: StorefrontOrdersStatusesType.declined,
  CANCELLED: StorefrontOrdersStatusesType.cancelled
}
