import templateUrl from './issue-tracker-filters.pug'
import * as issueTrackerEvents from 'common/constants/IssueTrackerEvents'

class IssueTrackerFiltersController {
  constructor ($scope) {
    this.$scope = $scope

    $scope.$on(issueTrackerEvents.OPEN_FILTERS, () => {
      this.updateSelectedBranch()
      this.open()
    })

    // defaults
    this.isOpened = false
  }

  setSearchQuery (value) {
    this.filters.searchQuery = value
  }

  onBusinessBranchSelect (businessOrBranch) {
    this.filters.selectedBusinessId = businessOrBranch.businessId
    this.filters.selectedBranchId = businessOrBranch.branchId
    this.updateSelectedBranch()
  }

  updateSelectedBranch () {
    this.selectedBranch = this.filters.selectedBranchId || this.filters.selectedBusinessId || null
  }

  close () {
    this.isOpened = false
    this.onFiltersChange()
  }

  open () {
    this.isOpened = true
  }
}

export default {
  templateUrl,
  controller: IssueTrackerFiltersController,
  bindings: {
    isOpened: '<',
    filters: '<',
    onFiltersChange: '&'
  }
}
