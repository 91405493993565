import templateUrl from './driver-job-task.pug'
// import PaymentMethods from 'common/constants/PaymentMethods'

const KNOWN_TASK_STATUSES = ['drive', 'collect', 'deliver', 'pickup', 'dropoff', 'credit', 'debit']

class DriverJobTask {
  constructor ($scope, $filter) {
    this.$scope = $scope
    this.$filter = $filter
  }

  $onChanges () {
    if (this.task) {
      this.formatData()
    }
  }

  getPaymentMethod (task) {
    const delivery = task.metadata.deliveries[0]
    const paymentMethod = delivery.payment.method
    return paymentMethod
  }

  formatData () {
    this.showData = KNOWN_TASK_STATUSES.indexOf(this.task.action) > -1

    this.isInProgress = this.task.status === 'IN_PROGRESS'
    this.isDone = this.task.status === 'DONE'

    this.status = (this.isInProgress && this.task.eta)
      ? this.$filter('specificTimeTo')(this.task.eta)
      : this.$filter('translate')(`COMPONENTS.DRIVER_JOB_TASK.STATUS.${this.task.status}`)

    this.contents = ''
    const actionLabel = this.$filter('translate')(`COMPONENTS.DRIVER_JOB_TASK.ACTION.${this.task.action}`)
    const isPaid = this.getPaymentMethod(this.task) === 'card' && !this.task.cashOffSystem

    const cost = (this.task.cashOffSystem)
      ? this.$filter('translate')('COMMON.PAYMENT_METHODS.CASH_OFF_SYSTEM')
      : this.task.costs.map(cost => cost.getFormattedCost()).join(',')

    switch (this.task.action) {
    case 'drive':
      this.contents = (this.task.address)
        ? this.task.partyName
        : this.$filter('translate')('COMPONENTS.DRIVER_JOB_TASK.FREE_RIDE.LOCATION')

      this.contents = `${actionLabel} ${this.contents}`
      break

    case 'collect':
    case 'deliver':
      this.contents = this.task.deliveryRefs.join(', ')
      this.contents = `${actionLabel} ${this.contents}`
      break

    case 'pickup':
    case 'dropoff':
      this.contents = this.task.partyName
      this.contents = `${actionLabel} ${this.contents}`
      break

    case 'credit':
      if (isPaid) {
        this.showData = false
      } else {
        this.contents = [
          this.$filter('translate')('COMPONENTS.DRIVER_JOB_TASK.ACTION.CREDIT'),
          cost,
          this.$filter('translate')('COMPONENTS.DRIVER_JOB_TASK.ACTION.CREDIT_INFIX'),
          this.task.partyName
        ].join(' ')
      }
      break

    case 'debit':

      if (isPaid) {
        this.showData = false
      } else {
        this.contents = [
          actionLabel,
          cost,
          this.$filter('translate')('COMPONENTS.DRIVER_JOB_TASK.ACTION.DEBIT_INFIX'),
          this.task.partyName
        ].join(' ')
      }

      break
    }

    if (this.task.address && this.task.action === 'drive') {
      this.address = this.task.address.getFormattedAddress()
    }
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$filter', DriverJobTask],
  bindings: {
    job: '<',
    task: '<'
  }
}
