import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import business from './business/business'
import businessSettings from './business/business-settings/businessSettings'
import branchSettings from './branch-settings/branchSettings'
import branch from './branches-branch/branch'
import BusinessesDashboard from './dashboard/BusinessesDashboard'

import branchesBranchCashBalance from './branches-branch-cash-balance/branchesBranchCashBalance'
import branchCurrentCashBalance from './branches-branch-cash-balance/branch-current-cash-balance/branchCurrentCashBalance'
import branchBalanceHistoryTable from './branches-branch-cash-balance/branch-balance-history-table/branchBalanceHistoryTable'
import deliveryReceiptSettings from './delivery-receipt-settings/deliveryReceiptSettings'
import consumerAddressLocationMapState from './consumer-address-location-map-state/consumerAddressLocationMapState'
import deliveryTypeSettings from './delivery-type-settings/deliveryTypeSettings'
import deliveryChargeSettings from './delivery-charge-settings/deliveryChargeSettings'
import deliveryBatchingSettings from './delivery-batching-settings/deliveryBatchingSettings'
import deliveryStatusSettings from './delivery-status-settings/deliveryStatusSettings'
import proofOfDeliverySettings from './proof-of-delivery-settings/proofOfDeliverySettings'
import textMessageSettings from './text-message-settings/textMessageSettings'
import fleetSettings from './fleet-settings/fleetSettings'
import orderDetailsSettings from './order-details-settings/orderDetailsSettings'
import requestPaymentOptionsSettings from './request-payment-options-settings/requestPaymentOptionsSettings'
import pickupTimeSettings from './pickup-time-settings/pickupTimeSettings'
import printDialogSettings from './print-dialog-settings/printDialogSettings'
import packageSettings from './package-settings/packageSettings'
import radioLink from './radio-link/radioLink'
import textMessageSettingsTerm from './text-message-settings/text-message-settings-term/textMessageSettingsTerm'
import areasSettings from './areas-settings/areasSettings'
import verifyAddressSettings from './verify-address-settings/verifyAddressSettings'
import customDomainSettings from './custom-domain-settings/customDomainSettings'
import businessStorefrontSettings from './business-storefront-settings/businessStorefrontSettings'
import branchStorefrontSettings from './branch-storefront-settings/branchStorefrontSettings'
import purchaseStorefrontDialog from './purchase-storefront-dialog/purchaseStorefrontDialog'
import businessStorefrontAppearanceSettings from './business-storefront-appearance-settings/businessStorefrontAppearanceSettings'
import businessStorefrontMenuDisplaySettings from './business-storefront-menu-display-settings/businessStorefrontMenuDisplaySettings'

export default declareAppModule('businesses', (module: ng.IModule) => module
  .component('branchesBranchCashBalance', branchesBranchCashBalance)
  .component('branchCurrentCashBalance', branchCurrentCashBalance)
  .component('branchBalanceHistoryTable', branchBalanceHistoryTable)
  .component('businessSettings', businessSettings)
  .component('branchSettings', branchSettings)
  .component('business', business)
  .component('branch', branch)
  .component('businessesDashboard', BusinessesDashboard)
  .component('deliveryReceiptSettings', deliveryReceiptSettings)
  .component('consumerAddressLocationMapState', consumerAddressLocationMapState)
  .component('deliveryTypeSettings', deliveryTypeSettings)
  .component('deliveryChargeSettings', deliveryChargeSettings)
  .component('deliveryBatchingSettings', deliveryBatchingSettings)
  .component('deliveryStatusSettings', deliveryStatusSettings)
  .component('proofOfDeliverySettings', proofOfDeliverySettings)
  .component('textMessageSettings', textMessageSettings)
  .component('fleetSettings', fleetSettings)
  .component('orderDetailsSettings', orderDetailsSettings)
  .component('requestPaymentOptionsSettings', requestPaymentOptionsSettings)
  .component('pickupTimeSettings', pickupTimeSettings)
  .component('printDialogSettings', printDialogSettings)
  .component('packageSettings', packageSettings)
  .component('radioLink', radioLink)
  .component('textMessageSettingsTerm', textMessageSettingsTerm)
  .component('areasSettings', areasSettings)
  .component('verifyAddressSettings', verifyAddressSettings)
  .component('customDomainSettings', customDomainSettings)
  .component('businessStorefrontSettings', businessStorefrontSettings)
  .component('businessStorefrontAppearanceSettings', businessStorefrontAppearanceSettings)
  .component('businessStorefrontMenuDisplaySettings', businessStorefrontMenuDisplaySettings)
  .component('branchStorefrontSettings', branchStorefrontSettings)
  .component('purchaseStorefrontDialog', purchaseStorefrontDialog)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main',
      stateName: 'businesses',
      stateKey: 'BUSINESSES',
      componentName: 'businessesDashboard',
      requiresPermission: 'BUSINESSES'
    })

    declareState($stateProvider, {
      parent: 'main.businesses',
      stateName: 'business',
      idParam: 'businessId',
      stateKey: 'BUSINESS',
      componentName: 'business'
    })

    declareState($stateProvider, {
      parent: 'main.businesses',
      stateName: 'create',
      stateKey: 'CREATE_BUSINESS',
      componentName: 'business',
      requiresPermission: 'BUSINESSES_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business',
      stateName: 'branches',
      redirectTo: 'main.businesses'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business',
      stateName: 'settings',
      stateKey: 'BUSINESS_SETTINGS',
      componentName: 'businessSettings'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business',
      stateName: 'storefront-settings',
      stateKey: 'BUSINESS_STOREFRONT_SETTINGS',
      componentName: 'businessStorefrontSettings'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business.branches',
      stateName: 'create',
      stateKey: 'CREATE_BRANCH',
      componentName: 'branch',
      requiresPermission: 'BRANCHES_CREATE'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business.branches',
      stateName: 'branch',
      idParam: 'branchId',
      stateKey: 'BRANCH',
      componentName: 'branch'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business.branches.branch',
      stateName: 'settings',
      stateKey: 'BRANCH_SETTINGS',
      componentName: 'branchSettings'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business.branches.branch',
      stateName: 'storefront-settings',
      stateKey: 'BRANCH_STOREFRONT_SETTINGS',
      componentName: 'branchStorefrontSettings'
    })

    declareState($stateProvider, {
      parent: 'main.businesses.business.branches.branch',
      stateName: 'cash-balance',
      stateKey: 'BRANCH_CASH_BALANCE',
      componentName: 'branchesBranchCashBalance'
    })
  })
)
