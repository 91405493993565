import templateUrl from './order-details.pug'
import BaseController from 'presentation/common/BaseController'
import * as PaymentMethods from 'common/constants/PaymentMethods'
import * as PaymentMethodsOptions from 'common/constants/PaymentMethodsSettings'

class OrderDetailsController extends BaseController {
  constructor ($scope) {
    super($scope)

    this.paymentMethods = PaymentMethodsOptions.ALL_PAYMENTS_OPTIONS
    this.localPosReference = ''

    $scope.$watch('$ctrl.localPosReference', posValue => {
      if (posValue) {
        this.handlePosReferenceChanged({value: this.localPosReference})
      }
    })

    $scope.$watch('$ctrl.paymentMethod', paymentMethodValue => {
      this.disableAmountControls = (paymentMethodValue === PaymentMethods.CASH_OFF_SYSTEM || paymentMethodValue === PaymentMethods.PAID_OFF_SYSTEM)
      if (this.disableAmountControls) {
        this.cost.amount = 0
      }
    })
  }

  onPOSBlur () {
    this.handlePosBlur()
  }
}

export default {
  templateUrl,
  controller: OrderDetailsController,
  bindings: {
    form: '=',
    cost: '=',
    paymentMethod: '=',
    posReference: '=',
    hideTitle: '<',
    disablePos: '=',
    isEditing: '<',
    handlePosReferenceChanged: '&',
    handlePosBlur: '&',
    availablePaymentMethods: '<'
  }
}
