import LogisticsSettings from 'data/domain-objects/LogisticsSettings'
import KanbanSettings from 'data/domain-objects/KanbanSettings'
import DeliveriesHistorySettings from 'data/domain-objects/DeliveriesHistorySettings'
import IssueTrackerSettings from 'data/domain-objects/IssueTrackerSettings'

export default class UiSettings {
  constructor (
    public logisticsSettings: LogisticsSettings,
    public kanbanSettings: KanbanSettings,
    public deliveriesHistorySettings: DeliveriesHistorySettings,
    public isSidebarExpanded: boolean,
    public issueTrackerSettings: IssueTrackerSettings
  ) {}

  static build (dto: any = {}): UiSettings {
    return new UiSettings(
      LogisticsSettings.build(dto.logisticsSettings),
      KanbanSettings.build(dto.kanbanSettings),
      DeliveriesHistorySettings.build(dto.deliveriesHistorySettings),
      !!dto.isSidebarExpanded,
      IssueTrackerSettings.build(dto.issueTrackerSettings)
    )
  }
}
