import clamp from 'lodash-es/clamp'
import debounce from 'lodash-es/debounce'
import Logger from 'common/Logger'

const DEFAULT_MAX_SUGGESTION_COUNT = 30
const MIN_CHARACTER_COUNT = 3
const TAB_KEYCODE = 9
const ENTER_KEY_CODE = 13
const ESCAPE_KEY_CODE = 27
const ARROW_UP_KEY_CODE = 38
const ARROW_DOWN_KEY_CODE = 40

const SUGGESTIONS_LIST_CLASS = '.c-autocomplete-input_suggestions'
const NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_SCROLLING = 8
const SUGGESTION_HEIGHT = 50
const KEYDOWN_DEBOUNCE_TIME = 300

const logger = new Logger('Auto-suggest Input')

/*
 * Fork of AUTOCOMPLETE INPUT tweaked to work with ID's
 */
class AutoSuggestInput {

  /* Bindings START */
  public name: string
  public form: ng.IFormController
  public selectedId: string
  public onChange: (data: {suggestion: ISuggestion}) => void
  public hostClass: string
  public isRequired: boolean
  public isDisabled: boolean
  public label: string
  public placeholder: string
  public hideOptional: boolean
  public backendValidationModel: any
  public suggestions: ISuggestion[]
  public maxSuggestionCount: number
  public minCharsLimit: number
  public showSuggestionsOnFocus: boolean
  public suggestionsCount: number
  public skipOutOfSuggestionsValidation: boolean
  /* Bindings END */

  public searchText: string = '' // Search text is only used to choose a suggestion object
  public defaultMaxSuggestionCount: number = DEFAULT_MAX_SUGGESTION_COUNT
  public minCharsCount: number
  public localSuggestions: ISuggestion[] = []
  public showSuggestions: boolean
  private isFocused: boolean = false
  public searchTextChangedBySuggestion: boolean = false // Latch used to detect suggestion being picked
  private focusedSuggestionIndex: number = null
  public debouncedKeyDownHandler: () => void
  public onSearchTextChanged: (data: {searchText: string}) => void
  public keepClear: boolean = false
  public randomString: string = String(Date.now())

  constructor (
    private $filter: ng.IFilterService,
    private $scope: ng.IScope
  ) {}

  $onInit (): void {
    this.buildPlaceholders()

    if (typeof this.showSuggestionsOnFocus === 'undefined') {
      this.showSuggestionsOnFocus = true
    }

    this.minCharsCount = (typeof this.minCharsLimit === 'number') ? this.minCharsLimit : MIN_CHARACTER_COUNT
    if (!this.suggestionsCount) {
      this.suggestionsCount = NUMBER_OF_ITEMS_TO_DISPLAY_WITHOUT_SCROLLING
    }

    this.debouncedKeyDownHandler = debounce(() => {
      this.onSearchTextChanged({searchText: this.searchText})

      if (this.selectedId && !this.searchText) { // If user just erased search text — clear model
        this.onClear()
      }
    }, KEYDOWN_DEBOUNCE_TIME)
  }

  $onChanges (changes: ng.IOnChangesObject): void {
    // Here the order of handlers is important!

    if (changes.suggestions) {
      if (!this.suggestions) {
        this.suggestions = []
      } else if (this.suggestions.length && (typeof this.suggestions[0] === 'string' || typeof this.suggestions[0] === 'number')) {
        // on this step we convert simple values to ISuggestion interface. So we make possible to work with array of simple types.
        // But keep in mind that the output will be in ISuggestion
        this.suggestions = this.suggestions.map((value: ISuggestion | number | string, index: number) => {
          return {id: index.toString(), name: value.toString()}
        })
      }
    }

    if (changes.selectedId) {
      // convert to string. Inside the component we only work with strings
      if (this.selectedId !== null && typeof this.selectedId !== 'undefined') {
        this.selectedId += ''
      }
      this.onSelectedIdChange(this.selectedId)
    }

    if (changes.suggestions && !changes.suggestions.isFirstChange()) {
      this.onSuggestionsChange()
    }

    this.setValidity()
  }

  public buildPlaceholders = (): void => {
    if (!this.isRequired && this.placeholder && !this.hideOptional) {
      this.placeholder += ' ' + this.$filter<ITranslateFilter>('translate')('COMMON.OPTIONAL_SUFFIX')
    }
    if (!this.isRequired && !this.placeholder && !this.hideOptional) {
      this.placeholder += this.$filter<ITranslateFilter>('translate')('COMMON.OPTIONAL')
    }
    if (!this.isRequired && !this.hideOptional) {
      this.label += ' ' + this.$filter<ITranslateFilter>('translate')('COMMON.OPTIONAL_SUFFIX')
    }
  }

  private onSuggestionsChange = (): void => {
    this.showSuggestions = this.suggestions.length && this.isFocused // Always show new suggestions if we have them
    this.localSuggestions = this.suggestions.slice(0)
    if (this.selectedId !== null) {
      this.searchByIdAndApplyModel(this.selectedId)
    }
  }

  private onSelectedIdChange = (selectedId: string): void => {
    if (selectedId === null || typeof selectedId === 'undefined') {
      this.searchText = ''
      this.searchTextChangedBySuggestion = true
    } else {
      this.searchByIdAndApplyModel(selectedId)
    }
  }

  private searchByIdAndApplyModel = (id: string): void => {
    if (id && this.suggestions) {
      const match: ISuggestion | undefined = this.suggestions.find((suggestion: ISuggestion) => suggestion.id === id)
      if (!match) {
        logger.warn('Can\'t find id', id, 'in suggestions', this.suggestions)
      } else {
        this.searchText = match.name
        this.searchTextChangedBySuggestion = true
        this.setUpSuggestionsList()
      }
    }
  }

  public onSuggestionAccepted = (suggestion: ISuggestion, event?: ng.IAngularEvent): void => {
    this.searchTextChangedBySuggestion = true
    this.showSuggestions = false
    this.searchText = this.keepClear ? '' : suggestion.name
    this.onChange({suggestion})
    this.setUpSuggestionsList()
  }

  public onInputChanged = (): void => {
    this.searchTextChangedBySuggestion = false
    this.showSuggestions = true
    this.setUpSuggestionsList()

    const match: ISuggestion | undefined = this.localSuggestions.find((suggestion: ISuggestion) => suggestion.name === this.searchText)
    if (match) {
      this.onChange({suggestion: match})
    }
    this.setValidity()
  }

  public onFocus = (): void => {
    this.setUpSuggestionsList()
    this.isFocused = true
    this.showSuggestions = true
    this.setValidity()
  }

  public onBlur = (): void => {
    this.isFocused = false
    this.showSuggestions = false

    this.focusedSuggestionIndex = null
    this.setValidity()
  }

  public onClear = (event?: ng.IAngularEvent): void => {
    this.resetSuggestionsList()
    this.searchText = ''
    if (this.form && this.form[this.name]) {
      this.form[this.name].$setTouched()
    }
    this.setValidity()
    this.onChange({suggestion: null})
    this.onSearchTextChanged({searchText: null})

    if (event) {
      event.preventDefault()
    } // this to prevent blur event on input
  }

  public setUpSuggestionsList = (): void => {
    this.localSuggestions = this.suggestions
      .filter((item: ISuggestion) => item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1).slice(0)
  }

  public resetSuggestionsList = (): void => {
    this.localSuggestions = this.suggestions.slice(0)
  }

  public setValidity = (): void => {

    // for some reasons form object not always has field named with `this.name`
    if (this.form && this.form[this.name]) {
      // if we skip out of suggestions validation we always set field as valid
      if (this.skipOutOfSuggestionsValidation) {
        this.form[this.name].$setValidity('valueNotInList', true)
      } else {
        const isItNotInTheList = !!this.localSuggestions.find((suggestion: ISuggestion) => suggestion.name.toLowerCase() === this.searchText.toLowerCase())
        this.form[this.name].$setValidity('valueNotInList', isItNotInTheList)
      }
    }
  }

  public onKeyDown ($event: KeyboardEvent): void {
    const keyCode: number = $event.keyCode

    let newFocusIndex: number
    if (keyCode === ARROW_UP_KEY_CODE) {
      newFocusIndex = this.getIncrementedFocusIndex(-1)
    } else if (keyCode === ARROW_DOWN_KEY_CODE) {
      newFocusIndex = this.getIncrementedFocusIndex(+1)
    } else if (keyCode === ENTER_KEY_CODE) {
      this.conditionallyAcceptCurrentSuggestion()
      $event.preventDefault()
      newFocusIndex = null
    } else if (keyCode === TAB_KEYCODE) {
      this.conditionallyAcceptCurrentSuggestion()
    } else if (keyCode === ESCAPE_KEY_CODE) {
      newFocusIndex = null
    } else {
      this.debouncedKeyDownHandler()
      this.searchTextChangedBySuggestion = false
    }

    const focusIndexChanged = typeof newFocusIndex !== 'undefined' && newFocusIndex !== this.focusedSuggestionIndex
    if (focusIndexChanged) {
      this.focusedSuggestionIndex = newFocusIndex
      this.showSuggestions = newFocusIndex !== null
      $event.preventDefault() // Only prevent the event if we actually did a change in response
    }

    const suggestionsList: Element = document.querySelector(SUGGESTIONS_LIST_CLASS)

    if (newFocusIndex > (this.suggestionsCount - 1) || keyCode === ARROW_UP_KEY_CODE) {
      if (suggestionsList) {
        suggestionsList.scrollTop = newFocusIndex * SUGGESTION_HEIGHT
      }
    }
  }

  getIncrementedFocusIndex = (increment: number): number | null => {
    if (this.focusedSuggestionIndex === null && increment > 0) {
      return 0 // Select first suggestion
    } else if (this.focusedSuggestionIndex === null && increment <= 0) {
      return null // Keep none selected
    } else if (this.focusedSuggestionIndex !== null) {
      const incrementedIndex = this.focusedSuggestionIndex + increment
      if (incrementedIndex === -1) {
        return null // Select none
      } else {
        return clamp(incrementedIndex, 0, this.getMaxSuggestionIndex()) // Select within available range
      }
    }
  }

  conditionallyAcceptCurrentSuggestion = (): void => {
    const suggestionForIndex: ISuggestion = this.localSuggestions[this.focusedSuggestionIndex]
    if (suggestionForIndex) {
      this.onSuggestionAccepted(suggestionForIndex)
    } else if (this.localSuggestions.length === 1) {
      this.onSuggestionAccepted(this.localSuggestions[0])
      this.showSuggestions = false
    }
  }

  focusSuggestionIndex = (rawIndex: number): void => {
    const maxIndex: number = this.getMaxSuggestionIndex()
    this.focusedSuggestionIndex = clamp(rawIndex, 0, maxIndex)
  }

  getMaxSuggestionIndex (): number {
    const maxSuggestionCount: number = this.maxSuggestionCount || this.defaultMaxSuggestionCount
    const shownSuggestionCount: number = Math.min(this.localSuggestions.length, maxSuggestionCount)
    return shownSuggestionCount ? shownSuggestionCount - 1 : 0 // Indices are zero-offset
  }
}

export default {
  templateUrl: require('./autosuggest-input.pug'),
  controller: AutoSuggestInput,
  bindings: {
    name: '@',

    // If you not pass this parameter no validation will be performed
    form: '<?',

    // this field always should be a string, even if you pass simple array as suggestions and index as selected-id
    selectedId: '<?',
    onChange: '&',
    onSearchTextChanged: '&',
    hostClass: '@?',
    isRequired: '<?',
    isDisabled: '<?',
    label: '@?',
    placeholder: '@?',

    // this parameter is for managing of display or not "(optional)" word in placeholder, which appears when isRequire is false
    hideOptional: '<?',
    backendValidationModel: '<?',

    // this property should be passed synchronously, otherwise component fails with exception.
    // Tip: pass empty array while generating actual suggestions
    suggestions: '<',

    // Suggestions count that will be displayed under input
    maxSuggestionCount: '<?',

    // Suggestions start to appear if input length more than or equals this value
    minCharsLimit: '<?',

    // true by default (see code)
    showSuggestionsOnFocus: '<?',
    suggestionsCount: '<?',

    // By passing this param with `true` value you force component to omit `out of the suggestions list` validation
    skipOutOfSuggestionsValidation: '<?',
    keepClear: '<?'
  }
}
