// this is one giant hack
// cause there's no BE yet for industries
const industriesList = [
  'Agriculture',
  'Accounting',
  'Advertising',
  'Aerospace',
  'Aircraft',
  'Airline',
  'Apparel & Accessories',
  'Automotive',
  'Banking',
  'Broadcasting',
  'Brokerage',
  'Biotechnology',
  'Call Centers',
  'Cargo Handling',
  'Chemical',
  'Computer',
  'Consulting',
  'Consumer Products',
  'Cosmetics',
  'Defense',
  'Department Stores',
  'Education',
  'Electronics',
  'Energy',
  'Entertainment & Leisure',
  'Executive Search',
  'Financial Services',
  'Food',
  'Beverage & Tobacco',
  'Grocery',
  'Health Care',
  'Internetand Technology',
  'Internet Publishing',
  'Investment Banking',
  'Legal',
  'Manufacturing',
  'Marketing and PR',
  'Motion Picture & Video',
  'Music',
  'Newspaper Publishers',
  'Online Auctions',
  'Pension Funds',
  'Pharmaceuticals',
  'Private Equity',
  'Publishing',
  'Real Estate',
  'Retail & Wholesale',
  'Securities & Commodity Exchanges',
  'Service',
  'Software',
  'Sports',
  'Technology',
  'Telecommunications',
  'Television',
  'Transportation',
  'Venture Capital',
  'Professional Services',
  'Recruitment',
  'Photography',
  'Charity',
  'Housing',
  'Restaurant'
]

export function IndustriesCollectionFactory () {
  function getIndustries () {
    // this will be moved to BE one day, so it should be promise
    return Promise.resolve(industriesList)
  }

  return {
    getIndustries
  }
}

export default IndustriesCollectionFactory()
