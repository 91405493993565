/**
 * This class is used for wrapping entities with id and name, where id is guid
 */

export default class DBMappedNamedEntity implements ISuggestion {
  public id: string
  public name: string

  constructor (
    id: string = '',
    name: string = ''
  ) {
    this.id = id
    this.name = name
  }

  static build (dto: any = {}): DBMappedNamedEntity {
    return new DBMappedNamedEntity(
      dto.id,
      dto.name
    )
  }
}
