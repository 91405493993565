import cloneDeep from 'lodash-es/cloneDeep'
import templateUrl from './account-dashboard.pug'
import BaseController from 'presentation/common/BaseController'
import userModel from 'data/models/userModel'

class AccountDashboardController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.user = {}
    this.sync()
  }

  onSync () {
    return userModel.getUser()
      .then(data => this.user = data)
      .catch(err => this.logger.error('Cannot get user', err))
  }

  onUserChanged (user) {
    this.user = cloneDeep(user)
  }
}

export default {
  templateUrl,
  controller: AccountDashboardController
}
