import DBMappedNamedEntity from './DBMappedNamedEntity'
import Cost from './Cost'

export default class BalanceAccount {
  constructor (
    public id: string,
    public branch: DBMappedNamedEntity,
    public lastBalance: Cost,
    public lastBalanceAt: Date,
    public ref: string
  ) {}

  static build(dto: any): BalanceAccount {
    return new BalanceAccount(
      dto.id || '',
      DBMappedNamedEntity.build(dto.branch || {}),
      Cost.build(dto.lastBalance || {}),
      new Date(dto.lastBalanceAt),
      dto.ref || ''
    )
  }
}
