import get from 'lodash-es/get'
import templateUrl from './requests.pug'
import envConfig from 'common/envConfig'
import requestsCollection from 'data/collections/requestsCollection'
import BaseController from 'presentation/common/BaseController'
import PaginationParameters from 'data/domain-objects/PaginationParameters'

const DEFAULT_REFRESH_REQUESTS_ACTION_REQUIRED_INTERVAL_MS = 2000
const REFRESH_REQUESTS_ACTION_REQUIRED_INTERVAL_MS = get(envConfig, 'requestsActionRequiredRefreshInterval', DEFAULT_REFRESH_REQUESTS_ACTION_REQUIRED_INTERVAL_MS)

class RequestsController extends BaseController {
  constructor ($scope, $interval) {
    super($scope)
    this.$interval = $interval

    this.requestsThatRequireAction = []
    this.paginationParams = new PaginationParameters()
    this.refreshIntervalRequestsThatRequireAction = $interval(() => this.getRequestsThatRequireAction(), REFRESH_REQUESTS_ACTION_REQUIRED_INTERVAL_MS, 0, false)

    this.getRequestsThatRequireAction()
  }

  onPaginationUpdated (offset, limit) {
    this.paginationParams.skip = offset
    this.paginationParams.limit = limit
    this.getRequestsThatRequireAction()
  }

  getRequestsThatRequireAction () {
    this.unconfirmedRequestsLoading = true
    return requestsCollection.getAllRequestsThatRequireAction(this.paginationParams.limit, this.paginationParams.skip)
      .then(response => {
        this.paginationParams = PaginationParameters.build(response.metadata)
        this.requestsThatRequireAction = response.records
      })
      .catch(error => this.logger.error('Error syncing requests that require action.', error))
      .then(() => {
        this.unconfirmedRequestsLoading = false
        this.$scope.$digest()
      })
  }

  $onDestroy () {
    if (this.refreshIntervalRequestsThatRequireAction) {
      this.$interval.cancel(this.refreshIntervalRequestsThatRequireAction)
      this.refreshIntervalRequestsThatRequireAction = null
    }
  }
}

export default {
  templateUrl,
  controller: RequestsController
}
