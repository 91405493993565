import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'
import { StateProvider } from '@uirouter/angularjs'

import vouchersDashboard from 'presentation/tools/vouchers/dashboard/vouchersDashboard'
import voucherForm from 'presentation/tools/vouchers/voucher-form/voucherForm'

export default declareAppModule('vouchers', (module: ng.IModule) => module
  .component('vouchersDashboard', vouchersDashboard)
  .component('voucherForm', voucherForm)
  .config(($stateProvider: StateProvider) => {

    declareState($stateProvider, {
      parent: 'main.tools',
      stateName: 'vouchers',
      componentName: 'vouchersDashboard',
      params: ['text', 'startDate', 'endDate'],
      stateKey: 'VOUCHERS',
      requiresPermission: 'VOUCHERS'
    })

    declareState($stateProvider, {
      parent: 'main.tools.vouchers',
      stateName: 'create',
      componentName: 'voucherForm',
      stateKey: 'VOUCHER_CREATE',
      requiresPermission: 'VOUCHERS'
    })

    declareState($stateProvider, {
      parent: 'main.tools.vouchers',
      stateName: 'voucher',
      idParam: 'voucherId',
      params: ['isEditing'],
      componentName: 'voucherForm',
      stateKey: 'VOUCHER_MANAGE',
      requiresPermission: 'VOUCHERS'
    })
  })
)
