const deliveryDialogIds = {
  KANBAN_POPUP_ID: 'KANBAN_POPUP_ID',
  DRIVER_OVERVIEW_ID: 'DRIVER_OVERVIEW_ID',
  DELIVERY_OVERVIEW_DIALOG_ID: 'DELIVERY_OVERVIEW_DIALOG_ID',
  RIDE_OVERVIEW_DIALOG_ID: 'RIDE_OVERVIEW_DIALOG_ID',
  PENDING_CONFIRMATION_DIALOG_ID: 'PENDING_CONFIRMATION_DIALOG_ID',
  REHAIL_CONFIRMATION_DIALOG_ID: 'REHAIL_CONFIRMATION_DIALOG_ID',
  CHANGE_DRIVER_DIALOG_ID: 'CHANGE_DRIVER_DIALOG_ID',
  CANCEL_CONFIRMATION_DIALOG_ID: 'CANCEL_DELIVERY_DIALOG_ID',
  DELIVERED_CONFIRMATION_DIALOG_ID: 'DELIVERY_DELIVERED_DIALOG_ID',
  COLLECTED_CONFIRMATION_DIALOG_ID: 'DELIVERY_COLLECTED_DIALOG_ID',
  BATCHING_DIALOG_ID: 'BATCHING_DIALOG_ID',
  TRANSFER_DELIVERIES_DIALOG_ID: 'TRANSFER_DELIVERIES_DIALOG_ID'
}

const addressDialogIds = {
  FIND_ADDRESS_DIALOG_ID: 'FIND_ADDRESS_DIALOG_ID'
}

export {
  deliveryDialogIds,
  addressDialogIds
}
