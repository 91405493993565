import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const brandedDomainsService = new CrudService(endpoints.BRANDED_DOMAINS)

function customDomainsCollectionFactory (brandedDomainsService) {
  return {
    getBrandedDomains: () => brandedDomainsService.get()
  }
}

export default customDomainsCollectionFactory(brandedDomainsService)
