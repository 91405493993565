import moment, { Moment } from 'moment'
import Cost from './Cost'
import cloneDeep from 'lodash-es/cloneDeep'
import config from './constants'

interface IDateRange {
  startDate: Moment,
  endDate: Moment
}

export default class Voucher {
  private dateFormat: string = config.dateOnlyDateFormat
  public dates: IDateRange = null
  public dateRangeDisplay: string = ''
  public validFrom: string = ''
  public validTo: string = ''

  constructor (
    public description: string,
    public limitUsed: number,
    public usageLimit: number,
    public voucherCode: string,
    public id: string,
    public createdBy: string,
    public creatorName: string,
    public tenant: string,
    public updatedAt: string,
    public createdAt: string,
    private amount: Cost,
    validFrom: Date,
    validTo: Date) {

    if (validFrom && validTo) {
      this.dates = {
        startDate: moment(validFrom),
        endDate: moment(validTo)
      }
      this.dateRangeDisplay = this.dates.startDate.format(this.dateFormat) + ' - ' + this.dates.endDate.format(this.dateFormat)
    }
  }

  set price (value: Cost) {
    this.amount = Cost.build(value)
  }

  get price (): Cost {
    return this.amount
  }

  get createdAtFormatted (): string {
    if (this.createdAt) {
      return moment(this.createdAt).format(config.defaultDateFormat)
    }
    return ''
  }

  get dateRange (): IDateRange {
    return this.dates
  }

  set dateRange (value: IDateRange) {
    this.dates = value
    if (value.startDate) {
      this.validFrom = moment(value.startDate).toISOString()
    }
    if (value.endDate) {
      this.validTo = moment(value.endDate).toISOString()
    }
  }

  static build (dto: any = {}): Voucher {
    return new Voucher(
      dto.description || '',
      dto.limitUsed || 0,
      dto.usageLimit || dto.limit || 0,
      dto.voucherCode || '',
      dto.id || null,
      dto.createdBy || null,
      dto.creatorName || null,
      dto.tenant || null,
      dto.updatedAt || null,
      dto.createdAt || null,
      dto.amount ? Cost.build(dto.amount) : Cost.build(),
      dto.validFrom,
      dto.validTo
    )
  }

  /**
   * Due to inconsistency between BE API and our widgets, we need
   * to rewrite Cost object
   */
  getProperModel (): any {
    const res: any = cloneDeep(this)
    res.amount = {
      value: res.amount.amount,
      currencyCode: res.amount.currencyCode
    }
    res.usageLimit = parseInt(res.usageLimit, 10)
    return res
  }
}
