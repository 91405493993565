export default {
  templateUrl: require('./radio.pug'),
  bindings: {
    name: '<',
    value: '<',
    model: '=',
    form: '<',
    isDisabled: '<?',
    onChange: '&?',
    label: '<?'
  }
}
