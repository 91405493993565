import templateUrl from './services-dashboard.pug'
import servicesCollection from 'data/collections/servicesCollection'
import BaseController from 'presentation/common/BaseController'

class ServicesDashboardController extends BaseController {
  constructor ($scope, $state, $filter) {
    super($scope, $state)
    this.$filter = $filter
    this.searchTerm = ''
    this.sync()
  }

  getVolume (packages) {
    if (packages.length) {
      return packages.map(pkg => `${pkg.amount} × ${pkg.name}`).join(', ')
    } else {
      return this.$filter('translate')('COMMON.NOT_AVAILABLE')
    }
  }

  search (searchTerm) {
    this.searchTerm = searchTerm
    this.sync()
  }

  onSync () {
    return servicesCollection.getAll(this.searchTerm).then(services => this.services = services)
  }

  deleteService (params) {
    return servicesCollection.delete(params.id)
      .catch(err => this.logger.error('Cannot delete service', err))
      .then(() => this.sync())
  }
}

export default {
  templateUrl,
  controller: ServicesDashboardController
}
