import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import templateUrl from './other-information.pug'
import BaseController from 'presentation/common/BaseController'
import branchesCollection from 'data/collections/branchesCollection'
import getValueFromChangeDescriptors from 'presentation/_utilities/getValueFromChangeDescriptors'

class OtherInformationController extends BaseController {
  constructor ($scope, $filter) {
    super($scope)

    this.$filter = $filter
    this.resetChargeAbleBalanceAccounts()
    this.resetVisibility()

    this.batchWithDeliveryId = null
    this.deliveryStatuses = envConfig.businessAndBranchSettings.deliveryStatusValues
  }

  resetVisibility () {
    this.showAllocationSettings = true
    this.showChargeDeliveryTo = true
    this.showBatchingOptions = true
  }

  resetChargeAbleBalanceAccounts () {
    this.chargeableBalanceAccountIds = []
    this.chargeableBalanceAccounts = {}
  }

  $onChanges (changeDescriptorsByKey) {
    const shownNoCreditError = getValueFromChangeDescriptors(changeDescriptorsByKey, 'gotNoCreditError', false)

    if (changeDescriptorsByKey.settings) {
      this.setVisibilityOfItems()
      this.getChargeToBranchBalances()
    }

    if (changeDescriptorsByKey.consumer || changeDescriptorsByKey.branch) {
      this.getChargeToBranchBalances()
    }

    if (changeDescriptorsByKey.gotNoCreditError && shownNoCreditError) {
      this.showChargeDeliveryTo = true
    }
  }

  setVisibilityOfItems () {
    const OPTION_HIDE = 'hide'

    this.resetVisibility()

    const showDeliveryChargeSetting = get(this.settings, 'showDeliveryCharge.value', null)
    if (showDeliveryChargeSetting === OPTION_HIDE) {
      this.showChargeDeliveryTo = false
    }

    this.batchingType = get(this.settings, 'batching.value', null)
    const showBatchingSetting = get(this.settings, 'showBatching.value', null)
    if (showBatchingSetting === OPTION_HIDE) {
      this.showBatchingOptions = false
    }

    const showAllocationSettings = get(this.settings, 'showDeliveryStatus.value', null)
    if (showAllocationSettings === OPTION_HIDE) {
      this.showAllocationSettings = false
    }
  }

  getChargeToBranchBalances () {
    this.balanceAccountsIds = []
    this.balanceAccountsIdsMap = {}
    this.disabledBalanceAccountsIdsMap = {}

    if (this.branch && !this.loadingBranchBalances) {
      this.loadingBranchBalances = true
      branchesCollection.getBalances(this.branch.businessId, this.branch.id)
        .then(balances => {
          const selectableBalances = []
          balances.forEach(balance => {
            const balanceId = balance.balanceAccountId
            const balanceName = balance.balanceAccountName || `balance - ${balanceId}`

            if (!this.balanceAccountsIdsMap.hasOwnProperty(balanceId)) {
              this.balanceAccountsIds.push(balanceId)
              this.balanceAccountsIdsMap[balanceId] = balanceName
              // here we mark balances without funds as disabled
              if (!balance.isEnoughFundsToCreateDelivery) {
                this.disabledBalanceAccountsIdsMap[balanceId] = true
                this.balanceAccountsIdsMap[balanceId] += ' ' + this.$filter('translate')('COMPONENTS.OTHER_INFORMATION.NOT_ENOUGH_FUNDS')
              } else {
                selectableBalances.push(balanceId)
              }
            }
          })

          // if only one balance is available, then we choose it by default
          if (selectableBalances.length === 1 || !this.showChargeDeliveryTo) {
            this.chargeDeliveryToBalanceAccountId = selectableBalances[0]
          } else if (!selectableBalances.length) {
            this.showChargeDeliveryTo = true
          }
        })
        .catch(err => this.logger.error('Cannot get branch balances', err))
        .then(() => {
          this.loadingBranchBalances = false
          this.$scope.$digest()
        })
    }
  }
}

export default {
  templateUrl,
  controller: OtherInformationController,
  bindings: {
    form: '=',
    chargeDeliveryToBalanceAccountId: '=',
    batchWithDeliveryId: '=',
    allowBatching: '=',
    branch: '<',
    consumer: '<',
    fleetId: '<',
    settings: '<',
    gotNoCreditError: '<',
    deliveryStatus: '<',
    onDeliveryStatusChange: '&'
  }
}
