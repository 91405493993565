import get from 'lodash-es/get'
import Cost from 'data/domain-objects/Cost'
import templateUrl from './driver-cash-account-history.pug'
import envConfig from 'common/envConfig'
import BaseController from 'presentation/common/BaseController'
import driverCashBalanceCollection from 'data/collections/driverCashBalanceCollection'
import driverCollection from 'data/collections/driverCollection'
import ExportUtils from 'common/utils/exportUtils'
import moment from 'moment'

const DRAEWIL_ID_COLUMN_NAME = 'draewilId'
const COLUMNS_CONFIGURATION = [
  {name: 'createdAt', title: 'DATE', sortable: true, sortKey: 'createdAt', icon: 'time'},
  {name: DRAEWIL_ID_COLUMN_NAME, title: 'DRAEWIL_ID'},
  {name: 'pos', title: 'POS'},
  {name: 'pickup', title: 'PICK_UP'},
  {name: 'dropoff', title: 'DROP_OFF'},
  {name: 'operation', title: 'OPERATION', sortable: true, sortKey: 'operation', icon: 'amount'},
  {name: 'balance', title: 'BALANCE', sortable: true, sortKey: 'balance', icon: 'charge'}
]

const MISSING_DATA = envConfig.reports.invalidData

class DriverCashAccountHistoryController extends BaseController {
  constructor ($scope, $filter, $stateParams) {
    super($scope)
    this.$filter = $filter

    this.driverId = $stateParams.driverId
    this.columns = COLUMNS_CONFIGURATION
    this.orderDirection = 'DESC'

    this.filters = {
      orderBy: 'createdAt',
      orderDirection: -1,
      limit: envConfig.pagination.defaultLimit,
      skip: 0
    }

    this.displayRows = []
    this.exportRows = []

    this.sync()

    this.$scope.$watch('$ctrl.filters', (oldVal, newVal) => this.onFiltersChange(oldVal, newVal), true)

    driverCollection.getWithCashBalance(this.driverId)
      .then(response => this.handleDriver(response))
      .catch(err => this.logger.error('Cannot get driver with cash balance', err))
  }

  onOffsetChange (offset, limit) {
    this.filters.skip = offset
    this.filters.limit = limit
  }

  exportHistoryToCSV () {
    const fileName = 'driver-cash-account-history_' + moment(Date.now()).format(envConfig.defaultDateFormat) + '.csv'
    const columnTitles = this.columns.map(column => {
      return this.$filter('translate')('PAGES.DRIVER_CASH_ACCOUNT_HISTORY.TABLE_HEADERS.' + column.title)
    })
    ExportUtils.exportCSV(fileName, this.exportRows, this.columns, columnTitles)
  }

  onFiltersChange (oldVal, newVal) {
    if (oldVal !== newVal) {
      this.sync()
    }
  }

  onSortDirectionChange (params) {
    this.filters.orderDirection = params.orderDirection === 'DESC' ? -1 : 1
  }

  onSync () {
    return driverCashBalanceCollection.get(this.driverId, this.filters)
      .then(response => this.processData(response))
      .catch(err => this.logger.error('Cannot get deliveries with senders and recipients', err))
  }

  handleDriver (driver) {
    this.driver = Object.assign(driver, {
      balance: get(driver, 'cashBalanceAccount.lastBalance', false) ? Cost.build(driver.cashBalanceAccount.lastBalance) : Cost.build()
    })
  }

  processData (response) {
    this.rowsTotalCount = response.metadata.count
    this.displayRows = []
    this.exportRows = []

    for (const row of response.records) {
      let displayRow = []
      let exportRow = []

      const date = (row.createdAt) ? this.$filter('date')(new Date(row.createdAt), envConfig.angularDefaultDateFormat) : MISSING_DATA
      const draewilId = (row.draewilId) ? `<a href="/#!/deliveries/overview/${row.deliveryId}">${row.draewilId}</a>` : MISSING_DATA

      displayRow = [
        date,
        draewilId,
        row.pos || MISSING_DATA,
        (row.sender.business ? `${row.sender.business} — ` : '') + (row.sender.name || ''),
        row.recipient.name || MISSING_DATA,
        Cost.build(row.operation ? row.operation : {}).getFormattedCost(),
        Cost.build(row.balance ? row.balance : {}).getFormattedCost()
      ]

      exportRow = displayRow.slice()
      const draewilIdColumnIndex = this.columns.findIndex(columnConfigRow => columnConfigRow.name === DRAEWIL_ID_COLUMN_NAME)
      exportRow[draewilIdColumnIndex] = (row[DRAEWIL_ID_COLUMN_NAME]) ? row[DRAEWIL_ID_COLUMN_NAME] : MISSING_DATA

      this.displayRows.push(displayRow)
      this.exportRows.push(exportRow)
    }
  }
}

export default {
  templateUrl,
  controller: DriverCashAccountHistoryController
}
