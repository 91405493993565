import templateUrl from './custom-domain-settings.pug'
import SettingsGroupController from 'presentation/businesses/common/SettingsGroupController'
import customDomainsCollection from 'data/collections/customDomainsCollection'

const SETTING_KEYS = [
  'consumerDomainName'
]

class CustomDomainsSettingsController extends SettingsGroupController {
  constructor ($scope, $state) {
    super($scope, $state, SETTING_KEYS)
  }

  $onInit () {
    super.$onInit()

    customDomainsCollection.getBrandedDomains()
      .then(domains => {
        this.domains = domains.map(d => d.name)
        this.$scope.$digest()
      })
      .catch(err => this.logger.error('Cannot get list of branded domains', err))
  }
}

export default {
  templateUrl,
  controller: CustomDomainsSettingsController,
  bindings: {
    form: '=',
    name: '@',
    model: '=',
    isEditing: '<'
  }
}
