import templateUrl from './user-menu.pug'
import localStorageService from 'data/services/localStorageService'
import userModel from 'data/models/userModel'
import Logger from 'common/Logger'

const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'
const logger = new Logger('UserMenu')

class UserMenu {
  constructor ($state, $scope) {
    this.$state = $state
    this.$scope = $scope
  }

  $onInit () {
    this.getUserData()
    userModel.onUserChanged(() => {
      this.getUserData().then(() => this.$scope.$digest())
    })
  }

  getUserData () {
    return userModel.getUser()
      .then(data => this.user = data)
      .catch(err => this.logger.error('Cannot get user', err))
  }

  account () {
    this.toggleMenu()
    this.$state.go('main.account')
  }

  logout () {
    userModel.logOut()
      .then(() => {
        logger.info('Logged out successfully, redirecting to login')
        localStorageService.removeValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
        this.$state.go('auth.login')
      })
      .catch(() => logger.error('Could not log out. Remaining in current state.'))
  }

  toggleMenu () {
    this.menuVisible = !this.menuVisible
  }
}

export default {
  templateUrl,
  controller: UserMenu,
  bindings: {
  }
}
