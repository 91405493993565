import DBMappedNamedEntity from 'data/domain-objects/DBMappedNamedEntity'

export function flattenTree(tree: any, fieldName: string): any[] {
  const extractChildItems = (itemsList: any[], currentItem: any): any[] => {
    const childItems = currentItem[fieldName].reduce((acc: any[], childItem: any): any[] => {
      acc.push(childItem)
      return extractChildItems(acc, childItem)
    }, [])
    return itemsList.concat(childItems)
  }

  const startItem = {}
  startItem[fieldName] = tree

  return extractChildItems([], startItem)
}

export function hasChildById(tree: any, fieldName: string, id: string): boolean {
  if (!tree[fieldName].length) {
    return false
  }

  return !!tree[fieldName].find((c: DBMappedNamedEntity): boolean => (c.id === id) || hasChildById(c, fieldName, id))
}
