import userModel from 'data/models/userModel'
import localStorageService from 'data/services/localStorageService'
import envConfig from 'common/envConfig'
import UiSettings from 'data/domain-objects/UiSettings'
import LogisticsSettings from 'data/domain-objects/LogisticsSettings'
import User from 'data/domain-objects/User'
import KanbanSettings from 'data/domain-objects/KanbanSettings'
import DeliveriesHistorySettings from 'data/domain-objects/DeliveriesHistorySettings'
import IssueTrackerSettings from 'data/domain-objects/IssueTrackerSettings'

const SETTINGS_KEYS = {
  KANBAN: 'kanbanSettings' as const,
  DELIVERIES_HISTORY: 'deliveriesHistorySettings' as const,
  LOGISTICS: 'logisticsSettings' as const,
  SIDEBAR_EXPANDED: 'isSidebarExpanded' as const,
  ISSUE_TRACKER: 'issueTrackerSettings' as const
}

const getUiSettingsForUser = (user: User): UiSettings => {
  let settings: UiSettings
  if (!user || !user.id) {
    return UiSettings.build()
  }

  const uiSettingsData = localStorageService.getValue(`${envConfig.localStorageUiSettingsPrefix}_${user.id}`)
  if (uiSettingsData) {
    try {
      const uiSettingsParsedData = JSON.parse(uiSettingsData)
      settings = UiSettings.build(uiSettingsParsedData)
    } catch (error) {
      // do nothing
    }
  }

  return settings || UiSettings.build()
}

const getSettingsForKey = <K extends keyof UiSettings>(key: K): UiSettings[K] => {
  return getUiSettingsForUser(userModel.getLocalUser())[key]
}

const setSettingsForKey = <K extends keyof UiSettings>(key: K, value: UiSettings[K]): void => {
  const user = userModel.getLocalUser()
  const settings = getUiSettingsForUser(user)
  settings[key] = value
  localStorageService.setValue(`${envConfig.localStorageUiSettingsPrefix}_${user.id}`, JSON.stringify(settings))
}

export class UiSettingsService {
  get kanbanSettings (): KanbanSettings {
    return getSettingsForKey(SETTINGS_KEYS.KANBAN)
  }
  set kanbanSettings (value: KanbanSettings) {
    setSettingsForKey(SETTINGS_KEYS.KANBAN, value)
  }

  get deliveriesHistorySettings (): DeliveriesHistorySettings {
    return getSettingsForKey(SETTINGS_KEYS.DELIVERIES_HISTORY)
  }
  set deliveriesHistorySettings (value: DeliveriesHistorySettings) {
    setSettingsForKey(SETTINGS_KEYS.DELIVERIES_HISTORY, value)
  }

  get logisticsSettings (): LogisticsSettings {
    return getSettingsForKey(SETTINGS_KEYS.LOGISTICS)
  }
  set logisticsSettings (value: LogisticsSettings) {
    if (!(value instanceof LogisticsSettings)) {
      throw new Error('Invalid value provided for logistics settings')
    }
    setSettingsForKey(SETTINGS_KEYS.LOGISTICS, value)
  }

  get isSidebarExpanded (): boolean {
    return getSettingsForKey(SETTINGS_KEYS.SIDEBAR_EXPANDED)
  }
  set isSidebarExpanded (value: boolean) {
    setSettingsForKey(SETTINGS_KEYS.SIDEBAR_EXPANDED, !!value)
  }

  get issueTrackerSettings (): IssueTrackerSettings {
    return getSettingsForKey(SETTINGS_KEYS.ISSUE_TRACKER)
  }
  set issueTrackerSettings (value: IssueTrackerSettings) {
    setSettingsForKey(SETTINGS_KEYS.ISSUE_TRACKER, value)
  }
}
const uiSettingsService = new UiSettingsService()
export default uiSettingsService
