import declareAppModule from 'presentation/_utilities/declareAppModule'
import { StateProvider, UrlRouterProvider } from '@uirouter/angularjs'
import { UIViewScrollProvider } from '@uirouter/angularjs/lib-esm/viewScroll'
import { ICompileProvider } from 'angular'
import layoutLoggedIn from './layout-logged-in/LayoutLoggedIn'
import layoutLoggedOut from './layout-logged-out/layoutLoggedOut'
import headerLoggedOut from './header-logged-out/headerLoggedOut'

import bindableTemplate from './bindable-template/bindableTemplate'
import fileModel from './file-model/fileModel'
import formatCostFilter from './format-cost/formatCost'
import ifFeatureEnabled from './if-feature-enabled/ifFeatureEnabled'
import ifHasPermission from './if-has-permission/ifHasPermission'
import languageAttributes from './language-attributes/languageAttributes'
import restrictedInputPattern from './restricted-input-pattern/restrictedInputPattern'
import specificTimeToFilter from './specific-time-to/specificTimeToFilter'
import timeElapsedFilter from './time-elapsed/timeElapsedFilter'
import timeToFilter from './time-to/timeToFilter'
import translateFilter from './translate/translateFilter'
import translateInnerText from './translate-inner-text/translateInnerText'
import qrCode from './qr-code/qrCode'
import formatPaymentCostFilter from './format-payment-cost/formatPaymentCost'

export default declareAppModule('core', (module: ng.IModule) => module
  .filter('formatCost', formatCostFilter)
  .filter('formatPaymentCost', formatPaymentCostFilter)
  .filter('timeElapsed', timeElapsedFilter)
  .filter('timeTo', timeToFilter)
  .filter('specificTimeTo', specificTimeToFilter)
  .filter('translate', translateFilter)
  .directive('bindableTemplate', bindableTemplate)
  .directive('fileModel', fileModel)
  .component('headerLoggedOut', headerLoggedOut)
  .directive('ifFeatureEnabled', ifFeatureEnabled)
  .directive('ifHasPermission', ifHasPermission)
  .directive('languageAttributes', languageAttributes)
  .directive('restrictedInputPattern', restrictedInputPattern)
  .directive('translateInnerText', translateInnerText)
  .component('layoutLoggedIn', layoutLoggedIn)
  .component('layoutLoggedOut', layoutLoggedOut)
  .component('qrCode', qrCode)
  .config((
    $urlRouterProvider: UrlRouterProvider,
    $stateProvider: StateProvider,
    $uiViewScrollProvider: UIViewScrollProvider
  ) => {

    $uiViewScrollProvider.useAnchorScroll()

    $stateProvider
      .state('main', {
        abstract: true,
        url: '',
        views: {
          draewil: {
            template: '<layout-logged-in></layout-logged-in>'
          }
        },
        data: {
          requiredIsAuthedValue: true,
          authRedirectState: 'auth.login'
        }
      })
      .state('auth', {
        abstract: true,
        url: '',
        views: {
          draewil: {
            template: '<layout-logged-out></layout-logged-out>'
          }
        },
        data: {
          requiredIsAuthedValue: false,
          authRedirectState: 'main.dashboard'
        }
      })

    // If no state can be matched to the URL, redirect to dashboard but keep any token
    $urlRouterProvider.otherwise(() => '/dashboard')
  })
  .config(($compileProvider: ICompileProvider) => $compileProvider.debugInfoEnabled(false))
)
