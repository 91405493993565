import templateUrl from './user-top-up.pug'
import Cost from 'data/domain-objects/Cost'
import envConfig from 'common/envConfig'
import windowLocation from 'common/windowLocation'
import paymentsCollection from 'data/collections/paymentsCollection'
import * as PaymentProviders from 'common/constants/BalanceTopUpPaymentMethods'
import BaseFormController from 'presentation/common/BaseFormController'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'

class UserTopUpController extends BaseFormController {
  constructor ($scope, $sce) {
    super($scope)

    this.$sce = $sce
    this.currencyCode = envConfig.defaultCurrencyCode
    this.presetCosts = []
    this.selectedPresetCost = null
    this.selectedPaymentMethod = null
    this.topUpCostAmount = '0'
    this.stripePaymentCards = []
    this.availablePaymentProviders = []
    this.getDecimalPointsCount = Cost.getDecimalPointsCountForCurrency
    this.stripeCardId = null
    this.paymentConfirmationDialogId = 'payment-confirmation-dialog'
  }

  $onInit () {
    this.$scope.$watch(() => this.selectedPresetCost, val => this.updateTopUpCostWithPresetCost(val))
    this.$scope.$watch(() => this.topUpCostAmount, val => this.setValidPresetCost(val))

    this.castPresetTopUpAmountsToCosts()
    this.setTopUpAmountToFirstPresetCost()
  }

  onPaymentMethodChange (paymentInfo) {
    this.selectedPaymentMethod = paymentInfo.selectedPaymentMethod
    this.stripeCardId = paymentInfo.stripeCardId
    setTimeout(() => this.$scope.$digest())
  }

  beforeSubmit () {
    if (!this.selectedPaymentMethod) {
      this.logger.error('No payment method selected')
      return Promise.resolve(null)
    }

    const topUpCost = Cost.build({
      amount: parseFloat(this.topUpCostAmount),
      currencyCode: envConfig.defaultCurrencyCode
    })

    let paymentPromise = paymentPromise = Promise.resolve(null)

    switch (this.selectedPaymentMethod) {
    case PaymentProviders.STRIPE:
      paymentPromise = paymentsCollection.createStripePayment(topUpCost, this.paymentProcessedPath, this.stripeCardId)
      break

    case PaymentProviders.TAP:
      paymentPromise = paymentsCollection.createTapPayment(topUpCost, this.paymentProcessedPath)
      break

    case PaymentProviders.KNET:
      paymentPromise = paymentsCollection.createKnetPayment(topUpCost, this.paymentProcessedPath)
      break

    case PaymentProviders.CYBERSOURCE:
      paymentPromise = paymentsCollection.createCybersourcePayment(topUpCost, this.paymentProcessedPath)
      break

    default:
      this.logger.error('Invalid payment method selected')
      break
    }

    return paymentPromise
      .then(response => this.handlePaymentSuccess(response))
      .catch(err => this.handleNewPaymentFailed(err))
  }

  updateTopUpCostWithPresetCost (newValue) {
    if (newValue && newValue.amount) {
      this.topUpCostAmount = newValue.getFormattedCost(true)
    }
  }

  setValidPresetCost (newTopCostAmount) {
    const validPreset = this.presetCosts.find(presetCost => {
      return presetCost.getFormattedCost(true) === newTopCostAmount
    })

    if (validPreset) {
      this.selectedPresetCost = validPreset
    } else {
      this.selectedPresetCost = null
    }
  }

  castPresetTopUpAmountsToCosts () {
    this.presetTopUpAmounts.forEach(presetTopUpAmount => {
      const cost = Cost.build({
        currencyCode: envConfig.defaultCurrencyCode,
        amount: presetTopUpAmount
      })
      this.presetCosts.push(cost)
    })
  }

  setTopUpAmountToFirstPresetCost () {
    if (this.presetCosts.length) {
      this.topUpCostAmount = this.presetCosts[0].amount
    }
  }

  topUpAmountIsValid () {
    return parseFloat(this.topUpCostAmount) > 0
  }

  openConfirmationDialog () {
    modalDialogStateService.emitOpenModalDialog({dialogId: this.paymentConfirmationDialogId})
  }

  submitCybersourceForm (response) {
    this.cybersourceAction = this.$sce.trustAsResourceUrl(response.action)
    this.cybesourceFields = []
    Object.keys(response.fields).forEach(field => {
      this.cybesourceFields.push({
        name: field,
        value: response.fields[field]
      })
    })
    this.$scope.$digest()
    document.querySelector('.cybersourceForm').submit()
  }

  handlePaymentSuccess (response) {
    if (this.selectedPaymentMethod === PaymentProviders.CYBERSOURCE) {
      this.submitCybersourceForm(response)
    } else if (response && response.redirectToUrl) {
      windowLocation.go(response.redirectToUrl)
    } else {
      this.handleNewPaymentFailed('No redirectToUrl found in payment response')
    }
  }
}

export default {
  templateUrl,
  controller: UserTopUpController,
  bindings: {
    handleNewPaymentFailed: '&',
    handleNewPaymentSuccess: '&',
    presetTopUpAmounts: '<',
    paymentProcessedPath: '<'
  }
}
