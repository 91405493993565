import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import templateUrl from './fleet-job-history.pug'
import Cost from 'data/domain-objects/Cost'
import fleetsCollection from 'data/collections/fleetsCollection'
import BalanceHistoryBaseController from 'presentation/common/BalanceHistoryBaseController'

const COLUMNS_CONFIGURATION = [
  {name: 'date', title: 'DATE', sortable: true, sortKey: 'createdAt', icon: 'time'},
  {name: 'draewilId', title: 'DRAEWIL_ID'},
  {name: 'pos', title: 'POS'},
  {name: 'pickup', title: 'PICK_UP'},
  {name: 'dropoff', title: 'DROP_OFF'},
  {name: 'driver', title: 'DRIVER'},
  {name: 'operation', title: 'OPERATION', sortable: true, sortKey: 'operation', icon: 'amount'},
  {name: 'balance', title: 'BALANCE', sortable: true, sortKey: 'balance', icon: 'charge'}
]

class FleetJobHistoryController extends BalanceHistoryBaseController {
  constructor ($scope, $state, $stateParams, $filter) {
    super($scope, $state, $filter)
    this.$stateParams = $stateParams
    this.columns = COLUMNS_CONFIGURATION
    this.sync()
  }

  onFiltersChange (oldVal, newVal) {
    // we need to reset to first page
    if (oldVal !== newVal && oldVal.driverId !== newVal.driverId || oldVal.status !== newVal.status) {
      this.filters.skip = 0
      this.currentPage = 1
    }

    this.sync()
  }

  onSync () {
    const filters = Object.assign({}, this.filters)
    if (!this.filtersInitialized) {
      filters.includeFilters = true
      this.filtersInitialized = true
    }

    return fleetsCollection.getHistory(this.$stateParams.fleetId, filters)
      .then(response => this.processData(response))
  }

  processMetaData (metadata) {
    this.rowsTotalCount = metadata.count

    const drivers = get(metadata, 'filter.drivers', [])
    if (drivers.length > 0) {
      this.driverFilterNames = {}
      this.driverFilterIds = []

      drivers.forEach(driver => {
        this.driverFilterIds.push(driver.id)
        this.driverFilterNames[driver.id] = driver.name
      })
    }
  }

  processData (response) {
    this.processMetaData(response.metadata)

    this.rows = response.records.map(row => {
      const businessName = get(row, 'sender.business', '')
      const senderName = get(row, 'sender.name') || ''
      const fullName = (businessName ? `${businessName} ` : '') + senderName
      const recipient = get(row, 'recipient.name', null)
      const link = row.deliveryId && row.type ? `<a href="/#!/deliveries/overview/${row.deliveryId}">${row.draewilId}</a>` : null

      return [
        this.$filter('date')(new Date(row.createdAt), envConfig.angularDefaultDateFormat),
        link,
        row.pos,
        fullName,
        recipient,
        row.driver,
        Cost.build(row.operation ? row.operation : {}).getFormattedCost(),
        Cost.build(row.balance ? row.balance : {}).getFormattedCost()
      ]
    })
  }
}

export default {
  templateUrl,
  controller: FleetJobHistoryController
}
