/* globals ga:true */
import authModel from 'data/models/authModel'
import userModel from 'data/models/userModel'
import permissionsModel from 'data/models/permissionsModel'
import localeHelper from 'common/localeHelper'
import {RejectType} from '@uirouter/angularjs'
import Logger from 'common/Logger'

const logger = new Logger('State Transition Manager')
const HASHBANG_REPLACEMENT_REGEXP = /^#!?/
let transitionInProgress = false

function stateTransitionManager ($transitions, $state) {
  /**
   * We listen to auth change event.
   * If auth breaks while no transitions are being made (for example on kanban screen during polling)
   * then we gonna redirect user to login
   */
  authModel.onIsAuthedChanged(isAuth => {
    if (!isAuth && !transitionInProgress) {
      $state.go('auth.login')
    }
  })

  /**
   * Checks if state requires permission and if user has that permissions
   * @param {Object} state
   * @returns {Boolean} true if user have permissions
   */
  const checkPermissions = (stateData = {}) => {
    const requiredPermissionKey = stateData.requiresPermission || ''
    return requiredPermissionKey ? permissionsModel.getHasPermission(requiredPermissionKey) : true
  }

  /**
   * Checks for Auth and required permissions before changing state
   * @param {Transition} trans
   */
  const stateChangeCheck = trans => {
    transitionInProgress = true
    const transitionToName = trans.$to().name
    const transitionData = trans.$to().data || {}
    const stateParams = trans.params()

    // handling special case when user is trying to login via Token
    if (stateParams.token) {
      return userModel.logInViaToken(stateParams.token)
    }

    // for any URL that's not related to Login/Out we need to check auth
    if (transitionToName.startsWith('main')) {
      // another weird case when we have redirectTo in data
      if (transitionData.redirectTo) {
        return trans.router.stateService.target(transitionData.redirectTo, stateParams)
      }

      // we need to get actual auth status
      return authModel.getIsAuthed()
        .then(isAuthenticated => {
          // if not logged in, then we save original path and redirect to login
          if (!isAuthenticated) {
            // we gonna preserve original path and redirect to it after logging in
            const destinationPath = trans.router.stateService.href(transitionToName, stateParams).replace(HASHBANG_REPLACEMENT_REGEXP, '')
            const redirectParams = {destination: encodeURIComponent(destinationPath)}

            return trans.router.stateService.target('auth.login', redirectParams)
          }

          // if user is logged in we need to check if he have enough permissions
          if (!checkPermissions(transitionData)) {
            // if no permissions, then redirect to dashboard
            return trans.router.stateService.target('main.dashboard')
          }
          // all is good, transition allowed
        })
    }
  }

  $transitions.onBefore({to: '*.**'}, stateChangeCheck)

  // Send stats once we finish transition
  $transitions.onFinish({to: '*.**'}, trans => {
    const pageTitle = trans.$to().self.pageTitle || null
    transitionInProgress = false

    if (pageTitle) {
      document.title = localeHelper.lookupTranslationKey(pageTitle)
    }

    const name = window.location.hash.replace(HASHBANG_REPLACEMENT_REGEXP, '')
    const userRole = userModel.getUserRole()

    if (userRole) {
      ga('set', 'dimension1', userRole.name)
    }
    ga('set', 'page', name)
    ga('send', 'pageview')
  })

  $transitions.onError({to: '*.**'}, trans => {
    const err = trans.error()
    switch (err.type) {
    case RejectType.ERROR:
      logger.error('Coulnd\'t change state ', err)
      break
    case RejectType.SUPERSEDED:
    case RejectType.IGNORED:
      // ignore
      break
    }
  })
}

export default stateTransitionManager
