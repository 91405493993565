import templateUrl from './no-fleets-panel.pug'
import BaseController from 'presentation/common/BaseController'
import fleetsCollection from 'data/collections/fleetsCollection'

class NoFleetPanelController extends BaseController {
  constructor ($scope) {
    super($scope)
    this.sync()
  }

  onSync () {
    return fleetsCollection.getAll()
      .then(fleets => this.fleets = fleets)
      .catch(err => this.logger.error('Cannot get all fleets for current user', err))
  }
}

export default {
  templateUrl,
  controller: NoFleetPanelController
}
