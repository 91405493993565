const MILLISECONDS_PER_MINUTE = 60000
const REQUESTED_TIME_TYPES = {
  ASAP: 'ASAP',
  AFTER_DELAY_MINUTES: 'AFTER_DELAY_MINUTES',
  AT_DATE_TIME: 'AT_DATE_TIME'
}

export default class RequestedTime {
  public asap: boolean = false
  public atDateTime: Date = null
  public timeSlot: number = null
  public afterDelayMinutes: number = null

  constructor (isNextDay: boolean = false) {
    this.clearValues()
    this.setAsap(!isNextDay) // ASAP is OFF for NDD and ON for normal deliveries
  }

  clearValues (): void {
    this.asap = false
    this.afterDelayMinutes = null
    this.atDateTime = null
    this.timeSlot = null
  }

  setTimeSlot (timestamp: string): void {
    this.timeSlot = Number(timestamp)
  }

  setAsap (isAsap: boolean): void {
    this.clearValues()
    this.asap = isAsap
  }

  setAfterDelayMinutes (delayMinutes: string): void {
    this.clearValues()
    this.afterDelayMinutes = parseInt(delayMinutes)
  }

  setAtDateTime (dateTime: Date): void {
    this.clearValues()
    this.atDateTime = dateTime
  }

  getType (): string {
    if (this.asap) {
      return REQUESTED_TIME_TYPES.ASAP
    } else if (this.afterDelayMinutes) {
      return REQUESTED_TIME_TYPES.AFTER_DELAY_MINUTES
    } else if (this.atDateTime) {
      return REQUESTED_TIME_TYPES.AT_DATE_TIME
    } else {
      return null
    }
  }

  toISOStringGivenNow (currentDateTime: Date): string { // Keep function pure by requiring current date time
    if (this.timeSlot !== null) {
      return new Date(this.timeSlot).toISOString()
    } else if (this.asap) {
      return currentDateTime.toISOString()
    } else if (this.afterDelayMinutes) {
      const timestamp = currentDateTime.valueOf()
      const timeStampOffset = this.afterDelayMinutes * MILLISECONDS_PER_MINUTE
      return new Date(timestamp + timeStampOffset).toISOString()
    } else if (this.atDateTime) {
      return this.atDateTime.toISOString()
    } else { // Fall back to asap behaviour by default
      return currentDateTime.toISOString()
    }
  }

  static get TYPES (): any {
    return REQUESTED_TIME_TYPES
  }
}
