import templateUrl from './progress-wizard.pug'

export default {
  templateUrl,
  bindings: {
    routeSteps: '=',
    currentStepIndex: '=',
    allStepsComplete: '='
  }
}
