import templateUrl from './card-picker.pug'

export default {
  templateUrl,
  transclude: true,
  bindings: {
    form: '=',
    model: '=',
    isDisabled: '<',
    isRequired: '<'
  }
}
