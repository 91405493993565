import BaseController from 'presentation/common/BaseController'
import { StateService } from '@uirouter/core'

class ScheduledDeliveryController extends BaseController {
  public isExpanded: boolean = false
  public delivery: any = null

  constructor (
    $scope: ng.IScope,
    $state: StateService
  ) {
    super($scope, $state)
  }

  toggleExpand (): void {
    this.isExpanded = !this.isExpanded
  }
}

export default {
  templateUrl: require('./scheduled-delivery.pug'),
  controller: ScheduledDeliveryController,
  bindings: {
    delivery: '<',
    isExpanded: '<?',
    hostClass: '@?'
  }
}
