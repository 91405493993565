export default class ValidationUtils {

  static resetBackendValidationForChanged (validationErrorCodesByField: any, newObject: any, oldObject: any): void {
    const backendInvalidFieldNames = Object.keys(validationErrorCodesByField)
    const fieldNamesToResetValidation = []
    backendInvalidFieldNames.forEach((invalidFieldName: string) => {
      if (newObject[invalidFieldName] !== oldObject[invalidFieldName]) {
        fieldNamesToResetValidation.push(invalidFieldName)
      }
    })
    fieldNamesToResetValidation.forEach((fieldNameToReset: string) => {
      delete validationErrorCodesByField[fieldNameToReset]
    })
  }

  static getValidationErrorsByField (errorDescriptorArraysByFieldName: any, lookupFieldNameInMap?: any): any {
    const validationErrorCodesByField = {}
    const fieldNames: string[] = Object.keys(errorDescriptorArraysByFieldName)

    fieldNames.forEach((fieldName: string) => {
      const errorDescriptorArray = errorDescriptorArraysByFieldName[fieldName]
      if (Array.isArray(errorDescriptorArray) && errorDescriptorArray.length > 0) {
        let mappedFieldName = fieldName

        if (lookupFieldNameInMap) {
          mappedFieldName = lookupFieldNameInMap(fieldName)
        }

        if (mappedFieldName) {
          validationErrorCodesByField[mappedFieldName] = errorDescriptorArray[0].errorCode // Only use the first error code
        }
      }
    })

    return validationErrorCodesByField
  }

  static getIsEntityChanged (editedEntity: any, previousEntity: any): boolean {
    return JSON.stringify(editedEntity) !== JSON.stringify(previousEntity)
  }
}
