import envConfig from 'common/envConfig'
import BaseFormController from 'presentation/common/BaseFormController'
import ridesCollection from '../ridesCollection'
import fleetsCollection from 'data/collections/fleetsCollection'
import consumerCollection from 'data/collections/consumerCollection'
import Ride from 'data/domain-objects/Ride'
import Address from 'data/domain-objects/Address'
import { StateService } from '@uirouter/core'
import Consumer from 'data/domain-objects/Consumer'
import { AvailableFleet } from 'data/domain-objects/Fleet'
import RequestedTime from 'data/domain-objects/RequestedTime'
import * as RideTypes from 'common/constants/RideTypes'
import debounce from 'lodash-es/debounce'

interface ISyncFleetParams {
  pickupAddressId: string,
  dropOffAddressId: string,
  pickupTime?: string,
  numberOfPassengers: number,
  promoCodeName?: string,
  customerId: string
}
const ERR_NOT_ENOUGH_DATA = 'ERR_NOT_ENOUGH_DATA'
const ERR_DATE_IN_THE_PAST = 'ERR_DATE_IN_THE_PAST'
const ERR_NOT_ENOUGH_BALANCE = 'ERR_NOT_ENOUGH_BALANCE'
const ERR_AREA_NOT_SERVED = 'ERR_AREA_NOT_SERVED'
const ERR_PICKUPTIME_FAR_AWAY = 'ERR_PICKUPTIME_FAR_AWAY'
const ERR_NO_AVAILABLE_FLEETS = 'ERR_NO_AVAILABLE_FLEETS'

const WARNING_ICONS = {
  ERR_NOT_ENOUGH_DATA: 'icon_no_fleets_available.png',
  ERR_DATE_IN_THE_PAST: 'sad-panda.svg',
  ERR_NOT_ENOUGH_BALANCE: 'icon_insufficient_funds.png',
  ERR_AREA_NOT_SERVED: 'sad-panda.svg',
  ERR_PICKUPTIME_FAR_AWAY: 'sad-panda.svg',
  ERR_NO_AVAILABLE_FLEETS: 'icon_no_fleets_available.png'
}

class RideCreateController extends BaseFormController {
  public paymentMethods: string[] = envConfig.ridePaymentMethods || []
  public consumer: Consumer = null
  public requestedPickUpTime: RequestedTime = new RequestedTime()
  public fleets: AvailableFleet[]
  public fleetsChecked: boolean = false
  public ride: Ride = Ride.build({})
  public newConsumerPhoneNumber: string = ''

  public errorsList: string[] = []

  public consumerSelectMode: boolean = true
  public pickupAddressTypes: string[] = []
  public dropoffAddressTypes: string[] = []
  public selectedDropoffAddressType: string
  public selectedPickupAddressId: string
  public selectedDropoffAddressId: string
  public selectedPickupAddressType: string = envConfig.addressTypes.consumer
  public onConsumerCreated: (consumer: Consumer) => void
  public pickupWidgetType: string
  public dropoffWidgetType: string

  private selectedBusinessBalanceAccountId: string = null
  public phoneNumber: string = ''
  public countryDiallingCode: string = ''

  public rideTypesList: string[] = [ RideTypes.B2B, RideTypes.C2B, RideTypes.B2C, RideTypes.C2C ]
  public rideType: string

  public fleetEtaPricePrefix: string = ''

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    private $filter: ng.IFilterService
  ) {
    super($scope, $state)
  }

  resetConsumer(): void {
    this.consumer = null
    this.consumerSelectMode = true
    this.ride.consumerFirstName = ''
    this.ride.consumerLastName = ''
  }

  buildConsumerAddresses(): void {
    this.consumer.addresses = this.consumer.addresses.map((addressDTO: object) => Address.build(addressDTO))
  }

  onConsumerFound (consumer: Consumer): void {
    this.consumer = Consumer.build(consumer)
    this.consumerSelectMode = false
    this.buildConsumerAddresses()
    this.calculatePickupAddressTypes()
    this.calculateDropoffAddressTypes()
    if (consumer.firstName) {
      this.ride.consumerFirstName = consumer.firstName
    }
    if (consumer.lastName) {
      this.ride.consumerLastName = consumer.lastName
    }
    setTimeout(() => {
      const el: HTMLInputElement = document.querySelector('.js-selected-consumer--first-name input')
      el.focus()
    })
    this.$scope.$digest()
  }

  switchRideType(rideType: string): void {
    this.rideType = rideType
    this.ride.balanceAccount = ''
    this.ride.fleetId = null
    this.ride.pickupTime = ''
    this.ride.paymentMethod = this.paymentMethods[0]
    this.ride.numberOfPassengers = 1
    this.ride.pickupAddressId = null
    this.ride.dropOffAddressId = null

    this.resetFleets()

    this.selectedDropoffAddressType = (this.rideType === RideTypes.C2C || this.rideType === RideTypes.B2C)
      ? envConfig.addressTypes.consumer
      : envConfig.addressTypes.business

    // this HACK will force pickup/dropoff widgets to fully reinitialize and
    // and fire appropriate events
    this.pickupWidgetType = 'fake'
    this.dropoffWidgetType = 'fake'
    setTimeout(() => {
      this.calculatePickupAddressTypes()
      this.calculateDropoffAddressTypes()
      this.$scope.$digest()
    })
  }

  $onInit (): void {
    this.switchRideType(RideTypes.C2C)
    this.onConsumerCreated = (newConsumer: Consumer): void => {
      consumerCollection.get(newConsumer.id, true)
        .then((consumer: Consumer) => this.onConsumerFound(consumer))
    }

    const debouncedUpdateAvailableFleets = debounce(() => this.getAvailableFleets(), 300)
    this.$scope.$watch('$ctrl.ride.promoCode', debouncedUpdateAvailableFleets)
  }

  calculatePickupAddressTypes(): void {
    if (this.rideType === RideTypes.B2B || this.rideType === RideTypes.B2C) {
      this.dropoffAddressTypes = [envConfig.addressTypes.business]
      this.pickupWidgetType = RideTypes.B2B
    } else {
      this.dropoffAddressTypes = [envConfig.addressTypes.consumer, envConfig.addressTypes.notKnown]
      this.pickupWidgetType = RideTypes.C2C
    }
  }

  calculateDropoffAddressTypes(): void {
    if (this.rideType === RideTypes.C2C || this.rideType === RideTypes.B2C) {
      this.dropoffAddressTypes = [envConfig.addressTypes.consumer, envConfig.addressTypes.notKnown]
      this.dropoffWidgetType = RideTypes.C2C
    } else {
      this.dropoffAddressTypes = [envConfig.addressTypes.business]
      this.dropoffWidgetType = RideTypes.B2B
    }
  }

  $postLink(): void {
    this.$scope.$watchGroup([
      '$ctrl.ride.pickupAddressId',
      '$ctrl.ride.dropOffAddressId',
      '$ctrl.requestedPickUpTime'
    ], () => this.getAvailableFleets())
  }

  onPickupAddressCreated(consumer: Consumer): void {
    this.onAddressCreated(consumer)
    this.selectedPickupAddressId = this.consumer.addresses[ this.consumer.addresses.length - 1].id
    this.onPickupAddressChanged(this.selectedPickupAddressId)
  }

  onDropoffAddressCreated(consumer: Consumer): void {
    this.onAddressCreated(consumer)
    this.selectedDropoffAddressId = this.consumer.addresses[ this.consumer.addresses.length - 1].id
    this.onDropoffAddressChanged(this.selectedDropoffAddressId)
  }

  onAddressCreated(consumer: Consumer): void {
    this.consumer = Consumer.build(consumer)
    this.buildConsumerAddresses()
  }

  onPaymentMethodChange(paymentMethod: string): void {
    this.toggleError(ERR_NOT_ENOUGH_BALANCE)
    this.ride.paymentMethod = paymentMethod
  }

  onPickupAddressChanged(addressId: string): void {
    this.ride.pickupAddressId = addressId
    this.calculateDropoffAddressTypes()
  }

  onDropoffAddressChanged (addressId: string): void {
    this.ride.dropOffAddressId = addressId
  }

  handleRequestedPickupTimeChanged (rpt: RequestedTime): void {
    this.toggleError(ERR_DATE_IN_THE_PAST)
    this.toggleError(ERR_PICKUPTIME_FAR_AWAY)
    this.requestedPickUpTime = rpt
  }

  handleFleetIdChanged (fleetId: string): void {
    this.ride.fleetId = fleetId
  }

  onDropOffAddressTypeChanged (addressType: string): void {
    this.selectedDropoffAddressType = addressType
    if (this.selectedDropoffAddressType === envConfig.addressTypes.notKnown
      || this.selectedDropoffAddressType !== envConfig.addressTypes.notKnown && !!this.ride.dropOffAddressId) {
      this.ride.dropOffAddressId = null
    }
    this.getAvailableFleets()
  }

  onPickupBranchSelected(branchData: any ): void {
    this.ride.pickupAddressId = branchData.addressId
    this.calculateDropoffAddressTypes()
  }

  onDropoffBranchSelected(branchData: any ): void {
    this.ride.dropOffAddressId = branchData.addressId
    this.calculateDropoffAddressTypes()
  }

  onBalanceIdChange(balanceId: string): void {
    this.selectedBusinessBalanceAccountId = balanceId
  }

  onBalanceBranchChange(branchData: any): void {
    this.ride.originatingBranchId = branchData.branchId
  }

  getAvailableFleets (): void {
    if (this.isLoading || !this.consumer || !this.ride.pickupAddressId) {
      return
    }

    let isValidDropoffAddress = false

    // two conditions separated for easier code reading
    if ((this.rideType === RideTypes.C2C || this.rideType === RideTypes.B2C) // any to consumer
        && ( // either free ride, or valid consumer address
          this.selectedDropoffAddressType === envConfig.addressTypes.notKnown ||
          this.selectedDropoffAddressType === envConfig.addressTypes.consumer && this.ride.dropOffAddressId
        )
    ) {
      isValidDropoffAddress = true
    }

    if ((this.rideType === RideTypes.C2B || this.rideType === RideTypes.B2B) // any to business
      && !!this.ride.dropOffAddressId // valid business address
      && this.ride.dropOffAddressId !== this.ride.pickupAddressId // we are not mad
    ) {
      isValidDropoffAddress = true
    }

    if (isValidDropoffAddress) {

      this.fleetEtaPricePrefix = (!this.ride.dropOffAddressId)
      ? this.$filter<ITranslateFilter>('translate')('COMPONENTS.RIDE.RIDE_CREATE.FLEET_ETA_PRICE_PREFIX')
      : ''

      this.isLoading = true
      this.resetFleets()

      const params: ISyncFleetParams = {
        pickupAddressId: this.ride.pickupAddressId,
        dropOffAddressId: this.ride.dropOffAddressId,
        numberOfPassengers: this.ride.numberOfPassengers,
        customerId: this.consumer.id
      }

      if (!this.requestedPickUpTime.asap) {
        params.pickupTime = this.requestedPickUpTime.toISOStringGivenNow(new Date())
      }

      if (this.ride.promoCode) {
        params.promoCodeName = this.ride.promoCode
      }

      this.toggleError(ERR_NOT_ENOUGH_DATA, false)
      this.syncFleets(params).then(() => {
        this.isLoading = false
        this.$scope.$digest()
      })
    } else {
      this.toggleError(ERR_NOT_ENOUGH_DATA, true)
      this.handleFleetIdChanged(null)
    }
  }

  getWarningData(errorKey: string): any {
    const i18nKey = errorKey.replace('ERR_', '')
    return {
      icon: `/assets/images/${WARNING_ICONS[ errorKey ]}`,
      title: this.$filter<ITranslateFilter>('translate')(`COMPONENTS.RIDE.RIDE_CREATE.${i18nKey}.TITLE`),
      description: this.$filter<ITranslateFilter>('translate')(`COMPONENTS.RIDE.RIDE_CREATE.${i18nKey}.DESCRIPTION`)
    }
  }

  toggleError(errorKey: string, isError: boolean = false): void {
    const knownError = this.errorsList.includes(errorKey)

    if (isError && !knownError) {
      this.errorsList.push(errorKey)
    } else if (knownError) {
      this.errorsList.splice( this.errorsList.indexOf(errorKey), 1)
    }
  }

  syncFleets (params: ISyncFleetParams): Promise<void> {
    return fleetsCollection.getAvailableRideFleets(params)
      .then((fleets: AvailableFleet[]) => {
        this.fleets = fleets
        this.ride.fleetId = (fleets.length) ? fleets[0].id : null
        this.toggleError(ERR_NO_AVAILABLE_FLEETS, !this.fleets.length)

        this.fleetsChecked = true
      })
      .catch((error: Error) => {
        this.logger.error('Could not retrieve available fleets given params.', params, error)
        this.fleets = []
        this.ride.fleetId = null
        this.toggleError(ERR_NO_AVAILABLE_FLEETS, !this.fleets.length)
      })
  }

  resetFleets (): void {
    this.fleets = []
    this.ride.fleetId = null
    this.toggleError(ERR_NO_AVAILABLE_FLEETS)
  }

  beforeSubmit (): Promise<Ride> {
    this.toggleError(ERR_PICKUPTIME_FAR_AWAY)

    this.ride.balanceAccount = this.ride.paymentMethod === 'cash' ? this.consumer.balanceAccountId : this.selectedBusinessBalanceAccountId
    this.ride.pickupTime = this.requestedPickUpTime.toISOStringGivenNow(new Date())
    if (this.requestedPickUpTime.asap) {
      this.ride.pickupTime = null
    }
    this.ride.consumerId = this.consumer.id
    return ridesCollection.create(this.ride)
  }

  afterSubmitSuccess (rideData: Ride): Promise<void> {
    this.notifySubmitSuccess()
    return Promise.resolve()
      .then(() => {
        this.$state.go('main.ride-confirmation', {
          rideId: rideData.id
        })
      })
  }

  afterSubmitFailure (error: Error): Promise<void> {
    this.logger.warn('Could not create ride', error)
    this.toggleError(ERR_NOT_ENOUGH_BALANCE, error && error.message === 'Insufficient funds')
    this.toggleError(ERR_DATE_IN_THE_PAST, error && error.message === 'Pickup time can not be in the past')
    this.toggleError(ERR_AREA_NOT_SERVED, error && error.message === 'Sender address is outside of served area')
    this.toggleError(ERR_PICKUPTIME_FAR_AWAY, error && error.message.startsWith('Pickup time cannot be later than'))
    return Promise.resolve()
  }
}

export default {
  templateUrl: require('./ride-create.pug'),
  controller: RideCreateController
}
