import templateUrl from './linked-deliveries-time-slot.pug'
import NextDayDeliveryTimeSlot from 'data/domain-objects/NextDayDeliveryTimeSlot'

class LinkedDeliveriesTimeSlot {
  constructor () {
    this.isExpanded = false
  }

  $onChanges (changesDescriptor) {
    if (changesDescriptor.timeSlot) {
      this.timeSlot = NextDayDeliveryTimeSlot.buildFromDate(this.timeSlot)
    }
  }

  toggleExpand () {
    this.isExpanded = !this.isExpanded
  }

  onCheckboxClick (value) {
    if (value) {
      this.onCheckAll()
    } else {
      this.onUncheckAll()
    }
  }

  uncheckAll ($event) {
    this.onUncheckAll()
    $event.stopPropagation()
  }
}

export default {
  templateUrl,
  controller: LinkedDeliveriesTimeSlot,
  transclude: true,
  bindings: {
    timeSlot: '<',
    checkState: '<',
    deliveriesCount: '<',
    selectedDeliveriesCount: '<',
    onCheckAll: '&',
    onUncheckAll: '&'
  }
}
