import envConfig from 'common/envConfig'
import Cost from 'data/domain-objects/Cost'
import templateUrl from './driver-cash-account.pug'
import driverModel from 'data/models/driverModel'
import driverCollection from 'data/collections/driverCollection'
import BaseFormController from 'presentation/common/BaseFormController'
import {convertObjectToMetadataArray} from 'data/common/transactionMetadataConverter'

const DEFAULT_CASH_LIMIT_OBJECT = Cost.build({
  amount: envConfig.defaultDriverCashBalanceLimit,
  currencyCode: envConfig.defaultCurrencyCode
})

class driverCashAccountController extends BaseFormController {
  constructor ($scope, $state, $stateParams) {
    super($scope, $state)
    this.$stateParams = $stateParams
    this.balanceChange = Cost.build()

    this.driver = {
      balance: Cost.build(),
      cashLimit: DEFAULT_CASH_LIMIT_OBJECT
    }
    this.driverId = $stateParams.driverId

    driverCollection.get(this.driverId)
      .then(driver => this.handleDriver(driver))
      .catch(error => this.logger.error('couldn\'t get driver', error))
      .then(() => this.$scope.$digest())
  }

  handleDriver (driver) {
    this.driver = Object.assign({}, driver, {
      balance: driver.balance ? Cost.build(driver.balance) : Cost.build({amount: 0}),
      cashLimit: (driver.cashLimit && driver.cashLimit.currencyCode) ? Cost.build(driver.cashLimit) : DEFAULT_CASH_LIMIT_OBJECT
    })

    if (!driver.balance) {
      this.forceGetDriverBalance()
    } else {
      this.updateModelCurrency(driver.balance.currencyCode)
    }
  }

  forceGetDriverBalance () {
    driverCollection.getCash(this.driverId)
      .then(driverBalance => this.handleDriverBalance(driverBalance))
      .catch(error => this.logger.error('couldn\'t get driver\'s balance', error))
      .then(() => this.$scope.$digest())
  }

  handleDriverBalance (driverBalance) {
    if (driverBalance.lastBalance) {
      this.driver.balance = Cost.build(driverBalance.lastBalance)
      this.updateModelCurrency(driverBalance.lastBalance.currencyCode)
    }
  }

  updateModelCurrency (currencyCode = envConfig.defaultCurrencyCode) {
    Object.assign(this.balanceChange, {currencyCode})
    Object.assign(this.driver.cashLimit, {currencyCode})
  }

  beforeSubmit () {
    const model = {
      amount: {
        value: this.balanceChange.amount,
        currencyCode: this.balanceChange.currencyCode
      },
      metadata: convertObjectToMetadataArray({
        draewilId: null,
        pickUp: null,
        dropOff: this.driver.id,
        operation: this.balanceChange.amount,
        balance: this.driver.balance.amount + this.balanceChange.amount,
        byManager: true
      })
    }

    this.driver.cashLimit = Object.assign({}, this.driver.cashLimit, {
      value: this.driver.cashLimit.amount
    })

    return Promise.all([
      driverModel.update(this.driver),
      driverCollection.setCash(model, this.driver.id)
    ])
  }

  handleSubmitSuccess ([driverResponse, balanceResponse]) {
    this.handleDriver(driverResponse)

    if (balanceResponse && balanceResponse.lastBalance) {
      this.driver.balance = Cost.build(balanceResponse.lastBalance)
    }

    this.balanceChange = Cost.build({amount: 0})

    super.handleSubmitSuccess([driverResponse, balanceResponse])
  }
}

export default {
  templateUrl,
  controller: driverCashAccountController
}
