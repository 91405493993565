import endpoints from 'common/endpoints'
import Logger from 'common/Logger'
import CrudService from './CrudService'
import Cost from 'data/domain-objects/Cost'

const logger = new Logger('Withdrawal Service')
const crudService = new CrudService(endpoints.WITHDRAWAL_REQUEST)

export function withdrawalServiceFactory (crudService) {
  function sendWithdrawalRequest (balanceAccountId, amount) {
    const safeAmount = amount.currencyCode ? Cost.build(amount) : Cost.build({amount})
    const requestBody = {
      balanceAccountId,
      amount: {
        currencyCode: amount.currencyCode,
        value: safeAmount.amount
      }
    }
    logger.info('Posting withdrawal request:', requestBody)
    return crudService.create(requestBody)
      .then(handleSuccessWithdrawalRequest)
      .catch(handleWithdrawalRequestError)
  }

  function handleSuccessWithdrawalRequest (response) {
    logger.info('Withdrawal request successfully posted: ', response)
    return response
  }

  function handleWithdrawalRequestError (error) {
    logger.error('Can\'t post withdrawal error: ', error)
  }

  return {
    sendWithdrawalRequest
  }
}

export default withdrawalServiceFactory(crudService)
