import templateUrl from './driver-overview-popup.pug'
import BasePopupController from 'presentation/common/BasePopupController'
import DriverOverviewDataService from 'presentation/logistics-drivers/driver-details/driver-overview/driverOverviewData'
import {deliveryDialogIds} from 'common/constants/DialogIds'
import envConfig from 'common/envConfig'
import {DRIVER_OVERVIEW_OPEN_POPUP_EVENT} from 'common/constants/PopupEvents'

class DriverPopup extends BasePopupController {
  constructor ($scope, $rootScope) {
    super($scope, $rootScope, DRIVER_OVERVIEW_OPEN_POPUP_EVENT, deliveryDialogIds.DRIVER_OVERVIEW_ID)

    this.DRIVER_OVERVIEW_ID = deliveryDialogIds.DRIVER_OVERVIEW_ID
    this.driverId = null
    this.driver = null

    this.setupPopupOpenEventListener(driverId => {
      if (this.driverId !== driverId) {
        this.popupOpened = true
        this.loadDriverData(driverId)
      }
    })
  }

  onClose () {
    this.popupOpened = false
    this.driver = null
    this.driverId = null
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  scheduleUpdate () {
    if (this.popupOpened) {
      this.refreshTimeout = setTimeout(() => {
        if (this.driverId !== null) {
          this.loadDriverData(this.driverId)
        }
      }, envConfig.driversRefreshInterval)
    }
  }

  loadDriverData (driverId) {
    DriverOverviewDataService.getDriverInfo(driverId)
      .catch(err => this.logger.error('Coulnd\'t load driver info for driver id', driverId, err))
      .then(data => {
        if (this.popupOpened) {
          this.driver = data
          this.driverId = driverId
          this.scheduleUpdate()
          this.$scope.$digest()
        }
      })
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$rootScope', DriverPopup],
  bindings: {
    hideDeliveryPopupDialog: '<?'
  }
}

