import moment from 'moment'
import templateUrl from './time-picker.pug'
import {DATE_TIME_PICKER_CHANGE_DATE_EVENT, DATE_TIME_PICKER_OPEN_EVENT} from 'presentation/_core-forms/date-time-picker/dateTimePicker'
import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'

const TIME_FORMAT = 'HH:mm'

class TimePickerController {
  constructor ($scope) {
    this.$scope = $scope
    this.cancelDateTimePickerSubscription = null
    modalDialogStateService.onCloseModalDialog(() => {
      if (this.cancelDateTimePickerSubscription && typeof this.cancelDateTimePickerSubscription === 'function') {
        this.cancelDateTimePickerSubscription()
      }
    })
  }

  $onChanges () {
    const formattedCurrentTime = moment().format(TIME_FORMAT)
    const dateModel = moment(this.model || formattedCurrentTime, TIME_FORMAT).toDate()
    this.dateTimePickerConfig = {
      isRange: false,
      showDate: false,
      showTime: true,
      selectedDate: dateModel
    }
  }

  openTimePicker () {
    this.$scope.$emit(DATE_TIME_PICKER_OPEN_EVENT, this.dateTimePickerConfig)
    this.cancelDateTimePickerSubscription = this.$scope.$on(DATE_TIME_PICKER_CHANGE_DATE_EVENT, (...args) => this.handleTimePickerClose(...args))
  }

  handleTimePickerClose (event, payload) {
    const selectedMoment = moment(payload.date)
    this.model = selectedMoment.locale('en').format(TIME_FORMAT)
    this.dateTimePickerConfig.selectedDate = selectedMoment.toDate()
    this.cancelDateTimePickerSubscription()
  }

  clear () {
    this.model = null
    this.dateTimePickerConfig.selectedDate = new Date()
  }
}

export default {
  templateUrl,
  replace: true,
  controller: TimePickerController,
  bindings: {
    model: '=',
    form: '=',
    name: '@',
    backendValidationModel: '=',
    isEditing: '<',
    isRequired: '<',
    isDisabled: '<',
    title: '@',
    valueMessage: '@',
    placeholder: '@'
  }
}
