import templateUrl from './report-table.pug'
import envConfig from 'common/envConfig'

export class ReportTable {
  constructor ($scope) {
    this.$scope = $scope

    this.$scope.$watch('$ctrl.records', () => {
      this.hasVisibleRecords = this.records && this.records.length > 0
    })
  }

  changeSorting (column) {
    this.setSortingOrder(column.name)
  }

  setSortingOrder (order) {
    if (this.disableSortChange) {
      return
    }
    if (this.orderBy === order) {
      this.orderDirection = (this.orderDirection === 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.orderBy = order
      this.orderDirection = envConfig.reports.defaultSortOrder
    }

    if (typeof this.onChangeSort === 'function') {
      this.onChangeSort({orderBy: this.orderBy, orderDirection: this.orderDirection})
    }
  }
}

export default {
  templateUrl,
  controller: ReportTable,
  bindings: {
    titles: '@',
    columns: '<',
    records: '<?',
    orderBy: '=',
    orderDirection: '=',
    emptyTitle: '@',
    onChangeSort: '&?',
    hostClass: '@?',
    disableSortChange: '<?'
  }
}
