import Cost from 'data/domain-objects/Cost'
import { ShippingMethods } from 'common/constants/ShippingMethods'
import * as PaymentMethods from 'common/constants/PaymentMethods'
import StorefrontOrder from 'data/domain-objects/storefront/StorefrontOrder'
import StorefrontBusinessDetails from 'data/domain-objects/storefront/StorefrontBusinessDetails'

class PrintOrderThermoTemplate {
  // Inputs
  public order: StorefrontOrder = null
  public businessDetails: StorefrontBusinessDetails = null

  // Props
  public shippingMethods: typeof ShippingMethods = ShippingMethods
  public paymentMethods: typeof PaymentMethods.PaymentMethod = PaymentMethods.PaymentMethod
  public totalItemsAmount: number = 0

  public get subtotal(): Cost {
    return Cost.build({
      amount: this.order.items.reduce((acc: number, item: any): number => acc + item.totalPrice.value, 0)
    })
  }

  public $onChanges(simpleChanges: ng.IOnChangesObject): void {
    if (simpleChanges.order) {
      this.totalItemsAmount = this.order.items.reduce((acc: number, item: any) => acc + item.amount, 0)
    }
  }
}

export default {
  templateUrl: require('./print-order-thermo-template.pug'),
  controller: PrintOrderThermoTemplate,
  bindings: {
    order: '<',
    estimatedTime: '<',
    showLogo: '<',
    businessDetails: '<',
  }
}
