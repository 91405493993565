import Cost from './Cost'

export default class VoucherValidation {

  constructor(
    public voucherCodeExists: boolean,
    public balanceAccountValid: boolean,
    public id: string,
    public limitUsed: number,
    public usageLimit: number,
    public redeemed: boolean,
    public status: string,
    public voucherExpired: boolean,
    public voucherCode: string,
    public amount: Cost,
    public balanceAccountPermitted: boolean
  ) {}

  static build(dto: any = {}): VoucherValidation {
    return new VoucherValidation(
      dto.voucherCodeExists || false,
      dto.balanceAccountValid || false,
      dto.id || null,
      dto.limitUsed || 0,
      dto.usageLimit || null,
      dto.redeemed || false,
      dto.status || '',
      dto.voucherExpired || false,
      dto.voucherCode || null,
      dto.amount ? Cost.build(dto.amount) : Cost.build(),
      dto.hasOwnProperty('balanceAccountPermitted') && dto.balanceAccountPermitted || false
    )
  }
}
