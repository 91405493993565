class LocalStorageService {
  constructor () {
    if (!window.localStorage) {
      throw new Error('Please use modern browsers! Local storage is unavailable')
    }
  }

  getValue (key) {
    if (typeof key !== 'string') {
      throw new Error('Provided key is not a string!')
    }

    return window.localStorage.getItem(key)
  }

  setValue (key, value) {
    if (typeof key !== 'string' || (typeof value !== 'string' && typeof value !== 'number' && typeof value && 'boolean')) {
      throw new Error('Provided key or value has wrong type!')
    }

    window.localStorage.setItem(key, value)
  }

  removeValue (key) {
    if (typeof key !== 'string') {
      throw new Error('Provided key is not a string!')
    }

    window.localStorage.removeItem(key)
  }
}

const localStorageService = new LocalStorageService()
export default localStorageService
