import endpoints from 'common/endpoints'
import CrudService from 'data/services/CrudService'

const subscriptionPlansService = new CrudService(endpoints.SUBSCRIPTION_PLANS)

export function subscriptionPlansCollectionFactory (subscriptionPlansService) {
  return {
    getAll: function () {
      return subscriptionPlansService.get()
    }
  }
}

export default subscriptionPlansCollectionFactory(subscriptionPlansService)
