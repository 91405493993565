/* globals google, MarkerClusterer */
import locationsService from 'data/services/locationsService'
import envConfig from 'common/envConfig'
import * as mapUtils from 'common/utils/mapUtils'

export default class BaseMapController {
  constructor ($scope, mapElement, markerType) {
    const defaultLocation = locationsService.getDefaultLocation()

    this.$scope = $scope
    this.mapElement = mapElement
    this.startingCenterPoint = new mapUtils.GoogleMapsPoint(defaultLocation.latitude, defaultLocation.longitude)
    this.mapOptions = new mapUtils.GoogleMapsOptions(this.startingCenterPoint, envConfig.googleMapsSettings.defaultZoomLevel, envConfig.googleMapsSettings.styles.lightSilverMap)
    this.renderedMapMarkers = {}
    this.markerType = typeof envConfig.googleMapsSettings.markerTypes[markerType] === 'undefined' ? envConfig.googleMapsSettings.markerTypes.pin.name : markerType
  }

  initMap (doInitClusterer) {
    this.$scope.map = new google.maps.Map(this.mapElement, this.mapOptions)
    if (doInitClusterer) {
      this.markerClusterer = new MarkerClusterer(
        this.$scope.map,
        [],
        envConfig.googleMapsSettings.clustererOptions
      )
    }
  }

  checkAndRedrawMarkers (markers) {
    const markerIds = markers.map(marker => marker.id)
    markers.forEach(marker =>
      this.renderMarkerAtLocation(marker.id, marker.location, marker.status, marker.onClick)
    )
    this.validateRenderedMarkers(markerIds)

    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers()
      const googleMapsMarkers = Object.keys(this.renderedMapMarkers).map(markerId => this.renderedMapMarkers[markerId].marker).filter(marker => !!marker)
      this.markerClusterer.addMarkers(googleMapsMarkers)
      this.markerClusterer.redraw()
    }
  }

  renderMarkerAtLocation (id, point, status, onClick, svgMarker = null, markerSize, zIndex = 1) {
    const renderedMarker = this.renderedMapMarkers[id] || {}
    const googleMapsLatLng = new google.maps.LatLng(point.latitude, point.longitude)
    const locationHasChanged = mapUtils.checkHasLatLngChanged(googleMapsLatLng, renderedMarker.googleMapsLatLng)
    const statusHasChanged = mapUtils.checkStatusHasChanged(status, renderedMarker)

    if (renderedMarker.marker && (locationHasChanged || statusHasChanged)) {
      if (!this.markerClusterer) {
        if (renderedMarker.isSVGMarker) {
          renderedMarker.marker.setMap(null)
        } else {
          renderedMarker.marker.removeFromMap()
        }
      }
    }

    if (!renderedMarker.marker || locationHasChanged || statusHasChanged) {
      let marker = null
      if (svgMarker) {
        marker = mapUtils.createSVGMarker(this.$scope.map, point.latitude, point.longitude, svgMarker, markerSize, zIndex)
      } else {
        marker = new mapUtils.Marker(googleMapsLatLng, status, onClick, this.markerType)
        if (!this.markerClusterer) {
          marker.addToMap(this.$scope.map)
        }
      }

      this.renderedMapMarkers[id] = {
        marker: marker,
        googleMapsLatLng: googleMapsLatLng,
        isSVGMarker: !!svgMarker
      }
    }
  }

  validateRenderedMarkers (markerIdsThatShouldBeVisible) {
    Object.keys(this.renderedMapMarkers).forEach(renderedMapMarkerId => {
      if (markerIdsThatShouldBeVisible.indexOf(renderedMapMarkerId) === -1) {
        this.removeRenderedMarkerFromMap(renderedMapMarkerId)
      }
    })
  }

  removeRenderedMarkerFromMap (markerId) {
    const renderedMarker = this.renderedMapMarkers[markerId]
    if (renderedMarker && renderedMarker.marker) {
      if (!this.markerClusterer) {
        if (renderedMarker.isSVGMarker) {
          renderedMarker.marker.setMap(null)
        } else {
          renderedMarker.marker.removeFromMap()
        }
      }
      renderedMarker.marker = null
    }
  }

  viewAllMarkers () {
    if (!this.$scope.map) {
      return
    }
    const bounds = new google.maps.LatLngBounds()
    const renderedMapMarkerKeys = Object.keys(this.renderedMapMarkers)
    const renderedMapMarkers = renderedMapMarkerKeys
      .map(renderedMapMarkerKey => this.renderedMapMarkers[renderedMapMarkerKey])
      .filter(renderedMarker => !!renderedMarker.marker)

    renderedMapMarkers.forEach(renderedMapMarker => {
      bounds.extend(renderedMapMarker.googleMapsLatLng)

      if (renderedMapMarkers.length === 1) {
        this.$scope.map.setCenter(renderedMapMarker.googleMapsLatLng)
        this.$scope.map.setZoom(envConfig.googleMapsSettings.defaultZoomLevel)
      }
    })

    if (renderedMapMarkers.length > 1) {
      this.$scope.map.setCenter({
        lat: envConfig.locations.defaultLocationLatitude,
        lng: envConfig.locations.defaultLocationLongitude
      })
      // If you need to show all markers use this.$scope.map.fitBounds(bounds)
    }
  }

  clearMapListeners () {
    google.maps.event.clearInstanceListeners(this.$scope.map)
  }
}
