import templateUrl from './schedule-dashboard.html'
import BaseController from 'presentation/common/BaseController'
import scheduleCollection from 'data/collections/scheduleCollection'

class ScheduleDashboardController extends BaseController {
  constructor ($scope, $state) {
    super($scope, $state)
    this.sync()
  }

  onSync () {
    return scheduleCollection.getAll()
      .then(schedules => this.entities = schedules)
  }

  deleteSchedule (params) {
    return scheduleCollection.delete(params.id)
      .catch(err => this.logger.error('Cannot delete schedule', err))
      .then(() => this.sync())
  }
}

export default {
  templateUrl,
  controller: ScheduleDashboardController
}
