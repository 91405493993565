import templateUrl from './pickup-slot.pug'
import moment from 'moment'
import envConfig from 'common/envConfig'

const periods = envConfig.nextDayDeliveryPickupSlots

class Period {
  constructor (id, startingHour, endingHour) {
    this.id = id
    this.name = `slot_${id}`

    const nowTimestamp = moment().add(1, 'hours').valueOf()
    const periodBegin = moment().hours(startingHour).minutes(0).seconds(0)
    if (nowTimestamp >= periodBegin.valueOf()) {
      periodBegin.add(1, 'days')
    }

    this.date = periodBegin.format('ddd D MMM')
    this.time = `${startingHour}:00 – ${endingHour}:00`,
    this.value = periodBegin.valueOf()
    this.isDisabled = false
  }
}

class PickupSlotController {
  constructor ($scope) {
    this.$scope = $scope
    this.calculateAvailableSlots()

    this.$scope.$watch('$ctrl.selectedSlotTimestamp', val => {
      if (val) {
        this.onSlotChanged({value: this.selectedSlotTimestamp})
      }
    })
  }

  calculateAvailableSlots () {
    this.slots = []
    periods.forEach((periodLimits, idx) => {
      this.slots.push(new Period(idx, periodLimits[0], periodLimits[1]))
    })

    // sorting by timestamp
    this.slots.sort((a, b) => a.value - b.value)
  }
}

export default {
  templateUrl,
  controller: PickupSlotController,
  bindings: {
    form: '=',
    settings: '<',
    onSlotChanged: '&'
  }
}
