import { StateService } from '@uirouter/core'
import BaseController from 'presentation/common/BaseController'
import localStorageService from 'data/services/localStorageService'
import branchesCollection from 'data/collections/branchesCollection'
import storefrontOrdersCollection, { StorefrontOrdersSorting } from 'data/collections/storefrontOrdersCollection'
import { STOREFRONT_ORDER_STATUSES } from 'common/constants/StorefrontOrderStatuses'
import { ORDER_POPUP_SHOW_ACTION } from 'presentation/storefront/order-popup/orderPopup'
import StorefrontOrder from 'data/domain-objects/storefront/StorefrontOrder'
import StorefrontOrderBranch from 'data/domain-objects/storefront/StorefrontOrderBranch'
import { PaymentMethod } from 'common/constants/PaymentMethods'
import { PaymentProviders } from 'common/constants/PaymentProviders'
import StorefrontOrderPayment from 'data/domain-objects/storefront/StorefrontOrderPayment'
import StorefrontOrderContactDetails from 'data/domain-objects/storefront/StorefrontOrderContactDetails'
import moment from 'moment'
import envConfig from 'common/envConfig'

const SKIP = 0
const LIMIT = 20
const POLLING_INTERVAL = 30000
const MIN_SEARCH_QUERY_LENGTH = 2
const STOREFRONT_BUSINESS_ID_STORAGE_KEY = 'storefrontBusinessId'
const ORDER_STATUSES_BASE_TRANSLATION_KEY = 'COMMON.ORDER_STATUSES'

class OrderHistoryController extends BaseController {
  public rowsTotalCount: number = 0
  public currentPage: number = 1
  public limit: number = LIMIT
  public skip: number = SKIP

  public pollingTimeout: ng.IPromise<any>
  public formatters: any = {}

  public searchQuery: string = null
  public businessId: string = localStorageService.getValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY)
  public branchId: string = null
  public startDate: number = null
  public endDate: number = null

  public dateRange: { [key: string]: Date } = {
    startDate: null,
    endDate: null
  }

  public branchNamesById: any = {}
  public orders: StorefrontOrder[] = []
  public rows: any[] = []

  public showError: boolean

  constructor(
    public $scope: ng.IScope,
    public $state: StateService,
    public $timeout: ng.ITimeoutService,
    public $filter: ng.IFilterService
  ) {
    super($scope, $state)
  }

  $onInit(): void {
    this.$scope.$watch('$ctrl.dateRange.endDate', (value: Date) => this.onDateEndChange(value))

    this.getBranches().then((): void => {
      if (this.businessId) {
        this.sync()
      }
    })

    this.formatters = this.getFormatters()
  }

  $onDestroy(): void {
    this.stopPolling()
  }

  getBranches(): Promise<void> {
    return branchesCollection.getBranchNamesById()
      .then((response: any[]): any => this.branchNamesById = response)
      .catch((error: Error): void => this.logger.error('Cannot get branches data', error))
  }

  onSync(): Promise<any> {
    this.showError = false

    return storefrontOrdersCollection
      .getOrders(
        this.businessId,
        this.branchId,
        this.searchQuery,
        [
          STOREFRONT_ORDER_STATUSES.COMPLETED,
          STOREFRONT_ORDER_STATUSES.DECLINED,
          STOREFRONT_ORDER_STATUSES.CANCELLED
        ],
        this.limit,
        this.skip,
        StorefrontOrdersSorting.createdReversed,
        this.startDate ? new Date(this.startDate) : null,
        this.endDate ? new Date(this.endDate) : null
      )
      .then((response: any): any => {
        this.orders = response.records
        this.rowsTotalCount = response.metadata.count
        this.generateRows()
        this.pollingTimeout = this.$timeout(() => this.sync(), POLLING_INTERVAL)
      })
      .catch((error: Error): void => {
        this.logger.error('An error occured while getting Storefront orders', error)
        this.isLoading = false
        this.isLoaded = true
        this.showError = true
        this.stopPolling()
      })
  }

  public changeDates(payload: { [key: string]: Date }): void {
    this.dateRange.startDate = payload.dateFrom
    this.dateRange.endDate = payload.dateTo
  }

  public onDateEndChange(value: Date): void {
    if (value) {
      this.startDate = moment(this.dateRange.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf()
      this.endDate = moment(this.dateRange.endDate).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).valueOf()
    } else {
      this.startDate = null
      this.endDate = null
    }

    this.skip = 0
    this.currentPage = 1
    this.restartPolling()
  }

  generateRows(): void {
    this.rows = this.orders.map((order: StorefrontOrder): any => {
      return {
        created: order.createdAt,
        type: order,
        pos: order.posReference,
        branch: order.branches,
        customer: order.contactDetails,
        amount: order,
        status: order.status,
        action: (): void => this.viewOrder(order.id)
      }
    })
  }

  getFormatters(): any {
    return {
      created: (value: Date): string => moment(value).format(envConfig.defaultDateFormat),
      type: (value: StorefrontOrder): string => '',
      branch: (value: StorefrontOrderBranch[]): string => value
        .map((branch: StorefrontOrderBranch): string => this.branchNamesById[branch.id])
        .join(', '),
      customer: (value: StorefrontOrderContactDetails): string => '',
      amount: (value: StorefrontOrder): string => '',
      status: (value: any): string => this.$filter<ITranslateFilter>('translate')(`${ORDER_STATUSES_BASE_TRANSLATION_KEY}.${value}`),
      action: (value: any): string => null
    }
  }

  stopPolling(): void {
    this.$timeout.cancel(this.pollingTimeout)
  }

  restartPolling(): void {
    this.stopPolling()
    this.sync()
  }

  onBusinessSelected(business: any): void {
    this.businessId = business.businessId
    localStorageService.setValue(STOREFRONT_BUSINESS_ID_STORAGE_KEY, this.businessId)
    this.branchId = null
    if (this.businessId) {
      this.restartPolling()
    } else {
      this.orders = []
    }
  }

  onBranchSelected(branch: any): void {
    this.branchId = branch.branchId

    if (this.businessId) {
      this.restartPolling()
    } else {
      this.orders = []
    }
  }

  onSearchQueryChanged(searchQuery: string): void {
    if (searchQuery.length >= MIN_SEARCH_QUERY_LENGTH || !searchQuery) {
      this.searchQuery = searchQuery
      this.skip = SKIP
      this.restartPolling()
    }
  }

  onOffsetChange(offset: number, limit: number): void {
    this.skip = offset
    this.limit = limit
    this.restartPolling()
  }

  viewOrder(orderId: string): void {
    this.$scope.$emit(ORDER_POPUP_SHOW_ACTION, {
      businessId: this.businessId,
      orderId
    })
  }
}

export default {
  templateUrl: require('./order-history.pug'),
  controller: OrderHistoryController
}
