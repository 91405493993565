import deliveriesCollection from 'data/collections/deliveriesCollection'
import {StateParams, StateService} from '@uirouter/core'
import BaseController from 'presentation/common/BaseController'
import Delivery from 'data/domain-objects/Delivery'

class RideSummaryController extends BaseController {

  public ride: Delivery = null
  private rideId: string = ''

  constructor (
    public $scope: ng.IScope,
    public $state: StateService,
    public $stateParams: StateParams
  ) {
    super($scope, $state)
  }

  $onInit (): void {
    this.rideId = this.$stateParams.rideId
    this.sync()
  }

  onSync (): Promise<Delivery> {
    return deliveriesCollection.getById(this.rideId)
      .then((ride: Delivery) => this.ride = Delivery.build(ride))
  }
}

export default {
  templateUrl: require('./ride-confirmation.pug'),
  controller: RideSummaryController
}
