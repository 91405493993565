import promoCodesCollection from 'data/collections/promoCodesCollection'
import PromoCode from 'data/domain-objects/PromoCode'
import Logger from 'common/Logger'
import PaginationParameters from 'data/domain-objects/PaginationParameters'

const logger = new Logger('Places List Controller')

class PromoCodesList {
  public promoCodes: PromoCode[] = []
  public isLoading: boolean = false
  public DATE_FORMAT: string = 'yyyy/MM/dd HH:mm'

  constructor(private $scope: ng.IScope) {}

  $onInit(): void {
    this.getPromoCodes()
  }

  getPromoCodes(): void {
    this.isLoading = true

    promoCodesCollection.getPromoCodes()
    .then((promoCodes: PromoCode[]) => {
      this.promoCodes = promoCodes
    })
    .catch((error: Error) => {
      logger.error('Can\'t load promo codes', error)
    })
    .then(() => {
      this.isLoading = false
      this.$scope.$digest()
    })
  }

  deletePromoCode = (promoCodeId: string): Promise<void> => { // Delete Dialog Controller which we pass this function to awayting Promise
    return promoCodesCollection.deletePromoCode(promoCodeId)
      .then(() => {
        const placeIndex = this.promoCodes.findIndex((promoCode: PromoCode) => promoCodeId === promoCode.id)
        if (placeIndex > -1) {
          this.promoCodes.splice(placeIndex, 1)
        }
      })
      .catch((error: Error) => {
        logger.error('Can\'t delete promo code:', error)
      })
  }
}

export default {
  templateUrl: require('./promo-codes-list.pug'),
  controller: PromoCodesList
}
