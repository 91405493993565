import minBy from 'lodash-es/minBy'
import maxBy from 'lodash-es/maxBy'
import envConfig from 'common/envConfig'
import Cost from 'data/domain-objects/Cost'
import templateUrl from './driver-cash-account-change.pug'
import ParseUtils from 'common/utils/parseUtils'

const AVAILABLE_AMOUNTS_LIST = envConfig.balanceChangePresetOptions
const CHANGE_OF_BALANCE_INPUT_NAME = 'driver-cash-account-change_cost-input'

class DriverCashAccountChange {
  constructor ($scope) {
    this.currencyCode = this.currentBalance && this.currentBalance.currencyCode ? this.currentBalance.currencyCode : envConfig.defaultCurrencyCode
    this.newBalance = Cost.build()
    this.getDecimalPointsCount = Cost.getDecimalPointsCountForCurrency

    $scope.$watch('$ctrl.model.amount', () => this.handleModelUpdate())
  }

  getAvailableOptions () {
    const currentBalanceAmount = this.getCurrentBalanceAmount()
    const currentAmounts = currentBalanceAmount ? [this.getOptionForAmount(-currentBalanceAmount)] : []
    const availableAmounts = AVAILABLE_AMOUNTS_LIST.map(amount => this.getOptionForAmount(amount))

    this.availableOptions = currentAmounts.concat(availableAmounts)

    this.getMinimumAndMaximum()
  }

  getMinimumAndMaximum () {
    const currentCashLimitAmount = Cost.build(this.cashLimit)

    this.minimum = minBy(this.availableOptions, 'amount')
    this.maximum = maxBy([...this.availableOptions, currentCashLimitAmount], 'amount')
  }

  getOptionForAmount (amount) {
    return {amount, currencyCode: this.currencyCode}
  }

  getCurrentBalanceAmount () {
    if (typeof this.currentBalance === 'number') {
      return this.currentBalance
    }

    return parseFloat((this.currentBalance && this.currentBalance.amount) ? this.currentBalance.amount : 0)
  }

  handleModelUpdate () {
    let newBalance = 0
    newBalance += isNaN(this.getCurrentBalanceAmount()) ? 0 : this.getCurrentBalanceAmount()
    newBalance += ParseUtils.safeParseNumber(this.model.amount, 0)
    this.newBalance = Cost.build({
      amount: newBalance,
      currencyCode: this.currencyCode
    })

    this.validateModel()
  }

  validateModel () {
    const isNewBalanceBiggerThanMaximumLimit = this.newBalance.amount > this.cashLimit.amount
    if (this.form && this.form[CHANGE_OF_BALANCE_INPUT_NAME]) {
      this.form[CHANGE_OF_BALANCE_INPUT_NAME].$setValidity('newBalanceBiggerThanMaximumLimit', !isNewBalanceBiggerThanMaximumLimit)
    }
  }

  $onChanges (changes) {
    if (changes.currentBalance) {
      this.currentBalance = changes.currentBalance.currentValue
      this.getAvailableOptions()
      this.handleModelUpdate()
    }

    if (changes.cashLimit) {
      this.cashLimit = changes.cashLimit.currentValue
      this.getMinimumAndMaximum()
    }
  }
}

export default {
  templateUrl,
  controller: DriverCashAccountChange,
  bindings: {
    form: '=',
    model: '=',
    currentBalance: '<',
    cashLimit: '<'
  }
}
