export default {
  templateUrl: require('./fancy-dashboard-button.pug'),
  bindings: {
    buttonTitleTranslationKey: '@',
    stateToGo: '@',
    fontIconClass: '@',
    fontSizeMultiplier: '<'// in css we have defined 100px for wrapper and 1em for icon as font-size, you can redefine this by passing multiplier here.
                          // This can help if font icon have sides ratio different from 1:1
  }
}
