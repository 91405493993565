import { PaymentProviders } from 'common/constants/PaymentProviders'
import { PaymentMethod } from 'common/constants/PaymentMethods'

export default class StorefrontOrderPayment {
  constructor(
    public provider: PaymentProviders,
    public method: PaymentMethod,
    public refundRequested: boolean,
    public refundProcessed: boolean
  ) { }

  public static build(data: any = {}): StorefrontOrderPayment {
    return new StorefrontOrderPayment(
      data.provider || null,
      data.method || null,
      !!data.refundRequested,
      !!data.refundProcessed
    )
  }
}
