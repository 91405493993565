export const NOT_READY = 'notReady'
export const CANT_ALLOCATE = 'cantAllocate'
export const TO_PICKUP_IDLING = 'toPickupIdling'
export const AT_PICKUP_IDLING = 'atPickupIdling'
export const TO_DROPOFF_IDLING = 'toDropoffIdling'
export const AT_DROPOFF_IDLING = 'atDropoffIdling'
export const PAYMENT = 'payment'
export const PAYMENT_TIMEOUT = 'paymentTimeout'
export const DELIVERY_TIME = 'deliveryTime'
export const COMPLIANCE = 'compliance'
export const GPS = 'gps'
export const BATTERY = 'battery'
export const DISTANCE = 'distance'
export const DRIVER_STALLED = 'driverStalled'
