import declareAppModule from 'presentation/_utilities/declareAppModule'
import declareState from 'presentation/_utilities/declareState'

import {StateProvider} from '@uirouter/angularjs'
import toolsDashboard from 'presentation/tools/dashboard/toolsDashboard'
import vouchersModule from 'presentation/tools/vouchers/vouchersModule'
import placesModule from 'presentation/tools/places/placesModule'
import promoCodesModule from './promo-codes/promoCodesModule'

export default declareAppModule('tools', (module: ng.IModule) => module
  .component('toolsDashboard', toolsDashboard)
  .config(($stateProvider: StateProvider) => {
    declareState($stateProvider, {
      parent: 'main',
      stateName: 'tools',
      componentName: 'toolsDashboard',
      stateKey: 'TOOLS'
    })
  }),
  [
    vouchersModule,
    placesModule,
    promoCodesModule
  ]
)
