import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import templateUrl from './user-transactions-history.pug'
import userModel from 'data/models/userModel'
import Cost from 'data/domain-objects/Cost'
import userCollection from 'data/collections/userCollection'
import BalanceHistoryBaseController from 'presentation/common/BalanceHistoryBaseController'

const COLUMNS_CONFIGURATION = [
  {name: 'date', title: 'DATE', sortable: true, sortKey: 'createdAt', icon: 'time'},
  {name: 'draewilId', title: 'DRAEWIL_ID'},
  {name: 'pos', title: 'POS'},
  {name: 'pickup', title: 'PICK_UP'},
  {name: 'fleet', title: 'FLEET'},
  {name: 'driver', title: 'DRIVER'},
  {name: 'operation', title: 'OPERATION', sortable: true, sortKey: 'operation', icon: 'amount'},
  {name: 'description', title: 'STATUS'}
]

class UserTransactionsHistory extends BalanceHistoryBaseController {
  constructor ($scope, $state, $filter) {
    super($scope, $state, $filter)

    this.columns = COLUMNS_CONFIGURATION
    this.$scope.$watch('$ctrl.triggerDataReload', (newTimestamp, oldTimestamp) => this.conditionallyResync(newTimestamp, oldTimestamp))
    this.sync()
  }

  conditionallyResync (newTimestamp, oldTimestamp) { // Only re-sync on change
    if (oldTimestamp && newTimestamp !== oldTimestamp) {
      this.sync()
    }
  }

  onSync () {
    const filters = Object.assign({}, this.filters)
    if (!this.filtersInitialized) {
      filters.includeFilters = true
      this.filtersInitialized = true
    }

    return userModel.getUser()
      .then(user => userCollection.getHistory(user.id, filters))
      .then(response => this.processData(response))
  }

  processMetaData (metadata) {
    this.rowsTotalCount = metadata.count

    const pickups = get(metadata, 'filter.senders', [])
    if (pickups.length > 0) {
      this.pickupFilterNames = {}
      this.pickupFilterIds = []

      pickups.forEach(pickup => {
        this.pickupFilterIds.push(pickup.id)
        this.pickupFilterNames[pickup.id] = (pickup.business) ? `${pickup.business} — ${pickup.name}` : pickup.name
      })
    }
  }

  processData (response) {
    this.processMetaData(response.metadata)

    this.rows = response.records.map(row => {
      const date = (row.createdAt) ? this.$filter('date')(new Date(row.createdAt), envConfig.angularDefaultDateFormat) : ''
      const businessName = get(row, 'sender.business', '')
      const senderName = get(row, 'sender.name') || ''
      const fullName = (businessName ? `${businessName} ` : '') + senderName
      const fleet = row.fleet
      const link = row.deliveryId && row.type ? `<a href="/#!/deliveries/overview/${row.deliveryId}">${row.draewilId}</a>` : null

      return [
        date,
        link,
        row.pos,
        fullName,
        fleet,
        row.driver,
        Cost.build(row.operation ? row.operation : {}).getFormattedCost(),
        row.description
      ]
    })
  }
}

export default {
  bindings: {
    triggerDataReload: '<?'
  },
  templateUrl,
  controller: UserTransactionsHistory
}
