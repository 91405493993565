import moment from 'moment'
import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import tariffPeriodsCollection from 'data/collections/tariffPeriodsCollection'
import { StateService, StateParams } from '@uirouter/core'
import TariffPeriod from 'data/domain-objects/TariffPeriod'

class TariffPeriodController extends CreateViewEditFormController {
  // properties
  private INCORRECT_TIME_RANGE_ERROR_NAME: string = 'incorrect-time-range'
  public tariffPeriodForm: ng.IFormController

  constructor ($scope: ng.IScope, $state: StateService, $stateParams: StateParams) {
    super($scope, $state, $stateParams, 'tariffPeriodId')

    this.INCORRECT_TIME_RANGE_ERROR_NAME = 'incorrect-time-range'
  }

  $onInit (): void {
    super.$onInit()
    this.$scope.$watchGroup([
      '$ctrl.model.from',
      '$ctrl.model.to',
      '$ctrl.model.crossday'
    ], () => this.validateTimeChanges())
  }

  getIsTimePeriodCorrect (): boolean {
    if (this.model.from && this.model.to) {
      const timestampFrom = moment(this.model.from, 'HH:mm').unix()
      const timestampTo = moment(this.model.to, 'HH:mm').unix()
      return timestampTo > timestampFrom
    }

    return false
  }

  validateTimeChanges (): void {
    if (this.model.from && this.model.to) {
      this.tariffPeriodForm.tariffPeriodTo.$setValidity(this.INCORRECT_TIME_RANGE_ERROR_NAME, this.getIsTimePeriodCorrect() || this.model.crossday)
    }
  }

  getDefaultModel (): void {
    return this.backendModel || {
      name: '',
      from: '',
      to: '',
      crossday: false,
      days: {}
    }
  }

  getEntity (): Promise<void> {
    return tariffPeriodsCollection.getById(this.entityId)
      .then((tariffPeriod: TariffPeriod) => {
        this.setModel(tariffPeriod)
        this.computeCrossdayValue()
      })
      .catch((err: any) => this.logger.error('Cannot get tariff period', err))
      .then(() => this.$scope.$digest())
  }

  computeCrossdayValue (): void {
    this.model.crossday = this.model.from && this.model.to && !this.getIsTimePeriodCorrect()
  }

  beforeSubmit (): Promise<void> {
    return !this.isCreate ? tariffPeriodsCollection.update(this.entityId, this.model) : this.createTariffPeriod()
  }

  afterSubmitSuccess (backendModel: any): Promise<any> {
    return super.afterSubmitSuccess(backendModel)
      .then(() => {
        this.computeCrossdayValue()
        this.$scope.$digest()
      })
  }

  cancelEdit (): void {
    super.cancelEdit()
    this.computeCrossdayValue()
  }

  createTariffPeriod (): Promise<void> {
    return tariffPeriodsCollection.create(this.model)
      .then((tariffPeriod: TariffPeriod) => this.$state.go('main.logistics.tariff-periods.tariff-period', {
        tariffPeriodId: tariffPeriod.id
      }))
  }
}

export default {
  templateUrl: require('./tariff-period.pug'),
  controller: TariffPeriodController
}
