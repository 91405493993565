import templateUrl from './lazy-loader.pug'

class LazyLoader {
  constructor ($scope, $element) {
    this.$scope = $scope
    this.$element = $element
    this.observer = new IntersectionObserver(changes => this.onObserverChanges(changes))
  }

  onObserverChanges (changes) {
    changes.forEach(change => {
      if (change.intersectionRatio > 0) {
        this.onLazyTrigger()
        this.$scope.$apply()
      }
    })
  }

  $postLink () {
    this.observer.observe(this.$element[0])
  }
}

export default {
  templateUrl,
  controller: ['$scope', '$element', LazyLoader],
  bindings: {
    onLazyTrigger: '&'
  }
}
