import get from 'lodash-es/get'
import envConfig from 'common/envConfig'
import Logger from 'common/Logger'
import endpoints from 'common/endpoints'
import CrudService from './CrudService'
import BaseAddressTemplate from 'data/domain-objects/BaseAddressTemplate'
import {compareAddresses} from 'data/common/comparator'

export const DEFAULT_LOCATION_LATITUDE = get(envConfig, 'locations.defaultLocationLatitude', 0)
export const DEFAULT_LOCATION_LONGITUDE = get(envConfig, 'locations.defaultLocationLongitude', 0)
const logger = new Logger('Locations Service')
const locationFromAddressService = new CrudService(endpoints.ADDRESS_GEOCODE_FROM_ADDRESS)
const addressByLocationService = new CrudService(endpoints.ADDRESS_GEOCODE_FROM_LOCATION)

function getGeocodedLocationForAddress (addressDescriptor) {
  return locationFromAddressService.create(addressDescriptor) // I have no idea why is this post when it should be get
    .then(response => {
      const location = getLocationFromGeocodedLocationResponse(response) // NB. This may throw
      logger.info('Successfully geocoded location for address. Address and location are:', addressDescriptor, location)
      return location
    })
    .catch(error => {
      logger.warn('Could not geocode location for address. Returning default location. Address and error were:', addressDescriptor, error)
      return getDefaultLocation()
    })
}

function getLocationFromGeocodedLocationResponse (response) {
  if (typeof response.longitude === 'number' && typeof response.latitude === 'number') {
    return {
      latitude: response.latitude,
      longitude: response.longitude
    }
  } else {
    return getDefaultLocation()
  }
}

function getAddressByLocation (longitude, latitude) {
  return addressByLocationService.create({
    location: {longitude, latitude}
  }).then(addresses => {
    // Here we choose the address which has more information among others
    const sortedAddresses = addresses
      .map(address => BaseAddressTemplate.build(address))
      .sort(compareAddresses)
    return sortedAddresses.length ? BaseAddressTemplate.build(sortedAddresses[0]) : null
  })
}

function getDefaultLocation () {
  return {
    latitude: DEFAULT_LOCATION_LATITUDE,
    longitude: DEFAULT_LOCATION_LONGITUDE
  }
}

export default {
  getGeocodedLocationForAddress,
  getDefaultLocation,
  getAddressByLocation
}
