import modalDialogStateService from 'presentation/_core-elements/modal-dialog/modalDialogStateService'

export const CONFIRM_PLAN_CHANGE_DIALOG_ID = 'CONFIRM_PLAN_CHANGE_DIALOG_ID'

class ConfirmPlaceChangeDialog {

  public dialogId: string = CONFIRM_PLAN_CHANGE_DIALOG_ID
  public businessId: string = null
  public titleKey: string = ''
  public messageKey: string = ''

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService
  ) {
    modalDialogStateService.onOpenModalDialog((payload: any) => {
      if (payload.dialogId === CONFIRM_PLAN_CHANGE_DIALOG_ID && payload.businessId) {
        this.businessId = payload.businessId
        this.messageKey = payload.messageKey
        this.titleKey = payload.titleKey
      }
    })

    modalDialogStateService.onCloseModalDialog(() => {
      this.resetDialogState()
    })
  }

  public dismiss(): void {
    modalDialogStateService.emitCloseModalDialog({
      dialogId: CONFIRM_PLAN_CHANGE_DIALOG_ID,
    })
  }

  public confirm(): void {
    modalDialogStateService.emitCloseModalDialog({
      dialogId: CONFIRM_PLAN_CHANGE_DIALOG_ID,
      businessId: this.businessId
    })
  }

  private resetDialogState(): void {
    this.businessId = null
    this.messageKey = ''
    this.titleKey = ''
  }
}

export default {
  templateUrl: require('./confirm-plan-change-dialog.pug'),
  controller: ConfirmPlaceChangeDialog,
}
