import get from 'lodash-es/get'
import Payment from 'data/domain-objects/Payment'
import BaseController from 'presentation/common/BaseController'
import deliveriesCollection from 'data/collections/deliveriesCollection'
import branchesCollection from 'data/collections/branchesCollection'
import templateUrl from './delivery-confirmation.pug'
import * as PaymentMethods from 'common/constants/PaymentMethods'

const BRANCH_PARTY_DESCRIPTOR_TYPE = 'branch'
const SHOW_VALUE = 'show'

class DeliveryConfirmationController extends BaseController {
  constructor ($scope, $state, $stateParams, $filter) {
    super($scope, $state)
    this.$filter = $filter
    this.$stateParams = $stateParams

    this.showPrintDialog = false
    this.settingsAreLoaded = false
    this.delivery = null
  }

  $onInit () {
    this.sync()
  }

  onSync () {
    return deliveriesCollection.getById(this.$stateParams.deliveryId)
      .then(delivery => {
        if (delivery.quote && delivery.quote.totalPrice) {
          delivery.quote.totalPrice = new Payment(delivery.quote.totalPrice, delivery.quote.method)
        }
        this.delivery = delivery

        if (this.delivery.payment.method !== PaymentMethods.CASH_OFF_SYSTEM) {
          this.paymentText =
            this.$filter('translate')(`COMMON.PAYMENT_METHODS.${this.delivery.payment.method}`)
            + ' '
          this.$filter('translate')('COMPONENTS.DELIVERY_SUMMARY.ADDITIONS.PAYMENT_OF')
            + ' '
          new Payment(this.delivery.payment.cost, this.delivery.payment.method).getFormattedCost()
        } else {
          this.paymentText = this.$filter('translate')(`COMMON.PAYMENT_METHODS.${PaymentMethods.CASH_OFF_SYSTEM}`)
        }

        return this.getPrintDialogSettings()
      })
      .catch(err => this.logger.error('Cannot get delivery by specified ID', err))
  }

  getPrintDialogSettings () {
    const branch = this.getBranchFromDelivery()
    if (branch) {
      return branchesCollection.getBranchSettings(branch.businessId, branch.id, {merge: true})
        .then(settings => {
          this.settingsAreLoaded = true
          this.showPrintDialog = get(settings, 'showPrintDialog.value', null) === SHOW_VALUE
        })
    }
    return Promise.resolve()
  }

  getBranchFromDelivery () {
    return [this.delivery.sender, this.delivery.recipient]
      .find(partyDescriptor => partyDescriptor.type === BRANCH_PARTY_DESCRIPTOR_TYPE)
  }
}

export default {
  templateUrl: templateUrl,
  controller: DeliveryConfirmationController
}
