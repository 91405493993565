import CreateViewEditFormController from 'presentation/common/CreateViewEditFormController'
import { StateParams, StateService } from '@uirouter/core'
import Surge from 'data/domain-objects/Surge'
import surgesCollection from 'data/collections/surgesCollection'
import permissionsModel from 'data/models/permissionsModel'
import Area from 'data/domain-objects/Area'
import Fleet from 'data/domain-objects/Fleet'
import TariffPeriod from 'data/domain-objects/TariffPeriod'
import areasCollection from 'data/collections/areasCollection'
import fleetsCollection from 'data/collections/fleetsCollection'
import tariffPeriodsCollection from 'data/collections/tariffPeriodsCollection'

const MAX_INTEGER = 10
const FRACTAL_STEP = 5

const getIntegerOptions = (): string[] => Array(MAX_INTEGER)
  .fill(null)
  .map((item: null, idx: number): string => (idx + 1).toString())

const getFractalOptions = (): string[] => Array(Math.round(100 / FRACTAL_STEP))
  .fill(null)
  .map((item: null, idx: number): number => idx * FRACTAL_STEP)
  .map((item: number): string => `${item < 10 ? 0 : ''}${item}`)

class PriceSurgeFormController extends CreateViewEditFormController {
  public availableAreas: Area[] = []
  public availableFleets: Fleet[] = []
  public availableTimePeriods: TariffPeriod[] = []

  public multiplierIntegerOptions: string[] = getIntegerOptions()
  public multiplierFractionalOptions: string[] = getFractalOptions()

  public multiplierIntegerPart: string = '1'
  public multiplierFractionalPart: string = '00'

  public showEntityLoadingFailedError: boolean = false
  public showDependenciesLoadingFailedError: boolean = false

  public backendModel: Surge = Surge.build({
    enabled: false,
    multiplier: 1,
    comment: null,
    fleetIds: [],
    pickupAreaIds: [],
    timePeriodIds: []
  })

  constructor (
    $scope: ng.IScope,
    $state: StateService,
    $stateParams: StateParams,
  ) {
    super($scope, $state, $stateParams, 'surgeId')
  }

  $onInit (): void {
    super.$onInit()
    if (this.isCreate) {
      this.isLoading = true
      this.getDependencies()
        .then((): void => {
          this.isLoading = false
          this.isLoaded = true
          this.$scope.$digest()
        })
    }
  }

  onSync (): Promise<any> {
    return Promise.all([
      this.getEntity(),
      this.getDependencies()
    ])
  }

  getEntity (): Promise<void> {
    return surgesCollection.getSurgeById(this.entityId)
      .then((surge: Surge): void => this.handleEntityLoaded(surge))
      .catch((error: Error): void => {
        this.logger.error('Cannot load surge pricing', error)
        this.showEntityLoadingFailedError = true
      })
  }

  getDependencies (): Promise<void | [void, void, void]> {
    return Promise.all([
      this.getAreas(),
      this.getFleets(),
      this.getTimePeriods()
    ]).catch((): void => { this.showDependenciesLoadingFailedError = true })
  }

  getAreas (): Promise<void> {
    return areasCollection.getAll()
      .then((areas: any[]): void => { this.availableAreas = areas.map(Area.build) })
      .catch((error: Error): void => { this.logger.error('Cannot get areas for surge pricing', error) })
  }

  getFleets (): Promise<void> {
    return fleetsCollection.getAll()
      .then((fleets: Fleet[]): void => { this.availableFleets = fleets })
      .catch((error: Error): void => { this.logger.error('Cannot get fleets for surge pricing', error) })
  }

  getTimePeriods (): Promise<void> {
    return tariffPeriodsCollection.getAll()
      .then((periods: TariffPeriod[]): void => { this.availableTimePeriods = periods })
      .catch((error: Error): void => { this.logger.error('Cannot get tariff periods for surge pricing', error) })
  }

  handleEntityLoaded (surge: Surge): void {
    const [integer, fractional] = surge.multiplier.toFixed(2).split(/[\.\,]/)
    this.multiplierIntegerPart = integer
    this.multiplierFractionalPart = fractional

    this.setModel(surge)
  }

  canEdit (): boolean {
    return permissionsModel.getHasPermission('PRICE_SURGE_UPDATE')
  }

  beforeSubmit (): Promise<Surge> {
    const jsonModel = this.model.toPostJson()
    return this.isCreate ? surgesCollection.createSurge(jsonModel) : surgesCollection.updateSurge(this.entityId, jsonModel)
  }

  public onMultiplierIntegerPartChanged (value: string): void {
    this.multiplierIntegerPart = value
    this.onMultiplierPartChanged()
  }

  public onMultiplierFractalPartChanged (value: string): void {
    this.multiplierFractionalPart = value
    this.onMultiplierPartChanged()
  }

  public onMultiplierPartChanged (): void {
    const multiplier = parseFloat(`${this.multiplierIntegerPart}.${this.multiplierFractionalPart}`)

    if (!isNaN(multiplier)) {
      this.model.multiplier = multiplier
    }
  }
}

export default {
  templateUrl: require('./price-surge-form.pug'),
  controller: PriceSurgeFormController
}
