import templateUrl from './radio-group.pug'

class RadioGroupController {
  constructor ($scope) {
    this.$scope = $scope
  }

  getLabel (valueIndex) {
    let result
    if (this.valuesLabels) {
      result = this.valuesLabels[valueIndex]
    } else {
      result = this.valuePrefixTranslationKey + '.' + this.values[valueIndex]
    }
    return result
  }
}

export default {
  templateUrl,
  controller: RadioGroupController,
  bindings: {
    name: '@',
    values: '<',
    model: '=',
    form: '=',
    valuePrefixTranslationKey: '@',
    isDisabled: '<?',
    onChange: '&?',
    valuesLabels: '<?'
  }
}
