import isEqual from 'lodash-es/isEqual'
import cloneDeep from 'lodash-es/cloneDeep'
import isObjectLike from 'lodash-es/isObjectLike'
import every from 'lodash-es/every'
import userModel from './userModel'
import PubSub from 'common/PubSub'
import Logger from 'common/Logger'

/* The user permissions API response is a list of permission 'groups' with permissions 'values' like so:
 * {
 *   "area": ["list", "read", "create", "update"], // Note that the names are singular, "area" not "areas"
 *   "business": ["list"],
 *   "services": [] // Or for an empty group the key can be undefined
 * }
 *
 * Our UI permissions need arbitrarily map from the API permissions to UI features:
 * 1) We may want to enable a feature if it has a specific value in its permissions group
 * 2) We may want to enable a feature based on arbitrary combinations of permissions values
 *
 * Because these are complex, we have a 'resolver' function for each of these types of mapping.
 * When we invoke this function, it will tell us if we have that UI permission.
 *
 * The resolver functions correspond to these three scenarios above like so:
 * 1) "has a specific value in its permissions group" -> getResolverForPermissionsValue()
 * 2) "has an arbitrary combination of permissions" -> customResolver
 */
const PERMISSIONS_CHANGED_EVENT = 'permissions-changed'
const PERMISSIONS_RESOLVERS_BY_UI_PERMISSIONS_KEY = {
  // Addresses array is a property of the consumer object, so this has special permissions flags.
  ACCOUNT: claimedUserResolver, // Account is only available for claimed users
  ADDRESSES: getClaimedUserResolverForPermissionsValue('consumer', 'ui-listaddresses'),
  ADDRESSES_CREATE: getClaimedUserResolverForPermissionsValue('consumer', 'ui-updateaddress'),
  ADDRESSES_EDIT: getClaimedUserResolverForPermissionsValue('consumer', 'ui-updateaddress'),
  ADDRESSES_VERIFY: getResolverForPermissionsValue('consumer', 'ui-updateaddress'),
  ADDRESSES_DELETE: getResolverForPermissionsValue('consumer', 'ui-deleteaddress'), // ui-deleteaddress doesn't exists yet on the BE. Thats intentional decision to disable address removal for now.
  AREAS: getClaimedUserResolverForMultiplePermissionsValues('area', ['ui', 'list']),
  AREAS_CREATE: getClaimedUserResolverForMultiplePermissionsValues('area', ['ui', 'create']),
  AREAS_DELETE: getClaimedUserResolverForMultiplePermissionsValues('area', ['ui', 'delete']),
  AREAS_EDIT: getClaimedUserResolverForMultiplePermissionsValues('area', ['ui', 'update']),
  BUSINESSES: getClaimedUserResolverForMultiplePermissionsValues('business', ['ui', 'list']),
  BUSINESSES_CREATE: getClaimedUserResolverForMultiplePermissionsValues('business', ['ui', 'create']),
  BUSINESSES_EDIT: getClaimedUserResolverForMultiplePermissionsValues('business', ['ui', 'update']),
  BUSINESSES_DELETE: getClaimedUserResolverForMultiplePermissionsValues('business', ['ui', 'delete']),
  BRANCHES_CREATE: getClaimedUserResolverForPermissionsValue('branch', 'create'),
  BRANCHES_EDIT: getClaimedUserResolverForPermissionsValue('branch', 'update'),
  BRANCHES_DELETE: getClaimedUserResolverForPermissionsValue('branch', 'delete'),
  DELIVERIES: getClaimedUserResolverForPermissionsValue('delivery', 'ui'),
  DELIVERIES_VIEW: getClaimedUserResolverForPermissionsValue('delivery', 'read'), // NB. Don't need UI perm to view a delivery
  DELIVERIES_DELIVERY_CREATE: getClaimedUserResolverForMultiplePermissionsValues('delivery', ['create', 'new-delivery-ui']),
  DELIVERIES_NEXT_DAY: getClaimedUserResolverForPermissionsValue('delivery', 'next-day-delivery-ui'),
  DELIVERIES_DELIVERY_CREATE_NEXT_DAY: getClaimedUserResolverForMultiplePermissionsValues('delivery', ['create', 'next-day-delivery-ui']),
  DELIVERIES_LINKED_NEXT_DAY: getClaimedUserResolverForPermissionsValue('delivery', 'next-day-linked-deliveries-ui'),
  DELIVERIES_RIDE_CREATE: getClaimedUserResolverForMultiplePermissionsValues('delivery', ['create', 'new-ride-ui']),
  DELIVERIES_EDIT: getClaimedUserResolverForPermissionsValue('delivery', 'update'),
  DRIVERS: getClaimedUserResolverForMultiplePermissionsValues('driver', ['ui', 'list']),
  DRIVERS_CREATE: getClaimedUserResolverForMultiplePermissionsValues('driver', ['ui', 'create']),
  DRIVERS_EDIT: getClaimedUserResolverForMultiplePermissionsValues('driver', ['ui', 'update']),
  DRIVERS_DELETE: getClaimedUserResolverForMultiplePermissionsValues('driver', ['ui', 'delete']),
  JOBS_DELETE: getClaimedUserResolverForMultiplePermissionsValues('job', ['delete']),
  FLEETS: getClaimedUserResolverForMultiplePermissionsValues('fleet', ['ui', 'list']),
  FLEETS_CREATE: getClaimedUserResolverForMultiplePermissionsValues('fleet', ['ui', 'create']),
  FLEETS_EDIT: getClaimedUserResolverForMultiplePermissionsValues('fleet', ['ui', 'update']),
  FLEETS_EDIT_GLOBAL: getClaimedUserResolverForMultiplePermissionsValues('fleet', ['ui', 'update', 'setglobal']),
  FLEETS_DELETE: getClaimedUserResolverForMultiplePermissionsValues('fleet', ['ui', 'delete']),
  ISSUES: getClaimedUserResolverForMultiplePermissionsValues('issue', ['ui', 'list']),
  LOGISTICS: logisticsResolver, // Logistics is special, see resolver
  USER_IS_CLAMED: claimedUserResolver,
  REQUESTS: getClaimedUserResolverForMultiplePermissionsValues('deliveryrequest', ['list']),
  REQUESTS_CREATE: getClaimedUserResolverForMultiplePermissionsValues('deliveryrequest', ['update', 'create']),
  // Orders array is a property of the consumer object, so this has a special permissions flag.
  ORDERS: getClaimedUserResolverForPermissionsValue('consumer', 'listdeliveries'),
  SERVICES: getClaimedUserResolverForMultiplePermissionsValues('service', ['ui', 'list']),
  SERVICES_CREATE: getClaimedUserResolverForMultiplePermissionsValues('service', ['ui', 'create']),
  SERVICES_EDIT: getClaimedUserResolverForMultiplePermissionsValues('service', ['ui', 'update']),
  SERVICES_DELETE: getClaimedUserResolverForMultiplePermissionsValues('service', ['ui', 'delete']),
  TARIFFS: getClaimedUserResolverForMultiplePermissionsValues('tariff', ['ui', 'list']),
  TARIFFS_CREATE: getClaimedUserResolverForMultiplePermissionsValues('tariff', ['ui', 'create']),
  TARIFFS_EDIT: getClaimedUserResolverForMultiplePermissionsValues('tariff', ['ui', 'update']),
  TARIFFS_DELETE: getClaimedUserResolverForMultiplePermissionsValues('tariff', ['ui', 'delete']),
  TARIFF_PERIODS: getClaimedUserResolverForMultiplePermissionsValues('tariffperiod', ['ui', 'list']),
  TARIFF_PERIODS_CREATE: getClaimedUserResolverForMultiplePermissionsValues('tariffperiod', ['ui', 'create']),
  TARIFF_PERIODS_EDIT: getClaimedUserResolverForMultiplePermissionsValues('tariffperiod', ['ui', 'update']),
  TARIFF_PERIODS_DELETE: getClaimedUserResolverForMultiplePermissionsValues('tariffperiod', ['ui', 'delete']),
  TRACKING: trackingResolver, // Tracking is special
  USER_MANAGEMENT: getClaimedUserResolverForPermissionsValue('teammember', 'ui'),
  USER_MANAGEMENT_EDIT: getClaimedUserResolverForMultiplePermissionsValues('teammember', ['ui', 'update']),
  USER_MANAGEMENT_CREATE: getClaimedUserResolverForMultiplePermissionsValues('teammember', ['ui', 'create']),
  VOUCHERS: getClaimedUserResolverForMultiplePermissionsValues('voucher', ['ui', 'create', 'update']),
  REPORTS_LIVE_ORDERS: getClaimedUserResolverForPermissionsValue('report', 'liveorders'),
  REPORTS_DRIVER_ANALYTICS: getClaimedUserResolverForPermissionsValue('report', 'drivers'),
  // REPORTS_DRIVER_ANALYTICS: getClaimedUserResolverForPermissionsValue('report', 'areas'), // TODO enable me
  REPORTS_AREA_ANALYTICS: () => true,
  REPORTS: getClaimedUserResolverForPermissionsValue('report', 'dashboard'),
  RIDES_REPORT: getClaimedUserResolverForPermissionsValue('report', 'rides'),
  SCHEDULE: getClaimedUserResolverForMultiplePermissionsValues('schedule', ['ui', 'list']),
  SCHEDULE_CREATE: getClaimedUserResolverForMultiplePermissionsValues('schedule', ['ui', 'create']),
  SCHEDULE_EDIT: getClaimedUserResolverForMultiplePermissionsValues('schedule', ['ui', 'update']),
  SCHEDULE_DELETE: getClaimedUserResolverForMultiplePermissionsValues('schedule', ['ui', 'delete']),
  STOREFRONT: getClaimedUserResolverForMultiplePermissionsValues('storefront', ['ui', 'list']),
  STOREFRONT_READ: getClaimedUserResolverForMultiplePermissionsValues('storefront', ['ui', 'read']),
  STOREFRONT_CREATE: getClaimedUserResolverForMultiplePermissionsValues('storefront', ['ui', 'create']),
  STOREFRONT_UPDATE: getClaimedUserResolverForMultiplePermissionsValues('storefront', ['ui', 'update']),
  STOREFRONT_DELETE: getClaimedUserResolverForMultiplePermissionsValues('storefront', ['ui', 'delete']),
  STOREFRONT_PRODUCT_GLOBAL_AVAILABILITY: getClaimedUserResolverForMultiplePermissionsValues('storefront', ['toggleproductavailability', 'toggleproductavailability-ui']),
  PRICE_SURGE: getClaimedUserResolverForMultiplePermissionsValues('pricesurge', ['ui', 'list']),
  PRICE_SURGE_VIEW: getClaimedUserResolverForMultiplePermissionsValues('pricesurge', ['ui', 'view']),
  PRICE_SURGE_CREATE: getClaimedUserResolverForMultiplePermissionsValues('pricesurge', ['ui', 'create']),
  PRICE_SURGE_UPDATE: getClaimedUserResolverForMultiplePermissionsValues('pricesurge', ['ui', 'update']),
  PRICE_SURGE_DELETE: getClaimedUserResolverForMultiplePermissionsValues('pricesurge', ['ui', 'delete']),
  TOOLS: getIsSuperAdminResolver(),
  PLACES: getIsSuperAdminResolver(),
  PROMO_CODES: getIsSuperAdminResolver(),
  CONSUMER_WALLET_HISTORY: getIsSuperAdminResolver()
}
const logger = new Logger('Permissions Model')

export function permissionsModelFactory (userModel) {
  const permissionsModelPubSub = new PubSub()
  let permissionsByApiPermissionsKey = {}
  let isUserClaimed = null
  let userIsSuperAdmin = false

  function init () {
    userModel.onUserChanged(syncPermissionsGivenUser)
  }

  function syncPermissionsGivenUser (user) {
    if (user && isObjectLike(user.permissions)) {
      setPermissions(user.permissions, user.claimedByUser, user.isSuperAdmin)
    }
  }

  function setPermissions (newPermissionsByApiPermissionsKey, userIsClaimed, isSuperAdmin) {
    if (!isEqual(permissionsByApiPermissionsKey, newPermissionsByApiPermissionsKey) ||
        !isEqual(isUserClaimed, userIsClaimed)) {
      permissionsByApiPermissionsKey = cloneDeep(newPermissionsByApiPermissionsKey)
      isUserClaimed = userIsClaimed
      permissionsModelPubSub.publish(PERMISSIONS_CHANGED_EVENT)
      userIsSuperAdmin = isSuperAdmin
    }
  }

  function onPermissionsChanged (callback) {
    permissionsModelPubSub.subscribe(PERMISSIONS_CHANGED_EVENT, callback)
  }

  function getHasPermission (uiPermissionsKey = '') {
    if (userIsSuperAdmin) {
      return true
    }
    const isValidKey = Object.keys(PERMISSIONS_RESOLVERS_BY_UI_PERMISSIONS_KEY).includes(uiPermissionsKey)
    if (isValidKey) {
      const permissionResolver = PERMISSIONS_RESOLVERS_BY_UI_PERMISSIONS_KEY[uiPermissionsKey]
      return !!permissionResolver(permissionsByApiPermissionsKey, isUserClaimed, userIsSuperAdmin)
    } else {
      logger.warn('Non-existent permissions key requested:', uiPermissionsKey)
      return false
    }
  }

  init()

  return {
    onPermissionsChanged,
    getHasPermission
  }
}

function getIsSuperAdminResolver (piPermissionsKey, isUserClaimed, isSuperAdmin) {
  return function () {
    return isSuperAdmin
  }
}

function getClaimedUserResolverForPermissionsValue (key, value) {
  return function (permissionsByApiPermissionsKey, isUserClaimed) {
    return claimedUserResolver(permissionsByApiPermissionsKey, isUserClaimed) &&
      getHasPermissionsValue(permissionsByApiPermissionsKey, key, value)
  }
}

function getResolverForPermissionsValue (key, value) {
  return function (permissionsByApiPermissionsKey) {
    return getHasPermissionsValue(permissionsByApiPermissionsKey, key, value)
  }
}

function getClaimedUserResolverForMultiplePermissionsValues (key, values) {
  return function (permissionsByApiPermissionsKey, isUserClaimed) {
    return claimedUserResolver(permissionsByApiPermissionsKey, isUserClaimed) &&
      getHasPermissionsValues(permissionsByApiPermissionsKey, key, values)
  }
}

// We differentiate between 'known unclaimed, known claimed, and unknown'
function claimedUserResolver (permissionsByApiPermissionsKey, isUserClaimed) {
  return isUserClaimed !== null ? isUserClaimed : false
}

function logisticsResolver (permissionsByApiPermissionsKey, isUserClaimed) {
  // Logistics in the UI is a 'parent' of fleets/drivers/etc. We only show it if it has any of its 'children'.
  return isUserClaimed &&
    (getHasPermissionsValues(permissionsByApiPermissionsKey, 'fleet', ['ui', 'list']) ||
    getHasPermissionsValues(permissionsByApiPermissionsKey, 'driver', ['ui', 'list']) ||
    getHasPermissionsValues(permissionsByApiPermissionsKey, 'tariffperiod', ['ui', 'list']) ||
    getHasPermissionsValues(permissionsByApiPermissionsKey, 'area', ['ui', 'list']) ||
    getHasPermissionsValues(permissionsByApiPermissionsKey, 'service', ['ui', 'list']))
}

function trackingResolver (permissionsByApiPermissionsKey, isUserClaimed) {
  // Tracking is only enabled if this user can view logistics, and can list deliveries
  return logisticsResolver(permissionsByApiPermissionsKey, isUserClaimed) &&
    getHasPermissionsValue(permissionsByApiPermissionsKey, 'delivery', 'list')
}

function getHasPermissionsValue (permissionsByApiPermissionsKey, key, value) {
  const permissionsForKey = permissionsByApiPermissionsKey[key]
  return !!(permissionsForKey && Array.isArray(permissionsForKey) && permissionsForKey.includes(value))
}

function getHasPermissionsValues (permissionsByApiPermissionsKey, key, values) {
  return every(values.map(value => getHasPermissionsValue(permissionsByApiPermissionsKey, key, value)))
}

export default permissionsModelFactory(userModel)

export const PERMISSIONS = {
  EDIT: 'update',
  VIEW: 'ui',
  DELETE: 'delete',
  CREATE: 'create'
}
