import BasePopupController from 'presentation/common/BasePopupController'
import { STOREFRONT_OPEN_PURCHASE_STOREFRONT_POPUP_EVENT } from 'common/constants/PopupEvents'

export const PURCHASE_STOREFRONT_DILAOG_ID = 'PURCHASE_STOREFRONT_DILAOG_ID'

class PurchaseStorefrontDialogController extends BasePopupController {

  public dialogId: string = PURCHASE_STOREFRONT_DILAOG_ID

  constructor (
    public $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
  ) {
    super($scope, $rootScope, STOREFRONT_OPEN_PURCHASE_STOREFRONT_POPUP_EVENT, PURCHASE_STOREFRONT_DILAOG_ID)
    this.setupPopupOpenEventListener(() => {
    })
  }


}

export default {
  templateUrl: require('./purchase-storefront-dialog.pug'),
  controller: PurchaseStorefrontDialogController,
  bindings: {
    onAccept: '&'
  }
}
